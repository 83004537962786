import React, { Component } from 'react';
import './ListeBakelistesboursiers.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import API from '../../variablesGlobales';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import $ from 'jquery';



export default class ListeBakelistesboursiers extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getSelectedBakelist = this.getSelectedBakelist.bind(this);
        this.assignCoach = this.assignCoach.bind(this);

        this.state = {
            bakeliste: [],
            fields: {},
            domaines: [],
            coachs: [],
            loading: false,
            api: API.API,
            assignationEncours: false,
            ress: [],
            user: [],
            dataBakeliste: {},
            countBakelisteBoursier: 0,
        };
    }
    componentDidMount() {
        this.onGetListeBakelisteBoursier();
        this.onGetBakelisteCounter();
        this.onGetListeDomaineFormation();
        this.getAllActivesCoach();
    }

    onGetListeBakelisteBoursier = () => {
        this.setState({
            loading: true
        })
        axios.get(this.state.api + 'liste-des-bakelistes-boursiers')
            .then(res => {
                if(res.data.success){
                    let bakelistes = res.data.data;
                    this.setState({
                        bakeliste: bakelistes.reverse(),
                        countBakelisteBoursier: res.data.count,
                        loading: false,
                    });
                    console.log(this.state.bakeliste);
                }else{
                    this.setState({
                        loading: false
                    })
                    console.log(res)
                }
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    loading: false
                })
            })
    }

    onGetBakelisteCounter = () => {
        axios.get(this.state.api + 'nbre-bakeliste')
            .then((response) => {
                const nbreBakeliste = response.data.data1;
                const nbrebakelistesGY1 = response.data.data5;
                const nbrebakelistesGY2 = response.data.data6;


                console.log('nombre bakeliste', response.data.data1);
                this.setState({
                    nbreBakeliste: nbreBakeliste,
                    nbrebakelistesGY1: nbrebakelistesGY1,
                    nbrebakelistesGY2: nbrebakelistesGY2

                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onGetListeDomaineFormation = () => {
        axios.get(this.state.api + 'domaines')
            .then(res => {
                if(res.data.success){
                    const domains = res.data;
                    this.setState({
                        domaines: domains.data,
                    });
                    console.log(this.state.domaines)
                }else{
                    console.log(res)
                }
            })
            .catch(error => {
                console.log(error.message)
            })
    }

    getAllActivesCoach = () => {
        axios.get(this.state.api + 'liste-des-coachs')
            .then(res => {
                const coachsData = res.data;
                const listActiveCoach = [];
                coachsData.data.forEach((item) => {
                    if (item.coach_is_actif === 1) {
                        listActiveCoach.push(item)
                    }
                })
                this.setState({
                    coachs: listActiveCoach,
                });
            })
            .catch(error => {
                // if (process.env.NODE_ENV !== 'production') {
                //     return;
                // }
                // Sentry.captureException(error);
                console.log(error.message)
            })
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }

    getSelectedBakelist(x) {
        console.log(x);
        this.setState({
            selectedBakelist: x
        })
        return x.id
    }


    assignCoach() {
        console.log("before assign", this.state);
        this.setState({
            assignationEncours: true
        });
        const id_bakelist = this.state.selectedBakelist.id;
        const dataForAssign = {
            bakeliste_id: id_bakelist,
            coach_id: this.state.fields.coach_id

        }
        console.log(dataForAssign)
        axios.post(this.state.api + 'assigner-un-coach', dataForAssign)
            .then(res => {
                console.log(res);
                console.log(res.data);
                if (res.data.success) {
                    $('.close').click();
                    this.setState({
                        assignationEncours: false
                    });
                    //window.location.reload();
                    this.onGetListeBakelisteBoursier();
                }else{
                    this.setState({
                        assignationEncours: false
                    });
                }
            })
            .catch(error => {
                console.log(error.message)
            })
    }

    Detail(x) {
        console.log("detail baliste: ", x);
        this.setState({
            selectedBakelist: x,
            dataBakeliste: x
        })

    }

    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    bakelisteFullnameFormatter = (cell, row) => {
        return (
            <span className="fullname-link" data-toggle="modal" data-target="#moModal" onClick={(e) => this.Detail(row, e)}>{cell}</span>
        );
    }

    coachRowFormatter = (cell, row) => {
        return (
            <div className="coach_row actions-btn-container">
                {row.coach_id ?
                    <Link to="#">
                        {cell}
                    </Link> :
                    <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#myModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
                        <i className="fas fa-user-check"></i>
                    </button>

                }
            </div>
        );
    }

    actionsFormatter = () => {

    }

    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    createCustomButtonGroup = props => {
        return (
            <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
                {props.exportCSVBtn}
                {/* <button type='button'
              className={ `btn btn-primary volkeno-red-bcolor` }>
              MyCustomBtn
            </button> */}
            </ButtonGroup>
        );
    }

    render() {
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton,
            btnGroup: this.createCustomButtonGroup
        };

        if (this.state.loading) {
            return (
                <div className="component-ListeBakelistesboursiers">
                    <Menu />
                    <div className="createBakeliste row" >
                        <div className="col-lg-12">
                            <h1 className="page-header">
                                <span className="page-title">Liste des bakelistes</span>
                                <div className="pull-right">
                                    <ol className="breadcrumb">
                                        <li>
                                            <Link to="/dashboard-admin">Accueil</Link>
                                        </li>
                                        <li className="active">Liste des bakelistes</li>
                                    </ol>
                                </div>
                            </h1>
                        </div>

                        <div className="col-md-12 reporting-container">
                            <div className="row add_bakeliste_container">
                                <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                                    <Link data-toggle="tooltip" data-placement="top" title="Ajouter bakeliste" to="/ajouter-un-bakeliste" className="btn btn-success add_coach_btn button">
                                        <i className="fas fa-plus-circle"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="list_bakeliste_container">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Prénom et Nom</th>
                                                <th scope="col">Domaines</th>
                                                <th scope="col">Coach</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div id="loader"></div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            )
        }
        return (
            <div className="component-ListeBakelistesboursiers">
                <Menu />
                <div className="createBakeliste row" >
                    <div className="col-lg-12">
                        <h1 className="page-header">
                            <span className="page-title">Liste des bakelistes Boursiers</span>
                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard-admin">Accueil</Link>
                                    </li>
                                    <li className="active">Liste des bakelistes</li>
                                </ol>
                            </div>
                        </h1>
                    </div>

                    {/* Bakeliste counter */}
                    <div className="row count-bloc-container">
                        <div className="col-lg-4 col-md-6">
                            <NavLink to="#" className="counterLink">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                    <div className="info-box">
                                        <span className="info-box-icon bg-vgreen">
                                            <i className="fas fa-tasks"></i>
                                        </span>

                                        <div className="info-box-content">
                                            <span className="info-box-text">Nombre de bakeliste BOURSIER</span>
                                            <span className="info-box-number">
                                                {this.state.countBakelisteBoursier}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>



                    <div className="col-md-12 reporting-container">
                        <div className="row add_bakeliste_container">
                            <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                                <Link data-toggle="tooltip" data-placement="top" title="Ajouter bakeliste" to="/ajouter-un-bakeliste" className="btn btn-success add_coach_btn button">
                                    <i className="fas fa-plus-circle"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="list_bakeliste_container">
                        <BootstrapTable data={this.state.bakeliste}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}>
                                {/* <TableHeaderColumn dataAlign="center" width='450' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center"
                                width='250'
                                dataField='type_formation'
                                dataFormat={this.typeFormationFormatter.bind(this)}
                                filter={{ type: 'TextFilter', delay: 1000 }}
                                dataSort={true}>Type de formation</TableHeaderColumn> */}
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>

                            </BootstrapTable>

                            <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <h4 className="modal-title" id="myModalLabel">Selection du coach</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group " >
                                                <label >Coach:</label>
                                                <select className="form-control" id="inputbakeli_domaine_id" name="coach_id" onChange={this.handleChange}>
                                                    <option> -- Choisir un coach -- </option>
                                                    {this.state.coachs.map((z, y) => <option style={{ color: 'blue' }} value={y.id}>{z.first_name} {z.last_name} {'-'} {z.nbreBakeliste} {'bakeliste(s)'}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-default" data-dismiss="modal">Quitter</button>
                                            {!this.state.assignationEncours &&
                                                <button type="button" id="ass" className="btn btn-primary" onClick={(e) => this.assignCoach(e)}>Assigner</button>
                                            }
                                            {this.state.assignationEncours &&
                                                <button type="button" className="btn btn-warning">
                                                    Assignation en cours &nbsp;
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="modal fade" id="moModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <h4 className="modal-title" id="myModalLabel">Detail sur {this.state.dataBakeliste.first_name}&nbsp;{this.state.dataBakeliste.last_name}</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="email_container">
                                                <span className="item_label">Email : </span> <span className="label_content">{this.state.dataBakeliste.email}</span>
                                            </div> <hr />
                                            <div className="objectif_container">
                                                <span className="item_label">Objectifs : </span> <span className="label_content">{this.state.dataBakeliste.objectifs}</span>
                                            </div> <hr />

                                            {this.state.dataBakeliste.ecole !== '' &&
                                                <div className="ecole_container">
                                                    <span className="item_label">Ecole : </span> <span className="label_content">{this.state.dataBakeliste.ecole}</span> <hr />
                                                </div>

                                            }
                                            <div className="email_container">
                                                <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.dataBakeliste.phone}</span>
                                            </div> <hr />
                                            {this.state.dataBakeliste.boursier === 1 &&
                                                <div className="ecole_container">
                                                    <span className="item_label">Boursier : </span> <span className="label_content">NON</span> <hr />
                                                </div>

                                            }
                                            {this.state.dataBakeliste.boursier === 0 &&
                                                <div className="ecole_container">
                                                    <span className="item_label">Boursier : </span> <span className="label_content">OUI</span> <hr />
                                                </div>

                                            }
                                            <div className="email_container">
                                                <span className="item_label">lieu_formation : </span> <span className="label_content">{this.state.dataBakeliste.lieu_formation}</span>
                                            </div> <hr />
                                            <div className="email_container">
                                                <span className="item_label">Type de formation : </span> <span className="label_content">{this.state.dataBakeliste.type_formation}</span>
                                            </div> <hr />
                                            {this.state.dataBakeliste.paiement_inscription === 1 &&
                                                <div className="ecole_container">
                                                    <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">NON</span> <hr />
                                                </div>

                                            }
                                            {this.state.dataBakeliste.paiement_inscription === 0 &&
                                                <div className="ecole_container">
                                                    <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">OUI</span> <hr />
                                                </div>

                                            }
                                            <div className="email_container">
                                                <span className="item_label">Date de début de la formation : </span> <span className="label_content">{this.state.dataBakeliste.debut_formation}</span>
                                            </div> <hr />
                                            <div className="email_container">
                                                <span className="item_label">Date de fin de la formation : </span> <span className="label_content">{this.state.dataBakeliste.fin_formation}</span>
                                            </div> <hr />
                                        </div>
                                        <div className="modal-footer">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
