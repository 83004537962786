import React, { Component } from "react";
import "./Connexion.css";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import { Container, Row, Col } from "shards-react";
import { isMobile } from "react-device-detect";
import ConnexionSkeleton from "./ConnexionSkeleton";
import $ from "jquery";

// eslint-disable-next-line
class Connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginPage: true,
      email: "",
      password: "",
      errors: {},
      isAuthenticated: false,
      token: null,
      isValidUser: true,
      authAlertError: false,
      userFullName: "",
      isPending: false,
      api: API.API,
      email_empty: false,
      password_empty: false,
      isRequestError: false,
      trainingIsCompleted: false,
      isLoaded: true,
    };
    Sentry.init({
      dsn:
        "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    });
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentDidMount() {
    let x = document.getElementById("page-wrapper");
    x.style.marginLeft = 0;
    x.style.Top = 0;

    if (isMobile) {
      let idSelector = document.getElementById("page-wrapper");
      idSelector.marginTop = 0;
    }
    setTimeout(
      function () {
        this.setState({ isLoaded: false });
        $("#start-hidden").removeClass("start-hidden");
      }.bind(this),
      5000
    );

    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    //this.amplitudeScript('7a9faefb1bb6a856ed9b89eaaf1e8944');
  }

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
          console.log("[Amplitude] Error: could not load SDK");
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };

  handleChangeEmail(event) {
    this.setState({
      email: event.target.value,
    });
  }
  handleChangePassword(event) {
    this.setState({
      password: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  validateLoginForm() {
    let email = this.state.email;
    let password = this.state.password;
    let isValidForm = true;
    let errors = {};

    if (!email) {
      this.setState({
        isPending: false,
      });
      isValidForm = false;
      this.setState({
        email_empty: true,
      });
      this.handleShowAndHideAlert("email");
      errors["email"] = "Le champ email est obligatoire";
    }
    if (!password) {
      this.setState({
        isPending: false,
      });
      isValidForm = false;
      this.setState({
        password_empty: true,
      });
      this.handleShowAndHideAlert("password");
      errors["password"] = "Le champ mot de passe est obligatoire";
    }
    this.setState({
      errors: errors,
    });
    return isValidForm;
  }

  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === "credential_error") {
        this.setState({
          isValidUser: true,
        });
      }
      if (input === "email") {
        this.setState({
          email_empty: false,
        });
      }
      if (input === "password") {
        this.setState({
          password_empty: false,
        });
      }
      if (input === "requestError") {
        this.setState({
          isRequestError: false,
        });
      }
    }, 5000);
  }

  doLogin = () => {
    this.setState({
      isPending: true,
    });
    if (this.validateLoginForm()) {
      const fields = {
        email: this.state.email,
        password: this.state.password,
      };
      //console.log(this.state.password)
      //console.log(this.state.api)
      //console.log('fields',fields);

      var eventProperties = {
        user_email: this.state.email,
      };

      axios
        .post(this.state.api + "user/login", fields, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log("user", response.data.success);
          //let isValidUser = true;
          let errors = {};
          const token = response.data.data.auth_token;
          const user_first_name = response.data.data.first_name;
          const user_last_name = response.data.data.last_name;
          const userFullName =
            response.data.data.first_name + " " + response.data.data.last_name;
          const userStatus = response.data.data.status;
          const connectedUserID = response.data.data.id;
          const bakelisteCoachId = response.data.data.coach_id;
          const user_bio = response.data.data.bio;
          const user_address = response.data.data.address;
          const isBakelisteEndTraining = response.data.data.trainingIsCompleted;
          const user_domaine = response.data.data.bakeliste_domaine_id;
          const user_phone = response.data.data.phone;
          this.setState({
            userFullName: userFullName,
            token: token,
          });
          console.log(response.data.data.first_name);
          if (token) {
            window.sessionStorage.setItem("token", token);
            window.sessionStorage.setItem("userFullName", userFullName);
            window.sessionStorage.setItem("user_status", userStatus);
            window.sessionStorage.setItem("user_id", connectedUserID);
            window.sessionStorage.setItem("coach_id", bakelisteCoachId);
            window.sessionStorage.setItem("user_bio", user_bio);
            window.sessionStorage.setItem("user_phone", user_phone);
            window.sessionStorage.setItem("user_address", user_address);
            window.sessionStorage.setItem("user_first_name", user_first_name);
            window.sessionStorage.setItem("user_last_name", user_last_name);
            window.sessionStorage.setItem("user_domaine", user_domaine);

            localStorage.setItem("app_data", JSON.stringify(token));
            if (userStatus === "bakeliste") {
              window.sessionStorage.setItem(
                "coach_fullname",
                response.data.data.coachFullname
              );
              window.sessionStorage.setItem(
                "coach_email",
                response.data.data.coachEmail
              );
              window.sessionStorage.setItem(
                "coach_phone",
                response.data.data.coachPhone
              );
            }
            console.log(bakelisteCoachId);
            this.setState({
              isPending: false,
            });

            amplitude
              .getInstance()
              .logEvent("CONNEXION_BAKELISTE_TYPE", eventProperties);
            if (response.data.data.status === "bakeliste") {
              if (isBakelisteEndTraining === 1) {
                this.setState({
                  trainingIsCompleted: true,
                });
                window.sessionStorage.removeItem("coach_fullname");
                window.sessionStorage.removeItem("coach_email");
                window.sessionStorage.removeItem("coach_phone");
                window.sessionStorage.removeItem("token");

                amplitude
                  .getInstance()
                  .logEvent(
                    "END_TRANING_BAKELISTE_CONNEXION_TYPE",
                    eventProperties
                  );
              } else {
                window.location = "dashboard-bakeliste";

                amplitude
                  .getInstance()
                  .logEvent("BAKELISTE_CONNEXION_TYPE", eventProperties);
              }
            } else if (response.data.data.status === "admin") {
              window.location = "dashboard-admin";

              amplitude
                .getInstance()
                .logEvent("ADMIN_CONNEXION_TYPE", eventProperties);
            } else if (response.data.data.status === "coach") {
              window.location = "dashboard-coach";

              amplitude
                .getInstance()
                .logEvent("COACH_CONNEXION_TYPE", eventProperties);
            }
          } else if (!token) {
            errors["auth_error"] = "Vos identifiants ne sont pas corrects.";
            this.setState({
              errors: errors,
              isValidUser: false,
              isPending: false,
            });

            this.handleShowAndHideAlert("credential_error");

            amplitude.getInstance().logEvent("NO_CORRECT_ID_CONNEXION_TYPE");
          }
        })
        .catch((error) => {
          this.setState({
            isPending: false,
            isRequestError: true,
          });
          amplitude.getInstance().logEvent("REQUEST_ERROR_CONNEXION_TYPE");
          this.handleShowAndHideAlert("requestError");
          console.log(error);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  render() {
    return (
      <Row>
        {this.state.isLoaded && <ConnexionSkeleton />}
        <div className="container-fluid start-hidden" id="start-hidden">
          <div className="Connexion" style={{ backgroundImage: "url()" }}>
            <Helmet>
              <title>Bakeli App | LogIn</title>
              <link
                rel="stylesheet"
                href="/vendor/bootstrap/css/bootstrap.min.css"
              />
            </Helmet>
            <div className="page-holder d-flex align-items-center">
              <div className="container-fluid">
                <div className="row align-items-center login-form-container">
                  <div className="col-md-6 jeema_coder">
                    <div className="row wow fadeIn">
                      <div className="col-md-12 mb-4 white-text text-center wow fadeIn">
                        <Container className="contact-content debug-border">
                          <Row className="justify-content-center">
                            <Col>
                              <br />
                              <br />
                              <img
                                className="img-fluid"
                                src={require("../../images/logobakeli.png")}
                                alt="Sample "
                                style={{
                                  height: "70%",
                                  width: "70%",
                                  borderRadius: "50%",
                                }}
                              />
                            </Col>
                            <Col></Col>
                            <Col></Col>
                          </Row>
                        </Container>
                        <h5
                          className="display-3 font-weight-bold"
                          style={{ color: "white", marginTop: "20%" }}
                        >
                          Bienvenue sur <br />
                          Bakeli SI
                          <br />
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 px-lg-4 loginify"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="">
                      <h2 className="conex" style={{ Color: "rgb(7,149,115)" }}>
                        CONNECTEZ-VOUS
                      </h2>{" "}
                      <form id="loginForm" className="mt-4">
                        <div className="form-group mb-4">
                          <input
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChangeEmail}
                            placeholder="Votre adresse email"
                            className="form-control border-0 shadow form-control-lg"
                          />
                        </div>
                        {this.state.email_empty && (
                          <div className="errorMsg">
                            {this.state.errors.email}
                          </div>
                        )}
                        <div className="form-group mb-4">
                          <input
                            type="password"
                            value={this.state.password}
                            onChange={this.handleChangePassword}
                            name="passowrd"
                            placeholder="Votre mot de passe"
                            className="form-control border-0 shadow form-control-lg text-violet"
                          />
                        </div>
                        {this.state.password_empty && (
                          <div className="errorMsg">
                            {this.state.errors.password}
                          </div>
                        )}
                        <div className="form-group">
                          {!this.state.isPending && (
                            <Amplitude>
                              {({ logEvent }) => (
                                <button
                                  className="btn btn-primary shadow px-5 bg-vgreen btn_owny pull-right"
                                  onClick={this.doLogin}
                                >
                                  <img
                                    src="https://img.icons8.com/ios/50/FFFFFF/login-rounded-right.png"
                                    className="login-icon"
                                    alt="Connexion"
                                  />
                                </button>
                              )}
                            </Amplitude>
                          )}
                          {this.state.isPending && (
                            <button className="btn btn-warning shadow px-5 bg-vorange add_in_progress pull-right">
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          )}
                        </div>
                      </form>
                      <div id="forget_container">
                        <NavLink
                          className="forget-pwd-link"
                          to="/reinitialiser-mot-de-passe"
                        >
                          Mot de passe oublié ?
                        </NavLink>
                        {/* &emsp; &emsp; &emsp; &emsp;
                          <NavLink className="forget-pwd-link" to="/register">
                            S'inscrire
                          </NavLink> */}
                        &emsp; &emsp; &emsp; &emsp;
                        <NavLink
                          className="forget-pwd-link"
                          to="/signaler-un-probleme"
                        >
                          Signaler un problème
                        </NavLink>
                      </div>
                      {!this.state.isValidUser && (
                        <div className="auth-error text-center">
                          {this.state.errors.auth_error}
                        </div>
                      )}
                      {this.state.isRequestError && (
                        <div className="auth-error text-center">
                          La connexion a échoué. Veuillez réessayer plus tard.
                        </div>
                      )}
                      {this.state.trainingIsCompleted && (
                        <div className="auth-error text-center">
                          Votre formation est terminée. Veuillez vous addresser
                          à votre coach.
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6 maintenance_mode_image_container">
                <img src={'/images/maintenance.svg'} className="img-responsive maintenance-img" alt="Maintenance" />
              </div>
              <div className="col-md-6 maintenance_mode_textuel_container">
                 <h1 className="maintenance_title">Bakeli SI est en maintenance !</h1>
                 <p>Vous serez informé de la disponibilité de la plateforme dés que la maintenance sera achevée.</p>
                 <p>Vous pouvez contacter les administrateurs de Bakeli en cliquant sur le bouton ci-dessous.</p>
                 <a href="mailto:contact@bakeli.tech" className="btn btn-success contact-mail-btn">
                    Nous envoyer un email
                 </a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}
export default Connexion;
