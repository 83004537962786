import React, {Component} from 'react';
import './delayAndAbsence.css'
//import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
//import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
// import axios from 'axios';
import { NavLink } from 'react-router-dom';
// import API from '../../variablesGlobales';
// import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import '../../../node_modules/admin-lte/dist/js/adminlte.js';
import AdminViewDelayAbsence from './adminViewDelayAbsence/AdminViewDelayAbsence';
import CoachViewDelayAbsence from './coachViewDelayAbsence/CoachViewDelayAbsence';


export default class delayAndAbsence extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');

        if (!token) {
            window.history.back()
        }
        if (userStatus === 'bakeliste') {
            window.history.back();
        }

        super(props);
        this.state = {
          userStatus: window.sessionStorage.getItem('user_status'),

        };
    }
    render() {
      return(
        <div className="component-delay-and-absence">
          <Menu />
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
                <div className="row no-content-wrapper">

                    <section className="content-header">
                        <h1>
                            Retards/Présences
                        </h1>
                        <ol className="breadcrumb">
                            <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                            <li className="active">Retards/Présences</li>
                        </ol>
                    </section>

                    <section className="content">
                        {/* Blocs contain bakeliste delay list and absence list */}
                        <div className="row tab-data-row">
                          <div className="col-md-12 tab-data-col">
                            {this.state.userStatus === 'admin' &&
                              <AdminViewDelayAbsence />
                            }
                            {this.state.userStatus === 'coach' &&
                              <CoachViewDelayAbsence />
                            }
                          </div>
                        </div>
                    </section>
                </div>
        </div>
      );
    }
}