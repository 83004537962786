import React, { Component } from 'react';
import './createProspect.css';
import axios from 'axios';
import API from '../../variablesGlobales';
import Menu from './../menu/Menu';
import { Link } from 'react-router-dom';

class createProspect extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.state = {
            fields: {},
            errors: {},
            value: '',
            domaines: [],
            isFormationCourte: false,
            isFormationDiplomante: false,
            isPro: false,
            isEtudiant: false,
            api: API.API,
            fn_empty: false,
            ln_empty: false,
            email_empty: false,
            phone_empty: false,
            ispro_empty: false,
            tf_empty: false,
            df_empty: false,
            fd_empty: false
        };
    }

    handleSubmit(event) {
        //alert('An essay was submitted: ' + this.state.firstName);
        event.preventDefault();
    }

    componentDidMount() {
        axios.get(this.state.api + 'domaines')
            .then(res => {
                const domains = res.data;
                /* this.setState({
                    domaines:persons.data
                }) */
                console.log(res.data.data)
                this.setState({
                    domaines: domains.data,
                });
            })
    }

    handleShowAndHideAlert(input){
        setTimeout(() => {
            if(input === 'firstName'){
                this.setState({
                    fn_empty: false
                });
            }
            if(input === 'lastName'){
                this.setState({
                    ln_empty: false
                });
            }
            if(input === 'email'){
                this.setState({
                    email_empty: false
                });
            }
            if(input === 'phone'){
                this.setState({
                    phone_empty: false
                });
            }
            if(input === 'type_formation'){
                this.setState({
                    tf_empty: false
                });
            }
            if(input === 'ispro'){
                this.setState({
                    ispro_empty: false
                });
            }
            if(input === 'domaine_formation'){
                this.setState({
                    df_empty: false
                });
            }
            if(input === 'formation_diplomante'){
                this.setState({
                    fd_empty: false
                });
            }
        }, 5000);
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["firstName"]) {
            formIsValid = false;
            this.setState({
                fn_empty: true
            })
            this.handleShowAndHideAlert("firstName");
            errors["firstName"] = "*Ce champ est obligatoire";
        }

        if (typeof fields["firstName"] !== "undefined") {
            if (!fields["firstName"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
                formIsValid = false;
                this.setState({
                    fn_empty: true
                })
                this.handleShowAndHideAlert("firstName");
                errors["firstName"] = "*SVP que des lettres.";
            }
        }

        if (!fields["lastName"]) {
            formIsValid = false;
            this.setState({
                ln_empty: true
            })
            this.handleShowAndHideAlert("lastName");
            errors["lastName"] = "*Ce champ est obligatoire";
        }

        if (typeof fields["lastName"] !== "undefined") {
            if (!fields["lastName"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
                formIsValid = false;
                this.setState({
                    ln_empty: true
                })
                this.handleShowAndHideAlert("lastName");
                errors["lastName"] = "*SVP que des lettres.";
            }
        }

        if (!fields["email"]) {
            formIsValid = false;
            this.setState({
                email_empty: true
            })
            this.handleShowAndHideAlert("email");
            errors["email"] = "*Ce champ est obligatoire.";
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                this.setState({
                    email_empty: true
                })
                this.handleShowAndHideAlert("email");
                errors["email"] = "*Adresse email invalide.";
            }
        }

        if (!fields["phone"]) {
            formIsValid = false;
            this.setState({
                phone_empty: true
            })
            this.handleShowAndHideAlert("phone");
            errors["phone"] = "*Ce champ est obligatoire.";
        }

        if (typeof fields["phone"] !== "undefined") {
            if (!fields["phone"].match(/^[0-9]{9}$/)) {
                formIsValid = false;
                this.setState({
                    phone_empty: true
                })
                this.handleShowAndHideAlert("phone");
                errors["phone"] = "*Numéro de telephone invalide.";
            }
        }
        if (!fields["ispro"]) {
            formIsValid = false;
            this.setState({
                ispro_empty: true
            })
            this.handleShowAndHideAlert("ispro");
            errors["ispro"] = "*Ce champ est obligatoire.";
        }
        if (!fields["type_formation"]) {
            formIsValid = false;
            this.setState({
                tf_empty: true
            })
            this.handleShowAndHideAlert("type_formation");
            errors["type_formation"] = "*Ce champ est obligatoire.";
        }
        if (!fields["study_choice"]) {
            formIsValid = false;
            this.setState({
                df_empty: true
            })
            this.handleShowAndHideAlert("domaine_formation");
            errors["domaine_formation"] = "*Ce champ est obligatoire.";
        }
        if (fields["type_formation"] === 'diplômante' && !fields["fd_type"]) {
            formIsValid = false;
            this.setState({
                fd_empty: true
            })
            this.handleShowAndHideAlert("formation_diplomante");
            errors["formation_diplomante"] = "*Ce champ est obligatoire.";
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        console.log("handle change", e.target.name)
        if (e.target.name === "study_choice") {
            this.state.domaines.forEach((x) => {
                if (x.name === e.target.value) {
                    this.setState({
                        idSelect: x.id
                    })
                }
            })
            fields[e.target.name] = this.state.idSelect;

        }
        if (e.target.name === 'type_formation') {
            fields[e.target.name] = e.target.value;
            if (e.target.value === 'diplômante') {
                this.setState({
                    isFormationDiplomante: true,
                    isFormationCourte: false
                });
            } else if (e.target.value === 'courte') {
                this.setState({
                    isFormationDiplomante: false,
                    isFormationCourte: true
                });
            }

        }
        if (e.target.name === 'ispro') {
            fields[e.target.name] = e.target.value;
            if (e.target.value === 1) {
                this.setState({
                    isPro: true,
                    isEtudiant: false
                });
            } else if (e.target.value === 0) {
                this.setState({
                    isPro: false,
                    isEtudiant: true
                });
            }
        }

        this.setState({
            fields
        });
    }

    submituserRegistrationForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            let fields = {};
            fields["firstName"] = "";
            fields["lastName"] = "";
            fields["email"] = "";
            fields["phone"] = "";
            fields["study_choice"] = "";
            fields['ispro'] = "";
            fields['pro_profession'] = "";
            fields['pro_poste_title'] = "";
            fields['fd_type'] = "";
            fields['session'] = "";
            fields['level'] = "";
            fields['type_formation'] = "";
            this.setState({ fields: fields });
            const dataProspect = this.state.fields;
            console.log(dataProspect);
            axios.post(this.state.api + 'prospects', dataProspect)
                .then(res => {
                    console.log(res);
                    console.log(res.data);
                    if (res.data.success === true) {
                        window.location = 'liste-prospects';
                    }
                })
        }
    }


    render() {
        return (
            <div>
                <Menu />

                <div className="createBakeliste row" >
                    <div className="col-lg-12">
                        <h1 className="page-header">
                            <span className="page-title">Ajouter un Prospect</span>
                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard-admin">Accueil</Link>
                                    </li>
                                    <li className="active">Ajouter un Prospect</li>
                                </ol>
                            </div>
                        </h1>
                    </div>
                    <div className="col-md-8 col-md-offset-2 add_coach_form_container">
                        <form onSubmit={this.submituserRegistrationForm}  >
                            <div className="form-row">
                                <div>
                                    <h1 id="titreCoach">Ajouter un Prospect</h1>
                                </div>
                                <div className="form-group ">
                                    <label >Prénom:</label>
                                    <input type="text" className="form-control" id="inputpFirstName" placeholder="Prénom" name="firstName" value={this.state.fields.firstName} onChange={this.handleChange} />
                                </div>
                                {this.state.fn_empty &&
                                    <div className="errorMsg">{this.state.errors.firstName}</div>
                                }
                                <div className="form-group ">
                                    <label >Nom:</label>
                                    <input type="text" className="form-control" id="inputLastName" placeholder="Nom" name="lastName" value={this.state.fields.lastName} onChange={this.handleChange} />
                                </div>
                                {this.state.ln_empty &&
                                    <div className="errorMsg">{this.state.errors.lastName}</div>
                                }

                                <div className="form-group " >
                                    <label >Email:</label>
                                    <input type="Email" className="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.fields.email} onChange={this.handleChange} />
                                </div>
                                {this.state.email_empty &&
                                    <div className="errorMsg">{this.state.errors.email}</div>
                                }
                                <div className="form-group conditionnal-input">
                                    <label>Statut professionnel</label> <br />
                                    <input type="radio" className="radio-bsi" name="ispro" value="1" onClick={this.handleChange} />
                                    <span>Professionnel</span> <br />
                                    <input type="radio" className="radio-bsi" name="ispro" value="0" onClick={this.handleChange} />
                                    <span>Etudiant</span>
                                </div>
                                {this.state.ispro_empty &&
                                    <div className="errorMsg">{this.state.errors.ispro}</div>
                                }
                                {this.state.isEtudiant &&
                                    <div>
                                        <div className="form-group " >
                                            <label >Niveau d'étude :</label>
                                            <input type="text" className="form-control" id="inputLevel" placeholder="Niveau d'étude" name="level" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                }

                                {this.state.isPro &&
                                    <div>
                                        <div className="form-group " >
                                            <label >Profession :</label>
                                            <input type="text" className="form-control" id="inputProfession" placeholder="Profession" name="pro_profession" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group " >
                                            <label >Titre du poste :</label>
                                            <input type="text" className="form-control" id="inputPosteTitle" placeholder="Titre du poste" name="pro_poste_title" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                }
                                <div className="form-group ">
                                    <label >Téléphone:</label>
                                    <input type="text" className="form-control" id="inputNumero" placeholder="Téléphone" name="phone" onChange={this.handleChange} />
                                </div>
                                {this.state.phone_empty &&
                                    <div className="errorMsg">{this.state.errors.phone}</div>
                                }

                                <div className="form-group " >
                                    <label >Domaine de formation:</label>
                                    <select className="form-control" id="inputbakeli_domaine_id" name="study_choice" onChange={this.handleChange}>
                                        <option></option>
                                        {this.state.domaines.map((x, y) => <option key={y}>{x.name}</option>)}
                                    </select>
                                </div>
                                {this.state.df_empty &&
                                    <div className="errorMsg">{this.state.errors.domaine_formation}</div>
                                }

                                <div className="form-group conditionnal-input" >
                                    <label >Formation:</label> <br />
                                    <input type="radio" className="radio-bsi" name="type_formation" value="courte" onClick={this.handleChange} />
                                    <span>Formation courte</span> <br />
                                    <input type="radio" className="radio-bsi" name="type_formation" value="diplômante" onClick={this.handleChange} />
                                    <span>Formation diplômante</span>
                                </div>
                                {this.state.tf_empty &&
                                    <div className="errorMsg">{this.state.errors.type_formation}</div>
                                }
                                {this.state.isFormationDiplomante &&
                                    <div>
                                        <div className="form-group">
                                            <label >Type de Formation dipômante :</label>
                                            <select className="form-control" id="inputtype_formation" name="fd_type" onClick={this.handleChange}>
                                                <option>Choisir un type</option>
                                                <option value="BTS">BTS</option>
                                                <option value="CAP">CAP</option>
                                                <option value="BEP">BEP</option>
                                            </select>
                                        </div>
                                        {this.state.fd_empty &&
                                            <div className="errorMsg">{this.state.errors.formation_diplomante}</div>
                                        }
                                        <div className="form-group">
                                            <label>Session : (Octobre / Avril)</label>
                                            <input type="text" className="form-control" name="session" id="inputSession" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="col-md-8 col-md-offset-2 submit-btn-container">
                                <div className="form-group " >
                                    <button type="submit" className='btn btn-warning add-item-btn'>
                                        <i className="fas fa-plus-circle"></i> &nbsp;
                                        Ajouter
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default createProspect;

