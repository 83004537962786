import React, { options, Component } from "react";
import Menu from "../menu/Menu";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import { Button, Modal } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";
import FeatherIcon from "feather-icons-react";

import { Amplitude } from "@amplitude/react-amplitude";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import { Container, Row, Col } from "shards-react";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
class Livraison extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api: API.API,
      fields: {},
      livraison: 0,
      commentaires: [],
      isOk: false ,
      is_empty_tache_file: false,
      isInvalidFileExtension: false,
      userID: window.sessionStorage.getItem("user_id"),
    };
  }

  componentDidMount() {
    this.getLivraison();
  }

  getLivraison = () => {
    var api = this.state.api;
    axios.get(api + "livraison/" + this.state.userID).then((res) => {
      if (res.data.success) {
        const alllivraisons = res.data;
        
        this.setState({
          alllivraison: alllivraisons.data,
        });
       /*  {this.state.alllivraison.map((index) => (
          console.log(index.feedbacks)
         ))} */
      } else {
        console.log(this.state.alllivraison, "part");
      }
    });
  };
  getFeedbacks = () => {
    var api = this.state.api;
    axios.get(api + "coach_livrable_feedbacks/" ).then((res) => {
      if (res.data.success) {
        const feedbacks = res.data;
        this.setState({
          feedback: feedbacks.data,
        });
      } else {
        console.log(this.state.fedback, "part");
      }
    });
  };

  livrableActionFormatter = (cell, row) => {
    return(
        <div >
            <button onClick={() => this.onGetCommentaire(row)} type="button" className="btn btn-warning"   data-toggle="modal" style={{marginLeft: "18%"}} data-target="#feedbacks">
                <i className="fas fa-eye"></i> &nbsp; Voir 
            </button>
        </div>
    )
  }
  onGetCommentaire(alllivraison){
    console.log(alllivraison.feedbacks, "fedd")
    this.setState({
        commentaires: alllivraison.feedbacks
       
    });
    
}


  render() {
    return (
      <div className="container">
        
        <Menu />

        <div class="col-md-10">
        
          <div className="row prospectw-table-row">
            <div className="bakeli-ete-table-col">
              <BootstrapTable
                data={this.state.alllivraison}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                pagination
                exportCSV
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="commentaire"
                  filter={{ type: "TextFilter", delay: 1000 }}
                  dataSort={true}
                  width="230"
                >
                  Commentaires
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="created_at"
                  dataSort={true}
                  width="200"
                  filter={{ type: "TextFilter", delay: 1000 }}
                >
                  Date d'envoie
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataSort={true}
                  width="200"
                  dataField="tache_file"
                >
                  Livrables
                </TableHeaderColumn>
               <TableHeaderColumn  
                  dataSort={true} 
                  dataField='id' 
                  width="200" 
                  dataFormat={this.livrableActionFormatter.bind(this)} 
                >
                  Commentaire du coach
                </TableHeaderColumn> 

              </BootstrapTable>
            </div>
          </div>
        </div>

        
        {/* Modal d'affichhage des feedbacks sur un livrable*/}

         
        <div className="modal fade" id="feedbacks" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="envoyerLivrableLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="modal-title" id="editTacheModalLabel">les commentaires de votre livrable</h4>
                    </div>
                    <form id="edit-tache-bycoach">
                        <div className="modal-body">
                       
                          {this.state.commentaires.map((index) => ( 
                            <div class="card">
                              <div class="row">
                                <div class="col-md-7">
                                  <p class="card-text">{index.commentaire}</p>
                                </div>
                                <div class="col-md-2">
                                  <p class="card-text">
                                    {index.isOk ? (
                                    
                                      <i className="fas fa-heart" style={{color:"red"}}></i>)
                                          :''}
                                  </p>
                                </div>
                                <div class="col-md-3">
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      color: "blue",
                                    }}
                                  >
                                    {index.created_at}
                                  </p>
                                </div>
                              </div>
                              <hr/>
                            </div>
                          ))}
                        </div>

                          
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger pull-left bsi-cancel-btn" data-dismiss="modal">
                                <i className="fas fa-times-circle"></i> &nbsp;
                                Quitter
                            </button>
                          
                        </div>
                    </form>
                </div>
            </div>
           
        </div>

                           
      </div>
    );
  }
}

export default Livraison;
