import React, {Component} from 'react';
import './accountValidation.scss'
//import Form from 'react-bootstrap/Form'
import axios from 'axios';
import API from '../../variablesGlobales';
export default class AccountValidation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventBakeliste: 1,
      id: 0,
      api: API.API,
      isPending: false,
      successValidation: false,
      showForm: true,
      fields: {
        event:'',
        password: '',
      },
     
      type:'hidden'
    };

    this.handleRadioChange = this.handleRadioChange.bind(this);
    /* this.handeleChangePassword = this.handeleChangePassword.bind(this); */
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 
componentDidMount() {
  let x = document.getElementById('page-wrapper');
  x.style.marginLeft = 0;
  x.style.backgroundColor = '#e8e8e8';
  let y = document.getElementById('body-page');
  y.style.backgroundColor = '#e8e8e8';
 
  /* const { match: { params } } = this.props;
  this.setState({
    id: params.id,
  })
  window.sessionStorage.setItem('userId', params.id); */
}
    handleRadioChange = (e) => {
      let value = e.target.value;
         if (value === 1) {
          this.setState({
            type: 'password',
          },()=>{//console.log('You have change the tyeof :', this.state.type);
          })
         }
         let fields = this.state.fields;
         fields[e.target.name] = e.target.value;
         this.setState({
          fields: fields,
        },()=>{//console.log('You have selected:', this.state.fields);
        })
    };
 
    handleSubmit(e) {
      e.preventDefault();
    this.setState({
      isPending: true
    })
    let user_id = window.sessionStorage.getItem('userId');
    // let event = this.state.fields;
    // let password = this.state.password;

   
    axios.post(this.state.api + 'confirmationCompte/' + user_id, this.state.fields)
      .then(response => {
        this.setState({
          isPending: false
        });

        if (response.data.success === true) {
          this.setState({
            successValidation: true,
            showForm: false
          },()=>{//console.log(response.data)
          });
        } else {
          console.log('Erreur lors de la modification')
        }
      })
    }

   

    render() {
      return (
        <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <div className="panel panel-login">
              <div className="panel-heading">
                <div className="row">
                  <div className="col-md-10">
                  <div className="logo_container">
                    <img className="img-responsive logo_bakeli" src={require('../../images/logo_bak.png')} alt="logo bakeli"/>
                  </div>
                  <h4 className="color1 connect-libelle">Voulez vous confirmer votre compte ?</h4>
                  </div>
                </div>
                <br/>
                <br/>

              </div>
              <div className="panel-body blog">
                <div className="row">
                  <div className="col-lg-12">
                    <form onSubmit={this.handleSubmit}>
                      <div className="radio-row">
                        <div className="input-row">
                          <input
                            type="radio"
                            name="event"
                            value={1}
                            onChange={this.handleRadioChange}
                          />
                          <label className="custom-control-label" htmlFor="validate">Oui je confirme mon compte bakeli</label>
                        </div><br/>
                        <div className="input-row password2">
                          <input 
                          type={this.state.type} 
                          name="password"
                          id="password2"
                          className="form-control"
                          placeholder="Modifier votre Mot de passe" 
                          value={this.state.password}
                          onChange={this.handleRadioChange}
                          />
                          </div>
                          <br/>
                        <div className="input-row">
                          <input
                            type="radio"
                            name="event"
                            value={0}
                            onChange={this.handleRadioChange}
                          />
                          <label className="custom-control-label" htmlFor="customRadio1">Non je ne veux pas un compte bakeli</label>
                        </div>
                        <br/><br/>
                          <div className="form-group">
                          {!this.state.isPending &&
                            <button className='btn btn-warning submit button'>
                              <i class="fas fa-edit"></i> &nbsp;
                              Confirmer
                          </button>
                          }
                          {this.state.isPending &&
                            <button className="btn btn-primary pendingLoginBtn button">
                              Confirmation en cours &nbsp;
                          <i class="fas fa-spinner fa-spin"></i>
                            </button>
                          }

                      </div>
                      </div>
                  </form>
         
        </div>
                      
      </div>
    </div>
   </div>
   </div>
  </div>
</div>                                     
      )
    }
  }

   
  
  