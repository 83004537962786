import React, {Component} from 'react';
import { Link, NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from '../menu/Menu';
import * as Sentry from '@sentry/react';
import $ from 'jquery';
import "../MenuBakeliste/Production.css";
 import amplitude from "amplitude-js";
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../demandeAbsence/demande.css';
import {
  Amplitude
} from "@amplitude/react-amplitude";
import { logToConsole } from 'pusher-js';
export default class SuiviDemande extends Component{
    data = [];
    static defaultProps = {
        required: true
    }
   
    constructor(props){
        super(props);
      

        this.state = {
            isPageLoading:false,
            isSendingDemandeInProgress: false,
            isSuccessSending: false,
            isErrorSending: false,
            api: API.API,
            userID: window.sessionStorage.getItem('user_id'),
            fields: {},
            utInProgress: false,
            demande: [],
            alldemande:[],
            demandeStatusUpdateField:'',
            demandeID: 0,
            demandeUpdatedInProgress: false,
            updateDemandeStatusError: false,
            fields:{}
        };
        this.getDemandeInfos = this.getDemandeInfos.bind(this);
        this.getDemandeID = this.getDemandeID.bind(this);
        this.updateDemandeByAdmin = this.updateDemandeByAdmin.bind(this);
        this.updateDBAHandle = this.updateDBAHandle.bind(this);

    }


    componentDidMount() {
        this.getDemande();
    }


   

  
    updateDBAHandle = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
        console.log(fields)
 
    }

    updateDemandeByAdmin = (e) => {
       e.preventDefault();
        this.setState({
            demandeUpdatedInProgress: true
        })
        let demandeID = window.sessionStorage.getItem('bdemande_id');


        //console.log(tacheID)
        let updateData = this.state.demandeStatusUpdateField;

        axios.post(this.state.api + 'update-status-demande-by-admin/' + demandeID, this.state.fields)
            .then(response => {
                if (response.data.success === true) {
                    this.getDemande();
                    $('.close').click();
                    document.getElementById('form_status').reset();
                    this.setState({
                        demandeUpdatedInProgress: false,
                        updateDemandeStatusSuccess: true
                    })

                    this.handleShowAndHideAlert('successStatusUpdate');
                    
                }else {
                    this.setState({
                        isUpdatedError: true,
                        demandeUpdatedInProgress: false,
                        updateDemandeStatusError: true
                    })

                    this.handleShowAndHideAlert('errorStatusUpdate');
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isUpdatedError: true,
                    demandeUpdatedInProgress: false,
                    updateDemandeStatusError: true
                })
                this.handleShowAndHideAlert('errorStatusUpdate');
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }
  
 

   

  
   getDemande = () =>{
    axios
      .get(this.state.api + "demande_absences/")
      .then((res) => {
        const demandes = res.data;
        this.setState({
          demande: demandes.data,
        });
        console.log(this.state.demande);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }
  actionsFormatter(cell, row) {
    return (
      <span>
        <button
          onClick={() => {
            this.getDemandeInfos(row);
          }}
          id="show_item"
          className="btn btn-info show-btn volkeno-green-bcolor"
          data-placement="top"
          title="Voir les infos du coach"
          data-toggle="modal"
          data-target="#showInfosDemande"
        >
          <i class="fas fa-eye"></i>
        </button>
        &nbsp;&nbsp;
        
      </span>
    );
  }
  getDemandeID = (demande) => {
    //console.log(tache.id)
    window.sessionStorage.setItem('bdemande_id', demande.id);
}
  onGetDemande(demande){
    console.log(demande.status, "status des demandes")
    this.setState({
        status: demande.status
       
    });
    
}

getDemandeInfos = (demande) => {
    this.setState(
      {
        DemandDebut: demande.date_debut,
        DemandeFin: demande.date_fin,
        DemandeMotif: demande.motif,
        DemandeContenu: demande.contenu,
        demandeID: demande.id,
        demandeFor: demande.bakeliste_name,
        bakID: demande.bakeliste_id,
      },
      () => {
      }
    );
    this.setState(
      {
      },
      () => {
      }
    );
  };

demandeStateFormatter(cell, row) {
    return (
        <span>
            {row.status === 'rejete' &&
                <span className="label label-danger" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getDemandeID(row)}>{row.status}</span>
            }
            {row.status === 'nouveau' &&
                <span className="label label-warning" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getDemandeID(row)}>{row.status}</span>
            }
            {row.status === 'accepte' &&
                <span className="label label-success" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getDemandeID(row)}>{row.status}</span>
            }
        </span>
    );
}
 
handleShowAndHideAlert(label) {
    setTimeout(() => {
        if(label === 'successStatusUpdate'){
            this.setState({
                updateTacheStatusSuccess: false
            })
        }
        
        if(label === 'errorStatusUpdate'){
            this.setState({
                isUpdatedError: false,
                updateDemandeStatusError: false
            })
        }
    }, 5000);
}

  
    render(){
        const {required} = this.props;
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton,
            btnGroup: this.createCustomButtonGroup,
      
          };
        if (this.state.isPageLoading) {
            return (
              <div className="component-manage-users">
                <Menu />
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
                <div className="row no-content-wrapper">
      
                  <section className="content-header">
                    <h1>
                    Demande d'absence
                    </h1>
                    <ol className="breadcrumb">
                      <li><Link to="/dashboard-admin">Accueil</Link></li>
                      <li className="active">Demander absence</li>
                    </ol>
                  </section>
      
                  <section className="content">
                    <div className="list_users_container">
                      <div id="loader"></div>
                    </div>
                  </section>
      
                </div>
              </div>
            );
          }
          return(
            <div className="component-manage-users">
                <Menu />
                <div className="row no-content-wrapper">
                    <section className="content-header" >
                        <h1>
                        Demande d'absence
                        </h1>
                        <ol className="breadcrumb">
                        <li><Link to="/dashboard-admin">Accueil</Link></li>
                        <li className="active">Demander absence</li>
                        </ol>
                    </section>
                   
                    <div className="row prospectw-table-row">
                        <div className="bakeli-ete-table-col">
                            <BootstrapTable data={this.state.demande}
                            striped={true} hover={true} condensed={true}
                            multiColumnSort={2} options={options}
                            pagination exportCSV>
                                <TableHeaderColumn dataField='id' isKey={true} hidden={true}> 
                                    ID
                                </TableHeaderColumn>
                                <TableHeaderColumn width="250" dataField='bakeliste_name'
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataSort={true}>Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataField='motif' filter={{ type: 'TextFilter', delay: 1000 }} width='100' dataSort={true} >
                                     Motif
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField='date_debut' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true} width='200' >
                                    Date de début
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField='date_fin' dataSort={true} width='200' filter={{ type: 'TextFilter', delay: 1000 }} >
                                    Date de fin
                                </TableHeaderColumn> 
                             <TableHeaderColumn dataField='status' dataFormat={this.demandeStateFormatter.bind(this)} dataSort={true} width='90' filter={{ type: 'TextFilter', delay: 1000 }} >
                                   Status
                                </TableHeaderColumn> 


                                <TableHeaderColumn  
                                    dataSort={true} 
                                    dataField='id' 
                                    width="70" 
                                    dataFormat={this.actionsFormatter.bind(this)} 
                                    >
                                    Actions
                                </TableHeaderColumn> 
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
               

        {/* Modal Afficher les infos d'une demande */}
                <div
                className="modal fade"
                id="showInfosDemande"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="showInfosDemande"
                >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        >
                        <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="modal-title" id="showInfosDemande">
                        Detail sur la demande &nbsp; {this.state.demandeFor}
                        </h4>
                    </div>
                    <div className="modal-body">
                        <div className="bakelisteInfos-container">

                        <div className="phone_container">
                            <span className="item_label">Motif : </span>{" "}
                            <span className="label_content">
                            {this.state.DemandeMotif}
                            </span>
                        </div>{" "}
                        <hr />
                        <div className="phone_container">
                            <span className="item_label">Contenu : </span>{" "}
                            <span className="label_content">
                            {this.state.DemandeContenu}
                            </span>
                        </div>{" "}
                        <hr />
                        <div className="durée_container">
                            <span className="item_label">Durée de l'absence : </span>{" "}
                            du{" "}
                            <span className="label_content">
                            {this.state.DemandDebut}
                            </span>{" "}
                            au{" "}
                            <span className="label_content">
                            {this.state.DemandeFin}
                            </span>
                        </div>{" "}
                        
                        <hr />
                        </div>
                        <div className="modal-footer"></div>
                    </div>
                    </div>
                </div>
                </div>
             {/* Update demande status by admin modal */}
             <div className="modal fade" id="utModal" tabIndex="-1" role="dialog" aria-labelledby="utModalLabel" data-keyboard = "false"
                  data-backdrop = "static">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="utModalLabel">Modifier le status d'une demande</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="ut_form_container">
                                                    <form id="form_status">
                                                        <div className="form-group " >
                                                            <select className="form-control" id="tstatus" name="status" onChange={(e) => this.updateDBAHandle(e)}>
                                                                <option>--Choisir un statut--</option>
                                                                <option value="accepte">Accepté</option>
                                                                <option value="rejete">Rejeté</option>
                                                            </select>
                                                        </div>
                                                        {this.state.updateDemandeStatusError &&
                                                            <div className="form-group alert alert-danger update-error">
                                                                Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                            </div>
                                                        }
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-danger pull-left bsi-cancel-btn" data-dismiss="modal">
                                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                                Quitter
                                                            </button>
                                                            {!this.state.demandeUpdatedInProgress &&
                                                                <button onClick={(e) => this.updateDemandeByAdmin(e)} type="submit" className="btn btn-primary bsi-submit-btn pull-right">
                                                                    <i className="fas fa-edit"></i> &nbsp;
                                                                    Modifier
                                                                </button>
                                                            }
                                                            {this.state.demandeUpdatedInProgress &&
                                                                <button type="button" className="btn btn-warning bsi-inprogress-btn pull-right">
                                                                    Modification en cours &nbsp;
                                                                    <i className="fas fa-spinner fa-spin"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="modal-footer">

                                            </div>
                                        </div>
                                    </div>
                                </div>
 
  
    </div>

            
        )
    }
}