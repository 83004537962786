/*eslint no-unused-expressions: "error"*/

import React, { Component } from "react";
import "./allBakelistesListe.css";
import Menu from "../menu/Menu";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import $ from "jquery";

class allBakelistesListe extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem("token");
    let userStatus = window.sessionStorage.getItem("user_status");

    if (!token) {
      window.history.back();
    }
    if (userStatus === "bakeliste" || userStatus === "coach") {
      window.history.back();
    }

    super(props);

    this.state = {
      user_id: window.sessionStorage.getItem("user_id"),
      bakeliste: [],
      domaines: [],
      fields: {},
      upFields: {},
      api: API.API,
      bakelisteID: 0,
      BakelisteFullname: "",
      BakelisteEmail: "",
      BakelisteDomaine: "",
      BakelisteEcole: "",
      BakelistePhone: "",
      BakelisteDebutFormation: "",
      BakelisteFinFormation: "",
      BakelistecoachFullname: "",
      emailValue: "",
      phoneValue: "",
      boursierValue: false,
      demiBoursierValue: false,
      adresseValue: "",
      ecoleValue: "",
      stageFormationValue: false,
      isISAContractValue: false,
      lieuFormationValue: "",
      horaireValue: "",
      paiementInscriptionValue: false,
      debutFormationValue: "",
      finFormationValue: "",
      firstNameValue: "",
      lastNameValue: "",
      loading: true,
      getBakelisteRequestError: false,
      isEmptyData: false,
      updateInProgress: false,
      successAlert: false,
      updateBakelisteRequestError: false,
    };
    this.getBakelisteInfos = this.getBakelisteInfos.bind(this);
    this.updateBakelisteInfos = this.updateBakelisteInfos.bind(this);
  }

  componentDidMount() {
    this.getListAllBakelistes();
    this.getAllDomaines();
  }
  getAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
        //console.log(this.state.domaines)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  getListAllBakelistes = () => {
    axios
      .get(this.state.api + "liste-tous-les-bakelistes")
      .then((res) => {
        //console.log('All Bakelistes', res.data.data)
        if (res.data.success === true) {
          this.setState({
            bakeliste: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
          //console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          getBakelisteRequestError: true,
        });
        console.log(error.message);
      });
  };

  getBakelisteInfos = (bakeliste) => {
    this.setState(
      {
        bakelisteID: bakeliste.id,
        BakelisteFullname: bakeliste.bakelisteFullname,
        BakelisteEmail: bakeliste.email,
        BakelisteDomaine: bakeliste.domaine,
        BakelisteEcole: bakeliste.ecole,
        BakelistePhone: bakeliste.phone,
        BakelistecoachFullname: bakeliste.coachFullname,
        BakelisteDebutFormation: bakeliste.startFrenchDateFormat,
        BakelisteFinFormation: bakeliste.endFrenchDateFormat,
      },
      () => {
        //console.log(this.state.BakelistecoachFullname)
      }
    );
    this.setState(
      {
        bakelisteIDForUpdate: bakeliste.id,
        bakelisteFullname: bakeliste.bakelisteFullname,
        emailValue: bakeliste.email,
        phoneValue: bakeliste.phone,
        boursierValue: bakeliste.boursier,
        demiBoursierValue: bakeliste.demi_boursier,
        adresseValue: bakeliste.address,
        ecoleValue: bakeliste.ecole,
        domaineFormationValue: bakeliste.bakeliste_domaine_id,
        stageFormationValue: bakeliste.is_stagede_formation,
        formationPayanteValue: bakeliste.is_formation_payante,
        isISAContractValue: bakeliste.is_isa_contract,
        lieuFormationValue: bakeliste.lieu_formation,
        nbreJoursValue: bakeliste.nbreJours,
        horaireValue: bakeliste.horaire,
        paiementInscriptionValue: bakeliste.paiement_inscription,
        debutFormationValue: bakeliste.debut,
        finFormationValue: bakeliste.fin,
        firstNameValue: bakeliste.first_name,
        lastNameValue: bakeliste.last_name,
        updateBakeliste: bakeliste,
      },
      () => {
       // console.log(this.state.updateBakeliste);
      }
    );
  };

  // Reaction au click sur createCustomClearButton
  handleClearButtonClick = (onClick) => {
    //console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };
  // Action pour effacer les données du champs de recherche
  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  actionsFormatter(cell, row) {
    return (
      <span>
        <button
          onClick={() => {
            this.getBakelisteInfos(row);
          }}
          id="show_item"
          className="btn btn-info show-btn volkeno-green-bcolor"
          data-placement="top"
          title="Voir les infos du coach"
          data-toggle="modal"
          data-target="#showInfosModal"
        >
          <i class="fas fa-eye"></i>
        </button>
        &nbsp;&nbsp;
        <button
          type="button"
          className="btn btn-warning si-tooltip"
          id="btn_update"
          data-toggle="modal"
          data-target="#editBakelisteModal"
          onClick={(e) => this.getBakelisteInfos(row, e)}
        >
          <i className="fas fa-edit"></i>
          {/* <span className="tooltiptext">Modifier les infos</span> */}
        </button>
      </span>
    );
  }

  typeFormationFormatter = (cell, row) => {
    if (cell === "courte") {
      return (
        <span className="label label-success volkeno-green-bcolor">
          {" "}
          formation courte{" "}
        </span>
      );
    } else if (cell === "diplomante") {
      return (
        <span className="label label-warning volkeno-orange-bcolor">
          {" "}
          formation diplômante{" "}
        </span>
      );
    }
  };

  typeDeStageFormatter = (cell, row) => {
    if (row.is_stagede_formation === 1) {
      return (
        <span className="label label-success volkeno-green-bcolor">
          {" "}
          en stage de formation{" "}
        </span>
      );
    } else if (row.is_isa_contract === 1) {
      return (
        <span className="label label-warning volkeno-green-bcolor">
          {" "}
          sous contra ISA{" "}
        </span>
      );
    } else if (row.is_formation_payante === 1) {
      return (
        <span className="label label-danger volkeno-green-bcolor">
          {" "}
          formation payante{" "}
        </span>
      );
    } else {
      return (
        <span className="label label-info volkeno-green-bcolor">
          {" "}
          type de stage non trouvé{" "}
        </span>
      );
    }
  };
  updateHandleChange = (e) => {
    let fields = this.state.upFields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      this.setState({
        emailValue: e.target.value,
      });
    }
    if (e.target.name === "phone") {
      this.setState({
        phoneValue: e.target.value,
      });
    }
    if (e.target.name === "adresse") {
      this.setState({
        adresseValue: e.target.value,
      });
    }
    if (e.target.name === "ecole") {
      this.setState({
        ecoleValue: e.target.value,
      });
    }
    if (e.target.name === "boursier") {
      this.setState({
        boursierValue: e.target.value,
      });
    }
    if (e.target.name === "demi_boursier") {
      this.setState({
        demiBoursierValue: e.target.value,
      });
    }
    if (e.target.name === "is_stagede_formation") {
      this.setState({
        stageFormationValue: e.target.value,
      });
    }
    if (e.target.name === "is_formation_payante") {
      this.setState({
        formationPayanteValue: e.target.value,
      });
    }
    if (e.target.name === "is_isa_contract") {
      this.setState({
        isISAContractValue: e.target.value,
      });
    }
    if (e.target.name === "lieu_formation") {
      this.setState({
        lieuFormationValue: e.target.value,
      });
    }
    if (e.target.name === "horaire") {
      this.setState({
        horaireValue: e.target.value,
      });
    }
    if (e.target.name === "bakeliste_domaine_id") {
      this.setState({
        domaineFormationValue: e.target.value,
      });
    }
    if (e.target.name === "paiement_inscription") {
      this.setState({
        paiementInscriptionValue: e.target.value,
      });
    }
    if (e.target.name === "debut_formation") {
      this.setState({
        debutFormationValue: e.target.value,
      });
    }
    if (e.target.name === "fin_formation") {
      this.setState({
        finFormationValue: e.target.value,
      });
    }
    if (e.target.name === "first_name") {
      this.setState({
        firstNameValue: e.target.value,
      });
    }
    if (e.target.name === "last_name") {
      this.setState({
        lastNameValue: e.target.value,
      });
    }

    if (!fields["first_name"]) {
      fields["first_name"] = this.state.firstNameValue;
    }
    if (!fields["last_name"]) {
      fields["last_name"] = this.state.lastNameValue;
    }
    if (!fields["email"]) {
      fields["email"] = this.state.emailValue;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.phoneValue;
    }
    if (!fields["adresse"]) {
      fields["adresse"] = this.state.adresseValue;
    }
    if (!fields["ecole"]) {
      fields["ecole"] = this.state.ecoleValue;
    }
    if (!fields["boursier"]) {
      fields["boursier"] = this.state.updateBakeliste.boursier;
    }
    if (!fields["demi_boursier"]) {
      fields["demi_boursier"] = this.state.demiBoursierValue;
    }
    if (!fields["is_stagede_formation"]) {
      fields["is_stagede_formation"] = this.state.stageFormationValue;
    }
    if (!fields["is_formation_payante"]) {
      fields["is_formation_payante"] = this.state.formationPayanteValue;
    }
    if (!fields["is_isa_contract"]) {
      fields["is_isa_contract"] = this.state.isISAContractValue;
    }
    if (!fields["lieu_formation"]) {
      fields["lieu_formation"] = this.state.lieuFormationValue;
    }
    if (!fields["horaire"]) {
      fields["horaire"] = this.state.horaireValue;
    }
    if (!fields["bakeliste_domaine_id"]) {
      fields["bakeliste_domaine_id"] = this.state.domaineFormationValue;
    }
    if (!fields["paiement_inscription"]) {
      fields["paiement_inscription"] = this.state.paiementInscriptionValue;
    }
    if (!fields["debut_formation"]) {
      fields["debut_formation"] = this.state.debutFormationValue;
    }
    if (!fields["fin_formation"]) {
      fields["fin_formation"] = this.state.finFormationValue;
    }
    this.setState({
      upFields: fields,
    });

    console.log(fields);
  };
  updateBakelisteInfos = (e) => {
    e.preventDefault();
   // console.log(this.state.upFields);
    this.setState({
      updateInProgress: true,
    });
    let bakelisteID = this.state.bakelisteIDForUpdate;
    let data = this.state.upFields;
    // let admin_id = window.sessionStorage.getItem('user_id');
    let historique = {};
    historique["bakeliste_id"] = bakelisteID;
    historique["admin_id"] = this.state.user_id;
    historique["debut_date"] = this.state.debutFormationValue;
    historique["fin_date"] = this.state.finFormationValue;
    historique["text"] =
      "la formation de " +
      this.state.firstNameValue +
      " " +
      this.state.lastNameValue +
      " à été modifié";

    // data['admin_id'] =  admin_id
    axios
      .put(this.state.api + "update-bakeliste-infos/" + bakelisteID, data)
      .then((response) => {
        this.setState({
          updateInProgress: false,
        });
        //console.log(response.data.data)
        /* let  histo = this.historique(historique);
                    console.log('historique',histo) */
        if (response.data.success === true) {
          // window.location = 'liste-bakelistes';
          //this.getBakelisteEnFormation();
          $(".close").click();
         // console.log("click");
          $("body").removeClass("modal-open");
         // console.log("open");
          $(".modal-backdrop").removeClass("modal-backdrop");
          //console.log("backdrop");
          $(".fade").removeClass("fade");
          $(".in").removeClass("in");
          this.setState({
            successAlert: true,
            upFields: {},
          });
          this.handleShowAndHideAlert("closeSuccessAlert");
        } else if (
          response.data.success === false &&
          response.data.exist === true
        ) {
          this.setState({
            isExistUser: true,
          });
          this.handleShowAndHideAlert("isExistUser");
        } else {
          //console.log(response);
          this.setState({
            updateBakelisteRequestError: true,
          });
          this.handleShowAndHideAlert("closeErrorAlert");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateInProgress: false,
          updateBakelisteRequestError: true,
        });
        this.handleShowAndHideAlert("closeErrorAlert");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "closeModal") {
        this.setState({
          isClosingModal: true,
        });
      }
      if (label === "closeSuccessAlert") {
        this.setState({
          successAlert: false,
        });
      }
      if (label === "closeErrorAlert") {
        this.setState({
          updateBakelisteRequestError: false,
        });
      }
      if (label === "successHired") {
        this.setState({
          successHiredAlert: false,
        });
      }
      if (label === "errorHired") {
        this.setState({
          errorHiredAlert: false,
        });
      }
      if (label === "successCompletedFormation") {
        this.setState({
          successCompletedFormationAlert: false,
        });
      }
      if (label === "errorCompletedFormation") {
        this.setState({
          completedFormationRequestError: false,
        });
      }
      if (label === "successImmersionAlert") {
        this.setState({
          successImmersionAlert: false,
        });
      }
      if (label === "errorImmersionAlert") {
        this.setState({
          errorImmersionAlert: false,
        });
      }
      if (label === "assignationSuccessAlert") {
        this.setState({
          assignationSuccessAlert: false,
        });
      }
      if (label === "assignationErrorAlert") {
        this.setState({
          assignationErrorAlert: false,
        });
      }
      if (label === "successRecruitable") {
        this.setState({
          successRecruitableAlert: false,
        });
      }
      if (label === "errorRecruitable") {
        this.setState({
          isRecruitableRequestError: false,
        });
      }
      if (label === "isExistUser") {
        this.setState({
          isExistUser: false,
        });
      }
    }, 5000);
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
    };
    if (this.state.loading) {
      return (
        <div>
          <Menu />
          <div className="row">
            <div className="row ">
              <div className="col-lg-12">
                <h1 className="page-header">
                  <span className="page-title">Bakelistes de Volkeno</span>
                  <div className="pull-right">
                    <ol className="breadcrumb">
                      <li>
                        <Link to="/dashboard-admin">Accueil</Link>
                      </li>
                      <li className="active">Liste de tous les bakelistes</li>
                    </ol>
                  </div>
                </h1>
              </div>
            </div>
            <div className="col-md-12 reporting-container">
              <div className="bakeliste_container">
                <div className="table_container">
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Nom du Bakeliste</th>
                        <th scope="col">Domaine</th>
                        <th scope="col">Ecole</th>
                        <th scope="col">Telephone</th>
                        <th scope="col">Coach</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="dashboard-container">
        <Menu />

        <div className="row ">
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">Bakelistes de Volkeno</span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard-admin">Accueil</Link>
                  </li>
                  <li className="active">Liste de tous les bakelistes</li>
                </ol>
              </div>
            </h1>
          </div>
        </div>

        <div className="col-md-12">
          <BootstrapTable
            data={this.state.bakeliste}
            striped={true}
            hover={true}
            condensed={true}
            search={true}
            scrollTop={"Bottom"}
            pagination
            options={options}
            exportCSV
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              {" "}
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="bakelisteFullname"
              filter={{ type: "TextFilter", delay: 1000 }}
              dataSort={true}
            >
              {" "}
              Nom du bakeliste{" "}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="domaine"
              filter={{ type: "TextFilter", delay: 1000 }}
            >
              Domaine{" "}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="type_formation"
              dataFormat={this.typeFormationFormatter.bind(this)}
              filter={{ type: "TextFilter", delay: 1000 }}
              dataSort={true}
            >
              {" "}
              Type de formation{" "}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.typeDeStageFormatter.bind(this)}
              filter={{ type: "TextFilter", delay: 1000 }}
              dataSort={true}
            >
              {" "}
              Type de stage{" "}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="ecole"
              filter={{ type: "TextFilter", delay: 1000 }}
            >
              Ecole{" "}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="phone"
              filter={{ type: "TextFilter", delay: 1000 }}
            >
              Téléphone{" "}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="coachFullname"
              filter={{ type: "TextFilter", delay: 1000 }}
            >
              Coach{" "}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionsFormatter.bind(this)}
            >
              Actions
            </TableHeaderColumn>
          </BootstrapTable>

          {this.state.getBakelisteRequestError && (
            <div className="row empty_container">
              <div className="col-md-12 empty_content">
                <div className="alert alert-danger danger-alerty">
                  Un problème est survenu lors de la recupération des données.
                  Veuillez réessayer plus tard. Si le probléme persite, veuillez
                  contacter un administrateur du site en envoyant en email à
                  <b> contact@bakeli.tech</b>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Modal Afficher les infos du Bakeliste */}
        <div
          className="modal fade"
          id="showInfosModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="showInfosModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showInfosModalLabel">
                  Detail sur {this.state.BakelisteFullname}
                </h4>
              </div>
              <div className="modal-body">
                <div className="bakelisteInfos-container">
                  <div className="fullname_container">
                    <span className="item_label">Prénom et Nom : </span>{" "}
                    <span className="label_content">
                      {this.state.BakelisteFullname}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="email_container">
                    <span className="item_label">Email : </span>{" "}
                    <span className="label_content">
                      {this.state.BakelisteEmail}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="phone_container">
                    <span className="item_label">Ecole : </span>{" "}
                    <span className="label_content">
                      {this.state.BakelisteEcole}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="phone_container">
                    <span className="item_label">Téléphone : </span>{" "}
                    <span className="label_content">
                      {this.state.BakelistePhone}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="domaine_container">
                    <span className="item_label">Domaine de compétence : </span>{" "}
                    <span className="label_content">
                      {this.state.BakelisteDomaine}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="durée_container">
                    <span className="item_label">Durée de la formation : </span>{" "}
                    du{" "}
                    <span className="label_content">
                      {this.state.BakelisteDebutFormation}
                    </span>{" "}
                    au{" "}
                    <span className="label_content">
                      {this.state.BakelisteFinFormation}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="durée_container">
                    <span className="item_label">Coach : </span>{" "}
                    <span className="label_content">
                      {this.state.BakelistecoachFullname}
                    </span>
                  </div>{" "}
                  <hr />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
        {/* edit bakeliste */}
        <div
          className="modal fade"
          id="editBakelisteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editBakelisteModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="editBakelisteModalLabel">
                  Modifier : {this.state.bakelisteFullname}
                </h4>
              </div>
              <div className="modal-body">
                <div className="edit-bakeliste-container">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Prénom:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputFN"
                        name="first_name"
                        value={this.state.firstNameValue}
                        onChange={this.updateHandleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Nom de famille:</label>
                      <input
                        type="last_name"
                        className="form-control"
                        id="inputLN"
                        name="last_name"
                        value={this.state.lastNameValue}
                        onChange={this.updateHandleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Email:</label>
                      <input
                        type="Email"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Email"
                        name="email"
                        value={this.state.emailValue}
                        onChange={this.updateHandleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Téléphone:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputNumero"
                        placeholder="Téléphone"
                        name="phone"
                        value={this.state.phoneValue}
                        onChange={this.updateHandleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Adresse:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputAdresse"
                        placeholder="Adresse"
                        name="adresse"
                        value={this.state.adresseValue}
                        onChange={this.updateHandleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>École:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputEcole"
                        placeholder="Ecole"
                        name="ecole"
                        value={this.state.ecoleValue}
                        onChange={this.updateHandleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Boursier:</label>
                      <select
                        className="form-control"
                        id="inputBoursier"
                        name="boursier"
                        value={this.state.boursierValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Demi-Boursier:</label>
                      <select
                        className="form-control"
                        id="inputDBoursier"
                        name="demi_boursier"
                        value={this.state.demiBoursierValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Formation payante :</label>
                      <select
                        className="form-control"
                        id="inputFormationP"
                        name="is_formation_payante"
                        value={this.state.formationPayanteValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Stage de formation :</label>
                      <select
                        className="form-control"
                        id="inputSFormation"
                        name="is_stagede_formation"
                        value={this.state.stageFormationValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Contrat ISA :</label>
                      <select
                        className="form-control"
                        id="inputISA"
                        name="is_isa_contract"
                        value={this.state.isISAContractValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Horaire de formation</label>
                      <select
                        className="form-control"
                        id="horaire"
                        name="horaire"
                        value={this.state.horaireValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir un horaire-- </option>
                        <option value="8h-17h">8h - 17h</option>
                        <option value="8h-12h30">8h - 12h30</option>
                        <option value="12h30-17h">12h30 - 17h</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Lieu de formation:</label>
                      <select
                        className="form-control"
                        id="inputlieu_formation"
                        name="lieu_formation"
                        value={this.state.lieuFormationValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir un lieu-- </option>
                        <option value="Sicap Foire">Sicap Foire</option>
                        <option value="Grand Yoff 1">Grand Yoff 1</option>
                        <option value="Grand Yoff 2">Grand Yoff 2</option>
                        <option value="Thiés">Thiés</option>
                        <option value="Labé">Labé (Guinée)</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Domaine de formation:</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="bakeliste_domaine_id"
                        value={this.state.domaineFormationValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir un domaine-- </option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Paiement a l'inscription:</label>
                      <select
                        className="form-control"
                        id="inputpaiement_inscription"
                        name="paiement_inscription"
                        value={this.state.paiementInscriptionValue}
                        onChange={this.updateHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Date de début:</label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputdebut_formation"
                        name="debut_formation"
                        value={this.state.debutFormationValue}
                        onChange={this.updateHandleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Date de fin:</label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputfin_formation"
                        name="fin_formation"
                        value={this.state.finFormationValue}
                        onChange={this.updateHandleChange}
                      />
                    </div>
                  </div>

                  {this.state.updateBakelisteRequestError && (
                    <div className="col-md-12 alert alert-danger updateBakelisteError">
                      Un problème est survenu lors de la modification. Veuillez
                      réessayer plus tard.
                    </div>
                  )}
                  {this.state.isExistUser && (
                    <div className="alert alert-danger text-center">
                      Cet utilisateur avec ce mail existe déjà dans le système.{" "}
                      <br />
                      Merci de le réintégrer !
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <div className="row sq-btn-container">
                    <div className="col-md-12 sq-btn-col">
                      <button
                        type="button"
                        className="btn btn-default cancel_btn cancel button"
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i> &nbsp; Quitter
                      </button>
                      {!this.state.updateInProgress && (
                        <button
                          type="button"
                          className="btn btn-primary btn_for_assignation button addBtn"
                          onClick={(e) => this.updateBakelisteInfos(e)}
                        >
                          <i className="fas fa-edit"></i>&nbsp; Modifier
                        </button>
                      )}
                      {this.state.updateInProgress && (
                        <button
                          type="button"
                          className="btn btn-warning button addBtn btn_for_wait"
                        >
                          Modification en cours &nbsp;
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default allBakelistesListe;
