import React, { Component } from 'react';
import './recruitablesBakelistesViewByAdmin.css'
import Menu from '../menu/Menu';
import API from '../../variablesGlobales';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import '../../../node_modules/admin-lte/dist/js/adminlte.js';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import * as Sentry from '@sentry/browser';

export default class recruitablesBakelistesViewByAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      bakelistes: [],
      isPageLoading: false,
      isRequestError: false,
      bakelisteInfos: [],

    };
  }

  componentDidMount = () => {
    this.getRecruitablesBakelistes();
  }

  componentDidCatch(err, info){
    Sentry.captureException(err);
  }

  getRecruitablesBakelistes = () => {
    this.setState({
      isPageLoading: true
    });

    axios.get(this.state.api + 'liste-bakelistes-recrutables')
      .then(response => {
        this.setState({
          isPageLoading: false
        });
        if (response.data.success) {
          this.setState({
            bakelistes: response.data.data,
            isPageLoading: false
          });
        } else {
          console.log(response)
          this.setState({
            isRequestError: true,
            isPageLoading: false
          });
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isRequestError: true,
          isPageLoading: false
        })
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  getBakelisteInfos = (bakeliste) => {
    this.setState({
      bakelisteInfos: bakeliste
    })
  }

  bakelisteMoreInfosAction = (cell, row) => {
    return (
      <span className="more-infos-linky" data-toggle="modal" data-target="#moreInfosModal" onClick={(e) => this.getBakelisteInfos(row, e)}> {cell} </span>
    )
  }

  actionsFormatter = (cell, row) => {
    return(
      <div className="actions-btn-container">
        <NavLink to={'/show-bakeliste-cv/' + row.id + '/admin-view'} data-toggle="tooltip" data-placement="top" title="Voir le CV" type="button" className="btn btn-info showcv" id="btn_show_cv">
            <i class="fas fa-list"></i>
        </NavLink>
      </div>
    )
  }

  typeFormationFormatter = (cell, row) => {
    if (cell === 'courte') {
      return (
        <span className="label label-success volkeno-green-bcolor"> formation courte </span>
      );
    } else if (cell === 'diplomante') {
      return (
        <span className="label label-warning volkeno-orange-bcolor"> formation diplômante </span>
      );
    }
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  /**
     * Training since formatter
     */
  inTrainingSinceFormatter = (cell, row) => {
    if (cell < 1) {
      return (
        <span class="label label-success volkeno-green-bcolor"> {row.inTrainingSinceDays} jour(s) </span>
      )
    } else {
      return (
        <span class="label label-success volkeno-orange-bgcolor"> {row.inTrainingSinceMonths} mois </span>
      )
    }
  }

  coachRowFormatter = (cell, row) => {
    return (
      <div className="coach_row actions-btn-container">
        <Link to="#">
          {cell}
        </Link>
      </div>
    );
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };
    if (this.state.isPageLoading) {
      return (
        <div className="component-recruitables-bakelistes-view-by-admin">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Bakelistes recrutables
              </h1>
              <ol class="breadcrumb">
                <li><NavLink to="dashboard-admin"><i class="fa fa-dashboard"></i> Home</NavLink></li>
                <li className="active">Bakelistes recrutables</li>
              </ol>
            </section>

            <section className="content">
              <div className="list_bakeliste_container">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Prénom</th>
                        <th scope="col">Nom</th>
                        <th scope="col">Domaines</th>
                        <th scope="col">Coach</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }

    return (
      <div className="component-recruitables-bakelistes-view-by-admin">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
          Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>
              Bakelistes recrutables
            </h1>
            <ol class="breadcrumb">
              <li><NavLink to="dashboard-admin"><i class="fa fa-dashboard"></i> Home</NavLink></li>
              <li className="active">Bakelistes recrutables</li>
            </ol>
          </section>
          <div className="row tab-data-row">
                    <div className="col-md-12 tab-data-col">
                      <div className="col-md-12 reporting-container">
                        <div className="list_prospect_container">
                          <div className="row prospect-row-row">
                            <div className="col-md-12 prospect-col">
                              <div className="nav-tabs-custom">
                                <div className="col-md-12 da-list-col">
                                  <ul className="nav nav-tabs">
                                   
                                    <li className=""><NavLink to="/liste-entreprises-partenaires">Entreprises partenaires</NavLink></li>
                                    <li className=""><NavLink to="/demandes-placement-directes">Demandes entreprises</NavLink></li>
                                    <li className=""><NavLink to="/liste-bakelistes-en-immersion">B. Immersion</NavLink></li>
                                    <li className=""><NavLink to="/liste-des-bakelistes-en-poste">Placements</NavLink></li>
                                    <li className="active"><NavLink to="/liste-bakelistes-recrutables">B.Recrutable</NavLink></li>
     
      
                                    {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                                   </ul>
                                </div>
                                
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
      
                      </div>
                    </div>     
          <section className="content">
            {/* TODO */}
            <div className="recruitables-bakeliste-table-container">
              <BootstrapTable data={this.state.bakelistes}
                striped={true} hover={true} condensed={true}
                multiColumnSort={2} options={options}
                pagination exportCSV
                search={true}>
                <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                <TableHeaderColumn dataField='first_name'
                  width="300" filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}
                  dataFormat={this.bakelisteMoreInfosAction.bind(this)} >Prénom</TableHeaderColumn>
                <TableHeaderColumn dataField='last_name'
                  width="300" filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}
                  dataFormat={this.bakelisteMoreInfosAction.bind(this)} >Nom</TableHeaderColumn>
                <TableHeaderColumn dataField='email' width="250" >Email</TableHeaderColumn>
                <TableHeaderColumn dataField='phone' width="200" >Téléphone</TableHeaderColumn>
                <TableHeaderColumn dataField='type_formation' width="200"
                  dataFormat={this.typeFormationFormatter.bind(this)}
                  filter={{ type: 'TextFilter', delay: 1000 }}
                  dataSort={true}>Type de formation</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                  width='300'
                  dataField='domaine'
                  filter={{ type: 'TextFilter', delay: 1000 }}
                  dataSort={true}>Domaine</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                  width='300'
                  dataField='inTrainingSinceMonths'
                  dataFormat={this.inTrainingSinceFormatter}
                  filter={{ type: 'TextFilter', delay: 1000 }}
                  dataSort={true}>En formation depuis</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                  width='250'
                  dataField='coachFullname'
                  dataFormat={this.coachRowFormatter.bind(this)}
                  filter={{ type: 'TextFilter', delay: 1000 }}
                  dataSort={true}>Coach</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='horaire'
                  filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Horaire</TableHeaderColumn>
                <TableHeaderColumn dataField='joursPresence'
                  filter={{ type: 'TextFilter', delay: 1000 }} width="300" >Jours de présence</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='frenchDateFormat'>Date et Heure</TableHeaderColumn> */}
                <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
              </BootstrapTable>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
