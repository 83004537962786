import React, { Component } from 'react';
import './manageUsers.css';
import { Link, NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';

export default class manageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: false,
      api: API.API,
      users: [],
      fields: {},
      successAccessPointMeAlert: false,
      errorAccessPointMeRequest: false,
      user: [],
      changeAccessInProgress: false,

    };

    this.handleAccessChange = this.handleAccessChange.bind(this);
    this.submitAccessPMRequest = this.submitAccessPMRequest.bind(this);
  }

  componentDidMount = () => {
    this.onGetUsersList();
  }

  onGetUsersList = () => {
    this.setState({
      isPageLoading: true
    })
    var api = this.state.api;

    axios.get(api + 'list-admin-coach')
      .then(response => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            users: response.data.data
          })
        } else {
          this.setState({
            isPageLoading: false
          })
          console.log(response)
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isPageLoading: false
        })
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  onGetUserInfos = (user) => {
    this.setState({
      user: user
    })
  }

  handleAccessChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields
    })
    console.log(fields)
  }

  submitAccessPMRequest = (e) => {
    this.setState({
      changeAccessInProgress: true
    })

    var userID = this.state.user.id;
    var data = this.state.fields;
    var baseApi = this.state.api;

    axios.post(baseApi + 'can-access-pointme/' + userID, data)
      .then(response => {
        this.setState({
          changeAccessInProgress: false
        })

        if (response.data.success) {
          this.onGetUsersList();
          //$('#pointme-access-form')[0].reset();
          $('.close').click();
          $('.modal-backdrop').removeClass('modal-backdrop');
          $('.fade').removeClass('fade')
          $('.in').removeClass('in')
          this.setState({
            successAccessPointMeAlert: true,
            fields: {}
          })
          this.handleShowAndHideAlert('successAccessPointMeAlert')
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          changeAccessInProgress: false,
          errorAccessPointMeRequest: true
        })
        this.handleShowAndHideAlert('errorAccessPointMeRequest')
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === 'successAccessPointMeAlert') {
        this.setState({
          successAccessPointMeAlert: false
        });
      }
      if (label === 'errorAccessPointMeRequest') {
        this.setState({
          errorAccessPointMeRequest: false
        });
      }
    }, 5000);
  }


  // actionsFormatter = (cell, row) => {

  // }

  userFullnameFormatter = (cell, row) => {
    return (
      <span data-toggle="tooltip" data-placement="top" title="Voir plus" type="button" className="userFullname" id="uFullname">
        {row.first_name + ' ' + row.last_name}
      </span>
    )
  }

  statusFormatter = (cell, row) => {
    if (cell === 'admin') {
      return (
        <span className="label label-success label-vgreen"> {cell} </span>
      )
    }
    if (cell === 'coach') {
      return (
        <span className="label label-warning"> {cell} </span>
      )
    }
  }

  canAccessPointMeFormatter = (cell, row) => {
    if (cell === 1) {
      return (
        <span className="label label-success label-vgreen access_ok" data-toggle="modal" data-target="#postAccessModal" onClick={(e) => this.onGetUserInfos(row, e)}> <i className="fas fa-check"></i> </span>
      )
    } else {
      return (
        <span className="label label-danger bg-vred access_nok" data-toggle="modal" data-target="#postAccessModal" onClick={(e) => this.onGetUserInfos(row, e)}> <i className="fas fa-ban"></i> </span>
      )
    }
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
            className={ `btn btn-primary volkeno-red-bcolor` }>
            MyCustomBtn
          </button> */}
      </ButtonGroup>
    );
  }




  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-manage-users">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Users Manager
              </h1>
              <ol class="breadcrumb">
                <li><Link to="/dashboard-admin">Accueil</Link></li>
                <li className="active">Users Manager</li>
              </ol>
            </section>

            <section className="content">
              <div className="list_users_container">
                <div id="loader"></div>
              </div>
            </section>

          </div>
        </div>
      );
    }

    return (
      <div className="component-manage-users">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
          Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>
              Users Manager
            </h1>
            <ol class="breadcrumb">
              <li><Link to="/dashboard-admin">Accueil</Link></li>
              <li className="active">Users Manager</li>
            </ol>
          </section>
 
          <section className="content">
            <div className="list_users_container">
              <div className="table_container">
                <BootstrapTable data={this.state.users}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  pagination
                  search={true}>

                  <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                  <TableHeaderColumn 
                    width='200'
                    dataField='last_name'
                    dataFormat={this.userFullnameFormatter.bind(this)}
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true} >Prénom et Nom</TableHeaderColumn>
                  <TableHeaderColumn 
                    width='150'
                    dataField='email'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true} >Email</TableHeaderColumn>
                  <TableHeaderColumn dataAlign="center"
                    width='100'
                    dataField='status'
                    dataFormat={this.statusFormatter.bind(this)}
                    filter={{ type: 'TextFilter', delay: 1000 }}
                  >Statuts</TableHeaderColumn>
                  <TableHeaderColumn dataAlign="center"
                    width='100'
                    dataField='can_access_pointme'
                    dataFormat={this.canAccessPointMeFormatter.bind(this)}
                    filter={{ type: 'TextFilter', delay: 1000 }}
                  >Pointme Access</TableHeaderColumn>

                </BootstrapTable>
              </div>
            </div>
          </section>

        </div>

        <div className="modal fade" id="postAccessModal" tabIndex="-1" role="dialog" aria-labelledby="postAccessModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="postAccessModalLabel"> {this.state.user.first_name + ' ' + this.state.user.last_name} </h4>
              </div>
              <form id="pointme-access-form">
                <div className="modal-body">
                  {this.state.user.can_access_pointme === 1 ?
                    <div className="row pm_rowy">
                      <div className="col-md-12 pm_coly">
                        <div className="alert alert-success bg-vgreen">
                          <i className="fas fa-check"></i> Accès activé sur POINTme
                      </div>
                      </div>
                    </div> :
                    <div className="row pm_rowy">
                      <div className="col-md-12 pm_coly">
                        <div className="alert alert-danger bg-vred">
                          <i className="fas fa-ban"></i> Accès non activé sur POINTme
                      </div>
                      </div>
                    </div>
                  }
                  <div className="row pm_form_rowy">
                    <div className="col-md-12 pm_input_coly">
                      <div className="form-group">
                        <select className="form-control" name="can_access_pointme" onChange={this.handleAccessChange}>
                          <option> --Choisir un état-- </option>
                          <option value={1}> Activé l'accès </option>
                          <option value={0}> Désactivé l'accès </option>
                        </select>
                      </div>
                    </div>
                    {this.state.errorAccessPointMeRequest &&
                      <div className="col-md-12 ri-error-alert-container">
                        <div className="alert alert-danger alerty-content">
                          Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                  {!this.state.changeAccessInProgress &&
                    <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.submitAccessPMRequest(e)}>
                      <i className="fas fa-user-check"></i>&nbsp;
                      Modifier
                    </button>
                  }
                  {this.state.changeAccessInProgress &&
                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                      Modification en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Success access form alert */}
        {this.state.successAccessPointMeAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              La modification a été effectuée avec succès.
            </div>
        }
      </div>
    );
  }
}