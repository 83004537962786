import React, { Component } from 'react';
import './domaineFormation.css';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import API from '../../variablesGlobales';
import $ from 'jquery';


export default class domaineFormation extends Component {
    constructor(props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeid = this.onChangeid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.updateDomaine = this.updateDomaine.bind(this);
        this.getEditModal = this.getEditModal.bind(this);
        this.openAddModal = this.openAddModal.bind(this);
        this.validatedForm = this.validatedForm.bind(this);
        this.handleUpdateDomaineStatus = this.handleUpdateDomaineStatus.bind(this);
        this.handleChangeUpdateDomaineStatus = this.handleChangeUpdateDomaineStatus.bind(this);

        this.state = {
            id: '',
            name: '',
            description: '',
            api: API.API,
            domaines: [],
            fields: {},
            isnotname_empty: false,
            isnotdescription_empty: false,
            loading: true,
            errors: {},
            isAddingRequestError: false,
            isUpdateError: false,
            addDomaineInProgress: false,
            updateDomaineInProgress: false,
            openModal: true,
            addingSuccessAlert: false,
            updateSuccessAlert: false,
            updateDomaineStatusInProgress: false,
            isUpdateStatusError: false,
            domaine_id: 0,
            domaine_status: null,
            domaineInfos: [],

        };
    }

    componentDidMount() {
        this.getAllDomaines();
    }

    getAllDomaines = () => {
        axios.get(this.state.api + 'domaines')
            .then(res => {
                const domains = res.data;
                this.setState({
                    domaines: domains.data.reverse(),
                });
                console.log(this.state.domaines)
            });
    }


    handleShowAndHideAlert = (label) => {
        setTimeout(() => {
            if (label === 'name') {
                this.setState({
                    isnotname_empty: false
                });
            }
            if (label === 'description') {
                this.setState({
                    isnotdescription_empty: false
                });
            }
            if(label === 'closeAddSuccessAlert'){
                this.setState({
                    addingSuccessAlert: false
                })
            }
            if(label === 'closeUpdateSuccessAlert'){
                this.setState({
                    updateSuccessAlert: false
                })
            }
            if(label === 'updateStatusSuccessAlert'){
                this.setState({
                    updateStatusSuccessAlert: false
                })
            }
            if(label === 'isUpdateStatusError'){
                this.setState({
                    isUpdateStatusError: false
                })
            }
        }, 5000);
    }
    validatedForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["name"]) {
            formIsValid = false;
            this.setState({
                isnotname_empty: true
            })
            this.handleShowAndHideAlert("name");
            errors["name"] = "*Ce champ est obligatoire";
        }

        // if (!fields["description"]) {
        //     formIsValid = false;
        //     this.setState({
        //         isnotdescription_empty: true
        //     })
        //     this.handleShowAndHideAlert("description");
        //     errors["description"] = "*Ce champ est obligatoire";
        // }
        this.setState({
            errors: errors
        });
        return formIsValid;

    }


    onChangeName(e) {
        this.setState({
            name: e.target.value,
        });
        console.log(this.state.name)
    }
    onChangeDescription(e) {
        this.setState({
            description: e.target.value,
        });
        console.log(this.state.description)

    }
    onChangeid(e) {
        this.setState({
            id: e.target.value,
        });
    }

    getEditModal = (domaine) => {
        console.log('domaine infos ', domaine);
        this.setState({
            isUpdateError: false
        })
        this.setState({
            name: domaine.name,
            description: domaine.description,
            id: domaine.id
        })
    }

    updateDomaine(e) {
        e.preventDefault(e);
        this.setState({
            updateDomaineInProgress: true
        })
        const newDomaine = {
            name: this.state.name,
            description: this.state.description,
            id: this.state.id
        }
        console.log(newDomaine)
        axios.put(this.state.api + 'domaines/' + newDomaine.id, newDomaine)
            .then(res => {
                console.log(res);
                console.log(res.data);
                if (res.data.success === true) {
                    this.getAllDomaines();
                    $('.close').click();

                    this.setState({
                        updateSuccessAlert: true,
                        updateDomaineInProgress: false
                    })

                    this.handleShowAndHideAlert('closeUpdateSuccessAlert');
                } else {
                    this.setState({
                        updateDomaineInProgress: false,
                        isUpdateError: true
                    })
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    updateDomaineInProgress: false,
                    isUpdateError: true
                })
            })
        this.setState({
            name: '',
            description: ''
        })
    }

    onSubmit(e) {
        e.preventDefault(e);
        this.setState({
            addDomaineInProgress: true
        })
        const newDomaine = {
            name: this.state.name,
            description: this.state.description
        };
        console.log(newDomaine);
        axios.post(this.state.api + 'domaines', newDomaine)
            .then(res => {
                if(res.data.success){
                    this.getAllDomaines();
                    $('.close').click();

                    this.setState({
                        addDomaineInProgress: false,
                        addingSuccessAlert: true
                    })
                    this.handleShowAndHideAlert('closeAddSuccessAlert');
                    document.getElementById('addModalForm').reset();
                }else{
                    this.setState({
                        addDomaineInProgress: false,
                        isAddingRequestError: true
                    })
                }
            })
            .catch(error => {
                console.log(error.message);
                this.setState({
                    addDomaineInProgress: false,
                    isAddingRequestError: true
                })
            })
        this.setState({
            name: '',
            description: ''
        })
    }

    actionsFormatter = (cell, row) => {
        return (
            <div className="actions-btn-container">
                <button type="button" className="btn btn-warning edit-btn" id="btn_update" data-toggle="modal" data-target="#editModal" onClick={(e) => this.getEditModal(row, e)}>
                    <i className="fas fa-edit"></i>
                </button>
                {/* <button data-toggle="tooltip" data-placement="top" title="Archiver" type="button" className="btn btn-danger" id="btn_update" data-toggle="modal" data-target="#">
                    <i className="fas fa-archive"></i>
                </button> */}
            </div>
        );
    }

    descriptionFormatter = (cell, row) => {
        if(cell === null){
            return (
                <div className="description-format">
                    Aucune description
                </div>
            );
        }else{
            return (
                <div className="description-format">
                    {cell}
                </div>
            );
        }
    }

    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    openAddModal = () => {
        if(this.state.openModal){
            // this.setState({
            //     isAddingRequestError: false
            // })
        }
    }

    statusFormatter = (cell, row) => {
        if(cell === 1){
            return(
                <span data-toggle="modal" data-target="#updateStatusModal" className="label label-success domaine_status" onClick={(e) => this.onUpdateDomaineStatus(row, 'archivé')}> actif </span>
            )
        }else if(cell === 0){
            return(
                <span data-toggle="modal" data-target="#updateStatusModal" className="label label-danger domaine_status" onClick={(e) => this.onUpdateDomaineStatus(row, 'activé')}> archivé </span>
            )
        }
    }

    onUpdateDomaineStatus = (item, label) => {
        this.setState({
            domaineInfos: item,
            label: label,
            domaine_status: item.status
        })
        console.log(label)
    }

    handleChangeUpdateDomaineStatus = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;

        if(e.target.name === 'status'){
            if(e.target.value === 1){
                this.setState({
                    label: 'activé'
                })
            }else if(e.target.value === 0){
                this.setState({
                    label: 'archivé'
                })
            }
        }
        this.setState({
            fields,
            domaine_status: e.target.value
        })
        console.log(fields)
    }

    handleUpdateDomaineStatus = (e) => {
        e.preventDefault();
        this.setState({
            updateDomaineStatusInProgress: true
        })

        let domaineID = this.state.domaineInfos.id;
        let label = this.state.label;

        axios.post(this.state.api + 'enable-disable-domaine-status/' + domaineID + '/' + label)
            .then(response => {
                this.setState({
                    updateDomaineStatusInProgress: false
                })
                if(response.data.success){
                    this.getAllDomaines();
                    $('.close').click();
                    this.setState({
                        updateStatusSuccessAlert: true
                    })
                    this.handleShowAndHideAlert('updateStatusSuccessAlert')
                }else{
                    console.log(response)
                    this.setState({
                        isUpdateStatusError: true
                    })
                    this.handleShowAndHideAlert('isUpdateStatusError')
                }
            })
            .catch(error => {
                console.log(error.message)
                    this.setState({
                        updateDomaineStatusInProgress: false,
                        isUpdateStatusError: true
                    })
                    this.handleShowAndHideAlert('isUpdateStatusError')
            })
    }
    

    render() {
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton
        };

        return <div className="component-domaine-formation">
            <Menu />
            <div className="col-lg-12">
                <h1 className="page-header">
                    <span className="page-title">Liste des domaines de formation</span>
                    <div className="pull-right">
                        <ol className="breadcrumb">
                            <li>
                                <NavLink to="/dashboard-admin">Accueil</NavLink>
                            </li>
                            <li className="active">Domaine de formation</li>
                        </ol>
                    </div>
                </h1>
            </div>

            <div className="col-md-12 reporting-container">
                <div className="row add_coach_btn_row">
                    <div className="col-md-6 col-md-offset-3 add-coach-btn-container">
                        <button onClick={this.openAddModal()} to="#" className="btn btn-success add_coach_btn button button addBtn" data-toggle="modal" data-target="#addModal">
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>
                <div className="bakeliste_container">
                    <div className="table_container">
                        <BootstrapTable data={this.state.domaines}
                            striped={true} hover={true} condensed={true}
                            multiColumnSort={2} options={options}
                            pagination
                            search={true}>
                            <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='name' width="300" >Domaines</TableHeaderColumn>
                            <TableHeaderColumn dataField='description' width="350" dataFormat={this.descriptionFormatter.bind(this)}>Description</TableHeaderColumn>
                            <TableHeaderColumn dataField='status' width="100" dataFormat={this.statusFormatter.bind(this)}>Statut</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' width="250" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="addModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="myModalLabel2">Ajoutez une domaine de formation</h4>
                        </div>
                        <div className="modal-body">

                            <form onSubmit={this.handledomaineFormation} id="addModalForm">
                                <div className="form-row">

                                    <div className="form-group ">
                                        <label >Nom:</label>
                                        <input type="text"
                                            className="form-control"
                                            id="inputLastName"
                                            placeholder="Nom"
                                            name="name"
                                            required="required"
                                            onChange={this.onChangeName} />
                                    </div>

                                    <div className="form-group " >
                                        <label >Description:</label>
                                        <textarea className="form-control"
                                            id="inputpFirstName"
                                            placeholder="decription"
                                            name="description"
                                            row="5"
                                            onChange={this.onChangeDescription} />

                                    </div>
                                </div>
                                <div className="row">
                                <div className="form-actions-btn col-md-12">
                                        <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                            <i className="fas fa-times-circle"></i> &nbsp;
                                            Quitter
                                            </button>
                                        {!this.state.addDomaineInProgress &&
                                            <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={this.onSubmit}>
                                                <i className="fas fa-plus-circle"></i>&nbsp;
                                                Ajouter
                                                </button>
                                        }
                                        {this.state.addDomaineInProgress &&
                                            <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                Ajout en cours &nbsp;
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </form>
                            <div className="modal-footer">
                                {this.state.isAddingRequestError &&
                                    <div className="alert alert-danger error-alert">
                                        Un problème est survenu lors de l'ajout. Veuillez réessayer plus tard.
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit muodal */}

            <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="myModalLabel2">Modifier la domaine de formation</h4>
                        </div>
                        <div className="modal-body">

                            <form onSubmit={this.handledomaineFormation} id="updateDomaineModal">
                                <div className="form-row">

                                    <div className="form-group ">
                                        <label >Nom:</label>
                                        <input type="text"
                                            className="form-control"
                                            id="inputLastName"
                                            placeholder="Nom"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.onChangeName} />
                                    </div>


                                    <div className="form-group " >
                                        <label >Description:</label>
                                        <textarea className="form-control"
                                            id="inputpFirstName"
                                            placeholder="description"
                                            name="commentaires"
                                            value={this.state.description}
                                            onChange={this.onChangeDescription} />

                                    </div>
                                </div>

                                <div className="row">
                                <div className="form-actions-btn col-md-12">
                                        <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                            <i className="fas fa-times-circle"></i> &nbsp;
                                            Quitter
                                            </button>
                                        {!this.state.updateDomaineInProgress &&
                                            <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={this.updateDomaine}>
                                                <i className="fas fa-edit"></i>&nbsp;
                                                Modifier
                                                </button>
                                        }
                                        {this.state.updateDomaineInProgress &&
                                            <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                Modification en cours &nbsp;
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </button>
                                        }
                                    </div>
                                </div>

                            </form>
                            <div className="modal-footer">
                                {this.state.isUpdateError &&
                                    <div className="alert alert-danger error-alert text-center">
                                        Un problème est survenu lors de l'ajout. Veuillez réessayer plus tard.
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Update domaine status */}
            <div className="modal fade" id="updateStatusModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="myModalLabel2">Modifier la domaine de formation</h4>
                        </div>
                        <div className="modal-body">

                            <form onSubmit={this.handleUpdateDomaineStatus} id="updateDomaineStatusForm">
                                <div className="form-row">

                                    <div className="form-group ">
                                        <label>Statut :</label>
                                        <select name="status" className="form-control" onChange={this.handleChangeUpdateDomaineStatus} value={this.state.domaine_status}>
                                            <option> --Choisir une option-- </option>
                                            <option value={1}> Activer </option>
                                            <option value={0}> Archiver </option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                <div className="form-actions-btn col-md-12">
                                        <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                            <i className="fas fa-times-circle"></i> &nbsp;
                                            Quitter
                                            </button>
                                        {!this.state.updateDomaineStatusInProgress &&
                                            <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={this.handleUpdateDomaineStatus}>
                                                <i className="fas fa-edit"></i>&nbsp;
                                                Modifier
                                                </button>
                                        }
                                        {this.state.updateDomaineStatusInProgress &&
                                            <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                Modification en cours &nbsp;
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </button>
                                        }
                                    </div>
                                </div>

                            </form>
                            <div className="modal-footer">
                                {this.state.isUpdateStatusError &&
                                    <div className="alert alert-danger error-alert text-center">
                                        Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {this.state.addingSuccessAlert === true &&
                <div className="alert alert-success text-center upsuccess-alert">
                    Domaine ajouté avec succès.
                </div>
            }

            {this.state.updateSuccessAlert === true &&
                <div className="alert alert-success text-center upsuccess-alert">
                    Domaine modifié avec succès.
                </div>
            }

            {this.state.updateStatusSuccessAlert === true &&
                <div className="alert alert-success text-center upsuccess-alert">
                    Statut domaine modifié avec succès.
                </div>
            }

        </div>;

    }
}
//   export default connect(
//       ({ domaineFormation }) => ({ ...domaineFormation }),
//       dispatch => bindActionCreators({ ...domaineFormationActions }, dispatch)
//     )( domaineFormation );