import React, { Component } from 'react';
import './entreprisesPartenaires.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import $ from 'jquery';


export default class entreprisesPartenaires extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem('token');
    let userStatus = window.sessionStorage.getItem('user_status');

    if (!token) {
        window.history.back()
    }
    if (userStatus === 'bakeliste' || userStatus === 'coach') {
        window.history.back();
    }
    super(props);
    this.state = {
      userID: window.sessionStorage.getItem('user_id'),
      token: window.sessionStorage.getItem('token'),
      userStatus: window.sessionStorage.getItem('user_status'),
      api: API.API,
      isPageLoading: true,
      isGetCompaniesRequestError: false,
      companies: [],
      companyInfos: [],
      addCompanyInProgress: false,
      fields: {},
      errors: {},
      isCompanyNameEmpty: false,
      isFirstNameEmpty: false,
      isLastNameEmpty: false,
      isPhoneEmpty: false,
      isEmailEmpty: false,
      addCompanyRequestError: false,
      successAddingCompanyAlert: false,

    };

    this.handleAddCompanyChange = this.handleAddCompanyChange.bind(this);
    this.addNewCompany = this.addNewCompany.bind(this);
  }

  componentDidMount() {
    this.getCompaniesList();
  }

  /**
   * Liste des entreprises partenaires
   */
  getCompaniesList = () => {
    this.setState({
      isPageLoading: true
    })
    axios.get(this.state.api + 'companies')
      .then(response => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            companies: response.data.data
          })
        } else {
          this.setState({
            isPageLoading: false,
            isGetCompaniesRequestError: true
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isPageLoading: false,
          isGetCompaniesRequestError: true
        })
      })
  }

  flnameFormatter = (cell, row) => {
    return (
      <span className="fullname-link" data-toggle="modal" data-target="#showDetailsModal" onClick={(e) => this.getCompanyInfos(row, e)}>{cell}</span>
    );
  }

  actionsFormatter = (cell, row) => {
    return(
      <div className="actions-btn-container">
        <button onClick={() => { this.getCompanyInfos(row) }} id="show_item" className="btn btn-info show-btn volkeno-green-bcolor" data-toggle="modal" data-target="#showDetailsModal">
            <i className="fas fa-eye"></i>
        </button>
      </div>
    )
  }

  getCompanyInfos = (company) => {
    this.setState({
      companyInfos: company
    })
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  /**
   * Handle hide alert
   */
  handleShowAndHideAlert(input){
    setTimeout(() => {
      if(input === 'companyNameAlert'){
        this.setState({
          isCompanyNameEmpty: false
        })
      }
      if(input === 'firstNameAlert'){
        this.setState({
          isFirstNameEmpty: false
        })
      }
      if(input === 'lastNameAlert'){
        this.setState({
          isLastNameEmpty: false
        })
      }
      if(input === 'phoneAlert'){
        this.setState({
          isPhoneEmpty: false
        })
      }
      if(input === 'emailAlert'){
        this.setState({
          isEmailEmpty: false
        })
      }
      if(input === 'successAddingCompanyAlert'){
        this.setState({
          successAddingCompanyAlert: false
        })
      }
      if(input === 'addCompanyRequestError'){
        this.setState({
          addCompanyRequestError: false
        })
      }
      
    }, 5000);
  }

  /**
   * Handle change add company form
   */
  handleAddCompanyChange = (e) => {
    let fields = this.state.fields;
    fields['admin_id'] = this.state.userID;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields
    })
    console.log(fields);
  }

  addCompanyValidationForm = () => {
    let fields = this.state.fields;
    let isValidForm = true;
    let errors = this.state.errors;

    if(!fields['company_name']){
      isValidForm = false;
      this.setState({
        isCompanyNameEmpty: true
      })
      this.handleShowAndHideAlert('companyNameAlert');
      errors["company_name_empty"] = "Le nom de l'entreprise est obligatoire";
    }
    if(!fields['agent_firstName']){
      isValidForm = false;
      this.setState({
        isFirstNameEmpty: true
      })
      this.handleShowAndHideAlert('firstNameAlert');
      errors["first_name_empty"] = "Le prénom est obligatoire";
    }
    if(!fields['agent_lastName']){
      isValidForm = false;
      this.setState({
        isLastNameEmpty: true
      })
      this.handleShowAndHideAlert('lastNameEmpty');
      errors["last_name_empty"] = "Le nom est obligatoire";
    }
    if(!fields['phone']){
      isValidForm = false;
      this.setState({
        isPhoneEmpty: true
      })
      this.handleShowAndHideAlert('phoneAlert');
      errors["phone_empty"] = "Le téléphone est obligatoire";
    }
    if(!fields['email']){
      isValidForm = false;
      this.setState({
        isEmailEmpty: true
      })
      this.handleShowAndHideAlert('emailAlert');
      errors["email_empty"] = "L'email est obligatoire";
    }

    this.setState({
      errors: errors
    })

    return isValidForm;
  }

  /**
   * Submit add company form
   */
  addNewCompany = (e) => {
    e.preventDefault();
    this.setState({
      addCompanyInProgress: true
    })

    if(this.addCompanyValidationForm()){
      var data = this.state.fields;
      axios.post(this.state.api + 'companies', data)
        .then(response => {
          if(response.data.success){
            this.getCompaniesList();
            $('.close').click();
            console.log('click')
            $('body').removeClass('modal-open');
            console.log('open')
            $('.modal-backdrop').removeClass('modal-backdrop');
            console.log('backdrop')
            $('.fade').removeClass('fade')
            $('.in').removeClass('in')
            //document.getElementById('add_company_form').reset();
            this.setState({
              addCompanyInProgress: false,
              successAddingCompanyAlert: true
            })
            this.handleShowAndHideAlert('successAddingCompanyAlert');
          }else{
            console.log(response)
            this.setState({
              addCompanyRequestError: true,
              addCompanyInProgress: false
            })
          this.handleShowAndHideAlert('addCompanyRequestError');
          }
        })
        .catch(error => {
          console.log(error.message)
          this.setState({
            addCompanyRequestError: true,
            addCompanyInProgress: false
          })
          this.handleShowAndHideAlert('addCompanyRequestError');
        })
    }else{
      this.setState({
        addCompanyInProgress: false
      })
    }
  }



  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-entreprises-partenaires">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Entreprises partenaires
              </h1>
              <ol className="breadcrumb">
                <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                <li className="active">Entreprises partenaires</li>
              </ol>
            </section>

            <section className="content">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th>Entreprise</th>
                      <th scope="col">Prénom et Nom</th>
                      <th scope="col">Téléphone</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <div id="loader"></div>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>);
    }

    return (
      <div className="component-entreprises-partenaires">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>
              Entreprises partenaires
            </h1>
            <ol className="breadcrumb">
              <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Home</NavLink></li>
              <li className="active">Entreprises partenaires</li>
            </ol>
          </section>

          <section className="content">
            <div className="companies_container">
              <div className="row add_company_container">
                <div className="col-md-6 col-md-offset-3 add-company-btn-container">
                  <button data-toggle="modal" data-target="#addCompanyModal" className="btn btn-success add_coach_btn button">
                    <i className="fas fa-plus-circle"></i>
                  </button>
                </div>
              </div>
              <div className="table_container">
                <BootstrapTable data={this.state.companies}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  exportCSV
                  pagination
                  search={true}>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='company_name' dataSort={true} filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.flnameFormatter.bind(this)}>Entreprise</TableHeaderColumn>
                  <TableHeaderColumn dataField='agent_firstName' dataSort={true} filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.flnameFormatter.bind(this)} >Prenom</TableHeaderColumn>
                  <TableHeaderColumn dataField='agent_lastName' dataSort={true} filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.flnameFormatter.bind(this)} >Nom</TableHeaderColumn>
                  <TableHeaderColumn dataField='phone'>Téléphone</TableHeaderColumn>
                  <TableHeaderColumn dataField='email' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Email</TableHeaderColumn>
                  <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)}>Actions</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          </section>
          
          {/* Add company modal */}
          <div className="modal fade" id="addCompanyModal" tabindex="-1" role="dialog" aria-labelledby="addCompanyModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="addCompanyModalLabel">Ajouter une entreprise</h4>
                </div>
                <form id="add_company_form">
                  <div className="modal-body">
                    <div className="row add-company-row">
                      <div className="col-md-12">
                        <div className="col-md-6 form-group ac-input-container">
                          <label>Nom de l'entreprise</label>
                          <input type="text" name="company_name" className="form-control ac-input" placeholder="Nom de l'entreprise" onChange={this.handleAddCompanyChange} />
                          {this.state.isCompanyNameEmpty &&
                            <div className="errorMsg">{this.state.errors.company_name_empty}</div>
                          }
                        </div>
                        <div className="col-md-6 form-group ac-input-container">
                          <label>Prénom du représentant</label>
                          <input type="text" name="agent_firstName" className="form-control ac-input" placeholder="Prénom du représentant" onChange={this.handleAddCompanyChange} />
                          {this.state.isFirstNameEmpty &&
                            <div className="errorMsg">{this.state.errors.first_name_empty}</div>
                          }
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-6 form-group ac-input-container">
                          <label>Nom de famille du représentant</label>
                          <input type="text" name="agent_lastName" className="form-control ac-input" placeholder="Nom de famille du représentant" onChange={this.handleAddCompanyChange} />
                          {this.state.isLastNameEmpty &&
                            <div className="errorMsg">{this.state.errors.last_name_empty}</div>
                          }
                        </div>
                        <div className="col-md-6 form-group ac-input-container">
                          <label>Téléphone</label>
                          <input type="text" name="phone" className="form-control ac-input" placeholder="Téléphone" onChange={this.handleAddCompanyChange} />
                          {this.state.isPhoneEmpty &&
                            <div className="errorMsg">{this.state.errors.phone_empty}</div>
                          }
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="col-md-12 form-group ac-input-container">
                          <label>Email</label>
                          <input type="text" name="email" className="form-control ac-input" placeholder="Email" onChange={this.handleAddCompanyChange} />
                          {this.state.isEmailEmpty &&
                            <div className="errorMsg">{this.state.errors.email_empty}</div>
                          }
                        </div>
                        <div className="col-md-12 form-group ac-input-container">
                          <label>Description de l'entreprise</label>
                          <textarea name="description" className="form-control ac-input" placeholder="Description de l'entreprise" onChange={this.handleAddCompanyChange} />
                        </div>
                      </div>

                      {this.state.addCompanyRequestError &&
                      <div className="col-md-12 add_request_error_container">
                        <div className="alert alert-danger add_request_error">
                          Un probléme est survenu lors de l'ajout. Veuillez réessayer plus tard.
                        </div>
                      </div>
                      }
                    </div>

                    
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger cancel_btn cancel button" data-dismiss="modal">
                      <i className="fas fa-times-circle"></i> &nbsp;
                      Quitter
                      </button>
                    {!this.state.addCompanyInProgress &&
                      <button type="button" id="ass" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.addNewCompany(e)}>
                        <i className="fas fa-plus"></i>&nbsp;
                        Ajouter
                      </button>
                    }
                    {this.state.addCompanyInProgress &&
                      <button type="button" className="btn btn-warning bsi-inprogress-btn">
                        Ajout en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                      </button>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Show company infos modal */}
          <div className="modal fade" id="showDetailsModal" tabindex="-1" role="dialog" aria-labelledby="showDetailsModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="showDetailsModalLabel">Détails de {this.state.companyInfos.company_name}</h4>
                </div>
                  <div className="modal-body">
                    <div className="row show-company-row">
                      <div className="col-md-12 infos_item_container">
                          <span className="item_label">Prénom et Nom du représentant : <br />
                          </span> <span className="label_content">{this.state.companyInfos.agent_firstName + ' ' + this.state.companyInfos.agent_lastName}</span>
                          <hr />
                      </div> 
                      <div className="col-md-12 infos_item_container">
                          <span className="item_label">Email : <br />
                          </span> <span className="label_content">{this.state.companyInfos.email}</span>
                          <hr />
                      </div>
                      <div className="col-md-12 infos_item_container">
                          <span className="item_label">Téléphone : <br />
                          </span> <span className="label_content">{this.state.companyInfos.phone}</span>
                          <hr />
                      </div>
                      <div className="col-md-12 infos_item_container">
                          <span className="item_label">Description : <br /> </span> 
                          {this.state.companyInfos.description !== null ?
                            <span className="label_content">{this.state.companyInfos.description}</span> :
                            <span className="label label-warning"> Aucune description sur cette entreprise </span>
                          }
                          <hr />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger cancel_btn cancel button" data-dismiss="modal">
                      <i className="fas fa-times-circle"></i> &nbsp;
                      Quitter
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </div>

        {/* Success completed formation toast alert */}
        {this.state.successAddingCompanyAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              L'entreprise a été ajoutée avec succès.
          </div>
        }
      </div>
    );
  }
}
