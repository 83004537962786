import React, { Component } from 'react';
import './hiredBakelistes.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import $ from 'jquery';

export default class hiredBakelistes extends Component {
  constructor(props) {
    super(props);
    this.handleHireCompleted = this.handleHireCompleted.bind(this);

    this.state = {
      isPageLoading: false,
      api: API.API,
      hiredBakelistes: [],
      dataPlacement: [],
      hiredCompletedInProgress: false,
      successCompleteHireAlert: false,
      isCompletedHireRequestError: false
    };
  }

  componentDidMount() {
    this.getHiredBakelistes();
  }

  handleShowAndHideAlert(label){
    setTimeout(() => {
        if(label === 'completedHireSuccess'){
            this.setState({
              successCompleteHireAlert: false
            });
        }
    }, 5000);
  }

  getHiredBakelistes = () => {
    this.setState({
      isPageLoading: true
    });

    axios.get(this.state.api + 'placements')
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            isPageLoading: false,
            hiredBakelistes: response.data.data.reverse()
          });
        }
      })
      .catch(error => {
        this.setState({
          isPageLoading: false
        });
        console.log(error)
      })
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  getPlacementInfos = (bakeliste) => {
    this.setState({
      dataPlacement: bakeliste
    })
  }

  handleHireCompleted = (placement) => {
    this.setState({
      hireCompletedInProgress: true
    });

    let placementID = placement.id;
    let bakelisteID = placement.bakeliste_id;

    axios.post(this.state.api + 'complete-bakeliste-placements/' + placementID + '/' + bakelisteID)
      .then(response => {
        if (response.data.success === true) {
          this.getHiredBakelistes();
          $('.close').click();
          this.setState({
            hiredCompletedInProgress: false,
            successCompleteHireAlert: true
          });

          this.handleShowAndHideAlert('completedHireSuccess');
        } else {
          this.setState({
            isCompletedHireRequestError: true
          });
        }
      })
      .catch(error => {
        this.setState({
          isCompletedHireRequestError: true
        });
        console.log(error);
      })
  }

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button type="button" className="btn btn-warning" id="btn_update" data-toggle="modal" data-target="#showHiredModal" onClick={(e) => this.getPlacementInfos(row, e)}>
          <i className="fas fa-eye"></i>
        </button>
        <button type="button" className="btn btn-danger terminer-formation" id="completed"
          onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter le placement de ce bakeliste ?") &&
            this.handleHireCompleted(row)}
        >
          {this.state.hiredCompletedInProgress ?
            <i className="fas fa-spin fa-spinner"></i> :
            <i className="fas fa-stop-circle"></i>
          }

        </button>
      </div>
    );
  }

  fullnameFormatter = (cell, row) => {
    return (
      <span className="fullname-link" data-toggle="modal" data-target="#showHiredModal" onClick={(e) => this.getPlacementInfos(row, e)}>
        {cell}
      </span>
    );
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      noDataText: 'Aucune donnée pour le moment'
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-hired-bakelistes">
          <Menu />
          <div className="hired-bakelistes row" >
            <div className="col-lg-12">
              <h1 className="page-header">
                <span className="page-title">Bakelistes placés</span>
                <div className="pull-right">
                  <ol className="breadcrumb">
                    <li>
                      <NavLink to="/dashboard-admin">Accueil</NavLink>
                    </li>
                    <li className="active">Liste des bakelistes placés</li>
                  </ol>
                </div>
              </h1>
            </div>

            <div className="col-md-12 hiredBakelistes-container">
              <div className="row">
                <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                  {/* Empty */}
                </div>
              </div>
              <div className="list_hiredBakeliste_container">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Prénom et Nom</th>
                        <th scope="col">Entreprise</th>
                        <th scope="col">Date de début</th>
                        <th scope="col">Date de fin</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>

      )
    }

    return (
      <div className="component-hired-bakelistes">
        <Menu />
        <div className="row hiredBakelisteRow">
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">Bakelistes placés</span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <NavLink to="/dashboard-admin">Accueil</NavLink>
                  </li>
                  <li className="active">Liste des bakelistes placés</li>
                </ol>
              </div>
            </h1>
          </div>
{/* section navlink */}
<div className="row tab-data-row">
                    <div className="col-md-12 tab-data-col">
                      <div className="col-md-12 reporting-container">
                        <div className="list_prospect_container">
                          <div className="row prospect-row-row">
                            <div className="col-md-12 prospect-col">
                              <div className="nav-tabs-custom">
                                <div className="col-md-12 da-list-col">
                                  <ul className="nav nav-tabs">
                                   
                                    <li className=""><NavLink to="/liste-entreprises-partenaires">Entreprises partenaires</NavLink></li>
                                    <li className=""><NavLink to="/demandes-placement-directes">Demandes entreprises</NavLink></li>
                                    <li className=""><NavLink to="/liste-bakelistes-en-immersion">B. Immersion</NavLink></li>
                                    <li className="active"><NavLink to="/liste-des-bakelistes-en-poste">Placements</NavLink></li>
                                    <li className=""><NavLink to="/liste-bakelistes-recrutables">B.Recrutable</NavLink></li>
     
      
                                    {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                                   </ul>
                                </div>
                                
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
      
                      </div>
                    </div> 
          <div className="col-md-12 hiredBakelistesContainer">
            <div className="hiredBakelisteTable">
              <div className="table-container">
                <BootstrapTable data={this.state.hiredBakelistes}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  pagination
                  search={true}>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='bakelisteFullname' dataFormat={this.fullnameFormatter.bind(this)} >Nom complet</TableHeaderColumn>
                  <TableHeaderColumn dataField='company_name'>Nom de l'entreprise</TableHeaderColumn>
                  <TableHeaderColumn dataField='startFrenchDateFormat'>Date de début</TableHeaderColumn>
                  <TableHeaderColumn dataField='endFrenchDateFormat'>Date de fin</TableHeaderColumn>
                  <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="showHiredModal" tabindex="-1" role="dialog" aria-labelledby="showHiredModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showHiredModalLabel">Detail sur {this.state.dataPlacement.bakelisteFullname}</h4>
              </div>
              <div className="modal-body">
                <div className="company_container">
                  <span className="item_label">Recruteur : </span> <span className="label_content">{this.state.dataPlacement.company_name}</span>
                </div> <hr />
                <div className="email_container">
                  <span className="item_label">Email : </span> <span className="label_content">{this.state.dataPlacement.bakelisteEmail}</span>
                </div> <hr />
                <div className="phone_container">
                  <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.dataPlacement.bakelistePhone}</span>
                </div> <hr />
                <div className="debut_container">
                  <span className="item_label">Début du placement : </span> <br />
                  <span className="label_content">{this.state.dataPlacement.startFrenchDateFormat}</span>
                </div> <hr />
                <div className="fin_container">
                  <span className="item_label">Fin du placement : </span> <br />
                  <span className="label_content">{this.state.dataPlacement.endFrenchDateFormat}</span>
                </div> <hr />
              </div>
              <div className="modal-footer">

              </div>
            </div>
          </div>
        </div>

        {this.state.successCompleteHireAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            Modification effectuée avec succès.
          </div>
        }
      </div>
    );
  }
}
