import React, { Component } from 'react';
import Menu from './../menu/Menu'
import { Link, NavLink } from 'react-router-dom';
//import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import API from '../../variablesGlobales';
import axios from 'axios';
import { MagicSpinner } from "react-spinners-kit";


class Meetings extends Component {
    data = [];
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.DisabledhandleChange = this.DisabledhandleChange.bind(this);
        this.state = {
            isPageLoading: false,
            api: API.API,
            fields: {},
            isAdmin: false,
            rencontres: [],
            detail_rencontres: [],
            addMeetingInProgress: false,
            isAddMeetingRequestError: false,
            slugValue: '',
            meetingValue: '',
            event_id: -1,
        }
    }

    componentDidMount() {
        this.getRencontres();
    }

    getRencontres = () => {
        this.setState({
            isPageLoading: true
        })
        axios.get(this.state.api + 'rencontres')
            .then((res) => {
                let rencontres = res.data.data
                this.setState({
                    rencontres,
                    isPageLoading: false
                })
                //this.state.detail_rencontres = this.state.rencontres
                this.setState({
                    detail_rencontres: this.state.rencontres
                })

                console.log(this.state.detail_rencontres)
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    isPageLoading: false
                })
            })
    }

    onRemoveAccents = (strAccents) => {
        var _strAccents = strAccents.split('');
        var strAccentsOut = [];
        var strAccentsLen = _strAccents.length;
        var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
        var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
        for (var y = 0; y < strAccentsLen; y++) {
            if (accents.indexOf(_strAccents[y]) !== -1) {
                strAccentsOut[y] = accentsOut.substr(accents.indexOf(_strAccents[y]), 1);
            } else {
                strAccentsOut[y] = _strAccents[y];
            }
        }
        strAccentsOut = strAccentsOut.join('');
        console.log(strAccentsOut);
        return strAccentsOut;
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if (e.target.name === 'rencontre_type') {
            this.setState({ meetingValue: e.target.value });
        }


        console.log("handle change", e.target.value)

        if (e.target.name === 'rencontre_name') {
            var rencontreName = e.target.value.toLowerCase();
            var rnWithoutAccent = this.onRemoveAccents(rencontreName)
            this.setState({
                slugValue: rnWithoutAccent.replace(/\s/g, "-")
            })
            fields["rencontre_slug"] = this.state.slugValue;
        }
        fields["is_completed"] = 0;
        this.setState({
            fields
        });
        console.log("handle change", this.state.fields)
    }

    // Handle change disable action
    DisabledhandleChange(e) {

        let rencontreID = e.target;
        let id = rencontreID.options[rencontreID.selectedIndex].getAttribute('data-id');

        console.log("handle change", e.target.value)

        this.setState({
            event_id: id,
        });
        console.log("handle change", this.state.fields)

    }

    addMeetings = (e) => {
        e.preventDefault();

        this.setState({
            addMeetingInProgress: true
        });

        const data = this.state.fields;
        console.log(data);

        axios.post(this.state.api + 'rencontres', data)
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        addMeetingInProgress: false
                    });
                    //window.location.reload();
                }

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    addMeetingInProgress: false,
                    isAddMeetingRequestError: true
                })
            })
    }

    // Disable rencontre action
    DisabledMeetings = (e) => {
        e.preventDefault();
        //console.log(this.state.event_id)

        axios.post(this.state.api + 'disable-meeting/' + this.state.event_id)
            .then(res => {
                //console.log(res);
                if (res.status === 200) {
                    this.setState({
                        addMeetingInProgress: false
                    });
                    window.location.reload();
                }

            }).catch(error => {
                console.log(error);
                this.setState({
                    addMeetingInProgress: false,
                    isAddMeetingRequestError: true
                })
            })

    }



    getRencontreInfos = (rencontre) => {
        window.sessionStorage.setItem('rencontreIDForRedirect', rencontre.id)
        window.sessionStorage.setItem('rencontreName', rencontre.rencontre_name)
        window.sessionStorage.setItem('rencontreType', rencontre.rencontre_type)
        // window.location = "participants-rencontre";
    };

    render() {
        if (this.state.isPageLoading) {
            return (
                <div className="component-meeting">
                    {/* <Menu /> */}
                    {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
                    <div className="row no-content-wrapper">

                        <section className="content-header">
                            <h1>Gestion des Rencontres</h1>

                            <ol className="breadcrumb">
                                <li><Link to="/dashboard-admin">Accueil</Link></li>
                                <li className="active">Gestion des rencontres</li>
                            </ol>
                        </section>

                        <section className="content">
                            <div className="loader-row row">
                                <div className="col-md-4 col-md-offset-4 loader-container">
                                    <MagicSpinner size={150} color="#009688" loading={this.state.isPageLoading} />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            );
        }

        return (
            <div className="component-meeting">
                {/* <Menu /> */}
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
                <div className="row no-content-wrapper">
{/* 
                    <section className="content-header">
                        <h1>Gestion des Rencontres</h1>

                        <ol className="breadcrumb">
                            <li><Link to="/dashboard-admin">Accueil</Link></li>
                            <li className="active">Gestion des rencontres</li>
                        </ol>
                    </section> */}
                   
                    <section className="content">
                        <div className="row add_coach_btn_row">
                            <div className="row add_meeting_container">
                                <div className="col-md-6 col-md-offset-3 add-btn-container">
                                    <div className="row actions_btn_row">
                                        <div className="col-md-6 actions_btn_item">
                                            <button data-toggle="modal" data-target="#myModal" className="btn btn-success bg-vgreen add_btn button">
                                                <img src="https://img.icons8.com/ios/50/FFFFFF/add.png" alt="Ajouter" />
                                            </button>
                                        </div>
                                        <div className="col-md-6 actions_btn_item">
                                            <button data-toggle="modal" data-target="#myModaldiseabled" className="btn btn-danger bg-vred disabled_btn">
                                                <img src="https://img.icons8.com/plasticine/50/FFFFFF/archive-folder.png" alt="Archiver" />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row empty_data_content_container">
                            {this.state.rencontres.length === 0 &&
                                <div className="empty-rencontre-container">
                                    <div className="alert alert-warning empty-rencontre text-center">
                                        <p>Aucune rencontre en cours.</p>
                                        <hr />
                                    </div>
                                </div>
                            }
                        </div>
{console.log(this.state.rencontres,"rencontre")}
                        <div className="row meeting-list-row">
                            {this.state.rencontres.map((x, y) =>
                                <div className="col-md-4 meeting-col-container">
                                    {x.rencontre_type === 'evenement' &&
                                        <div className="panel panel-widget panel-volkeno-green" key={y}>
                                            <div className="panel-heading">
                                                <div className="row">
                                                    <div className="col-md-12 meeting-heading">
                                                        <div className="title">{x.rencontre_name}</div>
                                                        <div>
                                                            <div className="text-huge">{x.nbreParticipant}</div>
                                                            <span className="participantLabel">participant(s)</span>
                                                            <div className="clearfix"></div>
                                                            <hr />
                                                            <div className="meeting-form-url">
                                                                <span className="meeting_url_label"> <b> URL du formulaire : </b> </span>
                                                                <span className="meeting_register_url">
                                                                    <a href={'http://apply.bakeli.tech/#/inscription-evenement/' + x.rencontre_slug} className="form-link" target="_blank" rel="noopener noreferrer"> {'http://apply.bakeli.tech/#/inscription-evenement/' + x.rencontre_slug} </a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavLink to="/participants-rencontre" onClick={(y) => this.getRencontreInfos(x)} className="moreInfosLink">
                                                <div className="panel-footer">
                                                    <span className="pull-left">Détails</span>
                                                    <span className="pull-right"><i className="fas fa-arrow-circle-right"></i></span>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    }
                                    {x.rencontre_type === 'atelier' &&
                                        <div className="panel panel-widget panel-volkeno-orange" key={y}>
                                            <div className="panel-heading">
                                                <div className="row">
                                                    <div className="col-md-12 meeting-heading">
                                                        <div className="title">{x.rencontre_name}</div>
                                                        <div>
                                                            <div className="text-huge">{x.nbreParticipant}</div>
                                                            <span className="participantLabel">participant(s)</span>
                                                            <div className="clearfix"></div>
                                                            <hr />
                                                            <div className="meeting-form-url">
                                                                <span className="meeting_url_label"> <b> URL du formulaire : </b> </span>
                                                                <span className="meeting_register_url">
                                                                    <a href={'http://apply.bakeli.tech/#/inscription-atelier/' + x.rencontre_slug} className="meeting-form-link" target="_blank" rel="noopener noreferrer"> {'http://apply.bakeli.tech/#/inscription-atelier/' + x.rencontre_slug} </a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavLink to="/participants-rencontre" onClick={(y) => this.getRencontreInfos(x)} className="moreInfosLink">
                                                <div className="panel-footer">
                                                    <span className="pull-left">Détails</span>
                                                    <span className="pull-right"><i className="fas fa-arrow-circle-right"></i></span>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    }
                                    {x.rencontre_type === 'interview' &&
                                        <div className="panel panel-widget panel-volkeno-red" key={y}>
                                            <div className="panel-heading">
                                                <div className="row">
                                                    <div className="col-md-12 meeting-headind">
                                                        <div className="title">{x.rencontre_name}</div>

                                                        <div className="text-huge">{x.nbreParticipant}</div>
                                                        <span className="participantLabel">participant(s)</span>
                                                        <div className="clearfix"></div>
                                                        <hr />
                                                        <div className="meeting-form-url">
                                                            <span className="meeting_url_label"> <b> URL du formulaire : </b> </span>
                                                            <span className="meeting_register_url">
                                                                <a href={'http://apply.bakeli.tech/#/inscription-recrutement/' + x.rencontre_slug} className="meeting-form-link" target="_blank" rel="noopener noreferrer"> {'http://apply.bakeli.tech/#/inscription-recrutement/' + x.rencontre_slug} </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavLink to="/participants-rencontre" onClick={(y) => this.getRencontreInfos(x)} className="moreInfosLink">
                                                <div className="panel-footer">
                                                    <span className="pull-left">Détails</span>
                                                    <span className="pull-right"><i className="fas fa-arrow-circle-right"></i></span>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    }

                                    {x.rencontre_type === 'bootcamp' &&
                                        <div className="panel panel-widget panel-volkeno-green" key={y}>
                                            <div className="panel-heading">
                                                <div className="row">
                                                    <div className="col-md-12 meeting-headind">
                                                        <div className="title">{x.rencontre_name}</div>

                                                        <div className="text-huge">{x.nbreParticipant}</div>
                                                        <span className="participantLabel">participant(s)</span>
                                                        <div className="clearfix"></div>
                                                        <hr />
                                                        <div className="meeting-form-url">
                                                            <span className="meeting_url_label"> <b> URL du formulaire : </b> </span>
                                                            <span className="meeting_register_url">
                                                                <a href={'http://apply.bakeli.tech/#/inscription-bootcamp/' + x.rencontre_slug} className="meeting-form-link" target="_blank" rel="noopener noreferrer"> {'http://apply.bakeli.tech/#/inscription-bootcamp/' + x.rencontre_slug} </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <NavLink to="/participants-rencontre" onClick={(y) => this.getRencontreInfos(x)} className="moreInfosLink">
                                                <div className="panel-footer">
                                                    <span className="pull-left">Détails</span>
                                                    <span className="pull-right"><i className="fas fa-arrow-circle-right"></i></span>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    }

                                </div>
                            )}
                        </div>

                    </section>
                </div>

                {/** Modal ajouter une rencontre */}
                <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="myModalLabel">Ajouter une rencontre </h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group " >
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="titre" name="rencontre_name" placeholder="Titre" onChange={this.handleChange} required />
                                    </div>
                                    <div class="form-group">
                                        <input type="text" disabled class="form-control" id="titre" value={this.state.slugValue} name="rencontre_slug" placeholder="Slug" onChange={this.handleChange} required />
                                    </div>
                                    <div class="form-group ">
                                        <textarea class="form-control" rows="5" id="rencontre_description" name="rencontre_description" placeholder="Description" onChange={this.handleChange} />
                                    </div>

                                    <div class="form-group " >
                                        <select class="form-control" id="inputTypeRencontre" name="rencontre_type" onClick={this.handleChange}>
                                            <option>-- Choisir un type de rencontre --</option>
                                            <option value="evenement">Evenement</option>
                                            <option value="atelier">Atelier</option>
                                            <option value="interview">Recrutement</option>
                                            <option value="bootcamp">Bootcamp</option>
                                        </select>
                                    </div>
                                    {this.state.meetingValue === 'evenement' || this.state.meetingValue === 'atelier' || this.state.meetingValue === 'bootcamp' ?
                                        <div>
                                            {this.state.meetingValue === 'atelier' &&
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Est-ce que l'atelier cible uniquement des professionnels ?</label>
                                                            <div className="option_item">
                                                                <input type="radio" class="radio_choice" id="inputIsPro_1" name="is_atelier_pro" value={1} onChange={this.handleChange} />
                                                                <span>OUI</span>
                                                            </div>
                                                            <div className="option_item">
                                                                <input type="radio" class="radio_choice" id="inputIsPro_0" name="is_atelier_pro" value={0} onChange={this.handleChange} />
                                                                <span>NON</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Date Debut</label>
                                                        <input type="date" class="form-control" id="inputSDate" name="rencontre_start_date" placeholder="Date" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Date Fin</label>
                                                        <input type="date" class="form-control" id="inputEDate" name="rencontre_end_date" placeholder="Date" onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Heure Debut <i>(Format : HH:MM:SS)</i></label>
                                                    <input type="text" class="form-control" id="inputEndDate" name="rencontre_start_time" placeholder="Exemple : 09:30:00" onChange={this.handleChange} />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Heure Fin <i>(Format : HH:MM:SS)</i></label>
                                                    <input type="text" class="form-control" id="inputStartDate" name="rencontre_end_time" placeholder="Exemple : 19:00:00" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div> :
                                        this.state.meetingValue === 'interview' ?
                                            <div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Date Debut</label>
                                                            <input type="date" class="form-control" id="inputSDate" name="rencontre_start_date" placeholder="Date" onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Date Fin</label>
                                                            <input type="date" class="form-control" id="inputEDate" name="rencontre_end_date" placeholder="Date" onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            ''
                                    }


                                </div>
                                {this.state.isAddMeetingRequestError &&
                                    <div className="alert alert-danger text-center">
                                        Un problème est survenu lors de l'ajout de la rencontre. Veuillez réessayer plus tard.
                                        </div>
                                }
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                    <i class="fas fa-times-circle"></i> &nbsp;
                                    Quitter
                                            </button>
                                {!this.state.addMeetingInProgress &&
                                    <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.addMeetings(e)}>
                                        <i class="fas fa-plus"></i>&nbsp;
                                        Ajouter
                                        </button>
                                }
                                {this.state.addMeetingInProgress &&
                                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                        Ajout en cours &nbsp;
                                            <i className="fas fa-spinner fa-spin"></i>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/** Fin modal ajouter une rencontre */}



                {/** Modal désactiver une rencontre */}
                <div className="modal fade" id="myModaldiseabled" tabIndex="-1" role="dialog" aria-labelledby="myModaldiseabledLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="myModaldiseabledLabel"> Désactiver une rencontre </h4>
                            </div>
                            <div className="modal-body">
                                <div className="form-group " >

                                    <div class="form-group " >
                                        <select class="form-control" id="inputTypeRencontre" name="rencontre_type" onChange={this.DisabledhandleChange}>
                                            <option>-- Choisir la rencontre --</option>
                                            {this.state.rencontres.map((z, y) => <option key={y} data-id={z.id} value={z.rencontre_name}>{z.rencontre_name}</option>)}
                                        </select>
                                    </div>

                                </div>
                                {this.state.isAddMeetingRequestError &&
                                    <div className="alert alert-danger text-center">
                                        Un problème est survenu lors de l'ajout de la rencontre. Veuillez réessayer plus tard.
                                    </div>
                                }
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                    <i class="fas fa-times-circle"></i> &nbsp;
                                    Quitter
                                </button>
                                {!this.state.addMeetingInProgress &&
                                    <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.DisabledMeetings(e)}>
                                        <i class="fas fa-plus"></i>&nbsp;
                                        Désactiver
                                    </button>
                                }
                                {this.state.addMeetingInProgress &&
                                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                        Désactivation en cours &nbsp;
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/** Fin modal désactiver une rencontre */}

            </div>
        )
    }

}
export default Meetings;


