import React,{Component} from 'react';
import API from '../../../variablesGlobales';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
// import $ from 'jquery';
import * as Sentry from '@sentry/browser';
import { NavLink } from 'react-router-dom';
import {Button , Icon} from 'semantic-ui-react';
import './editorMobile.css'
/* 
 * Simple editor component that takes placeholder text as a prop 
 */
class Editor extends Component {
	constructor (props) {
	  super(props)
	  this.state = { 
		   bakelisteID: window.sessionStorage.getItem('user_id'),
		   api: API.API,
		   isPageLoading: false,
		   addTextualContentErrorAlert: false,
		   addTextualContentSuccessAlert: false,
		   textualContentInProgress: false,
		   submitTextualInProgress: false,
		   editorHtml: '',
		   theme: 'snow',
		   fields:{},
		   errors: {},
		   bakelisteInfos:[],
		   titleEmpty: false,
          contentTypeEmpty: false,
		  textualContentEmpty: false,
		  emptyContent: false,
          showTextualContentErrorAlert: false,
		 }
	  this.handleChange = this.handleChange.bind(this)
	  this.handleChangeEditor = this.handleChangeEditor.bind(this)
	  this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount = () => {
		this.onGetBakelisteInfos();
	  }

	onGetBakelisteInfos = () => {
		axios.get(this.state.api + 'bakeliste-by-id/' + this.state.bakelisteID)
		  .then(response => {
			
			this.setState({
			  bakelisteInfos: response.data.data
			})
		  })
		  .catch(error => {
			console.log(error)
		  })
	  }
	  handleChangeEditor (html) {
		this.setState({ editorHtml: html });
	}

	terxtualContentFormValidation = () => {
		let fields = this.state.fields;
		let errors = {};
		let isValidForm = true;
  
		if (!fields["title"]) {
		  isValidForm = false;
		  this.setState({
			titleEmpty: true
		  })
		  this.handleShowAndHideAlert('titleEmpty');
		  errors["titleEmpty"] = "*Ce champ est obligatoire";
		}
		if (!fields["content_type"]) {
		  isValidForm = false;
		  this.setState({
			contentTypeEmpty: true
		  })
		  this.handleShowAndHideAlert('contentTypeEmpty');
		  errors["contentTypeEmpty"] = "*Ce champ est obligatoire";
		}
  
		this.setState({
		  errors: errors
		});
	
		return isValidForm;
	  }

	  handleShowAndHideAlert(label) {
		setTimeout(() => {
		  if (label === 'addTextualContentSuccessAlert') {
			this.setState({
			  addTextualContentSuccessAlert: false
			});
		  }
		  if (label === 'addTextualContentErrorAlert') {
			this.setState({
			  addTextualContentErrorAlert: false
			});
		  }
		  if(label === 'titleEmpty'){
			this.setState({
			  titleEmpty: false
			});
		  }
		  if(label === 'contentTypeEmpty'){
			this.setState({
			  contentTypeEmpty: false
			});
		  }
		  if(label === 'textualContentEmpty'){
			this.setState({
			  textualContentEmpty: false
			});
		  }
		  if(label === 'updateTextualContentErrorAlert'){
			this.setState({
			  updateTextualContentErrorAlert: false
			})
		  }
		  if(label === 'updateTextualContentSuccessAlert'){
			this.setState({
			  updateTextualContentSuccessAlert: false
			})
		  }
		}, 5000);
	  }

	handleChange (e) {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		fields["bakeliste_id"] = this.state.bakelisteID;
		fields["coach_id"] = this.state.bakelisteInfos.coach_id;
		this.setState({
		  fields: fields,
		})
		console.log(fields)
	}
	
	handleThemeChange (newTheme) {
	  if (newTheme === "core") newTheme = null;
	  this.setState({ theme: newTheme })
	}
	

	handleSubmit(e){
		e.preventDefault();
		let data = this.state.fields;
		data["content"] = this.state.editorHtml;

		let isNotEmptyContent = true;
		let errors = {};
  
		if (!data["content"]) {
		  isNotEmptyContent = false;
		  this.setState({
			textualContentEmpty: true
		  })
		  this.handleShowAndHideAlert('textualContentEmpty');
		  errors["textualContentEmpty"] = "*Ce champ est obligatoire";
  
		  this.setState({
			errors: errors
		  })
		}
  
		if(this.terxtualContentFormValidation() && isNotEmptyContent){
		  console.log(this.state.fields)
		  
		  this.setState({
			submitTextualInProgress: true
		  }) 
  
		  console.log('sending data ', data)
  
		  axios.post(this.state.api + 'textual-contents', data)
			.then(response => {
			  this.setState({
				submitTextualInProgress: false
			  })
			  if(response.data.success){
				this.setState({
				  addTextualContentSuccessAlert: true
				})
				this.handleShowAndHideAlert('addTextualContentSuccessAlert')
                    window.location = '/editeur-texte';
			  }else{
				this.setState({
				  addTextualContentErrorAlert: true
				})
				this.handleShowAndHideAlert('addTextualContentErrorAlert')
			  }
			})
			.catch(error => {
			  console.log(error)
			  this.setState({
				submitTextualInProgress: false,
				addTextualContentErrorAlert: true
			  })
			  this.handleShowAndHideAlert('addTextualContentErrorAlert')
			  if (process.env.NODE_ENV !== 'production') {
				return;
			  }
			  Sentry.captureException(error);
			})
		 } 
	}

	render () {
	
		  
	  return (
		<div className='text-editor-Mobile'>
			 <div class="ui huge breadcrumb">
				<NavLink to="/editeur-texte" className="section">
					<i aria-hidden="true" className="arrow left link icon"></i>
				</NavLink>
				<NavLink to="dashboard=bakeliste" className="section">Accueil</NavLink>
				<i aria-hidden="true" class="right angle icon divider"></i>
				<NavLink to="/editeur-texte" className="section">Editeur de texte</NavLink>
				<i aria-hidden="true" class="right angle icon divider"></i>
				<div class="active section">Ajoute contenu</div>
			</div>
			 <br/><br/><br/>
			<form  onSubmit={this.handleSubmit}  >
				<div className="col-md-12 tc-input-item" id='editor-mobile'>
                    <div className="form-group ">
                      {/* <label >Choix du type de contenu :</label> */}
                        <select className="form-control" id='editor-mobile' name="content_type" onChange={this.handleChange}>
                           <option> --Choisir un type de contenu-- </option>
                           <option value="résumé"> Résumé de cours </option>
                            <option value="article"> Article </option>
                            <option value="tutoriel"> Tutoriel </option>
                            <option value="rapport de stage"> Rapport de stage </option>
                            <option value="cahier de charges"> Cahier de charges </option>
                            <option value="backlog"> Backlog </option>
                         </select>
                     </div>
                        {this.state.contentTypeEmpty &&
                                  <div className="errorMsg">{this.state.errors.contentTypeEmpty}</div>
                         } 
                          </div>
						  <div className="col-md-12 tc-input-item" id='editor-mobile'>
                              <div className="form-group ">
                                  {/* <label >Titre :</label> */}
                                  <input type="text" className="form-control"id='editor-mobile' placeholder="Titre" name="title" onChange={this.handleChange} />
                              </div>
                               {this.state.titleEmpty &&
                                  <div className="errorMsg">{this.state.errors.titleEmpty}</div>
                              } 
                          </div>
						 
				<ReactQuill 
					theme={this.state.theme}
					onChange={this.handleChangeEditor}
					value={this.state.editorHtml}
					modules={Editor.modules}
					formats={Editor.formats}
					bounds={'.app'}
					placeholder={this.props.placeholder}
				/>
				{this.state.textualContentEmpty &&
                 <div className="errorMsg">*Ce champ est obligatoire</div>
                 }
		      <div className="themeSwitcher">
				<label>Theme </label>
				<select onChange={(e) => this.handleThemeChange(e.target.value)}>
				<option value="snow">Snow</option>
				<option value="bubble">Bubble</option>
				<option value="core">Core</option>
				</select>
			 </div> 
				<br/>
				{this.state.submitTextualInProgress === false ?
				<Button color='teal' content='Envoyer' 
                   icon='send' size='huge'
                   id='btn-register'
                   fluid
                   /> :
				   <Button color='orange' size='huge' fluid id='btn-register'>
                 <Icon loading name='spinner' />
				   Envoie en cours
                 </Button>
	            }
				{/*  <div className="col-md-4 col-offset-4">
                            {this.state.submitTextualInProgress === false ?
                              <button className="btn btn-success submit-button-mobile" onClick={(e) => this.handleSubmit(e)}>
                                <i className="fas fa-paper-plane"></i> &nbsp;
                                Envoyer
                              </button> :
                              <button className="btn btn-warning submit-button-mobile" >
                                <i className="fas fa-spin fa-spinner"></i> &nbsp;
                                Envoie en cours
                              </button>
                            }
                          </div> */}
				</form>
		 </div>
	   )
	}
  }
  
  /* 
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  Editor.modules = {
	toolbar: [
	  [ { 'font': [] }],
	  [{size: []}],
	  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
	  [{'list': 'ordered'}, {'list': 'bullet'}, 
	   {'indent': '-1'}, {'indent': '+1'}],
	  ['link', 'image'],
	  [{ 'color': [] }, { 'background': [] }],
	  ['clean']
	],
	clipboard: {
	  // toggle to add extra line breaks when pasting HTML:
	  matchVisual: false,
	}
  }
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  Editor.formats = [
	'header', 'font', 'size',
	'bold', 'italic', 'underline', 'strike', 'blockquote',
	'list', 'bullet', 'indent',
	'link', 'image',
	'color', 'background'
  ]
  
  /* 
   * PropType validation
   */
 /*  Editor.propTypes = {
	placeholder: PropTypes.string,
  } */
  
  /* 
   * Render component on page
   */
 /*  ReactDOM.render(
	<Editor placeholder={'Write something...'}/>, 
	document.querySelector('.app')
  )
 */


export default Editor;
