import React, { Component } from 'react';
import axios from 'axios';
import API from '../../variablesGlobales';
import $ from 'jquery';
import * as Sentry from '@sentry/browser'
import { NavLink } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
//import { CSVReader } from 'react-papaparse';
import amplitude from "amplitude-js";
export default class ControleFonction extends Component{
    data = [];
    static defaultProps = {
        required: true
    }
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        if (!token) {
            window.history.back()
        }
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.onUpdateReportingStatus = this.onUpdateReportingStatus.bind(this);
        this.onUpdateReportingRequest = this.onUpdateReportingRequest.bind(this);
        this.updateHandleChange = this.updateHandleChange.bind(this);
        this.filterList = this.filterList.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fileInput = React.createRef();
        this.fileUploadHandler = this.fileUploadHandler.bind(this);
        this.state = {
            reportings: [],
            domains: [],
            api: API.API,
            fields: {},
            reportingEncours: false,
            addReportingError: false,
            userStatus: window.sessionStorage.getItem('user_status'),
            currentConnecteduserID: window.sessionStorage.getItem('user_id'),
            coachID: window.sessionStorage.getItem('coach_id'),
            isAdmin: false,
            isCoach: false,
            isBakeliste: false,
            bakelistesByCoach: [],
            allBakeliste: [],
            listBakeliste: [],
            reportingByBakeliste: [],
            bakelistReportsTab: [],
            bakelistEmptyReportsTab: {
                id: "",
                date: "",
                tache: "",
                status: "",
                commentaire: "",
                using_links: "",
                lieu: "",
                app_name: "",
                technology: "",
                bakeliste_id: "",
                coach_id: "",
                created_at: "",
                updated_at: "",
                deleted_at: ""
            },
            reporting_id: 0,
            dateValue: '',
            dateFRValue: '',
            tacheValue: '',
            statutValue: '',
            commentaireValue: '',
            using_linksValue: '',
            lieuValue: '',
            appNameValue: '',
            technologyValue: '',
            bakelisteIDValue: 0,
            updateDateValue: '',
            coachIDValue: 0,
            updateFields: {},
            updateReportingPending: false,
            updateReportingID: 0,
            bakelisteAddReportLoading: false,
            currentPage: 1,
            itemPerPage: 10,
            addReportingSuccessAlert: false,
            updateReportingSuccessAlert: false,
            updateReportingStatusSuccessAlert: false,
            isUpdateStatusError: false,
            errors: {},
            emptyDate: false,
            emptyLieu: false,
            emptyStatus: false,
            emptyTache: false,
            isLoadingBakelisteData: false,
            reporting_lastfill:[],
            last_date: 0,
            search:null,
            staatus:'Terminé',
         date:'',
         lieeu:'bakeli',
         app_namee:'name_app',
         technologye:'techno',
         bakelister_id: window.sessionStorage.getItem('user_id'),
         using_links:'',
         commentaire:'',
         file:'',
         importInProgress: false,
        successImportAlert: false
        }
        Sentry.init({dsn: "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964"});

        this.handleClick = this.handleClick.bind(this);
      /*  this.onSearchClickExample = this.onSearchClickExample.bind(this); */
    }

    handleClick(event) {
        this.setState({
          currentPage: Number(event.target.id)
        });
      }


    fadeOutBakelisteReports = (x) => {
        axios.get(this.state.api + `reporting-by-bakeliste/${x}`)
            .then(res => {
                let bakelistReportsTab = res.data.data;
                this.setState({
                    bakelistReportsTab,
                    bakelistEmptyReportsTab: this.state.bakelistReportsTab
                });
                //console.log(this.state.bakelistReportsTab, "bakhna")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })

    }


    componentDidMount() {
        this.fadeOutBakelisteReports();
        this.getAllReportings();
        this.getBakelisteByCoach();
        this.getReportingByBakeliste();
        this.getAllBakeliste();
        this.amplitudeScript('0168d5bcb0d33437d15f2a6220fb054a');
      //  this.amplitudeScript('7a9faefb1bb6a856ed9b89eaaf1e8944');
        this.setState({
            allBakeliste: this.state.allBakeliste
        })

        if (this.state.userStatus === 'coach') {
            this.setState({
                isCoach: true,
                isAdmin: false,
                isBakeliste: false
            })
        } else if (this.state.userStatus === 'admin') {
            this.setState({
                isCoach: false,
                isAdmin: true,
                isBakeliste: false
            })
        } else if (this.state.userStatus === 'bakeliste') {
            this.setState({
                isCoach: false,
                isAdmin: false,
                isBakeliste: true
            })
        }
       
    }
    amplitudeScript = (api_key) => {
        (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
          ;r.type="text/javascript"
          ;r.integrity="sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta"
          ;r.crossOrigin="anonymous";r.async=true
          ;r.src="https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js"
          ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
          console.log("[Amplitude] Error: could not load SDK")}}
          ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
          ;function s(e,t){e.prototype[t]=function(){
          this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
          var o=function(){this._q=[];return this}
          ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
          ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
          ;return this}
          ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
          ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
          ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
          ;function v(e){function t(t){e[t]=function(){
          e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
          for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
          e=(!e||e.length===0?"$default_instance":e).toLowerCase()
          ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
          ;e.amplitude=n})(window,document);
    
          amplitude.getInstance().init(api_key);
      }
    handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'addSuccessAlert') {
                this.setState({
                    addReportingSuccessAlert: false
                });
            }
            if (label === 'updateSuccessAlert') {
                this.setState({
                    updateReportingSuccessAlert: false
                });
            }
            if(label === 'updateStatusSuccessAlert'){
                this.setState({
                    updateReportingStatusSuccessAlert: false
                })
            }
            if(label === 'addReportErrorAlert'){
                this.setState({
                    updateReportingSuccessAlert: false
                })
            }
            if(label === 'updateErrorAlert'){
                this.setState({
                    updateReportingError: false
                })
            }
            if(label === 'emptyLieu'){
                this.setState({
                    emptyLieu: false
                })
            }
            if(label === 'emptyTache'){
                this.setState({
                    emptyTache: false
                })
            }
            if(label === 'emptyDate'){
                this.setState({
                    emptyDate: false
                })
            }
            if(label === 'emptyStatus'){
                this.setState({
                    emptyStatus: false
                })
            }
        }, 5000);
    }

    getAllReportings = () => {
        axios.get(this.state.api + 'reporting_bakelistes')
            .then(res => {
                 let reportings = res.data.data;
              
                this.setState({
                    reportings: reportings
                })
                //console.log(this.state.reportings);
                //this.getBakelisteNotFillreporting(this.state.reportings);
               
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getBakelisteByCoach = () => {
        axios.get(this.state.api + `bakelistes-by-coach/${this.state.currentConnecteduserID}`)
            .then(res => {
                var data = res.data.data;
                if (data !== undefined) {
                    this.setState({
                        bakelistesByCoach: data,
                    });
                }

                //console.log(this.state.bakelistesByCoach, "lolo")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }


   getAllBakeliste = () => {
        axios.get(this.state.api + `liste-des-bakelistes-en-formation`)
            .then(res => {
                var data = res.data.data;
                if (data !== undefined) {
                    this.setState({  
                        allBakeliste: data,
                        listBakeliste:data
                    });
                }

                console.log(this.state.allBakeliste, "lolo")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getReportingByBakeliste = () => {
        this.setState({
            isLoadingBakelisteData: true
        })
        axios.get(this.state.api + `reporting-by-bakeliste/${this.state.currentConnecteduserID}`)
            .then(res => {
                var data = res.data.data;
                console.log(data,"reporting")
                this.setState({
                    isLoadingBakelisteData: false
                })
                if (data !== undefined) {
                    this.setState({
                        reportingByBakeliste: data.reverse(),
                    });
                }
               // console.log(this.state.reportingByBakeliste, "lolo")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getReportingInfos(reporting_id) {
        this.setState({
            reporting_id: reporting_id
        })
    }

    onUpdateReportingStatus = (e) => {
        e.preventDefault();
        this.setState({
            updateReportingStatusInProgress: true
        })
        var api = API.API;
        console.log(this.state.reporting_id)
        axios.post(api + 'update-reporting-status/' + this.state.reporting_id, this.state.fields)
            .then(response => {
                if(response.data.success === true){
                    this.getReportingByBakeliste();
                    $('.close').click();
                    
                    this.setState({
                        updateReportingStatusSuccessAlert: true,
                        updateReportingStatusInProgress: false
                    });

                    this.handleShowAndHideAlert('updateStatusSuccessAlert');
                }else{
                    console.log(response);
                    this.setState({
                        updateReportingStatusInProgress: false,
                        isUpdateStatusError: true
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    updateReportingStatusInProgress: false,
                    isUpdateStatusError: true
                });
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }


    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        console.log("handle change", e.target.value)
        fields["bakeliste_id"] = window.sessionStorage.getItem('user_id')
        fields["coach_id"] = window.sessionStorage.getItem('coach_id')
        this.setState({
            fields
        });
        console.log("handle change", this.state.fields)
    }
    
    onValidateAddReportingForm = () => {
        let fields = this.state.fields;
        let errors = {};
        let isValidForm = true;

        if (!fields["lieu"]) {
            isValidForm = false;
            this.setState({
                emptyLieu: true
            })
            this.handleShowAndHideAlert('emptyLieu');
            errors["emptyLieu"] = "*Ce champ est obligatoire";
        }
        if (!fields["tache"]) {
            isValidForm = false;
            this.setState({
                emptyTache: true
            })
            this.handleShowAndHideAlert('emptyTache');
            errors["emptyTache"] = "*Ce champ est obligatoire";
        }
        if (!fields["date"]) {
            isValidForm = false;
            this.setState({
                emptyDate: true
            })
            this.handleShowAndHideAlert('emptyDate');
            errors["emptyDate"] = "*Ce champ est obligatoire";
        }
        if (!fields["status"] || fields["status"] === '--Choisir un status--') {
            if(fields["status"] === '--Choisir un status--'){
                fields["status"] = ''
            }
            isValidForm = false;
            this.setState({
                emptyStatus: true
            })
            this.handleShowAndHideAlert('emptyStatus');
            errors["emptyStatus"] = "*Ce champ est obligatoire";
        }

        this.setState({
            errors: errors
        })

        return isValidForm;
    }

    addReporting() {
        console.log("report add", this.state.fields);
        if(this.onValidateAddReportingForm()){
            this.setState({
                reportingEncours: true
            })
            var eventProperties = {
                'user_email': this.state.email
               };
            const dataCoach = this.state.fields;
            console.log(dataCoach)
            console.log()
            axios.post(this.state.api + 'reporting_bakelistes', this.state.fields)
                .then(res => {
                    if (res.status === 200) {
                        this.getReportingByBakeliste();
                        $('.close').click();
                        this.handleShowAndHideAlert('addSuccessAlert');
                        amplitude.getInstance().logEvent('REPORTING_BAKELISTE_TYPE', eventProperties);
                        $('#add-reporting-form')[0].reset()
                        this.setState({
                            addReportingSuccessAlert: true,
                            reportingEncours: false,
                            fields: {}
                        });
                        
                        
                    }
                })
                .catch(error => {
                    this.setState({
                        reportingEncours: false,
                        addReportingError: true
                    });
                    this.handleShowAndHideAlert('addReportErrorAlert');
                    amplitude.getInstance().logEvent('Reporting_BAKELISTE_Error', eventProperties);
                    console.log(error);
                    if (process.env.NODE_ENV !== 'production') {
                        return;
                    }
                    Sentry.captureException(error);
                });
        }
        
    }



    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }


    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    getReportingInfosForUpdated = (reporting) => {
        this.setState({
            updateReportingID: reporting.id,
            dateValue: reporting.date,
            dateFRValue: reporting.date_fr,
            tacheValue: reporting.tache,
            updateDateValue: reporting.forUpdateDateFormat,
            statutValue: reporting.status,
            commentaireValue: reporting.commentaire,
            using_linksValue: reporting.using_links,
            lieuValue: reporting.lieu,
            appNameValue: reporting.app_name,
            technologyValue: reporting.technology,
            bakelisteIDValue: reporting.bakeliste_id,
            coachIDValue: reporting.coach_id,
            isUpdateStatusError: false
        })
        console.log(reporting)
    }

    updateHandleChange(e) {
        let fields = this.state.updateFields;
        fields[e.target.name] = e.target.value;
        if (e.target.name === 'tache') {
            this.setState({
                tacheValue: e.target.value
            })
        }
        if (e.target.name === 'technology') {
            this.setState({
                technologyValue: e.target.value
            })
        }
        if (e.target.name === 'using_links') {
            this.setState({
                using_linksValue: e.target.value
            })
        }
        if (e.target.name === 'app_name') {
            this.setState({
                appNameValue: e.target.value
            })
        }
        if (e.target.name === 'commentaire') {
            this.setState({
                commentaireValue: e.target.value
            })
        }
        if (e.target.name === 'status') {
            this.setState({
                statutValue: e.target.value
            })
        }
        if (e.target.name === 'lieu') {
            this.setState({
                lieuValue: e.target.value
            })
        }
        if (e.target.name === 'date') {
            this.setState({
                updateDateValue: e.target.value
            })
        }

        if (!fields["tache"]) {
            fields["tache"] = this.state.tacheValue;
        }
        if (!fields["technology"]) {
            fields["technology"] = this.state.technologyValue;
        }
        if (!fields["using_links"]) {
            fields["using_links"] = this.state.using_linksValue;
        }
        if (!fields["app_name"]) {
            fields["app_name"] = this.state.appNameValue;
        }
        if (!fields["commentaire"]) {
            fields["commentaire"] = this.state.commentaireValue;
        }
        if (!fields["status"]) {
            fields["status"] = this.state.statutValue;
        }
        if (!fields["lieu"]) {
            fields["lieu"] = this.state.lieuValue;
        }
        if (!fields["date"]) {
            fields["date"] = this.state.updateDateValue;
        }
        //fields["date"] = this.state.dateValue;
        fields["bakeliste_id"] = this.state.bakelisteIDValue;
        fields["coach_id"] = this.state.coachID;
        this.setState({
            updateFields: fields
        });

        console.log(fields);
    }

    onUpdateReportingRequest = (e) => {
        e.preventDefault();
        this.setState({
            updateReportingPending: true
        });
        let updateData = this.state.updateFields;
        axios.put(this.state.api + 'reporting_bakelistes/' + this.state.updateReportingID, updateData)
            .then(response => {
                // let reportingBakeliste = response.data.data;

                if(response.data.success === true){
                    this.getReportingByBakeliste();
                    $('.close').click();
                    this.setState({
                        updateReportingPending: false,
                        updateReportingSuccessAlert: true
                    });
                    this.handleShowAndHideAlert('updateSuccessAlert');
                }else{
                    this.setState({
                        updateReportingPending: false,
                        updateReportingError: true
                    });
                    this.handleShowAndHideAlert('updateErrorAlert');
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    updateReportingPending: false,
                    updateReportingError: true
                });
                this.handleShowAndHideAlert('updateErrorAlert');
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }
      
    statusFormatter = (cell) => {
        return ( 
            <span>
                {cell === 'A Faire' &&
                    <span className="label label-danger">{cell}</span>
                }
                {cell === 'En Cours' &&
                    <span className="label label-warning">{cell}</span>
                }
                {cell === 'Terminé' &&
                    <span className="label label-success">{cell}</span>
                }
            </span>
        );
    }
    nbjoursFormatter(lastReportingDays, lastReportingMonths){
        
        const tab1 =[2,3,4];
        const tab2 =[5,6,7,8];
        const tab3 =[9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
       
        if(lastReportingDays === 0 && lastReportingMonths > 0){
            return (
                <span className="badge badge-danger"> {lastReportingMonths} &nbsp;mois</span>
            )
        }
  
        if(lastReportingDays > 0 && lastReportingMonths > 0){
            return (
                <span className="badge badge-danger"> {lastReportingMonths} mois &nbsp;{lastReportingDays}&nbsp;j</span>
            )
        }
        
        if(lastReportingDays > 0 && lastReportingMonths === 0){
        
        for (const i of tab1) {
            if (i === lastReportingDays) {
                return (
                    <span className="badge badge-info"> {lastReportingDays} &nbsp;jours</span>
                )
            }  
        }

        for (const j of tab2) {
            if (j === lastReportingDays) {
                return (
                    <span className="badge badge-warning"> {lastReportingDays} &nbsp;jours</span>
                )
            }  
        }

        for (const k of tab3) {
            if (k === lastReportingDays) {
                return (
                    <span className="badge badge-danger"> {lastReportingDays} &nbsp;jours</span>
                )
            }  
        }
     }
        if (lastReportingDays ===1 && lastReportingMonths ===0) {
            return (
                <span className="badge badge-success"> {lastReportingDays} &nbsp;jour</span>
            )
        } 
     
    }
  

    bakelistesRepStatusFormatter = (cell, row) => {
        return (
            <div>
                {cell === 'A Faire' &&
                    <span className="label label-danger bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
                }
                {cell === 'En Cours' &&
                    <span className="label label-warning bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
                }
                {cell === 'Terminé' &&
                    <span className="label label-success bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
                }
            </div>
        );
    }

    commentFormatter = (cell, row) => {
        return (
            <div>
                {cell !== null &&
                    <p>{cell}</p>
                }
                {cell === null &&
                    <span className="label label-warning empty-comment">Aucun commentaire sur cette tâche</span>
                }
            </div>
        );
    }

    tacheFormatter = (cell, row) => {
        return(
            <span>
                <span className="tache-link" data-toggle="modal" data-target="#showReportingItemModal" onClick={() => this.getReportingInfosForUpdated(row)}> {cell} </span>
            </span>
        );
    }

    actionsFormatter = (cell, row) => {
        return (
            <div className="actions-btn-container">
                <button onClick={() => this.getReportingInfosForUpdated(row)} type="button" className="btn btn-warning bsi-action-update-btn" id="btn_update" data-toggle="modal" data-target="#updateReportingModal">
                    <i className="fas fa-edit"></i>
                </button>
                <button onClick={() => this.getReportingInfosForUpdated(row)} type="button" className="btn btn-info bsi-action-show-btn" id="btn_update" data-toggle="modal" data-target="#showReportingItemModal">
                    <i className="fas fa-eye"></i>
                </button>
            </div>
        );
    }

    filterList = (event) => {
        var updatedList = this.state.allBakeliste;
        
            updatedList = updatedList.filter((item) => {
                return item.first_name.toLowerCase().search(
                    event.target.value.toLowerCase()) !== -1 || item.last_name.toLowerCase().search(
                        event.target.value.toLowerCase()) !== -1 || item.email.search(
                            event.target.value.toLowerCase()) !== -1  ;              
            });
            this.setState({listBakeliste: updatedList });
    }
    customHeaderClass() {
        return 'custom-header-tache';
      }
      columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {

        return rowIdx % 2 === 0 ? 'td-column-function-even-example' : 'td-column-function-odd-example';
      }
      
  componentWillMount(){
    this.setState({
        listBakeliste: this.state.allBakeliste
    })
  }
  handleInputChange(event) {
     this.setState({
    importInProgress: true
    });
    const toto = new Date();
    const newLocal = this.state;
    newLocal.date = toto.getFullYear() + '-' + (toto.getMonth() + 1) + '-' + toto.getDate();
    
    const fields = this.state.fields;
     fields["date"]= this.state.date;
     fields["bakeliste_id"]=this.state.bakelister_id;
     fields["status"]=this.state.staatus;
     fields["lieu"]=this.state.lieeu;
     fields["app_name"]=this.state.app_namee;
     fields["technology"]=this.state.technologye;
     fields["coach_id"]=this.state.coachID;
     fields["using_links"]=this.state.using_links;
     fields["commentaire"]=this.state.commentaire;
     if (event.target.name === 'tache') {
      console.log(event.target.files)
      this.createImage(event.target.files[0]);
      fields["tache"] = event.target.files[0]
    } else {
      fields[event.target.name] = event.target.value;
    }
    this.setState({
      fields
    })
  
    console.log(fields);
    this.fileUploadHandler();
  }
  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        file: e.target.result
      })
    };
    console.log(this.state.file)
    reader.readAsDataURL(file);
  }
  fileUploadHandler = () => {
    this.setState({
      importInProgress: true
    })
    const filer = new FormData();
    //  filer.append('date',this.state.fields.date);
      filer.append('tache', this.state.fields.tache);
      filer.append('coach_id', this.state.fields.coach_id);
      filer.append('bakeliste_id',this.state.fields.bakeliste_id);
      filer.append('status',this.state.fields.status);
      filer.append('lieu',this.state.fields.lieu);
      filer.append('app_name',this.state.fields.app_name);
      filer.append('technology',this.state.fields.technology);
      filer.append('using_links',this.state.fields.using_links);
      filer.append('commentaire',this.state.fields.commentaire);
    // let data = this.state.fields;
     console.log(this.state.fields,"data")
      //console.log(this.state.reporting_id,'id')
        axios.post(this.state.api +'reporting_import_file',filer)
          .then(response => {
            //console.log(response.status)
            if (response.status === 200) {
           $('.close').click();
              console.log('click')
              $('body').removeClass('modal-open');
              console.log('open')
              $('.modal-backdrop').removeClass('modal-backdrop');
              console.log('backdrop')
              $('.fade').removeClass('fade')
              $('.in').removeClass('in')
              // this.handleShowAndHideAlerte('successImport');
              this.getReportingByBakeliste();
              this.setState({
                importInProgress: false,
                successImportAlert: true,
              })
              setTimeout(() => {
                this.setState({
                  successImportAlert: false
                })
              }, 5000);
             
          }else{  
              //console.log(response);
              console.log('non ok')
              this.setState({
                importInProgress: false
              });
          }
      })
      .catch(error => {
          console.log(error);
          this.setState({
            importInProgress: false
          });
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
      
     //console.log("ok");
  }
  triggerClick = () => {
    this.fileInput.current.click();
  }

}