import React, { Component } from 'react';
import './ratingsManager.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import API from '../../variablesGlobales';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import './../adminHomeDashboard/adminDashboardChart/Chart';
import Chart from './../adminHomeDashboard/adminDashboardChart/Chart';


export default class ratingsManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      isPageLoading: false,
      coachData: [],
      ratingPerCoach: [],
      notations: [],
      requestError: false,
      notationMoreInfos: [],
      moyenneNoteBakeli: 0,
      nbreVotes: 0,
      noteBakeliPercent: 0,
      ratingInfos: [],

    };
  }

  componentDidMount = () => {
    this.getBakelistesNotationData();
  }

  /**
   * Get notation data and chart data
   */
  getBakelistesNotationData = () => {
    this.setState({
      isPageLoading: true
    });

    axios.get(this.state.api + 'notations')
      .then(response => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            coachData: response.data.dataCoach,
            ratingPerCoach: response.data.dataNoteMoyenneCoach,
            notations: response.data.dataNotation,
            moyenneNoteBakeli: response.data.dataNoteMoyenneBakeli,
            nbreVotes: response.data.dataNbreVote,
          })
          let noteBakeliPercent = ((this.state.moyenneNoteBakeli * 100) / 5).toFixed(0) ;
          //console.log(noteBakeliPercent)
          this.setState({
            noteBakeliPercent: parseInt(noteBakeliPercent)
          })
          $(function () {
            //'use strict';

            /* ChartJS
             * -------
             * Here we will create a few charts using ChartJS
             */

            // -----------------------
            // - MONTHLY SALES CHART -
            // -----------------------

            // Get context with jQuery - using jQuery's .get() method.
            var noteChartCanvas = $('#note-moyenne-per-coach').get(0).getContext('2d');
            // This will get the first returned node in the jQuery collection.
            var notesChart = new Chart(noteChartCanvas);

            var notesChartData = {
              labels: response.data.dataCoach,
              datasets: [
                {
                  label: 'Coach Rating',
                  fillColor: '#009688',
                  strokeColor: '#009688',
                  pointColor: '#009688',
                  pointStrokeColor: 'rgba(60,141,188,1)',
                  pointHighlightFill: '#fff',
                  pointHighlightStroke: 'rgba(60,141,188,1)',
                  data: response.data.dataNoteMoyenneCoach
                }
              ]
            };

            var notesChartOptions = {
              // Boolean - If we should show the scale at all
              showScale: true,
              // Boolean - Whether grid lines are shown across the chart
              scaleShowGridLines: true,
              // String - Colour of the grid lines
              scaleGridLineColor: 'rgba(0,0,0,.05)',
              // Number - Width of the grid lines
              scaleGridLineWidth: 1,
              // Boolean - Whether to show horizontal lines (except X axis)
              scaleShowHorizontalLines: true,
              // Boolean - Whether to show vertical lines (except Y axis)
              scaleShowVerticalLines: true,
              // Boolean - Whether the line is curved between points
              bezierCurve: true,
              // Number - Tension of the bezier curve between points
              bezierCurveTension: 0.3,
              // Boolean - Whether to show a dot for each point
              pointDot: true,
              // Number - Radius of each point dot in pixels
              pointDotRadius: 2,
              // Number - Pixel width of point dot stroke
              pointDotStrokeWidth: 1,
              // Number - amount extra to add to the radius to cater for hit detection outside the drawn point
              pointHitDetectionRadius: 20,
              // Boolean - Whether to show a stroke for datasets
              datasetStroke: true,
              // Number - Pixel width of dataset stroke
              datasetStrokeWidth: 2,
              // Boolean - Whether to fill the dataset with a color
              datasetFill: true,
              // String - A legend template
              legendTemplate: '<ul class=\'<%=name.toLowerCase()%>-legend\'><% for (var i=0; i<datasets.length; i++){%><li><span style=\'background-color:<%=datasets[i].fillColor%>\'></span><%=datasets[i].label%></li><%}%></ul>',
              // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container
              maintainAspectRatio: true,
              // Boolean - whether to make the chart responsive to window resizing
              responsive: true
            };

            // Create the line chart
            notesChart.Line(notesChartData, notesChartOptions);
          })
        } else {
          console.log(response);
          this.setState({
            isPageLoading: false,
            requestError: true
          })
        }
      })
      .catch(error => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
          requestError: true
        })
      })
  }

  /**
   * Get single notation more infos
   */
  getNotationInfos = (notation) => {
    this.setState({
      notationMoreInfos: notation
    })
  }

  /**
   * Coach name formatter
   */
  coachFullnameFormatter = (cell, row) => {
    return (
      <span className="fullname-link" data-toggle="modal" data-target="#moreInfosModal" onClick={(e) => this.getNotationInfos(row, e)} >{cell}</span>
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  /**
   * Actions formatter
   */
  actionsFormatter = (cell, row) => {

  }

  onRowClick = (row) => {
    // alert(`You click row id: ${row.id}`);
    this.setState({
      ratingInfos: row
    })
    $('.open-infos-modal').click();
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
      onRowClick: this.onRowClick
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-ratings-manager">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
          Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Ratings Manager
            </h1>
              <ol className="breadcrumb">
                <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Accueil</NavLink></li>
                <li className="active">Ratings Manager</li>
              </ol>
            </section>

            <section className="content">
              <div className="loader-row row">
                <div className="col-md-4 col-md-offset-4 loader-container">
                  <div id="loader"></div>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }
    return (
      <div className="component-ratings-manager">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
          Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>
              Ratings Manager
            </h1>
            <ol className="breadcrumb">
              <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Accueil</NavLink></li>
              <li className="active">Ratings Manager</li>
            </ol>
          </section>

          <section className="content">
            <div class="row coach-notation-moyenne-row">
              <div className="col-md-8 col-md-offset-2 bakeli-notation-container">
                <div className={"info-box "  + (this.state.noteBakeliPercent >= 50 && this.state.noteBakeliPercent <= 70 ? 'bg-yellow' : 
                                                         this.state.noteBakeliPercent >= 80 && this.state.noteBakeliPercent <= 100 ? 'bg-green' : 
                                                         this.state.noteBakeliPercent  >= 35 && this.state.noteBakeliPercent  <= 49 ? 'bg-aqua' : 
                                                         this.state.noteBakeliPercent >= 0 && this.state.noteBakeliPercent <= 34 ? 'bg-red' : 'bg-red')}>
                  <span className="info-box-icon"><i className="fas fa-user-graduate"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Note moyenne Bakeli</span>
                    <span className="info-box-number"> {this.state.moyenneNoteBakeli} / 5 </span>

                    <div className="progress">
                      <div className="progress-bar" style={{width: this.state.noteBakeliPercent + '%'}}></div>
                    </div>
                    <span className="progress-description">
                      {this.state.noteBakeliPercent >= 0 && this.state.noteBakeliPercent <= 34 ? 'Résultat trés mauvais, veuillez améliorer le niveau de la formation' :
                       this.state.noteBakeliPercent  >= 35 && this.state.noteBakeliPercent  <= 49 ? 'Résultat faiblement mauvais, veuillez améliorer le niveau de la formation' :
                       this.state.noteBakeliPercent >= 50 && this.state.noteBakeliPercent <= 70 ? 'Résultat moyen mais vous pouvez faire mieux. Discutez avec les coachs.' :
                       this.state.noteBakeliPercent >= 80 && this.state.noteBakeliPercent <= 100 ? 'Excellent résultat. Maintenez le cap, le meilleur reste à venir.' : ''}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-12 coach-notation-moyenne-col">
                <div className="box">
                  <div className="box-header with-border">
                    <h3 className="box-title">Moyenne des notes par coach</h3>

                    <div className="box-tools pull-right">
                      <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                      </button>
                      <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
                    </div>
                  </div>

                  <div class="box-body">
                    <div class="row">
                      <div class="col-md-12 chart-container">

                        <div class="chart">
                          <canvas id="note-moyenne-per-coach"></canvas>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row notation-table-row">
              <div className="col-md-12 notation-table-col">
                <div class="box">
                  <div class="box-header with-border">
                    <h3 class="box-title">Détails des notes attribuées</h3>

                    <div class="box-tools pull-right">
                      <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i>
                      </button>
                      <button type="button" class="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
                    </div>
                  </div>

                  <div class="box-body">
                    <div class="row">
                      <div class="col-md-12 notation-table-container">

                        <div class="notation-table">
                          <BootstrapTable data={this.state.notations}
                            striped={true} hover={true} condensed={true}
                            multiColumnSort={2} options={options}
                            exportCSV
                            pagination
                            search={true}>

                            <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                            <TableHeaderColumn dataAlign="center"
                              width='300'
                              dataField='frenchDateFormat'
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataSort={true} >Date</TableHeaderColumn>
                            <TableHeaderColumn dataAlign="center"
                              width='300'
                              dataField='coachFullname'
                              dataFormat={this.coachFullnameFormatter.bind(this)}
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataSort={true} >Coach</TableHeaderColumn>
                            <TableHeaderColumn dataAlign="center"
                              width='250'
                              dataField='coach_notation'
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataSort={true}
                            >Note du coach</TableHeaderColumn>
                            <TableHeaderColumn dataAlign="center"
                              width='200'
                              dataField='training_notation'
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataSort={true}
                            >Note de la formation</TableHeaderColumn>
                            <TableHeaderColumn dataAlign="center"
                              width='400'
                              dataField='commentaires'
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataSort={true}
                            >Commentaires</TableHeaderColumn>
                            {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                          </BootstrapTable>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="dyn_btn_container">
              <button className="open-infos-modal" data-toggle="modal" data-target="#ratingInfosModal">Open Infos Modal</button>
            </div>

            <div className="modal fade" id="ratingInfosModal" tabIndex="-1" role="dialog" aria-labelledby="ratingInfosModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title" id="ratingInfosModalLabel">Rating Information</h4>
                        </div>
                        <div className="modal-body">
                          <div className="row modal-row">
                            <div className="col-md-12 rating-item">
                              <div className="row rating-row">
                                <div className="col-md-6 item_rating_bloc">
                                  <div className="item-title"> Note attribuée au coach {this.state.ratingInfos.coachFullname} </div>
                                  <div className="item-coach-notation"> {this.state.ratingInfos.coach_notation + '/5'} </div>
                                </div>
                                <div className="col-md-6 item_rating_bloc">
                                  <div className="item-title"> Note attribuée à Bakeli </div>
                                  <div className="item-bakeli-notation"> {this.state.ratingInfos.training_notation + '/5'} </div>
                                </div>
                              </div>
                              
                              {this.state.ratingInfos.commentaires !== null ?
                                <div className="row comments-row">
                                  <div className="col-md-12 comments-col">
                                    <div className="commentaire_container">
                                      <h4 className="comment-title"> Commentaire </h4>
                                      <div className="comment_content">
                                        {this.state.ratingInfos.commentaires}
                                      </div>
                                    </div>
                                  </div>
                                </div> :
                                <div className="bg-vorange empty_comment_container">
                                  Aucun commentaire n'a été ajouté avec cette notation.
                                </div>
                              }
                            </div>
                          </div>
                          
                        </div>
                        
                    </div>
                </div>
            </div>
          </section>

        </div>
      </div>
    );
  }
}