import React, { Component } from 'react';
import './passwordReset.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../variablesGlobales';

export default class passwordReset extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.resetUserPassword = this.resetUserPassword.bind(this);
    this.state = {
      id: 0,
      api: API.API,
      isPending: false,
      fields: {},
      successPasswordReset: false,
      showForm: true
    };
  }

  componentDidMount() {
    let x = document.getElementById('page-wrapper');
    x.style.marginLeft = 0;

    const { match: { params } } = this.props;
    this.setState({
      id: params.id
    })
    window.sessionStorage.setItem('reset_userID', params.id);
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields
    })
    console.log(fields)
  }

  resetUserPassword = (e) => {
    e.preventDefault();
    this.setState({
      isPending: true
    })
    let reset_userID = window.sessionStorage.getItem('reset_userID');
    let passwordData = this.state.fields;

    axios.post(this.state.api + 'update-user-password/' + reset_userID, passwordData)
      .then(response => {
        this.setState({
          isPending: false
        });

        if (response.data.success === true) {
          this.setState({
            successPasswordReset: true,
            showForm: false
          });
        } else {
          console.log('Erreur lors de la modification')
        }
      })
  }


  render() {
    return (
      <div className="component-password-reset">
        <section className="col-md-6 col-md-offset-3">
        <div className="logo_container">
          <img className="img-responsive logo_bakeli" src={require('../../images/logo_bak.png')} alt="Logo Bakeli" />
        </div>

          <h2 className="color1 connect-libelle">MODIFIER VOTRE MOT DE PASSE</h2>
          {this.state.showForm ?
            <div className="reset-form-container">
              <form className="positionForm" onSubmit={this.resetUserPassword}>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="password" placeholder="Mot de passe"
                    name="password" onChange={this.handleChange} required />
                </div>
                <div className="form-group">
                  {!this.state.isPending &&
                    <button className='btn btn-warning submitLoginBtn button'>
                      <i class="fas fa-edit"></i> &nbsp;
                      Valider
                  </button>
                  }
                  {this.state.isPending &&
                    <button className="btn btn-primary pendingLoginBtn button">
                      Modification en cours &nbsp;
                  <i class="fas fa-spinner fa-spin"></i>
                    </button>
                  }

                </div>

              </form>
            </div> :

            <div className="success-reset-form-container">
              <div className="alert alert-success success-message">
                <p>Bravo ! Votre mot de passe a été bien réinitialisé. Vous pouvez vous connecter dorénavant
                avec votre email et votre nouveau mot de passe.</p>
                <hr />
                <Link to="/" className="connexion_link">
                  <button className="btn btn-primary btn-lg volkeno-green-bcolor connexionBtn-link">Connectez-vous</button>
                </Link>
              </div>
            </div>
          }
        </section>
      </div>
    );
  }
}
