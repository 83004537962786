import React, {Component} from 'react';
import './detailsRencontreByLevel.css';
import Menu from './../menu/Menu'
import { NavLink } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import API from '../../variablesGlobales';
import axios from 'axios';


export default class detailsRencontreByLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          rencontres: [],
          detailsRencontres: [],
          isPageLoading: false,
          rencontreName: window.sessionStorage.getItem('rencontreName'),
          rencontreType: window.sessionStorage.getItem('rencontreType'),
          participantFullname: '',
          participant: [],
          participantCount: 0,
          emptyData: false,
          requestError: false,

        };
    }

    componentDidMount(){
      const { match: { params } } = this.props;
      this.setState({
        level: params.level
      })
      this.getDetailsCampagneRecrutementByLevel(params.rencontre_id, params.level);
    }

    getDetailsCampagneRecrutementByLevel = (rencontreID, level) => {
      this.setState({
        isPageLoading: true
      });

      console.log('sadio')

      axios.get(this.state.api + 'details-rencontre-by-level/' + rencontreID + '/' + level)
        .then(response => {
          if(response.data.success === true){
            console.log(response.data.data);
            this.setState({
              isPageLoading: false,
              detailsRencontres: response.data.data,
              participantCount: response.data.count
            })
          }else{
            console.log(response)
            this.setState({
              isPageLoading: false,
              emptyData: true
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false,
            requestError: true
          })
        })
    }


    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }
  
    createCustomClearButton = (onClick) => {
      return (
        <ClearSearchButton
          btnText='Effacer'
          btnContextual='btn-warning'
          className='my-custom-class'
          onClick={e => this.handleClearButtonClick(onClick)} />
      );
    }
  
    getParticipantInfos = (participant) => {
      console.log(participant)
      this.setState({
        participantFullname: participant.participantFullname,
        participantEmail: participant.participantEmail,
        participantPhone: participant.participantPhone,
        participantVilleResidende: participant.ville_residence,
        participantFormationSuivie: participant.formation_suivie,
        participantNiveauEtude: participant.niveau_etude,
        participant: participant
      })
    }
  
    actionsFormatter = (cell, row) => {
      return (
        <div className="actions-btn-container">
          <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#showInfosModal" onClick={(e) => this.getParticipantInfos(row, e)}>
            <i className="fas fa-eye"></i>
          </button>
        </div>
      );
    }
  
    fullnameFormatter = (cell, row) => {
      return (
        <span type="button" className="participantFullname fullname-link" id="pFullname" data-toggle="modal" data-target="#showInfosModal" onClick={(e) => this.getParticipantInfos(row, e)} >
          {cell}
        </span>
      );
    }
  
    cvFormatter = (cell, row) => {
      return (
        <span>
          <a href={cell} alt="CV Link" target="_blank" rel="noopener noreferrer" className="cv_link text-center"><i className="fas fa-file"></i></a>
        </span>
      );
    }
  
    createCustomButtonGroup = props => {
      return (
        <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
          { props.exportCSVBtn }
          {/* <button type='button'
            className={ `btn btn-primary volkeno-red-bcolor` }>
            MyCustomBtn
          </button> */}
        </ButtonGroup>
      );
    }



    render() {
      const options = {
        clearSearch: true,
        clearSearchBtn: this.createCustomClearButton,
        btnGroup: this.createCustomButtonGroup
      };
  
      if (this.state.isPageLoading) {
        return (
          <div className="component-details-rencontre-by-level">
            <Menu />
            <div className="col-lg-12">
              <h1 className="page-header">
                <span className="page-title">
                  [{this.state.rencontreName}] - &nbsp;
                  Participants en &nbsp;
                  {this.state.level === 'master' ?
                    <span>master</span> :
                    this.state.level === 'licence' ?
                      <span>licence</span> :
                      <span>Bac+2 et moins</span>
                  }
                </span>
                <div className="pull-right">
                  <ol className="breadcrumb">
                    <li>
                      <NavLink to="/dashboard-admin" activeClassName="active">Accueil</NavLink>
                    </li>
                    <li>
                      <NavLink to="/meetings" activeClassName="active">Gestion des rencontres</NavLink>
                    </li>
                    <li className="active">Gestion des participant</li>
                  </ol>
                </div>
              </h1>
            </div>
  
            <div className="detailsRencontre_container">
              <div className="table_container">
                <table className="table table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Prénom et Nom</th>
                      <th scope="col">Email</th>
                      <th scope="col">Téléphone</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <div id="loader"></div>
                  </tbody>
                </table>
  
              </div>
            </div>
              
          </div>
        );
      }

      return(
        <div className="component-details-rencontre-by-level">
          <Menu />
        <div className="col-lg-12">
          <h1 className="page-header">
            <span className="page-title">
              [{this.state.rencontreName}] - &nbsp;
              Participants en &nbsp;
              {this.state.level === 'master' ?
                <span>master</span> :
                this.state.level === 'licence' ?
                  <span>licence</span> :
                  <span>Bac+2 et moins</span>
              }
            </span>
            <div className="pull-right">
              <ol className="breadcrumb">
                <li>
                  <NavLink to="/dashboard-admin" activeClassName="active">Accueil</NavLink>
                </li>
                <li>
                  <NavLink to="/meetings" activeClassName="active">Gestion des rencontres</NavLink>
                </li>
                <li className="active">Gestion des participant</li>
                
              </ol>
            </div>
          </h1>
        </div>

        <div className="detailsRencontre_container">
          {this.state.emptyData &&
            <div className="alert alert-warning empty-details-rencontre text-center">
              <p>Vous n'avez pour le moment aucun participant à cette rencontre.</p>
              <hr />
              <div className="return-btn-container">
                <NavLink to="/meetings" activeClassName="active" className="btn btn-info volkeno-green-bcolor return-btn">
                  <i className="fas fa-arrow-circle-left"></i> &nbsp;&nbsp;
                  Gestion des rencontres
                </NavLink>
              </div>
            </div>
          }

            <div className="table_container">
              <BootstrapTable data={this.state.detailsRencontres}
                striped={true} hover={true} condensed={true}
                multiColumnSort={2} options={options}
                exportCSV
                pagination
                search={true}>
                <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                <TableHeaderColumn dataField='participantFullname' dataFormat={this.fullnameFormatter.bind(this)} >Nom complet</TableHeaderColumn>
                <TableHeaderColumn dataField='participantEmail'>Email</TableHeaderColumn>
                <TableHeaderColumn dataField='participantPhone'>Téléphone</TableHeaderColumn>
                <TableHeaderColumn dataField='domaine'>Domaine</TableHeaderColumn>
                <TableHeaderColumn filter={{ type: 'TextFilter', delay: 1000 }} dataField='ville_residence'>Ville de résidence</TableHeaderColumn>
                <TableHeaderColumn filter={{ type: 'TextFilter', delay: 1000 }} dataField='diplome_obtenu'>Diplôme</TableHeaderColumn>
                <TableHeaderColumn dataField='cvLink' dataFormat={this.cvFormatter.bind(this)}>CV</TableHeaderColumn>
                <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)}>Actions</TableHeaderColumn>
              </BootstrapTable>
            </div>

          </div>

          {/* Show participant infos modal */}
        <div className="modal fade" id="showInfosModal" tabIndex="-1" role="dialog" aria-labelledby="showInfosModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showInfosModalLabel">Detail sur {this.state.participantFullname} </h4>
              </div>
              <div className="modal-body">
                <div className="item_container">
                  <span className="item_label">Email du participant : </span> <br />
                  <span className="label_content"> {this.state.participantEmail} </span>
                </div> <hr />
                <div className="item_container">
                  <span className="item_label">Téléphone du participant : </span> <br />
                  <span className="label_content"> {this.state.participantPhone} </span>
                </div> <hr />

                  <div className="interview-details-participant">
                    <div className="item_container">
                      <span className="item_label">Motivations : </span> <br />
                      <span className="label_content"> {this.state.participant.motivations} </span>
                    </div> <hr />
                    <div className="item_container">
                      <span className="item_label">Formation suivie : </span> <br />
                      <span className="label_content"> {this.state.participant.formation_suivie} </span>
                    </div> <hr />
                    <div className="item_container">
                      <span className="item_label">Niveau d'étude : </span> <br />
                      <span className="label_content"> {this.state.participant.niveau_etude} </span>
                    </div> <hr />
                    <div className="item_container">
                      <span className="item_label">Diplôme obtenu : </span> <br />
                      <span className="label_content"> {this.state.participant.diplome_obtenu} </span>
                    </div> <hr />
                    <div className="item_container">
                        <span className="item_label">Stage effectué : </span> <br />
                        {this.state.participant.stage_ornot === true ?
                          <div className="label label-success">Ce candidat a déjà effectué un stage de {this.state.participant.nbre_mois_stage} </div>
                          :
                          <div className="label label-info"> Ce candidat n'a jamais effectué un stage en entreprise </div>
                        }
                    </div> <hr />
                    <div className="item_container">
                      <span className="item_label">Ordinateur et/ou Smartphone : </span> <br /><br />
                      {this.state.participant.pc_ornot === true ?
                          <div className="label label-success"> Ce candidat a un ordinateur. </div>
                        :
                        <div className="label label-info"> Ce candidat n'a pas un ordinateur. </div>
                      }
                      <br />
                      {this.state.participant.smartphone_ornot === true ?
                          <div className="label label-success"> Ce candidat a un smartphone. </div>
                        :
                        <div className="label label-info"> Ce candidat n'a pas un smartphone. </div>
                      }
                    </div> <hr />
                  </div>

                <div className="modal-footer">

                </div>
              </div>
            </div>
          </div>
        </div>

        </div>
      );
    }
  }
