import React, { Component } from 'react';
import './gestionTache.css'
import { Link, NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import $ from 'jquery';
// import { Linkify } from 'react-linkify';
import MultiSelect from "@khanacademy/react-multi-select";
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';
import { MagicSpinner } from "react-spinners-kit";


export default class gestionTache extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        if (!token) {
            window.history.back()
        }
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.updateTBBHandle = this.updateTBBHandle.bind(this);
        this.updateTacheByBakeliste = this.updateTacheByBakeliste.bind(this);
        this.getTacheID = this.getTacheID.bind(this);

        //this.getSelectedTache = this.getSelectedTache.bind(this);
        this.state = {
            coach: [],
            tache: [],
            fields: {},
            content: '',
            bakeliste: [],
            loading: true,
            api: API.API,
            assignationEncours: false,
            tachesByCoach: [],
            tachesByCoache: [],
            userStatus: window.sessionStorage.getItem('user_status'),
            userID: window.sessionStorage.getItem('user_id'),
            isAdmin: true,
            isCoach: false,
            bakelistesByCoach: [],
            tachesByBakeliste: [],
            tacheStatusUpdateField: {},
            tacheUpdatedInProgress: false,
            updateTacheID: 0,
            isUpdatedError: false,
            tacheContent: '',
            tacheState: '',
            tacheDuration: '',
            tacheDate: '',
            tacheTodoDate: '',
            tacheCoachID: 0,
            tacheID: 0,
            isTache: true,
            tacheBakelisteID: 0,
            utFields: {},
            utInProgress: false,
            updateTacheStatusError: false,
            assignationSuccessAlert: false,
            isAssignationError: false,
            updateTacheStatusSuccess: false,
            updateTacheRequestSuccessAlert: false,
            updateTacheRequestError: false,
            selectOptions: [],
            selected: [],
            onlySelect: false,
            groupySelect: false,
            noSavedAndSavedTrueAlert: false,
            noSavedData: [],
        };
        this.utHandleChange = this.utHandleChange.bind(this);
        this.updateTacheByCoach = this.updateTacheByCoach.bind(this);
        Sentry.init({dsn: "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964"});

    
    }

    componentDidMount() {
        console.log(this.state.userStatus + " status")
        if (this.state.userStatus === 'coach') {
            this.setState({
                isCoach: true,
                isAdmin: false
            })
            this.getTacheByCoach(this.state.userID)
            console.log(this.getTacheByCoach(this.state.userID), "salut")
        }
        this.getAllTaches();
        this.getTachesAssignedByCoach();
        this.getBakelisteByCoach();
        this.getTachesByBakeliste();
    }

    componentDidCatch(err, info){
        Sentry.captureException(err);
    }

    getAllTaches = () => {
        axios.get(this.state.api + 'taches')
            .then(res => {
                this.setState({
                    loading: false
                })
                if(res.data.data !== undefined){
                    this.setState({
                        tache: res.data.data.reverse(),
                    });
                }
                //console.log(this.state.tache);
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }
    getTachesByBakeliste = () => {
        if (this.state.userStatus === 'bakeliste') {
            axios.get(this.state.api + 'taches-by-bakeliste/' + this.state.userID)
                .then(response => {
                    if (response.data.success === true) {
                        let tachesByBakeliste = response.data.data;
                        console.log(this.tachesByBakeliste, "tachebybakeliste")
                        if(tachesByBakeliste !== undefined){
                            this.setState({
                                tachesByBakeliste: tachesByBakeliste.reverse()
                            })
                        }
                    } else {
                        console.log(response.data.success);
                    }
                })
                .catch(error => {
                    if (process.env.NODE_ENV !== 'production') {
                        return;
                    }
                    Sentry.captureException(error);
                })
        }
    }
        

    getTacheByCoach(coach_id) {
        //console.log(this.state.tachesByCoach + "baxma")
        let tachesByCoach = this.state.tachesByCoach;
        let allTaches = this.state.tache;
        //console.log(allTaches.length)
        if (allTaches.length > 0) {
            // allTaches.forEach((tache, index) => {
            //     if (coach_id === tache.coach_id) {
            //         tachesByCoach.push(tache)
            //     }
            // });
            allTaches.forEach(function(tache) {
                if (coach_id === tache.coach_id) {
                    tachesByCoach.push(tache)
                }
            });
        }

        if (tachesByCoach !== undefined) {
            this.setState({
                tachesByCoach: tachesByCoach
            })
        }
    }

    getTachesAssignedByCoach = () => {
        axios.get(this.state.api + `taches-by-coach/${this.state.userID}`)
            .then(res => {
                if(res.data.data !== undefined){
                    this.setState({
                        tachesByCoache: res.data.data.reverse(),
                        loading: false
                    });
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    // Bakeliste by coach
    getBakelisteByCoach() {
        axios.get(this.state.api + 'bakelistes-by-coach/' + this.state.userID)
            .then(response => {
                var data = response.data.data;
                //console.log('coach bakelistes ', data)
                if (data !== undefined) {
                    this.setState({
                        bakelistesByCoach: data
                    })
                }
                data.forEach((item) => {
                    let infos = 
                        {
                            label: item.first_name + ' ' + item.last_name,
                            value: item.id
                        }
                    
                    this.state.selectOptions.push(infos)
                })
                //console.log(this.state.selectOptions)
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    /**
     * Activate only select assignation
     */
    onOnlyAssignation = () => {
        this.setState({
            onlySelect: true,
            groupySelect: false
        })
    }

    /**
     * Activate groupy select assignation
     */
    onGroupyAssignation = () => {
        this.setState({
            onlySelect: false,
            groupySelect: true
        })
    }

    handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'successAssignation') {
                this.setState({
                    assignationSuccessAlert: false
                });
            }
            if (label === 'errorAssignation') {
                this.setState({
                    isAssignationError: false
                });
            }
            if(label === 'successStatusUpdate'){
                this.setState({
                    updateTacheStatusSuccess: false
                })
            }
            if(label === 'tacheRequestSuccess'){
                this.setState({
                    updateTacheRequestSuccessAlert: false
                })
            }
            if(label === 'tacheRequestError'){
                this.setState({
                    updateTacheRequestError: false
                })
            }
            if(label === 'errorStatusUpdate'){
                this.setState({
                    isUpdatedError: false,
                    updateTacheStatusError: false
                })
            }
        }, 5000);
    }

    // assigner une tache a un bakeliste
    assignTache() {
        this.setState({
            assignationEncours: true
        });

        let data = this.state.fields;

        if(this.state.onlySelect){
            data["is_only"] = 1;
            data["is_groupy"] = 0;
            data["selected_bakeliste"] = null;
            data["bakeliste_user_id"] = this.state.selected;
        }else if(this.state.groupySelect){
            data["is_groupy"] = 1;
            data["is_only"] = 0;
            data["selected_bakeliste"] = this.state.selected;
        }

        console.log(data)

        axios.post(this.state.api + 'taches', data)
            .then(res => {
                if (res.data.success === true && res.data.nosavedStatus === false) {
                    this.getTachesAssignedByCoach();
                    $('.close').click();
                    document.getElementById("create-tache-form").reset();
                    $("#date_tach").val('');
                    this.setState({
                        assignationEncours: false,
                        assignationSuccessAlert: true,
                        fields: {}
                    });

                    this.handleShowAndHideAlert('successAssignation')
                    
                }else if(res.data.success === true && res.data.nosavedStatus === true){
                    this.getTachesAssignedByCoach();
                    $('.close').click();
                    document.getElementById("create-tache-form").reset();
                    $("#date_tach").val('');
                    this.setState({
                        assignationEncours: false,
                        assignationSuccessAlert: true,
                        noSavedAndSavedTrueAlert: true,
                        noSavedData: res.data.nosaved,
                        fields: {}
                    });

                    this.handleShowAndHideAlert('successAssignation')
                }else if(res.data.success === false && res.data.nosavedStatus === true){
                    this.setState({
                        assignationEncours: false,
                        isAssignationError: true
                    });

                    this.handleShowAndHideAlert('errorAssignation')
                }else{
                    this.setState({
                        assignationEncours: false,
                        isAssignationError: true
                    });

                    this.handleShowAndHideAlert('errorAssignation')
                }
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    assignationEncours: false,
                    isAssignationError: true
                });
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    renderNa(id) {
        if(this.state.tachesByCoach !== undefined){
            this.state.tachesByCoache.forEach((x) => {
                if (x.id === id) {
                    this.setState({
                        nameTacheByCoache: x.name
                    })
                }
    
                //console.log("super" + x.bakeli_user_id);
            })
    
            return (
                <td><Link to="#">{this.state.nameTache} </Link></td>
            )
        }
        
    }

    renderName(id) {
        if(this.state.tache !== undefined){
            this.state.tache.forEach((x) => {
                if (x.id === id) {
                    this.setState({
                        nameTache: x.name
                    })
                }
    
                //console.log("super" + x.bakeli_user_id);
            })

            return (
                <td><Link to="#">{this.state.nameTache} </Link></td>
            )
        }  
    }


    handleChange(e) {
        let fields = this.state.fields;
        fields["coach_user_id"] = this.state.userID;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
        console.log(fields)
    }

    // Retourne la liste de toutes les tâches assignées à un bakeliste
    getTacheID = (tache) => {
        //console.log(tache.id)
        window.sessionStorage.setItem('btache_id', tache.id);
    }

    tacheStateFormatter(cell, row) {
        return (
            <span>
                {row.state === 'todo' &&
                    <span className="label label-danger" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
                }
                {row.state === 'doing' &&
                    <span className="label label-warning" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
                }
                {row.state === 'done' &&
                    <span className="label label-success" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
                }
            </span>
        );
    }

    getTacheInfos = (tache) => {
       // console.log(tache);
        window.sessionStorage.setItem('tacheContent', tache.content);
        window.sessionStorage.setItem('tacheState', tache.state);
        window.sessionStorage.setItem('tacheDuration', tache.duration);

        if (this.state.isTache) {
            this.setState({
                tacheBakelisteID: tache.bakeli_user_id,
                tacheContent: tache.content,
                tacheState: tache.state,
                tacheDuration: tache.duration,
                tacheDate: tache.dateForEdit,
                tacheTodoDate: tache.todo_date,
                tacheCoachID: tache.coach_user_id,
                tacheURL: tache.tache_url,
                tacheID: tache.id
            })
        }
        //console.log(tache.bakeli_user_id)
    }

    tacheFormatter = (cell, row) => {
        return (
            <span className="modal-linky fullname-link" data-placement="top" title="Visualiser la tâche" data-toggle="modal" data-target="#tacheInfosModal" onClick={() => this.getTacheInfos(row)}><linkify> {row.content} </linkify></span>
        );
    }

    updateTBBHandle = (e) => {
        let fields = this.state.tacheStatusUpdateField;
        fields[e.target.name] = e.target.value;

        this.setState({
            tacheStatusUpdateField: fields
        });
        console.log(fields);
    }

    updateTacheByBakeliste = (e) => {
        e.preventDefault();

        this.setState({
            tacheUpdatedInProgress: true
        })
        let tacheID = window.sessionStorage.getItem('btache_id');
        //console.log(tacheID)
        let updateData = this.state.tacheStatusUpdateField;

        axios.post(this.state.api + 'update-tache-status-by-bakeliste/' + tacheID, updateData)
            .then(response => {
                if (response.data.success === true) {
                    this.getAllTaches();
                    this.getTachesByBakeliste();
                    this.getTachesAssignedByCoach();
                    $('.close').click();
                    document.getElementById('update-tache-status-bybakeliste').reset();
                    this.setState({
                        tacheUpdatedInProgress: false,
                        updateTacheStatusSuccess: true
                    })

                    this.handleShowAndHideAlert('successStatusUpdate');
                    
                }else {
                    this.setState({
                        isUpdatedError: true,
                        tacheUpdatedInProgress: false,
                        updateTacheStatusError: true
                    })

                    this.handleShowAndHideAlert('errorStatusUpdate');
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isUpdatedError: true,
                    tacheUpdatedInProgress: false,
                    updateTacheStatusError: true
                })
                this.handleShowAndHideAlert('errorStatusUpdate');
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }


    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    actionsFormatter = (cell, row) => {
        return (
            <span>
                <button onClick={() => { this.getTacheInfos(row) }} id="show_item" className="btn btn-info show-item-btn" data-toggle="modal" data-target="#tacheInfosModal">
                    <i className="fas fa-eye"></i>
                </button> &nbsp;&nbsp;
                <button onClick={() => { this.getTacheInfos(row) }} id="edit_item" className="btn btn-info edit-btn volkeno-orange-bcolor" data-toggle="modal" data-target="#editTacheModal">
                    <i className="fas fa-edit"></i>
                </button>
            </span>

        );
    }

    utHandleChange = (e) => {
        let fields = this.state.utFields;
        fields[e.target.name] = e.target.value;
        fields["bakeli_user_id"] = this.state.tacheBakelisteID;
        fields["coach_user_id"] = this.state.tacheCoachID;
        //console.log(this.state.selected)
        if (e.target.name === 'content') {
            this.setState({
                tacheContent: e.target.value
            })
        }

        if (e.target.name === 'tache_url') {
            this.setState({
                tacheURL: e.target.value
            })
        }

        if (e.target.name === 'state') {
            this.setState({
                tacheState: e.target.value
            })
        }
        if (e.target.name === 'duration') {
            this.setState({
                tacheDuration: e.target.value
            })
        }
        if (e.target.name === 'todo_date') {
            this.setState({
                tacheTodoDate: e.target.value
            })
        }

        if (!fields["content"]) {
            fields["content"] = this.state.tacheContent;
        }
        if (!fields["tache_url"]) {
            fields["tache_url"] = this.state.tacheURL;
        }
        if (!fields["duration"]) {
            fields["duration"] = this.state.tacheDuration;
        }
        if (!fields["state"]) {
            fields["state"] = this.state.tacheState;
        }
        if (!fields["todo_date"]) {
            fields["todo_date"] = this.state.tacheTodoDate;
        }

        this.setState({
            utFields: fields
        })

        console.log(fields)
    }

    updateTacheByCoach = (e) => {
        e.preventDefault();
        //console.log(this.state.utFields);
        this.setState({
            utInProgress: true
        });

        let tacheID = this.state.tacheID;
        let updateData = this.state.utFields;

        axios.put(this.state.api + 'taches/' + tacheID, updateData)
            .then(response => {
                this.setState({
                    utInProgress: false
                });
                if(response.data.success === true){
                    this.getTachesAssignedByCoach();
                    $('.close').click();
                    document.getElementById('edit-tache-bycoach').reset();
                    this.setState({
                        utFields: {},
                        updateTacheRequestSuccessAlert: true
                    })
                    this.handleShowAndHideAlert('tacheRequestSuccess')
                }else{
                    console.log(response);
                    this.setState({
                        updateTacheRequestError: true
                    })
                    this.handleShowAndHideAlert('tacheRequestError')
                }
            })
            .catch(error => {
                this.setState({
                    updateTacheRequestError: true
                })
                this.handleShowAndHideAlert('tacheRequestError')
                
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    coachFullnameFormatter = (cell, row) => {
        return(
            <NavLink to="#" className="bakeliste_coachname"> {cell} </NavLink>
        );
    }

    render() {
        const options = {
            // clearSearch: true,
            clearSearchBtn: this.createCustomClearButton
        };
        if (this.state.loading) {
            return (
                <div>
                    <Menu />
                    <div className="ListProspects row" id="assignBody">
                        <div className="col-lg-12">
                            <h1 className="page-header">
                                <span className="page-title">Gestion des Taches </span>
                                <div className="pull-right">
                                    <ol className="breadcrumb">
                                        <li>
                                            <Link to="/dashboard-admin">Accueil</Link>
                                        </li>
                                        <li className="active">Gestion des taches</li>
                                    </ol>
                                </div>
                            </h1>
                        </div>

                        <div className="col-md-12 reporting-container">

                            {this.state.userStatus === 'coach' &&
                                <div className="row add_coach_btn_row">
                                    <div className="col-md-6 col-md-offset-3 add-coach-btn-container">
                                        <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#myModal" >
                                            <i className="fas fa-plus-circle"></i>
                                        </button>
                                    </div>
                                </div>
                            }


                            <div className="tachebakeliste_container">
                                <MagicSpinner size={150} color="#009688" loading={this.state.loading} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <Menu />
                <div className="ListProspects row" id="assignBody">
                    <div className="col-lg-12">
                        <h1 className="page-header">
                            <span className="page-title">Gestion des Taches</span>
                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard-admin">Accueil</Link>
                                    </li>
                                    <li className="active">Gestion des taches</li>
                                </ol>
                            </div>
                        </h1>
                    </div>
                    <div className="col-md-12 reporting-container">
                        {this.state.userStatus === 'coach' &&
                            <div className="row add_coach_btn_row">
                                <div className="col-md-6 col-md-offset-3 add-coach-btn-container">
                                    <button type="button" className="btn btn-info add_tache_btn button addBtn" id="btn_assign" data-toggle="modal" data-target="#myModal" >
                                        <i className="fas fa-plus-circle"></i>
                                    </button>
                                </div>
                            </div>
                        }

                        <div className="coach_container">
                            <div className="table_container">
                                {this.state.userStatus === 'admin' &&
                                    <div className="tacheView-admin-container">
                                        <BootstrapTable data={this.state.tache}
                                            striped={true} hover={true} condensed={true}
                                            multiColumnSort={2} options={options}
                                            pagination
                                            search={true}>
                                            <TableHeaderColumn width="250" dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                            <TableHeaderColumn width="250" dataField='frenchDateFormat' dataSort={true}>Date</TableHeaderColumn>
                                            <TableHeaderColumn width="250" dataField='bakeliste_fullname'
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataSort={true}>Nom complet</TableHeaderColumn>
                                            <TableHeaderColumn width="350" dataField='content' dataFormat={this.tacheFormatter.bind(this)}>Tâche</TableHeaderColumn>
                                            <TableHeaderColumn width="100" dataField='duration'
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataSort={true}>Délais</TableHeaderColumn>
                                            <TableHeaderColumn width="250" dataField='coachFullname'
                                                dataFormat={this.coachFullnameFormatter.bind(this)}
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataSort={true}>Coach</TableHeaderColumn>
                                            <TableHeaderColumn width="250" dataField='state' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.tacheStateFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>
                                            {/* <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                        </BootstrapTable>
                                    </div>
                                }

                                {this.state.userStatus === 'coach' &&
                                    <div className="tache-table-coach">
                                        {this.state.noSavedAndSavedTrueAlert && 
                                            <div className="row no-saved-row">
                                                <div className="col-md-10 col-md-offset-1 no-saved-col">
                                                    <div className="box box-danger box-solid">
                                                        <div className="box-header with-border">
                                                            <h3 className="box-title"> <i className="fas fa-exclamation-triangle fa-2x"></i> ALERTE NON ASSIGNATION</h3>

                                                            <div className="box-tools pull-right">
                                                                <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
                                                            </div>
                                                        </div>
                                                        <div className="box-body">
                                                            <p>Vous venez d'effectuer une affectation de tâche. Mais toutes les assignations n'ont pas été effectives.</p>
                                                            <p>Veuillez trouver ci aprés les bakelistes à qui leur affectation de tâche n'a pas été effective : 
                                                                <ul className="nosaved-ul">
                                                                    {this.state.noSavedData.map((item, index) => 
                                                                        <li key={index} className="nosaved-item">
                                                                            <b>{item}</b>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </p>
                                                            <p>Nous vous conseillons de réessayer sinon d'effectuer une affectation unique pour chaque bakeliste listé ci dessus.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <BootstrapTable data={this.state.tachesByCoache}
                                            striped={true} hover={true} condensed={true}
                                            multiColumnSort={2} options={options}
                                            pagination
                                            search={true}>
                                            <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                            <TableHeaderColumn dataField='bakeliste_fullname' >Nom complet</TableHeaderColumn>
                                            <TableHeaderColumn dataField='content' dataFormat={this.tacheFormatter.bind(this)}>Tâche</TableHeaderColumn>
                                            <TableHeaderColumn dataField='frenchDateFormat'>Date</TableHeaderColumn>
                                            <TableHeaderColumn dataField='duration'>Délais</TableHeaderColumn>
                                            <TableHeaderColumn dataField='state' dataFormat={this.tacheStateFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>
                                            <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                }

                                {this.state.userStatus === 'bakeliste' && this.state.tachesByBakeliste !== undefined ?
                                    <BootstrapTable data={this.state.tachesByBakeliste}
                                        striped={true} hover={true} condensed={true}
                                        multiColumnSort={2} options={options}
                                        pagination
                                        search={true}>
                                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                        {/* <TableHeaderColumn dataField='bakelisteFullname' >Nom complet</TableHeaderColumn> */}
                                        <TableHeaderColumn dataField='content' dataFormat={this.tacheFormatter.bind(this)}>Tache</TableHeaderColumn>
                                        <TableHeaderColumn dataField='duration'>Délais</TableHeaderColumn>
                                        <TableHeaderColumn dataField='state' dataFormat={this.tacheStateFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>
                                        <TableHeaderColumn dataField='frenchDateFormat' dataSort={true}>Date</TableHeaderColumn>
                                    </BootstrapTable> : ''
                                }



                                <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <form id="create-tache-form">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="myModalLabel">Assigner une Tache </h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row add-form row">
                                                    <div className="col-md-12 form-group assignation-type-choice">
                                                        <div className="item-label">Choisir un type d'assignation</div> 
                                                        <input type="radio" name="only-groupy" value="only" className="item1" onClick={this.onOnlyAssignation} /> <span className="radioItem">Assignation unique</span> <br />
                                                        <input type="radio" name="only-groupy" value="groupy" className="item2" onClick={this.onGroupyAssignation} /> <span className="radioItem">Assignation groupée</span>
                                                    </div>
                                                    {/* Assignation unique select view */}
                                                    {this.state.onlySelect &&
                                                        <div className="form-group col-md-6">
                                                            <label >Bakeliste:</label>
                                                            <select className="form-control" id="" name="bakeli_user_id" onChange={this.handleChange}>
                                                                <option>--Choisir un bakeliste--</option>
                                                                {this.state.bakelistesByCoach.map((x, y) =>
                                                                    <option value={x.id} key={y}>{x.first_name}&nbsp;{x.last_name}</option>)
                                                                }

                                                            </select>
                                                        </div>
                                                    }
                                                    {/* Assignation groupée select view // */}
                                                    {this.state.groupySelect &&
                                                        <div className="form-group col-md-12">
                                                            <MultiSelect
                                                                overrideStrings={{
                                                                    selectSomeItems: "Choisissez les bakelistes",
                                                                    allItemsAreSelected: "Tous vos bakelistes on été choisis",
                                                                    selectAll: "Sélectionnez tout",
                                                                    search: "Rechercher",
                                                                }}
                                                                options={this.state.selectOptions}
                                                                selected={this.state.selected}
                                                                onSelectedChanged={selected => this.setState({selected})}
                                                            />
                                                            
                                                        </div>
                                                    }
                                                    <div className="form-group col-md-6">
                                                    <label> Date : </label>
                                                        <input type="date" name="todo_date" id="date_tach" className="form-control" onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label >Tâche:</label>
                                                        <textarea type="text" className="form-control" id="inputTache" placeholder="tache asigner au bakeliste" name="content" onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label >URL associé à la tâche : <span className="alerty"> (Laissez ce champ vide s'il n'ya pas d'URL associé à la tâche) </span></label>
                                                        <input type="text" className="form-control" id="inputURL" placeholder="URL associé à la tâche" name="tache_url" onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Durée:</label>
                                                        <input type="text" className="form-control" id="inputpDuration" placeholder="Durée tache. (Exemple : 6h)" name="duration" onChange={this.handleChange} />
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label >Status:</label>
                                                        <select className="form-control" id="inputStatus" name="state" onChange={this.handleChange} >
                                                            <option>Choisir un statut</option>
                                                            <option value="todo">A faire</option>
                                                            <option value="doing">en cours</option>
                                                            <option value="done">terminer</option>
                                                        </select>
                                                    </div>

                                                </div>

                                                {this.state.isAssignationError && 
                                                    <div className="row">
                                                        <div className="alert alert-danger col-md-12 error-alert text-center">
                                                            Un probléme est survenu lors de l'assignation. Veuillez réessayer plus tard.
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                    <i className="fas fa-times-circle"></i> &nbsp;
                                                    Quitter
                                                </button>
                                                {!this.state.assignationEncours &&
                                                    <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.assignTache(e)}>
                                                        <i className="fas fa-user-check"></i>&nbsp;
                                                        Assigner
                                                </button>
                                                }
                                                {this.state.assignationEncours &&
                                                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                        Assignation en cours &nbsp;
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                    </button>
                                                }
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                {/* Update tache status by bakeliste modal */}
                                <div className="modal fade" id="utModal" tabIndex="-1" role="dialog" aria-labelledby="utModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="utModalLabel">Modifier le status d'une tâche</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="ut_form_container">
                                                    <form id="update-tache-status-bybakeliste">
                                                        <div className="form-group " >
                                                            <select className="form-control" id="tstatus" name="state" onChange={this.updateTBBHandle}>
                                                                <option>--Choisir un statut--</option>
                                                                <option value="todo">A Faire</option>
                                                                <option value="doing">En Cours</option>
                                                                <option value="done">Terminé</option>
                                                            </select>
                                                        </div>
                                                        {this.state.updateTacheStatusError &&
                                                            <div className="form-group alert alert-danger update-error">
                                                                Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                            </div>
                                                        }
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-danger pull-left bsi-cancel-btn" data-dismiss="modal">
                                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                                Quitter
                                                            </button>
                                                            {!this.state.tacheUpdatedInProgress &&
                                                                <button onClick={(e) => this.updateTacheByBakeliste(e)} type="submit" className="btn btn-primary bsi-submit-btn pull-right">
                                                                    <i className="fas fa-edit"></i> &nbsp;
                                                                    Modifier
                                                                </button>
                                                            }
                                                            {this.state.tacheUpdatedInProgress &&
                                                                <button type="button" className="btn btn-warning bsi-inprogress-btn pull-right">
                                                                    Modification en cours &nbsp;
                                                                    <i className="fas fa-spinner fa-spin"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {/* <div className="modal-footer">

                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                {/* Show tache infos by bakeliste and coach */}
                                <div className="modal fade" id="tacheInfosModal" tabIndex="-1" role="dialog" aria-labelledby="tacheInfosModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="tacheInfosModalLabel">Détails de votre tâche</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="tache-infos-container">
                                                    <div className="tacheContent_container">
                                                        <span className="item_label">Tâche à effectuer : </span> <br />
                                                        <span className="label_content"> <linkify> {this.state.tacheContent} </linkify> </span>
                                                    </div> <hr />
                                                    <div className="urlContent_container">
                                                        <span className="item_label">URL associé à la tâche : </span> <br />
                                                        {this.state.tacheURL !== null ?
                                                            <a href={this.state.tacheURL} alt="URL" className="tache_url-content" target="_blank" rel="noopener noreferrer">
                                                                <span className="label_content tacheURL"> Cliquez pour accéder </span>
                                                            </a> :
                                                            <span className="label label-info"> Aucun URL n'est associé à cette tâche </span>
                                                        }
                                                    </div> <hr />
                                                    <div className="tacheState_container">
                                                        <span className="item_label">Statut de la tâche : </span> <br />
                                                        {this.state.tacheState === 'todo' &&
                                                            <span className="label_content label label-danger">A Faire</span>
                                                        }
                                                        {this.state.tacheState === 'doing' &&
                                                            <span className="label_content label label-warning">En Cours</span>
                                                        }
                                                        {this.state.tacheState === 'done' &&
                                                            <span className="label_content label label-success">Terminé</span>
                                                        }
                                                    </div> <hr />
                                                    <div className="tacheDuratiupdateTacheByCoachon_container">
                                                        <span className="item_label">Durée de la tâche : </span> <br />
                                                        <span className="label_content badge">{this.state.tacheDuration}</span>
                                                    </div> <hr />
                                                </div>
                                            </div>
                                            {/* <div className="modal-footer">

                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                {/* Edit tâche by coach */}
                                <div className="modal fade" id="editTacheModal" tabIndex="-1" role="dialog" aria-labelledby="editTacheModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="editTacheModalLabel">Modification tâche</h4>
                                            </div>
                                            <form id="edit-tache-bycoach">
                                            <div className="modal-body">
                                                <div className="tache-editForm-container">
                                                    <div className="form-group">
                                                        <div className="form-group ">
                                                            <label >Bakeliste:</label>
                                                            <select value={this.state.tacheBakelisteID} className="form-control" id="inputBakeliste" name="bakeli_user_id" onClick={this.handleChange}>
                                                                <option>Choisir un bakeliste</option>
                                                                {this.state.bakelistesByCoach.map((x, y) =>
                                                                    <option value={x.id}>{x.first_name}&nbsp;{x.last_name}</option>)
                                                                }

                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <input value={this.state.tacheDate} type="date" name="todo_date" className="form-control" onChange={this.utHandleChange} />
                                                        </div>
                                                        <div className="form-group ">
                                                            <label >Tache:</label>
                                                            <textarea value={this.state.tacheContent} type="text" className="form-control" id="inputTache" placeholder="tache asigner au bakeliste" name="content" onChange={this.utHandleChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label >URL associé à la tâche : <span className="alerty"> (Laissez ce champ vide s'il n'ya pas d'URL associé à la tâche) </span></label>
                                                            <input value={this.state.tacheURL} type="text" className="form-control" id="inputURL" placeholder="URL associé à la tâche" name="tache_url" onChange={this.utHandleChange} />
                                                        </div>
                                                        <div className="form-group ">
                                                            <label >Durée:</label>
                                                            <input value={this.state.tacheDuration} type="text" className="form-control" id="inputpDuration" placeholder="durée tache" name="duration" onChange={this.utHandleChange} />
                                                        </div>

                                                        <div className="form-group ">
                                                            <label >Status:</label>
                                                            <select value={this.state.tacheState} className="form-control" id="inputStatus" name="state" onChange={this.utHandleChange} >
                                                                <option>Choisir un statut</option>
                                                                <option value="todo">A faire</option>
                                                                <option value="doing">en cours</option>
                                                                <option value="done">terminer</option>
                                                            </select>
                                                        </div>
                                                        
                                                        {this.state.updateTacheRequestError === true &&
                                                            <div className="form-group alert alert-danger error-container">
                                                                Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger pull-left bsi-cancel-btn" data-dismiss="modal">
                                                    <i className="fas fa-times-circle"></i> &nbsp;
                                                    Quitter
                                                </button>
                                                {!this.state.utInProgress &&
                                                    <button type="submit" className="btn btn-primary bsi-submit-btn pull-right" onClick={this.updateTacheByCoach}>
                                                        <i className="fas fa-edit"></i> &nbsp;
                                                        Modifier
                                                    </button>
                                                }
                                                {this.state.utInProgress &&
                                                    <button type="button" className="btn btn-warning bsi-inprogress-btn pull-right">
                                                        Modification en cours &nbsp;
                                                                    <i className="fas fa-spinner fa-spin"></i>
                                                    </button>
                                                }
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    {/* Assignation tache success toast */}
                    {this.state.assignationSuccessAlert === true &&
                        <div className="alert alert-success text-center upsuccess-alert">
                            Tâche affectée avec succès.
                        </div>
                    }
                    
                    {/* Update tache status by bakeliste success toast */}
                    {this.state.updateTacheStatusSuccess === true &&
                        <div className="alert alert-success text-center upsuccess-alert">
                            Le statut de la tâche a été modifié avec succès.
                        </div>
                    }

                    {/* Update tache by coach success toast */}
                    {this.state.updateTacheRequestSuccessAlert === true &&
                        <div className="alert alert-success text-center upsuccess-alert">
                            Le statut de la tâche a été modifié avec succès.
                        </div>
                    }
                </div>
            </div>
        );
    }
}
// export default connect(
//     ({ gestionTache }) => ({ ...gestionTache }),
//     dispatch => bindActionCreators({ ...gestionTacheActions }, dispatch)
//   )( gestionTache );