import React, {Component} from 'react';
import './historiques.scss'
import { Link} from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import Menu from './../menu/Menu';
import { Divider, List,Header, Icon} from 'semantic-ui-react';
// import TimeAgo from 'timeago-react';


export default class historiques extends Component {
  constructor(props) {
    super(props);
      this.state = {
        api: API.API,
        historiques:[],
        loading:true
      };
   }

  componentDidMount() {
    this.getAllHistoriques();
}

// get all message
getAllHistoriques = () => {
axios.get(this.state.api + 'historiques')
    .then(res => {
        console.log('Alls', res.data.data)
        if (res.data.success === true) {
            this.setState({
                historiques: res.data.data.reverse(),
                loading: false
            });
        } else {
            this.setState({
                loading: false,
                isEmptyData: true
            })
            console.log(res)
        }
    })
    .catch(error => {
        this.setState({
            loading: false,
        })
        console.log(error.message)
    })
}

formatdateFrence =(date) => {
  const date2 = new Date(date);

  const fullYear = date2.getUTCFullYear();
  const  day = date2.getDay();
  const  month = date2.getMonth();

  const event = new Date(Date.UTC(fullYear,month,day));

const options = {year: 'numeric', month: 'long', day: 'numeric' };
return date = event.toLocaleDateString(undefined, options);

}

getFirstLetter(string1,string2){

  return string1.charAt(0).toUpperCase()
     +string2.charAt(0).toUpperCase();
  }

    render() {
      return (
        <div className="component-manage-users">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
          Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">
             <Divider horizontal width={4}>
                <Header as='h1'>
                  <Icon name='history' size='huge' />
                        Historiques
                </Header>
              </Divider>
          <div className="row">
            {this.state.historiques.map(item =>(
                <div className="col-md-6" >
                  <List relaxed='very' className='list-historique'>
                    <List.Item >
                       
                      <List.Content className='content-historique'>
                        <List.Header id='item-date'>
                        <div className="mb-3 mx-auto historique">
                           <h3 className="rounded-circle" >
                              {this.getFirstLetter(item.first_name,item.last_name)}
                            </h3>
                        </div>
                          {this.formatdateFrence(item.created_at)}
                          
                          </List.Header>
                        <List.Description>
                          La formation de {' '}
                          <Link to='#'>
                          <b>{item.bakeliste_full_name}</b>
                          </Link>{' '}
                          à été modifié  par  <Link to='#'><b>{item.admin_full_name}</b>{' '}</Link> 
                        </List.Description>
                      </List.Content>
                    </List.Item> 
                  </List> 
            </div>
              ))
            }
        </div>
        </div>
     </div>
      );
    }
  }
// export default connect(
//     ({ historiques }) => ({ ...historiques }),
//     dispatch => bindActionCreators({ ...historiquesActions }, dispatch)
//   )( historiques );