import React, {Component} from 'react';
import './noterMonCoach.scss'
import Menu from './../menu/Menu';
//import Iframe from 'react-iframe';


export default class noterMonCoach extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-noter-mon-coach">
        <Menu />
        <div className="col-md-12 noter-mon-coach-bk">
          <div class="embed-responsive embed-responsive-16by9">
            {/* <iframe title="iframe" class="embed-responsive-item" src="http://ratings.bakeli.tech" allowfullscreen></iframe> */}
            {/* <Iframe url="http://ratings.bakeli.tech"
                     width="450px"
                     height="450px"
                     title="iframe"
                     className="embed-responsive-item"
                     
                    allowfullscreen/>   */}
            <embed
              src="http://ratings.bakeli.tech"
              width="450px"
              height="450px"
              title="embeb"
              className="embed-responsive-item"
            />
          </div>
        </div>
      </div>
    );
  }
}
