import React, { Component } from 'react';
import './ListeBakelistes.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import $ from 'jquery';
import * as Sentry from '@sentry/browser';
import Collapse from "@kunukn/react-collapse";
import { MagicSpinner } from "react-spinners-kit";



export default class ListeBakelistes extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');

        if (!token) {
            window.location = '/';
        }
        if (userStatus === 'bakeliste' || userStatus === 'coach') {
            window.history.back();
        }

        super(props);
        this.getBakelisteEnFormation = this.getBakelisteEnFormation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getSelectedBakelist = this.getSelectedBakelist.bind(this);
        this.onCoachAssignation = this.onCoachAssignation.bind(this);
        this.renderNameCoach = this.renderNameCoach.bind(this);
        this.getBakelisteIDForShowingComment = this.getBakelisteIDForShowingComment.bind(this);
        this.updateHandleChange = this.updateHandleChange.bind(this);
        this.updateBakelisteInfos = this.updateBakelisteInfos.bind(this);
        this.handleChangeHiredBakeliste = this.handleChangeHiredBakeliste.bind(this);
        this.onSubmitHiredBakeliste = this.onSubmitHiredBakeliste.bind(this);
        this.getBakelisteInfos = this.getBakelisteInfos.bind(this);
        this.handleChangeImmersion = this.handleChangeImmersion.bind(this)
        this.onSubmitImmersionBakeliste = this.onSubmitImmersionBakeliste.bind(this);
        this.getBakelisteId = this.getBakelisteId.bind(this);
        this.handleChangeRecruitable = this.handleChangeRecruitable.bind(this);
        this.onRecruitableBakeliste = this.onRecruitableBakeliste.bind(this);

        this.state = {
            bakeliste: [],
            fields: {},
            domaines: [],
            coachs: [],
            loading: false,
            api: API.API,
            assignationEncours: false,
            assignationSuccessAlert: false,
            assignationErrorAlert: false,
            ress: [],
            user: [],
            dataBakeliste: {},
            completedInProgress: false,
            bakelisteIDForComment: 0,
            CoachComments: [],
            bakelisteFullname: '',
            emailValue: '',
            phoneValue: '',
            boursierValue: false,
            demiBoursierValue: false,
            stageFormationValue: false,
            isISAContractValue: false,
            lieuFormationValue: '',
            horaireValue: '',
            paiementInscriptionValue: false,
            debutFormationValue: '',
            finFormationValue: '',
            firstNameValue: '',
            lastNameValue: '',
            upFields: {},
            updateInProgress: false,
            bakelisteIDForUpdate: 0,
            isClosingModal: false,
            successAlert: false,
            bakelisteDetails: [],
            hiredBakelisteInProgress: false,
            successHiredAlert: false,
            errorHiredAlert: false,
            hiredFields: {},
            successCompletedFormationAlert: false,
            completedFormationRequestError: false,
            updateBakeliste: [],
            updateBakelisteRequestError: false,
            nbreBakeliste: 0,
            nbreBakelisteBoursier: 0,
            nbreBakelistePayant: 0,
            nbrebakelistesVF: 0,
            nbrebakelistesGY2: 0,
            nbrebakelistesGY1: 0,
            nbrebakelistesThies: 0,
            nbrebakelistesTamba: 0,
            showHiredForm: false,
            showImmersionForm: false,
            errorImmersionAlert: false,
            successImmersionAlert: false,
            imFields: {},
            imErrors: {},
            companies: [],
            isListBakelisteRequestError: false,
            recFields: {},
            recruitableNicknameValue: 'Talent_',
            recruitableEncours: false,
            successRecruitableAlert: false,
            isRecruitableRequestError: false,
            isExistUser: false,
            nbreBakelisteISA: 0,
            nbreBakelisteStageDF: 0,
            isOpen: false,

        };

    }
    componentDidMount() {
        this.getBakelisteEnFormation();
        this.getBakelistesCount();
        this.getAllActivesCoach();
        this.getAllDomaines();
        this.getCompaniesList();
        this.getNombreBakeliste();
        //$('.export-btn').after('<div>sadio</div>')
    }

    componentDidCatch(err, info) {
        Sentry.captureException(err);
    }

    getAllActivesCoach = () => {
        axios.get(this.state.api + 'liste-des-coachs')
            .then(res => {
                const coachsData = res.data;
                const listActiveCoach = [];
                coachsData.data.forEach((item) => {
                    if (item.coach_is_actif === 1) {
                        listActiveCoach.push(item)
                    }
                })
                this.setState({
                    coachs: listActiveCoach,
                });
                //console.log("coach list: ", this.state.coachs)
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getAllDomaines = () => {
        axios.get(this.state.api + 'domaines')
            .then(res => {
                const domains = res.data;
                this.setState({
                    domaines: domains.data,
                });
                //console.log(this.state.domaines)
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getBakelisteEnFormation = () => {
        this.setState({
            loading: true
        })
        axios.get(this.state.api + 'liste-des-bakelistes-en-formation')
            .then(res => {
                if(res.data.success){
                    let bakelistes = res.data.data;
                   //  console.log(bakelistes)
                    this.setState({
                        bakeliste: bakelistes.reverse(),
                        loading: false
                    });
                    $('.export-btn').after('<div class="alerty_immersion"><i class="fas fa-exclamation-circle"></i> &nbsp; Les bakeliste en couleur <span class="verty">noire et en gras</span> sont ceux qui sont en immersion.</div>');
                }else{
                    console.log(res)
                    this.setState({
                        loading: false
                    })
                }

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    isListBakelisteRequestError: true
                })
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    /**
     * Liste des entreprises partenaires
     */
    getCompaniesList = () => {
        axios.get(this.state.api + 'companies')
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        companies: response.data.data
                    })
                } else {
                    this.setState({
                        isGetCompaniesRequestError: true
                    })
                }
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    isGetCompaniesRequestError: true
                })
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    /**
   * Get bakeliste count in training
   */
    getNombreBakeliste() {
        axios.get(this.state.api + 'nbre-bakeliste')
        .then((response) => {
            const nbreBakeliste = response.data.data1;
            const nbreBakelisteBoursier = response.data.data2;
            const nbreBakelistePayant = response.data.data3;
            const nbrebakelistesVF = response.data.data4;
            const nbrebakelistesGY1 = response.data.data5;
            const nbrebakelistesGY2 = response.data.data6;
            const nbrebakelistesThies = response.data.data7;
            const nbrebakelistesTamba = response.data.data8;
            const nbreBakelisteISA = response.data.data9;
            const nbreBakelisteStageDF = response.data.data10;
            const nbreBakelisteDemiBoursier = response.data.data11;
            const nbreBakelisteFormationDiplomante = response.data.data12;
            const nbreBakelisteFormationCourte = response.data.data13;


            console.log('nombre bakeliste', response.data.data1);
            this.setState({
            nbreBakeliste: nbreBakeliste,
            nbreBakelisteBoursier: nbreBakelisteBoursier,
            nbreBakelistePayant: nbreBakelistePayant,
            nbrebakelistesVF: nbrebakelistesVF,
            nbrebakelistesGY2: nbrebakelistesGY2,
            nbrebakelistesGY1: nbrebakelistesGY1,
            nbrebakelistesThies: nbrebakelistesThies,
            nbrebakelistesTamba: nbrebakelistesTamba,
            nbreBakelisteISA: nbreBakelisteISA,
            nbreBakelisteStageDF: nbreBakelisteStageDF,
            nbreBakelisteDemiBoursier: nbreBakelisteDemiBoursier,
            nbreBakelisteFormationDiplomante: nbreBakelisteFormationDiplomante,
            nbreBakelisteFormationCourte: nbreBakelisteFormationCourte
            });
        })
        .catch((error) => {
            console.log(error);
        });
    }

    handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'closeModal') {
                this.setState({
                    isClosingModal: true
                });
            }
            if (label === 'closeSuccessAlert') {
                this.setState({
                    successAlert: false
                })
            }
            if (label === 'closeErrorAlert') {
                this.setState({
                    updateBakelisteRequestError: false
                })
            }
            if (label === 'successHired') {
                this.setState({
                    successHiredAlert: false
                })
            }
            if (label === 'errorHired') {
                this.setState({
                    errorHiredAlert: false
                })
            }
            if (label === 'successCompletedFormation') {
                this.setState({
                    successCompletedFormationAlert: false
                })
            }
            if (label === 'errorCompletedFormation') {
                this.setState({
                    completedFormationRequestError: false
                })
            }
            if (label === 'successImmersionAlert') {
                this.setState({
                    successImmersionAlert: false
                })
            }
            if (label === 'errorImmersionAlert') {
                this.setState({
                    errorImmersionAlert: false
                })
            }
            if (label === 'assignationSuccessAlert') {
                this.setState({
                    assignationSuccessAlert: false
                })
            }
            if (label === 'assignationErrorAlert') {
                this.setState({
                    assignationErrorAlert: false
                })
            }
            if (label === 'successRecruitable') {
                this.setState({
                    successRecruitableAlert: false
                })
            }
            if (label === 'errorRecruitable') {
                this.setState({
                    isRecruitableRequestError: false
                })
            }
            if (label === 'isExistUser') {
                this.setState({
                    isExistUser: false
                })
            }
        }, 5000);
    }

    getBakelistesCount = () => {
        axios.get(this.state.api + 'nbre-bakeliste')
            .then((response) => {
                const nbreBakeliste = response.data.data1;
                const nbreBakelisteBoursier = response.data.data2;
                const nbreBakelistePayant = response.data.data3;
                const nbrebakelistesVF = response.data.data4;
                const nbrebakelistesGY1 = response.data.data5;
                const nbrebakelistesGY2 = response.data.data6;
                const nbrebakelistesThies = response.data.data7;
                const nbrebakelistesTamba = response.data.data8;
                //console.log('nombre bakeliste', response.data.data1);
                this.setState({
                    nbreBakeliste: nbreBakeliste,
                    nbreBakelisteBoursier: nbreBakelisteBoursier,
                    nbreBakelistePayant: nbreBakelistePayant,
                    nbrebakelistesVF: nbrebakelistesVF,
                    nbrebakelistesGY2: nbrebakelistesGY2,
                    nbrebakelistesGY1: nbrebakelistesGY1,
                    nbrebakelistesThies: nbrebakelistesThies,
                    nbrebakelistesTamba: nbrebakelistesTamba
                });
            })
            .catch((error) => {
                console.log(error);
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            });
    }

    renderName(id) {
        if (id != null) {
            this.state.domaines.forEach((domaine) => {
                if (domaine.id === id) {
                    this.setState({
                        nameDomain: domaine.name
                    })
                }
            })
            return (
                <td><Link to="#">{this.state.nameDomain}</Link></td>
            )
        }
        else {
            return (
                <td> Domaine inconnu</td>
            )
        }
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    getSelectedBakelist(x) {
        this.getAllActivesCoach();
        this.setState({
            selectedBakelist: x
        })
        return x.id
    }

    getBakelisteInfos = (bakeliste) => {
        console.log(bakeliste);

        this.setState({
            bakelisteIDForUpdate: bakeliste.id,
            bakelisteFullname: bakeliste.bakelisteFullname,
            emailValue: bakeliste.email,
            phoneValue: bakeliste.phone,
            boursierValue: bakeliste.boursier,
            demiBoursierValue: bakeliste.demi_boursier,
            domaineFormationValue: bakeliste.bakeliste_domaine_id,
            stageFormationValue: bakeliste.is_stagede_formation,
            formationPayanteValue: bakeliste.is_formation_payante,
            isISAContractValue: bakeliste.is_isa_contract,
            lieuFormationValue: bakeliste.lieu_formation,
            nbreJoursValue: bakeliste.nbreJours,
            horaireValue: bakeliste.horaire,
            paiementInscriptionValue: bakeliste.paiement_inscription,
            debutFormationValue: bakeliste.debut,
            finFormationValue: bakeliste.fin,
            firstNameValue: bakeliste.first_name,
            lastNameValue: bakeliste.last_name,
            updateBakeliste: bakeliste
        }, () => {
            console.log(this.state.updateBakeliste)
        })

    }

    onCoachAssignation = (e) => {
        this.setState({
            assignationEncours: true
        });
        console.log(this.state.selectedBakelist.id)
        const id_bakelist = this.state.selectedBakelist.id

        const dataForAssign = {
            bakeliste_id: id_bakelist,
            coach_id: this.state.fields.coachSelect

        }
        axios.post(this.state.api + 'assigner-un-coach', dataForAssign)
            .then(res => {
                console.log(res.data);
                if (res.data.success) {
                    this.getBakelisteEnFormation();
                    //e.target.reset();
                    $('#assignation-form')[0].reset()
                    $('.close').click();
                    this.setState({
                        assignationEncours: false,
                        assignationSuccessAlert: true
                    });
                    //window.location.reload();
                    this.handleShowAndHideAlert('assignationSuccessAlert')
                } else {
                    console.log(res)
                    this.setState({
                        assignationEncours: false,
                        assignationErrorAlert: true
                    })
                    this.handleShowAndHideAlert('assignationErrorAlert')
                }
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    assignationEncours: false,
                    assignationErrorAlert: true
                })
                this.handleShowAndHideAlert('assignationErrorAlert')
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })

    }

    renderNameCoach(coach_id) {
        let name = ""
        this.state.coachs.forEach((x) => {
            if (x.id === coach_id) {
                name = x.first_name + ' ' + x.last_name
            }
        })
        return name
    }
    Detail = (item) => {
        this.setState({
            selectedBakelist: item,
            dataBakeliste: item
        })

    }
    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleCompletedFormation = (bakeliste) => {
        this.setState({
            completedInProgress: true
        });

        let bakelisteID = bakeliste.id;
        let data = {};

        axios.post(this.state.api + 'completed-bakelistes-formation/' + bakelisteID, data)
            .then(response => {
                this.setState({
                    completedInProgress: false
                });

                if (response.data.success === true) {
                    this.getBakelisteEnFormation();
                    this.getBakelistesCount();
                    this.setState({
                        successCompletedFormationAlert: true
                    })
                    this.handleShowAndHideAlert('successCompletedFormation')
                } else {
                    console.log(response);
                    this.setState({
                        completedFormationRequestError: true,
                    })
                    this.handleShowAndHideAlert('errorCompletedFormation')
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    completedFormationRequestError: true,
                    completedInProgress: false
                })
                this.handleShowAndHideAlert('errorCompletedFormation')
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    coachRowFormatter = (cell, row) => {
        return (
            <div className="coach_row actions-btn-container">
                {row.coach_id ?
                    <Link to="#">
                        {cell}
                    </Link> :
                    <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#myModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
                        <i className="fas fa-user-check"></i>
                    </button>

                }
            </div>
        );
    }


    getCoachCommentToBakeliste = () => {

        let bakelisteID = window.sessionStorage.getItem('bakelisteIDForShowComment');

        axios.get(this.state.api + 'coach-comments-by-bakeliste/' + bakelisteID)
            .then(response => {
                if (response.data.success === true) {
                    console.log('success')
                    if (response.data.data !== undefined) {
                        this.setState({
                            CoachComments: response.data.data
                        });
                    }
                } else {
                    console.log('empty')
                    console.log('Commentaire non trouvé')
                }
            })
            .catch(error => {
                this.setState({
                    isGetCommentsRequestError: true
                });
                console.log(error)
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getBakelisteIDForShowingComment(x) {
        console.log(x.id);

        this.setState({
            CoachComments: [],
            bakelisteIDForComment: x.id
        });
        window.sessionStorage.setItem('bakelisteIDForShowComment', x.id)
        this.getCoachCommentToBakeliste();
    }

    getBakelisteForHired = (bakeliste) => {
        console.log(bakeliste)
        this.setState({
            bakelisteDetails: bakeliste
        }, () => {
            console.log(this.state.bakelisteDetails)
        })
        window.sessionStorage.setItem('hiredBakelisteID', bakeliste.id);
        window.sessionStorage.setItem('hiredBakelisteCoachID', bakeliste.coach_id);
        window.sessionStorage.setItem('hiredBakelisteFullname', bakeliste.bakelisteFullname);
        // console.log(this.state.bakelisteDetails)
    }

    handleChangeHiredBakeliste(e) {
        let fields = this.state.hiredFields;
        fields[e.target.name] = e.target.value;

        fields["bakeliste_id"] = window.sessionStorage.getItem('hiredBakelisteID');
        fields["coach_id"] = window.sessionStorage.getItem('hiredBakelisteCoachID');

        this.setState({
            hiredFields: fields
        })
        console.log(fields)
    }

    onSubmitHiredBakeliste = (e) => {
        e.preventDefault();
        this.setState({
            hiredBakelisteInProgress: true
        })

        let hiredData = this.state.hiredFields;
        let bakelisteID = window.sessionStorage.getItem('hiredBakelisteID');

        axios.post(this.state.api + 'placements', hiredData)
            .then(response => {
                if (response.data.success === true) {
                    this.onChangeBakelisteHiredStatus(bakelisteID);
                    this.getBakelisteEnFormation();
                    this.getBakelistesCount();
                    $('.close').click();
                    document.getElementById('hired-form').reset();
                    this.setState({
                        hiredBakelisteInProgress: false,
                        successHiredAlert: true,
                        hiredFields: {}
                    })
                    this.handleShowAndHideAlert('successHired')
                } else {
                    this.setState({
                        hiredBakelisteInProgress: false,
                        errorHiredAlert: true
                    })
                    this.handleShowAndHideAlert('errorHired')
                }
            })
            .catch(error => {
                this.setState({
                    hiredBakelisteInProgress: false,
                    errorHiredAlert: true
                })
                this.handleShowAndHideAlert('errorHired')
                console.log(error)
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    onChangeBakelisteHiredStatus = (bakeliste_id) => {
        axios.post(this.state.api + 'hired-bakeliste-status/' + bakeliste_id)
            .then(response => {
                if (response.data.success === true) {
                    console.log(response)
                } else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    /**
     * Redirect Admin to bakeliste reporting
     */
    redirectToBakelisteReporting = (bakeliste) => {
        window.sessionStorage.setItem('bakelisteIDForReporting', bakeliste.id);
        window.sessionStorage.setItem('bakelisteReportingFullname', bakeliste.first_name + ' ' + bakeliste.last_name);
        window.sessionStorage.setItem('redirect_origin', 'list-bakeliste');
        window.location = 'reportings-by-bakeliste';
    }

    actionsFormatter = (cell, row) => {
        return (
            <div className="actions-btn-container">
                <button type="button" className="btn btn-info reassigner si-tooltip" id="btn_assign" data-toggle="modal" data-target="#myModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
                    <i className="fas fa-user-check"></i>
                    {/* <span className="tooltiptext">Assigner un coach</span> */}
                </button>
                <button type="button" className="btn btn-warning si-tooltip" id="btn_update" data-toggle="modal" data-target="#editBakelisteModal" onClick={(e) => this.getBakelisteInfos(row, e)}>
                    <i className="fas fa-edit"></i>
                    {/* <span className="tooltiptext">Modifier les infos</span> */}
                </button>
                <NavLink to={'/show-bakeliste-textual-contents/' + row.id + '/admin-view'} type="button" className="btn btn-info showtc si-tooltip" id="btn_show_tc">
                    <i className="fas fa-file-alt"></i>
                    <span className="tc-counter"> {row.textualContentCount} </span>
                    {/* <span className="tooltiptext">Voir les contenus créés</span> */}
                </NavLink>
                <button onClick={() => { this.redirectToBakelisteReporting(row) }} id="show_item" className="btn btn-info show-item-btn si-tooltip" data-toggle="tooltip" data-placement="top" title="Voir le reporting">
                    <i className="fas fa-list-ul"></i>
                    {/* <span className="tooltiptext">Voir le reporting</span> */}
                </button>
                <button onClick={(e) => this.getBakelisteIDForShowingComment(row, e)} type="button" className="btn btn-success volkeno-green-bcolor pseudo-comment si-tooltip" id="btn_update" data-toggle="modal" data-target="#CommentModal">
                    <i className="fas fa-comment"></i>
                    <span className="comment-counter"> {row.commentCount} </span>
                    {/* <span className="tooltiptext">Voir les commentaires du coach</span> */}
                </button>
                <button onClick={(e) => this.getBakelisteForHired(row, e)} type="button" className="btn btn-success volkeno-orange-color-wb si-tooltip" id="btn_update" data-toggle="modal" data-target="#hireBakelisteModal">
                    <i className="fab fa-hire-a-helper"></i>
                    {/* <span className="tooltiptext">Déclarer le placement/immersion</span> */}
                </button>
                {row.is_recruitable === 0 ?
                    <button onClick={() => this.getBakelisteId(row)} id="btn_recruitable" data-toggle="modal" data-target="#recruitableModal">
                        <i className="fas fa-thumbs-up"></i>
                    </button> :
                    <NavLink to={'/show-bakeliste-cv/' + row.id + '/admin-view'} type="button" className="btn btn-info showcv" id="btn_show_cv">
                        <i className="fas fa-list"></i>
                    </NavLink>
                }
                <button data-toggle="tooltip" type="button" className="btn btn-danger terminer-formation si-tooltip" id="completed"
                    onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter la formation de ce bakeliste ?") &&
                        this.handleCompletedFormation(row)}
                >
                    {this.state.completedInProgress ?
                        <i className="fas fa-spin fa-spinner"></i> :
                        <i className="fas fa-stop-circle"></i>
                    }
                    {/* <span className="tooltiptext">Arrêter la formation</span> */}
                </button>
            </div>
        );
    }

    getBakelisteId = (bakeliste) => {
        console.log(bakeliste.id)
        window.sessionStorage.setItem('recruitableBakelisteId', bakeliste.id);
    }

    bakelisteFullnameFormatter = (cell, row) => {
        return (
            <span className="fullname-link open-infos-modal" data-toggle="modal" data-target="#moModal" onClick={(e) => this.Detail(row, e)}>{cell}</span>
        );
    }

    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    updateHandleChange = (e) => {
        let fields = this.state.upFields;
        fields[e.target.name] = e.target.value;

        if (e.target.name === 'email') {
            this.setState({
                emailValue: e.target.value
            })
        }
        if (e.target.name === 'phone') {
            this.setState({
                phoneValue: e.target.value
            })
        }
        if (e.target.name === 'boursier') {
            this.setState({
                boursierValue: e.target.value
            })
        }
        if (e.target.name === 'demi_boursier') {
            this.setState({
                demiBoursierValue: e.target.value
            })
        }
        if (e.target.name === 'is_stagede_formation') {
            this.setState({
                stageFormationValue: e.target.value
            })
        }
        if (e.target.name === 'is_formation_payante') {
            this.setState({
                formationPayanteValue: e.target.value
            })
        }
        if (e.target.name === 'is_isa_contract') {
            this.setState({
                isISAContractValue: e.target.value
            })
        }
        if (e.target.name === 'lieu_formation') {
            this.setState({
                lieuFormationValue: e.target.value
            })
        }
        if (e.target.name === 'horaire') {
            this.setState({
                horaireValue: e.target.value
            })
        }
        if (e.target.name === 'bakeliste_domaine_id') {
            this.setState({
                domaineFormationValue: e.target.value
            })
        }
        if (e.target.name === 'paiement_inscription') {
            this.setState({
                paiementInscriptionValue: e.target.value
            })
        }
        if (e.target.name === 'debut_formation') {
            this.setState({
                debutFormationValue: e.target.value
            })
        }
        if (e.target.name === 'fin_formation') {
            this.setState({
                finFormationValue: e.target.value
            })
        }
        if (e.target.name === 'first_name') {
            this.setState({
                firstNameValue: e.target.value
            })
        }
        if (e.target.name === 'last_name') {
            this.setState({
                lastNameValue: e.target.value
            })
        }


        if (!fields["first_name"]) {
            fields["first_name"] = this.state.firstNameValue;
        }
        if (!fields["last_name"]) {
            fields["last_name"] = this.state.lastNameValue;
        }
        if (!fields["email"]) {
            fields["email"] = this.state.emailValue;
        }
        if (!fields["phone"]) {
            fields["phone"] = this.state.phoneValue;
        }
        if (!fields["boursier"]) {
            fields["boursier"] = this.state.updateBakeliste.boursier;
        }
        if (!fields["demi_boursier"]) {
            fields["demi_boursier"] = this.state.demiBoursierValue;
        }
        if (!fields["is_stagede_formation"]) {
            fields["is_stagede_formation"] = this.state.stageFormationValue;
        }
        if (!fields["is_formation_payante"]) {
            fields["is_formation_payante"] = this.state.formationPayanteValue;
        }
        if (!fields["is_isa_contract"]) {
            fields["is_isa_contract"] = this.state.isISAContractValue;
        }
        if (!fields["lieu_formation"]) {
            fields["lieu_formation"] = this.state.lieuFormationValue;
        }
        if (!fields["horaire"]) {
            fields["horaire"] = this.state.horaireValue;
        }
        if (!fields["bakeliste_domaine_id"]) {
            fields["bakeliste_domaine_id"] = this.state.domaineFormationValue;
        }
        if (!fields["paiement_inscription"]) {
            fields["paiement_inscription"] = this.state.paiementInscriptionValue;
        }
        if (!fields["debut_formation"]) {
            fields["debut_formation"] = this.state.debutFormationValue;
        }
        if (!fields["fin_formation"]) {
            fields["fin_formation"] = this.state.finFormationValue;
        }

        this.setState({
            upFields: fields
        })

        console.log(fields)
    }
       historique = (data) =>{
         return axios.post(this.state.api + 'historiques', data)
         .then(res => res.data ? res.data: res)
        }

    updateBakelisteInfos = (e) => {
        e.preventDefault();
        console.log(this.state.upFields);
        this.setState({
            updateInProgress: true
        })
        let bakelisteID = this.state.bakelisteIDForUpdate;
        let data = this.state.upFields;
        // let admin_id = window.sessionStorage.getItem('user_id');
        let historique = {};
        historique["bakeliste_id"] = bakelisteID;
       // historique["admin_id"] = admin_id;
        historique["debut_date"] = this.state.debutFormationValue;
        historique["fin_date"] = this.state.finFormationValue;
        historique["text"] = 'la formation de ' +this.state.firstNameValue+' '+this.state.lastNameValue + ' à été modifié' ;
       
         // data['admin_id'] =  admin_id
        axios.put(this.state.api + 'update-bakeliste-infos/' + bakelisteID, data)
            .then(response => {
                this.setState({
                    updateInProgress: false
                });
                //console.log(response.data.data)
                    /* let  histo = this.historique(historique);
                    console.log('historique',histo) */
                     if (response.data.success === true) {
                    // window.location = 'liste-bakelistes';
                    this.getBakelisteEnFormation();
                    $('.close').click();
                    console.log('click')
                    $('body').removeClass('modal-open');
                    console.log('open')
                    $('.modal-backdrop').removeClass('modal-backdrop');
                    console.log('backdrop')
                    $('.fade').removeClass('fade')
                    $('.in').removeClass('in')
                    this.setState({
                        successAlert: true,
                        upFields: {}
                    });
                    this.handleShowAndHideAlert('closeSuccessAlert');
                }else if(response.data.success === false && response.data.exist === true){
                    this.setState({
                        isExistUser: true
                    });
                    this.handleShowAndHideAlert('isExistUser');
                }
                 else {
                    console.log(response)
                    this.setState({
                        updateBakelisteRequestError: true
                    })
                    this.handleShowAndHideAlert('closeErrorAlert');
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    updateInProgress: false,
                    updateBakelisteRequestError: true
                })
                this.handleShowAndHideAlert('closeErrorAlert');
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getDomaineName = (name) => {
        window.sessionStorage.setItem('domaineNameForRedirectedPage', name);
    }

    createCustomButtonGroup = props => {
        return (
            <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
                {props.exportCSVBtn}
                {/* <button type='button'
              className={ `btn btn-primary volkeno-red-bcolor` }>
              MyCustomBtn
            </button> */}
            </ButtonGroup>
        );
    }


    typeFormationFormatter = (cell, row) => {
        if (cell === 'courte') {
            return (
                <span className="label label-success volkeno-green-bcolor"> formation courte </span>
            );
        } else if (cell === 'diplomante') {
            return (
                <span className="label label-warning volkeno-orange-bcolor"> formation diplômante </span>
            );
        }
    }

    /**
     * Show hired form
     */
    onOpenHiredForm = () => {
        this.setState({
            showHiredForm: true,
            showImmersionForm: false
        })
    }

    /**
     * Show immersion form
     */
    onOpenImmersionForm = () => {
        this.setState({
            showHiredForm: false,
            showImmersionForm: true
        })
    }

    /**
     * Return back to choice menu in modal
     */
    onReturnActionChoice = () => {
        this.setState({
            showHiredForm: false,
            showImmersionForm: false
        })
    }

    /**
     * Initialise forms
     */
    initialiseForm = () => {
        this.setState({
            showHiredForm: false,
            showImmersionForm: false
        })
    }

    /**
     * Handle change immersion declaration
     */
    handleChangeImmersion = (e) => {
        let fields = this.state.imFields;
        fields[e.target.name] = e.target.value;
        fields["bakeliste_id"] = this.state.bakelisteDetails.id;
        this.setState({
            imFields: fields
        })
        console.log(fields)
    }

    /**
     * Submit immersion declaration
     */
    onSubmitImmersionBakeliste = (e) => {
        e.preventDefault();
        this.setState({
            immersionDeclarationInProgress: true,
            immersionBakelisteID: this.state.imFields.bakeliste_id
        })

        let data = this.state.imFields;

        axios.post(this.state.api + 'immersions', data)
            .then(response => {
                if (response.data.success) {
                    this.onChangeBakelisteImmersionStatus();
                    this.getBakelisteEnFormation();
                    this.getBakelistesCount();
                    $('.close').click();
                    document.getElementById('hired-form').reset();
                    this.setState({
                        immersionDeclarationInProgress: false,
                        successImmersionAlert: true
                    })
                    this.handleShowAndHideAlert('successImmersionAlert');
                } else {
                    console.log(response);
                    this.setState({
                        immersionDeclarationInProgress: false,
                        errorImmersionAlert: true
                    })
                    this.handleShowAndHideAlert('errorImmersionAlert');
                }
            })
            .catch(error => {
                console.log(error.message);
                this.setState({
                    immersionDeclarationInProgress: false,
                    errorImmersionAlert: true
                })
                this.handleShowAndHideAlert('errorImmersionAlert');
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    /**
     * Change bakeliste immersion status
     */
    onChangeBakelisteImmersionStatus = () => {
        let bakeliste_id = this.state.immersionBakelisteID;
        axios.post(this.state.api + 'start-immersion/' + bakeliste_id)
            .then(response => {
                if (response.data.success === true) {
                    console.log(response)
                } else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    /**
     * Immersion notification
     */
    rowClassNameFormat = (row, rowIdx) => {
        if (row.in_immersion === 1 && row.trainingIsCompleted === 1) {
            return 'bakeliste_in_immersion is_completed_training';
        }
        if (row.in_immersion === 1 && row.trainingIsCompleted === 0) {
            return 'bakeliste_in_immersion';
        }
        if (row.in_immersion === 0 && row.trainingIsCompleted === 1) {
            return 'is_completed_training';
        }
    }

    /**
     * Training since formatter
     */
    inTrainingSinceFormatter = (cell, row) => {
        if (cell < 1) {
            return (
                <span className="label label-success volkeno-green-bcolor"> {row.inTrainingSinceDays} jour(s) </span>
            )
        } else {
            return (
                <span className="label label-success volkeno-orange-bgcolor"> {row.inTrainingSinceMonths} mois </span>
            )
        }
    }

    /**
     * Handle change recruitable bakeliste declaration by coach
     */
    handleChangeRecruitable = (e) => {
        let fields = this.state.recFields;
        fields[e.target.name] = e.target.value;
        fields["label"] = 'recruitable';

        if (e.target.name === 'bakeliste_nickname') {
            this.setState({
                recruitableNicknameValue: e.target.value
            })
        }

        if (!fields["bakeliste_nickname"]) {
            fields["bakeliste_nickname"] = this.state.recruitableNicknameValue
        }

        this.setState({
            recFields: fields
        })

        console.log(fields)
    }

    /**
     * Handle submit recruitable bakeliste declaration by coach
     */
    onRecruitableBakeliste = (e) => {
        e.preventDefault();
        this.setState({
            recruitableEncours: true
        })

        console.log(this.state.recFields)

        let recruitable = this.state.recFields;
        let bakelisteID = window.sessionStorage.getItem('recruitableBakelisteId');

        axios.post(this.state.api + 'recruitable-bakeliste/' + bakelisteID, recruitable)
            .then(response => {
                if (response.data.success === true) {
                    this.getBakelisteEnFormation();
                    $('.close').click();

                    this.setState({
                        recruitableEncours: false,
                        successRecruitableAlert: true,
                        recruitableNicknameValue: 'Talent_'
                    })

                    this.handleShowAndHideAlert('successRecruitable')
                } else {
                    console.log(response)
                    this.setState({
                        recruitableEncours: false,
                        isRecruitableRequestError: true
                    })
                    this.handleShowAndHideAlert('errorRecruitable')
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    recruitableEncours: false,
                    isRecruitableRequestError: true
                })
                this.handleShowAndHideAlert('errorRecruitable')
            
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    onRowClick = (row) => {
        this.setState({
            selectedBakelist: row,
            dataBakeliste: row
        })
        $('.open-infos-modal').click();
    }


    render() {
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton,
            btnGroup: this.createCustomButtonGroup
        };

        if (this.state.loading) {
            return (
                <div className="component-ListeBakelistes">
                     <Menu /> 
                    <div className="createBakeliste row" >
                        

                        <div className="col-md-12 reporting-container">
                            <div className="row add_bakeliste_container">
                                <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                                    <NavLink data-toggle="tooltip" data-placement="top" title="Ajouter bakeliste" to="/ajouter-un-bakeliste" className="btn btn-success add_coach_btn button">
                                        <i className="fas fa-plus-circle"></i>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="list_bakeliste_container">
                                <MagicSpinner size={150} color="#009688" loading={this.state.loading} />
                            </div>
                        </div>
                    </div>

                </div>

            )
        }
        return (
            <div className="component-ListeBakelistes">
                 <Menu /> 
                <div className="createBakeliste row" >
                    
                    <div className="row count-bloc-container">
                        <div className="col-lg-4 col-md-6">
                            <NavLink to="/liste-bakelistes-volkeno-hgrand-yoff1" className="counterLink">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                <span className="info-box-icon bg-vgreen">
                                    <i className="fas fa-user"></i>
                                </span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Bakelistes aux HLM GY1</span>
                                    <span className="info-box-number">
                                    {this.state.nbrebakelistesGY1}
                                    </span>
                                </div>
                                </div>
                            </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <NavLink to="/liste-bakelistes-volkeno-hgrand-yoff2" className="counterLink">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                <span className="info-box-icon bg-vorange">
                                    <i className="fas fa-user"></i>
                                </span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Bakelistes aux HLM GY2</span>
                                    <span className="info-box-number">
                                    {this.state.nbrebakelistesGY2}
                                    </span>
                                </div>
                                </div>
                            </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <NavLink to="/liste-bakelistes-thies" className="counterLink">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                <span className="info-box-icon bg-vred">
                                    <i className="fas fa-user"></i>
                                </span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Bakelistes à Thiés</span>
                                    <span className="info-box-number">
                                    {this.state.nbrebakelistesThies} 
                                    </span>
                                </div>
                                </div>
                            </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <NavLink to="/liste-bakelistes-payants" className="counterLink">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-vgreen">
                                    <i className="fas fa-user-graduate" ></i>
                                    </span>

                                    <div className="info-box-content">
                                    <span className="info-box-text">Payants</span>
                                    <span className="info-box-number">
                                        {this.state.nbreBakelistePayant}
                                        {/* <small>%</small> */}
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <NavLink to="/liste-bakelistes-en-stage-de-formation" className="counterLink">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                    <               span className="info-box-icon bg-vorange">
                                    <i className="fas fa-user-graduate" ></i>
                                    </span>

                                    <div className="info-box-content">
                                    <span className="info-box-text">Stage de formation</span>
                                    <span className="info-box-number">
                                        {this.state.nbreBakelisteStageDF}
                                        {/* <small>%</small> */}
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <NavLink to="/liste-bakelistes-en-contrat-isa" className="counterLink">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-vred">
                                    <i className="fas fa-user-graduate" ></i>
                                    </span>

                                    <div className="info-box-content">
                                    <span className="info-box-text">Contrat ISA</span>
                                    <span className="info-box-number">
                                        {this.state.nbreBakelisteISA}
                                        {/* <small>%</small> */}
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <hr />

                    <Collapse isOpen={true}>
                        <div className="row count-item-row">
                            
                            {this.state.domaines.map((domaine, index) =>
                                <NavLink key={index} to={"/liste-bakelistes-by-domaine/" + domaine.id} className="counting-link" onClick={this.getDomaineName(domaine.name)}>
                                    <div className="col-md-4 count-item">
                                        <div className="row count-intern-row">
                                            <div className="col-md-4 count-intern-icon-container dcplb">
                                                <i className="fas fa-landmark fa-2x"></i>
                                            </div>
                                            <div className="col-md-8 count-intern-content">
                                                <span className="degree-title dcplb"> {domaine.name} </span> <br />
                                                <span className="count-number"> {domaine.nbreBakeliste}  </span>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            )}
                        </div>
                    </Collapse>
                    
                    <hr />

                    <div className="col-md-12 reporting-container">
                        <div className="row add_bakeliste_container">
                            <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                                <NavLink data-toggle="tooltip" data-placement="top" title="Ajouter bakeliste" to="/ajouter-un-bakeliste" className="btn btn-success add_coach_btn button">
                                    <i className="fas fa-plus-circle"></i>
                                </NavLink>
                            </div>
                        </div>
                        <div className="list_bakeliste_container">
                            <div className="table-bakelistes">
                                {/* <div className="row info_row">
                                    <div className="col-md-10 col-md-offset-1 release_info_col">
                                        <div className="box box-danger box-solid">
                                            <div className="box-header with-border">
                                                <h3 className="box-title">INFOS UTILES</h3>

                                                <div className="box-tools pull-right">
                                                    <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
                                                </div>
                                            </div>
                                            <div className="box-body">
                                                <div className="alerty_news">
                                                    Les bakelistes en background rouge sont en fin de formation.
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {this.state.isListBakelisteRequestError &&
                                    <div className="row liste-bakeliste-request-error-row">
                                        <div className="col-md-12 lbre-col">
                                            <div className="alert alert-danger lbre-alerty">
                                                Un probléme est survenu lors de la requête. Veuillez réessayer plus tard.
                                    </div>
                                        </div>
                                    </div>
                                }
                                <BootstrapTable data={this.state.bakeliste}
                                    striped={true} hover={true} condensed={true}
                                    multiColumnSort={2} options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                    trClassName={this.rowClassNameFormat}>
                                    <TableHeaderColumn dataAlign="center" width='450' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='300'
                                        dataField='bakelisteFullname'
                                        dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true} >Nom complet</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='250'
                                        dataField='email'
                                    >Email</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='200'
                                        dataField='phone'
                                    >Téléphone</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='300'
                                        dataField='domaine'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Domaine</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='300'
                                        dataField='inTrainingSinceMonths'
                                        dataFormat={this.inTrainingSinceFormatter}
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>En formation depuis</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='250'
                                        dataField='coachFullname'
                                        dataFormat={this.coachRowFormatter.bind(this)}
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Coach</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='250'
                                        dataField='type_formation'
                                        dataFormat={this.typeFormationFormatter.bind(this)}
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Type de formation</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='200'
                                        dataField='lieu_formation'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Espace de formation</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='250'
                                        dataField='ecole'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Ecole</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='150'
                                        dataField='horaire'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Horaire</TableHeaderColumn>

                                </BootstrapTable>



                                <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="myModalLabel">Selection du coach</h4>
                                            </div>
                                            <form id="assignation-form">
                                                <div className="modal-body">
                                                    <div className="form-group " >
                                                        <label >Coach:</label>
                                                        <select className="form-control" id="inputbakeli_domaine_id" name="coachSelect" onChange={this.handleChange}>
                                                            <option>--Choisir un coach--</option>
                                                            {this.state.coachs.map((z, y) => <option key={y} value={z.id}>{z.first_name} {z.last_name} - {'[ ' + z.nbreBakeliste + ' bakeliste(s) ]'}</option>)}
                                                        </select>
                                                    </div>

                                                    {this.state.assignationErrorAlert &&
                                                        <div className="row assignation-error-row">
                                                            <div className="col-md-12 assignation-error-col">
                                                                <div className="alert alert-danger assignation-alert-error">
                                                                    Un problème est survenu lors de l'assignation. Veuillez réessayer plus tard.
                                                            </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-default" data-dismiss="modal">Quitter</button>
                                                    {!this.state.assignationEncours &&
                                                        <button type="button" id="ass" className="btn btn-primary" onClick={(e) => this.onCoachAssignation(e)}>Assigner</button>
                                                    }
                                                    {this.state.assignationEncours &&
                                                        <button type="button" className="btn btn-warning">
                                                            Assignation en cours &nbsp;
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal fade" id="moModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="myModalLabel">Detail sur {this.state.dataBakeliste.first_name}&nbsp;{this.state.dataBakeliste.last_name}</h4>
                                            </div>
                                            <div className="modal-body">
                                                {this.state.dataBakeliste.type_formation === 'courte' ? (
                                                    this.state.dataBakeliste.nbreJours === '3' ? (
                                                        <div className="content_container">
                                                            <b>Jours de présences : </b> <br />
                                                            <span> {this.state.dataBakeliste.jour1}, {this.state.dataBakeliste.jour2}, {this.state.dataBakeliste.jour3} </span> <br />
                                                            <b>Heure de présence</b> <br />
                                                            <span className="horaire-bakeliste-item"> {this.state.dataBakeliste.horaire} </span>

                                                        </div>
                                                    ) :
                                                        this.state.dataBakeliste.nbreJours === '5' ? (
                                                            <div className="content_container">
                                                                <b>Jours de présences : </b> <br />
                                                                <span> {this.state.dataBakeliste.jour1}, {this.state.dataBakeliste.jour2}, {this.state.dataBakeliste.jour3}, {this.state.dataBakeliste.jour4}, {this.state.dataBakeliste.jour5} </span> <br />
                                                                <b>Heure de présence</b> <br />
                                                                <span className="horaire-bakeliste-item"> {this.state.dataBakeliste.horaire} </span>

                                                            </div>
                                                        ) :
                                                            this.state.dataBakeliste.nbreJours === '6' && (
                                                                <div className="content_container">
                                                                    <b>Jours de présences : </b> <br />
                                                                    <span> {this.state.dataBakeliste.jour1}, {this.state.dataBakeliste.jour2}, {this.state.dataBakeliste.jour3}, {this.state.dataBakeliste.jour4}, {this.state.dataBakeliste.jour5}, {this.state.dataBakeliste.jour6} </span> <br />
                                                                    <b>Heure de présence</b> <br />
                                                                    <span className="horaire-bakeliste-item"> {this.state.dataBakeliste.horaire} </span>
                                                                </div>
                                                            )
                                                ) :
                                                    this.state.dataBakeliste.type_formation === 'diplomante' && (
                                                        <div className="content_container">
                                                            <b>Jours de présences : </b> <br />
                                                            <span> Du Lundi au Vendredi </span> <br />
                                                            <b>Heure de présence</b> <br />
                                                            <span className="horaire-bakeliste-item"> {this.state.dataBakeliste.horaire} </span>

                                                        </div>
                                                    )
                                                }
                                                <div className="email_container">
                                                    <span className="item_label">Email : </span> <span className="label_content">{this.state.dataBakeliste.email}</span>
                                                </div> <hr />
                                                <div className="objectif_container">
                                                    <span className="item_label">Objectifs : </span> <span className="label_content">{this.state.dataBakeliste.objectifs}</span>
                                                </div> <hr />

                                                {this.state.dataBakeliste.ecole !== '' &&
                                                    <div className="ecole_container">
                                                        <span className="item_label">Ecole : </span> <span className="label_content">{this.state.dataBakeliste.ecole}</span> <hr />
                                                    </div>

                                                }
                                                <div className="email_container">
                                                    <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.dataBakeliste.phone}</span>
                                                </div> <hr />
                                                <div className="ecole_container">
                                                    <span className="item_label">Boursier : </span>
                                                    {this.state.dataBakeliste.boursier === 1 ?
                                                        <span className="label_content">OUI</span> :
                                                        <span className="label_content">NON</span>
                                                    }

                                                </div> <hr />
                                                <div className="email_container">
                                                    <span className="item_label">Lieu de formation : </span> <span className="label_content">{this.state.dataBakeliste.lieu_formation}</span>
                                                </div> <hr />
                                                <div className="email_container">
                                                    <span className="item_label">Type de formation : </span> <span className="label_content">{this.state.dataBakeliste.type_formation}</span>
                                                </div> <hr />
                                                <div className="ecole_container">
                                                    <span className="item_label">Formation payante : </span>
                                                    {this.state.dataBakeliste.is_formation_payante === 1 ?
                                                        <span className="label_content">OUI</span> :
                                                        <span className="label_content">NON</span>
                                                    }
                                                </div> <hr />
                                                <div className="email_container">
                                                    <span className="item_label">Date de début de la formation : </span> <span className="label_content">{this.state.dataBakeliste.startFrenchDateFormat}</span>
                                                </div> <hr />
                                                <div className="email_container">
                                                    <span className="item_label">Date de fin de la formation : </span> <span className="label_content">{this.state.dataBakeliste.endFrenchDateFormat}</span>
                                                </div> <hr />
                                            </div>
                                            <div className="modal-footer">

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Commentaires */}
                                <div className="modal fade" id="CommentModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="myModalLabel">Commentaires</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="comments-container">
                                                    <div className="comment-items">
                                                        {this.state.CoachComments.length !== 0 ?
                                                            this.state.CoachComments.map((comment, index) =>

                                                                <div key={index} className="comment-item">
                                                                    <b>Commentaire du {comment.frenchDateFormat}</b> <br />
                                                                    <p> {comment.commentaire_content} </p>
                                                                    <hr />
                                                                </div>
                                                            )
                                                            :
                                                            <div className="alert alert-warning empty-comment-alert">
                                                                Aucun commentaire sur ce bakeliste.
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                                <div className="modal-footer">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Déclarer un bakeliste placé ou en immersion */}
                                <div className="modal fade" id="hireBakelisteModal" tabIndex="-1" role="dialog" aria-labelledby="hireBakelisteModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.initialiseForm}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="hireBakelisteModalLabel">Bakeliste : {this.state.bakelisteDetails.bakelisteFullname}</h4>
                                            </div>
                                            <form id="hired-form">
                                                {this.state.showHiredForm === false ?
                                                    this.state.showImmersionForm === false ?
                                                        <div>
                                                            <div className="modal-body">
                                                                <div className="row options-row">
                                                                    <div className="col-md-6 option-item-container">
                                                                        <button onClick={this.onOpenHiredForm} className="btn btn-success volkeno-green-bcolor option-item-btn">
                                                                            Placement
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-md-6 option-item-container">
                                                                        <button onClick={this.onOpenImmersionForm} className="btn btn-warning volkeno-organge-bgcolor option-item-btn">
                                                                            Immersion
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal" onClick={this.initialiseForm}>
                                                                    <i className="fas fa-times-circle"></i> &nbsp;
                                                                    Quitter
                                                                </button>
                                                            </div>
                                                        </div>
                                                        : ''
                                                    : ''
                                                }
                                                {this.state.showHiredForm &&
                                                    <div className="modal-body">
                                                        <div className="row edit-bakeliste-container">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Raison Social / Recruteur</label>
                                                                    <input type="text" name="company_name" placeholder="Raison sociale ou Prénom et Nom du rectruteur" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Nombre de mois du contrat</label>
                                                                    <input type="number" placeholder="Nombre de mois du contrat" name="contract_duration" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Montant mensuel</label>
                                                                    <input type="text" placeholder="Montant mensuel du contract" name="contract_amount" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Date de début du contrat</label>
                                                                    <input type="date" placeholder="Date de début" name="date_debut" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Date de fin du contrat</label>
                                                                    <input type="date" placeholder="Date de fin" name="date_fin" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                                </div>
                                                            </div>
                                                            {this.state.errorHiredAlert &&
                                                                <div className="form-group col-md-12 hiredError alert alert-danger">
                                                                    Un problème est survenu lors de la déclaration. Veuillez réessayer plus tard.
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-default cancel_btn cancel button" onClick={this.onReturnActionChoice}>
                                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                                Revenir aux choix
                                                        </button>
                                                            {/* <button type="button" className="btn btn-primary return_back-btn button" onClick={this.onReturnActionChoice}>
                                                            Revenir aux choix
                                                        </button> */}
                                                            {!this.state.hiredBakelisteInProgress &&
                                                                <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.onSubmitHiredBakeliste(e)}>
                                                                    <i className="fas fa-edit"></i>&nbsp;
                                                                    Valider
                                                            </button>
                                                            }
                                                            {this.state.hiredBakelisteInProgress &&
                                                                <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                                    Validation en cours &nbsp;
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.showImmersionForm &&
                                                    <div className="modal-body">
                                                        <div className="row edit-bakeliste-container">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label>Entreprise</label>
                                                                    <select name="company_id" className="form-control" onChange={this.handleChangeImmersion}>
                                                                        <option> -- Choisir une entreprise -- </option>
                                                                        {this.state.companies.map((item, index) =>
                                                                            <option key={index} value={item.id}> {item.company_name} </option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Date de début du contrat</label>
                                                                    <input type="date" placeholder="Date de début" name="start_date" className="form-control" onChange={this.handleChangeImmersion} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Date de fin du contrat</label>
                                                                    <input type="date" placeholder="Date de fin" name="end_date" className="form-control" onChange={this.handleChangeImmersion} />
                                                                </div>
                                                            </div>
                                                            {this.state.errorImmersionAlert &&
                                                                <div className="form-group col-md-12 hiredError alert alert-danger">
                                                                    Un problème est survenu lors de la déclaration. Veuillez réessayer plus tard.
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-default cancel_btn cancel button" onClick={this.onReturnActionChoice}>
                                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                                Revenir aux choix
                                                        </button>
                                                            {/* <button type="button" className="btn btn-primary return_back-btn button" onClick={this.onReturnActionChoice}>
                                                            Revenir aux choix
                                                        </button> */}
                                                            {!this.state.immersionDeclarationInProgress &&
                                                                <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.onSubmitImmersionBakeliste(e)}>
                                                                    <i className="fas fa-edit"></i>&nbsp;
                                                                    Valider
                                                            </button>
                                                            }
                                                            {this.state.immersionDeclarationInProgress &&
                                                                <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                                    Validation en cours &nbsp;
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                            </form>
                                        </div>
                                    </div>
                                </div>

                                {/* edit bakeliste */}
                                <div className="modal fade" id="editBakelisteModal" tabIndex="-1" role="dialog" aria-labelledby="editBakelisteModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="editBakelisteModalLabel">Modifier : {this.state.bakelisteFullname}</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="edit-bakeliste-container">
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Prénom:</label>
                                                            <input type="text" className="form-control" id="inputFN" name="first_name" value={this.state.firstNameValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Nom de famille:</label>
                                                            <input type="last_name" className="form-control" id="inputLN" name="last_name" value={this.state.lastNameValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Email:</label>
                                                            <input type="Email" className="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.emailValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label >Téléphone:</label>
                                                            <input type="text" className="form-control" id="inputNumero" placeholder="Téléphone" name="phone" value={this.state.phoneValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Boursier:</label>
                                                            <select className="form-control" id="inputBoursier" name="boursier" value={this.state.boursierValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Demi-Boursier:</label>
                                                            <select className="form-control" id="inputDBoursier" name="demi_boursier" value={this.state.demiBoursierValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label>Formation payante :</label>
                                                            <select className="form-control" id="inputFormationP" name="is_formation_payante" value={this.state.formationPayanteValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Stage de formation :</label>
                                                            <select className="form-control" id="inputSFormation" name="is_stagede_formation" value={this.state.stageFormationValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Contrat ISA :</label>
                                                            <select className="form-control" id="inputISA" name="is_isa_contract" value={this.state.isISAContractValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Horaire de formation</label>
                                                            <select className="form-control" id="horaire" name="horaire" value={this.state.horaireValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir un horaire-- </option>
                                                                <option value="8h-17h">8h - 17h</option>
                                                                <option value="8h-12h30">8h - 12h30</option>
                                                                <option value="12h30-17h">12h30 - 17h</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Lieu de formation:</label>
                                                            <select className="form-control" id="inputlieu_formation" name="lieu_formation" value={this.state.lieuFormationValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir un lieu-- </option>
                                                                <option value="Sicap Foire">Sicap Foire</option>
                                                                <option value="Grand Yoff 1">Grand Yoff 1</option>
                                                                <option value="Grand Yoff 2">Grand Yoff 2</option>
                                                                <option value="Thiés">Thiés</option>
                                                                <option value="Labé">Labé (Guinée)</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Domaine de formation:</label>
                                                            <select className="form-control" id="inputbakeli_domaine_id" name="bakeliste_domaine_id" value={this.state.domaineFormationValue} onChange={this.updateHandleChange}>

                                                                <option> --Choisir un domaine-- </option>
                                                                {this.state.domaines.map((x, y) => <option value={x.id} key={y} >{x.name}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group " >
                                                            <label >Paiement a l'inscription:</label>
                                                            <select className="form-control" id="inputpaiement_inscription" name="paiement_inscription" value={this.state.paiementInscriptionValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label >Date de début:</label>
                                                            <input type="date" className="form-control" id="inputdebut_formation" name="debut_formation" value={this.state.debutFormationValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label >Date de fin:</label>
                                                            <input type="date" className="form-control" id="inputfin_formation" name="fin_formation" value={this.state.finFormationValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>

                                                    {this.state.updateBakelisteRequestError &&
                                                        <div className="col-md-12 alert alert-danger updateBakelisteError">
                                                            Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                        </div>
                                                    }
                                                    {this.state.isExistUser &&
                                                        <div className="alert alert-danger text-center">
                                                            Cet utilisateur avec ce mail existe déjà dans le système. <br />
                                                            Merci de le réintégrer !
                                                        </div>
                                                    }

                                                </div>
                                                <div className="modal-footer">
                                                    <div className="row sq-btn-container">
                                                        <div className="col-md-12 sq-btn-col">
                                                            <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                                Quitter
                                                            </button>
                                                            {!this.state.updateInProgress &&
                                                                <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.updateBakelisteInfos(e)}>
                                                                    <i className="fas fa-edit"></i>&nbsp;
                                                                    Modifier
                                                                </button>
                                                            }
                                                            {this.state.updateInProgress &&
                                                                <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                                    Modification en cours &nbsp;
                                                                <i className="fas fa-spinner fa-spin"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Déclarer un bakeliste recrutable */}
                                <div className="modal fade" id="recruitableModal" tabIndex="-1" role="dialog" aria-labelledby="recruitableModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="recruitableModalLabel">Déclarer</h4>
                                            </div>
                                            <form id="recruitableForm">
                                                <div className="modal-body">
                                                    <div className="form-group " >
                                                        <label >Choisissez un surnom :</label>
                                                        <input type="text" name="bakeliste_nickname" className="form-control nickname" value={this.state.recruitableNicknameValue} placeholder="Choisissez un surnom" onChange={this.handleChangeRecruitable} />
                                                    </div>
                                                    {this.isRecruitableRequestError &&
                                                        <div className="row assignation-error">
                                                            <div className="col-md-12 error-message-alert">
                                                                Un problème est survenu lors de la déclaration. Veuillez réessayer plus tard.
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                        <i className="fas fa-times-circle"></i> &nbsp;
                                                        Quitter
                                                </button>
                                                    {!this.state.recruitableEncours &&
                                                        <button onClick={(e) => this.onRecruitableBakeliste(e)} type="button" id="ass" className="btn btn-primary btn_for_assignation button addBtn">
                                                            <i className="fas fa-plus"></i>&nbsp;
                                                            Valider
                                                </button>
                                                    }
                                                    {this.state.recruitableEncours &&
                                                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                            Déclaration en cours &nbsp;
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                {this.state.successAlert === true &&
                                    <div className="alert alert-success text-center upsuccess-alert">
                                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                                        Bakeliste infos modifié avec succès.
                                    </div>
                                }

                                {this.state.successHiredAlert === true &&
                                    <div className="alert alert-success text-center upsuccess-alert">
                                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                                        Statut du bakeliste modifié avec succès.
                                    </div>
                                }

                                {this.state.successImmersionAlert === true &&
                                    <div className="alert alert-success text-center upsuccess-alert">
                                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                                        Statut du bakeliste modifié avec succès.
                                    </div>
                                }

                                {/* Coach assignation success toast alert */}
                                {this.state.assignationSuccessAlert === true &&
                                    <div className="alert alert-success text-center upsuccess-alert">
                                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                                        L'assignation a été effectuée avec succès.
                                    </div>
                                }

                                {/* Success completed formation toast alert */}
                                {this.state.successCompletedFormationAlert === true &&
                                    <div className="alert alert-success text-center upsuccess-alert">
                                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                                        La formation du bakeliste a été arrêtée avec succès.
                                    </div>
                                }

                                {/* Error completed formation toast alert */}
                                {this.state.completedFormationRequestError === true &&
                                    <div className="alert alert-danger text-center toast-error-alert">
                                        <i className="fas fa-exclamation-triangle"></i> &nbsp;&nbsp;
                                        Un problème est survenu. Réessayer plus tard.
                                    </div>
                                }

                                {/* Success recruitable toast alert */}
                                {this.state.successRecruitableAlert === true &&
                                    <div className="alert alert-success text-center upsuccess-alert">
                                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                                        La validation a été effectuée avec succès.
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
