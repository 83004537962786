import React, { Component } from 'react';
import './cvtheque.css'
//import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
//import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
//import { Link, NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
//import $ from 'jquery';
import { Link } from 'react-router-dom';
import {Form, Input} from 'semantic-ui-react-form-validator';
import {Button , Icon,Grid} from 'semantic-ui-react';

export default class cvtheque extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cvthequeID: 0,
      bakelisteID: window.sessionStorage.getItem('user_id'),
      api: API.API,
      isRecruitable: false,
      cv: [],
      isCvthequeEmpty: false,
      isProjectEmpty: false,
      isEducationEmpty: false,
      isPortfolioEmpty: false,
      isTecnologyEmpty: false,
      isLanguageEmpty: false,
      isEmptyCV: false,
      cvinfos: [],
      cvprojects: [],
      cveducation: [],
      cvportfolio: [],
      cvtechnology: [],
      cvlanguague: [],
      showProjectForm: false,
      showProjectForm2: false,
      fields: {},
      cvtheque_data:{},
      isAddProjectInProgress: false,
      isSuccessAddItemAlert: false,
      isAddRequestError: false,
      showTacheForm: false,
      showProjectForm2Btn: true,
      showTacheFormBtn: true,
      isAddTacheInProgress: false,
      isTacheRequestError: false,
      showEducationForm: false,
      showEducationFormBtn: false,
      isAddEducationInProgress: false,
      showEducationForm2Btn: true,
      showEducationForm2: false,

      showPortfolioForm: false,
      showPortfolioFormBtn: false,
      isAddPortfolioInProgress: false,
      showPortfolioForm2Btn: true,
      showPortfolioForm2: false,
      imagePreviewUrl: false,
      image: '',
      imgPathValue: '',
      idValue: 0,
      portProjectName: '',

      showMotivationForm: false,
      showMotivationFormBtn: false,
      showMotivationForm2: false,
      showMotivationForm2Btn: true,
      isAUMotivationInProgress: false,
      cvMotivationsValue: '',

      showTechnoForm: false,
      showTechnoFormBtn: false,
      showTechnoForm2: false,
      showTechnoForm2Btn: true,
      isAddTechnoInProgress: false,

      showLangForm: false,
      showLangFormBtn: false,
      showLangForm2: false,
      showLangForm2Btn: true,
      isAddLangInProgress: false,
      isPending: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitCvTheque = this.handleSubmitCvTheque.bind(this);
    this.handleSubmitNewProject = this.handleSubmitNewProject.bind(this);
    this.handleSubmitNewTache = this.handleSubmitNewTache.bind(this);
    this.handleSubmitNewEducation = this.handleSubmitNewEducation.bind(this);
    this.handleSubmitNewPortfolio = this.handleSubmitNewPortfolio.bind(this);
    this.hanldeSubmitMotivation = this.hanldeSubmitMotivation.bind(this);
    this.handleChangeUpdateMotivation = this.handleChangeUpdateMotivation.bind(this);
    this.hanldeSubmitTechno = this.hanldeSubmitTechno.bind(this);
    this.hanldeSubmitLang = this.hanldeSubmitLang.bind(this);
    this.handleChangeCvTheque = this.handleChangeCvTheque.bind(this);
  }

  componentDidMount() {
    // let token = window.sessionStorage.getItem('token');
    // let userStatus = window.sessionStorage.getItem('user_status');
    // let userID = window.sessionStorage.getItem('user_id');

    // if (userStatus === 'bakeliste') {
    //   this.setState({
    //     bakelisteID: userID
    //   })
    // }

    this.getBakelisteCV(this.state.bakelisteID);

  }


  getBakelisteCV = (bakeliste_id) => {
    axios.get(this.state.api + 'cvtheques/' + bakeliste_id)
      .then(response => {
        if (response.data.success === true) {
          console.log('ok')
          //var data = this.state.cv;
          //var data = [];
          if (response.data.cvthequeData.length === 0) {
            this.setState({
              isCvthequeEmpty: true
            })
          } else {
            this.setState({
              cvinfos: response.data.cvthequeData,
              cvMotivationsValue: response.data.cvthequeData.cv_motivations
            })
          }
          console.log(response.data.cvthequeData)
          if (response.data.cvprojectsData.length === 0) {
            this.setState({
              isProjectEmpty: true
            })
          } else {
            this.setState({
              cvprojects: response.data.cvprojectsData
            })
            console.log(this.state.cvprojects)
          }
          if (response.data.cveducationData.length === 0) {
            this.setState({
              isEducationEmpty: true
            })
          } else {
            this.setState({
              cveducation: response.data.cveducationData
            })
            //console.log(response.data.cveducationData)
          }
          if (response.data.cvportfolioData.length === 0) {
            this.setState({
              isPortfolioEmpty: true
            })
          } else {
            this.setState({
              cvportfolio: response.data.cvportfolioData
            })
            console.log(response.data.cvportfolioData)
          }
          if (response.data.cvtechnologiesData.length === 0) {
            this.setState({
              isTecnologyEmpty: true
            })
          } else {
            this.setState({
              cvtechnology: response.data.cvtechnologiesData
            })

            //console.log(response.data.cvtechnologiesData)
          }
          if (response.data.cvlanguagesData.length === 0) {
            this.setState({
              isLanguageEmpty: true
            })
          } else {
            this.setState({
              cvlanguague: response.data.cvlanguagesData
            })
          }

        } else {
          this.setState({
            isEmptyCV: true,
            isCvthequeEmpty: true
          })
        }
      })
      .catch(error => {
        console.log(error);

      })
  }
  // handleChange cv theque

  handleChangeCvTheque = (e) => {
    let cvtheque_data = this.state.cvtheque_data;
    cvtheque_data[e.target.name] = e.target.value ;
    cvtheque_data["bakeliste_id"] = this.state.bakelisteID;
    cvtheque_data["is_hired"] = 1;
    this.setState({
      cvtheque_data:cvtheque_data
    })

    console.log('cv thecque data',this.state.cvtheque_data);
  }
  handleSubmitCvTheque(e){
    e.preventDefault();
  
          this.setState({
            isPending: true
          });
          
          let data = this.state.cvtheque_data;
            axios.post(this.state.api + 'cvtheques', data)
              .then(response => {
                this.setState({
                  isPending: false
                });
                  if (response.data.success) {
                      this.setState({ successRegister:true });
                      console.log(response.data);
                    }
              })
              .catch(error => {
                console.log(error.message)
                /* this.setState({
                  errorprogresseAlert: true
                }) */
                //this.handleSubmitlError('errorprogresseAlert')
            })
       
        }
  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === 'successAddItem') {
        this.setState({
          isSuccessAddItemAlert: false
        })
      }
      if (label === 'errorAddItem') {
        this.setState({
          isAddRequestError: false
        })
      }
    }, 5000)
  }

  addNewProjectForm = (e, label) => {
    e.preventDefault()
    if (label === 'pFirstForm') {
      this.setState({
        showProjectForm: true
      })
    }
    if (label === 'pSecondForm') {
      this.setState({
        showProjectForm2: true,
        showProjectForm2Btn: false,
        showTacheFormBtn: false
      })
    }
  }

  addNewTacheForm = (e) => {
    e.preventDefault();
    this.setState({
      showTacheForm: true,
      showProjectForm2Btn: false,
      showTacheFormBtn: false
    })
  }

  /**
   * Switcher method for showing education form
   */
  addNewEducationForm = (e, label) => {
    e.preventDefault();
    if (label === 'eFirstForm') {
      this.setState({
        showEducationForm: true,
        showEducationFormBtn: false
      })
    }
    if (label === 'eSecondForm') {
      this.setState({
        showEducationForm2: true,
        showEducationForm2Btn: false
      })
    }

  }


  /**
   * Switcher method for showing portfolio form
   */
  addNewPortfolioForm = (e, label) => {
    e.preventDefault();
    if (label === 'poFirstForm') {
      this.setState({
        showPortfolioForm: true,
        showPortfolioFormBtn: false
      })
    }
    if (label === 'poSecondForm') {
      this.setState({
        showPortfolioForm2: true,
        showPortfolioForm2Btn: false
      })
    }

  }

  /**
   * Switcher method for showing motivations form
   */
  addMotivationForm = (e, label, motivation) => {
    e.preventDefault();
    if (label === 'moFirstForm') {
      this.setState({
        showMotivationForm: true,
        showMotivationFormBtn: false
      })
    }
    if (label === 'moSecondForm') {
      this.setState({
        showMotivationForm2: true,
        showMotivationForm2Btn: false,
        cvMotivationsValue: motivation
      })
    }
  }

  /**
   * Switcher method for showing techno form
   */
  addTechnoForm = (e, label) => {
    e.preventDefault();
    if (label === 'teFirstForm') {
      this.setState({
        showTechnoForm: true,
        showTechnoFormBtn: false
      })
    }
    if (label === 'teSecondForm') {
      this.setState({
        showTechnoForm2: true,
        showTechnoForm2Btn: false,
      })
    }
  }

  /**
   * Switcher method for showing languague form
   */
  addLangForm = (e, label) => {
    e.preventDefault();
    if (label === 'laFirstForm') {
      this.setState({
        showLangForm: true,
        showLangFormBtn: false
      })
    }
    if (label === 'laSecondForm') {
      this.setState({
        showLangForm2: true,
        showLangForm2Btn: false,
      })
    }
  }

  hideItemForm = (e) => {
    e.preventDefault();
    this.setState({
      showProjectForm: false,
      showProjectForm2Btn: true,
      showProjectForm2: false,

      showTacheFormBtn: true,
      showTacheForm: false,

      showEducationForm: false,
      showEducationForm2: false,
      showEducationForm2Btn: true,

      showPortfolioForm: false,
      showPortfolioForm2: false,
      showPortfolioForm2Btn: true,

      showMotivationForm: false,
      showMotivationForm2: false,
      showMotivationForm2Btn: true,

      showTechnoForm: false,
      showTechnoForm2: false,
      showTechnoForm2Btn: true,

      showLangForm: false,
      showLangForm2: false,
      showLangForm2Btn: true,
    })
  }

  /**
   * Handle add form
   */
  handleChange = (e) => {
    let fields = this.state.fields;
    fields["cvtheque_id"] = this.state.cvinfos.id;
    fields["action"] = 'add';
    if (e.target.name === 'img_path') {
      console.log(e.target.files)
      this.createImage(e.target.files[0]);
      fields["img_path"] = e.target.files[0]
    } else {
      fields[e.target.name] = e.target.value;
    }
    
    this.setState({
      fields
    })

    console.log(fields);
  }

  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        image: e.target.result
      })
    };
    console.log(this.state.image)
    reader.readAsDataURL(file);
  }

  /**
   * Submit new project form
   */
  handleSubmitNewProject = (e) => {
    e.preventDefault();
    this.setState({
      isAddProjectInProgress: true
    })
    let newProject = this.state.fields;
    this.setState({
      fields: {}
    })
    axios.post(this.state.api + 'cvprojects', newProject)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            isProjectEmpty: false, // déclarer projet non vide
            showProjectForm: false, // cacher le formulaire d'ajout
            showProjectForm2: false, // cacher le deuxième formulaire d'ajout
            showProjectForm2Btn: true, // afficher le bouton switcheur
            showTacheFormBtn: true, // afficher bouton switcheur
            isAddProjectInProgress: false, // cacher l'action en cours
            isSuccessAddItemAlert: true // afficher le toast de confirmation
          })
          this.getBakelisteCV(this.state.bakelisteID) // raffraîchir les données
          this.handleShowAndHideAlert('successAddItem')
        } else {
          this.setState({
            isAddProjectInProgress: false, // cacher l'action en cours
            isAddRequestError: true // afficher le message d'erreur
          })
          this.handleShowAndHideAlert('errorAddItem')
        }
      })
      .catch(error => {
        this.setState({
          isAddProjectInProgress: false, // cacher l'action en cours
          isAddRequestError: true // afficher le message d'erreur
        })
        console.log(error)
        this.handleShowAndHideAlert('errorAddItem')
      })
    console.log(this.state.fields)
  }

  /**
   * Submit add new tache form
   */
  handleSubmitNewTache = (e) => {
    e.preventDefault();
    this.setState({
      isAddTacheInProgress: true // activer l'action en cours
    })
    let newTache = this.state.fields; // recupération des données du formulaire

    axios.post(this.state.api + 'cvtaches', newTache)
      .then(response => { // return back de la requête
        if (response.data.success === true) { // return back égal à true
          document.getElementById("addTacheForm").reset(); // vider le formulaire
          this.setState({
            isAddTacheInProgress: false, // cacher l'action en cours
            isSuccessAddItemAlert: true, // afficher le toast de confirmation
            fields: {}
          })
          this.getBakelisteCV(this.state.bakelisteID) // raffraichissement des données
          this.handleShowAndHideAlert('successAddItem')
        } else {
          console.log(response)
          this.setState({
            isAddTacheInProgress: false, // cacher l'action en cours
            isAddRequestError: true // afficher le message d'erreur
          })
          this.handleShowAndHideAlert('errorAddItem')
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isAddTacheInProgress: false, // cacher l'action en cours
          isAddRequestError: true // afficher le message d'erreur
        })
        this.handleShowAndHideAlert('errorAddItem')
      })

  }

  /**
   * Submit new education
   */
  handleSubmitNewEducation = (e, label) => {
    e.preventDefault();
    this.setState({
      isAddEducationInProgress: true // submit en cours
    })
    let newEducation = this.state.fields; // recupération des données du formulaire

    axios.post(this.state.api + 'cveducations', newEducation)
      .then(response => { // return back du serveur
        if (response.data.success === true) { // submit action effectuée
          this.getBakelisteCV(this.state.bakelisteID) // raffraichissement des données
          document.getElementById('addNewEducationForm').reset(); // reset du formulaire
          this.setState({
            isEducationEmpty: false, // education non vide
            isAddEducationInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showEducationForm: false, // cacher le formulaire
            fields: {}
          })
          if (label === 'first') { // test si c'est le premier ajout
            this.setState({
              showEducationForm2Btn: true, // affichage du bouton switcher
            })
          } else if (label === 'second') { // test si c'est ajout supplémentaire
            this.setState({
              showEducationForm2Btn: false, // cacher le bouton switcher
            })
          }
          this.handleShowAndHideAlert('successAddItem') // cacher le toast de confirmation 
        } else {
          console.log(response)
          this.setState({
            isAddEducationInProgress: false, // désactiver ajout en cours
            isAddRequestError: true // affcihage de l'erreur
          })
          this.handleShowAndHideAlert('errorAddItem') // cacher le message d'alerte
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isAddEducationInProgress: false, // désactiver ajout en cours
          isAddRequestError: true // affcihage de l'erreur
        })
        this.handleShowAndHideAlert('errorAddItem') // cacher le message d'alerte
      })
  }

  /**
   * Submit new portfolio item
   */
  handleSubmitNewPortfolio = (e, label) => {
    e.preventDefault();
    this.setState({
      isAddPortfolioInProgress: true
    })

    const newPortfolio = new FormData();
    newPortfolio.append('img_path', this.state.fields.img_path);
    newPortfolio.append('cvtheque_id', this.state.fields.cvtheque_id);
    newPortfolio.append('project_id', this.state.fields.project_id);

    axios.post(this.state.api + 'cvportfolios', newPortfolio)
      .then(response => {
        if (response.data.success === true) {
          this.getBakelisteCV(this.state.bakelisteID) // raffraichissement des données
          document.getElementById('addNewPortfolioForm').reset();
          this.setState({
            isPortfolioEmpty: false,
            isAddPortfolioInProgress: false,
            isSuccessAddItemAlert: true,
            showPortfolioForm: false,
            fields: {}
          })
          if (label === 'first') {
            this.setState({
              showPortfolioForm2Btn: true,
            })
          } else if (label === 'second') {
            this.setState({
              showPortfolioForm2Btn: false,
            })
          }
          this.handleShowAndHideAlert('successAddItem')
        } else {
          console.log(response)
          this.setState({
            isAddPortfolioInProgress: false,
            isAddRequestError: true
          })
          this.handleShowAndHideAlert('errorAddItem')
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isAddPortfolioInProgress: false,
          isAddRequestError: true
        })
        this.handleShowAndHideAlert('errorAddItem')
      })
  }

  /**
   * Portfolio infos pour afficher l'image en taille réelle
   */
  getPortfolioInfos = (portfolio) => {
    this.setState({
      imgPathValue: portfolio.img_path,
      idValue: portfolio.id,
      portProjectName: portfolio.projectName
    })
  }

  /**
   * Handle Change method for update motivations
   */
  handleChangeUpdateMotivation = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["action"] = 'update';
    if (e.target.name === 'cv_motivations') {
      this.setState({
        cvMotivationsValue: e.target.value
      })
    }

    if (!fields["cv_motivations"]) {
      fields["cv_motivations"] = this.state.cvMotivationsValue;
    }

    this.setState({
      fields: fields
    })

    console.log(fields);
  }

  /**
   * Handle submit bakeliste motivations
   */
  hanldeSubmitMotivation = (e, label) => {
    e.preventDefault();
    this.setState({
      isAUMotivationInProgress: true // submit en cours
    })
    let motivations = this.state.fields; // recupération des données du formulaire
    console.log(motivations)

    axios.post(this.state.api + 'bakeliste-add-cv-motivations/' + this.state.cvinfos.id, motivations)
      .then(response => { // return back du serveur
        if (response.data.success === true) { // submit action effectuée
          this.getBakelisteCV(this.state.bakelisteID) // raffraichissement des données
          document.getElementById('addUpdateMotivationForm').reset(); // reset du formulaire
          this.setState({
            isAUMotivationInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showMotivationForm: false, // cacher le formulaire
            fields: {}
          })
          if (label === 'first') { // test si c'est le premier ajout
            this.setState({
              showMotivationForm2Btn: true, // affichage du bouton switcher
            })
          } else if (label === 'second') { // test si c'est ajout supplémentaire
            this.setState({
              showMotivationForm2Btn: true, // cacher le bouton switcher
              showMotivationForm2: false, // cacher le formulaire de modification
            })
          }
          this.handleShowAndHideAlert('successAddItem') // cacher le toast de confirmation 
        } else {
          console.log(response)
          this.setState({
            isAUMotivationInProgress: false, // désactiver ajout en cours
            isAddRequestError: true // affcihage de l'erreur
          })
          this.handleShowAndHideAlert('errorAddItem') // cacher le message d'alerte
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isAUMotivationInProgress: false, // désactiver ajout en cours
          isAddRequestError: true // affcihage de l'erreur
        })
        this.handleShowAndHideAlert('errorAddItem') // cacher le message d'alerte
      })
  }

  /**
   * Handle submit new techno
   */
  hanldeSubmitTechno = (e, label) => {
    e.preventDefault();
    this.setState({
      isAddTechnoInProgress: true // submit en cours
    })
    let techno = this.state.fields; // recupération des données du formulaire

    axios.post(this.state.api + 'cvtechnologies', techno)
      .then(response => { // return back du serveur
        if (response.data.success === true) { // submit action effectuée
          this.getBakelisteCV(this.state.bakelisteID) // raffraichissement des données
          document.getElementById('addTechnoForm').reset(); // reset du formulaire
          this.setState({
            isTecnologyEmpty: false, // techno non vide
            isAddTechnoInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showTechnoForm: false, // cacher le formulaire
            fields: {}
          })
          if (label === 'first') { // test si c'est le premier ajout
            this.setState({
              showTechnoForm2Btn: true, // affichage du bouton switcher
            })
          } else if (label === 'second') { // test si c'est ajout supplémentaire
            this.setState({
              showTechnoForm2Btn: false, // cacher le bouton switcher
            })
          }
          this.handleShowAndHideAlert('successAddItem') // cacher le toast de confirmation 
        } else {
          console.log(response)
          this.setState({
            isAddTechnoInProgress: false, // désactiver ajout en cours
            isAddRequestError: true // affcihage de l'erreur
          })
          this.handleShowAndHideAlert('errorAddItem') // cacher le message d'alerte
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isAddTechnoInProgress: false, // désactiver ajout en cours
          isAddRequestError: true // affcihage de l'erreur
        })
        this.handleShowAndHideAlert('errorAddItem') // cacher le message d'alerte
      })
  }

  /**
   * Handle submit new lang
   */
  hanldeSubmitLang = (e, label) => {
    e.preventDefault();
    this.setState({
      isAddLangInProgress: true // submit en cours
    })
    let lang = this.state.fields; // recupération des données du formulaire

    axios.post(this.state.api + 'cvlanguages', lang)
      .then(response => { // return back du serveur
        if (response.data.success === true) { // submit action effectuée
          this.getBakelisteCV(this.state.bakelisteID) // raffraichissement des données
          document.getElementById('addLangForm').reset(); // reset du formulaire
          this.setState({
            isLanguageEmpty: false, // language non vide
            isAddLangInProgress: false, // désactiver ajout en cours
            isSuccessAddItemAlert: true, // toast de confirmation
            showLangForm: false, // cacher le formulaire
            fields: {}
          })
          if (label === 'first') { // test si c'est le premier ajout
            this.setState({
              showLangForm2Btn: true, // affichage du bouton switcher
            })
          } else if (label === 'second') { // test si c'est ajout supplémentaire
            this.setState({
              showLangForm2Btn: false, // cacher le bouton switcher
            })
          }
          this.handleShowAndHideAlert('successAddItem') // cacher le toast de confirmation 
        } else {
          console.log(response)
          this.setState({
            isAddLangInProgress: false, // désactiver ajout en cours
            isAddRequestError: true // affcihage de l'erreur
          })
          this.handleShowAndHideAlert('errorAddItem') // cacher le message d'alerte
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isAddLangInProgress: false, // désactiver ajout en cours
          isAddRequestError: true // affcihage de l'erreur
        })
        this.handleShowAndHideAlert('errorAddItem') // cacher le message d'alerte
      })
  }

  render() {
    return (
      <div className="component-cvtheque">
        <Menu />
        <div className="cvtheque_container " style={{marginTop:"8%"}} >
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">CV Builder</span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard-admin">Accueil</Link>
                  </li>
                  <li className="active">CV Builder</li>
                </ol>
              </div>
            </h1>
          </div>

          <div className="col-md-12 cvtheque_content">
              <div className="row cv_row">
                <div className="col-md-12 cvprojects">
                  <div>
                    {this.state.isCvthequeEmpty === false &&
                  <div className="cv_name">
                    <h2 className="cv_name_label">{this.state.cvinfos.cv_name}</h2>
                  </div>
                   }
                   <br/>
                  {this.state.isCvthequeEmpty === true &&
                   <Form color='teal' size='large' id="register-form" ref="form"  onSubmit={this.handleSubmitCvTheque}  >                             
                             <Grid stackable columns={2}>
                            <Grid.Column>
                              <Input type="text" size='large'   width={15}   
                                placeholder='nom du cv '  onChange={this.handleChangeCvTheque}  name="cv_name"
                                validators={['required']} value={this.state.cvtheque_data.cv_name} 
                                errorMessages={['* Ce champ est obligatoire']} />
                            </Grid.Column>
                            <Grid.Column>
                            {!this.state.isPending &&
                              <Button color='teal' content='Ajouter' icon='plus' size='huge' id='btn-add'/> 
                              }
                            {this.state.isPending && 
                            <Button color='teal' size='huge' id='btn-add'> <Icon loading name='spinner' />
                              L'ajoute en cours
                            </Button>
                            }
                            </Grid.Column>
                          </Grid>
                          </Form> 
                    }
                     <hr />
              </div>
              <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    {/* panel projet */}
                    <div className="panel panel-default">
                      <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingOne">
                        <h4 className="panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseProjet" aria-expanded="true" aria-controls="collapseOne">
                            Vos Projets
                          </a>
                        </h4>

                      </div>
                      <div id="collapseProjet" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">
                          <div className="projects_container">
                            {this.state.isProjectEmpty === true &&
                              this.state.showProjectForm === false &&
                              <div className="empty_container">
                                <div className="empty_projects alert alert-warning">
                                  Vous n'avez pas encore renseigné vos projets. Nous vous invitons à le faire.
                                </div>
                                <div className="add_form_btn_container">
                                  <button data-toggle="tooltip" data-placement="top" title="Ajouter un projet" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addNewProjectForm(e, 'pFirstForm')}>
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            }

                            {this.state.showProjectForm === true &&
                              <div className="form_container row">
                                <div className="col-md-12">
                                  <h5 className="new_project_title">Nouveau projet</h5>
                                </div>
                                <form onSubmit={this.handleSubmitNewProject}>
                                  <div className="col-md-6 form-group">
                                    <input type="text" placeholder="Nom du projet" name="project_name" onChange={this.handleChange} className="form-control form_item" />
                                  </div>
                                  <div className="col-md-6 form-group">
                                    <input type="text" placeholder="Technologies utilisées" name="project_technologies" onChange={this.handleChange} className="form-control form_item" />
                                  </div>
                                  {this.state.isAddRequestError &&
                                    <div className="error-row">
                                      <div className="col-md-12 error-container alert alert-danger">
                                            Une erreur est survenue. Veuillez réessayer plus tard.
                                      </div>
                                    </div>
                                  }
                                  <div className="col-md-12 form-group">
                                    <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                    {this.state.isAddProjectInProgress === false ?
                                      <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                      :
                                      <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                        Ajout en cours
                                        <i className="fas fa-spin fa-spinner"></i>
                                      </button>
                                    }
                                  </div>
                                </form>
                              </div>
                            }

                            {this.state.isProjectEmpty === false &&
                              <div className="list_projects_row">
                                {this.state.cvprojects.map((item, indexe) =>
                                  <div key={indexe} className="list_projects_container col-md-12">
                                    <h5 className="project_name_label">Nom du projet : {item.project_name} </h5>
                                    <h6 className="project_technologies_label"> {item.project_technologies} </h6>
                                    {item.taches.length === 0 ?
                                      <div>
                                        <i>Veuillez ajouter une ou les tâche(s)</i>

                                      </div>
                                      :
                                      <div className="list_taches_container">
                                        <ul>
                                          {item.taches.map((tache, ind) =>
                                            <li className="tache_item"> {tache.tache_content} </li>
                                          )}
                                        </ul>
                                      </div>
                                    }
                                    <hr />
                                  </div>
                                )}

                                <div className="col-md-12 button-create-cv-actions-container">
                                  <div className="col-md-6 projects-btn">
                                    {this.state.showProjectForm2Btn === true &&
                                      <div className="add_form_btn_container">
                                        <button data-toggle="tooltip" data-placement="top" title="Ajouter un projet" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addNewProjectForm(e, 'pSecondForm')}>
                                          <i className="fas fa-plus"></i>
                                        </button>
                                      </div>
                                    }
                                  </div>
                                  <div className="col-md-6 taches-btn">
                                    {this.state.showTacheFormBtn === true &&
                                      <div className="add_tache_btn_container">
                                        <button data-toggle="tooltip" data-placement="top" title="Ajouter une tâche" onClick={(e) => this.addNewTacheForm(e)} className="btn btn-success volkeno-orange-bcolor switch-btn">
                                          <i className="fas fa-plus"></i>
                                        </button>
                                      </div>
                                    }
                                  </div>
                                </div>

                                {this.state.showTacheForm === true &&
                                  <div className="row tache_form_container">
                                    <div className="col-md-12">
                                      <h5 className="new_tache_title">Nouvelle tâche</h5>
                                    </div>
                                    <div className="form_row">
                                      <form onSubmit={this.handleSubmitNewTache} id="addTacheForm">
                                        <div className="select_project col-md-6 form-group">
                                          <select className="form-control project_id_label" name="project_id" onChange={this.handleChange} required>
                                            <option>--Choisir un projet--</option>
                                            {this.state.cvprojects.map((pr, indx) =>
                                              <option key={indx} value={pr.id}> {pr.project_name} </option>
                                            )}
                                          </select>
                                        </div>
                                        <div className="tache_content col-md-6 form-group">
                                          <textarea placeholder="Tâche effectuée" className="form-control tache_content_label" name="tache_content" onChange={this.handleChange} required />
                                        </div>
                                        {this.state.isAddRequestError &&
                                          <div className="error-row">
                                            <div className="col-md-12 error-container alert alert-danger">
                                                Une erreur est survenue. Veuillez réessayer plus tard.
                                            </div>
                                          </div>
                                        }
                                        <div className="col-md-12 form-group">
                                          <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                          {this.state.isAddTacheInProgress === false ?
                                            <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                            :
                                            <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                              Ajout en cours
                                              <i className="fas fa-spin fa-spinner"></i>
                                            </button>
                                          }
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                }
                                {this.state.showProjectForm2 === true &&
                                  <div className="form_container">
                                    <div className="col-md-12">
                                      <h5 className="new_project_title">Nouveau projet</h5>
                                    </div>
                                    <form onSubmit={this.handleSubmitNewProject}>
                                      <div className="col-md-6 form-group">
                                        <input type="text" placeholder="Nom du projet" name="project_name" onChange={this.handleChange} className="form-control form_item" />
                                      </div>
                                      <div className="col-md-6 form-group">
                                        <input type="text" placeholder="Technologies utilisées" name="project_technologies" onChange={this.handleChange} className="form-control form_item" />
                                      </div>
                                      {this.state.isAddRequestError &&
                                        <div className="error-row">
                                          <div className="col-md-12 error-container alert alert-danger">
                                              Une erreur est survenue. Veuillez réessayer plus tard.
                                          </div>
                                        </div>
                                      }
                                      <div className="col-md-12 form-group">
                                        <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                        {this.state.isAddProjectInProgress === false ?
                                          <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                          :
                                          <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                            Ajout en cours
                                            <i className="fas fa-spin fa-spinner"></i>
                                          </button>
                                        }
                                      </div>
                                    </form>
                                  </div>
                                }

                              </div>
                            }

                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Education panel */}
                    <div className="panel panel-default">
                      <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingTwo">
                        <h4 className="panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEducation" aria-expanded="true" aria-controls="collapseEducation">
                            Education
                          </a>
                        </h4>
                      </div>
                      <div id="collapseEducation" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div className="panel-body">
                          {this.state.isEducationEmpty === true &&
                            this.state.showEducationForm === false &&
                            <div className="empty_container">
                              <div className="empty_education alert alert-warning">
                                Vous n'avez pas encore renseigné votre parcours scolaire. Nous vous invitons à le faire.
                                </div>
                              <div className="add_form_btn_container">
                                <button data-toggle="tooltip" data-placement="top" title="Ajouter une éducation" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addNewEducationForm(e, 'eFirstForm')}>
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          }

                          {this.state.showEducationForm === true &&
                            <div className="form_container row">
                              <div className="col-md-12">
                                <h5 className="new_project_title">Nouvelle éducation</h5>
                              </div>
                              <form onSubmit={(e) => this.handleSubmitNewEducation(e, 'first')} id="addNewEducationForm">
                                <div className="col-md-6 form-group">
                                  <input type="text" placeholder="Nom du dipôme - Exemple : Master" name="diplome" onChange={this.handleChange} className="form-control form_item" />
                                </div>
                                <div className="col-md-6 form-group">
                                  <input type="text" placeholder="Libellé du dipôme - Exemple : Génie Informatique" name="diplome_label" onChange={this.handleChange} className="form-control form_item" />
                                </div>
                                <div className="col-md-6 form-group">
                                  <input type="text" placeholder="Année scoalire" name="annee_scolaire" onChange={this.handleChange} className="form-control form_item" />
                                </div>
                                <div className="col-md-6 form-group">
                                  <input type="text" placeholder="Ecole / Université" name="school" onChange={this.handleChange} className="form-control form_item" />
                                </div>
                                <div className="col-md-6 form-group">
                                  <input type="text" placeholder="Ville d'obtention" name="ville" onChange={this.handleChange} className="form-control form_item" />
                                </div>
                                <div className="col-md-6 form-group">
                                  <input type="text" placeholder="Pays d'obtention" name="pays" onChange={this.handleChange} className="form-control form_item" />
                                </div>
                                {this.state.isAddRequestError &&
                                  <div className="error-row">
                                    <div className="col-md-12 error-container alert alert-danger">
                                        Une erreur est survenue. Veuillez réessayer plus tard.
                                    </div>
                                  </div>
                                }
                                <div className="col-md-12 form-group">
                                  <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                  {this.state.isAddEducationInProgress === false ?
                                    <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                    :
                                    <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                      Ajout en cours
                                        <i className="fas fa-spin fa-spinner"></i>
                                    </button>
                                  }
                                </div>
                              </form>
                            </div>
                          }

                          {/* Render education s'il n'est pas vide */}
                          {this.state.isEducationEmpty === false &&
                            <div className="list_educations_row">
                              {this.state.cveducation.map((educ, i) =>
                                <div className="col-md-12 education_item_container">
                                  <span className="diplome_label"> {educ.diplome_label}, </span>
                                  <span className="school_container"> {educ.school} - </span>
                                  <i className="diplome_container"> {educ.diplome} </i> <br />
                                  <span className="ville_pays"> {educ.ville} - {educ.pays} </span>
                                  <hr />
                                </div>
                              )}

                              {/* Add new education button */}
                              {this.state.showEducationForm2Btn === true &&
                                <div className="add_form_btn_container">
                                  <button data-toggle="tooltip" data-placement="top" title="Ajouter une éducation" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addNewEducationForm(e, 'eSecondForm')}>
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              }

                              {/* Render add form au clic sur le bouton ajouter */}
                              {this.state.showEducationForm2 &&
                                <div className="form_container row">
                                  <div className="col-md-12">
                                    <h5 className="new_project_title">Nouvelle éducation</h5>
                                  </div>
                                  <form onSubmit={(e) => this.handleSubmitNewEducation(e, 'second')} id="addNewEducationForm">
                                    <div className="col-md-6 form-group">
                                      <input type="text" placeholder="Nom du dipôme - Exemple : Master" name="diplome" onChange={this.handleChange} className="form-control form_item" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <input type="text" placeholder="Libellé du dipôme - Exemple : Génie Informatique" name="diplome_label" onChange={this.handleChange} className="form-control form_item" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <input type="text" placeholder="Année scoalire" name="annee_scolaire" onChange={this.handleChange} className="form-control form_item" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <input type="text" placeholder="Ecole / Université" name="school" onChange={this.handleChange} className="form-control form_item" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <input type="text" placeholder="Ville d'obtention" name="ville" onChange={this.handleChange} className="form-control form_item" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <input type="text" placeholder="Pays d'obtention" name="pays" onChange={this.handleChange} className="form-control form_item" />
                                    </div>
                                    {this.state.isAddRequestError &&
                                      <div className="error-row">
                                        <div className="col-md-12 error-container alert alert-danger">
                                            Une erreur est survenue. Veuillez réessayer plus tard.
                                        </div>
                                      </div>
                                    }
                                    <div className="col-md-12 form-group">
                                      <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                      {this.state.isAddEducationInProgress === false ?
                                        <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                        :
                                        <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                          Ajout en cours
                                          <i className="fas fa-spin fa-spinner"></i>
                                        </button>
                                      }
                                    </div>
                                  </form>
                                </div>
                              }
                            </div>
                          }


                        </div>
                      </div>
                    </div>

                    {/* Render Section CV Portfolio */}
                    <div className="panel panel-default">
                      <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingThree">
                        <h4 className="panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsePortfolio" aria-expanded="true" aria-controls="collapsePortfolio">
                            Portfolio
                          </a>
                        </h4>
                      </div>
                      <div id="collapsePortfolio" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div className="panel-body">
                          {this.state.isPortfolioEmpty === true &&
                            this.state.showPortfolioForm === false &&
                            this.state.isProjectEmpty === true &&
                            <div className="empty_container">
                              <div className="empty_portfolio alert alert-danger">
                                Vous n'avez pas encore ajouté de projets. Vous devez en créer au préalable pour pouvoir gérer votrer portfolio.
                                    </div>
                            </div>
                          }
                          {this.state.isPortfolioEmpty === true &&
                            this.state.showPortfolioForm === false &&
                            this.state.isProjectEmpty === false &&
                            <div className="empty_container">
                              <div className="empty_portfolio alert alert-warning">
                                Vous n'avez pas encore uploadé vos schreenshots. Nous vous invitons à le faire.
                                    </div>
                              <div className="add_form_btn_container">
                                <button data-toggle="tooltip" data-placement="top" title="Ajouter un portfolio" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addNewPortfolioForm(e, 'poFirstForm')}>
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          }

                          {this.state.showPortfolioForm === true &&
                            <div className="form_container row">
                              <div className="col-md-12">
                                <h5 className="new_project_title">Nouveau screenshot</h5>
                              </div>
                              <form onSubmit={(e) => this.handleSubmitNewPortfolio(e, 'first')} id="addNewPortfolioForm">
                                <div className="select_project col-md-6 form-group">
                                  <select className="form-control project_id_label" name="project_id" onChange={this.handleChange} required>
                                    <option>--Choisir un projet--</option>
                                    {this.state.cvprojects.map((pr, indx) =>
                                      <option key={indx} value={pr.id}> {pr.project_name} </option>
                                    )}
                                  </select>
                                </div>
                                <div className="col-md-6 form-group">
                                  <input type="file" name="img_path" onChange={this.handleChange} className="form-control form_item" />
                                </div>
                                {this.state.isAddRequestError &&
                                  <div className="error-row">
                                    <div className="col-md-12 error-container alert alert-danger">
                                        Une erreur est survenue. Veuillez réessayer plus tard.
                                    </div>
                                  </div>
                                }
                                <div className="col-md-12 form-group">
                                  <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                  {this.state.isAddPortfolioInProgress === false ?
                                    <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                    :
                                    <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                      Ajout en cours
                                        <i className="fas fa-spin fa-spinner"></i>
                                    </button>
                                  }
                                </div>
                              </form>
                            </div>
                          }

                          {/* Render portfolio s'il n'est pas vide */}
                          {this.state.isEducationEmpty === false &&
                            <div className="list_portfolio_row row">
                              {this.state.cvportfolio.map((port, ind) =>
                                <div key={ind} className="portfolio_item_container">
                                  <div className="col-md-4 portfolio_item_container">
                                    <div className="panel panel-warning">
                                      <div className="panel-heading volkeno-orange-bcolor"> {port.projectName} </div>
                                      <div className="panel-body">
                                        <img src={port.img_path} alt={port.projectName} className="img_responsive portfolio_img" />
                                      </div>
                                      <div className="panel-footer" data-toggle="modal" data-target="#showPrtfolioItemModal" onClick={(e) => this.getPortfolioInfos(port)}>
                                        <i className="fas fa-eye fa-2x"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Add new portfolio button */}
                              {this.state.showPortfolioForm2Btn === true &&
                                <div className="add_form_btn_container col-md-12">
                                  <button data-toggle="tooltip" data-placement="top" title="Ajouter un portfolio" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addNewPortfolioForm(e, 'poSecondForm')}>
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              }

                              {/* Render add form au clic sur le bouton ajouter */}
                              {this.state.showPortfolioForm2 === true &&
                                <div className="form_container row">
                                  <div className="col-md-12">
                                    <h5 className="new_project_title">Nouveau screenshot</h5>
                                  </div>
                                  <form onSubmit={(e) => this.handleSubmitNewPortfolio(e, 'second')} id="addNewPortfolioForm">
                                    <div className="select_project col-md-6 form-group">
                                      <select className="form-control project_id_label" name="project_id" onChange={this.handleChange} required>
                                        <option>--Choisir un projet--</option>
                                        {this.state.cvprojects.map((pr, indx) =>
                                          <option key={indx} value={pr.id}> {pr.project_name} </option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="col-md-6 form-group">
                                      <input type="file" name="img_path" onChange={this.handleChange} className="form-control form_item" />
                                    </div>
                                    {this.state.isAddRequestError &&
                                      <div className="error-row">
                                        <div className="col-md-12 error-container alert alert-danger">
                                            Une erreur est survenue. Veuillez réessayer plus tard.
                                        </div>
                                      </div>
                                    }
                                    <div className="col-md-12 form-group">
                                      <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                      {this.state.isAddPortfolioInProgress === false ?
                                        <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                        :
                                        <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                          Ajout en cours
                                        <i className="fas fa-spin fa-spinner"></i>
                                        </button>
                                      }
                                    </div>
                                  </form>
                                </div>
                              }
                            </div>
                          }


                        </div>
                      </div>
                    </div>

                    {/* Render CV Motivation panel */}
                    <div className="panel panel-default">
                      <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingFour">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseMotivation" aria-expanded="false" aria-controls="collapseFour">
                            Motivations
                        </a>
                        </h4>
                      </div>
                      <div id="collapseMotivation" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div className="panel-body">
                          <div className="cv_motivations_container">
                            {/* Render alert si le bakeliste n'a pas encore écrit ses motivations */}
                            {this.state.cvinfos.cv_motivations === null &&
                              this.state.showMotivationForm === false &&
                              <div className="empty_motivations_row row">
                                <div className="col-md-12 error-alert-container alert alert-warning">
                                  Vous n'avez pas encore écrit vos motivations. Nous vous invitons à le faire. Merci !
                                </div> <hr />
                                <div className="add_form_switcher_container">
                                  <button data-toggle="tooltip" data-placement="top" title="Ajouter vos motivations" onClick={(e) => this.addMotivationForm(e, 'moFirstForm', this.state.cvinfos.cv_motivations)} className="btn btn-success volkeno-green-bcolor add_form_btn">
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            }

                            {/* Render premier formulaire d'ajout de motivation */}
                            {this.state.showMotivationForm === true &&
                              <div className="motivations_form_row row">
                                <div className="col-md-12 add_title">
                                  <h5 className="add_motivation_title text-center">Ajouter vos motivations </h5>
                                  <form id="addUpdateMotivationForm" onSubmit={(e) => this.hanldeSubmitMotivation(e, 'first')}>
                                    <div className="col-md-6 col-md-offset-3 form-group cvMotivation">
                                      <textarea placeholder="Ajoutez vos motivations" name="cv_motivations" className="form-control cv_motivation_input" onChange={this.handleChange} />
                                    </div>
                                    {this.state.isAddRequestError &&
                                      <div className="error-row">
                                        <div className="col-md-12 error-container alert alert-danger">
                                            Une erreur est survenue. Veuillez réessayer plus tard.
                                        </div>
                                      </div>
                                    }
                                    <div className="col-md-12 form-group">
                                      <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                      {this.state.isAUMotivationInProgress === false ?
                                        <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                        :
                                        <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                          Ajout en cours
                                        <i className="fas fa-spin fa-spinner"></i>
                                        </button>
                                      }
                                    </div>
                                  </form>
                                </div>
                              </div>
                            }

                            {/* Render motivations si le champ n'est pas vide */}
                            {this.state.cvinfos.cv_motivations !== null &&
                              <div className="motivations_content_row row">
                                <div className="motivations_item_container">
                                  <div className="col-md-12 motivations_item">
                                    <p>
                                      {this.state.cvinfos.cv_motivations}
                                    </p>
                                  </div>
                                </div> <hr />

                                {/* Update motivation show form button */}
                                {this.state.showMotivationForm2Btn === true &&
                                  <div className="add_form_btn_container col-md-12">
                                    <button data-toggle="tooltip" data-placement="top" title="Modifier vos motivations" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addMotivationForm(e, 'moSecondForm', this.state.cvinfos.cv_motivations)}>
                                      <i className="fas fa-edit"></i>
                                    </button>
                                  </div>
                                }

                                {/* Render update formulaire de motivation */}
                                {this.state.showMotivationForm2 === true &&
                                  <div className="motivations_form_row row">
                                    <div className="col-md-12 add_title">
                                      <h5 className="add_motivation_title text-center">Modifier vos motivations </h5>
                                      <form id="addUpdateMotivationForm" onSubmit={(e) => this.hanldeSubmitMotivation(e, 'second')}>
                                        <div className="col-md-6 col-md-offset-3 form-group cvMotivation">
                                          <textarea value={this.state.cvMotivationsValue} placeholder="Ajoutez vos motivations" name="cv_motivations" className="form-control cv_motivation_input" onChange={this.handleChangeUpdateMotivation} />
                                        </div>
                                        {this.state.isAddRequestError &&
                                          <div className="error-row">
                                            <div className="col-md-12 error-container alert alert-danger">
                                                Une erreur est survenue. Veuillez réessayer plus tard.
                                            </div>
                                          </div>
                                        }
                                        <div className="col-md-12 form-group">
                                          <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                          {this.state.isAUMotivationInProgress === false ?
                                            <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                            :
                                            <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                              Modification en cours
                                            <i className="fas fa-spin fa-spinner"></i>
                                            </button>
                                          }
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                }

                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Render Technologies et Languagues panel */}
                    <div className="panel panel-default">
                      <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingFive">
                        <h4 className="panel-title">
                          <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTechnoLang" aria-expanded="false" aria-controls="collapseFive">
                            {this.state.cvinfos.bakelisteDomaineName === 'Programmation' ?
                              <span>Technologies et Langues</span>
                              :
                              <span>Outils et Langues</span>
                            }
                          </a>
                        </h4>
                      </div>
                      <div id="collapseTechnoLang" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                        <div className="panel-body">
                          <div className="techno_lang_row row">
                            <div className="col-md-6 techno_container">
                              <h6 className="text-center techno_lang_title">
                                {this.state.cvinfos.bakelisteDomaineName === 'Programmation' ?
                                  <span>Technologies</span>
                                  :
                                  <span>Outils</span>
                                }
                              </h6>
                              {this.state.isTecnologyEmpty === true &&
                                this.state.showTechnoForm === false &&
                                // Render technology si les données sont nulles
                                <div className="empty_techno_row">
                                  <div className="col-md-12 error-alert-container alert alert-warning">
                                    Vous n'avez pas encore fourni d'informations. Nous vous invitons à le faire. Merci !
                                </div> <hr />
                                  <div className="add_form_switcher_container">
                                    <button data-toggle="tooltip" data-placement="top" title="Ajouter une techno / outils" onClick={(e) => this.addTechnoForm(e, 'teFirstForm')} className="btn btn-success volkeno-green-bcolor add_form_btn">
                                      <i className="fas fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              }

                              {/* Render premier formulaire d'ajout de techno ou outils */}
                              {this.state.showTechnoForm === true &&
                                <div className="techno_form_row row">
                                  <div className="col-md-12 add_title">
                                    <h5 className="add_techno_title text-center">Ajouter une technologies / Outils </h5>
                                    <form id="addTechnoForm" onSubmit={(e) => this.hanldeSubmitTechno(e, 'first')}>
                                      <div className="col-md-6 form-group techno">
                                        <input type="text" placeholder="Ajoutez une technologie / Outils" name="techno_name" className="form-control techno_input" onChange={this.handleChange} />
                                      </div>
                                      <div className="col-md-6 form-group techno">
                                        <input type="text" placeholder="% de maîtrise. Exemple: 65" name="techno_percent" className="form-control percent_input" onChange={this.handleChange} />
                                      </div>
                                      {this.state.isAddRequestError &&
                                        <div className="error-row">
                                          <div className="col-md-12 error-container alert alert-danger">
                                              Une erreur est survenue. Veuillez réessayer plus tard.
                                          </div>
                                        </div>
                                      }
                                      <div className="col-md-12 form-group">
                                        <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                        {this.state.isAddTechnoInProgress === false ?
                                          <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                          :
                                          <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                            Ajout en cours
                                          <i className="fas fa-spin fa-spinner"></i>
                                          </button>
                                        }
                                      </div>
                                    </form>Recrutable
                                </div>
                                </div>
                              }

                              {/* Render techno si le champ n'est pas vide */}
                              {this.state.isTecnologyEmpty === false &&
                                <div className="techno_content_row row">
                                  <div className="techno_item_container">
                                    <div className="col-md-12 techno_item">
                                      <ul className="list_techno_ul">
                                        {this.state.cvtechnology.map((techno, idx) =>
                                          <li key={idx} className="techno_li"> {techno.techno_name} </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div> <hr />

                                  {/* Add new techno show form button */}
                                  {this.state.showTechnoForm2Btn === true &&
                                    <div className="add_form_btn_container col-md-12">
                                      <button data-toggle="tooltip" data-placement="top" title="Ajouter une techno / outils" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addTechnoForm(e, 'teSecondForm')}>
                                        <i className="fas fa-plus"></i>
                                      </button>
                                    </div>
                                  }

                                  {/* Render ajout formulaire de techno */}
                                  {this.state.showTechnoForm2 === true &&
                                    <div className="motivations_form_row row">
                                      <div className="col-md-12 add_title">
                                        <h5 className="add_motivation_title text-center">Ajouter une technologie / outils </h5>
                                        <form id="addTechnoForm" onSubmit={(e) => this.hanldeSubmitTechno(e, 'second')}>
                                          <div className="col-md-6 form-group techno">
                                            <input type="text" placeholder="Ajoutez une technologie / Outils" name="techno_name" className="form-control techno_input" onChange={this.handleChange} />
                                          </div>
                                          <div className="col-md-6 form-group techno">
                                            <input type="text" placeholder="% de maîtrise. Exemple: 65" name="techno_percent" className="form-control percent_input" onChange={this.handleChange} />
                                          </div>
                                          {this.state.isAddRequestError &&
                                            <div className="error-row">
                                              <div className="col-md-12 error-container alert alert-danger">
                                                  Une erreur est survenue. Veuillez réessayer plus tard.
                                              </div>
                                            </div>
                                          }
                                          <div className="col-md-12 form-group">
                                            <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                            {this.state.isAddTechnoInProgress === false ?
                                              <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                              :
                                              <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                                Ajout en cours
                                            <i className="fas fa-spin fa-spinner"></i>
                                              </button>
                                            }
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  }

                                </div>
                              }

                            </div>

                            <div className="col-md-6 lang_container">
                              <h6 className="text-center techno_lang_title">
                                Langues
                            </h6>
                              {this.state.isLanguageEmpty === true &&
                                this.state.showLangForm === false &&
                                // Render technology si les données sont nulles
                                <div className="empty_lang_row">
                                  <div className="col-md-12 error-alert-container alert alert-warning">
                                    Vous n'avez pas encore renseigné les langues que vous parlez. Nous vous invitons à le faire. Merci !
                                  </div> <hr />
                                  <div className="add_form_switcher_container">
                                    <button data-toggle="tooltip" data-placement="top" title="Ajouter une langue" onClick={(e) => this.addLangForm(e, 'laFirstForm')} className="btn btn-success volkeno-green-bcolor add_form_btn">
                                      <i className="fas fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              }

                              {/* Render premier formulaire d'ajout de langue */}
                              {this.state.showLangForm === true &&
                                <div className="techno_form_row row">
                                  <div className="col-md-12 add_title">
                                    <h5 className="add_techno_title text-center">Ajouter une langue </h5>
                                    <form id="addLangForm" onSubmit={(e) => this.hanldeSubmitLang(e, 'first')}>
                                      <div className="col-md-6 form-group techno">
                                        <input type="text" placeholder="Langue" name="language_name" className="form-control language_input" onChange={this.handleChange} />
                                      </div>
                                      <div className="col-md-6 form-group techno">
                                        <input type="text" placeholder="Label. Exemple : Lu, parlé et écrit" name="languague_label" className="form-control label_input" onChange={this.handleChange} />
                                      </div>
                                      {this.state.isAddRequestError &&
                                        <div className="error-row">
                                          <div className="col-md-12 error-container alert alert-danger">
                                              Une erreur est survenue. Veuillez réessayer plus tard.
                                          </div>
                                        </div>
                                      }
                                      <div className="col-md-12 form-group">
                                        <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                        {this.state.isAddLangInProgress === false ?
                                          <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                          :
                                          <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                            Ajout en cours
                                          <i className="fas fa-spin fa-spinner"></i>
                                          </button>
                                        }
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              }

                              {/* Render lang si le champ n'est pas vide */}
                              {this.state.isLanguageEmpty === false &&
                                <div className="lang_content_row row">
                                  <div className="lang_item_container">
                                    <div className="col-md-12 lang_item">
                                      <ul className="list_lang_ul">
                                        {this.state.cvlanguague.map((lang, ix) =>
                                          <li key={ix} className="lang_li"> {lang.language_name} ( {lang.languague_label} ) </li>
                                        )}
                                      </ul>
                                    </div>
                                  </div> <hr />

                                  {/* Add new lang show form button */}
                                  {this.state.showLangForm2Btn === true &&
                                    <div className="add_form_btn_container col-md-12">
                                      <button data-toggle="tooltip" data-placement="top" title="Ajouter une langue" className="btn btn-success volkeno-green-bcolor add_form_btn" onClick={(e) => this.addLangForm(e, 'laSecondForm')}>
                                        <i className="fas fa-plus"></i>
                                      </button>
                                    </div>
                                  }

                                  {/* Render ajout formulaire de lang */}
                                  {this.state.showLangForm2 === true &&
                                    <div className="motivations_form_row row">
                                      <div className="col-md-12 add_title">
                                        <h5 className="add_motivation_title text-center">Ajouter une langue </h5>
                                        <form id="addLangForm" onSubmit={(e) => this.hanldeSubmitLang(e, 'second')}>
                                          <div className="col-md-6 form-group techno">
                                            <input type="text" placeholder="Langue" name="language_name" className="form-control language_input" onChange={this.handleChange} />
                                          </div>
                                          <div className="col-md-6 form-group techno">
                                            <input type="text" placeholder="Label. Exemple : Lu, parlé et écrit" name="languague_label" className="form-control label_input" onChange={this.handleChange} />
                                          </div>
                                          {this.state.isAddRequestError &&
                                            <div className="error-row">
                                              <div className="col-md-12 error-container alert alert-danger">
                                                  Une erreur est survenue. Veuillez réessayer plus tard.
                                              </div>
                                            </div>
                                          }
                                          <div className="col-md-12 form-group">
                                            <button className="btn btn-danger pull-left volkeno-red-bcolor return-btn" onClick={(e) => this.hideItemForm(e)}>Quitter</button>
                                            {this.state.isAddLangInProgress === false ?
                                              <button className="btn btn-success pull-right volkeno-green-bcolor sumbmit-btn">Valider</button>
                                              :
                                              <button className="btn btn-warning pull-right volkeno-orange-bcolor inprogress-btn">
                                                Ajout en cours
                                            <i className="fas fa-spin fa-spinner"></i>
                                              </button>
                                            }
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  }

                                </div>
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            {/* Show portfolio item modal */}
            <div className="modal fade" id="showPrtfolioItemModal" tabindex="-1" role="dialog" aria-labelledby="showPrtfolioItemModalLabel">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 className="modal-title" id="myModalLabel"> {this.state.portProjectName} </h4>
                  </div>
                  <div className="modal-body">
                    <img src={this.state.imgPathValue} alt="" className="img-responsive image_item" />
                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* Success actions toast confirmation */}
        {this.state.isSuccessAddItemAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            Item ajouté avec succès.
            </div>
        }

      </div>
    );
  }
}
