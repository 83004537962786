import React, { Component } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import Menu from "./../menu/Menu";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import axios from "axios";
import API from "../../variablesGlobales";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { MagicSpinner } from "react-spinners-kit";
import * as Sentry from "@sentry/browser";
import MultiSelect from "@khanacademy/react-multi-select";

export default class AssignationSyllabusByCoach extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem("token");
    let userStatus = window.sessionStorage.getItem("user_status");
    // let userID = window.sessionStorage.getItem('user_id');

    if (!token) {
      window.location = "/";
    }
    if (userStatus === "bakeliste") {
      window.history.back();
    }
    super(props);
    this.state = {
      api: API.API,
      isPageLoading: true,
      userID: window.sessionStorage.getItem("user_id"),
      syllabusDatas: [],
      syllabusInfos: [],
      addSyllabusContentInProgress: false,
      syllabusID: 0,
      bakelisteID: 0,
      fields: {},
      errors: {},
      addSuccessDetailsAlert: false,
      addErrorDetailsAlert: false,
      sskills_empty: false,
      stask_empty: false,
      scompletiontime_empty: false,
      sweeknumber_empty: false,
      dsInfos: [],
      upInfos: [],
      sSkillValue: "",
      sTaskValue: "",
      sCompletionTimeValue: 0,
      sCompletionWeek: 0.0,
      sWeekNumber: 0,
      editSyllabusContentInProgress: false,
      editSuccessDetailsAlert: false,
      editErrorDetailsAlert: false,
      _fields: {},
      selectOptions: [],
      selected: [],
      bakelistesByCoach: [],
      bakeliste: [],
      assignationEncours: false,
      assignationSuccessAlert: false,
      isAssignationError: false,
      noSavedData: [],
      syllabusByCoache: [],
      onlySelect: false,
      groupySelect: false,
    };
    this.addDetailsHandleChange = this.addDetailsHandleChange.bind(this);
    this.onSubmitDetailsSyllabus = this.onSubmitDetailsSyllabus.bind(this);
    this.editDetailsHandleChange = this.editDetailsHandleChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.assignSyllabus = this.assignSyllabus.bind(this);
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    this.setState({
      syllabusID: params.syllabus_id,
    });
    this.setState({
      bakelisteID: params.bakeliste_user_id,
    });

    this.onGetSyllabusInfos(params.syllabus_id);
    this.onGetDetailsBySyllabus(params.syllabus_id);
    this.getSyllabusAssignedByCoach();
    this.getBakelisteByCoach(params.bakeliste_user_id);
  };

  onGetSyllabusInfos = (syllabus_id) => {
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "syllabus/" + syllabus_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            syllabusInfos: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onGetDetailsBySyllabus = (syllabus_id) => {
    this.setState({
      isPageLoading: true,
    });

    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "details-by-syllabus/" + syllabus_id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });

        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            syllabusDatas: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
      </ButtonGroup>
    );
  };

  onRowClick = (row) => {
    this.setState({
      dsInfos: row,
    });
    $(".open-infos-modal").click();
  };

  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === "addSyllabusContentInProgress") {
        this.setState({
          addSyllabusContentInProgress: false,
        });
      }
      if (label === "addSuccessDetailsAlert") {
        this.setState({
          addSuccessDetailsAlert: false,
        });
      }
      if (label === "addErrorDetailsAlert") {
        this.setState({
          addErrorDetailsAlert: false,
        });
      }
      if (label === "sskills_empty") {
        this.setState({
          sskills_empty: false,
        });
      }
      if (label === "stask_empty") {
        this.setState({
          stask_empty: false,
        });
      }
      if (label === "scompletiontime_empty") {
        this.setState({
          scompletiontime_empty: false,
        });
      }
      if (label === "sweeknumber_empty") {
        this.setState({
          sweeknumber_empty: false,
        });
      }
      if (label === "editSuccessDetailsAlert") {
        this.setState({
          editSuccessDetailsAlert: false,
        });
      }
      if (label === "editErrorDetailsAlert") {
        this.setState({
          editErrorDetailsAlert: false,
        });
      }
    }, 5000);
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = {};
    var isValidForm = true;

    if (!fields["syllabus_skills"]) {
      isValidForm = false;
      this.setState({
        sskills_empty: true,
      });
      this.onSwitchAlertStatus("sskills_empty");
      errors["sskills_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_task"]) {
      isValidForm = false;
      this.setState({
        stask_empty: true,
      });
      this.onSwitchAlertStatus("stask_empty");
      errors["stask_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_completion_time"]) {
      isValidForm = false;
      this.setState({
        scompletiontime_empty: true,
      });
      this.onSwitchAlertStatus("scompletiontime_empty");
      errors["scompletiontime_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_week_number"]) {
      isValidForm = false;
      this.setState({
        sweeknumber_empty: true,
      });
      this.onSwitchAlertStatus("sweeknumber_empty");
      errors["sweeknumber_empty"] = "*Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  addDetailsHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.syllabusID;
    fields["created_by"] = this.state.userID;

    if (e.target.name === "syllabus_completion_time") {
      var sct = e.target.value;
      var week = (sct / 45).toFixed(1);
      fields["syllabus_completion_week"] = week;
    }

    this.setState({
      fields: fields,
    });
    console.log(fields);
  };

  onSubmitDetailsSyllabus = (e) => {
    e.preventDefault();

    if (this.onValidateFormData()) {
      this.setState({
        addSyllabusContentInProgress: true,
      });

      var data = this.state.fields;
      var baseApiUrl = this.state.api;

      axios
        .post(baseApiUrl + "details-syllabus", data)
        .then((response) => {
          this.setState({
            addSyllabusContentInProgress: true,
          });

          if (response.data.success) {
            this.onGetDetailsBySyllabus(this.state.syllabusID);
            this.setState({
              addSuccessDetailsAlert: true,
            });
            this.onSwitchAlertStatus("addSuccessDetailsAlert");
            window.location.reload();
          } else {
            console.log(response);
            this.setState({
              addErrorDetailsAlert: true,
            });
            this.onSwitchAlertStatus("addErrorDetailsAlert");
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.setState({
            addSyllabusContentInProgress: false,
            addErrorDetailsAlert: true,
          });
          this.onSwitchAlertStatus("addErrorDetailsAlert");
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  weekNumberFormatter = (cell, row) => {
    return <span className="semaine_number_container"> Semaine {cell} </span>;
  };

  completionWeekFormatter = (cell, row) => {
    return (
      <span className="semaine_completion_container">
        {" "}
        {cell + " semaine(s)"}{" "}
      </span>
    );
  };

  getDetailsInfos = (item) => {
    this.setState({
      upInfos: item,
      dsInfos: item,
      sSkillValue: item.syllabus_skills,
      sTaskValue: item.syllabus_task,
      sCompletionTimeValue: item.syllabus_completion_time,
      sCompletionWeek: item.syllabus_completion_week,
      sWeekNumber: item.syllabus_week_number,
    });
  };

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button
          data-toggle="modal"
          data-target="#detailsInfosModal"
          className="action-btn-item show_icon"
          onClick={(e) => this.getDetailsInfos(row)}
        >
          <img
            src="https://img.icons8.com/material-rounded/20/FFFFFF/visible.png"
            className="actions_icon"
            alt="Visualiser"
            title="Visualiser"
          />
        </button>
        <button
          data-toggle="modal"
          data-target="#editDetailsSyllabusModal"
          className="action-btn-item edit_icon"
          onClick={(e) => this.getDetailsInfos(row)}
        >
          <img
            src="https://img.icons8.com/ios-glyphs/20/FFFFFF/edit.png"
            className="actions_icon"
            alt="Modifier"
            title="Modifier"
          />
        </button>
      </div>
    );
  };

  editDetailsHandleChange = (e) => {
    let fields = this.state._fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.syllabusID;
    fields["created_by"] = this.state.userID;

    if (e.target.name === "syllabus_completion_time") {
      var sct = e.target.value;
      var week = (sct / 45).toFixed(1);
      fields["syllabus_completion_week"] = week;
    } else {
      fields["syllabus_completion_week"] = this.state.sCompletionWeek;
    }

    if (e.target.name === "syllabus_skills") {
      this.setState({
        sSkillValue: e.target.value,
      });
    }
    if (e.target.name === "syllabus_task") {
      this.setState({
        sTaskValue: e.target.value,
      });
    }
    if (e.target.name === "syllabus_completion_time") {
      this.setState({
        sCompletionTimeValue: e.target.value,
      });
    }
    if (e.target.name === "syllabus_week_number") {
      this.setState({
        sWeekNumber: e.target.value,
      });
    }

    if (!fields["syllabus_skills"]) {
      fields["syllabus_skills"] = this.state.sSkillValue;
    }
    if (!fields["syllabus_task"]) {
      fields["syllabus_task"] = this.state.sTaskValue;
    }
    if (!fields["syllabus_completion_time"]) {
      fields["syllabus_completion_time"] = this.state.sCompletionTimeValue;
    }
    if (!fields["syllabus_week_number"]) {
      fields["syllabus_week_number"] = this.state.sWeekNumber;
    }

    this.setState({
      _fields: fields,
    });
    console.log(fields);
  };

  // Bakeliste by coach
  getBakelisteByCoach() {
    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.userID)
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            bakelistesByCoach: data,
          });
        }
        data.forEach((item) => {
          let infos = {
            label: item.first_name + " " + item.last_name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  getSyllabusAssignedByCoach = () => {
    axios
      .get(this.state.api + `syllabus-by-domaine/${this.state.userID}`)
      .then((res) => {
        if (res.data.data !== undefined) {
          this.setState({
            syllabusByCoache: res.data.data.reverse(),
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onOnlyAssignation = () => {
    this.setState({
      onlySelect: true,
      groupySelect: false,
    });
  };
  /**
   * Activate groupy select assignation
   */
  onGroupyAssignation = () => {
    this.setState({
      onlySelect: false,
      groupySelect: true,
    });
  };

  handleChange(e) {
    e.preventDefault();
    var fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    console.log(this.state.fields);
  }

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "successAssignation") {
        this.setState({
          assignationSuccessAlert: false,
        });
      }
      if (label === "errorAssignation") {
        this.setState({
          isAssignationError: false,
        });
      }
      if (label === "successStatusUpdate") {
        this.setState({
          updateTacheStatusSuccess: false,
        });
      }
      if (label === "tacheRequestSuccess") {
        this.setState({
          updateTacheRequestSuccessAlert: false,
        });
      }
      if (label === "tacheRequestError") {
        this.setState({
          updateTacheRequestError: false,
        });
      }
      if (label === "errorStatusUpdate") {
        this.setState({
          isUpdatedError: false,
          updateTacheStatusError: false,
        });
      }
    }, 5000);
  }

  // assigner sylabus a un bakeliste
  assignSyllabus() {
    this.setState({
      assignationEncours: true,
    });
    let data = this.state.fields;
    if (this.state.onlySelect) {
      data["syllabus_id"] = this.state.syllabusID;
      data["is_only"] = 1;
      data["is_groupy"] = 0;
      data["selected_bakeliste"] = this.state.fields.selected_bakeliste;
    } else if (this.state.groupySelect) {
      data["syllabus_id"] = this.state.syllabusID;
      data["is_groupy"] = 1;
      data["is_only"] = 0;
      data["selected_bakeliste"] = this.state.selected;
    }
    console.log(data);
    axios
      .post(this.state.api + "assignation", data)
      .then((res) => {
        if (res.data.success === true && res.data.nosavedStatus === false) {
          $(".close").click();
          document.getElementById("create-syllabus-form").reset();
          $("#date_syllabus").val("");
          this.setState({
            assignationEncours: false,
            assignationSuccessAlert: true,
            fields: {},
          });
          this.handleShowAndHideAlert("successAssignation");
          this.setState({
            syllabusDatas: res.data.data,
          });
          console.log(this.state.syllabusDatas);
        } else if (
          res.data.success === true &&
          res.data.nosavedStatus === true
        ) {
          //this.getSyllabusAssignedByCoach();
          $(".close").click();
          document.getElementById("create-syllabus-form").reset();
          $("#date_syllabus").val("");
          this.setState({
            assignationEncours: false,
            assignationSuccessAlert: true,
            noSavedAndSavedTrueAlert: true,
            noSavedData: res.data.nosaved,
            fields: {},
          });

          this.handleShowAndHideAlert("successAssignation");
        } else if (
          res.data.success === false &&
          res.data.nosavedStatus === true
        ) {
          this.setState({
            assignationEncours: false,
            isAssignationError: true,
          });

          this.handleShowAndHideAlert("errorAssignation");
        } else {
          this.setState({
            assignationEncours: false,
            isAssignationError: true,
          });

          this.handleShowAndHideAlert("errorAssignation");
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          assignationEncours: false,
          isAssignationError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }
  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
      noDataText: "Aucune donnée pour le moment sur ce syllabus",
    };
    if (this.state.isPageLoading) {
      return (
        <div className="component-syllabus-content-manage-view">
          <Menu />
          <div className="row no-content-wrapper">
            <section className="content-header">
              <h1>Syllabus : {this.state.syllabusInfos.syllabus_name}</h1>
              <ol className="breadcrumb">
                <li>
                  <NavLink to="/dashboard-admin"> Accueil</NavLink>
                </li>
                <li>
                  <NavLink to="/syllabus-manager"> Syllabus Manager</NavLink>
                </li>
                <li className="active">
                  Syllabus : {this.state.syllabusInfos.syllabus_name}{" "}
                </li>
              </ol>
            </section>

            <section className="content">
              <div className="loader-row row">
                <div className="col-md-4 col-md-offset-4 loader-container">
                  <MagicSpinner
                    size={150}
                    color="#009688"
                    loading={this.state.isPageLoading}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }

    return (
      <div className="component-syllabus-content-manage-view">
        <Menu />
        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Syllabus : {this.state.syllabusInfos.syllabus_name}</h1>
            <ol className="breadcrumb">
              <li>
                <NavLink to="/dashboard-admin"> Accueil</NavLink>
              </li>
              <li>
                <NavLink to="/syllabus-manager"> Syllabus Manager</NavLink>
              </li>
              <li className="active">
                Syllabus : {this.state.syllabusInfos.syllabus_name}{" "}
              </li>
            </ol>
          </section>

          <section className="content">
            <div className="row add_syllabus_container">
              <div className="col-md-6 col-md-offset-3 add-btn-container">
                <button
                  data-toggle="modal"
                  data-target="#assignSyllabusModale"
                  className="btn btn-success bg-vgreen add_btn button"
                >
                  <img
                    src="https://img.icons8.com/ios/50/FFFFFF/add.png"
                    alt="Ajouter"
                  />
                </button>
              </div>
            </div>
          </section>
        </div>

        {/* Assign syllabus to bakeliste */}
        <div
          className="modal fade"
          id="assignSyllabusModale"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="assignSyllabusModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="assignSyllabusModalLabele">
                  Assigner un syllabus
                </h4>
              </div>
              <form id="add_syllabus_form">
                <div className="modal-body">
                  <div className="col-md-12 form-group assignation-type-choice">
                    <div className="item-label">
                      Choisir un type d'assignation
                    </div>
                    <input
                      type="radio"
                      name="only-groupy"
                      value="only"
                      className="item1"
                      onClick={this.onOnlyAssignation}
                    />{" "}
                    <span className="radioItem">Assignation unique</span> <br />
                    <input
                      type="radio"
                      name="only-groupy"
                      value="groupy"
                      className="item2"
                      onClick={this.onGroupyAssignation}
                    />{" "}
                    <span className="radioItem">Assignation groupée</span>
                  </div>
                  {/* Assignation unique select view */}
                  {this.state.onlySelect && (
                    <div className="form-group col-md-12">
                      <label>Bakeliste</label>
                      <select
                        className="form-control"
                        id=""
                        name="selected_bakeliste"
                        onChange={(e) => this.handleChange(e)}

                        // onChange={this.handleChange}
                      >
                        <option>--Choisir un bakeliste--</option>
                        {this.state.bakelistesByCoach.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.first_name}&nbsp;{x.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {/* Assignation groupée select view // */}
                  {this.state.groupySelect && (
                    <div className="form-group col-md-12">
                      <label>Groupe de Bakelistes</label>
                      <MultiSelect
                        overrideStrings={{
                          selectSomeItems: "Choisissez les bakelistes",
                          allItemsAreSelected:
                            "Tous vos bakelistes on été choisis",
                          selectAll: "Sélectionnez tout",
                          search: "Rechercher",
                        }}
                        options={this.state.selectOptions}
                        selected={this.state.selected}
                        onSelectedChanged={(selected) =>
                          this.setState({ selected })
                        }
                      />
                    </div>
                  )}
                  {this.state.addSyllabusErrorAlert && (
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert">
                        Un problème est survenu lors de l'ajout. Veuillez
                        réessayer plus tard.
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default bg-vred cancel-btn pull-left"
                    data-dismiss="modal"
                  >
                    <span className="cancel_btn_label"> Quitter </span>
                    <img
                      className="cancel-icon"
                      src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png"
                      alt="Annuler"
                    />
                  </button>
                  {!this.state.assignationEncours && (
                    <button
                      type="button"
                      className="btn btn-primary btn_for_assignation button addBtn"
                      onClick={() => this.assignSyllabus()}
                    >
                      <i className="fas fa-user-check"></i>&nbsp; Assigner
                    </button>
                  )}
                  {this.state.assignationEncours && (
                    <button
                      type="button"
                      className="btn btn-warning button addBtn btn_for_wait"
                    >
                      Assignation en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="detailsInfosModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ratingInfosModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="ratingInfosModalLabel">
                  Détails de la tâche
                </h4>
              </div>
              <div className="modal-body">
                <div className="row modal-row">
                  <div className="col-md-12 rating-item">
                    <div className="row ds-row">
                      <div className="col-md-12 item_ds_bloc">
                        <div className="item-ds-title">
                          {" "}
                          Compétences à acquérir{" "}
                        </div>
                        <div className="item-ds-infos">
                          {" "}
                          {this.state.dsInfos.syllabus_skills}{" "}
                        </div>
                      </div>
                      <div className="col-md-12 item_ds_bloc">
                        <div className="item-ds-title"> Tâche </div>
                        <div className="item-ds-infos">
                          {" "}
                          {this.state.dsInfos.syllabus_task}{" "}
                        </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial">
                          {" "}
                          Délais en Heure{" "}
                        </div>
                        <div className="item-ds-infos partial">
                          {" "}
                          {this.state.dsInfos.syllabus_completion_time +
                            " heure(s)"}{" "}
                        </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial">
                          {" "}
                          Délais en Semaine{" "}
                        </div>
                        <div className="item-ds-infos partial">
                          {" "}
                          {this.state.dsInfos.syllabus_completion_week +
                            " semaine(s)"}{" "}
                        </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial">
                          {" "}
                          N° de la semaine{" "}
                        </div>
                        <div className="item-ds-infos partial">
                          {" "}
                          {this.state.dsInfos.syllabus_week_number}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Success submit tost alert */}
        {this.state.assignationSuccessAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; Le syllabus a
            été assigné avec succès.
          </div>
        )}
        {/* Success submit tost alert */}
        {this.state.editSuccessDetailsAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; La tâche a été
            modifiée avec succès.
          </div>
        )}
      </div>
    );
  }
}
