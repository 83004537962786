import React, { Component } from "react";

import axios from "axios";
import * as Sentry from "@sentry/browser";
import { MagicSpinner } from "react-spinners-kit";
import API from "../../variablesGlobales";
import $ from "jquery";
import MultiSelect from "@khanacademy/react-multi-select";

import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import { Link, NavLink } from "react-router-dom";

import Menu from "../menu/Menu";
import { findDOMNode } from "react-dom";

export default class Groupe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group_name: "",
      name: "",
      api: API.API,
      fields: {},
      addGroupInProgress: false,
      userID: window.sessionStorage.getItem("user_id"),
      isSuccessAjout: false,
      isErrorAjout: false,
      isAjouterInProgress: false,
      onlySelect: false,
      groupySelect: true,
      bakelistes: [],
      ajoutEnCours: false,
      ajoutSuccessAlert: false,
      isAjoutError: false,
      noSavedData: [],
      selectOptions: [],
      selected: [],
      selectOptions1: [],
      selectOptions2: [],
      syllabusByCoache: [],
      addSGroupInProgress: false,
      newSousGroup: {
        description:  "",
        name: ""
      },
      newGroup: {
        group_name: "",
      },
      liste:[],
      groupes: [],
      Sous_groupes: [],
      groupe: {},
      groupeInfos: [],
      sgroupe: {},
      sgroupeInfos: [],
     sgroupID: 0,
     sgId:0,

      
    };
    this.handleChange = this.handleChange.bind(this);
    this.addSousGroup = this.addSousGroup.bind(this);
    this.AjouterBakelisteModal = this.AjouterBakelisteModal.bind(this);
    this.ajoutBakeliste = this.ajoutBakeliste.bind(this);
    this.handleChangeGroupe = this.handleChangeGroupe.bind(this);
  }
  componentDidMount() {
    this.__getGroupes();
    this.__getSousGroupes();
    this.getBakelistes();
  // this.__getBakelisteBySousGroupes();

  }


  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "successAjout") {
        this.setState({
          ajoutSuccessAlert: false,
        });
      }
      if (label === "errorAjout") {
        this.setState({
          isAjoutError: false,
        });
      }
     
    }, 5000);
  }
  __getGroupes = () => {
    axios
      .get(this.state.api + "groupes")
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            groupes: res.data.data.reverse(),
          });
        }
        console.log(this.state.groupes);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

   // ajouter un bakeliste dans un sous-groupe
    ajoutBakeliste() {
    this.setState({
      ajoutEnCours: true,
      onlySelect: false,
      groupySelect: true,
    });
    let data = this.state.fields;
    if (this.state.onlySelect) {
      data["sous_groupe_id"] = this.state.sgroupeInfos.id;
      data["is_only"] = 1;
      data["is_groupy"] = 0;
      data["bakeliste_ids"] = this.state.fields.bakeliste_ids;
    } else if (this.state.groupySelect) {
      data["sous_groupe_id"] = this.state.sgroupeInfos.id;
      data["is_groupy"] = 1;
      data["is_only"] = 0;
      data["bakeliste_ids"] = this.state.selected;
    }
    console.log("adddddddddddddddddddd",data);
    axios
      .post(this.state.api + "sous_groupes/add-bakeliste", data)
      .then((res) => {
        if (res.data.success === true && res.data.nosavedStatus === false) {
          $(".close").click();
          document.getElementById("ajout-bakeliste-groupe").reset();
          $("#date_groupe").val("");
          this.setState({
            ajoutEnCours: false,
            ajoutSuccessAlert: true,
            fields: {},
          });
          this.handleShowAndHideAlert("successAjout");
          this.setState({
            groupes: res.data.data,
          });
          //console.log(this.state);
        } else if (
          res.data.success === true &&
          res.data.nosavedStatus === true
        ) {

          document.getElementById("ajout-bakeliste-groupe").reset();
          $("#date_groupe").val("");
          this.setState({
            ajoutEnCours: false,
            ajoutSuccessAlert: true,
            noSavedAndSavedTrueAlert: true,
            noSavedData: res.data.nosaved,
            fields: {},
          });

          this.handleShowAndHideAlert("successAjout");
        } else if (
          res.data.success === false &&
          res.data.nosavedStatus === true
        ) {
          this.setState({
            ajoutEnCours: false,
            isAjoutError: true,
          });

          this.handleShowAndHideAlert("errorAjout");
        } else {
          this.setState({
            ajoutEnCours: false,
            isAjoutError: true,
          });

          this.handleShowAndHideAlert("errorAjout");
        }
        $(".close").click();
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          ajoutEnCours: false,
          isAjoutError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }
  AjouterBakelisteModal = (id) => {
    this.setState({
      sgroupID: id,
    });
   console.log("groupe id", this.state.sgroupeInfos.id);
  };

// liste des bakelistes
  getBakelistes() {
    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.userID)
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            bakelistes: data,
          });
        }
        data.forEach((item) => {
          let infos = {
            label: item.first_name + " " + item.last_name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  __getSousGroupes = () => {
    axios
      .get(this.state.api + "sous__groupes" )
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            Sous_groupes: res.data.data.reverse(),
          });
        }
        console.log(this.state.Sous_groupes);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };


  deleteGroup = (group) => {
    axios
      .delete(this.state.api + "groupes/" + group.id)
      .then((res) => {
        if (res.data.success) {
          this.__getGroupes();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  deleteSousGroup = (sgroup) => {
    axios
      .delete(this.state.api + "sous__groupes/" + sgroup.id)
      .then((res) => {
        if (res.data.success) {
          this.__getSousGroupes();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  actionsFormatterSousGroup(cell, row){
    return (
      <span>
        <button
          onClick={() => {
            this.deleteSousGroup(row);
          }}
          id="delete_group"
          className="btn btn-danger volkeno-red-bcolor"
        >
          <i class="fa fa-trash" title='supprimer'></i>
        </button>
        &nbsp;&nbsp;
        <button
          onClick={() => {
            this.onGetSousGroupeInfos(row);
          } }
          data-toggle="modal"
          className="btn btn-success volkeno-green-bcolor"
          //data-placement="top"
          data-target="#ajouterBakelisteModale"
          
        >
          
          <i className="fas fa-plus-circle" title='ajouter bakeliste'></i>
        </button>
        &nbsp;&nbsp;
        <button
          onClick={() => {
            this.__getBakelisteBySousGroupes(row.id);
            this.onGetSousGroupeInfos(row);
          } }
          data-toggle="modal"
          className="btn btn-warning volkeno-orange-bcolor"
          //data-placement="top"
          data-target="#voirListeModale"
          
        >
          <i className="fas fa-eye" title='membres'></i>
        </button>
      </span>  
       
    );
  }


  
  handleChangeGroupe(e) {
    e.preventDefault();
    var fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
   // console.log(this.state.fields);
  }
  actionsFormatter(cell, row) {
    return (
      <span>
        <button
          onClick={() => {
            this.deleteGroup(row);
          }}
          id="delete_group"
          className="btn btn-danger volkeno-red-bcolor"
        >
          <i class="fa fa-trash" title="supprimer"></i>
        </button>
        &nbsp;&nbsp;
        
        <button
          onClick={() => {
            this.onGetGroupeInfos(row);
          } }
          data-toggle="modal"
          className="btn btn-success volkeno-green-bcolor"
         // data-placement="top"
          data-target="#addSousGroupeModal"
           >
           
          <i className="fas fa-plus-circle" title="ajouter sous-groupe"></i>
          </button>
     
        &nbsp;&nbsp;
      </span>  
    );
  }


  onGetGroupeInfos(group) {
     this.setState({
       groupeInfos: group
 
     });
     //console.log(this.state.groupeInfos, "bfuefgjb")
   }

  onGetSousGroupeInfos(sgroup) {
    this.setState({
      sgroupeInfos: sgroup,
      sgId: sgroup.id

    });
    console.log(this.state.sgroupeInfos, " idddddddd")
    console.log(this.state.sgId,    "ids")
  }
  

// Ajouter groupes
  addGroups() {
    this.setState({
      addGroupInProgress: true,
    });

    var data = this.state.fields
    var fd = new FormData();
    fd.append("group_name", this.state.group_name);
    axios
      .post(this.state.api + "groupes",fd, this.state.newGroup)
      .then((res) => {
        if (res.data.success) {
          this.__getGroupes();
          $('#group_name').val('')

          $(".close").click();
          this.setState({
            addGroupInProgress: false,
            group_name: ""
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  handleChange = (e) => {
    
     let fields = this.state.fields;
     fields[e.target.name] = e.target.value;
     fields["created_by"] = this.state.userID;
     fields["groupe_id"] = this.state.groupeInfos.id;
   }


   addSousGroup(group) {
    this.setState({
      addSGroupInProgress: true,
    });
    var data = this.state.fields
    var fd = new FormData()
    fd.append('created_by', this.state.userID);
    fd.append('groupe_id', this.state.groupeInfos.id );
    fd.append("description", this.state.description);
    fd.append("name", this.state.name);

    axios
      .post(this.state.api + "sous__groupes",fd, this.state.newSousGroup)
      .then((res) => {
        console.log("name", this.state.name)
        if (res.data.success) {
          this.__getSousGroupes();
          $('#created_by').val('')
          $('#groupe_id').val('')
          $('#description').val('')
          $('#name').val('')

          $(".close").click();
          this.setState({
            addSGroupInProgress: false,
            description: "",
            name: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } 




  __getBakelisteBySousGroupes = (id) => {
    console.log(id, "deeeee")
    axios
      .get(this.state.api + "sous__groupes/" + id  )
      .then((response) => {
        console.log("en cours")
        this.setState({
          loading: false,
        });
        if (response.data.success) {
          this.setState({
            liste: response.data.liste[0].reverse(),
          });
         // console.log(response.data, "listeeeeeeee");

        }
       // console.log(this.state.liste[0], "liste bak");
        console.log(this.state.liste, "liste bak");

      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };


  AjoutMultiple = () => {
    this.setState({
      onlySelect: false,
      groupySelect: true,
    });
  };


  AjoutUnique = () => {
    this.setState({
      onlySelect: true,
      groupySelect: false,
    });
  };

  render() {
    const options = {
      // clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
    };
    return (
      <div>
        <Menu />
        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Liste des groupes</h1>
            <ol class="breadcrumb">
              <li>
                <NavLink to="dashboard-admin">
                  <i class="fa fa-dashboard"></i> Home
                </NavLink>
              </li>
              <li className="active">Liste des groupes</li>
            </ol>
          </section>
          <div className="row add_prospect_container">
            <div className="col-md-6 col-md-offset-3 add-prospect-btn-container">
              <span
                data-toggle="modal"
                className="btn btn-success add_coach_btn button button addBtn"
                data-placement="top"
                data-target="#addGroupsModal"
              >
                <i className="fas fa-plus-circle"></i>
              </span>
            </div>
          </div>

          {/* Modal Add Groupe */}

          <div
            className="modal fade"
            id="addGroupsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addGroupsModalLabel"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                  
                    
                    <span aria-hidden="true">&times;</span>
                  </button> 
                  <h4 className="modal-title" id="addGroupsModalLabel">
                    Ajouter un groupe de travail
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="edit-bakeliste-container">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label>Nom du groupe</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="Nom"
                          name="group_name"
                          value={this.state.group_name}
                          onChange={(e) => {
                            let newGroup = this.state;
                            newGroup.group_name = e.target.value;
                            this.setState({
                              newGroup,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div> 
                  <div className="modal-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-default cancel_btn cancel button"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times-circle"></i> &nbsp; Quitter
                        </button>
                        {!this.state.addGroupInProgress ? (
                          <button
                            type="button"
                            className="btn btn-primary btn_for_assignation button addBtn"
                            onClick={(e) => this.addGroups(e)}
                          >
                            <i className="fas fa-undo"></i>&nbsp; Ajouter
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning button addBtn btn_for_wait"
                          >
                            Ajouter en cours &nbsp;
                            <i className="fas fa-spinner fa-spin"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          


          {/* Modal Add SOus groupe */}

          <div
            className="modal fade"
            id="addSousGroupeModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addSousGroupsModalLabel"
          >
            <div className="modal-dialog ajout" role="document"  >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="addSousGroupsModalLabel">
                    Ajouter un sous-groupe dans {this.state.groupeInfos.group_name}
                  </h4>
                </div>

                <div className="modal-body">
                  <div className="edit-bakeliste-container">
                    <div className="col-md-6">
                      <div className="form-group ">
                      <div >
                        <label>Nom du sous-groupe</label>
                        <input
                          type="text"
                          className="form-control" 
                          placeholder="Nom"
                          name="name"
                          required  onChange={ this.handleChange }
                          value={this.state.name}
                          onChange={(e) => {
                            let newSousGroup = this.state;
                            newSousGroup.name = e.target.value;
                            this.setState({
                              newSousGroup,
                            });
                          }}
                        />
                      </div>
                      <div >
                        <label>Description</label>
                        <textarea
                          type="text"
                          className="form-control" 
                          placeholder="Description"
                          name="description"
                          required  onChange={ this.handleChange }
                          value={this.state.description}
                          onChange={(e) => {
                            let newSousGroup = this.state;
                            newSousGroup.description = e.target.value;
                            this.setState({
                              newSousGroup,
                            });
                          }}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-default bg-vred cancel-btn pull-left"
                          data-dismiss="modal"
                        >
                          <span className="cancel_btn_label"> Quitter </span>
                          <img
                            className="cancel-icon"
                            src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png"
                            alt="Annuler"
                          />
                        </button>
                        {!this.state.addSGroupInProgress ? (
                          <button
                          type="button"
                          className="btn btn-primary btn_for_assignation button addBtn"
                          onClick={() => this.addSousGroup()}
                        >
                          <i className="fas fa-user-check"></i>&nbsp; Ajouter
                        </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning button addBtn btn_for_wait"
                          >
                            Ajouter en cours &nbsp;
                            <i className="fas fa-spinner fa-spin"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
          
         

          <BootstrapTable
            data={this.state.groupes}
            striped={true}
            hover={true}
            condensed={true}
            multiColumnSort={2}
            options={options}
            pagination
            search={true}
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              {" "}
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="group_name">Nom</TableHeaderColumn>

            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionsFormatter.bind(this)}
              dataSort={true}
            >
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
       
       
          <div style={{fontSize:"30px", fontWeight:"700", marginTop:"13px"}}>
            <label>Liste des sous-groupes </label>
          </div>
          <BootstrapTable
            data={this.state.Sous_groupes}
            striped={true}
            hover={true}
            condensed={true}
            multiColumnSort={2}
            options={options}
            pagination
            search={true}
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              {" "}
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="groupe_name">Groupes</TableHeaderColumn>
            <TableHeaderColumn dataField="name">Sous-groupes</TableHeaderColumn>
            <TableHeaderColumn dataField="description">Description</TableHeaderColumn>

            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionsFormatterSousGroup.bind(this)}
              dataSort={true}
            >
              Actions
            </TableHeaderColumn> 
          </BootstrapTable>


         {/* Modal liste bakelistes dans un sous groupe */}

         <div
            className="modal fade"
            id="voirListeModale"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="voirListeModalLabel"
          >
            <div className="modal-dialog ajout" role="document"  >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="voirListeModalLabel">
                    Liste des bakelistes dans {this.state.sgroupeInfos.name}
                  </h4>
                </div>
                <div className="modal-body">
                               
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Prenom</th>
                          <th scope="col">Nom</th>
                          <th scope="col">Email</th>
                        </tr>
                      </thead>
                      <tbody> 
                      {this.state.liste.map((item, index) => ( 
                        <tr  key={index}>

                          <td>{item.first_name}</td>
                          <td>{item.last_name}</td>
                          <td>{item.email}</td>
                        </tr> 
                          ))} 
                      </tbody>
                    </table>
               
                </div>

              </div>
            </div>
          </div>
          
          


        {/* Ajouter bakeliste dans un groupe*/}
        <div
          className="modal fade"
          id="ajouterBakelisteModale"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ajouterBakelisteModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="assignSyllabusModalLabele">
                  Ajouter bakelistes dans {this.state.sgroupeInfos.name}
                </h4>
              </div>
              <form id="add_syllabus_form">
                <div className="modal-body">{/* 
                  <div className="col-md-12 form-group assignation-type-choice">
                    <div className="item-label">
                      Choisir un type d'assignation
                    </div>
                    <input
                      type="radio"
                      name="only-groupy"
                      value="only"
                      className="item1"
                      onClick={this.AjoutUnique}
                    />{" "}
                    <span className="radioItem">Ajout unique</span> <br />
                    <input
                      type="radio"
                      name="only-groupy"
                      value="groupy"
                      className="item2"
                      onClick={this.AjoutMultiple}
                    />{" "}
                    <span className="radioItem">Ajout multiple</span>
                  </div>
                 */}  {/* Ajout unique select view */}
                  {/* {this.state.onlySelect && (
                    <div className="form-group col-md-12">
                      <label>Bakeliste</label>
                      <select
                        className="form-control"
                        id=""
                        name="bakeliste_ids"
                        onChange={(e) => this.handleChangeGroupe(e)}

                        // onChange={this.handleChange}
                      >
                        <option>--Choisir un bakeliste--</option>
                        {this.state.bakelistes.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.first_name}&nbsp;{x.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )} */}
                  {/* Ajout multiple select view // */}
                  
                    <div className="form-group col-md-12">
                      <label>Choisissez un ou des Bakelistes</label>
                      <MultiSelect
                        overrideStrings={{
                          selectSomeItems: "Choisissez les bakelistes",
                          allItemsAreSelected:
                            "Tous vos bakelistes on été choisis",
                          selectAll: "Sélectionnez tout",
                          search: "Rechercher",
                        }}
                        options={this.state.selectOptions}
                        selected={this.state.selected}
                        onSelectedChanged={(selected) =>
                          this.setState({ selected })
                        }
                      />
                    </div>
               
                  {this.state.ajoutBakelisteErrorAlert && (
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert">
                        Un problème est survenu lors de l'ajout. Veuillez
                        réessayer plus tard.
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default bg-vred cancel-btn pull-left"
                    data-dismiss="modal"
                  >
                    <span className="cancel_btn_label"> Quitter </span>
                    <img
                      className="cancel-icon"
                      src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png"
                      alt="Annuler"
                    />
                  </button>
                  {!this.state.ajoutEnCours && (
                    <button
                      type="button"
                      className="btn btn-primary btn_for_assignation button addBtn"
                      onClick={() => this.ajoutBakeliste()}
                    >
                      <i className="fas fa-user-check"></i>&nbsp; Ajouter
                    </button>
                  )}
                  {this.state.ajoutEnCours && (
                    <button
                      type="button"
                      className="btn btn-warning button addBtn btn_for_wait"
                    >
                      Ajout en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

























