import React, { Component } from 'react';
import './AdminDashboardChart.css';
//import Menu from '../../menu/Menu';
import API from '../../../variablesGlobales';
import axios from 'axios';
//import { NavLink } from 'react-router-dom';
import '../../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import '../../../../node_modules/admin-lte/dist/js/adminlte.js';
import $ from 'jquery';
import './Chart.js';
// import './bakeliChart.js';
import Chart from './Chart.js'


export default class AdminDashboardChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      bakelistesCounter: [],
      prospectsCounter: [],
      periods: []
    };
  }

  componentDidMount() {
    this.getBakelistesCounter();
    //this.getChart();
  }


  getBakelistesCounter = () => {
    axios.get(this.state.api + 'bakelistes-counter')
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            bakelistesCounter: response.data.dataBakelistes,
            prospectsCounter: response.data.dataProspects,
            periods: response.data.dataMonths
          })
          window.sessionStorage.setItem('bakelistescount', response.data.dataBakelistes)
          window.sessionStorage.setItem('prospectscount', response.data.dataProspects)
          window.sessionStorage.setItem('months', response.data.dataMonths)
        }
       // console.log(response.data.dataBakelistes)
        let bakelisteCounter = [];
        let prospectCounter = [];
        response.data.dataBakelistes.forEach((item) => {
          bakelisteCounter.push(item)
        })

        response.data.dataProspects.forEach((item) => {
          prospectCounter.push(item)
        })

        $(function () {
          //'use strict';

          /* ChartJS
           * -------
           * Here we will create a few charts using ChartJS
           */

          // -----------------------
          // - MONTHLY SALES CHART -
          // -----------------------

          // Get context with jQuery - using jQuery's .get() method.
          var salesChartCanvas = $('#salesChart').get(0).getContext('2d');
          // This will get the first returned node in the jQuery collection.
          var salesChart = new Chart(salesChartCanvas);

          var salesChartData = {
            labels: response.data.dataMonths,
            datasets: [
              {
                label: 'Bakelistes',
                fillColor: '#ff9800',
                strokeColor: '#ff9800',
                pointColor: '#ff9800',
                pointStrokeColor: '#c1c7d1',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgb(220,220,220)',
                data: bakelisteCounter
              },
              {
                label: 'Prospects',
                fillColor: '#009688',
                strokeColor: '#009688',
                pointColor: '#009688',
                pointStrokeColor: 'rgba(60,141,188,1)',
                pointHighlightFill: '#fff',
                pointHighlightStroke: 'rgba(60,141,188,1)',
                data: prospectCounter
              }
            ]
          };

          var salesChartOptions = {
            // Boolean - If we should show the scale at all
            showScale: true,
            // Boolean - Whether grid lines are shown across the chart
            scaleShowGridLines: false,
            // String - Colour of the grid lines
            scaleGridLineColor: 'rgba(0,0,0,.05)',
            // Number - Width of the grid lines
            scaleGridLineWidth: 1,
            // Boolean - Whether to show horizontal lines (except X axis)
            scaleShowHorizontalLines: true,
            // Boolean - Whether to show vertical lines (except Y axis)
            scaleShowVerticalLines: true,
            // Boolean - Whether the line is curved between points
            bezierCurve: true,
            // Number - Tension of the bezier curve between points
            bezierCurveTension: 0.3,
            // Boolean - Whether to show a dot for each point
            pointDot: true,
            // Number - Radius of each point dot in pixels
            pointDotRadius: 2,
            // Number - Pixel width of point dot stroke
            pointDotStrokeWidth: 1,
            // Number - amount extra to add to the radius to cater for hit detection outside the drawn point
            pointHitDetectionRadius: 20,
            // Boolean - Whether to show a stroke for datasets
            datasetStroke: true,
            // Number - Pixel width of dataset stroke
            datasetStrokeWidth: 2,
            // Boolean - Whether to fill the dataset with a color
            datasetFill: true,
            // String - A legend template
            legendTemplate: '<ul class=\'<%=name.toLowerCase()%>-legend\'><% for (var i=0; i<datasets.length; i++){%><li><span style=\'background-color:<%=datasets[i].fillColor%>\'></span><%=datasets[i].label%></li><%}%></ul>',
            // Boolean - whether to maintain the starting aspect ratio or not when responsive, if set to false, will take up entire container
            maintainAspectRatio: true,
            // Boolean - whether to make the chart responsive to window resizing
            responsive: true
          };

          // Create the line chart
          salesChart.Line(salesChartData, salesChartOptions);
        })
      })


  }

  render() {
    return (
      <div className="component-admin-dashboard-chart">
        <div className="row">
          <div className="col-md-12">
            <div className="box">
              <div className="box-header with-border">
                <h3 className="box-title">Reporting mensuel des inscriptions (Bakelistes et Prospects)</h3>

                <div className="box-tools pull-right">
                  <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                  </button>
                  {/* <div className="btn-group">
                    <button type="button" className="btn btn-box-tool dropdown-toggle" data-toggle="dropdown">
                      <i className="fa fa-wrench"></i></button>
                    <ul className="dropdown-menu" role="menu">
                      <li><a href="#">Action</a></li>
                      <li><a href="#">Another action</a></li>
                      <li><a href="#">Something else here</a></li>
                      <li className="divider"></li>
                      <li><a href="#">Separated link</a></li>
                    </ul>
                  </div> */}
                  <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
                </div>
              </div>

              <div className="box-body">
                <div className="row">
                  <div className="col-md-12 chart-container">
                    {/* <p className="text-center">
                      <strong>Sales: 1 Jan, 2014 - 30 Jul, 2014</strong>
                    </p> */}

                    <div className="chart">

                      <canvas id="salesChart"></canvas>
                    </div>

                  </div>
                  <div className="col-md-6 col-md-offset-3 chart-legend-container">
                    <span className="bakeliste-legend"></span> Bakelistes
                    &nbsp;&nbsp;
                    <span className="prospect-legend"></span> Prospects
                  </div>

                  {/* <div className="col-md-4">
                  <p className="text-center">
                    <strong>Goal Completion</strong>
                  </p>

                  <div className="progress-group">
                    <span className="progress-text">Add Products to Cart</span>
                    <span className="progress-number"><b>160</b>/200</span>

                    <div className="progress sm">
                      <div className="progress-bar progress-bar-aqua"></div>
                    </div>
                  </div>
                  
                  <div className="progress-group">
                    <span className="progress-text">Complete Purchase</span>
                    <span className="progress-number"><b>310</b>/400</span>

                    <div className="progress sm">
                      <div className="progress-bar progress-bar-red"></div>
                    </div>
                  </div>
                  
                  <div className="progress-group">
                    <span className="progress-text">Visit Premium Page</span>
                    <span className="progress-number"><b>480</b>/800</span>

                    <div className="progress sm">
                      <div className="progress-bar progress-bar-green"></div>
                    </div>
                  </div>
                  
                  <div className="progress-group">
                    <span className="progress-text">Send Inquiries</span>
                    <span className="progress-number"><b>250</b>/500</span>

                    <div className="progress sm">
                      <div className="progress-bar progress-bar-yellow"></div>
                    </div>
                  </div>
                  
                </div> */}

                </div>

              </div>

              {/* <div className="box-footer">
              <div className="row">
                <div className="col-sm-3 col-xs-6">
                  <div className="description-block border-right">
                    <span className="description-percentage text-green"><i className="fa fa-caret-up"></i> 17%</span>
                    <h5 className="description-header">$35,210.43</h5>
                    <span className="description-text">TOTAL REVENUE</span>
                  </div>
                  
                </div>
                
                <div className="col-sm-3 col-xs-6">
                  <div className="description-block border-right">
                    <span className="description-percentage text-yellow"><i className="fa fa-caret-left"></i> 0%</span>
                    <h5 className="description-header">$10,390.90</h5>
                    <span className="description-text">TOTAL COST</span>
                  </div>
                  
                </div>
                
                <div className="col-sm-3 col-xs-6">
                  <div className="description-block border-right">
                    <span className="description-percentage text-green"><i className="fa fa-caret-up"></i> 20%</span>
                    <h5 className="description-header">$24,813.53</h5>
                    <span className="description-text">TOTAL PROFIT</span>
                  </div>
                  
                </div>
                
                <div className="col-sm-3 col-xs-6">
                  <div className="description-block">
                    <span className="description-percentage text-red"><i className="fa fa-caret-down"></i> 18%</span>
                    <h5 className="description-header">1200</h5>
                    <span className="description-text">GOAL COMPLETIONS</span>
                  </div>
                  
                </div>
              </div>
              
            </div> */}

            </div>

          </div>
        </div>
      </div>
    );
  }
}