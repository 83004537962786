import React, {Component} from 'react';
import { Link, NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from '../menu/Menu';
import * as Sentry from '@sentry/react';
import $ from 'jquery';
import "../MenuBakeliste/Production.css";
 import amplitude from "amplitude-js";
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../demandeAbsence/demande.css';
import {
  Amplitude
} from "@amplitude/react-amplitude";
import { logToConsole } from 'pusher-js';
export default class Demande extends Component{
    data = [];
    static defaultProps = {
        required: true
    }
   
    constructor(props){
        super(props);
       this.handleChange = this.handleChange.bind(this);
       this.handleChange1 = this.handleChange1.bind(this);

        this.onSubmitDemande = this.onSubmitDemande.bind(this);

        this.state = {
            isPageLoading:false,
            isSendingDemandeInProgress: false,
            isSuccessSending: false,
            isErrorSending: false,
            api: API.API,
            userID: window.sessionStorage.getItem('user_id'),
            fields: {},
            utInProgress: false,
            demande: [],
            alldemande:[],
        };
        

    }




    componentDidMount() {
        this.getDemande();
    }

    demandeStateFormatter(cell, row) {
        return (
            <span>
                {row.status === 'rejete' &&
                    <span className="label label-danger" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" >{row.status}</span>
                }
                {row.status === 'nouveau' &&
                    <span className="label label-warning" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal"  >{row.status}</span>
                }
                {row.status === 'accepte' &&
                    <span className="label label-success" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" >{row.status}</span>
                }
            </span>
        );
    }




    handleChange(e) {
        let fields = this.state.fields;
        fields["bakeliste_id"] = this.state.userID;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
       // console.log(fields)
    }
    
    handleChange1 = (e) => {
       /*  let fields = this.state.fields
      
        if(e.target.name === 'motif'){
            
            if(this.fileValidation('motif')){
                fields['tache_file'] = e.target.files[0];
            }else{
                this.setState({
                    isInvalidFileExtension: true
                })
                setTimeout(() => {
                    this.setState({
                        isInvalidFileExtension: false
                    })
                }, 6000);
            }
        }else{
            fields[e.target.name] = e.target.value
        }
      
        this.setState({
            fields: fields
        })
        console.log(fields) */
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        fields["bakeliste_id"] = this.state.userID;
        if (e.target.name === "motif") {
        var chars = e.target.value.length;
        var remaining_char = 245 - chars;
        this.setState({
            remaining_char: remaining_char,
        });
        }
        this.setState({
        fields: fields,
        });

       // console.log(fields);
      }


onSubmitDemande = (e) => {
    e.preventDefault()

    //if(this.onValidateDemandesFormData()){
        this.setState({
            isSendingDemandeInProgress: true
        })

        var api = this.state.api
        var data = this.state.fields

        var fd = new FormData()
        fd.append('motif', data.motif)
        console.log("motif:", data.motif);
        fd.append('contenu', data.contenu)
        fd.append('date_debut', data.date_debut)
        fd.append('date_fin', data.date_fin)
        fd.append('bakeliste_id', this.state.userID)
        //console.log("data", data);
        //console.log("fd", fd);
        axios.post(api + 'demande_absences', fd, {Headers:{'Content-Type':'multipart/formdata'}})
            .then(response => {
               // console.log(response);
                this.setState({
                    isSendingDemandeInProgress: false,
                })
                document.getElementById("submit-demande").reset()

                if(response.data.success){
                    this.getDemande();
                    $('#motif').val('')
                    $('#contenu').val('')
                    $('#date_debut').val('')
                    $('#date_fin').val('')
                    this.setState({
                        isSuccessSending: true
                       
                    })
            toast.success("Demande envoyé avec succès !")

                    setTimeout(() => {
                        this.setState({
                            isSuccessSending: false
                        })
                    }, 6000);
                }else{
                    toast.error("Oupsss! Une erreur est survenue lors de l\'envoi")

                    this.setState({
                        isErrorSending: true
                    })
                    setTimeout(() => {
                        this.setState({
                            isErrorSending: false
                        })
                    }, 6000);
                }
                $('.close').click();
            })
            .catch(error => {
                console.log(error.message)
                if(error.message = 'Request failed with status code 422'){
                    toast.error("Oupsss! Une erreur zzzzzzzz est survenue lors de l\'envoi")
                }
                this.setState({
                    isSendingDemandeInProgress: false,
                    isErrorSending: true
                })
                setTimeout(() => {
                    this.setState({
                        isErrorSending: false
                    })
                }, 6000);
            })
    //}
}


 onValidateDemandesFormData(){
    let fields = this.state.fields
    let errors = this.state.errors
    let isValidForm = false
   
    if(!fields['motif']){
        isValidForm = false
        this.setState({
            is_empty_motif: true
        })
        setTimeout(() => {
            this.setState({
                is_empty_motif: false
            })
        }, 5000);

        errors['motif'] = 'Ce champ est obligatoire'
    }

    this.setState({
        errors: errors
    })

    return isValidForm;
}
 


  
   getDemande = () =>{
    axios
      .get(this.state.api + "demande_absences/bakeliste/" + this.state.userID)
      .then((res) => {
        const demandes = res.data;
        this.setState({
          demande: demandes.data,
        });
        //console.log(this.state.demande);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }
  
    render(){
        const {required} = this.props;
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton,
            btnGroup: this.createCustomButtonGroup,
      
          };
        if (this.state.isPageLoading) {
            return (
              <div className="component-manage-users">
                <Menu />
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
                <div className="row no-content-wrapper">
      
                  <section className="content-header">
                    <h1>
                    Demande d'absence
                    </h1>
                    <ol className="breadcrumb">
                      <li><Link to="/dashboard-bakeliste">Accueil</Link></li>
                      <li className="active">Demander absence</li>
                    </ol>
                  </section>
      
                  <section className="content">
                    <div className="list_users_container">
                      <div id="loader"></div>
                    </div>
                  </section>
      
                </div>
              </div>
            );
          }
          return(
            <div className="component-manage-users">
                <Menu />
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
        
        
                <div className="row no-content-wrapper">
                    <section className="content-header" >
                        <h1>
                        Demande d'absence
                        </h1>
                        <ol className="breadcrumb">
                        <li><Link to="/dashboard-bakeliste">Accueil</Link></li>
                        <li className="active">Demander absence</li>
                        </ol>
                    </section>
                    <div>

                        <button type="button" className="btn btn-success" style={{  backgroundColor: "#009688",marginLeft: "32%",marginTop: "2%",}}  data-toggle="modal" data-target="#demandeabsence">Faire une nouelle demande d'absence</button>
                    </div>
                    <div className="row prospectw-table-row">
                        <div className="bakeli-ete-table-col">
                            <BootstrapTable data={this.state.demande}
                            striped={true} hover={true} condensed={true}
                            multiColumnSort={2} options={options}
                            pagination exportCSV>
                                <TableHeaderColumn dataField='id' isKey={true} hidden={true}> 
                                    ID
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField='motif' filter={{ type: 'TextFilter', delay: 1000 }} width='100' dataSort={true} >
                                     Motif
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField='date_debut' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true} width='230' >
                                    Date de début
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField='date_fin' dataSort={true} width='200' filter={{ type: 'TextFilter', delay: 1000 }} >
                                    Date de fin
                                </TableHeaderColumn> 
                                <TableHeaderColumn dataField='status' dataFormat={this.demandeStateFormatter.bind(this)} dataSort={true} width='90' filter={{ type: 'TextFilter', delay: 1000 }} >
                                   Status
                                </TableHeaderColumn> 
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
                {/* Modal de demandde d'absence */}   
                <div className="modal fade" id="demandeabsence" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="envoyerLivrableLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="editTacheModalLabel">Demande d'absence</h4>
                            </div>
                            <form id="submit-demande">
                                <div className="modal-body">
                                    <div className="form-group m-b-45">
                                        <label>Motif de votre absence</label>
                                        <input id="motifd" required  onChange={ this.handleChange1 }  name="motif" className="form-control form-custom-input" aria-describedby="emailHelp" type="text" placeholder="Motif de l'absence"></input>
                                    </div>
                                    <div className="form-group m-b-45">
                                        <label>Contenu de la demande</label>
                                        <textarea id="contenud" required  onChange={ this.handleChange1 }  name="contenu" className="form-control form-custom-input" aria-describedby="emailHelp" placeholder=""></textarea>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="form-group m-b-45">
                                            <label>Date de début</label>
                                            <input id="date_debutd" type="date" required  onChange={ this.handleChange1 }  name="date_debut" className="form-control form-custom-input" aria-describedby="emailHelp" ></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group m-b-45">
                                            <label>Date de fin</label>
                                            <input id="date_find"  type="date" required  onChange={ this.handleChange1 }  name="date_fin" className="form-control form-custom-input" aria-describedby="emailHelp" ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger pull-left bsi-cancel-btn" data-dismiss="modal">
                                        <i className="fas fa-times-circle"></i> &nbsp;
                                        Quitter
                                    </button>
                                    {this.state.utInProgress === false ?
                                        <button type="submit" className="btn btn-primary bsi-submit-btn pull-right" onClick={(e) => this.onSubmitDemande(e)} > 
                                            <i className="fas fa-paper-plane"></i> &nbsp;
                                        Envoyer</button> :
                                        <button className="btn-form in_progress">
                                            Envoi en cours &nbsp;
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                    {this.state.isSuccessSending &&
                        <ToastContainer />
                        
                    }
                    {this.state.isErrorSending &&
                        <ToastContainer />
                    }
                </div>

            </div>

            
        )
    }
}