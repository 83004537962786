import React, { Component } from 'react';
import './coachHomeDashboard.css';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import API from '../../variablesGlobales';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';


class App extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');

        if (!token) {
            window.history.back()
        }
        if (userStatus === 'bakeliste') {
            window.history.back();
        }

        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getSelectedBakelist = this.getSelectedBakelist.bind(this);
        this.getAllbakeliste = this.getAllbakeliste.bind(this);
        this.handleAddCoachComment = this.handleAddCoachComment.bind(this);
        this.getBakelisteId = this.getBakelisteId.bind(this);
        this.handleChangeRecruitable = this.handleChangeRecruitable.bind(this);
        this.onRecruitableBakeliste = this.onRecruitableBakeliste.bind(this);
        this.handleCompletedFormation = this.handleCompletedFormation.bind(this);
        this.handleChangeDA = this.handleChangeDA.bind(this);
        this.submitDelayAbsenceDeclaration = this.submitDelayAbsenceDeclaration.bind(this);
        // this.getBakelisteByCoach = this.getBakelisteByCoach.bind(this)
        this.state = {
            token: '',
            api: API.API,
            nbreBakeliste: 0,
            tenLastBakelistes: [],
            domaines: [],
            dataBakeliste: {},
            bakelistes: [],
            bakelisteByCoach: [],
            coach_id: window.sessionStorage.getItem('user_id'),
            name_coach: window.sessionStorage.getItem('userFullName'),
            assignationEncours: false,
            addCommentInProgress: false,
            isRequestError: false,
            fields: {},
            bakeliste_id: 0,
            isBakeliste: true,
            isGetCommentsRequestError: false,
            CoachComments: [],
            showBloc: false,
            coachs: [],
            recFields: {},
            isRecruitableRequestError: false,
            recruitableEncours: false,
            successRecruitableAlert: false,
            recruitableNicknameValue: 'Talent_',
            successCompletedFormation: false,
            completedFormationRequestError: false,
            completedInProgress: false,
            infBakeliste: [],
            isDelay: false,
            isAbsence: false,
            delayDeclarationInProgress: false,
            absenceDeclarationInProgress: false,
            successDelayDeclarationAlert: false,
            errorDeclarationAlert: false,
            successAbsenceDeclarationAlert: false,
            daFields: {},
            daErrors: {},
            userEmptyFeedbakTab:{
                user_id: "",
                feedback_type: "",
                feedback_content: "",
                feedback_priority: "",
                feedback_file:[]
                },
                feedback_id: 0,
                      typeValue: '',
                      contentValue: '',
                      prioriteValue: '',
                      file_pathValue:[0],
                      EmptyType: false,
                      EmptyCommentaire: false,
                      EmptyPriorite: false,
                      errors: {},
                      addFeedbackSuccessAlert: false,
                      addFeedbackProgress: false,
                      addFeedbackError: false,
                      feedbackEnCours:false,
                      isFeedbackAddSuccessAlert:false,
                      image: '',

        }
        this.handleFeedback = this.handleFeedback.bind(this);
        
    }

    componentDidMount() {
        this.getAllbakeliste();
        this.getAllDomaines();
        this.getNombreBakeliste();
        this.getAllActivesCoachs();
    }

    componentDidCatch(err, info){
        Sentry.captureException(err);
    }

    getAllbakeliste() {
        axios.get(this.state.api + 'bakelistes-by-coach/' + this.state.coach_id)
            .then(res => {
                if (res.data.count !== 0) {
                    const bakelistes = res.data.data;
                    console.log(bakelistes);
                    if (bakelistes !== undefined) {
                        this.setState({
                            bakelistes: bakelistes.reverse(),
                            loading: false
                        });
                    } else {
                        this.setState({
                            nbreBakeliste: 0,
                            loading: false
                        })
                    }

                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getNombreBakeliste() {
        axios.get(this.state.api + 'bakelistes-by-coach/' + this.state.coach_id)
            .then((response) => {
                if (response.data.data !== undefined) {
                    const nbreBakeliste = response.data.count;
                    console.log('nombre bakeliste', response.data.count);
                    this.setState({
                        nbreBakeliste: nbreBakeliste
                    });
                } else {
                    this.setState({
                        nbreBakeliste: 0
                    })
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }


    getAllDomaines() {
        axios.get(this.state.api + 'domaines')
            .then(response => {
                console.log('domaines ', response.data)
                const domaines = response.data;
                this.setState({
                    domaines: domaines.data,
                });
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getAllActivesCoachs = () => {
        axios.get(this.state.api + 'liste-des-coachs')
            .then(res => {
                const coachsData = res.data;
                const listActiveCoach = [];
                coachsData.data.forEach((item) => {
                    if(item.coach_is_actif === 1){
                        listActiveCoach.push(item)
                    }
                })
                this.setState({
                    coachs: listActiveCoach,
                });
                //console.log("coach list: ", this.state.coachs)
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        fields["bakeliste_id"] = window.sessionStorage.getItem('commentBakelisteId');
        fields["coach_id"] = this.state.coach_id;
        this.setState({
            fields: fields
        });
        console.log(fields);
    }

    getSelectedBakelist = (item) => {
        this.getAllActivesCoachs();
        this.setState({
            selectedBakelist: item
        });
        window.sessionStorage.setItem('assignBakelisteId', item.id);
        console.log(this.state.selectedBakelist)

    }

    getBakelisteId = (bakeliste) => {
        this.setState({
            CoachComments: []
        });
        console.log(bakeliste.id)
        window.sessionStorage.setItem('commentBakelisteId', bakeliste.id);
        window.sessionStorage.setItem('recruitableBakelisteId', bakeliste.id);
    }

    handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'successAssignation') {
                this.setState({
                    successAssignationAlert: false
                });
            }
            if (label === 'errorAssignation') {
                this.setState({
                    isCoachAssignationRequestError: false
                });
            }
            if (label === 'successRecruitable') {
                this.setState({
                    successRecruitableAlert: false
                });
            }
            if (label === 'errorRecruitable') {
                this.setState({
                    isRecruitableRequestError: false
                });
            }
            if (label === 'successCompletedFormation') {
                this.setState({
                    successCompletedFormationAlert: false
                })
            }
            if (label === 'errorCompletedFormation') {
                this.setState({
                    completedFormationRequestError: false
                })
            }
            if (label === 'successAbsenceDeclarationAlert') {
                this.setState({
                    successAbsenceDeclarationAlert: false
                })
            }
            if (label === 'successDelayDeclarationAlert') {
                this.setState({
                    successDelayDeclarationAlert: false
                })
            }
            if (label === 'errorDeclarationAlert') {
                this.setState({
                    errorDeclarationAlert: false
                })
            }
        }, 5000);
    }

    assignCoach() {
        this.setState({
            assignationEncours: true
        });
        console.log(this.state.selectedBakelist.id)
        const id_bakelist = window.sessionStorage.getItem('assignBakelisteId');

        const dataForAssign = {
            bakeliste_id: id_bakelist,
            coach_id: this.state.fields.coachSelect,
            requestLabel: 'réassignation'
        }
        axios.post(this.state.api + 'assigner-un-coach', dataForAssign)
            .then(res => {
                console.log(res.data);
                document.getElementById("assignForm").reset();
                if (res.data.success) {
                    this.getAllbakeliste();
                    this.getNombreBakeliste();
                    $('.close').click();
                    this.setState({
                        assignationEncours: false,
                        successAssignationAlert: true
                    });
                    this.handleShowAndHideAlert('successAssignation')
                } else {
                    console.log(res)
                    this.setState({
                        isCoachAssignationRequestError: true,
                        assignationEncours: false
                    })
                    this.handleShowAndHideAlert('errorAssignation')
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    isCoachAssignationRequestError: true,
                    assignationEncours: false
                })
                this.handleShowAndHideAlert('errorAssignation')
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })

    }

    handleAddCoachComment = (e) => {
        e.preventDefault();
        this.setState({
            addCommentInProgress: true
        });

        let addData = this.state.fields;
        axios.post(this.state.api + 'commentaires', addData)
            .then(response => {
                this.setState({
                    addCommentInProgress: false
                });
                console.log(response.data);
                if (response.data.success === true) {
                    this.setState({
                        fields: {}
                    })
                    window.location = 'dashboard-coach';
                } else {
                    console.log('error');
                }
            })
            .catch(error => {
                this.setState({
                    addCommentInProgress: false,
                    isRequestError: true
                });
                console.log(error)
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    showComment = () => {
        if (this.state.showBloc) {
            this.setState({
                showBloc: false
            });
        } else {
            this.setState({
                showBloc: true
            });
        }
        this.getCoachCommentToBakeliste();
    }

    getCoachCommentToBakeliste = () => {

        let bakelisteID = window.sessionStorage.getItem('commentBakelisteId');

        axios.get(this.state.api + 'coach-comments-by-bakeliste/' + bakelisteID)
            .then(response => {
                if (response.data.success === true) {
                    console.log('success')
                    if (response.data.data !== undefined) {
                        this.setState({
                            CoachComments: response.data.data
                        });
                    }
                } else {
                    console.log('empty')
                    console.log('Commentaire non trouvé')
                }
            })
            .catch(error => {
                this.setState({
                    isGetCommentsRequestError: true
                });
                console.log(error)
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    Detail = (item) => {
        //console.log("detail baliste: ", item);
        this.setState({
            selectedBakelist: item,
            dataBakeliste: item
        })

    }

    getBakelisteInfos = (bakeliste) => {
        this.setState({
            infBakeliste: bakeliste
        })
    }

    actionsFormatter = (cell, row) => {
        return (
            <div className="actions-btn-container">
                {/* <button data-toggle="tooltip" data-placement="top" title="Modifier" type="button" className="btn btn-warning" id="btn_update" data-toggle="modal" data-target="#">
                    <i className="fas fa-edit"></i>
                </button> */}
                <button onClick={() => this.getBakelisteInfos(row)} type="button" className="btn btn-danger" id="btn_update" data-toggle="modal" data-target="#delayAbsenceModal">
                    <i className="fas fa-hourglass-half"></i>
                </button>
                <NavLink to={'/show-bakeliste-textual-contents/' + row.id + '/coach-view'} type="button" className="btn btn-info showtc" id="btn_show_tc">
                    <i className="fas fa-file-alt" title='contenu textual'></i>
                    <span className="tc-counter"> {row.textualContentCount} </span>
                </NavLink>
                <button onClick={() => this.getBakelisteId(row)} type="button" className="btn btn-success volkeno-green-bcolor pseudo-comment" id="btn_update" data-toggle="modal" data-target="#CommentModal">
                    <i className="fas fa-comment" title='commentaire1111'></i>
                    <span className="comment-counter"> {row.commentCount} </span>
                </button>
                <button type="button" className="btn btn-info reassigner" id="btn_assign" data-toggle="modal" data-target="#myModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
                    <i className="fas fa-user-check"></i>
                </button>
                {row.is_recruitable === 0 ?
                    <button onClick={() => this.getBakelisteId(row)} id="btn_recruitable" data-toggle="modal" data-target="#recruitableModal">
                        <i className="fas fa-thumbs-up" title='déclare le bakeliste recruitable'></i>
                    </button> :
                    <NavLink to={'/show-bakeliste-cv/' + row.id + '/coach-view'} type="button" className="btn btn-info showcv" id="btn_show_cv">
                        <i className="fas fa-list"></i>
                    </NavLink>
                }
                <button type="button" className="btn btn-danger terminer-formation" id="completed" title='términer la formation'
                    onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter la formation de ce bakeliste ?") &&
                        this.handleCompletedFormation(row)}
                >
                    {this.state.completedInProgress ?
                        <i className="fas fa-spin fa-spinner"></i> :
                        <i className="fas fa-stop-circle"></i>
                    }

                    

                </button>
            </div>
        );
    }

    bakelisteMoreInfosAction = (cell, row) => {
        return (
            <span data-toggle="modal" className="fullname-link" data-target="#moModal" onClick={(e) => this.Detail(row, e)} >{cell}</span>
        );
    }

    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    /**
     * Handle change recruitable bakeliste declaration by coach
     */
    handleChangeRecruitable = (e) => {
        let fields = this.state.recFields;
        fields[e.target.name] = e.target.value;
        fields["label"] = 'recruitable';

        if (e.target.name === 'bakeliste_nickname') {
            this.setState({
                recruitableNicknameValue: e.target.value
            })
        }

        if (!fields["bakeliste_nickname"]) {
            fields["bakeliste_nickname"] = this.state.recruitableNicknameValue
        }

        this.setState({
            recFields: fields
        })

        console.log(fields)
    }

    /**
     * Handle submit recruitable bakeliste declaration by coach
     */
    onRecruitableBakeliste = (e) => {
        e.preventDefault();
        this.setState({
            recruitableEncours: true
        })

        console.log(this.state.recFields)

        let recruitable = this.state.recFields;
        let bakelisteID = window.sessionStorage.getItem('recruitableBakelisteId');

        axios.post(this.state.api + 'recruitable-bakeliste/' + bakelisteID, recruitable)
            .then(response => {
                if (response.data.success === true) {
                    this.getAllbakeliste();
                    $('.close').click();

                    this.setState({
                        recruitableEncours: false,
                        successRecruitableAlert: true,
                        recruitableNicknameValue: 'Talent_'
                    })

                    this.handleShowAndHideAlert('successRecruitable')
                } else {
                    console.log(response)
                    this.setState({
                        recruitableEncours: false,
                        isRecruitableRequestError: true
                    })
                    this.handleShowAndHideAlert('errorRecruitable')
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    recruitableEncours: false,
                    isRecruitableRequestError: true
                })
                this.handleShowAndHideAlert('errorRecruitable')
            
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    /**
     * Handle stop bakeliste training
     */
    handleCompletedFormation = (bakeliste) => {
        this.setState({
            completedInProgress: true
        });

        let bakelisteID = bakeliste.id;
        let data = {};

        axios.post(this.state.api + 'completed-bakelistes-formation/' + bakelisteID, data)
            .then(response => {
                this.setState({
                    completedInProgress: false
                });

                if (response.data.success === true) {
                    console.log('okay')
                    this.getAllbakeliste();
                    this.getNombreBakeliste();
                    this.setState({
                        successCompletedFormationAlert: true
                    })
                    this.handleShowAndHideAlert('successCompletedFormation')
                } else {
                    console.log(response);
                    this.setState({
                        completedFormationRequestError: true,
                    })
                    this.handleShowAndHideAlert('errorCompletedFormation')
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    completedFormationRequestError: true,
                    completedInProgress: false
                })
                this.handleShowAndHideAlert('errorCompletedFormation')
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    typeFormationFormatter = (cell, row) => {
        if (cell === 'courte') {
            return (
                <span className="label label-success volkeno-green-bcolor"> formation courte </span>
            );
        } else if (cell === 'diplomante') {
            return (
                <span className="label label-warning volkeno-orange-bcolor"> formation diplômante </span>
            );
        }
    }

    showDelayForm = () => {
        document.getElementById('declaration-da-form').reset()
        this.setState({
            isDelay: true,
            isAbsence: false
        })
    }

    showAbsenceForm = () => {
        document.getElementById('declaration-da-form').reset()
        this.setState({
            isDelay: false,
            isAbsence: true
        })
    }

    returnToDeclarationChoice = () => {
        document.getElementById('declaration-da-form').reset()
        this.setState({
            isDelay: false,
            isAbsence: false
        })
    }

    /**
     * Handle declaration delay or absence form
     */
    handleChangeDA = (e) => {
        let fields = this.state.daFields;
        if (this.state.isDelay) {
            fields["is_delay"] = 1;
            fields["is_absence"] = 0;
        }
        if (this.state.isAbsence) {
            fields["is_delay"] = 0;
            fields["is_absence"] = 1;
        }

        fields["bakeliste_id"] = this.state.infBakeliste.id;
        fields["coach_id"] = parseInt(this.state.coach_id);
        fields[e.target.name] = e.target.value;

        this.setState({
            daFields: fields
        })
        console.log(fields)
    }

    /**
     * Submit delay or absence declaration form data
     */
    submitDelayAbsenceDeclaration = (e) => {
        e.preventDefault();
        if (this.state.isAbsence) {
            this.setState({
                absenceDeclarationInProgress: true
            })
        } else if (this.state.isDelay) {
            this.setState({
                delayDeclarationInProgress: true
            })
        }

        let data = this.state.daFields;

        axios.post(this.state.api + 'delays-absences', data)
            .then(response => {
                if (response.data.success) {
                    this.getAllbakeliste();
                    $('.close').click();
                    document.getElementById('declaration-da-form').reset()
                    if (this.state.isAbsence) {
                        this.setState({
                            absenceDeclarationInProgress: false,
                            successAbsenceDeclarationAlert: true
                        })
                        this.handleShowAndHideAlert('successAbsenceDeclarationAlert')
                    } else if (this.state.isDelay) {
                        this.setState({
                            delayDeclarationInProgress: false,
                            successDelayDeclarationAlert: true
                        })
                        this.handleShowAndHideAlert('successDelayDeclarationAlert')
                    }
                } else {
                    console.log(response)
                    this.setState({
                        errorDeclarationAlert: true,
                        absenceDeclarationInProgress: false,
                        delayDeclarationInProgress: false
                    })
                    this.handleShowAndHideAlert('errorDeclarationAlert')
                }
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    errorDeclarationAlert: true,
                    absenceDeclarationInProgress: false,
                    delayDeclarationInProgress: false
                })
                this.handleShowAndHideAlert('errorDeclarationAlert')
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    rowClassNameFormat = (row, rowIdx) => {
        return row.trainingIsCompleted === 1 ? 'is_completed_training' : '';
    }

    createCustomButtonGroup = props => {
        return (
          <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
            { props.exportCSVBtn }
            {/* <button type='button'
              className={ `btn btn-primary volkeno-red-bcolor` }>
              MyCustomBtn
            </button> */}
          </ButtonGroup>
        );
    }
    // feedback
    handleFeedback = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        console.log("handle change", e.target.value)
        fields["user_id"] = window.sessionStorage.getItem('user_id')
        
        if(e.target.name === 'feedback_file'){
          fields["feedback_file"] = e.target.files[0]
        }
        if(e.target.name === 'feedback_content'){
          fields[e.target.name]= e.target.value;
        }
        if(e.target.name === 'feedback_priority'){
          fields[e.target.name] = e.target.value;
        }
        this.setState({
            fields
        });
        console.log("handle change", this.state.fields)
    }
    
        onValidateFeedbackForm = () => {
          let fields = this.state.fields;
          let errors = {};
          let isValidForm = true;
          if (!fields["feedback_content"]) {
            isValidForm = false;
            this.setState({
                EmptyCommentaire: true
            })
            this.handleShowAndHideAlert('EmptyCommentaire');
            errors["EmptyCommentaire"] = "*Ce champ est obligatoire";
        }
        if (!fields["feedback_file"]) {
          isValidForm = false;
          this.setState({
              EmptyFile: true
          })
          
      }
          
          if (!fields["feedback_priority"]) {
              isValidForm = false;
              this.setState({
                  EmptyPriorite: true
              })
              this.handleShowAndHideAlert('EmptyPriorite');
              errors["EmptyPriorite"] = "*Ce champ est obligatoire";
          }
          if (!fields["feedback_type"] || fields["feedback_type"] === '--Choisir un type --') {
              if(fields["feedback_type"] === '--Choisir un type --'){
                  fields["feedback_type"] = ''
              }
              isValidForm = false;
              this.setState({
                  emptyStatus: true
              })
              this.handleShowAndHideAlert('EmptyType');
              errors["EmptyType"] = "*Ce champ est obligatoire";
          }
      
          this.setState({
              errors: errors
          })
      
          return isValidForm;
      }
      

      // eslint-disable-next-line no-dupe-class-members
      handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'addSuccessAlert') {
                this.setState({
                    addFeedbackSuccessAlert: false
                });
            }
            if (label === 'isFeedbackAddSuccessAlert') {
              this.setState({
                  isFeedbackAddSuccessAlert: false
              });
            }
            if(label === 'addErrorAlert'){
              this.setState({
                  addFeedbackError: false
              })
          }
         
            if(label === 'EmptyType'){
                this.setState({
                    EmptyType: false
                })
            }
            if(label === 'EmptyCommentaire'){
                this.setState({
                    EmptyCommentaire: false
                })
            }
            if(label === 'EmptyPriorite'){
                this.setState({
                    EmptyPriorite: false
                })
            }
           
           
        }, 1000);
      }
      addFeedback() {
        console.log("report add", this.state.fields);
        if(this.onValidateFeedbackForm()){
            this.setState({
                feedbackEncours: true
            })
            const data = this.state.fields;
           // console.log(dataBakeliste,"donne")
            console.log(this.state.fields.feedback_file,"fedb")
            const fd = new FormData();
           fd.append('user_id',data.user_id);
          fd.append('feedback_type',data.feedback_type);
          fd.append('feedback_priority',data.feedback_priority)
          fd.append('feedback_content',data.feedback_content);
          fd.append('feedback_file',data.feedback_file);
          //data.set('fields',this.state.fields);
            console.log(fd,"feed")
            axios.post(this.state.api + 'feedbacks',fd)
                .then(res => {
                    if (res.status === 200) {
                        $('.close').click();
                        this.handleShowAndHideAlert('addSuccessAlert');
                        $('#add-feedback-form')[0].reset();
                        this.setState({
                            addFeedbackSuccessAlert: true,
                           feedbackEnCours: false,
                           isFeedbackAddSuccessAlert:true,
                            fields: {}
                        });
                        this.handleShowAndHideAlert('isFeedbackAddSuccessAlert') ;
                    }
                })
                .catch(error => {
                    this.setState({
                       feedbackEnCours: false,
                        addFeedbackError: true
                    });
                    this.handleShowAndHideAlert('addFeedbackError');
                    console.log(error);
                    if (process.env.NODE_ENV !== 'production') {
                        return;
                    }
                   // Sentry.captureException(error);
                });
        }
        
      }
      
      
      
      
      
    
    render() {
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton,
            btnGroup: this.createCustomButtonGroup
        };

        return (
            <div className="dashboard-coach-container">
                <Menu />
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
                <div className="row no-content-wrapper">

                    <section className="content-header">
                        <h1>
                            Dashboard
                            <small>Version 2.0</small>
                        </h1>
                        <ol className="breadcrumb">
                            <li><NavLink to="dashboard-coach"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </section>

                    <section className="content">
                        <div className="row coach_dashoboard_count_row">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                <span className="info-box-icon bg-vgreen">
                                    <i className="fas fa-user-graduate" ></i>
                                </span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Bakelistes</span>
                                    <span className="info-box-number">
                                    {this.state.nbreBakeliste}
                                    {/* <small>%</small> */}
                                    </span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="row table_bakeliste_inscrit">
                            <div className="col-md-12 liste_container">
                                <div className="panel panel-warning">
                                    <div className="panel-heading">
                                        <h3 className="panel-title">  Liste des bakelistes coachés par {this.state.name_coach}</h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="coachBakelisteListe-container">
                                            <BootstrapTable data={this.state.bakelistes}
                                                striped={true} hover={true} condensed={true}
                                                multiColumnSort={2} options={options}
                                                trClassName={this.rowClassNameFormat}
                                                pagination
                                                exportCSV
                                                search={true}>
                                                <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                                <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                                                <TableHeaderColumn dataField='bakelisteFullname'
                                                    width="300"
                                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                                    dataSort={true}
                                                    dataFormat={this.bakelisteMoreInfosAction.bind(this)} >Nom complet</TableHeaderColumn>
                                                <TableHeaderColumn dataField='email' width="250" >Email</TableHeaderColumn>
                                                <TableHeaderColumn dataField='phone' width="200" >Téléphone</TableHeaderColumn>
                                                <TableHeaderColumn dataField='type_formation' width="200"
                                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                                <TableHeaderColumn dataField='horaire'
                                                    filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Horaire</TableHeaderColumn>
                                                <TableHeaderColumn dataField='joursPresence'
                                                    filter={{ type: 'TextFilter', delay: 1000 }} width="300" >Jours de présence</TableHeaderColumn>
                                                {/* <TableHeaderColumn dataField='frenchDateFormat'>Date et Heure</TableHeaderColumn> */}
                                                
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                </div>


                <div className="modal fade" id="moModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="myModalLabel">Detail sur {this.state.dataBakeliste.first_name}&nbsp;{this.state.dataBakeliste.last_name}</h4>
                            </div>
                            <div className="modal-body">
                                {this.state.dataBakeliste.type_formation === 'courte' ? (
                                    this.state.dataBakeliste.nbreJours === '3' ? (
                                        <div className="content_container">
                                            <b>Jours de présences : </b> <br />
                                            <span> {this.state.dataBakeliste.jour1}, {this.state.dataBakeliste.jour2}, {this.state.dataBakeliste.jour3} </span>

                                        </div>
                                    ) :
                                        this.state.dataBakeliste.nbreJours === '5' ? (
                                            <div className="content_container">
                                                <b>Jours de présences : </b> <br />
                                                <span> {this.state.dataBakeliste.jour1}, {this.state.dataBakeliste.jour2}, {this.state.dataBakeliste.jour3}, {this.state.dataBakeliste.jour4}, {this.state.dataBakeliste.jour5} </span>

                                            </div>
                                        ) :
                                            this.state.dataBakeliste.nbreJours === '6' && (
                                                <div className="content_container">
                                                    <b>Jours de présences : </b> <br />
                                                    <span> {this.state.dataBakeliste.jour1}, {this.state.dataBakeliste.jour2}, {this.state.dataBakeliste.jour3}, {this.state.dataBakeliste.jour4}, {this.state.dataBakeliste.jour5}, {this.state.dataBakeliste.jour6} </span>

                                                </div>
                                            )
                                ) :
                                    this.state.dataBakeliste.type_formation === 'diplomante' && (
                                        <div className="content_container">
                                            <b>Jours de présences : </b> <br />
                                            <span> Du Lundi au Vendredi </span>

                                        </div>
                                    )
                                }
                                <div className="email_container">
                                    <span className="item_label">Email : </span> <span className="label_content">{this.state.dataBakeliste.email}</span>
                                </div> <hr />
                                <div className="objectif_container">
                                    <span className="item_label">Objectifs : </span> <span className="label_content">{this.state.dataBakeliste.objectifs}</span>
                                </div> <hr />

                                {this.state.dataBakeliste.ecole !== '' &&
                                    <div className="ecole_container">
                                        <span className="item_label">Ecole : </span> <span className="label_content">{this.state.dataBakeliste.ecole}</span> <hr />
                                    </div>

                                }
                                <div className="email_container">
                                    <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.dataBakeliste.phone}</span>
                                </div> <hr />
                                {this.state.dataBakeliste.boursier === 1 &&
                                    <div className="ecole_container">
                                        <span className="item_label">Boursier : </span> <span className="label_content">NON</span> <hr />
                                    </div>

                                }
                                {this.state.dataBakeliste.boursier === 0 &&
                                    <div className="ecole_container">
                                        <span className="item_label">Boursier : </span> <span className="label_content">OUI</span> <hr />
                                    </div>

                                }
                                <div className="email_container">
                                    <span className="item_label">lieu_formation : </span> <span className="label_content">{this.state.dataBakeliste.lieu_formation}</span>
                                </div> <hr />
                                <div className="email_container">
                                    <span className="item_label">Type de formation : </span> <span className="label_content">{this.state.dataBakeliste.type_formation}</span>
                                </div> <hr />
                                {this.state.dataBakeliste.paiement_inscription === 1 &&
                                    <div className="ecole_container">
                                        <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">NON</span> <hr />
                                    </div>

                                }
                                {this.state.dataBakeliste.paiement_inscription === 0 &&
                                    <div className="ecole_container">
                                        <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">OUI</span> <hr />
                                    </div>

                                }
                                <div className="email_container">
                                    <span className="item_label">Date de début de la formation : </span> <span className="label_content">{this.state.dataBakeliste.debut_formation}</span>
                                </div> <hr />
                                <div className="email_container">
                                    <span className="item_label">Date de fin de la formation : </span> <span className="label_content">{this.state.dataBakeliste.fin_formation}</span>
                                </div> <hr />
                            </div>
                            <div className="modal-footer">

                            </div>
                        </div>
                    </div>
                </div>

                {/* Commentaires */}
                <div className="modal fade" id="CommentModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="myModalLabel">Commentaires</h4>
                            </div>
                            <div className="modal-body">
                                <div className="comments-container">
                                    <div className="toggle-btn-container">
                                        <button className="btn btn-primary toggle-btn" onClick={this.showComment}>
                                            Voir les commentaires
                                        </button>
                                    </div>
                                    {this.state.showBloc &&
                                        <div className="comment-items">
                                            {this.state.CoachComments.length !== 0 ?
                                                this.state.CoachComments.map((comment, index) =>

                                                    <div key={index} className="comment-item">
                                                        <b>Commentaire du {comment.frenchDateFormat}</b> <br />
                                                        <p> {comment.commentaire_content} </p>
                                                        <hr />
                                                    </div>
                                                )
                                                :
                                                <div className="alert alert-warning empty-comment-alert">
                                                    Aucun commentaire sur ce bakeliste.
                                                        </div>
                                            }
                                        </div>
                                    }

                                </div>
                                <hr />
                                <div className="form-group">
                                    <textarea placeholder="Ajouter un commentaire" name="commentaire_content" className="form-control" onChange={this.handleChange} />
                                </div>
                                {this.state.isRequestError &&
                                    <div className="alert alert-danger comment-alerty text-center">
                                        Un problème est survenu lors de l'ajout de commentaire. Veuillez réessayer plus tard.
                                        </div>
                                }
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                        <i className="fas fa-times-circle"></i> &nbsp;
                                        Quitter
                                        </button>
                                    {!this.state.addCommentInProgress ?
                                        <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.handleAddCoachComment(e)}>
                                            <i className="fas fa-check"></i>&nbsp;
                                            Ajouter
                                        </button>
                                        :
                                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                            Ajout en cours &nbsp;
                                                <i className="fas fa-spinner fa-spin"></i>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ré-assigner un nouveau coach */}
                <div className="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="myModalLabel">Selection du coach</h4>
                            </div>
                            <form id="assignForm">
                                <div className="modal-body">
                                    <div className="form-group " >
                                        <label >Coach:</label>
                                        <select className="form-control" id="inputbakeli_domaine_id" name="coachSelect" onChange={this.handleChange}>
                                            <option>--Choisir un coach--</option>
                                            {this.state.coachs.map((z, y) => <option value={z.id} key={y}>{z.first_name} {z.last_name} {'-'} {z.nbreBakeliste} {'bakeliste(s)'}</option>)}
                                        </select>
                                    </div>
                                    {this.isCoachAssignationRequestError &&
                                        <div className="row assignation-error">
                                            <div className="col-md-12 error-message-alert">
                                                Un problème est survenu lors de l'assignation. veuillez réessayer plus tard.
                                                        </div>
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                        <i className="fas fa-times-circle"></i> &nbsp;
                                        Quitter
                                                </button>
                                    {!this.state.assignationEncours &&
                                        <button type="button" id="ass" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.assignCoach(e)}>
                                            <i className="fas fa-user-check"></i>&nbsp;
                                            Assigner
                                                    </button>
                                    }
                                    {this.state.assignationEncours &&
                                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                            Assignation en cours &nbsp;
                                                      <i className="fas fa-spinner fa-spin"></i>
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Déclarer un bakeliste recrutable */}
                <div className="modal fade" id="recruitableModal" tabindex="-1" role="dialog" aria-labelledby="recruitableModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="recruitableModalLabel">Déclarer</h4>
                            </div>
                            <form id="recruitableForm">
                                <div className="modal-body">
                                    <div className="form-group " >
                                        <label >Choisissez un surnom :</label>
                                        <input type="text" name="bakeliste_nickname" className="form-control nickname" value={this.state.recruitableNicknameValue} placeholder="Choisissez un surnom" onChange={this.handleChangeRecruitable} />
                                    </div>
                                    {this.isRecruitableRequestError &&
                                        <div className="row assignation-error">
                                            <div className="col-md-12 error-message-alert">
                                                Un problème est survenu lors de la déclaration. Veuillez réessayer plus tard.
                                                </div>
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                        <i className="fas fa-times-circle"></i> &nbsp;
                                        Quitter
                                        </button>
                                    {!this.state.recruitableEncours &&
                                        <button onClick={(e) => this.onRecruitableBakeliste(e)} type="button" id="ass" className="btn btn-primary btn_for_assignation button addBtn">
                                            <i className="fas fa-plus"></i>&nbsp;
                                            Valider
                                            </button>
                                    }
                                    {this.state.recruitableEncours &&
                                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                            Déclaration en cours &nbsp;
                                                <i className="fas fa-spinner fa-spin"></i>
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* Delay or Absence declaration modal */}
                <div className="modal fade" id="delayAbsenceModal" tabindex="-1" role="dialog" aria-labelledby="delayAbsenceModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="delayAbsenceModalLabel">Déclaration Retard/Absence de {this.state.infBakeliste.bakelisteFullname}</h4>
                            </div>
                            <form id="declaration-da-form">
                                <div className="modal-body">
                                    {this.state.isDelay === false ?
                                        this.state.isAbsence === false ?
                                            <div className="row initial-choice-row">
                                                <div className="col-md-6 text-center delay-container">
                                                    <button onClick={this.showDelayForm} className="btn btn-warning volkeno-orange-bgcolor delay-btn">
                                                        Retard
                                                        </button>
                                                </div>
                                                <div onClick={this.showAbsenceForm} className="col-md-6 text-center absence-container">
                                                    <button className="btn btn-danger absence-btn">
                                                        Absence
                                                        </button>
                                                </div>
                                            </div> : ''
                                        : ''
                                    }

                                    {this.state.isDelay &&
                                        /* Delay declaration form */
                                        <div className="row delay_declaration_form_row">
                                            <div className="col-md-6 da_col">
                                                <div className="form-group da_input_container">
                                                    <label>Date</label>
                                                    <input type="date" name="delay_date" className="form-control da_input" onChange={this.handleChangeDA} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 da_col">
                                                <div className="form-group da_input_container">
                                                    <label>Heure d'arrivée</label>
                                                    <input type="time" name="arriving_time" className="form-control da_input" onChange={this.handleChangeDA} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 da_col">
                                                <div className="form-group da_input_container">
                                                    <label>Est ce que le retard est justifié ?</label>
                                                    <select className="form-control da_input" name="is_justified" onChange={this.handleChangeDA}>
                                                        <option> --Choisir un statut </option>
                                                        <option value={1}>OUI</option>
                                                        <option value={0}>NON</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 da_col">
                                                <div className="form-group da_input_container">
                                                    <label>Raisons du retard</label>
                                                    <textarea name="delay_absence_reasons" className="form-control da_input" onChange={this.handleChangeDA} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.state.isAbsence &&
                                        /* Absence declaration form */
                                        <div className="row delay_declaration_form_row">
                                            <div className="col-md-12 da_col">
                                                <div className="form-group da_input_container">
                                                    <label>Date</label>
                                                    <input type="date" name="absence_date" className="form-control da_input" onChange={this.handleChangeDA} />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6 da_col">
                                                <div className="form-group da_input_container">
                                                    <label>Heure d'arrivée</label>
                                                    <input type="time" name="arriving_time" className="form-control da_input" onChange={this.handleChangeDA} />
                                                </div>
                                            </div> */}
                                            <div className="col-md-12 da_col">
                                                <div className="form-group da_input_container">
                                                    <label>Est ce que l'absence est justifié ?</label>
                                                    <select className="form-control da_input" name="is_justified" onChange={this.handleChangeDA}>
                                                        <option> --Choisir un statut </option>
                                                        <option value={1}>OUI</option>
                                                        <option value={0}>NON</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 da_col">
                                                <div className="form-group da_input_container">
                                                    <label>Raisons de l'absence</label>
                                                    <textarea name="delay_absence_reasons" className="form-control da_input" onChange={this.handleChangeDA} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.state.errorDeclarationAlert &&
                                        <div className="row error-row">
                                            <div className="col-md-10 col-md-offset-1 dec_alerty_error text-center">
                                                <div className="alert alert-danger dec_alerty_content text-center">
                                                    Un problème est survenu lors de la déclaration. Veuillez réessayer plus tard.
                                               </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer">
                                    {this.state.isDelay === false ?
                                        this.state.isAbsence === false ?
                                            <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                Quitter
                                            </button> : ''
                                        : ''
                                    }
                                    {this.state.isDelay &&
                                        <div>
                                            <button onClick={this.returnToDeclarationChoice} type="button" className="btn btn-default cancel_btn cancel button">
                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                Retour en arriére
                                            </button>
                                            {!this.state.delayDeclarationInProgress &&
                                                <button type="button" id="ass" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.submitDelayAbsenceDeclaration(e)}>
                                                    <i className="fas fa-user-check"></i>&nbsp;
                                                    Valider
                                                    </button>
                                            }
                                            {this.state.delayDeclarationInProgress &&
                                                <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                    Validation en cours &nbsp;
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                </button>
                                            }
                                        </div>
                                    }

                                    {this.state.isAbsence &&
                                        <div>
                                            <button onClick={this.returnToDeclarationChoice} type="button" className="btn btn-default cancel_btn cancel button">
                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                Retour en arriére
                                                </button>
                                            {!this.state.absenceDeclarationInProgress &&
                                                <button type="button" id="ass" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.submitDelayAbsenceDeclaration(e)}>
                                                    <i className="fas fa-user-check"></i>&nbsp;
                                                    Valider
                                                    </button>
                                            }
                                            {this.state.absenceDeclarationInProgress &&
                                                <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                    Validation en cours &nbsp;
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                </button>
                                            }
                                        </div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
     {/* add feedback success toast */}
     {this.state.isFeedbackAddSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                Feedback ajouté avec succès.
            </div>
        }

                {/* Success delay declaration toast alert */}
                {this.state.successDelayDeclarationAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                        La déclaration de retard a été effectuée avec succès.
                    </div>
                }

                {/* Success absence declaration toast alert */}
                {this.state.successAbsenceDeclarationAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                        La déclaration d'absence a été effectuée avec succès.
                    </div>
                }

                {/* Success completed formation toast alert */}
                {this.state.successAssignationAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                        La ré-assignation a été effectuée avec succès.
                    </div>
                }

                {/* Success recruitable toast alert */}
                {this.state.successRecruitableAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                        La validation a été effectuée avec succès.
                    </div>
                }

                {/* Success completed formation toast alert */}
                {this.state.successCompletedFormationAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                        La formation du bakeliste a été arrêtée avec succès.
                    </div>
                }

                {/* Error completed formation toast alert */}
                {this.state.completedFormationRequestError === true &&
                    <div className="alert alert-danger text-center toast-error-alert">
                        <i className="fas fa-exclamation-triangle"></i> &nbsp;&nbsp;
                        Un problème est survenu. Réessayer plus tard.
                    </div>
                }
            
            
        <div className="row add_bakeliste_container">
        <div className="col-md-6 col-md-offset-3 add-coach-btn-container pull-right">
        <a href class="floate" data-target="#infos" data-toggle="modal">
<i class="fas fa-envelope my-floate" style={{color:"white",width:"30px"}}></i>
</a>
            </div>
            </div>
            
            <div class="modal fade" id="infos">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="close">
          <span>&times;</span>
        </button>
        <h4 class="modal-title">Feedback</h4>
      </div>
      <div class="modal-body">
      <div class="modal-body row">
            <form className="add-feedback-form col">
            <div className="form-group">
              <label>Type feedback <sup className="required-field">*</sup> </label>
               <select className="form-control" id="inputType_Feedback" name="feedback_type" onChange={this.handleFeedback} required={true} >
                <option> --Choisir un type -- </option>
                 <option value="Administratif"> Administratif </option>
                  <option value="Technique"> Technique </option>
                    </select>
                    {this.state.EmptyType &&
                    <div className="errorMsg">{this.state.errors.EmptyType}</div>
                     }
                   </div>
                  

              <div className="form-group green-border-focus">
  <label className="feedback_content">commentaire <sup className="required-field">*</sup> </label>
  <   textarea className="form-control" rows="5" id="appComment" name="feedback_content" placeholder="Votre commentaire" onChange={this.handleFeedback} required={true} />
  {this.state.EmptyCommentaire &&
                    <div className="errorMsg">{this.state.errors.EmptyCommentaire}</div>
                     }
</div>
<div className="form-group">

  <div className="custom-file">
    <input type="file" className="form-control" id="inputGroupFile01" name="feedback_file" onChange={this.handleChange}
      aria-describedby="inputGroupFileAddon01"/>
    <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
  </div>

</div>
<div className="form-group">
<label>Priorité <sup className="required-field">*</sup>:</label>
<label class="checkbox-inline">
  <input type="checkbox" name="feedback_priority" value="utile" onChange={this.handleFeedback}/>1
    </label>
    <label class="checkbox-inline">
      <input type="checkbox" name="feedback_priority" value="Moyen" onChange={this.handleFeedback}/>2
    </label>
    <label class="checkbox-inline">
      <input type="checkbox" name="feedback_priority" value="necessaire" onChange={this.handleFeedback}/>3
    </label>
    <label class="checkbox-inline">
      <input type="checkbox" name="feedback_priority" value="Important" onChange={this.handleFeedback}/>4
    </label>
    <label class="checkbox-inline">
      <input type="checkbox" name="feedback_priority" value="Trés Important" onChange={this.handleFeedback}/>5
    </label>
    {this.state.EmptyPriorite &&
                    <div className="errorMsg">{this.state.errors.EmptyPriorite}</div>
                     }
                     {this.state.addFeedbackError &&
                                        <div className="alert alert-danger addReporting-error">
                                            Une erreur est survenu lors de l'ajout de votre feedback. Veuillez réessayer plus tard.
                                        </div>
                                    }
</div> 

</form>
 <div class="modal-footer">
 <button type="button" className="btn btn-danger pull-left cancel_btn cancel button" data-dismiss="modal">
                                        <i className="fas fa-times-circle"></i> &nbsp;
                                        Quitter
                                    </button>
                                    {!this.state.feedbackEnCours &&
                                        <button type="button" className="btn btn-primary bsi-submit-btn" onClick={(e) => this.addFeedback(e)}>
                                            <i className="fas fa-plus"></i> &nbsp;
                                            Ajouter
                                        </button>
                                      }
                                   {this.state.feedbackEnCours  &&
                                        <button type="button" className="btn btn-warning bsi-inprogress-btn">
                                            Ajout en cours &nbsp;
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </button>
                                    }  
                                        </div>
                                        {/* add feedback success toast */}
 {this.state.isFeedbackAddSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                Feedback ajouté avec succès.
            </div>
        }

        
      
      </div>
      </div>
    </div>
  </div>
</div>
</div>
        );
    }
}

export default App;
