import React, {Component} from 'react';
import './messages.scss'
import axios from 'axios';
import API from '../../variablesGlobales';
import Helmet from 'react-helmet';
import {Button , Header, Icon, Grid, Segment,Message} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import {Form, Input,TextArea} from 'semantic-ui-react-form-validator';

export default class messages extends Component {
    constructor(props) {
        super(props);
       this.state = {
        api: API.API,
        fields:{},
        isPending: false,
        successRegister: false,
        errorprogresseAlert: false
     };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleSubmitlError = this.handleSubmitlError.bind(this);
  }

  handleChange = (event) => {
  
    let fields = this.state.fields;
    fields[event.target.name] = event.target.value ;
   
    this.setState({
       fields:fields
      
      },()=> {console.log(fields)}
      
      );
}

handleSubmitlError(label) {
  setTimeout(() => {
    if (label === 'errorprogresseAlert') {
      this.setState({
        errorprogresseAlert: false
      });
    }
  }, 5000);
}

handleSubmit(e){
  e.preventDefault();

        this.setState({
          isPending: true
        });
        
        let data = this.state.fields;
          axios.post(this.state.api + 'messages', data)
            .then(response => {
              this.setState({
                isPending: false
              });
                if (response.data.success) {
                    this.setState({ successRegister:true });
                    console.log(response.data);
                  }
            })
            .catch(error => {
              this.setState({
                errorprogresseAlert: true
              })
              this.handleSubmitlError('errorprogresseAlert')
          })
     
      }
  


    render() {
      
      return    <div className="Connexion" style={{ backgroundImage: 'url()' }}>
      <Helmet>
      <title>Bakeli App | Signaler un problème</title>
        <link rel="stylesheet" href="/vendor/bootstrap/css/bootstrap.min.css" />
      </Helmet>
      <div className="app_owner_logo_container" id='logo-page-sigler-probleme'>
        <img className="img-responsive app_owner_logo" src={require('../../images/logo_bak.png')} alt="logo bakeli" />
      </div>
      <div className="page-holder d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center py-5">
            <div className="col-lg-5 px-lg-4 offset-lg-3">
               <Header as='h1' color='teal' className='signaler-probleme' textAlign='center'>
                {/*   <Icon name='bug' size='huge' /> */}
                  Signaler un problème aux admins du SI
                </Header>
                {this.state.successRegister &&
                <Message color="teal">
                <Message.Header> Hello!
                 <b>{this.state.fields.first_name} {this.state.fields.last_name }</b> 
                </Message.Header>
                   <p>votre message a bien été enregistré. Veuillez patienter l'administrateur 
                   vous contactera dans les plus brefs délais. Nous vous remercions cordialement.</p>
                   <p> <b>L'équipe de Bakeli</b></p>
              </Message>
               }
             {!this.state.successRegister &&
           <Segment id="segment-form-mss">
              <Form color='teal' size='large' id="register-form-massage" ref="form"   onSubmit={this.handleSubmit}   >
              <Input type="text" size='large'  icon='user'  width={16}   iconPosition='left'
                placeholder=' Prénom'  onChange={this.handleChange}  name="first_name"
                validators={['required']} value={this.state.fields.first_name} 
                errorMessages={['* Ce champ est obligatoire']} id='input-message'
               />
               <br/>
                <Input size='large' icon='user' width={16} iconPosition='left' placeholder='Nom'
                onChange={this.handleChange}  name="last_name"  value={this.state.fields.last_name}
                validators={['required']}   errorMessages={['* Ce champ est obligatoire']}  id='input-message'
               />
                <br/>
                <Input size='large' icon='phone' width={16} iconPosition='left' placeholder='Telephone'
                onChange={this.handleChange}  name="phone"  value={this.state.fields.phone} type='number'
                validators={['required']}   errorMessages={['* Ce champ est obligatoire']}  id='input-message'
               />
                 <br/>
             <Input  size='large' width={16} icon='mail' name="email" iconPosition='left' placeholder='Email'
                type="email" onChange={this.handleChange} value={this.state.fields.email}
                validators={['required']}  errorMessages={['* Ce champ est obligatoire']} id='input-message'
               />
                <br/>
              <TextArea
                 placeholder='Que voulez vous dire ?'
                 onChange={this.handleChange} value={this.state.fields.contenu}
                validators={['required']} name="contenu" errorMessages={['CAnnot Be empty']}  
                style={{ minHeight: 100 }} size='huge'  className='textarea-message' id='input-message'
                />
                <Grid.Column verticalAlign='middle'>
                  {!this.state.isPending &&
                  <Button color='orange' content='Signaler un problème' 
                   icon='bug' size='huge'
                   id='btn-signal-probleme'
                   fluid
                   /> 
                   
                  }
                  
                 {this.state.isPending && 
                 <Button color='orange' size='huge'  id='btn-signal-probleme'  fluid>
                 <Icon loading name='spinner' />
                  Envoie en cours
                 </Button>
                  } 
                </Grid.Column>
              </Form>
              {this.state.errorprogresseAlert &&
                      <div className="col-md-12 ri-error-alert-container">
                        <div className="alert alert-danger alerty-content">
                          Un problème est survenu lors de l'insersion. Veuillez réessayer plus tard.
                        </div>
                      </div>
                    }
          </Segment>
           }
         <Message className="return-to-login-message">
           <NavLink className="return-to-login" to="/"> <Icon name='arrow left' />Retour à la page de connexion</NavLink>
          </Message>
            </div>
          </div>
          <p className="mt-5 mb-0 text-gray-400 text-center bakeli-tech-footer">By <a href="https://bakeli.tech" className="external text-gray-400" target="_blank" rel="noopener noreferrer">Bakeli - Learn to create</a></p>

         </div>
      </div>
        
    </div>;
    }
  }
