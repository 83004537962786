import React, {Component} from 'react';
//import PropTypes from 'prop-types';
import Menu from './../../menu/Menu';
//import { Link } from 'react-router-dom';
import API from '../../../variablesGlobales';
import axios from 'axios';
//import request from 'request';

import './../Style.css'

class EditProfile extends Component {

  constructor(props) {
    
    super(props);
    
    const userId = window.sessionStorage.getItem('user_id');

    this.state = {
      api: API.API,
      userId: userId,
      userFullName: window.sessionStorage.getItem('userFullName'),
      userPhone: window.sessionStorage.getItem('user_phone'),
      userAddress: window.sessionStorage.getItem('user_address'),
      userBio: window.sessionStorage.getItem('user_bio'),
      userFirstName: window.sessionStorage.getItem('user_first_name'),
      userLastName: window.sessionStorage.getItem('user_last_name'),
      hasFlash: false,
      flashType: 'success',
      flashMessage: '',
    }

    axios.get(API.API + 'get-profile/' + this.state.userId).then(res => {
      const userData = res.data.data;
      
      this.setState({userFirstName: userData.first_name}); 
      this.setState({userLastName: userData.last_name}); 
      this.setState({userPhone: userData.phone}); 
      this.setState({userAddress: userData.address}); 
      this.setState({userBio: userData.bio}); 
    });

  }

  handleChange = (e) => {
    
    let value = e.target.value;
    //console.log(value);
    if(e.target.name === 'first_name'){
      this.setState({userFirstName: value});
    }else if(e.target.name === 'last_name'){
      this.setState({userLastName: value});
    }else if(e.target.name === 'phone'){
      this.setState({userPhone: value});
    }else if(e.target.name === 'address'){
      this.setState({userAddress: value});
    }else if(e.target.name === 'bio'){
      this.setState({userBio: value});
    }
  }


  hideAlertAfter = (duration) => {
    setTimeout(() => {
      this.setState({hasFlash: false});
    }, duration*1000);
  }



  updateProfile = (e) => {
    e.preventDefault();
    const user_id    = this.state.userId;
    const first_name = this.state.userFirstName;
    const last_name  = this.state.userLastName;
    const phone      = this.state.userPhone;
    const address    = this.state.userAddress;
    const bio        = this.state.userBio;

   // console.log(bio);

    const data      = { first_name, last_name, phone, address, bio };

    axios.put(this.state.api + 'editer-mon-profil/' + user_id, data).then(response => {
      if(response.data.success) {
        this.setState({hasFlash: true, flashMessage: 'Votre profil a bien été mis à jour.'});
        this.updateFormData(response.data.data);
        this.hideAlertAfter(2);
        this.props.history.push('/mon-profil');
      }
    }).catch(error => {
      this.setState({successfullUpdate: false, successfullMessage: 'Une erreur est survenue lors de la mise à jour de votre profil.'});
    });

  }

  updateFormData = (data) => {
    this.setState({userFirstName: data.first_name});
    this.setState({userLastName: data.last_name});
    this.setState({userFirstName: data.first_name});
    this.setState({userPhone: data.phone});
    this.setState({userAddress: data.address});
    this.setState({userBio: data.bio});
  }

  render() {
    return (
      <div>
        <Menu />
        <h1 className="text-center"><i className="fa fa-edit"></i> Editer mon profil</h1>
        <hr />
        <div className="row">
          <div className="col-md-6 col-md-offset-3" id='editprofil'>
            <form onSubmit={e => this.updateProfile(e)}>
              <div className="form-group">
                <label htmlFor="first_name">Prénom</label>
                <input type="text" name="first_name" id="first_name" className="form-control" value={this.state.userFirstName} onChange={e=> this.handleChange(e)} />
              </div>
              <div className={`form-group`}>
                <label htmlFor="last_name">Nom</label>
                <input type="text" name="last_name" id="last_name" className="form-control" value={this.state.userLastName} onChange={e=> this.handleChange(e)} />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Numéro de téléphone</label>
                <input type="text" name="phone" id="phone" className="form-control" value={this.state.userPhone} onChange={e=> this.handleChange(e)} />
              </div>
              <div className="form-group">
                <label htmlFor="address">Adresse</label>
                <input type="text" name="address" id="address" className="form-control" value={this.state.userAddress} onChange={e=> this.handleChange(e)} />
              </div>
              <div className="form-group">
                <label htmlFor="bio">Biographie</label>
                <textarea value={this.state.userBio} className="form-control" name="bio" id="bio" cols="30" rows="5" onChange={e => this.handleChange(e)} />
              </div>
              <div className="form-group">
                <button type="submit" className='btn btn-warning addBtn button submit'>
                    <i className="fas fa-edit"></i> &nbsp;
                    Enregistrer les modifications
                </button>
              </div>
            </form>
          </div>
        </div>

        {this.state.hasFlash === true &&
            <div className={`alert alert-${this.state.flashType} text-center upsuccess-alert animated bounceIn`}>
                <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                {this.state.flashMessage}
            </div>
        }
      </div>
    )
  }
}
EditProfile.propTypes = {
}

export default EditProfile;