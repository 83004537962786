import React, { Component } from "react";
import "./gestionPointage.css";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import API from "../../variablesGlobales";
import axios from "axios";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import Menu from "./../menu/Menu";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
//import $ from 'jquery';
import "../../../node_modules/admin-lte/dist/css/AdminLTE.min.css";
import "../../../node_modules/admin-lte/dist/css/AdminLTE.css";
import "../../../node_modules/admin-lte/dist/css/skins/_all-skins.css";
import * as Sentry from "@sentry/browser";

export default class gestionPointage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      isPageLoading: false,
      pointages: [],
      countPointages: 0,
      todayPointages: [],
      todayPointagesCount: 0,
      yesterdayPointages: [],
      yesterdayPointagesCount: 0,
      weeklyPointages: [],
      weeklyPointagesCount: 0,
      monthlyPointages: [],
      monthlyPointagesCount: 0,
    };
  }

  componentDidMount = () => {
    //this.onGetAllPointages();
    this.onGetPeriodicalPointages();
  };

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  onGetAllPointages = () => {
    this.setState({
      isPageLoading: true,
    });

    const baseApi = this.state.api;
    axios
      .get(baseApi + "bakeliste-liste-presences")
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            pointages: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onGetPeriodicalPointages = () => {
    this.setState({
      isPageLoading: true,
    });

    const apiBaseUrl = this.state.api;
    axios
      .get(apiBaseUrl + "pointme-infos")
      .then((response) => {
        this.setState({
          pointages: response.data.dataAllPointage,
          countPointages: response.data.countDataAllPointage,
          todayPointages: response.data.dataDaily,
          todayPointagesCount: response.data.countDataDaily,
          yesterdayPointages: response.data.dataYesterday,
          yesterdayPointagesCount: response.data.countDataYesterday,
          weeklyPointages: response.data.dataWeekly,
          weeklyPointagesCount: response.data.countDataWeekly,
          monthlyPointages: response.data.dataMonthly,
          monthlyPointagesCount: response.data.countDataMonthly,
          isPageLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
        {/* <button type='button'
              className={ `btn btn-primary volkeno-red-bcolor` }>
              MyCustomBtn
            </button> */}
      </ButtonGroup>
    );
  };

  // componentDidCatch(err, info) {
  //   Sentry.captureException(err);
  // }

  statusFormatter = (cell, row) => {
    if (cell === "admin") {
      return <span className="label label-info"> {row.user_status} </span>;
    }
    if (cell === "bakeliste") {
      return <span className="label label-danger"> {row.user_status} </span>;
    }
    if (cell === "coach") {
      return (
        <span className="label label-success label-vgreen">
          {" "}
          {row.user_status}{" "}
        </span>
      );
    }
  };

  frenchShortFormatDate = (datetoformat) => {
    const date = new Date(datetoformat);
    return date.toLocaleString("fr-FR", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
    };
    if (this.isPageLoading) {
      return (
        <div className="component-gestion-pointage">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">
            <section className="content-header">
              <h1>Gestion des pointages</h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/dashboard-admin">Accueil</Link>
                </li>
                <li className="active">Gestion des pointages</li>
              </ol>
            </section>

            <section className="content">
              <div className="loader_container">
                <div id="loader"></div>
              </div>
            </section>
          </div>
        </div>
      );
    }
    return (
      <div className="component-gestion-pointage">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Gestion des pointages</h1>
            <ol className="breadcrumb">
              <li>
                <Link to="/dashboard-admin">Accueil</Link>
              </li>
              <li className="active">Gestion des pointages</li>
            </ol>
          </section>
          {/* section navlink */}
          {/* <section className="content"> */}
          {/* Blocs contain bakelistes delay list and absence list */}
          <div className="row tab-data-row">
            <div className="col-md-12 tab-data-col">
              <div className="col-md-12 reporting-container">
                <div className="list_prospect_container">
                  <div className="row prospect-row-row">
                    <div className="col-md-12 prospect-col">
                      <div className="nav-tabs-custom">
                        <div className="col-md-12 da-list-col">
                          <ul className="nav nav-tabs">
                            <li className="">
                              <NavLink to="/reglement-interieur">RI</NavLink>
                            </li>
                            <li className="">
                              <NavLink to="/reportings">Reporting</NavLink>
                            </li>
                            <li className="active">
                              <NavLink to="/gestion-pointages">
                                Pointage
                              </NavLink>
                            </li>
                            <li className="">
                              <NavLink to="/gestion-retards-absences-bakelistes">
                                Retard/Absence
                              </NavLink>
                            </li>
                            <li className="">
                              <NavLink to="/gestion-tache">Tâches</NavLink>
                            </li>

                            {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </section>  */}
          <section className="content">
            <div className="pointages_container">
              <div className="col-md-12">
                <div className="box">
                  <div className="box-header with-border  volkeno-green-bcolor widget-header">
                    <h3 className="box-title pointage-title">
                      Pointage par période
                    </h3>
                    <div className="box-tools pull-right">
                      <button
                        type="button"
                        className="btn btn-box-tool"
                        data-widget="collapse"
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="header-container-training">
                      <div className="row da-row">
                        <div className="nav-tabs-custom">
                          <div className="col-md-12 da-list-col">
                            <ul className="nav nav-tabs">
                              <li className="active">
                                <a
                                  href="#today"
                                  data-toggle="tab"
                                  aria-expanded="true"
                                >
                                  Aujourd'hui{" "}
                                  <span className="label label-default volkeno-green-label">
                                    {" "}
                                    {this.state.todayPointagesCount}{" "}
                                  </span>{" "}
                                </a>
                              </li>
                              <li className="">
                                <a
                                  href="#yesterday"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Hier{" "}
                                  <span className="label label-default volkeno-green-label">
                                    {" "}
                                    {this.state.yesterdayPointagesCount}{" "}
                                  </span>{" "}
                                </a>
                              </li>
                              <li className="">
                                <a
                                  href="#weekly"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Semaine{" "}
                                  <span className="label label-default volkeno-green-label">
                                    {" "}
                                    {this.state.weeklyPointagesCount}{" "}
                                  </span>{" "}
                                </a>
                              </li>
                              <li className="">
                                <a
                                  href="#monthly"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Mois{" "}
                                  <span className="label label-default volkeno-green-label">
                                    {" "}
                                    {this.state.monthlyPointagesCount}{" "}
                                  </span>{" "}
                                </a>
                              </li>
                              <li className="">
                                <a
                                  href="#all"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  {" "}
                                  Tout{" "}
                                  <span className="label label-default volkeno-green-label">
                                    {" "}
                                    {this.state.countPointages}{" "}
                                  </span>{" "}
                                </a>
                              </li>
                              {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                            </ul>
                          </div>
                          <div className="tab-content">
                            <div className="tab-pane active" id="today">
                              <div className="row delay-table-row">
                                <div className="col-md-12 delay-table-col">
                                  {/* TODO */}
                                  <BootstrapTable
                                    data={this.state.todayPointages}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                  >
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="50"
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="date_pointage"
                                      // dataFormat={this.statusFormatter.bind(this)}
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataSort={true}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="userFullname"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Prénom et Nom
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="arriving_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure d'arrivée
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="departure_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure de départ
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="user_status"
                                      dataFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Statuts
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="training_place"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Lieu
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="yesterday">
                              <div className="row delay-table-row">
                                <div className="col-md-12 delay-table-col">
                                  {/* TODO */}
                                  <BootstrapTable
                                    data={this.state.yesterdayPointages}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                  >
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="50"
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="frenchDateFormat"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataSort={true}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="userFullname"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Prénom et Nom
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="arriving_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure d'arrivée
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="departure_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure de départ
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="user_status"
                                      dataFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Statuts
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="training_place"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Lieu
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="weekly">
                              <div className="row delay-table-row">
                                <div className="col-md-12 delay-table-col">
                                  {/* TODO */}
                                  <BootstrapTable
                                    data={this.state.weeklyPointages}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                  >
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="50"
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="frenchDateFormat"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataSort={true}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="userFullname"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Prénom et Nom
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataAlign="center"
                                      width='200'
                                      dataField='name'
                                      filter={{ type: 'TextFilter', delay: 1000 }}
                                    >Nom de famille</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="arriving_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure d'arrivée
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="departure_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure de départ
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="user_status"
                                      dataFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Statuts
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="training_place"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Lieu
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="monthly">
                              <div className="row delay-table-row">
                                <div className="col-md-12 delay-table-col">
                                  {/* TODO */}
                                  <BootstrapTable
                                    data={this.state.monthlyPointages}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                  >
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="50"
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="frenchDateFormat"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataSort={true}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="userFullname"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Prénom et Nom
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataAlign="center"
                                      width='200'
                                      dataField='name'
                                      filter={{ type: 'TextFilter', delay: 1000 }}
                                    >Nom de famille</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="arriving_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure d'arrivée
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="departure_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure de départ
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="user_status"
                                      dataFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Statuts
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="training_place"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Lieu
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="all">
                              <div className="row delay-table-row">
                                <div className="col-md-12 delay-table-col">
                                  {/* TODO */}
                                  <BootstrapTable
                                    data={this.state.pointages}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                  >
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="50"
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="date_pointage"
                                      dataFormat={this.frenchShortFormatDate.bind(
                                        this
                                      )}
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataSort={true}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="userFullname"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Prénom
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="arriving_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure d'arrivée
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="150"
                                      dataField="departure_time"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Heure de départ
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="user_status"
                                      dataFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Statuts
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataAlign="center"
                                      width="200"
                                      dataField="training_place"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                    >
                                      Lieu
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End training period */}
            </div>
          </section>
        </div>
      </div>
    );
  }
}
