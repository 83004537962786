import React, {Component} from 'react';

import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import $ from 'jquery';
import * as Sentry from '@sentry/browser';
export default class Controles extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');
    
        if (!token) {
            window.history.back()
        }
        if (userStatus === 'bakeliste' || userStatus === 'coach') {
            window.history.back();
        }
        super(props);
        this.state = {
          userID: window.sessionStorage.getItem('user_id'),
          token: window.sessionStorage.getItem('token'),
          userStatus: window.sessionStorage.getItem('user_status'),
          api: API.API,
          isPageLoading: true,
          isGetCompaniesRequestError: false,
          companies: [],
          companyInfos: [],
          addCompanyInProgress: false,
          fields: {},
          errors: {},
          isCompanyNameEmpty: false,
          isFirstNameEmpty: false,
          isLastNameEmpty: false,
          isPhoneEmpty: false,
          isEmailEmpty: false,
          addCompanyRequestError: false,
          successAddingCompanyAlert: false,
          adminID: window.sessionStorage.getItem('user_id'),
          
          demandesPlacement: [],
          addDirectPlacementRequestInProgress: false,
          addDirectPlacementRequestError: false,
          addSuccessDPRAlert: false,
         
          companyIDEmpty: false,
          descriptionEmpty: false,
          conditionsEmpty: false,
          levelEmpty: false,
          isInputLoading: false,
          
          isDPRError: false,
          placementRequestInfos: [],
          bakelistes: [],
          bakelisteInfos: [],
          completedInProgress: false,
          archivedActionSuccessAlert: false,
          archivedActionErrorAlert: false,
          hiredBakelistes: [],
      dataPlacement: [],
      hiredCompletedInProgress: false,
      successCompleteHireAlert: false,
      isCompletedHireRequestError: false
          
        };
    
        this.handleAddCompanyChange = this.handleAddCompanyChange.bind(this);
        this.addNewCompany = this.addNewCompany.bind(this);
        // dp
        this.addHandleChange = this.addHandleChange.bind(this);
        this.addNewDirectPlacementRequest = this.addNewDirectPlacementRequest.bind(this);
        this.getDirectPlacementRequestInfos = this.getDirectPlacementRequestInfos.bind(this);

        this.handleHireCompleted = this.handleHireCompleted.bind(this);

      }
    
      componentDidMount() {
        this.getCompaniesList();
        this.getAllDirectPlacementRequest();
        this.onGetBakelistesImmersion();
        this.getRecruitablesBakelistes();
        this.getHiredBakelistes();
      }
      getCompaniesList = () => {
        this.setState({
          isPageLoading: true
        })
        axios.get(this.state.api + 'companies')
          .then(response => {
            if (response.data.success) {
              this.setState({
                isPageLoading: false,
                companies: response.data.data
              })
            } else {
              this.setState({
                isPageLoading: false,
                isGetCompaniesRequestError: true
              })
            }
          })
          .catch(error => {
            console.log(error.message)
            this.setState({
              isPageLoading: false,
              isGetCompaniesRequestError: true
            })
          })
      }
    
      flnameFormatter = (cell, row) => {
        return (
          <span className="fullname-link" data-toggle="modal" data-target="#showDetailsModal" onClick={(e) => this.getCompanyInfos(row, e)}>{cell}</span>
        );
      }
    
      actionsFormatter = (cell, row) => {
        return(
          <div className="actions-btn-container">
            <button onClick={() => { this.getCompanyInfos(row) }} id="show_item" className="btn btn-info show-btn volkeno-green-bcolor" data-toggle="modal" data-target="#showDetailsModal">
                <i className="fas fa-eye"></i>
            </button>
          </div>
        )
      }
    
      getCompanyInfos = (company) => {
        this.setState({
          companyInfos: company
        })
      }
    
      handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
      }
    
      createCustomClearButton = (onClick) => {
        return (
          <ClearSearchButton
            btnText='Effacer'
            btnContextual='btn-warning'
            className='my-custom-class'
            onClick={e => this.handleClearButtonClick(onClick)} />
        );
      }
    
      createCustomButtonGroup = props => {
        return (
          <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
            {props.exportCSVBtn}
            {/* <button type='button'
              className={ `btn btn-primary volkeno-red-bcolor` }>
              MyCustomBtn
            </button> */}
          </ButtonGroup>
        );
      }
    
      /**
       * Handle hide alert
       */
      handleShowAndHideAlert(input){
        setTimeout(() => {
          if(input === 'companyNameAlert'){
            this.setState({
              isCompanyNameEmpty: false
            })
          }
          if(input === 'firstNameAlert'){
            this.setState({
              isFirstNameEmpty: false
            })
          }
          if(input === 'lastNameAlert'){
            this.setState({
              isLastNameEmpty: false
            })
          }
          if(input === 'phoneAlert'){
            this.setState({
              isPhoneEmpty: false
            })
          }
          if(input === 'emailAlert'){
            this.setState({
              isEmailEmpty: false
            })
          }
          if(input === 'successAddingCompanyAlert'){
            this.setState({
              successAddingCompanyAlert: false
            })
          }
          if(input === 'addCompanyRequestError'){
            this.setState({
              addCompanyRequestError: false
            })
          }
          
        }, 5000);
      }
    
      /**
       * Handle change add company form
       */
      handleAddCompanyChange = (e) => {
        let fields = this.state.fields;
        fields['admin_id'] = this.state.userID;
        fields[e.target.name] = e.target.value;
    
        this.setState({
          fields
        })
        console.log(fields);
      }
    
      addCompanyValidationForm = () => {
        let fields = this.state.fields;
        let isValidForm = true;
        let errors = this.state.errors;
    
        if(!fields['company_name']){
          isValidForm = false;
          this.setState({
            isCompanyNameEmpty: true
          })
          this.handleShowAndHideAlert('companyNameAlert');
          errors["company_name_empty"] = "Le nom de l'entreprise est obligatoire";
        }
        if(!fields['agent_firstName']){
          isValidForm = false;
          this.setState({
            isFirstNameEmpty: true
          })
          this.handleShowAndHideAlert('firstNameAlert');
          errors["first_name_empty"] = "Le prénom est obligatoire";
        }
        if(!fields['agent_lastName']){
          isValidForm = false;
          this.setState({
            isLastNameEmpty: true
          })
          this.handleShowAndHideAlert('lastNameEmpty');
          errors["last_name_empty"] = "Le nom est obligatoire";
        }
        if(!fields['phone']){
          isValidForm = false;
          this.setState({
            isPhoneEmpty: true
          })
          this.handleShowAndHideAlert('phoneAlert');
          errors["phone_empty"] = "Le téléphone est obligatoire";
        }
        if(!fields['email']){
          isValidForm = false;
          this.setState({
            isEmailEmpty: true
          })
          this.handleShowAndHideAlert('emailAlert');
          errors["email_empty"] = "L'email est obligatoire";
        }
    
        this.setState({
          errors: errors
        })
    
        return isValidForm;
      }
      addNewCompany = (e) => {
        e.preventDefault();
        this.setState({
          addCompanyInProgress: true
        })
    
        if(this.addCompanyValidationForm()){
          var data = this.state.fields;
          axios.post(this.state.api + 'companies', data)
            .then(response => {
              if(response.data.success){
                this.getCompaniesList();
                $('.close').click();
                console.log('click')
                $('body').removeClass('modal-open');
                console.log('open')
                $('.modal-backdrop').removeClass('modal-backdrop');
                console.log('backdrop')
                $('.fade').removeClass('fade')
                $('.in').removeClass('in')
                //document.getElementById('add_company_form').reset();
                this.setState({
                  addCompanyInProgress: false,
                  successAddingCompanyAlert: true
                })
                this.handleShowAndHideAlert('successAddingCompanyAlert');
              }else{
                console.log(response)
                this.setState({
                  addCompanyRequestError: true,
                  addCompanyInProgress: false
                })
              this.handleShowAndHideAlert('addCompanyRequestError');
              }
            })
            .catch(error => {
              console.log(error.message)
              this.setState({
                addCompanyRequestError: true,
                addCompanyInProgress: false
              })
              this.handleShowAndHideAlert('addCompanyRequestError');
            })
        }else{
          this.setState({
            addCompanyInProgress: false
          })
        }
      }
    // demande de placemant
    
  /**
   * Liste des demandes de placement directes
   */
  getAllDirectPlacementRequest = () => {
    this.setState({
      isPageLoading: true
    })

    axios.get(this.state.api + 'direct-placement-requests')
      .then(response => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            demandesPlacement: response.data.data
          })
        } else {
          console.log(response)
          this.setState({
            isPageLoading: false,
            isDPRError: true
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isPageLoading: false,
          isDPRError: true
        })
      })
  }
  getDirectPlacementRequestInfos = (demande) => {
    let placement = demande
    this.setState({
      placementRequestInfos: placement
    }, () => {
      console.log(this.state.placementRequestInfos)
    })
    //return await this.state.placementRequestInfos;
  console.log(this.state.placementRequestInfos)
  }

  /**
   * Company name formatter
   */
  companyNameFormatter = (cell, row) => {
    return (
      <span className="fullname-link" data-toggle="modal" data-target="#directPlacementRequestModal" onClick={(e) => this.getDirectPlacementRequestInfos(row, e)}>{cell}</span>
    );
  }

  /**
   * Agent name formatter
   */
  agentNameFormatter = (cell, row) => {
    return (
      <span className="fullname-link" data-toggle="modal" data-target="#directPlacementRequestModal" onClick={(e) => this.getDirectPlacementRequestInfos(row, e)}>{cell}</span>
    );
  }

  /**
   * Profile level formatter
   */
  profileLevelFormatter = (cell, row) => {
    if(cell === 'junior'){
      return(
        <span className="label label-default label-green-bgcolor"> {cell} </span>
      );
    }else if(cell === 'intermédiaire'){
      return(
        <span className="label label-warning"> {cell} </span>
      );
    }else if(cell === 'confirmé'){
      return(
        <span className="label label-success"> {cell} </span>
      );
    }

  }

  /**
   * Actions buttons formatter
   */
  actionsFormatterDP = (cell, row) => {
    return (
      <span>
        <button onClick={() => { this.getDirectPlacementRequestInfos(row) }} id="show_item" className="btn btn-info show-item-btn" data-toggle="modal" data-target="#directPlacementRequestModal">
          <i className="fas fa-eye"></i>
        </button> &nbsp;&nbsp;
            {/* <button onClick={() => { this.getTacheInfos(row) }} id="edit_item" className="btn btn-info edit-btn volkeno-orange-bcolor" data-toggle="tooltip" data-placement="top" title="Modifier la tâche" data-toggle="modal" data-target="#editTacheModal">
                <i className="fas fa-edit"></i>
            </button> */}
      </span>

    );
  }

  /**
   * Budget Formatter
   */
  budgetFormatterDP = (cell, row) => {
    return(
      <span className="company_budget">
        {cell + ' FCFA'}
      </span>
    );
  }
  handleShowAndHideAlertDP(input) {
    setTimeout(() => {
      if (input === 'company_id') {
        this.setState({
          companyIDEmpty: false
        });
      }
      if (input === 'offer_description') {
        this.setState({
          descriptionEmpty: false
        });
      }
      if (input === 'offer_conditions') {
        this.setState({
          conditionsEmpty: false
        });
      }
      if (input === 'profile_level') {
        this.setState({
          levelEmpty: false
        });
      }
      if(input === 'addSuccessDPRAlert'){
        this.setState({
          addSuccessDPRAlert: false
        })
      }
      if(input === 'addDirectPlacementRequestError'){
        this.setState({
          addDirectPlacementRequestError: false
        })
      }
    }, 5000);
  }

  /**
   * Handle change add form data
   */
  addHandleChange = (e) => {
    let fields = this.state.fields;
    fields["admin_id"] = this.state.adminID;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields
    })
    console.log(fields)
  }
  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
            className={ `btn btn-primary volkeno-red-bcolor` }>
            MyCustomBtn
          </button> */}
      </ButtonGroup>
    );
  }

  /**
   * Add form validation
   */
  addDPLValidation = () => {
    let isValidForm = true;
    let errors = this.state.errors;
    let fields = this.state.fields;

    if(!fields["company_id"]){
      isValidForm = false;
      errors["company_id"] = "Ce champ est obligatoire."
      this.setState({
        companyIDEmpty: true
      })
      this.handleClearButtonClick('compnay_id')
    }
    if(!fields["offer_description"]){
      isValidForm = false;
      errors["offer_description"] = "Ce champ est obligatoire."
      this.setState({
        descriptionEmpty: true
      })
      this.handleClearButtonClick('offer_description')
    }
    if(!fields["offer_conditions"]){
      isValidForm = false;
      errors["offer_conditions"] = "Ce champ est obligatoire."
      this.setState({
        conditionsEmpty: true
      })
      this.handleClearButtonClick('offer_conditions')
    }
    if(!fields["profile_level"]){
      isValidForm = false;
      errors["profile_level"] = "Ce champ est obligatoire."
      this.setState({
        levelEmpty: true
      })
      this.handleClearButtonClick('profile_level')
    }

    return isValidForm;
  }

  /**
   * Submit add form
   */
  addNewDirectPlacementRequest = (e) => {
    e.preventDefault();
    this.setState({
      addDirectPlacementRequestInProgress: true
    })

    if(this.addDPLValidation()){
      let data = this.state.fields;
      axios.post(this.state.api + 'direct-placement-requests', data)
        .then(response => {
          if(response.data.success){
            this.getAllDirectPlacementRequest();
            $('.close').click();
            $('.modal-backdrop').removeClass('modal-backdrop');
            $('.fade').removeClass('fade')
            $('.in').removeClass('in')
            document.getElementById('addNewDirectPlacementRequest').reset();
            this.setState({
              addDirectPlacementRequestInProgress: false,
              addSuccessDPRAlert: true
            })
            this.handleShowAndHideAlertDP('addSuccessDPRAlert')
          }else{
            console.log(response)
            this.setState({
              addDirectPlacementRequestError: true,
              addDirectPlacementRequestInProgress: false
            })
            this.handleShowAndHideAlertDP('addDirectPlacementRequestError')
          }
        })
        .catch(error => {
          console.log(error.message)
          this.setState({
            addDirectPlacementRequestError: true,
            addDirectPlacementRequestInProgress: false
          })
          this.handleShowAndHideAlertDP('addDirectPlacementRequestError')
        })
    }
  }
// immersion bakeliste
componentDidCatch(err, info){
  Sentry.captureException(err);
}

onGetBakelistesImmersion = () => {
  this.setState({
    isPageLoading: true
  })
  axios.get(this.state.api + 'liste-bakelistes-en-immersion')
    .then(response => {
      if(response.data.success){
        this.setState({
          isPageLoading: false,
          bakelistes: response.data.data
        })
      }else{
        this.setState({
          isPageLoading: false
        })
      }
    })
    .catch(error => {
      console.log(error)
      this.setState({
        isPageLoading: false
      })
      if (process.env.NODE_ENV !== 'production') {
        return;
      }
      Sentry.captureException(error);
    })
}

bakelisteFullnameFormatter = (cell, row) => {
  return (
      <span className="fullname-link" data-toggle="modal" data-target="#bakelisteInfosModal" onClick={(e) => this.getBakelisteInfos(row, e)}>{cell}</span>
  );
}

handleShowAndHideAlertBI(label) {
  setTimeout(() => {
    if (label === 'archivedActionSuccessAlert') {
      this.setState({
        archivedActionSuccessAlert: false
      });
    }
    if (label === 'archivedActionErrorAlert') {
      this.setState({
        archivedActionErrorAlert: false
      });
    }
  }, 5000);
}

getBakelisteInfos = (bakeliste) => {
  this.setState({
    bakelisteInfos: bakeliste
  })
}

handleCompletedImmersion = (bakeliste) => {
  this.setState({
    completedInProgress: true
  })
  let bakelisteID = bakeliste.id;
  console.log(bakelisteID)
  axios.post(this.state.api + 'archived-immersion/' + bakelisteID)
    .then(response => {
        this.onGetBakelistesImmersion();
        this.setState({
          completedInProgress: false,
          archivedActionSuccessAlert: true
        })
        this.handleShowAndHideAlertBI('archivedActionSuccessAlert')
    })
    .catch(error => {
      //this.onGetBakelistesImmersion();
      console.log(error)
      this.setState({
        completedInProgress: false,
        archivedActionErrorAlert: true
      })
      this.handleShowAndHideAlertBI('archivedActionErrorAlert')

      if (process.env.NODE_ENV !== 'production') {
        return;
      }
      Sentry.captureException(error);
    })
}

actionsFormatterBI = (cell, row) => {
  return (
      <div className="actions-btn-container">
          <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#bakelisteInfosModal" onClick={(e) => this.getBakelisteInfos(row)}>
        <i class="fas fa-eye"></i>
      </button> &nbsp;
          <button data-toggle="tooltip" data-placement="top" title="Terminer l'immersion" type="button" className="btn btn-danger terminer-immersion" id="completed"
              onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter l'immersion de ce bakeliste ?") &&
                  this.handleCompletedImmersion(row)}
          >
              {this.state.completedInProgress ?
                  <i className="fas fa-spin fa-spinner"></i> :
                  <i class="fas fa-stop-circle"></i>
              }
          </button>
      </div>
  );
}

rowClassNameFormat = () => {

}
coachRowFormatter = (cell, row) => {
  return (
      <div className="coach_row actions-btn-container">
          {row.coach_id ?
              <Link to="#">
                  { cell }
              </Link> :
              <span className="empty-coach label label-warning"> non assigné </span>

          }
      </div>
  );
}
// RECRUTABLE


getRecruitablesBakelistes = () => {
  this.setState({
    isPageLoading: true
  });

  axios.get(this.state.api + 'liste-bakelistes-recrutables')
    .then(response => {
      this.setState({
        isPageLoading: false
      });
      if (response.data.success) {
        this.setState({
          bakelistes: response.data.data,
          isPageLoading: false
        });
      } else {
        console.log(response)
        this.setState({
          isRequestError: true,
          isPageLoading: false
        });
      }
    })
    .catch(error => {
      console.log(error.message)
      this.setState({
        isRequestError: true,
        isPageLoading: false
      })
      if (process.env.NODE_ENV !== 'production') {
        return;
      }
      Sentry.captureException(error);
    })
}

getBakelisteInfos = (bakeliste) => {
  this.setState({
    bakelisteInfos: bakeliste
  })
}

bakelisteMoreInfosAction = (cell, row) => {
  return (
    <span className="more-infos-linky" data-toggle="modal" data-target="#moreInfosModal" onClick={(e) => this.getBakelisteInfos(row, e)}> {cell} </span>
  )
}

actionsFormatterPL = (cell, row) => {
  return(
    <div className="actions-btn-container">
      <NavLink to={'/show-bakeliste-cv/' + row.id + '/admin-view'} data-toggle="tooltip" data-placement="top" title="Voir le CV" type="button" className="btn btn-info showcv" id="btn_show_cv">
          <i class="fas fa-list"></i>
      </NavLink>
    </div>
  )
}

typeFormationFormatterPL = (cell, row) => {
  if (cell === 'courte') {
    return (
      <span className="label label-success volkeno-green-bcolor"> formation courte </span>
    );
  } else if (cell === 'diplomante') {
    return (
      <span className="label label-warning volkeno-orange-bcolor"> formation diplômante </span>
    );
  }
}

handleClearButtonClick = (onClick) => {
  console.log('This is my custom function for ClearSearchButton click event');
  onClick();
}

createCustomClearButton = (onClick) => {
  return (
    <ClearSearchButton
      btnText='Effacer'
      btnContextual='btn-warning'
      className='my-custom-class'
      onClick={e => this.handleClearButtonClick(onClick)} />
  );
}

createCustomButtonGroup = props => {
  return (
    <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
      {props.exportCSVBtn}
      {/* <button type='button'
        className={ `btn btn-primary volkeno-red-bcolor` }>
        MyCustomBtn
      </button> */}
    </ButtonGroup>
  );
}

/**
   * Training since formatter
   */
inTrainingSinceFormatter = (cell, row) => {
  if (cell < 1) {
    return (
      <span class="label label-success volkeno-green-bcolor"> {row.inTrainingSinceDays} jour(s) </span>
    )
  } else {
    return (
      <span class="label label-success volkeno-orange-bgcolor"> {row.inTrainingSinceMonths} mois </span>
    )
  }
}

coachRowFormatter = (cell, row) => {
  return (
    <div className="coach_row actions-btn-container">
      <Link to="#">
        {cell}
      </Link>
    </div>
  );
}
// 
handleShowAndHideAlertHI(label){
  setTimeout(() => {
      if(label === 'completedHireSuccess'){
          this.setState({
            successCompleteHireAlert: false
          });
      }
  }, 5000);
}

getHiredBakelistes = () => {
  this.setState({
    isPageLoading: true
  });

  axios.get(this.state.api + 'placements')
    .then(response => {
      if (response.data.success === true) {
        this.setState({
          isPageLoading: false,
          hiredBakelistes: response.data.data.reverse()
        });
      }
    })
    .catch(error => {
      this.setState({
        isPageLoading: false
      });
      console.log(error)
    })
}

handleClearButtonClick = (onClick) => {
  console.log('This is my custom function for ClearSearchButton click event');
  onClick();
}

createCustomClearButton = (onClick) => {
  return (
    <ClearSearchButton
      btnText='Effacer'
      btnContextual='btn-warning'
      className='my-custom-class'
      onClick={e => this.handleClearButtonClick(onClick)} />
  );
}

getPlacementInfos = (bakeliste) => {
  this.setState({
    dataPlacement: bakeliste
  })
}

handleHireCompleted = (placement) => {
  this.setState({
    hireCompletedInProgress: true
  });

  let placementID = placement.id;
  let bakelisteID = placement.bakeliste_id;

  axios.post(this.state.api + 'complete-bakeliste-placements/' + placementID + '/' + bakelisteID)
    .then(response => {
      if (response.data.success === true) {
        this.getHiredBakelistes();
        $('.close').click();
        this.setState({
          hiredCompletedInProgress: false,
          successCompleteHireAlert: true
        });

        this.handleShowAndHideAlertHI('completedHireSuccess');
      } else {
        this.setState({
          isCompletedHireRequestError: true
        });
      }
    })
    .catch(error => {
      this.setState({
        isCompletedHireRequestError: true
      });
      console.log(error);
    })
}

actionsFormatterHI = (cell, row) => {
  return (
    <div className="actions-btn-container">
      <button type="button" className="btn btn-warning" id="btn_update" data-toggle="modal" data-target="#showHiredModal" onClick={(e) => this.getPlacementInfos(row, e)}>
        <i className="fas fa-eye"></i>
      </button>
      <button type="button" className="btn btn-danger terminer-formation" id="completed"
        onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter le placement de ce bakeliste ?") &&
          this.handleHireCompleted(row)}
      >
        {this.state.hiredCompletedInProgress ?
          <i className="fas fa-spin fa-spinner"></i> :
          <i className="fas fa-stop-circle"></i>
        }

      </button>
    </div>
  );
}

fullnameFormatterHI = (cell, row) => {
  return (
    <span className="fullname-link" data-toggle="modal" data-target="#showHiredModal" onClick={(e) => this.getPlacementInfos(row, e)}>
      {cell}
    </span>
  );
}
render(){
    const options = {
        clearSearch: true,
        clearSearchBtn: this.createCustomClearButton,
        btnGroup: this.createCustomButtonGroup
      };
  
      if (this.state.isPageLoading) {
        return (
          <div className="component-entreprises-partenaires">
            <Menu />
            {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                    Aprés avoir intégré AdminLTE */}
            <div className="row no-content-wrapper">
  
              <section className="content-header">
                <h1>
                  Entreprises 
                </h1>
                <ol className="breadcrumb">
                  <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                  <li className="active"></li>
                </ol>
              </section>
              <section className="content">
              <div className="list_users_container">
                <div id="loader"></div>
              </div>
            </section>

          </div>
        </div>
      );
    }
        return (
        <div className="component-entreprises-partenaires">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">
  
            <section className="content-header">
              <h1>
                Entreprises 
              </h1>
              <ol className="breadcrumb">
                <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                <li className="active">Entreprises </li>
              </ol>
            </section>
  
            <section className="content">
            {/* Blocs contain bakelistes delay list and absence list */}
            <div className="row tab-data-row">
              <div className="col-md-12 tab-data-col">
                <div className="col-md-12 reporting-container">
                  <div className="list_prospect_container">
                    <div className="row prospect-row-row">
                      <div className="col-md-12 prospect-col">
                        <div className="nav-tabs-custom">
                          <div className="col-md-12 da-list-col">
                            <ul className="nav nav-tabs">
                            <li className="active"><NavLink to="#EP" data-toggle="tab" aria-expanded="true">Entreprises partenaires</NavLink></li>
                              <li className=""><NavLink to="#DE" data-toggle="tab" aria-expanded="false">Demandes entreprises</NavLink></li>
                              <li className=""><NavLink to="#BI" data-toggle="tab" aria-expanded="false">B. Immersion</NavLink></li>
                              <li className=""><NavLink to="#PL" data-toggle="tab" aria-expanded="false">Placements</NavLink></li>
                              <li className=""><NavLink to="#BR" data-toggle="tab" aria-expanded="false">B.Recrutable</NavLink></li>
                            </ul>
                          </div>
                          <div className="tab-content">
                            <div className="tab-pane active" id="EP">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 prospectw-table-col">
                                <BootstrapTable data={this.state.companies}
striped={true} hover={true} condensed={true}
multiColumnSort={2} options={options}
exportCSV
pagination
search={true}>
<TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
<TableHeaderColumn dataField='company_name' dataSort={true} filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.flnameFormatter.bind(this)}>Entreprise</TableHeaderColumn>
<TableHeaderColumn dataField='agent_firstName' dataSort={true} filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.flnameFormatter.bind(this)} >Prenom</TableHeaderColumn>
<TableHeaderColumn dataField='agent_lastName' dataSort={true} filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.flnameFormatter.bind(this)} >Nom</TableHeaderColumn>
<TableHeaderColumn dataField='phone'>Téléphone</TableHeaderColumn>
<TableHeaderColumn dataField='email' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Email</TableHeaderColumn>
<TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)}>Actions</TableHeaderColumn>
</BootstrapTable>
                                </div>
                              </div>
                            </div>
                           
                            <div className="tab-pane" id="DE">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-weekend-table-col">
                                <div className="col-md-4 col-md-offset-4 add_container">
<button className="btn btn-success add_coach_btn button" data-toggle="modal" data-target="#addRequestPlacementModal">
<i className="fas fa-plus-circle"></i>
</button>
</div>
  <BootstrapTable data={this.state.demandesPlacement}
striped={true} hover={true} condensed={true}
multiColumnSort={2} options={options}
exportCSV
pagination
search={true}>
<TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
<TableHeaderColumn width="250" dataField='frenchDateFormat' filter={{ type: 'TextFilter', delay: 1000 }} >Entreprise</TableHeaderColumn>
<TableHeaderColumn width="250" dataField='companyName' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.companyNameFormatter.bind(this)} >Entreprise</TableHeaderColumn>
<TableHeaderColumn width="250" dataField='agentFullname' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.agentNameFormatter.bind(this)}>Représentant</TableHeaderColumn>
<TableHeaderColumn width="250" dataField='companyPhone'>Téléphone</TableHeaderColumn>
<TableHeaderColumn width="250" dataField='companyEmail' filter={{ type: 'TextFilter', delay: 1000 }}>Email</TableHeaderColumn>
<TableHeaderColumn width="450" dataField='offer_description' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Description du besoin</TableHeaderColumn>
<TableHeaderColumn width="450" dataField='offer_conditions' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Conditions</TableHeaderColumn>
<TableHeaderColumn width="200" dataField='profile_level' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.profileLevelFormatter.bind(this)} dataSort={true}>Niveau souhaité</TableHeaderColumn>
<TableHeaderColumn width="250" dataField='company_budget' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.budgetFormatterDP.bind(this)} dataSort={true}>Budget</TableHeaderColumn>
<TableHeaderColumn width="200" dataField='id' dataFormat={this.actionsFormatterDP.bind(this)}>Actions</TableHeaderColumn>
</BootstrapTable>

                                </div>
                              </div>
                            </div>
                           
                             <div className="tab-pane" id="BI">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-ete-table-col">
                                  <BootstrapTable data={this.state.bakelistes}
striped={true} hover={true} condensed={true}
multiColumnSort={2} options={options}
exportCSV
pagination
search={true}
trClassName={this.rowClassNameFormat}>

<TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='300'
dataField='bakelisteFullname'
dataFormat={this.bakelisteFullnameFormatter.bind(this)}
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true} >Nom complet</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='250'
dataField='companyName'
>Entreprise</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='250'
dataField='companyEmail'
>Email</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='200'
dataField='companyPhone'
>Téléphone</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='300'
dataField='domaine'
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true}>Domaine</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='300'
dataField='immersionStartFrenchDate'
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true}>Début immersion</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='300'
dataField='immersionEndFrenchDate'
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true}>Fin immersion</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='250'
dataField='coachFullname'
dataFormat={this.coachRowFormatter.bind(this)}
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true}>Coach</TableHeaderColumn>

<TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
</BootstrapTable>
                                </div>
                              </div>
                            </div> 
                             <div className="tab-pane" id="BR">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-ete-table-col">
                                  <BootstrapTable data={this.state.bakelistes}
striped={true} hover={true} condensed={true}
multiColumnSort={2} options={options}
pagination exportCSV
search={true}>
<TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
<TableHeaderColumn dataField='first_name'
width="300" filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}
dataFormat={this.bakelisteMoreInfosAction.bind(this)} >Prénom</TableHeaderColumn>
<TableHeaderColumn dataField='last_name'
width="300" filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}
dataFormat={this.bakelisteMoreInfosAction.bind(this)} >Nom</TableHeaderColumn>
<TableHeaderColumn dataField='email' width="250" >Email</TableHeaderColumn>
<TableHeaderColumn dataField='phone' width="200" >Téléphone</TableHeaderColumn>
<TableHeaderColumn dataField='type_formation' width="200"
dataFormat={this.typeFormationFormatterPL.bind(this)}
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true}>Type de formation</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='300'
dataField='domaine'
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true}>Domaine</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='300'
dataField='inTrainingSinceMonths'
dataFormat={this.inTrainingSinceFormatter}
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true}>En formation depuis</TableHeaderColumn>
<TableHeaderColumn dataAlign="center"
width='250'
dataField='coachFullname'
dataFormat={this.coachRowFormatter.bind(this)}
filter={{ type: 'TextFilter', delay: 1000 }}
dataSort={true}>Coach</TableHeaderColumn>

<TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatterPL.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
</BootstrapTable>
                                </div>
                              </div>
                            </div> 
                           
                            <div className="tab-pane" id="PL">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-ete-table-col">
                                <BootstrapTable data={this.state.hiredBakelistes}
striped={true} hover={true} condensed={true}
multiColumnSort={2} options={options}
pagination
search={true}>
<TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
<TableHeaderColumn dataField='bakelisteFullname' dataFormat={this.fullnameFormatterHI.bind(this)} >Nom complet</TableHeaderColumn>
<TableHeaderColumn dataField='company_name'>Nom de l'entreprise</TableHeaderColumn>
<TableHeaderColumn dataField='startFrenchDateFormat'>Date de début</TableHeaderColumn>
<TableHeaderColumn dataField='endFrenchDateFormat'>Date de fin</TableHeaderColumn>
<TableHeaderColumn dataField='id' dataFormat={this.actionsFormatterHI.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
</BootstrapTable>
                                </div>
                              </div>
                            </div>              
                           
                            
                              
               
          
            
                            {/* end table */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
              </div>
            
            {/* Add company modal */}
            <div className="modal fade" id="addCompanyModal" tabindex="-1" role="dialog" aria-labelledby="addCompanyModalLabel">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title" id="addCompanyModalLabel">Ajouter une entreprise</h4>
                  </div>
                  <form id="add_company_form">
                    <div className="modal-body">
                      <div className="row add-company-row">
                        <div className="col-md-12">
                          <div className="col-md-6 form-group ac-input-container">
                            <label>Nom de l'entreprise</label>
                            <input type="text" name="company_name" className="form-control ac-input" placeholder="Nom de l'entreprise" onChange={this.handleAddCompanyChange} />
                            {this.state.isCompanyNameEmpty &&
                              <div className="errorMsg">{this.state.errors.company_name_empty}</div>
                            }
                          </div>
                          <div className="col-md-6 form-group ac-input-container">
                            <label>Prénom du représentant</label>
                            <input type="text" name="agent_firstName" className="form-control ac-input" placeholder="Prénom du représentant" onChange={this.handleAddCompanyChange} />
                            {this.state.isFirstNameEmpty &&
                              <div className="errorMsg">{this.state.errors.first_name_empty}</div>
                            }
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="col-md-6 form-group ac-input-container">
                            <label>Nom de famille du représentant</label>
                            <input type="text" name="agent_lastName" className="form-control ac-input" placeholder="Nom de famille du représentant" onChange={this.handleAddCompanyChange} />
                            {this.state.isLastNameEmpty &&
                              <div className="errorMsg">{this.state.errors.last_name_empty}</div>
                            }
                          </div>
                          <div className="col-md-6 form-group ac-input-container">
                            <label>Téléphone</label>
                            <input type="text" name="phone" className="form-control ac-input" placeholder="Téléphone" onChange={this.handleAddCompanyChange} />
                            {this.state.isPhoneEmpty &&
                              <div className="errorMsg">{this.state.errors.phone_empty}</div>
                            }
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="col-md-12 form-group ac-input-container">
                            <label>Email</label>
                            <input type="text" name="email" className="form-control ac-input" placeholder="Email" onChange={this.handleAddCompanyChange} />
                            {this.state.isEmailEmpty &&
                              <div className="errorMsg">{this.state.errors.email_empty}</div>
                            }
                          </div>
                          <div className="col-md-12 form-group ac-input-container">
                            <label>Description de l'entreprise</label>
                            <textarea name="description" className="form-control ac-input" placeholder="Description de l'entreprise" onChange={this.handleAddCompanyChange} />
                          </div>
                        </div>
  
                        {this.state.addCompanyRequestError &&
                        <div className="col-md-12 add_request_error_container">
                          <div className="alert alert-danger add_request_error">
                            Un probléme est survenu lors de l'ajout. Veuillez réessayer plus tard.
                          </div>
                        </div>
                        }
                      </div>
  
                      
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Quitter
                        </button>
                      {!this.state.addCompanyInProgress &&
                        <button type="button" id="ass" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.addNewCompany(e)}>
                          <i className="fas fa-plus"></i>&nbsp;
                          Ajouter
                        </button>
                      }
                      {this.state.addCompanyInProgress &&
                        <button type="button" className="btn btn-warning bsi-inprogress-btn">
                          Ajout en cours &nbsp;
                        <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
  
            {/* Show company infos modal */}
            <div className="modal fade" id="showDetailsModal" tabindex="-1" role="dialog" aria-labelledby="showDetailsModalLabel">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title" id="showDetailsModalLabel">Détails de {this.state.companyInfos.company_name}</h4>
                  </div>
                    <div className="modal-body">
                      <div className="row show-company-row">
                        <div className="col-md-12 infos_item_container">
                            <span className="item_label">Prénom et Nom du représentant : <br />
                            </span> <span className="label_content">{this.state.companyInfos.agent_firstName + ' ' + this.state.companyInfos.agent_lastName}</span>
                            <hr />
                        </div> 
                        <div className="col-md-12 infos_item_container">
                            <span className="item_label">Email : <br />
                            </span> <span className="label_content">{this.state.companyInfos.email}</span>
                            <hr />
                        </div>
                        <div className="col-md-12 infos_item_container">
                            <span className="item_label">Téléphone : <br />
                            </span> <span className="label_content">{this.state.companyInfos.phone}</span>
                            <hr />
                        </div>
                        <div className="col-md-12 infos_item_container">
                            <span className="item_label">Description : <br /> </span> 
                            {this.state.companyInfos.description !== null ?
                              <span className="label_content">{this.state.companyInfos.description}</span> :
                              <span className="label label-warning"> Aucune description sur cette entreprise </span>
                            }
                            <hr />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Quitter
                        </button>
                    </div>
                </div>
              </div>
            </div>
          
  
          {/* Success completed formation toast alert */}
          {this.state.successAddingCompanyAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                L'entreprise a été ajoutée avec succès.
            </div>
          }
          {/* Ajouter une nouvelle demande de placement directe */}
        <div className="modal fade" id="addRequestPlacementModal" tabindex="-1" role="dialog" aria-labelledby="addRequestPlacementModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="addRequestPlacementModalLabel">Ajouter une demande de placement directe</h4>
              </div>
              <form id="addNewDirectPlacementRequest">
                <div className="modal-body">
                  <div className="add-dpr-row row">
                    <div className="col-md-12 form_item">
                      <div className="input-group">
                        <div className="row row_espace">
                        <label>Nom de l'entreprise</label>
                        <div className="col-md-10 select_espace">
                          
                          <select name="company_id" className="form-control input_item" onChange={this.addHandleChange}>
                            <option> -- Sélectionnez une entreprise -- </option>
                            {this.state.companies.map((item, index) => 
                              <option key={index} value={item.id}> {item.company_name} </option>
                            )}
                          </select>
                          {this.state.companyIDEmpty &&
                            <div className="errorMsg">{this.state.errors.company_id}</div>
                          }
                        </div>
                        <div className="col-md-2 add_espace">
                          <span className="add_company_btn_container">
                            <button className="btn btn-success add_company_btn" data-toggle="tooltip" data-placement="top" title="Ajouter une entreprise">
                              <i className="fas fa-plus"></i>
                            </button>
                          </span>
                        </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-md-12 form_item">
                      <div className="form-group">
                        <label>Description de la mission</label>
                        <textarea placeholder="Description de la mission" name="offer_description" className="form-control input_item" onChange={this.addHandleChange} />
                        {this.state.descriptionEmpty &&
                          <div className="errorMsg">{this.state.errors.offer_description}</div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12 form_item">
                      <div className="form-group">
                        <label>Conditions (Compétences requises)</label>
                        <textarea placeholder="Compétences requises" name="offer_conditions" className="form-control input_item" onChange={this.addHandleChange} />
                        {this.state.conditionsEmpty &&
                          <div className="errorMsg">{this.state.errors.offer_conditions}</div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12 form_item">
                      <div className="form-group">
                        <label>Niveau du profil souhaité</label>
                        <select name="profile_level" className="form-control input_item" onChange={this.addHandleChange}>
                          <option> -- Sélectionnez un type de profil -- </option>
                          <option value="junior"> Junior </option>
                          <option value="intermédiaire"> Intermédiaire </option>
                          <option value="confirmé"> Confirmé </option>
                        </select>
                        {this.state.levelEmpty &&
                          <div className="errorMsg">{this.state.errors.profile_level}</div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12 form_item">
                      <div className="form-group">
                        <label>Budget de l'entreprise <b className="empty_notation"><i>Ce champ peut être vide</i></b></label>
                        <span className="input-group-addon" id="basic-addon-addd">FCFA</span>
                        <input placeholder="Budget de l'entreprise" type="text" name="company_budget" aria-describedby="basic-addon-addd" className="form-control input_item" onChange={this.addHandleChange} />
                      </div>
                    </div>
                    {this.state.addDirectPlacementRequestError &&
                      <div className="col-md-12 alert-container">
                        <div className="alert alert-danger error-alert-content">
                          Un problème est survenu lors de l'ajout. Veuillez réessayer plus tard.
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="row sq-btn-container">
                    <div className="col-md-12 sq-btn-col">
                      <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Quitter
                        </button>
                      {!this.state.addDirectPlacementRequestInProgress &&
                        <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.addNewDirectPlacementRequest(e)}>
                          <i className="fas fa-plus"></i>&nbsp;
                          Ajouter
                          </button>
                      }
                      {this.state.addDirectPlacementRequestInProgress &&
                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                          Ajout en cours &nbsp;
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> 

        {/* Ajouter une nouvelle demande de placement directe */}
        <div className="modal fade" id="directPlacementRequestModal" tabindex="-1" role="dialog" aria-labelledby="directPlacementRequestModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="directPlacementRequestModalLabel">Détails de la demande de {this.state.placementRequestInfos.companyName}</h4>
              </div>
                <div className="modal-body">
                    <div className="show-row">
                      <div className="alert alert-success volkeno-orange-bgcolor date_alerty">
                        Demande effectuée le <br /> {this.state.placementRequestInfos.frenchDateFormat}
                      </div>
                      <div className="infos_item_container">
                          <span className="item_label">Entreprise : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.companyName}</span>
                      </div> <hr />
                      <div className="infos_item_container">
                          <span className="item_label">Prénom et Nom du représentant : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.agentFullname}</span>
                      </div> <hr />
                    </div>
                    <div className="infos_item_container">
                        <span className="item_label">Email : </span> <br />
                        <span className="label_content">{this.state.placementRequestInfos.companyEmail}</span>
                    </div> <hr />
                    <div className="infos_item_container">
                          <span className="item_label">Entreprise : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.companyPhone}</span>
                    </div> <hr />
                    <div className="infos_item_container">
                          <span className="item_label">Description : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.offer_description}</span>
                    </div> <hr />
                    <div className="infos_item_container">
                          <span className="item_label">Conditions : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.offer_conditions}</span>
                    </div> <hr />
                    <div className="infos_item_container">
                          <span className="item_label">Niveau demandé : </span> <br />
                          {this.state.placementRequestInfos.profile_level === 'junior' ?
                            <span className="label label-default label-green-bgcolor"> {this.state.placementRequestInfos.profile_level} </span>
                            :
                            this.state.placementRequestInfos.profile_level === 'intermédiaire' ?
                              <span className="label label-warning"> {this.state.placementRequestInfos.profile_level} </span> :
                              this.state.placementRequestInfos.profile_level === 'confirmé' ?
                                <span className="label label-success"> {this.state.placementRequestInfos.profile_level} </span> :
                                  ''
                          }
                    </div> <hr />
                    <div className="infos_item_container">
                      <span className="item_label">Budget de l'entreprise : </span> <br />
                      {this.state.placementRequestInfos.company_budget !== null ?
                        <span className="label_content">{this.state.placementRequestInfos.company_budget + ' FCFA'}</span>
                        :
                        <span className="label label-info"> L'entreprise n'a pas fourni de budget </span>
                      }
                    </div> <hr />
                </div>
                <div className="modal-footer">
                  <div className="row sq-btn-container">
                    <div className="col-md-12 sq-btn-col">
                      <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Quitter
                        </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        {/* Success add new direct placement request toast alert */}
        {this.state.addSuccessDPRAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              La demande a été bien enregistrée.
          </div>
        }
        {/* INFO BAKELISTE IMMERSION */}
        <div className="modal fade" id="bakelisteInfosModal" tabindex="-1" role="dialog" aria-labelledby="bakelisteInfosModalLabel">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                          <h4 className="modal-title" id="bakelisteInfosModal">Detail sur {this.state.bakelisteInfos.first_name}&nbsp;{this.state.bakelisteInfos.last_name}</h4>
                      </div>
                      <div className="modal-body">
                          {this.state.bakelisteInfos.type_formation === 'courte' ? (
                              this.state.bakelisteInfos.nbreJours === '3' ? (
                                  <div className="content_container">
                                      <b>Jours de présences : </b> <br />
                                      <span> {this.state.bakelisteInfos.jour1}, {this.state.bakelisteInfos.jour2}, {this.state.bakelisteInfos.jour3} </span> <br />
                                      <b>Heure de présence</b> <br />
                                      <span className="horaire-bakeliste-item"> {this.state.bakelisteInfos.horaire} </span>

                                  </div>
                              ) :
                                  this.state.bakelisteInfos.nbreJours === '5' ? (
                                      <div className="content_container">
                                          <b>Jours de présences : </b> <br />
                                          <span> {this.state.bakelisteInfos.jour1}, {this.state.bakelisteInfos.jour2}, {this.state.bakelisteInfos.jour3}, {this.state.bakelisteInfos.jour4}, {this.state.bakelisteInfos.jour5} </span> <br />
                                          <b>Heure de présence</b> <br />
                                          <span className="horaire-bakeliste-item"> {this.state.bakelisteInfos.horaire} </span>

                                      </div>
                                  ) :
                                      this.state.bakelisteInfos.nbreJours === '6' && (
                                          <div className="content_container">
                                              <b>Jours de présences : </b> <br />
                                              <span> {this.state.bakelisteInfos.jour1}, {this.state.bakelisteInfos.jour2}, {this.state.bakelisteInfos.jour3}, {this.state.bakelisteInfos.jour4}, {this.state.bakelisteInfos.jour5}, {this.state.bakelisteInfos.jour6} </span> <br />
                                              <b>Heure de présence</b> <br />
                                              <span className="horaire-bakeliste-item"> {this.state.bakelisteInfos.horaire} </span>
                                          </div>
                                      )
                          ) :
                              this.state.bakelisteInfos.type_formation === 'diplomante' && (
                                  <div className="content_container">
                                      <b>Jours de présences : </b> <br />
                                      <span> Du Lundi au Vendredi </span> <br />
                                      <b>Heure de présence</b> <br />
                                      <span className="horaire-bakeliste-item"> {this.state.bakelisteInfos.horaire} </span>

                                  </div>
                              )
                          }
                          <div className="email_container">
                              <span className="item_label">Email : </span> <span className="label_content">{this.state.bakelisteInfos.email}</span>
                          </div> <hr />
                          <div className="objectif_container">
                              <span className="item_label">Entreprise hôte : </span> <span className="label_content">{this.state.bakelisteInfos.companyName}</span>
                          </div> <hr />

                          {/* {this.state.bakelisteInfos.ecole !== '' &&
                              <div className="ecole_container">
                                  <span className="item_label">Ecole : </span> <span className="label_content">{this.state.bakelisteInfos.ecole}</span> <hr />
                              </div>

                          } */}
                          <div className="email_container">
                              <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.bakelisteInfos.phone}</span>
                          </div> <hr />
                          {/* {this.state.bakelisteInfos.boursier === 1 ?
                              <div className="ecole_container">
                                  <span className="item_label">Boursier : </span> <span className="label_content">OUI</span> <hr />
                              </div> :
                              <div className="ecole_container">
                                  <span className="item_label">Boursier : </span> <span className="label_content">NON</span> <hr />
                              </div>

                          } */}
                          {/* <div className="email_container">
                              <span className="item_label">Lieu de formation : </span> <span className="label_content">{this.state.bakelisteInfos.lieu_formation}</span>
                          </div> <hr /> */}
                          <div className="email_container">
                              <span className="item_label">Type de formation : </span> <span className="label_content">{this.state.bakelisteInfos.type_formation}</span>
                          </div> <hr />
                          {/* {this.state.bakelisteInfos.paiement_inscription === 1 &&
                              <div className="ecole_container">
                                  <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">OUI</span> <hr />
                              </div>

                          } */}
                          {/* {this.state.bakelisteInfos.paiement_inscription === 0 &&
                              <div className="ecole_container">
                                  <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">NON</span> <hr />
                              </div>

                          } */}
                          <div className="email_container">
                              <span className="item_label">Date de début de l'immersion : </span> <span className="label_content">{this.state.bakelisteInfos.immersionStartFrenchDate}</span>
                          </div> <hr />
                          <div className="email_container">
                              <span className="item_label">Date de fin de l'immersion : </span> <span className="label_content">{this.state.bakelisteInfos.immersionEndFrenchDate}</span>
                          </div> <hr />
                      </div>
                      <div className="modal-footer">

                      </div>
                  </div>
              </div>
          </div>

          {/* Success completed immersion toast alert */}
          {this.state.archivedActionSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
              <i class="fas fa-check-double"></i> &nbsp;&nbsp;
              L'arrêt de l'immersion a été effectué avec succès.
            </div>
          }
          {/* Error completed immersion toast alert */}
          {this.state.archivedActionErrorAlert === true &&
            <div className="alert alert-danger text-center upsuccess-alert">
              <i class="fas fa-check-double"></i> &nbsp;&nbsp;
              L'arrêt de l'immersion a échoué.
            </div>
          }
          {/* HIRED */}
          <div className="modal fade" id="showHiredModal" tabindex="-1" role="dialog" aria-labelledby="showHiredModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showHiredModalLabel">Detail sur {this.state.dataPlacement.bakelisteFullname}</h4>
              </div>
              <div className="modal-body">
                <div className="company_container">
                  <span className="item_label">Recruteur : </span> <span className="label_content">{this.state.dataPlacement.company_name}</span>
                </div> <hr />
                <div className="email_container">
                  <span className="item_label">Email : </span> <span className="label_content">{this.state.dataPlacement.bakelisteEmail}</span>
                </div> <hr />
                <div className="phone_container">
                  <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.dataPlacement.bakelistePhone}</span>
                </div> <hr />
                <div className="debut_container">
                  <span className="item_label">Début du placement : </span> <br />
                  <span className="label_content">{this.state.dataPlacement.startFrenchDateFormat}</span>
                </div> <hr />
                <div className="fin_container">
                  <span className="item_label">Fin du placement : </span> <br />
                  <span className="label_content">{this.state.dataPlacement.endFrenchDateFormat}</span>
                </div> <hr />
              </div>
              <div className="modal-footer">

              </div>
            </div>
          </div>
        </div>

        {this.state.successCompleteHireAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            Modification effectuée avec succès.
          </div>
        }
        </div>
      );
        }
}