import React, { Component } from 'react';
import './reportingByBakeliste.css';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import API from '../../variablesGlobales';
import * as Sentry from '@sentry/browser';
//import amplitude from "amplitude-js";
export default class reportingByBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      isPageLoading: false,
      bakelisteIDForReporting: window.sessionStorage.getItem('bakelisteIDForReporting'),
      bakelisteReportingFullname: window.sessionStorage.getItem('bakelisteReportingFullname'),
      comingFrom: window.sessionStorage.getItem('redirect_origin'),
      reportingsByBakeliste: [],
      fromDomaineID: window.sessionStorage.getItem('domaineIDForFA'),
      fromDomaineName: window.sessionStorage.getItem('domaineNameForFA'),
      repID: 0,
      repDate: '',
      repTache: '',
      repStatut: '',
      repCommentaire: '',
      isReporting: true
    };
  }

  componentDidMount() {
    this.getReportingsByBakeliste();
    console.log(this.state.comingFrom)
  }

  componentDidCatch(err, info){
    Sentry.captureException(err);
  }

  getReportingsByBakeliste = () => {
    this.setState({
      isPageLoading: true
    });

    axios.get(this.state.api + 'reporting-by-bakeliste/' + this.state.bakelisteIDForReporting)
      .then(res => {
        var data = res.data.data;
        this.setState({
          isPageLoading: false
        })
        if (data !== undefined) {
          this.setState({
            reportingsByBakeliste: data,
          });
        } else {
          console.log(res)
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isPageLoading: false
        })
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  statusFormatter = (cell, row) => {
    return (
      <span>
        {cell === 'A Faire' &&
          <span className="label label-danger">{cell}</span>
        }
        {cell === 'En Cours' &&
          <span className="label label-warning">{cell}</span>
        }
        {cell === 'Terminé' &&
          <span className="label label-success">{cell}</span>
        }
      </span>
    );
  }

  getReportingInfos = (reporting) => {
    console.log(reporting);

    if(this.state.isReporting === true){
      this.setState({
        repID: reporting.id,
        repDate: reporting.frenchDateFormat,
        repTache: reporting.tache,
        repStatut: reporting.status,
        repCommentaire: reporting.commentaire
      })
    }
  }

  tacheReportingFormatter = (cell, row) => {
    return (
      <span className="modal-linky fullname-link" data-toggle="modal" data-target="#reportingItemModal" onClick={() => this.getReportingInfos(row)}> {cell} </span>
    );
   // amplitude.getInstance().logEvent('BAKELISTE_CREATE_TYPE');
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }


  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
        
    );
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-reporting-by-bakeliste">
          <Menu />
          <div className="reportingdBakelisteRow row" id="reportings-row">
            <div className="col-lg-12">
              <h1 className="page-header">
                <span className="page-title">Reporting de {this.state.bakelisteReportingFullname} </span>
                <div className="pull-right">
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard-admin">Accueil</Link>
                    </li>
                    {this.state.comingFrom === 'list-bakeliste' &&
                        <li className="">
                          <NavLink to="/liste-bakelistes"  activeClassName="active">Liste des bakelistes</NavLink>
                        </li>
                    }
                    {this.state.comingFrom === 'list-bakeliste-by-coach' &&
                        <li className="">
                          <NavLink to="/liste-coach"  activeClassName="active">Liste des coachs</NavLink>
                        </li>
                    }
                    {this.state.comingFrom === 'list-bakeliste-by-domaine' &&
                        <li className="">
                          <NavLink to={'/liste-bakelistes-by-domaine/' + this.state.fromDomaineID}  activeClassName="active">Bakeliste en {this.state.fromDomaineName}</NavLink>
                        </li>
                    }
                    
                    <li className="active">Bakeliste reporting</li>
                  </ol>
                </div>
              </h1>
            </div>
            <div className="col-md-12 reporting-container">
              <div className="breportings_container">
                <div className="table_container">
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Tâches</th>
                        <th scope="col">Statuts</th>
                        <th scope="col">Commentaires</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="component-reporting-by-bakeliste">
        <Menu />
        <div className="reportingdBakelisteRow row" id="reportings-row">
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">Reporting de {this.state.bakelisteReportingFullname} </span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard-admin">Accueil</Link>
                  </li>
                  {this.state.comingFrom === 'list-bakeliste' &&
                        <li className="">
                          <NavLink to="/liste-bakelistes"  activeClassName="active">Liste des bakelistes</NavLink>
                        </li>
                    }
                    {this.state.comingFrom === 'list-bakeliste-by-coach' &&
                        <li className="">
                          <NavLink to="/liste-coach"  activeClassName="active">Liste des coachs</NavLink>
                        </li>
                    }
                    {this.state.comingFrom === 'list-bakeliste-by-domaine' &&
                        <li className="">
                          <NavLink to={'/liste-bakelistes-by-domaine/' + this.state.fromDomaineID}  activeClassName="active">Bakeliste en {this.state.fromDomaineName}</NavLink>
                        </li>
                    }
                  <li className="active">Bakeliste reporting</li>
                </ol>
              </div>
            </h1>
          </div>
          <div className="col-md-12 reporting-container">
            <div className="breportings_container">
              <div className="table_container">
                <BootstrapTable data={this.state.reportingsByBakeliste}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  pagination
                  search={true}>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='frenchDateFormat' width='200' dataSort={true}>Date</TableHeaderColumn>
                  <TableHeaderColumn dataField='tache' dataFormat={this.tacheReportingFormatter.bind(this)}>Tache</TableHeaderColumn>
                  <TableHeaderColumn dataField='status' width='100' dataFormat={this.statusFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>
                  <TableHeaderColumn dataField='commentaire'>Commentaire</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>

            {/* Show reporting infos by admin */}
            <div className="modal fade" id="reportingItemModal" tabIndex="-1" role="dialog" aria-labelledby="reportingItemModalLabel">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title" id="reportingItemModalLabel">Reporting</h4>
                  </div>
                  <div className="modal-body">
                    <div className="reporting-infos-container">
                      <div className="repDate_container">
                        <span className="item_label">Date : </span> <br />
                        <span className="label_content">{this.state.repDate}</span>
                      </div> <hr />
                      <div className="repTache_container">
                        <span className="item_label">Tâche : </span> <br />
                        <span className="label_content">{this.state.repTache}</span>
                      </div> <hr />
                      <div className="tacheState_container">
                        <span className="item_label">Statut : </span> <br />
                        {this.state.repStatut === 'A Faire' &&
                          <span className="label_content label label-danger">A Faire</span>
                        }
                        {this.state.repStatut === 'En Cours' &&
                          <span className="label_content label label-warning">En Cours</span>
                        }
                        {this.state.repStatut === 'Terminé' &&
                          <span className="label_content label label-success">Terminé</span>
                        }
                      </div> <hr />
                      <div className="repCommentaire_container">
                        <span className="item_label">Commentaire : </span> <br />
                        {this.state.repCommentaire !== '' ?
                          <span className="label_content">{this.state.repCommentaire}</span>
                          :
                          <span className="label_content badge"> Aucun commentaire </span>
                        }
                      </div> <hr />
                    </div>
                  </div>
                  {/* <div className="modal-footer">

                  </div> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
