import React, { Component } from 'react';
import './bakelisteCvView.css'
import Menu from '../menu/Menu';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
//import $ from 'jquery';


export default class bakelisteCvView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      cvthequeID: 0,
      bakelisteID: 0,
      cv: [],
      isCvthequeEmpty: false,
      isProjectEmpty: false,
      isEducationEmpty: false,
      isPortfolioEmpty: false,
      isTecnologyEmpty: false,
      isLanguageEmpty: false,
      isEmptyCV: false,
      cvinfos: [],
      cvprojects: [],
      cveducation: [],
      cvportfolio: [],
      cvtechnology: [],
      cvlanguague: [],
      validationInProgress: false,
      isValidationCVSuccessAlert: false,
      isValidationCVRequestError: false,

    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    this.setState({
      bakelisteID: params.bakeliste_id
    })
    window.sessionStorage.setItem('bakelisteIDForShowingCV', params.bakeliste_id);
    window.sessionStorage.setItem('redirectionLabel', params.label)
    console.log(this.state.bakelisteID);
    console.log(window.sessionStorage.getItem('bakelisteIDForShowingCV'));
    this.getBakelisteCV(window.sessionStorage.getItem('bakelisteIDForShowingCV'))
  }

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === 'successValidation') {
        this.setState({
          isValidationCVSuccessAlert: false
        })
      }
      if (label === 'errorValidation') {
        this.setState({
          isValidationCVRequestError: false
        })
      }
    }, 5000)
  }

  getBakelisteCV = (bakeliste_id) => {

    axios.get(this.state.api + 'cvtheques/' + bakeliste_id)
      .then(response => {

        if (response.data.success === true) {
          console.log('ok')
          //var data = this.state.cv;
          //var data = [];
          if (response.data.cvthequeData.length === 0) {
            this.setState({
              isCvthequeEmpty: true
            })
          } else {
            this.setState({
              cvinfos: response.data.cvthequeData,
              cvMotivationsValue: response.data.cvthequeData.cv_motivations
            })
          }
          console.log(response.data.cvthequeData)
          if (response.data.cvprojectsData.length === 0) {
            this.setState({
              isProjectEmpty: true
            })
          } else {
            this.setState({
              cvprojects: response.data.cvprojectsData
            })
            console.log(this.state.cvprojects)
          }
          if (response.data.cveducationData.length === 0) {
            this.setState({
              isEducationEmpty: true
            })
          } else {
            this.setState({
              cveducation: response.data.cveducationData
            })
            //console.log(response.data.cveducationData)
          }
          if (response.data.cvportfolioData.length === 0) {
            this.setState({
              isPortfolioEmpty: true
            })
          } else {
            this.setState({
              cvportfolio: response.data.cvportfolioData
            })
            console.log(response.data.cvportfolioData)
          }
          if (response.data.cvtechnologiesData.length === 0) {
            this.setState({
              isTecnologyEmpty: true
            })
          } else {
            this.setState({
              cvtechnology: response.data.cvtechnologiesData
            })

            //console.log(response.data.cvtechnologiesData)
          }
          if (response.data.cvlanguagesData.length === 0) {
            this.setState({
              isLanguageEmpty: true
            })
          } else {
            this.setState({
              cvlanguague: response.data.cvlanguagesData
            })
          }

        } else {
          this.setState({
            isEmptyCV: true,
            isCvthequeEmpty: true
          })
        }
      })
      .catch(error => {
        console.log(error);

      })
  }

  /**
   * Cv validation method
   */
  onValidateCV = (cvID) => {
    this.setState({
      validationInProgress: true
    })

    console.log(cvID)
    axios.post(this.state.api + 'cv-bakeliste-done-validation/' + cvID)
      .then(response => {
        if (response.data.success === true) {
          this.getBakelisteCV(window.sessionStorage.getItem('bakelisteIDForShowingCV'))
          this.setState({
            validationInProgress: false,
            isValidationCVSuccessAlert: true
          })
          this.handleShowAndHideAlert('successValidation')
        } else {
          this.setState({
            validationInProgress: false,
            isValidationCVRequestError: true
          })
          console.log(response)
          this.handleShowAndHideAlert('errorValidation')
        }
      })
      .catch(error => {
        this.setState({
          validationInProgress: false,
          isValidationCVRequestError: true
        })
        this.handleShowAndHideAlert('errorValidation')
      })
  }


  // Render Component
  render() {
    return (
      <div className="component-bakeliste-cv-view-by-coach">

        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>CV Viewer</h1>
            <ol className="breadcrumb">
              <li><NavLink to="#"><i className="fa fa-dashboard"></i> Home</NavLink></li>
              {window.sessionStorage.getItem('redirectionLabel') === 'admin-view' &&
                <li><NavLink to="/liste-bakelistes-recrutables"><i className="fa fa-dashboard"></i> Liste bakelistes recrutables</NavLink></li>
              }
              <li className="active">CV Viewer</li>
            </ol>
          </section>

          <section className="content">
            <div className="validation-btn-container">
              {this.state.cvinfos.is_done === 1 ?
                // <button className="btn btn-success volkeno-green-bcolor validation-btn">
                //   <i className="fas fa-check-double"></i>
                // </button>
                ''
                :
                !this.state.validationInProgress ?
                  <button className="btn btn-success volkeno-orange-bcolor validation-btn"
                    onClick={(e) => window.confirm("Êtes-vous sûr de vouloir valider le CV ?") &&
                      this.onValidateCV(this.state.cvinfos.id)}>
                    <i className="fas fa-clipboard-check"></i>
                  </button>
                  :
                  <button className="btn btn-success volkeno-orange-bcolor validation-btn">
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
              } 
            </div>

            <div className="col-md-12 bakeliste_cv_content">
              {!this.state.isCvthequeEmpty && // test si cvtheque est à false
                <div className="row cv_row">
                  <div className="col-md-12 cvprojects">
                    <div className="cv_name">
                      <h2 className="cv_name_label">{this.state.cvinfos.cv_name} &nbsp;
                      {this.state.cvinfos.is_done === 1 ?
                          <span className="label label-success"> CV validé </span>
                          :
                          <span className="label label-danger"> CV non validé </span>
                        }
                      </h2>
                    </div>
                    <hr />

                    <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div className="panel panel-default">
                        <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingOne">
                          <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseProjet" aria-expanded="true" aria-controls="collapseOne">
                              Projets
                          </a>
                          </h4>
                        </div>
                        <div id="collapseProjet" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                          <div className="panel-body">
                            <div className="projects_container">
                              {this.state.isProjectEmpty === true &&
                                <div className="empty_container">
                                  <div className="empty_projects alert alert-warning">
                                    Aucun projet renseigné.
                                </div>
                                </div>
                              }

                              {this.state.isProjectEmpty === false &&
                                <div className="list_projects_row">
                                  {this.state.cvprojects.map((item, indexe) =>
                                    <div key={indexe} className="list_projects_container col-md-12">
                                      <h5 className="project_name_label">Nom du projet : {item.project_name} </h5>
                                      <h6 className="project_technologies_label"> {item.project_technologies} </h6>
                                      {item.taches.length === 0 ?
                                        <div>
                                          <i>Tâches non renseignées.</i>

                                        </div>
                                        :
                                        <div className="list_taches_container">
                                          <ul>
                                            {item.taches.map((tache, ind) =>
                                              <li className="tache_item"> {tache.tache_content} </li>
                                            )}
                                          </ul>
                                        </div>
                                      }
                                      <hr />
                                    </div>
                                  )}

                                </div>
                              }

                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Education panel */}
                      <div className="panel panel-default">
                        <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingTwo">
                          <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEducation" aria-expanded="true" aria-controls="collapseEducation">
                              Education
                          </a>
                          </h4>
                        </div>
                        <div id="collapseEducation" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                          <div className="panel-body">
                            {this.state.isEducationEmpty === true &&
                              <div className="empty_container">
                                <div className="empty_education alert alert-warning">
                                  Education non renseignée.
                                </div>
                              </div>
                            }

                            {/* Render education s'il n'est pas vide */}
                            {this.state.isEducationEmpty === false &&
                              <div className="list_educations_row">
                                {this.state.cveducation.map((educ, i) =>
                                  <div className="col-md-12 education_item_container">
                                    <span className="diplome_label"> {educ.diplome_label}, </span>
                                    <span className="school_container"> {educ.school} - </span>
                                    <i className="diplome_container"> {educ.diplome} </i> <br />
                                    <span className="ville_pays"> {educ.ville} - {educ.pays} </span>
                                    <hr />
                                  </div>
                                )}
                              </div>
                            }


                          </div>
                        </div>
                      </div>

                      {/* Render Section CV Portfolio */}
                      <div className="panel panel-default">
                        <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingThree">
                          <h4 className="panel-title">
                            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsePortfolio" aria-expanded="true" aria-controls="collapsePortfolio">
                              Portfolio
                          </a>
                          </h4>
                        </div>
                        <div id="collapsePortfolio" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                          <div className="panel-body">
                            {this.state.isPortfolioEmpty === true &&
                              <div className="empty_container">
                                <div className="empty_portfolio alert alert-warning">
                                  Le Portfolio est vide.
                                    </div>
                              </div>
                            }

                            {/* Render portfolio s'il n'est pas vide */}
                            {this.state.isEducationEmpty === false &&
                              <div className="list_portfolio_row row">
                                {this.state.cvportfolio.map((port, ind) =>
                                  <div key={ind} className="portfolio_item_container">
                                    <div className="col-md-4 portfolio_item_container">
                                      <div className="panel panel-warning">
                                        <div className="panel-heading volkeno-orange-bcolor"> {port.projectName} </div>
                                        <div className="panel-body">
                                          <img src={port.img_path} alt={port.projectName} className="img_responsive portfolio_img" />
                                        </div>
                                        <div className="panel-footer" data-toggle="modal" data-target="#showPrtfolioItemModal" onClick={(e) => this.getPortfolioInfos(port)}>
                                          <i className="fas fa-eye fa-2x"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            }


                          </div>
                        </div>
                      </div>

                      {/* Render CV Motivation panel */}
                      <div className="panel panel-default">
                        <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingFour">
                          <h4 className="panel-title">
                            <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseMotivation" aria-expanded="false" aria-controls="collapseFour">
                              Motivations
                        </a>
                          </h4>
                        </div>
                        <div id="collapseMotivation" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                          <div className="panel-body">
                            <div className="cv_motivations_container">
                              {/* Render alert si le bakeliste n'a pas encore écrit ses motivations */}
                              {this.state.cvinfos.cv_motivations === null &&
                                <div className="empty_motivations_row row">
                                  <div className="col-md-12 error-alert-container alert alert-warning">
                                    La motivation n'est pas encore renseignée.
                                </div>
                                </div>
                              }

                              {/* Render motivations si le champ n'est pas vide */}
                              {this.state.cvinfos.cv_motivations !== null &&
                                <div className="motivations_content_row row">
                                  <div className="motivations_item_container">
                                    <div className="col-md-12 motivations_item">
                                      <p>
                                        {this.state.cvinfos.cv_motivations}
                                      </p>
                                    </div>
                                  </div> <hr />

                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Render Technologies et Languagues panel */}
                      <div className="panel panel-default">
                        <div className="panel-heading volkeno-green-bcolor" role="tab" id="headingFive">
                          <h4 className="panel-title">
                            <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTechnoLang" aria-expanded="false" aria-controls="collapseFive">
                              {this.state.cvinfos.bakelisteDomaineName === 'Programmation' ?
                                <span>Technologies et Langues</span>
                                :
                                <span>Outils et Langues</span>
                              }
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTechnoLang" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                          <div className="panel-body">
                            <div className="techno_lang_row row">
                              <div className="col-md-6 techno_container">
                                <h6 className="text-center techno_lang_title">
                                  {this.state.cvinfos.bakelisteDomaineName === 'Programmation' ?
                                    <span>Technologies</span>
                                    :
                                    <span>Outils</span>
                                  }
                                </h6>
                                {this.state.isTecnologyEmpty === true &&
                                  // Render technology si les données sont nulles
                                  <div className="empty_techno_row">
                                    <div className="col-md-12 error-alert-container alert alert-warning">
                                      Technologies non renseignées.
                                </div>
                                  </div>
                                }

                                {/* Render techno si le champ n'est pas vide */}
                                {this.state.isTecnologyEmpty === false &&
                                  <div className="techno_content_row row">
                                    <div className="techno_item_container">
                                      <div className="col-md-12 techno_item">
                                        <ul className="list_techno_ul">
                                          {this.state.cvtechnology.map((techno, idx) =>
                                            <li key={idx} className="techno_li"> {techno.techno_name} </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div> <hr />

                                  </div>
                                }

                              </div>

                              <div className="col-md-6 lang_container">
                                <h6 className="text-center techno_lang_title">
                                  Langues
                            </h6>
                                {this.state.isLanguageEmpty === true &&
                                  // Render technology si les données sont nulles
                                  <div className="empty_lang_row">
                                    <div className="col-md-12 error-alert-container alert alert-warning">
                                      Langues non renseignées
                                  </div>
                                  </div>
                                }

                                {/* Render lang si le champ n'est pas vide */}
                                {this.state.isLanguageEmpty === false &&
                                  <div className="lang_content_row row">
                                    <div className="lang_item_container">
                                      <div className="col-md-12 lang_item">
                                        <ul className="list_lang_ul">
                                          {this.state.cvlanguague.map((lang, ix) =>
                                            <li key={ix} className="lang_li"> {lang.language_name} ( {lang.languague_label} ) </li>
                                          )}
                                        </ul>
                                      </div>
                                    </div>

                                  </div>
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              }

              {/* Show portfolio item modal */}
              <div className="modal fade" id="showPrtfolioItemModal" tabindex="-1" role="dialog" aria-labelledby="showPrtfolioItemModalLabel">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                      <h4 className="modal-title" id="myModalLabel"> {this.state.portProjectName} </h4>
                    </div>
                    <div className="modal-body">
                      <img src={this.state.imgPathValue} alt="" className="img-responsive image_item" />
                    </div>
                    <div className="modal-footer">
                      {/* <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Success actions toast confirmation */}
        {this.state.isValidationCVSuccessAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            La validation du CV a été bien effectuée.
            </div>
        }

        {/* Error actions toast confirmation */}
        {this.state.isValidationCVRequestError === true &&
          <div className="alert alert-danger text-center toast-error-alert">
            La validation du CV a été bien effectuée.
            </div>
        }
      </div>
    );
  }
}
