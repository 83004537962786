import React, { Component } from 'react';
import './bakelisteProfile.css';
import './../../../node_modules/semantic-ui-css';
import './../../../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css';
// import Loader from 'react-loader-spinner';
import { NavLink } from 'react-router-dom';
import Menu from '../menu/Menu';
import axios from 'axios';
import API from '../../variablesGlobales';
import * as Sentry from '@sentry/browser';
export default class BakelisteProfile extends Component {
  constructor(props) {
    super(props);
   
    
    this.state = {
      isPageLoading: true,
      api: API.API,
      tacheDoneCount: 0,
      userFullName: window.sessionStorage.getItem('userFullName'),
      bakeliste_id: window.sessionStorage.getItem('user_id'),
      bakelisteDomaine:window.sessionStorage.getItem('user_domaine'),
      rencontreID: window.sessionStorage.getItem('rencontreIDForRedirect'),
     domaines:[],
     reporting:[],
     context:[],
     userBio: window.sessionStorage.getItem('user_bio'),
    nameDomain:null,
    cvthequeID: 0,
    cv:[],
    cvinfos: [],
      cvprojects: [],
      cveducation: [],
      cvportfolio: [],
      cvtechnology: [],
      cvlanguague: [],
      tache:[],
      participant:[],
      IDparticipant:0,
      detailsRencontre:[],
      rencontre:[],
      event:[],
      id_rencontre:0,
      participants_event:[],
      allBakeliste:[],
      DomBakeliste:[],
      rencontre_event:[],
      rencontre_name:''


      
      

    };
    
  }

  componentDidMount = () => {
    console.log('dom',this.state.bakelisteDomaine)
    this.onGetBakelisteProfile();
    this.getTachesCountByBakeliste();
   // this.getAllDomaines();
    this.getReportingByBakeliste();
   this.getcvByBakeliste();
   // this.getcvTheque();
    this.getnbrContext();
    this.getEvenement();
    this.getBakelisteEnFormation();
    this.onGetparticipantsList();
   
   
  }
  getAllDomaines = () => {
    axios.get(this.state.api + 'domaines')
            .then(res => {
                if(res.data.success){
                    const domains = res.data;
                    this.setState({
                        domaines: domains.data,
                    });
                   // console.log(this.state.domaines,"domaineid")
                }else{
                    console.log(res)
                }
            })
            .catch(error => {
                console.log(error.message)
            })
            
}

  onGetBakelisteProfile = () => {
    
    
  axios.get(this.state.api + `liste-des-bakelistes-en-formation`)
  .then(res => {
      var data = res.data.data;
      if (data !== undefined) {
        axios.get(this.state.api + 'domaines')
        .then(res => {
            if(res.data.success){
                const domains = res.data;
                this.setState({
                   domaines: domains.data,
                });
          this.setState({  
             DomBakeliste: data,
              //listBakeliste:data
          });
       
  //this.getAllDomaines();
  const domBakeliste = this.state.DomBakeliste;
  const list1 = this.state.domaines
 // console.log(list1,"dom")
  for (let index of  domBakeliste) {
       
         console.log(index.bakeliste_domaine_id,"IDdomaine moussa")
         for (let item of list1) {
           // console.log(item.id,"ID moussa")
              if (index.bakeliste_domaine_id === item.id) {
               console.log('true')
               this.setState({
               nameDomain:item.name
              })
             //  console.log(this.state.nameDomain,"domaineNmae")
               }
          }
       }
      
        
      }
    //  console.log(this.state.DomBakeliste, "lolo")
    //  console.log(this.state.domaines,"lorem")
    }
        )   
      }
    })

  .catch(error => {
      if (process.env.NODE_ENV !== 'production') {
          return;
      }
    //  Sentry.captureException(error);
  })

  }
  // compter le numbre de tache assigne a un bakelist
  getTachesCountByBakeliste = () => {
    let bakeliste_id = this.state.bakeliste_id;

    axios.get(this.state.api + 'count-taches-by-bakeliste/' + bakeliste_id)
      .then(response => {
       // console.log('feedback', response.data)
        if(response.data.success === true){
          this.setState({
            //tacheTodoCount: response.data.datatodo,
           // tacheDoingCount: response.data.datadoing,
            tacheDoneCount: response.data.datadone
          })
        }
      })
  }
  getReportingByBakeliste = () => {
    this.setState({
        isLoadingBakelisteData: true
    })
    axios.get(this.state.api + `reporting-by-bakeliste/${this.state.bakeliste_id}`)
        .then(res => {
          this.setState({
            reporting: res.data.data
          })
           
        })
        .catch(error => {
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            //Sentry.captureException(error);
        })
        console.log(this.state.recuReporting,"toto")
}

getcvByBakeliste = () => {
  axios.get(this.state.api + 'cvtheques/' + this.state.bakeliste_id)
      .then(response => {
        if (response.data.success === true) {
          console.log('ok')
          //var data = this.state.cv;
          //var data = [];
          if (response.data.cvthequeData.length === 0) {
            this.setState({
              isCvthequeEmpty: true
            })
          } else {
            this.setState({
              cvinfos: response.data.cvthequeData,
              cvMotivationsValue: response.data.cvthequeData.cv_motivations
            })
          }
         // console.log(response.data.cvthequeData)
          if (response.data.cvprojectsData.length === 0) {
            this.setState({
              isProjectEmpty: true
            })
          } else {
            this.setState({
              cvprojects: response.data.cvprojectsData
              
            })
          //  console.log(this.state.cvprojects,"projet")
          }
          if (response.data.cveducationData.length === 0) {
            this.setState({
              isEducationEmpty: true
            })
          } else {
            this.setState({
              cveducation: response.data.cveducationData
            })
           // console.log(response.data.cveducationData,"alou")
          }
          if (response.data.cvportfolioData.length === 0) {
            this.setState({
              isPortfolioEmpty: true
            })
          } else {
            this.setState({
              cvportfolio: response.data.cvportfolioData
            })
            console.log(response.data.cvportfolioData)
          }
          if (response.data.cvtechnologiesData.length === 0) {
            this.setState({
              isTecnologyEmpty: true
            })
          } else {
            this.setState({
              cvtechnology: response.data.cvtechnologiesData
            })

            //console.log(response.data.cvtechnologiesData)
          }
          if (response.data.cvlanguagesData.length === 0) {
            this.setState({
              isLanguageEmpty: true
            })
          } else {
            this.setState({
              cvlanguague: response.data.cvlanguagesData
            })
           // console.log(this.state.cvlanguague,"langue")
          }

        } else {
          this.setState({
            isEmptyCV: true,
            isCvthequeEmpty: true
          })
        }
      })
      .catch(error => {
        console.log(error);

      })
}

getnbrContext=()=>{
  axios.get(this.state.api + `textual-contents-by-bakeliste/${this.state.bakeliste_id}`)
  .then(response =>{
    
    this.setState({
      textualContentInProgress: false
    })

    if(response.data.success){
      this.setState({
        context: response.data.data
      })
     
    }
    
  })
}

getBakelisteEnFormation =()=> {
 
  axios.get(this.state.api + `bakeliste-by-id/`+ this.state.bakeliste_id)
      .then(res => {
          if(res.data.success){
              let bakelistes = res.data.data;
              // console.log(bakelistes)
              this.setState({
                  allBakeliste: bakelistes
                  
              });
              this.getEvenement();
          console.log(this.state.allBakeliste,"liste")
          }})
      .catch(error => {
          
         
          if (process.env.NODE_ENV !== 'production') {
             return;
          }
          Sentry.captureException(error);
      })
    
  }
 
  onGetparticipantsList = () => {
    var api = this.state.api;
    axios.get(api + 'participants')
      .then(response => {
        if (response.data.success) {

          this.setState({
            participant: response.data.data
          })
        }
       this.getEvenement();
       // this.getBakelisteEnFormation();
        //console.log(this.state.participant,"participant") 
      })
      .catch(error => {
        //console.log(error.message)
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }
 
    getEvenement = () =>{
  
      const allBakeliste = this.state.allBakeliste;
      const list1 = this.state.participant;
     // const Bakeliste_id = this.state.bakeliste_id;
              for (let item of list1) {
                  if ( allBakeliste.email === item.email) {
                    
                     this.state.rencontre_event.push(item)
                  }
              }
         
        // console.log('rencontre ',this.state.rencontre_event)
     }
  
  render() {
    return (
      <div className="component-bakeliste-profile">
        <Menu />
        <div className="row bakeliste-profile-row">
          <div className="col-md-8 bakeliste-profile-container">
            {/* Header */}
            <header id="bp_header">
              <div className="bp_couverture_container">
                &nbsp;
                </div>
              <div className="user_avatar-container">
                <img src={require('./../../images/wagaan_head.png')} alt="Avatar" className="bp_avatar_img" />
              </div>
              <div className="bp_first_infos_container">
                <div className="bp_fullname_container">
                  <h1 className="bp_fullname"> {this.state.userFullName}</h1>
                </div>
                <div className="bp_profile_title_container">
    <h2 className="bp_profile_title">{this.state.nameDomain}</h2>
                </div>
              </div>
            </header>

            {/* Section Biographie */}
            <section id="section-bio">
             
              <div className="bio_container">
                <h3 className="section-title"> Biographie </h3>
                <p>
                  {this.state.userBio}
                 
                  </p>
              </div>
            </section>

            {/* Section Tableau de bord */ }
            <section id="section-tb">
              <div className="tb_container">
                <h3 className="section-title"> Tableau de bord </h3>
                <div className="row tb_row">
                  <div className="tb_container">
                    <div className="col-md-4 tb_col_item">
                      <div className="tb_count">{this.state.tacheDoneCount}</div>
                      <div className="tb_count_label"> Tâches terminées </div>
                    </div>
                    <div className="col-md-4 tb_col_item">
                      <div className="tb_count"> {this.state.cvprojects.length } </div>
                      <div className="tb_count_label"> Nombre de projets réalisés </div>
                    </div>
                    <div className="col-md-4 tb_col_item">
                      <div className="tb_count"> {this.state.context.length} </div>
                      <div className="tb_count_label"> Contenus textuels publiés </div>
                    </div>
                    <div className="col-md-4 tb_col_item">
                      <div className="tb_count"> 5</div>
                      <div className="tb_count_label"> Mois de formation </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Section Activités (Reporting) */}
            <section id="section-activities">
            
              <div className="activities_container">
                <h3 className="section-title"> Activités </h3>
                { this.state.reporting.map((num) =>
                <div className="activities_item_container" key={num.id}>
                 {num.tache}
            
                </div>
                )
  }
                <div className="activities_item_container section-footer">
                  <NavLink to="#">Voir tout le reporting</NavLink>
                </div>
              </div>
            </section>

            {/* Section Expériences */}
            <section id="section-exp">
              <div className="exp_container">
                <h3 className="section-title"> Expériences </h3>
                {this.state.cvprojects.map((item, indexe) =>
                <div className="exp_item_container" key={indexe} >
                  <h3 className="project_name"> {item.project_name}</h3>
                <div className="techno_tools">{item.project_technologies}</div>
                {item.taches.length === 0 ?
                <p></p>:
                  <div className="task_container">
                  
                    <ul className="task_list">
                    {item.taches.map((tache, ind) =>
                      <li className="task_list_item">{tache.tache_content}</li>
                    
                    )}
                    </ul>
                  </div>
                    }
                    <hr/>
                </div>
              
                )}
             
              </div> 
            </section>

            {/* Section Education */}
            <section id="section-educ">
              <div className="educ_container">
                <h3 className="section-title"> Education </h3>
                { this.state.cveducation.map((index) =>
               
                <div className="educ_content_container" key={index.id}>
                  
                  <div className="degree_label">{index.diplome_label}</div>
                  <div className="shool_degree_name_label">
                    <span>{index.school} - </span>
                    <span className="degree_name"> {index.diplome} </span>
                  </div>

                  <div className="country_town_container">
                    <span className="town_label"> {index.ville} - </span>
                    <span className="country_label"> {index.pays} </span>
                  </div>
                  <hr/>
                </div>
                
                )}
                
              </div> 
                
                
            
            </section>

            {/* Section skills */}
            <section id="section-skills">
              <div className="skills_container">
                <h3 className="section-title"> Compétences </h3>
                <div className="skills_item_container">
                { this.state.cvtechnology.map((index) =>
                <ul className="task_list" key={index.id}>
                <li className="task_list_item">{index.techno_name}</li>
                
                </ul>
                )}
                </div>
               
              </div>
            </section>

            {/* Section Extra */}
            <section id="section-extra">
              <div className="extra_container">
                <h3 className="section-title"> Langues et Activités extra professionnelles </h3>
                <div className="row ext-row">
                  <div className="col-md-6 langue_container">
                    <h5 className="extra_title">Langues</h5>
                    { this.state.cvlanguague.map((index) =>
                    <ul className="langue_list" key={index.id}>
                      <li>{index.language_name}  ({index.languague_label})</li>
                      
                    </ul>
                    )}
                  </div>
                  <div className="col-md-6 exp_container">
                    <h5 className="extra_title">Activités extra-professionnelles</h5>
                    <ul className="ep_list">
                      <li>Président de l'Association des Bakelistes de Dakar</li>
                      <li>Président de l'Association des Entrepreneurs</li>
                      <li>SG de l'Association des Volkan de Dakar</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

             {/* Section Porfolio */}
             <section id="section-extra">
              <div className="extra_container">
              <h3 className="section-title"> Porfolio </h3>
               <div className="form_container row">
                            
                              <div className="list_portfolio_row row">
                              {this.state.cvportfolio.map((port, ind) =>
                                <div key={ind} className="portfolio_item_container">
                                  <div className="col-md-4 portfolio_item_container">
                                    <div className="panel panel-warning">
                                      <div className="panel-heading volkeno-orange-bcolor"> {port.projectName} </div>
                                      <div className="panel-body">
                                        <img src={port.img_path} alt={port.projectName} className="img_responsive portfolio_img" />
                                      </div>
                                      
                                    </div>
                                  </div>
                                </div>
                              )}
                              </div>
              </div>
              </div>
            </section>
           
            {/*section evenement */}
            <section id="section-extra">
              
              <div className="extra_container">
                <h3 className="section-title">Evénement(s) Participé(s) </h3>
                
                <div className="row ext-row">
                <div className="col-md-6 langue_container" >
                  {this.state.rencontre_event.map(index=>
                  <ul key={index}>
            <li><h5>{index.rencontre}</h5></li>
                  </ul>
                  )}
                  </div>
               
                  </div>
                  </div>
                  </section>



            {/* Section school modules */}
            <section id="section-module">
              <div className="module_container">
                <h3 className="section-title"> Modules suivis à l'école </h3>
                <div className="module_item_container">
                  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                      <div className="panel-heading volkeno-green-bcolor" role="tab" id="heading1">
                        <h4 className="panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseItem1" aria-expanded="true" aria-controls="collapse1">
                            Développement web
                          </a>
                        </h4>
                      </div>
                      <div id="collapseItem1" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="heading1">
                        <div className="panel-body">
                          <div className="module_content_container">
                            <div className="col-md-6 module_description">
                              <h5 className="module_item_title">Description</h5>
                              <div className="module_description_content">
                                Pariatur ex in tempor esse sit. Tempor dolor commodo veniam cillum voluptate anim ad culpa velit consectetur commodo. Enim cupidatat laborum sit duis sunt. Nisi occaecat pariatur ad proident aliquip culpa aliquip eiusmod proident esse sunt sint. Duis occaecat occaecat Lorem Lorem et cillum eu commodo eu excepteur ut quis in.
                              </div>
                            </div>
                            <div className="col-md-6 module_item_list_container">
                              <h5 className="module_item_title">Contenus du module</h5>
                              <ul className="module_item_list">
                                <li>HTML/CSS</li>
                                <li>Javascript</li>
                                <li>Bootstrap</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="panel panel-default">
                      <div className="panel-heading volkeno-green-bcolor" role="tab" id="heading2">
                        <h4 className="panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseItem2" aria-expanded="true" aria-controls="collapse2">
                            Développement mobile
                          </a>
                        </h4>
                      </div>
                      <div id="collapseItem2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
                        <div className="panel-body">
                          <div className="module_content_container">
                            <div className="col-md-6 module_description">
                              <h5 className="module_item_title">Description</h5>
                              <div className="module_description_content">
                                Pariatur ex in tempor esse sit. Tempor dolor commodo veniam cillum voluptate anim ad culpa velit consectetur commodo. Enim cupidatat laborum sit duis sunt. Nisi occaecat pariatur ad proident aliquip culpa aliquip eiusmod proident esse sunt sint. Duis occaecat occaecat Lorem Lorem et cillum eu commodo eu excepteur ut quis in.
                              </div>
                            </div>
                            <div className="col-md-6 module_item_list_container">
                              <h5 className="module_item_title">Contenus du module</h5>
                              <ul className="module_item_list">
                                <li>Java</li>
                                <li>Android</li>
                                <li>Swift</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
      </div>
    );
  }
}