import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import API from "../../variablesGlobales";
import axios from "axios";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import Menu from "./../menu/Menu";
//import * as Sentry from '@sentry/react';
import $ from "jquery";
import Editor from "../editeurTexte/editeurTexte";
import "../MenuBakeliste/Production.css";
import amplitude from "amplitude-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Amplitude } from "@amplitude/react-amplitude";
import { logToConsole } from "pusher-js";
export default class Production extends Component {
  data = [];
  static defaultProps = {
    required: true,
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);

    this.updateTBBHandle = this.updateTBBHandle.bind(this);
    this.updateTacheByBakeliste = this.updateTacheByBakeliste.bind(this);
    this.getTacheID = this.getTacheID.bind(this);

    this.handleChangeR = this.handleChangeR.bind(this);
    this.onUpdateReportingStatus = this.onUpdateReportingStatus.bind(this);
    this.onUpdateReportingRequest = this.onUpdateReportingRequest.bind(this);
    this.updateHandleChange = this.updateHandleChange.bind(this);
    this.filterList = this.filterList.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.fileInput = React.createRef();
    this.fileUploadHandler = this.fileUploadHandler.bind(this);
    this.state = {
      isPageLoading: false,
      isSendingLivrableInProgress: false,
      isSuccessSending: false,
      isErrorSending: false,
      coach: [],
      tache: [],
      fields: {},
      content: "",
      bakeliste: [],
      loading: true,
      api: API.API,
      assignationEncours: false,
      tachesByCoach: [],
      tachesByCoache: [],
      userStatus: window.sessionStorage.getItem("user_status"),
      userID: window.sessionStorage.getItem("user_id"),
      tache_id: window.sessionStorage.getItem("tache_id"),
      bakeliste_id: window.sessionStorage.getItem("user_id"),
      isAdmin: true,
      isCoach: false,
      bakelistesByCoach: [],
      tachesByBakeliste: [],
      tacheStatusUpdateField: {},
      tacheUpdatedInProgress: false,
      updateTacheID: 0,
      isUpdatedError: false,
      tacheContent: "",
      tacheState: "",
      tacheDuration: "",
      tacheDate: "",
      tacheTodoDate: "",
      tacheCoachID: 0,
      tacheID: 0,
      isTache: true,
      tacheBakelisteID: 0,
      utFields: {},
      utInProgress: false,
      updateTacheStatusError: false,
      assignationSuccessAlert: false,
      isAssignationError: false,
      updateTacheStatusSuccess: false,
      updateTacheRequestSuccessAlert: false,
      updateTacheRequestError: false,
      selectOptions: [],
      selected: [],
      onlySelect: false,
      groupySelect: false,
      noSavedAndSavedTrueAlert: false,
      noSavedData: [],
      reportings: [],
      domains: [],

      reportingEncours: false,
      addReportingError: false,

      currentConnecteduserID: window.sessionStorage.getItem("user_id"),
      coachID: window.sessionStorage.getItem("coach_id"),

      allBakeliste: [],
      listBakeliste: [],
      reportingByBakeliste: [],
      bakelistReportsTab: [],
      bakelistEmptyReportsTab: {
        id: "",
        date: "",
        tache: "",
        status: "",
        commentaire: "",
        using_links: "",
        lieu: "",
        app_name: "",
        technology: "",
        bakeliste_id: "",
        coach_id: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      },
      reporting_id: 0,
      dateValue: "",
      dateFRValue: "",
      tacheValue: "",
      statutValue: "",
      commentaireValue: "",
      using_linksValue: "",
      lieuValue: "",
      appNameValue: "",
      technologyValue: "",
      bakelisteIDValue: 0,
      updateDateValue: "",
      coachIDValue: 0,
      updateFields: {},
      updateReportingPending: false,
      updateReportingID: 0,
      bakelisteAddReportLoading: false,
      currentPage: 1,
      itemPerPage: 10,
      addReportingSuccessAlert: false,
      updateReportingSuccessAlert: false,
      updateReportingStatusSuccessAlert: false,
      isUpdateStatusError: false,
      errors: {},
      emptyDate: false,
      emptyLieu: false,
      emptyStatus: false,
      emptyTache: false,
      isLoadingBakelisteData: false,
      reporting_lastfill: [],
      last_date: 0,
      search: null,
      staatus: "Terminé",
      date: "",
      lieeu: "bakeli",
      app_namee: "name_app",
      technologye: "techno",
      bakeliste_id: window.sessionStorage.getItem("user_id"),
      using_links: "",
      commentaire: "",
      file: "",
      importInProgress: false,
      successImportAlert: false,
      tacheInfos: [],
    };
    this.onSubmitLivrable = this.onSubmitLivrable.bind(this);
  }

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id),
    });
  }

  fadeOutBakelisteReports = (x) => {
    axios
      .get(this.state.api + `reporting-by-bakeliste/${x}`)
      .then((res) => {
        let bakelistReportsTab = res.data.data;
        this.setState({
          bakelistReportsTab,
          bakelistEmptyReportsTab: this.state.bakelistReportsTab,
        });
        //console.log(this.state.bakelistReportsTab, "bakhna")
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  componentDidMount() {
    console.log(this.state.userStatus + " status");
    if (this.state.userStatus === "coach") {
      this.setState({
        isCoach: true,
        isAdmin: false,
      });
      this.getTacheByCoach(this.state.userID);
      console.log(this.getTacheByCoach(this.state.userID), "salut");
    }
    this.getAllTaches();
    this.getTachesAssignedByCoach();
    this.getBakelisteByCoach();
    this.getTachesByBakeliste();

    this.fadeOutBakelisteReports();
    this.getAllReportings();
    this.getBakelisteByCoach();
    this.getReportingByBakeliste();
    this.getAllBakeliste();
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    this.setState({
      allBakeliste: this.state.allBakeliste,
    });
    this.setState({
      isCoach: false,
      isAdmin: false,
      isBakeliste: true,
    });
  }
  componentDidCatch(err, info) {
    // Sentry.captureException(err);
  }

  getAllTaches = () => {
    axios
      .get(this.state.api + "taches")
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.data !== undefined) {
          this.setState({
            tache: res.data.data.reverse(),
          });
        }
        //console.log(this.state.tache);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  getTacheByCoach(coach_id) {
    //console.log(this.state.tachesByCoach + "baxma")
    let tachesByCoach = this.state.tachesByCoach;
    let allTaches = this.state.tache;
    //console.log(allTaches.length)
    if (allTaches.length > 0) {
      // allTaches.forEach((tache, index) => {
      //     if (coach_id === tache.coach_id) {
      //         tachesByCoach.push(tache)
      //     }
      // });
      allTaches.forEach(function (tache) {
        if (coach_id === tache.coach_id) {
          tachesByCoach.push(tache);
        }
      });
    }

    if (tachesByCoach !== undefined) {
      this.setState({
        tachesByCoach: tachesByCoach,
      });
    }
  }

  getTachesAssignedByCoach = () => {
    axios
      .get(this.state.api + `taches-by-coach/${this.state.userID}`)
      .then((res) => {
        if (res.data.data !== undefined) {
          this.setState({
            tachesByCoache: res.data.data.reverse(),
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  // Bakeliste by coach
  getBakelisteByCoach() {
    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.userID)
      .then((response) => {
        var data = response.data.data;
        //console.log('coach bakelistes ', data)
        if (data !== undefined) {
          this.setState({
            bakelistesByCoach: data,
          });
        }
        data.forEach((item) => {
          let infos = {
            label: item.first_name + " " + item.last_name,
            value: item.id,
          };

          this.state.selectOptions.push(infos);
        });
        //console.log(this.state.selectOptions)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  }

  /**
   * Activate only select assignation
   */
  onOnlyAssignation = () => {
    this.setState({
      onlySelect: true,
      groupySelect: false,
    });
  };

  /**
   * Activate groupy select assignation
   */
  onGroupyAssignation = () => {
    this.setState({
      onlySelect: false,
      groupySelect: true,
    });
  };

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "successAssignation") {
        this.setState({
          assignationSuccessAlert: false,
        });
      }
      if (label === "errorAssignation") {
        this.setState({
          isAssignationError: false,
        });
      }
      if (label === "successStatusUpdate") {
        this.setState({
          updateTacheStatusSuccess: false,
        });
      }
      if (label === "tacheRequestSuccess") {
        this.setState({
          updateTacheRequestSuccessAlert: false,
        });
      }
      if (label === "tacheRequestError") {
        this.setState({
          updateTacheRequestError: false,
        });
      }
      if (label === "errorStatusUpdate") {
        this.setState({
          isUpdatedError: false,
          updateTacheStatusError: false,
        });
      }
    }, 5000);
  }

  // assigner une tache a un bakeliste
  assignTache() {
    this.setState({
      assignationEncours: true,
    });

    let data = this.state.fields;

    if (this.state.onlySelect) {
      data["is_only"] = 1;
      data["is_groupy"] = 0;
      data["selected_bakeliste"] = null;
    } else if (this.state.groupySelect) {
      data["is_groupy"] = 1;
      data["is_only"] = 0;
      data["selected_bakeliste"] = this.state.selected;
    }

    console.log(data);

    axios
      .post(this.state.api + "taches", data)
      .then((res) => {
        if (res.data.success === true && res.data.nosavedStatus === false) {
          this.getTachesAssignedByCoach();
          $(".close").click();
          document.getElementById("create-tache-form").reset();
          $("#date_tach").val("");
          this.setState({
            assignationEncours: false,
            assignationSuccessAlert: true,
            fields: {},
          });

          this.handleShowAndHideAlert("successAssignation");
        } else if (
          res.data.success === true &&
          res.data.nosavedStatus === true
        ) {
          this.getTachesAssignedByCoach();
          $(".close").click();
          document.getElementById("create-tache-form").reset();
          $("#date_tach").val("");
          this.setState({
            assignationEncours: false,
            assignationSuccessAlert: true,
            noSavedAndSavedTrueAlert: true,
            noSavedData: res.data.nosaved,
            fields: {},
          });

          this.handleShowAndHideAlert("successAssignation");
        } else if (
          res.data.success === false &&
          res.data.nosavedStatus === true
        ) {
          this.setState({
            assignationEncours: false,
            isAssignationError: true,
          });

          this.handleShowAndHideAlert("errorAssignation");
        } else {
          this.setState({
            assignationEncours: false,
            isAssignationError: true,
          });

          this.handleShowAndHideAlert("errorAssignation");
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          assignationEncours: false,
          isAssignationError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  }

  renderNa(id) {
    if (this.state.tachesByCoach !== undefined) {
      this.state.tachesByCoache.forEach((x) => {
        if (x.id === id) {
          this.setState({
            nameTacheByCoache: x.name,
          });
        }

        //console.log("super" + x.bakeli_user_id);
      });

      return (
        <td>
          <Link to="#">{this.state.nameTache} </Link>
        </td>
      );
    }
  }

  renderName(id) {
    if (this.state.tache !== undefined) {
      this.state.tache.forEach((x) => {
        if (x.id === id) {
          this.setState({
            nameTache: x.name,
          });
        }

        //console.log("super" + x.bakeli_user_id);
      });

      return (
        <td>
          <Link to="#">{this.state.nameTache} </Link>
        </td>
      );
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields["coach_user_id"] = this.state.userID;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
    console.log(fields);
  }

  handleChange1 = (e) => {
    let fields = this.state.fields;

    if (e.target.name === "tache_file") {
      if (this.fileValidation("tache_file")) {
        fields["tache_file"] = e.target.files[0];
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
    console.log(fields);
  };

  // Retourne la liste de toutes les tâches assignées à un bakeliste
  getTachesByBakeliste = () => {
    if (this.state.userStatus === "bakeliste") {
      axios
        .get(this.state.api + "taches-by-bakeliste/" + this.state.userID)
        .then((response) => {
          if (response.data.success === true) {
            let tachesByBakeliste = response.data.data;
            //console.log(tachesByBakeliste)
            if (tachesByBakeliste !== undefined) {
              this.setState({
                tachesByBakeliste: tachesByBakeliste.reverse(),
              });
            }
          } else {
            console.log(response.data.success);
          }
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          //Sentry.captureException(error);
        });
    }
  };

  getTacheID = (tache) => {
    //console.log(tache.id)
    window.sessionStorage.setItem("btache_id", tache.id);
  };

  tacheStateFormatter(cell, row) {
    return (
      <span>
        {row.state === "todo" && (
          <span
            className="label label-danger"
            data-placement="top"
            title="Modifier le statut de la tâche"
            data-toggle="modal"
            data-target="#utModal"
            onClick={() => this.getTacheID(row)}
          >
            {row.state}
          </span>
        )}
        {row.state === "doing" && (
          <span
            className="label label-warning"
            data-placement="top"
            title="Modifier le statut de la tâche"
            data-toggle="modal"
            data-target="#utModal"
            onClick={() => this.getTacheID(row)}
          >
            {row.state}
          </span>
        )}
        {row.state === "done" && (
          <span
            className="label label-success"
            data-placement="top"
            title="Modifier le statut de la tâche"
            data-toggle="modal"
            data-target="#utModal"
            onClick={() => this.getTacheID(row)}
          >
            {row.state}
          </span>
        )}
      </span>
    );
  }

  getTacheInfos = (tache) => {
    // console.log(tache);
    window.sessionStorage.setItem("tacheContent", tache.content);
    window.sessionStorage.setItem("tacheState", tache.state);
    window.sessionStorage.setItem("tacheDuration", tache.duration);

    if (this.state.isTache) {
      this.setState({
        tacheBakelisteID: tache.bakeli_user_id,
        tacheContent: tache.content,
        tacheState: tache.state,
        tacheDuration: tache.duration,
        tacheDate: tache.dateForEdit,
        tacheTodoDate: tache.todo_date,
        tacheCoachID: tache.coach_user_id,
        tacheURL: tache.tache_url,
        tacheID: tache.id,
      });
    }
    //console.log(tache.bakeli_user_id)
  };

  tacheFormatter = (cell, row) => {
    return (
      <span
        className="modal-linky fullname-link"
        data-placement="top"
        title="Visualiser la tâche"
        data-toggle="modal"
        data-target="#tacheInfosModal"
        onClick={() => this.getTacheInfos(row)}
      >
        <linkify> {row.content} </linkify>
      </span>
    );
  };

  updateTBBHandle = (e) => {
    let fields = this.state.tacheStatusUpdateField;
    fields[e.target.name] = e.target.value;

    this.setState({
      tacheStatusUpdateField: fields,
    });
    console.log(fields);
  };

  updateTacheByBakeliste = (e) => {
    e.preventDefault();

    this.setState({
      tacheUpdatedInProgress: true,
    });
    let tacheID = window.sessionStorage.getItem("btache_id");
    //console.log(tacheID)
    let updateData = this.state.tacheStatusUpdateField;

    axios
      .post(
        this.state.api + "update-tache-status-by-bakeliste/" + tacheID,
        updateData
      )
      .then((response) => {
        if (response.data.success === true) {
          this.getAllTaches();
          this.getTachesByBakeliste();
          this.getTachesAssignedByCoach();
          $(".close").click();
          document.getElementById("update-tache-status-bybakeliste").reset();
          this.setState({
            tacheUpdatedInProgress: false,
            updateTacheStatusSuccess: true,
          });

          this.handleShowAndHideAlert("successStatusUpdate");
        } else {
          this.setState({
            isUpdatedError: true,
            tacheUpdatedInProgress: false,
            updateTacheStatusError: true,
          });

          this.handleShowAndHideAlert("errorStatusUpdate");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isUpdatedError: true,
          tacheUpdatedInProgress: false,
          updateTacheStatusError: true,
        });
        this.handleShowAndHideAlert("errorStatusUpdate");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  actionsFormatter = (cell, row) => {
    return (
      <span>
        <button
          onClick={() => {
            this.getTacheInfos(row);
          }}
          id="show_item"
          className="btn btn-info show-item-btn"
          data-toggle="modal"
          data-target="#tacheInfosModal"
        >
          <i className="fas fa-eye"></i>
        </button>{" "}
        &nbsp;&nbsp;
        <button
          onClick={() => {
            this.getTacheInfos(row);
          }}
          id="edit_item"
          className="btn btn-info edit-btn volkeno-orange-bcolor"
          data-toggle="modal"
          data-target="#editTacheModal"
        >
          <i className="fas fa-edit"></i>
        </button>
      </span>
    );
  };

  utHandleChange = (e) => {
    let fields = this.state.utFields;
    fields[e.target.name] = e.target.value;
    fields["bakeli_user_id"] = this.state.tacheBakelisteID;
    fields["coach_user_id"] = this.state.tacheCoachID;
    //console.log(this.state.selected)
    if (e.target.name === "content") {
      this.setState({
        tacheContent: e.target.value,
      });
    }

    if (e.target.name === "tache_url") {
      this.setState({
        tacheURL: e.target.value,
      });
    }

    if (e.target.name === "state") {
      this.setState({
        tacheState: e.target.value,
      });
    }
    if (e.target.name === "duration") {
      this.setState({
        tacheDuration: e.target.value,
      });
    }
    if (e.target.name === "todo_date") {
      this.setState({
        tacheTodoDate: e.target.value,
      });
    }

    if (!fields["content"]) {
      fields["content"] = this.state.tacheContent;
    }
    if (!fields["tache_url"]) {
      fields["tache_url"] = this.state.tacheURL;
    }
    if (!fields["duration"]) {
      fields["duration"] = this.state.tacheDuration;
    }
    if (!fields["state"]) {
      fields["state"] = this.state.tacheState;
    }
    if (!fields["todo_date"]) {
      fields["todo_date"] = this.state.tacheTodoDate;
    }

    this.setState({
      utFields: fields,
    });

    console.log(fields);
  };

  updateTacheByCoach = (e) => {
    e.preventDefault();
    //console.log(this.state.utFields);
    this.setState({
      utInProgress: true,
    });

    let tacheID = this.state.tacheID;
    let updateData = this.state.utFields;

    axios
      .put(this.state.api + "taches/" + tacheID, updateData)
      .then((response) => {
        this.setState({
          utInProgress: false,
        });
        if (response.data.success === true) {
          this.getTachesAssignedByCoach();
          $(".close").click();
          document.getElementById("edit-tache-bycoach").reset();
          this.setState({
            utFields: {},
            updateTacheRequestSuccessAlert: true,
          });
          this.handleShowAndHideAlert("tacheRequestSuccess");
        } else {
          console.log(response);
          this.setState({
            updateTacheRequestError: true,
          });
          this.handleShowAndHideAlert("tacheRequestError");
        }
      })
      .catch((error) => {
        this.setState({
          updateTacheRequestError: true,
        });
        this.handleShowAndHideAlert("tacheRequestError");

        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  coachFullnameFormatter = (cell, row) => {
    return (
      <NavLink to="#" className="bakeliste_coachname">
        {" "}
        {cell}{" "}
      </NavLink>
    );
  };
  // reporting
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
          console.log("[Amplitude] Error: could not load SDK");
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);

    amplitude.getInstance().init(api_key);
  };
  handleShowAndHideAlertR(label) {
    setTimeout(() => {
      if (label === "addSuccessAlert") {
        this.setState({
          addReportingSuccessAlert: false,
        });
      }
      if (label === "updateSuccessAlert") {
        this.setState({
          updateReportingSuccessAlert: false,
        });
      }
      if (label === "updateStatusSuccessAlert") {
        this.setState({
          updateReportingStatusSuccessAlert: false,
        });
      }
      if (label === "addReportErrorAlert") {
        this.setState({
          updateReportingSuccessAlert: false,
        });
      }
      if (label === "updateErrorAlert") {
        this.setState({
          updateReportingError: false,
        });
      }
      if (label === "emptyLieu") {
        this.setState({
          emptyLieu: false,
        });
      }
      if (label === "emptyTache") {
        this.setState({
          emptyTache: false,
        });
      }
      if (label === "emptyDate") {
        this.setState({
          emptyDate: false,
        });
      }
      if (label === "emptyStatus") {
        this.setState({
          emptyStatus: false,
        });
      }
    }, 5000);
  }

  getAllReportings = () => {
    axios
      .get(this.state.api + "reporting_bakelistes")
      .then((res) => {
        let reportings = res.data.data;

        this.setState({
          reportings: reportings,
        });
        //console.log(this.state.reportings);
        //this.getBakelisteNotFillreporting(this.state.reportings);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  getBakelisteByCoach = () => {
    axios
      .get(
        this.state.api +
          `bakelistes-by-coach/${this.state.currentConnecteduserID}`
      )
      .then((res) => {
        var data = res.data.data;
        if (data !== undefined) {
          this.setState({
            bakelistesByCoach: data,
          });
        }

        //console.log(this.state.bakelistesByCoach, "lolo")
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  getAllBakeliste = () => {
    axios
      .get(this.state.api + `liste-des-bakelistes-en-formation`)
      .then((res) => {
        var data = res.data.data;
        if (data !== undefined) {
          this.setState({
            allBakeliste: data,
            listBakeliste: data,
          });
        }

        console.log(this.state.allBakeliste, "lolo");
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  getReportingByBakeliste = () => {
    this.setState({
      isLoadingBakelisteData: true,
    });
    axios
      .get(
        this.state.api +
          `reporting-by-bakeliste/${this.state.currentConnecteduserID}`
      )
      .then((res) => {
        var data = res.data.data;
        console.log(data, "reporting");
        this.setState({
          isLoadingBakelisteData: false,
        });
        if (data !== undefined) {
          this.setState({
            reportingByBakeliste: data.reverse(),
          });
        }
        // console.log(this.state.reportingByBakeliste, "lolo")
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  getReportingInfos(reporting_id) {
    this.setState({
      reporting_id: reporting_id,
    });
  }

  onUpdateReportingStatus = (e) => {
    e.preventDefault();
    this.setState({
      updateReportingStatusInProgress: true,
    });
    var api = API.API;
    console.log(this.state.reporting_id);
    axios
      .post(
        api + "update-reporting-status/" + this.state.reporting_id,
        this.state.fields
      )
      .then((response) => {
        if (response.data.success === true) {
          // this.getReportingByBakeliste();
          $(".close").click();

          this.setState({
            updateReportingStatusSuccessAlert: true,
            updateReportingStatusInProgress: false,
          });

          this.handleShowAndHideAlertR("updateStatusSuccessAlert");
        } else {
          console.log(response);
          this.setState({
            updateReportingStatusInProgress: false,
            isUpdateStatusError: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateReportingStatusInProgress: false,
          isUpdateStatusError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  handleChangeR(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    console.log("handle change", e.target.value);
    fields["bakeliste_id"] = window.sessionStorage.getItem("user_id");
    fields["coach_id"] = window.sessionStorage.getItem("coach_id");
    this.setState({
      fields,
    });
    console.log("handle change", this.state.fields);
  }

  onValidateAddReportingForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let isValidForm = true;

    if (!fields["lieu"]) {
      isValidForm = false;
      this.setState({
        emptyLieu: true,
      });
      this.handleShowAndHideAlert("emptyLieu");
      errors["emptyLieu"] = "*Ce champ est obligatoire";
    }
    if (!fields["tache"]) {
      isValidForm = false;
      this.setState({
        emptyTache: true,
      });
      this.handleShowAndHideAlert("emptyTache");
      errors["emptyTache"] = "*Ce champ est obligatoire";
    }
    if (!fields["date"]) {
      isValidForm = false;
      this.setState({
        emptyDate: true,
      });
      this.handleShowAndHideAlert("emptyDate");
      errors["emptyDate"] = "*Ce champ est obligatoire";
    }
    if (!fields["status"] || fields["status"] === "--Choisir un status--") {
      if (fields["status"] === "--Choisir un status--") {
        fields["status"] = "";
      }
      isValidForm = false;
      this.setState({
        emptyStatus: true,
      });
      this.handleShowAndHideAlert("emptyStatus");
      errors["emptyStatus"] = "*Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  addReporting() {
    console.log("report add", this.state.fields);
    if (this.onValidateAddReportingForm()) {
      this.setState({
        reportingEncours: true,
      });
      var eventProperties = {
        user_email: this.state.email,
      };

      const dataCoach = this.state.fields;
      console.log(dataCoach);
      console.log();
      axios
        .post(this.state.api + "reporting_bakelistes", this.state.fields)
        .then((res) => {
          if (res.status === 200) {
            this.getReportingByBakeliste();
            $(".close").click();
            this.handleShowAndHideAlert("addSuccessAlert");
            amplitude
              .getInstance()
              .logEvent("REPORTING_BAKELISTE_TYPE", eventProperties);
            $("#add-reporting-form")[0].reset();
            this.setState({
              addReportingSuccessAlert: true,
              reportingEncours: false,
              fields: {},
            });
          }
        })
        .catch((error) => {
          this.setState({
            reportingEncours: false,
            addReportingError: true,
          });
          this.handleShowAndHideAlert("addReportErrorAlert");
          amplitude
            .getInstance()
            .logEvent("Reporting_BAKELISTE_Error", eventProperties);
          console.log(error);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          //Sentry.captureException(error);
        });
    }
  }

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  getReportingInfosForUpdated = (reporting) => {
    this.setState({
      updateReportingID: reporting.id,
      dateValue: reporting.date,
      dateFRValue: reporting.date_fr,
      tacheValue: reporting.tache,
      updateDateValue: reporting.forUpdateDateFormat,
      statutValue: reporting.status,
      commentaireValue: reporting.commentaire,
      using_linksValue: reporting.using_links,
      lieuValue: reporting.lieu,
      appNameValue: reporting.app_name,
      technologyValue: reporting.technology,
      bakelisteIDValue: reporting.bakeliste_id,
      coachIDValue: reporting.coach_id,
      isUpdateStatusError: false,
    });
    console.log(reporting);
  };

  updateHandleChange(e) {
    let fields = this.state.updateFields;
    fields[e.target.name] = e.target.value;
    if (e.target.name === "tache") {
      this.setState({
        tacheValue: e.target.value,
      });
    }
    if (e.target.name === "technology") {
      this.setState({
        technologyValue: e.target.value,
      });
    }
    if (e.target.name === "using_links") {
      this.setState({
        using_linksValue: e.target.value,
      });
    }
    if (e.target.name === "app_name") {
      this.setState({
        appNameValue: e.target.value,
      });
    }
    if (e.target.name === "commentaire") {
      this.setState({
        commentaireValue: e.target.value,
      });
    }
    if (e.target.name === "status") {
      this.setState({
        statutValue: e.target.value,
      });
    }
    if (e.target.name === "lieu") {
      this.setState({
        lieuValue: e.target.value,
      });
    }
    if (e.target.name === "date") {
      this.setState({
        updateDateValue: e.target.value,
      });
    }

    if (!fields["tache"]) {
      fields["tache"] = this.state.tacheValue;
    }
    if (!fields["technology"]) {
      fields["technology"] = this.state.technologyValue;
    }
    if (!fields["using_links"]) {
      fields["using_links"] = this.state.using_linksValue;
    }
    if (!fields["app_name"]) {
      fields["app_name"] = this.state.appNameValue;
    }
    if (!fields["commentaire"]) {
      fields["commentaire"] = this.state.commentaireValue;
    }
    if (!fields["status"]) {
      fields["status"] = this.state.statutValue;
    }
    if (!fields["lieu"]) {
      fields["lieu"] = this.state.lieuValue;
    }
    if (!fields["date"]) {
      fields["date"] = this.state.updateDateValue;
    }
    //fields["date"] = this.state.dateValue;
    fields["bakeliste_id"] = this.state.bakelisteIDValue;
    fields["coach_id"] = this.state.coachID;
    this.setState({
      updateFields: fields,
    });

    console.log(fields);
  }

  onUpdateReportingRequest = (e) => {
    e.preventDefault();
    this.setState({
      updateReportingPending: true,
    });
    let updateData = this.state.updateFields;
    axios
      .put(
        this.state.api + "reporting_bakelistes/" + this.state.updateReportingID,
        updateData
      )
      .then((response) => {
        // let reportingBakeliste = response.data.data;

        if (response.data.success === true) {
          this.getReportingByBakeliste();
          $(".close").click();
          this.setState({
            updateReportingPending: false,
            updateReportingSuccessAlert: true,
          });
          this.handleShowAndHideAlert("updateSuccessAlert");
        } else {
          this.setState({
            updateReportingPending: false,
            updateReportingError: true,
          });
          this.handleShowAndHideAlert("updateErrorAlert");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateReportingPending: false,
          updateReportingError: true,
        });
        this.handleShowAndHideAlert("updateErrorAlert");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  statusFormatter = (cell) => {
    return (
      <span>
        {cell === "A Faire" && (
          <span className="label label-danger">{cell}</span>
        )}
        {cell === "En Cours" && (
          <span className="label label-warning">{cell}</span>
        )}
        {cell === "Terminé" && (
          <span className="label label-success">{cell}</span>
        )}
      </span>
    );
  };

  bakelistesRepStatusFormatter = (cell, row) => {
    return (
      <div>
        {cell === "A Faire" && (
          <span
            className="label label-danger bsi-status"
            data-toggle="modal"
            data-target="#statusModal"
            onClick={() => this.getReportingInfos(row.id)}
          >
            {" "}
            {cell}{" "}
          </span>
        )}
        {cell === "En Cours" && (
          <span
            className="label label-warning bsi-status"
            data-toggle="modal"
            data-target="#statusModal"
            onClick={() => this.getReportingInfos(row.id)}
          >
            {" "}
            {cell}{" "}
          </span>
        )}
        {cell === "Terminé" && (
          <span
            className="label label-success bsi-status"
            data-toggle="modal"
            data-target="#statusModal"
            onClick={() => this.getReportingInfos(row.id)}
          >
            {" "}
            {cell}{" "}
          </span>
        )}
      </div>
    );
  };

  commentFormatter = (cell, row) => {
    return (
      <div>
        {cell !== null && <p>{cell}</p>}
        {cell === null && (
          <span className="label label-warning empty-comment">
            Aucun commentaire sur cette tâche
          </span>
        )}
      </div>
    );
  };

  tacheFormatter = (cell, row) => {
    return (
      <span>
        <span
          className="tache-link"
          data-toggle="modal"
          data-target="#showReportingItemModal"
          onClick={() => this.getReportingInfosForUpdated(row)}
        >
          {" "}
          {cell}{" "}
        </span>
      </span>
    );
  };

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button
          onClick={() => this.getReportingInfosForUpdated(row)}
          type="button"
          className="btn btn-warning bsi-action-update-btn"
          id="btn_update"
          data-toggle="modal"
          data-target="#updateReportingModal"
        >
          <i className="fas fa-edit"></i>
        </button>
        <button
          onClick={() => this.getReportingInfosForUpdated(row)}
          type="button"
          className="btn btn-info bsi-action-show-btn"
          id="btn_update"
          data-toggle="modal"
          data-target="#showReportingItemModal"
        >
          <i className="fas fa-eye"></i>
        </button>
      </div>
    );
  };

  filterList = (event) => {
    var updatedList = this.state.allBakeliste;

    updatedList = updatedList.filter((item) => {
      return (
        item.first_name
          .toLowerCase()
          .search(event.target.value.toLowerCase()) !== -1 ||
        item.last_name
          .toLowerCase()
          .search(event.target.value.toLowerCase()) !== -1 ||
        item.email.search(event.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ listBakeliste: updatedList });
  };
  customHeaderClass() {
    return "custom-header-tache";
  }
  columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    return rowIdx % 2 === 0
      ? "td-column-function-even-example"
      : "td-column-function-odd-example";
  }

  componentWillMount() {
    this.setState({
      listBakeliste: this.state.allBakeliste,
    });
  }
  handleInputChange(event) {
    this.setState({
      importInProgress: true,
    });
    const toto = new Date();
    const newLocal = this.state;
    newLocal.date =
      toto.getFullYear() + "-" + (toto.getMonth() + 1) + "-" + toto.getDate();

    const fields = this.state.fields;
    fields["date"] = this.state.date;
    fields["bakeliste_id"] = this.state.bakeliste_id;
    fields["status"] = this.state.staatus;
    fields["lieu"] = this.state.lieeu;
    fields["app_name"] = this.state.app_namee;
    fields["technology"] = this.state.technologye;
    fields["coach_id"] = this.state.coachID;
    fields["using_links"] = this.state.using_links;
    fields["commentaire"] = this.state.commentaire;
    if (event.target.name === "tache") {
      console.log(event.target.files);
      this.createImage(event.target.files[0]);
      fields["tache"] = event.target.files[0];
    } else {
      fields[event.target.name] = event.target.value;
    }
    this.setState({
      fields,
    });

    console.log(fields);
    this.fileUploadHandler();
  }
  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        file: e.target.result,
      });
    };
    console.log(this.state.file);
    reader.readAsDataURL(file);
  };
  fileUploadHandler = () => {
    this.setState({
      importInProgress: true,
    });
    const filer = new FormData();
    //  filer.append('date',this.state.fields.date);
    filer.append("tache", this.state.fields.tache);
    filer.append("coach_id", this.state.fields.coach_id);
    filer.append("bakeliste_id", this.state.fields.bakeliste_id);
    filer.append("status", this.state.fields.status);
    filer.append("lieu", this.state.fields.lieu);
    filer.append("app_name", this.state.fields.app_name);
    filer.append("technology", this.state.fields.technology);
    filer.append("using_links", this.state.fields.using_links);
    filer.append("commentaire", this.state.fields.commentaire);
    // let data = this.state.fields;
    console.log(this.state.fields, "data");
    //console.log(this.state.reporting_id,'id')
    axios
      .post(this.state.api + "reporting_import_file", filer)
      .then((response) => {
        //console.log(response.status)
        if (response.status === 200) {
          $(".close").click();
          console.log("click");
          $("body").removeClass("modal-open");
          console.log("open");
          $(".modal-backdrop").removeClass("modal-backdrop");
          console.log("backdrop");
          $(".fade").removeClass("fade");
          $(".in").removeClass("in");
          // this.handleShowAndHideAlerte('successImport');
          this.getReportingByBakeliste();
          this.setState({
            importInProgress: false,
            successImportAlert: true,
          });
          setTimeout(() => {
            this.setState({
              successImportAlert: false,
            });
          }, 5000);
        } else {
          //console.log(response);
          console.log("non ok");
          this.setState({
            importInProgress: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          importInProgress: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });

    //console.log("ok");
  };
  triggerClick = () => {
    this.fileInput.current.click();
  };

  tacheActionFormatter = (cell, row) => {
    return (
      <div>
        <button
          onClick={() => this.onGetTacheinfos(row)}
          type="button"
          className="btn btn-warning"
          data-toggle="modal"
          data-target="#envoyerLivrable "
        >
          <i className="fas fa-paper-plane"></i> &nbsp; Envoyer livrable
        </button>
      </div>
    );
  };

  onGetTacheinfos(tache) {
   // console.log(tache);
    this.setState({
      tacheInfos: tache,

    });
    console.log(this.state.tacheInfos, "testrt")
  }

  fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };
  onValidateLivrablesFormData() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["tache_file"]) {
      isValidForm = false;
      this.setState({
        is_empty_tache_file: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_tache_file: false,
        });
      }, 5000);

      errors["tache_file"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  onSubmitLivrable = (e) => {
    e.preventDefault();

    if (this.onValidateLivrablesFormData()) {
      this.setState({
        isSendingLivrableInProgress: true,
      });

      var api = this.state.api;
      var data = this.state.fields;

      var fd = new FormData();
      fd.append("tache_file", data.tache_file);
      fd.append("commentaire", data.commentaire);
      fd.append("bakeliste_user_id", this.state.userID);
      fd.append("tache_id", this.state.tacheInfos.id);
      console.log("data", data);
      console.log("fd", fd);
      axios
        .post(this.state.api + "livraisons", fd, {
          Headers: { "Content-Type": "multipart/formdata" },
        })
        .then((response) => {
          console.log(response);
          this.setState({
            isSendingLivrableInProgress: false,
          });

          if (response.data.success) {
            $("#tache_file").val("");
            $("#tache_id").val("");
            $("#commentaire").val("");
            this.setState({
              isSuccessSending: true,
            });

            $("#envoyerLivrable").hide();
            toast.success("Livrable envoyé avec succès !");

            setTimeout(() => {
              this.setState({
                isSuccessSending: false,
              });
            }, 6000);
          } else {
            toast.error("Oupsss! Une erreur est survenue lors de l'envoi");

            this.setState({
              isErrorSending: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorSending: false,
              });
            }, 6000);
          }
          $(".close").click();
        })
        .catch((error) => {
          console.log(error.message);
          if ((error.message = "Request failed with status code 422")) {
            toast.error(
              "Oupsss! Une erreur zzzzzzzz est survenue lors de l'envoi"
            );
          }
          this.setState({
            isSendingLivrableInProgress: false,
            isErrorSending: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorSending: false,
            });
          }, 6000);
        });
    }
  };

  render() {
    const { required } = this.props;
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
    };
    if (this.state.isPageLoading) {
      return (
        <div className="component-manage-users">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">
            <section className="content-header">
              <h1>Production</h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/dashboard-admin">Accueil</Link>
                </li>
                <li className="active">Production</li>
              </ol>
            </section>

            <section className="content">
              <div className="list_users_container">
                <div id="loader"></div>
              </div>
            </section>
          </div>
        </div>
      );
    }
    return (
      <div className="component-manage-users">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
              Aprés avoir intégré AdminLTE */}

        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Production</h1>
            <ol className="breadcrumb">
              <li>
                <Link to="/dashboard-admin">Accueil</Link>
              </li>
              <li className="active">Production</li>
            </ol>
          </section>

          <section className="content">
            {/* Blocs contain bakelistes delay list and absence list */}
            <div className="row tab-data-row">
              <div className="col-md-12 tab-data-col">
                <div className="col-md-12 reporting-container">
                  <div className="list_prospect_container">
                    <div className="row prospect-row-row">
                      <div className="col-md-12 prospect-col">
                        <div className="nav-tabs-custom">
                          <div className="col-md-12 da-list-col">
                            <ul className="nav nav-tabs">
                              <li className="active">
                                <NavLink
                                  to="#tache"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Tâche
                                </NavLink>
                              </li>
                              <li className="">
                                <NavLink
                                  to="#reporting"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  reporting
                                </NavLink>
                              </li>
                              <li className="">
                                <NavLink
                                  to="#editeur"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Editeur de texte
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                          <div className="tab-content">
                            <div className="tab-pane active" id="tache">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 prospectw-table-col">
                                  <BootstrapTable
                                    data={this.state.tachesByBakeliste}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    pagination
                                    search={true}
                                  >
                                    <TableHeaderColumn
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='bakelisteFullname' >Nom complet</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataField="content"
                                      dataFormat={this.tacheFormatter.bind(
                                        this
                                      )}
                                    >
                                      Tache
                                    </TableHeaderColumn>
                                    <TableHeaderColumn dataField="duration">
                                      Délais
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="state"
                                      dataFormat={this.tacheStateFormatter.bind(
                                        this
                                      )}
                                      dataSort={true}
                                    >
                                      Status
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="frenchDateFormat"
                                      dataSort={true}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="id"
                                      dataFormat={this.tacheActionFormatter.bind(
                                        this
                                      )}
                                    >
                                      Actions
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>

                            <div className="tab-pane" id="reporting">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-weekend-table-col">
                                  <div className="row add_prospect_container">
                                    <div className="col-md-6 col-md-offset-3 add-reporting-btn-container">
                                      <button
                                        type="button"
                                        className="btn btn-success add_reporting_btn button addBtn"
                                        id="btn_report"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      >
                                        <i className="fas fa-plus-circle"></i>
                                      </button>
                                      &nbsp;&nbsp;
                                      {/* <button
                                        type="button"
                                        className="btn btn-success liste_prospect button"
                                        data-toggle="modal"
                                        data-target="#importCSVModal"
                                        data-placement="top"
                                        title="importer reporting"
                                        style={{
                                          backgroundColor: "rgb(255,184,79)",
                                          top: "-2px",
                                        }}
                                      >
                                        <i class="fas fa-paperclip"></i>
                                      </button> */}
                                    </div>
                                  </div>
                                  <BootstrapTable
                                    data={this.state.reportingByBakeliste}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    pagination
                                    search={true}
                                  >
                                    <TableHeaderColumn
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="date_fr"
                                      width="120"
                                      dataSort={true}
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="tache"
                                      width="250"
                                      dataFormat={this.tacheFormatter.bind(
                                        this
                                      )}
                                      columnClassName={
                                        this.columnClassNameFormat
                                      }
                                    >
                                      Tache
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="commentaire"
                                      dataFormat={this.commentFormatter.bind(
                                        this
                                      )}
                                    >
                                      Commentaire
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="status"
                                      width="90"
                                      dataFormat={this.bakelistesRepStatusFormatter.bind(
                                        this
                                      )}
                                      dataSort={true}
                                    >
                                      Status
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="id"
                                      width="140"
                                      dataFormat={this.actionsFormatter.bind(
                                        this
                                      )}
                                      dataSort={true}
                                    >
                                      Actions
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>

                            <div className="tab-pane" id="editeur">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-weekend-table-col">
                                  <Editor />
                                </div>
                              </div>
                            </div>

                            {/* end table */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Modal d'envoie de livrable */}
        <div
          className="modal fade"
          id="envoyerLivrable"
          data-backdrop="static"
          data-keyboard="false"
          tabindex="-1"
          aria-labelledby="envoyerLivrableLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="editTacheModalLabel">
                  Envoie de livrables
                </h4>
              </div>
              <form id="edit-tache-bycoach">
                <div className="modal-body">
                  <div className="form-group m-b-45">
                    <input
                      id="tache_file"
                      required
                      onChange={this.handleChange1}
                      name="tache_file"
                      type="file"
                      className="form-control form-custom-input"
                      aria-describedby="emailHelp"
                      placeholder="Votre livrable"
                    />
                    {this.state.is_empty_tache_file && (
                      <div className="alert alert-danger jc-alert-danger">
                        {" "}
                        {this.state.errors.livrable}{" "}
                      </div>
                    )}
                    {this.state.isInvalidFileExtension && (
                      <div className="alert alert-danger jc-alert-danger">
                        {" "}
                        Ce format de fichier n'est pas autorisé. Vous devez
                        envoyer votre livrable au format image{" "}
                      </div>
                    )}
                  </div>
                  <div className="form-group m-b-45">
                    <textarea
                      id="comments"
                      onChange={this.handleChange1}
                      name="commentaire"
                      className="form-control form-custom-input"
                      aria-describedby="emailHelp"
                      placeholder="Vos commentaires"
                    ></textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger pull-left bsi-cancel-btn"
                    data-dismiss="modal"
                  >
                    <i className="fas fa-times-circle"></i> &nbsp; Quitter
                  </button>
                  {this.state.utInProgress === false ? (
                    <button
                      type="submit"
                      className="btn btn-primary bsi-submit-btn pull-right"
                      onClick={(e) => this.onSubmitLivrable(e)}
                    >
                      <i className="fas fa-paper-plane"></i> &nbsp; Envoyer
                    </button>
                  ) : (
                    <button className="btn-form in_progress">
                      Envoi en cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.isSuccessSending && <ToastContainer />}
          {this.state.isErrorSending && <ToastContainer />}
        </div>

        {/* Update tache status by bakeliste modal */}
        <div
          className="modal fade"
          id="utModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="utModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="utModalLabel">
                  Modifier le status d'une tâche
                </h4>
              </div>
              <div className="modal-body">
                <div className="ut_form_container">
                  <form id="update-tache-status-bybakeliste">
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="tstatus"
                        name="state"
                        onChange={this.updateTBBHandle}
                      >
                        <option>--Choisir un statut--</option>
                        <option value="todo">A Faire</option>
                        <option value="doing">En Cours</option>
                        <option value="done">Terminé</option>
                      </select>
                    </div>
                    {this.state.updateTacheStatusError && (
                      <div className="form-group alert alert-danger update-error">
                        Un problème est survenu lors de la modification.
                        Veuillez réessayer plus tard.
                      </div>
                    )}
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-danger pull-left bsi-cancel-btn"
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i> &nbsp; Quitter
                      </button>
                      {!this.state.tacheUpdatedInProgress && (
                        <button
                          onClick={(e) => this.updateTacheByBakeliste(e)}
                          type="submit"
                          className="btn btn-primary bsi-submit-btn pull-right"
                        >
                          <i className="fas fa-edit"></i> &nbsp; Modifier
                        </button>
                      )}
                      {this.state.tacheUpdatedInProgress && (
                        <button
                          type="button"
                          className="btn btn-warning bsi-inprogress-btn pull-right"
                        >
                          Modification en cours &nbsp;
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="modal-footer">

                                            </div> */}
            </div>
          </div>
        </div>

        {/* Show tache infos by bakeliste and coach */}
        <div
          className="modal fade"
          id="tacheInfosModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="tacheInfosModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="tacheInfosModalLabel">
                  Détails de votre tâche
                </h4>
              </div>
              <div className="modal-body">
                <div className="tache-infos-container">
                  <div className="tacheContent_container">
                    <span className="item_label">Tâche à effectuer : </span>{" "}
                    <br />
                    <span className="label_content">
                      {" "}
                      <linkify> {this.state.tacheContent} </linkify>{" "}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="urlContent_container">
                    <span className="item_label">
                      URL associé à la tâche :{" "}
                    </span>{" "}
                    <br />
                    {this.state.tacheURL !== null ? (
                      <a
                        href={this.state.tacheURL}
                        alt="URL"
                        className="tache_url-content"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="label_content tacheURL">
                          {" "}
                          Cliquez pour accéder{" "}
                        </span>
                      </a>
                    ) : (
                      <span className="label label-info">
                        {" "}
                        Aucun URL n'est associé à cette tâche{" "}
                      </span>
                    )}
                  </div>{" "}
                  <hr />
                  <div className="tacheState_container">
                    <span className="item_label">Statut de la tâche : </span>{" "}
                    <br />
                    {this.state.tacheState === "todo" && (
                      <span className="label_content label label-danger">
                        A Faire
                      </span>
                    )}
                    {this.state.tacheState === "doing" && (
                      <span className="label_content label label-warning">
                        En Cours
                      </span>
                    )}
                    {this.state.tacheState === "done" && (
                      <span className="label_content label label-success">
                        Terminé
                      </span>
                    )}
                  </div>{" "}
                  <hr />
                  <div className="tacheDuratiupdateTacheByCoachon_container">
                    <span className="item_label">Durée de la tâche : </span>{" "}
                    <br />
                    <span className="label_content badge">
                      {this.state.tacheDuration}
                    </span>
                  </div>{" "}
                  <hr />
                </div>
              </div>
              {/* <div className="modal-footer">

                                            </div> */}
            </div>
          </div>
        </div>
        {/* Modal pour ajouter un reporting  */}
        <div
          className="modal fade"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="myModalLabel">
                  Ajouter un reporting{" "}
                </h4>
              </div>
              <form className="add-reporting-form">
                <div className="modal-body">
                  <div className="alerty-row row">
                    <div className="col-md-12">
                      <div className="alert alert-warning alerty-rep-textuel">
                        Les champs contenant des astérix{" "}
                        <sup className="required-field">(*)</sup> sont
                        obligatoire.
                      </div>
                    </div>
                  </div>
                  <div className="form-group add-reporting-form-group">
                    <div className="row">
                      <div className="col-md-6">
                        <label>Nom du projet ou de l'application </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputAppName"
                          name="app_name"
                          placeholder="App name"
                          onChange={this.handleChangeR}
                          required={true}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>Technologies / Outils </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputTech"
                          name="technology"
                          placeholder="Technologie utilisée"
                          onChange={this.handleChangeR}
                          required={required}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label>Lien utilisé </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputLink"
                          name="using_links"
                          placeholder="liens utilisés"
                          onChange={this.handleChangeR}
                          required={required}
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          Lieu de réalisation{" "}
                          <i className="marky">(Bakeli ou A Domicile)</i>{" "}
                          <sup className="required-field">*</sup>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputLieux"
                          name="lieu"
                          placeholder="Lieux"
                          onChange={this.handleChangeR}
                          required={required}
                        />
                        {this.state.emptyLieu && (
                          <div className="errorMsg">
                            {this.state.errors.emptyLieu}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row ar-rowy">
                      <div className="col-md-12">
                        <div className="form-group ">
                          <label>Commentaires </label>
                          <textarea
                            className="form-control"
                            rows="5"
                            id="appComment"
                            name="commentaire"
                            placeholder="Votre commentaire"
                            onClick={this.handleChangeR}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group ">
                          <label>
                            Statut de la tâche{" "}
                            <sup className="required-field">*</sup>{" "}
                          </label>
                          <select
                            className="form-control"
                            id="inputStatus_Tache"
                            name="status"
                            onClick={this.handleChangeR}
                            required={required}
                          >
                            <option value="A Faire">A Faire</option>
                            <option value="En Cours">En Cours</option>
                            <option value="Terminé">Terminé</option>
                          </select>
                          {this.state.emptyStatus && (
                            <div className="errorMsg">
                              {this.state.errors.emptyStatus}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group ">
                          <label>
                            Contenu de la tâche{" "}
                            <sup className="required-field">*</sup>{" "}
                          </label>
                          <textarea
                            className="form-control"
                            rows="3"
                            id="tache"
                            name="tache"
                            placeholder="Tache à effectuer"
                            onChange={this.handleChangeR}
                            required={required}
                          ></textarea>
                          {this.state.emptyTache && (
                            <div className="errorMsg">
                              {this.state.errors.emptyTache}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Date <sup className="required-field">*</sup>{" "}
                          </label>
                          <input
                            type="date"
                            name="date"
                            className="form-control"
                            onChange={this.handleChangeR}
                            required={required}
                          />
                          {this.state.emptyDate && (
                            <div className="errorMsg">
                              {this.state.errors.emptyDate}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.addReportingError && (
                    <div className="alert alert-danger addReporting-error">
                      Une erreur est survenu lors de l'ajout de votre reporting.
                      Veuillez réessayer plus tard.
                    </div>
                  )}
                </div>
              </form>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger pull-left cancel_btn cancel button"
                  data-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i> &nbsp; Quitter
                </button>
                {!this.state.reportingEncours && (
                  <Amplitude>
                    {({ logEvent }) => (
                      <button
                        type="button"
                        className="btn btn-primary bsi-submit-btn"
                        onClick={(e) => this.addReporting(e)}
                      >
                        <i className="fas fa-plus"></i> &nbsp; Ajouter
                      </button>
                    )}
                  </Amplitude>
                )}
                {this.state.reportingEncours && (
                  <button
                    type="button"
                    className="btn btn-warning bsi-inprogress-btn"
                  >
                    Ajout en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* ==== Modifier Statut ==== */}

        <div
          className="modal fade"
          id="statusModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="statusModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="statusModalLabel">
                  Modifier le statut{" "}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row update-status-row">
                  <form>
                    <div className="form-group ">
                      <select
                        className="form-control"
                        id="inputStatus_Reporting"
                        name="status"
                        onClick={this.handleChangeR}
                      >
                        <option>--Choisir un status--</option>
                        <option value="A Faire">A Faire</option>
                        <option value="En Cours">En Cours</option>
                        <option value="Terminé">Terminé</option>
                      </select>
                    </div>

                    <div className="row">
                      <div className="form-actions-btn col-md-12">
                        <button
                          type="button"
                          className="btn btn-default cancel_btn cancel button"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times-circle"></i> &nbsp; Quitter
                        </button>
                        {!this.state.updateReportingStatusInProgress && (
                          <button
                            type="button"
                            className="btn btn-primary btn_for_assignation button addBtn"
                            onClick={this.onUpdateReportingStatus}
                          >
                            <i className="fas fa-edit"></i>&nbsp; Modifier
                          </button>
                        )}
                        {this.state.updateReportingStatusInProgress && (
                          <button
                            type="button"
                            className="btn btn-warning button addBtn btn_for_wait"
                          >
                            Modification en cours &nbsp;
                            <i className="fas fa-spinner fa-spin"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="modal-footer">
                {this.state.isUpdateStatusError && (
                  <div className="alert alert-danger update-status-error text-center">
                    Un problème est survenu lors de la mise à jour du statut.
                    Veuillez réessayer plus tard.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* ====== Modal pour modifier un reporting ====== */}
        <div
          className="modal fade"
          id="updateReportingModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="updateReportingModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="updateReportingModalLabel">
                  Modifier un reporting{" "}
                </h4>
              </div>
              <div className="modal-body">
                <div className="form-group add-reporting-form-group">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Date </label>
                        <input
                          type="date"
                          name="date"
                          className="form-control"
                          onChange={this.updateHandleChange}
                          value={this.state.updateDateValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label>Nom du projet ou de l'application </label>
                      <input
                        type="text"
                        className="form-control"
                        id="appName"
                        name="app_name"
                        placeholder="App name"
                        onChange={this.updateHandleChange}
                        value={this.state.appNameValue}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Technologies / Outils </label>
                      <input
                        type="text"
                        className="form-control"
                        id="appTech"
                        name="technology"
                        placeholder="Technologie utilisée"
                        onChange={this.updateHandleChange}
                        value={this.state.technologyValue}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Lien utilisé </label>
                      <input
                        type="text"
                        className="form-control"
                        id="appLink"
                        name="using_links"
                        placeholder="liens utilisés"
                        onChange={this.updateHandleChange}
                        value={this.state.using_linksValue}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Lieu de réalisation (Bakeli / A domicile) </label>
                      <input
                        type="text"
                        className="form-control"
                        id="appLieu"
                        name="lieu"
                        placeholder="Lieux"
                        onChange={this.updateHandleChange}
                        value={this.state.lieuValue}
                      />
                    </div>
                  </div>

                  <div className="row ar-rowy">
                    <div className="col-md-12">
                      <div className="form-group ">
                        <label>Commentaires </label>
                        <textarea
                          className="form-control"
                          rows="5"
                          id="appComment"
                          name="commentaire"
                          placeholder="Votre commentaire"
                          onChange={this.updateHandleChange}
                          value={this.state.commentaireValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group ">
                        <label>Statut de la tâche </label>
                        <select
                          className="form-control"
                          id="appStatut"
                          name="status"
                          onChange={this.updateHandleChange}
                          value={this.state.statutValue}
                        >
                          <option>--Choisir un status--</option>
                          <option value={"A Faire"}>A Faire</option>
                          <option value={"En Cours"}>En Cours</option>
                          <option value={"Terminé"}>Terminé</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group ">
                        <label>Contenu de la tâche</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          id="appTache"
                          name="tache"
                          placeholder="Tâche à effectuer"
                          onChange={this.updateHandleChange}
                          value={this.state.tacheValue}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.updateReportingError && (
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="alert alert-danger text-center">
                          Un problème est survenu lors de la modification.
                          Veuillez réessayer plus tard.
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger pull-left cancel_btn cancel button"
                  data-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i> &nbsp; Quitter
                </button>
                {!this.state.updateReportingPending && (
                  <button
                    type="button"
                    className="btn btn-primary bsi-submit-btn"
                    onClick={(e) => this.onUpdateReportingRequest(e)}
                  >
                    <i className="fas fa-edit"></i> &nbsp; Modifier
                  </button>
                )}
                {this.state.updateReportingPending && (
                  <button
                    type="button"
                    className="btn btn-warning bsi-inprogress-btn"
                  >
                    Modification en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal pour visualiser un reporting  */}
        <div
          className="modal fade"
          id="showReportingItemModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="showReportingItemModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showReportingItemModalLabel">
                  Visualiser un reporting{" "}
                </h4>
              </div>
              <div className="modal-body">
                <div className="reporting_content">
                  <div className="date_container">
                    <span className="item_label">Date : </span>{" "}
                    <span className="label_content">
                      {this.state.dateFRValue}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="tache_container">
                    <span className="item_label">Tâche : </span> &nbsp;
                    {this.state.statutValue === "A Faire" && (
                      <span
                        className="label label-danger todoStatus"
                        data-toggle="modal"
                        data-target="#statusModal"
                        onClick={() =>
                          this.getReportingInfos(this.state.updateReportingID)
                        }
                      >
                        {" "}
                        {this.state.statutValue}{" "}
                      </span>
                    )}
                    {this.state.statutValue === "En Cours" && (
                      <span
                        className="label label-warning doingStatus"
                        data-toggle="modal"
                        data-target="#statusModal"
                        onClick={() =>
                          this.getReportingInfos(this.state.updateReportingID)
                        }
                      >
                        {" "}
                        {this.state.statutValue}{" "}
                      </span>
                    )}
                    {this.state.statutValue === "Terminé" && (
                      <span
                        className="label label-success doneStatus"
                        data-toggle="modal"
                        data-target="#statusModal"
                        onClick={() =>
                          this.getReportingInfos(this.state.updateReportingID)
                        }
                      >
                        {" "}
                        {this.state.statutValue}{" "}
                      </span>
                    )}
                    <br />
                    <p className="label_content">{this.state.tacheValue}</p>
                  </div>{" "}
                  <hr />
                  <div className="commentaire_container">
                    <span className="item_label">Commentaire : </span> <br />
                    {this.state.commentaireValue !== "" && (
                      <p className="label_content">
                        {this.state.commentaireValue}
                      </p>
                    )}
                    {this.state.commentaireValue === "" && (
                      <span className="alert alert-warning label-content">
                        Aucun commentaire sur cette tâche
                      </span>
                    )}
                  </div>{" "}
                  <hr />
                  <div className="lieu_container">
                    <span className="item_label">Lieu de réalisation : </span>{" "}
                    <br />
                    <p className="label_content">{this.state.lieuValue}</p>
                  </div>{" "}
                  <hr />
                  <div className="usingLinks_container">
                    <span className="item_label">Lien utilisé : </span> <br />
                    <p className="label_content">
                      {this.state.using_linksValue !== "" && (
                        <a
                          href={this.state.using_linksValue}
                          target="_blank"
                          className="usingLinks"
                          rel="noopener noreferrer"
                        >
                          Lien à cliquer
                        </a>
                      )}
                      {this.state.using_linksValue === "" && (
                        <span className="empty_link label label-warning">
                          Aucun lien utilisé
                        </span>
                      )}
                    </p>
                  </div>{" "}
                  <hr />
                  <div className="technology_container">
                    <span className="item_label">
                      Technologie / Framework :{" "}
                    </span>{" "}
                    <br />
                    <p className="label_content">
                      {this.state.technologyValue}
                    </p>
                  </div>{" "}
                  <hr />
                  <div className="appName_container">
                    <span className="item_label">Nom de l'application : </span>{" "}
                    <br />
                    <p className="label_content">{this.state.appNameValue}</p>
                  </div>{" "}
                  <hr />
                </div>
              </div>

              <div className="modal-footer"></div>
            </div>
          </div>
        </div>

        {this.state.addReportingSuccessAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            Reporting ajouté avec succès.
          </div>
        )}

        {this.state.updateReportingSuccessAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            Reporting modifié avec succès.
          </div>
        )}

        {this.state.updateReportingStatusSuccessAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            Statut du reporting modifié avec succès.
          </div>
        )}
      </div>
    );
  }
}