import React, { Component } from 'react';
import './bakelistesByCoach.css';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import API from '../../variablesGlobales';
import $ from 'jquery';


export default class bakelistesByCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      bakelistes: [],
      coachID: window.sessionStorage.getItem('coachIDForRedirect'),
      coachFullname: window.sessionStorage.getItem('coachFNForRedirect'),
      isPageLoading: false,
      bakelisteInfos: [],
      completedInProgress: false,
      bakelisteFullname: '',
      emailValue: '',
      phoneValue: '',
      boursierValue: false,
      demiBoursierValue: false,
      stageFormationValue: false,
      formationPayanteValue: false,
      isaContractValue: false,
      lieuFormationValue: '',
      paiementInscriptionValue: false,
      debutFormationValue: '',
      finFormationValue: '',
      upFields: {},
      updateInProgress: false,
      bakelisteIDForUpdate: 0,
      updateBakeliste: [],
      successAlert: false,
      domaines: [],
      updateBakelisteRequestError: false,
      coachs: [],
      fields: {},
      assignationEncours: false,
      selectedBakelist: [],
      successAssignationAlert: false,
      errorAssignationAlert: false,

    };
    this.handleChange = this.handleChange.bind(this);
    this.onCoachAssignation = this.onCoachAssignation.bind(this);
  }

  componentDidMount() {
    this.getListBakelisteByCoach();
    this.getAllDomaines();
    this.getListActiveCoach();
  }

  /**
   * Get training domain
   */
  getAllDomaines = () => {
    axios.get(this.state.api + 'domaines')
      .then(res => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
        //console.log(this.state.domaines)
      });
  }

  /**
   * List active coach
   */
  getListActiveCoach = () => {
    axios
      .get(this.state.api + 'liste-des-coachs')
      .then(res => {
        this.setState({ coachs: res.data.data });
      })
  }

  // Liste des bakelistes par coach
  getListBakelisteByCoach = () => {
    this.setState({
      isPageLoading: true
    });

    let coachID = this.state.coachID;

    axios.get(this.state.api + 'bakelistes-by-coach/' + coachID)
      .then(response => {
        var bakelistes = response.data.data;
        console.log('bakelistes by coach ', bakelistes);
        if (bakelistes !== undefined) {
          this.setState({
            bakelistes: bakelistes,
            isPageLoading: false
          });
        } else {
          //TODO
          this.setState({
            isPageLoading: false
          })
        }
      })
  }

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === 'closeModal') {
        this.setState({
          isClosingModal: true
        });
      }
      if (label === 'closeSuccessAlert') {
        this.setState({
          successAlert: false
        })
      }
      if (label === 'closeErrorAlert') {
        this.setState({
          updateBakelisteRequestError: false
        })
      }
      if (label === 'successHired') {
        this.setState({
          successHiredAlert: false
        })
      }
      if (label === 'successCompletedFormation') {
        this.setState({
          successCompletedFormationAlert: false
        })
      }
      if (label === 'errorCompletedFormation') {
        this.setState({
          completedFormationRequestError: false
        })
      }
      if (label === 'successAssignationAlert') {
        this.setState({
          successAssignationAlert: false
        })
      }
      if (label === 'errorAssignationAlert') {
        this.setState({
          errorAssignationAlert: false
        })
      }
    }, 5000);
  }

  getBakelisteInfos = (bakeliste, view) => {
    console.log("detail bakeliste: ", bakeliste);
    if (view === 'modal') {
      this.setState({
        bakelisteInfos: bakeliste,
        bakelisteIDForUpdate: bakeliste.id,
        bakelisteFullname: bakeliste.bakelisteFullname,
        emailValue: bakeliste.email,
        phoneValue: bakeliste.phone,
        boursierValue: bakeliste.boursier,
        demiBoursierValue: bakeliste.demi_boursier,
        formationPayanteValue: bakeliste.is_formation_payante,
        isaContractValue: bakeliste.is_isa_contract,
        domaineFormationValue: bakeliste.bakeliste_domaine_id,
        stageFormationValue: bakeliste.is_stagede_formation,
        lieuFormationValue: bakeliste.lieu_formation,
        paiementInscriptionValue: bakeliste.paiement_inscription,
        debutFormationValue: bakeliste.debut_formation,
        finFormationValue: bakeliste.fin_formation,
        updateBakeliste: bakeliste
      })
    } else if (view === 'redirect') {
      // TODO
      console.log(view)
      window.sessionStorage.setItem('bakelisteIDForReporting', bakeliste.id);
      window.sessionStorage.setItem('bakelisteReportingFullname', bakeliste.first_name + ' ' + bakeliste.last_name)
      window.sessionStorage.setItem('redirect_origin', 'list-bakeliste-by-coach');
      window.location = 'reportings-by-bakeliste';

    }

  }

  getSelectedBakelist(item) {
    this.getListActiveCoach();
    this.setState({
      selectedBakelist: item
    })
    return item.id
  }

  fullnameFormatter = (cell, row) => {
    return (
      <span data-toggle="modal" data-target="#binfosModal" className="fullname-link" onClick={() => this.getBakelisteInfos(row, 'modal')}> {cell} </span>
    )
  }

  handleCompletedFormation = (bakeliste) => {
    this.setState({
      completedInProgress: true
    });

    let bakelisteID = bakeliste.id;
    let data = {};

    axios.post(this.state.api + 'completed-bakelistes-formation/' + bakelisteID, data)
      .then(response => {
        if (response.data.success === true) {
          this.getListBakelisteByCoach();
        } else {
          console.log(response);
        }
        this.setState({
          completedInProgress: false
        });
      })
  }

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button type="button" className="btn btn-info reassigner" id="btn_assign" data-toggle="modal" data-target="#myModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
          <i className="fas fa-user-check"></i>
        </button>
        <button onClick={() => { this.getBakelisteInfos(row, 'redirect') }} id="show_item" className="btn btn-info show-item-btn" data-toggle="tooltip" data-placement="top" title="Voir le reporting">
          <i className="fas fa-list-ul"></i>
        </button>
        &nbsp;&nbsp;
            <button onClick={() => { this.getBakelisteInfos(row, 'modal') }} id="update_item" className="btn btn-default edit-btn volkeno-orange-bcolor" data-toggle="modal" data-target="#editBakelisteModal">
          <i className="fas fa-edit"></i>
        </button>
        &nbsp;&nbsp;
        <button data-toggle="tooltip" data-placement="top" title="Terminer la formation" type="button" className="btn btn-info completed-btn volkeno-red-bcolor" id="completed"
          onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter la formation de ce bakeliste ?") &&
            this.handleCompletedFormation(row)}
        >
          {this.state.completedInProgress ?
            <i className="fas fa-spin fa-spinner"></i> :
            <i className="fas fa-stop-circle"></i>
          }

        </button>
      </div>
    )
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }

  onCoachAssignation = () => {
    this.setState({
      assignationEncours: true
    });
    console.log(this.state.selectedBakelist.id)
    const id_bakelist = this.state.selectedBakelist.id

    const dataForAssign = {
      bakeliste_id: id_bakelist,
      coach_id: this.state.fields.coachSelect

    }
    axios.post(this.state.api + 'assigner-un-coach', dataForAssign)
      .then(res => {
        console.log(res.data);
        if (res.data.success) {
          this.getListBakelisteByCoach();
          $('.close').click();
          $('.modal-backdrop').removeClass('modal-backdrop');
          $('.fade').removeClass('fade')
          $('.in').removeClass('in')
          this.setState({
            assignationEncours: false,
            successAssignationAlert: true
          });
          //window.location.reload();
          this.handleShowAndHideAlert('successAssignationAlert')
        } else {
          this.setState({
            assignationEncours: false,
            errorAssignationAlert: true
          })
          console.log(res)
        }
      })
      .catch(error => {
        this.setState({
          assignationEncours: false,
          errorAssignationAlert: true
        })
        console.log(error.message)
      })

  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }


  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  updateHandleChange = (e) => {
    let fields = this.state.upFields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === 'email') {
      this.setState({
        emailValue: e.target.value
      })
    }
    if (e.target.name === 'phone') {
      this.setState({
        phoneValue: e.target.value
      })
    }
    if (e.target.name === 'boursier') {
      this.setState({
        boursierValue: e.target.value
      })
    }
    if (e.target.name === 'demi_boursier') {
      this.setState({
        demiBoursierValue: e.target.value
      })
    }
    if (e.target.name === 'is_formation_payante') {
      this.setState({
        formationPayanteValue: e.target.value
      })
    }
    if (e.target.name === 'is_isa_contract') {
      this.setState({
        isaContractValue: e.target.value
      })
    }
    if (e.target.name === 'is_stagede_formation') {
      this.setState({
        stageFormationValue: e.target.value
      })
    }
    if (e.target.name === 'lieu_formation') {
      this.setState({
        lieuFormationValue: e.target.value
      })
    }
    if (e.target.name === 'bakeliste_domaine_id') {
      this.setState({
        domaineFormationValue: e.target.value
      })
    }
    if (e.target.name === 'paiement_inscription') {
      this.setState({
        paiementInscriptionValue: e.target.value
      })
    }
    if (e.target.name === 'debut_formation') {
      this.setState({
        debutFormationValue: e.target.value
      })
    }
    if (e.target.name === 'fin_formation') {
      this.setState({
        finFormationValue: e.target.value
      })
    }

    if (!fields["email"]) {
      fields["email"] = this.state.updateBakeliste.email;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.phoneValue;
    }
    if (!fields["boursier"]) {
      fields["boursier"] = this.state.boursierValue;
    }
    if (!fields["demi_boursier"]) {
      fields["demi_boursier"] = this.state.demiBoursierValue;
    }
    if (!fields["is_formation_payante"]) {
      fields["is_formation_payante"] = this.state.formationPayanteValue;
    }
    if (!fields["is_isa_contract"]) {
      fields["is_isa_contract"] = this.state.isaContractValue;
    }
    if (!fields["is_stagede_formation"]) {
      fields["is_stagede_formation"] = this.state.stageFormationValue;
    }
    if (!fields["lieu_formation"]) {
      fields["lieu_formation"] = this.state.lieuFormationValue;
    }
    if (!fields["bakeliste_domaine_id"]) {
      fields["bakeliste_domaine_id"] = this.state.domaineFormationValue;
    }
    if (!fields["paiement_inscription"]) {
      fields["paiement_inscription"] = this.state.paiementInscriptionValue;
    }
    if (!fields["debut_formation"]) {
      fields["debut_formation"] = this.state.debutFormationValue;
    }
    if (!fields["fin_formation"]) {
      fields["fin_formation"] = this.state.finFormationValue;
    }

    this.setState({
      upFields: fields
    })

    console.log(fields)
  }

  updateBakelisteInfos = (e) => {
    e.preventDefault();
    console.log(this.state.upFields);
    this.setState({
      updateInProgress: true
    })
    let bakelisteID = this.state.bakelisteIDForUpdate;
    let data = this.state.upFields;

    axios.put(this.state.api + 'update-bakeliste-infos/' + bakelisteID, data)
      .then(response => {
        this.setState({
          updateInProgress: false
        });
        console.log(response.data.data)
        if (response.data.success === true) {
          this.getListBakelisteByCoach();
          //document.getElementById('updateBakelisteForm').reset();
          $('.cancel_btn').click();
          console.log('click')
          $('body').removeClass('modal-open');
          console.log('open')
          $('.modal-backdrop').removeClass('modal-backdrop');
          console.log('backdrop')
          $('.fade').removeClass('fade')
          $('.in').removeClass('in')
          this.setState({
            successAlert: true,
            upFields: {}
          });
          this.handleShowAndHideAlert('closeSuccessAlert');
        } else {
          console.log(response);
          this.setState({
            updateBakelisteRequestError: true
          })
          this.handleShowAndHideAlert('closeErrorAlert');
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          updateInProgress: false,
          updateBakelisteRequestError: true
        });
        this.handleShowAndHideAlert('closeErrorAlert');
      })
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-bakelistes-by-coach">
          <Menu />
          <div className="bakeliste-by-coach row" id="bakelisteByCoach">
            <div className="col-lg-12">
              <h1 className="page-header">
                <span className="page-title">Bakelistes de {this.state.coachFullname}</span>
                <div className="pull-right">
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard-admin">Accueil</Link>
                    </li>
                    <li className="">
                      <NavLink to="/liste-coach" activeClassName="active">Liste des coachs</NavLink>
                    </li>
                    <li className="active">Bakeliste par coach</li>
                  </ol>
                </div>
              </h1>
            </div>
            <div className="col-md-12 bakelistes-container">
              <div className="bakeliste_container">
                <div className="table_container">
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Prénom et Nom</th>
                        <th scope="col">Domaines</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Date de début</th>
                        <th scope="col">Date de fin</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      )
    }

    return (
      <div className="component-bakelistes-by-coach">
        <Menu />
        <div className="bakeliste-by-coach row" id="bakelisteByCoach">
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">Bakelistes de {this.state.coachFullname}</span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <NavLink to="/dashboard-admin">Accueil</NavLink>
                  </li>
                  <li className="">
                    <NavLink to="/liste-coach" activeClassName="active">Liste des coachs</NavLink>
                  </li>
                  <li className="active">Bakeliste par coach</li>
                </ol>
              </div>
            </h1>
          </div>
          <div className="col-md-12 bakelistes-container">
            <div className="bakeliste_container">
              <div className="table_container">
                <BootstrapTable data={this.state.bakelistes}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  pagination
                  search={true}>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)}>Actions</TableHeaderColumn>
                  <TableHeaderColumn dataField='bakelisteFullname' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.fullnameFormatter.bind(this)} >Nom complet</TableHeaderColumn>
                  <TableHeaderColumn dataField='domaine'>Domaine</TableHeaderColumn>
                  {/* <TableHeaderColumn dataField='coachFullname'>Coach</TableHeaderColumn> */}
                  <TableHeaderColumn dataField='phone'>Téléphone</TableHeaderColumn>
                  <TableHeaderColumn dataField='date_debut_fr' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Date de début</TableHeaderColumn>
                  <TableHeaderColumn dataField='date_fin_fr' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Date de fin</TableHeaderColumn>
                  
                </BootstrapTable>
              </div>
            </div>
          </div>

          {/* edit bakeliste */}
          <div className="modal fade" id="editBakelisteModal" tabindex="-1" role="dialog" aria-labelledby="editBakelisteModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="editBakelisteModalLabel">Modifier : {this.state.bakelisteFullname}</h4>
                </div>
                <form id="updateBakelisteForm">
                  <div className="modal-body">
                    <div className="edit-bakeliste-container">
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Email:</label>
                          <input type="Email" className="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.emailValue} onChange={this.updateHandleChange} />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <label >Téléphone:</label>
                          <input type="text" className="form-control" id="inputNumero" placeholder="Téléphone" name="phone" value={this.state.phoneValue} onChange={this.updateHandleChange} />
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group " >
                          <label >Boursier:</label>
                          <select className="form-control" id="inputBoursier" name="boursier" value={this.state.boursierValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Demi-Boursier:</label>
                          <select className="form-control" id="inputDBoursier" name="demi_boursier" value={this.state.demiBoursierValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Stage de formation :</label>
                          <select className="form-control" id="inputSFormation" name="is_stagede_formation" value={this.state.stageFormationValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Lieu de formation:</label>
                          <select className="form-control" id="inputlieu_formation" name="lieu_formation" value={this.state.lieuFormationValue} onChange={this.updateHandleChange}>
                            <option> --Choisir un lieu-- </option>
                            <option value="Sicap Foire">Sicap Foire</option>
                            <option value="Grand Yoff 1">Grand Yoff 1</option>
                            <option value="Grand Yoff 2">Grand Yoff 2</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Boursier:</label>
                          <select className="form-control" id="inputBoursier" name="boursier" value={this.state.boursierValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                        {this.state.boursier_empty &&
                          <div className="errorMsg">{this.state.errors.boursier}</div>
                        }
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Demi-Boursier:</label>
                          <select className="form-control" id="inputDBoursier" name="demi_boursier" value={this.state.demiBoursierValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label>Formation payante :</label>
                          <select className="form-control" id="inputFormationP" name="is_formation_payante" value={this.state.formationPayanteValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Stage de formation :</label>
                          <select className="form-control" id="inputSFormation" name="is_stagede_formation" value={this.state.stageFormationValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Contrat ISA :</label>
                          <select className="form-control" id="inputISA" name="is_isa_contract" value={this.state.isaContractValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Lieu de formation:</label>
                          <select className="form-control" id="inputlieu_formation" name="lieu_formation" value={this.state.lieuFormationValue} onChange={this.updateHandleChange}>
                            <option> --Choisir un lieu-- </option>
                            <option value="Sicap Foire">Sicap Foire</option>
                            <option value="Grand Yoff 1">Grand Yoff 1</option>
                            <option value="Grand Yoff 2">Grand Yoff 2</option>
                            <option value="Thiés">Thiés</option>
                            <option value="Tambacounda">Tambacounda</option>
                          </select>
                        </div>
                        {this.state.lf_empty &&
                          <div className="errorMsg">{this.state.errors.lieu_formation}</div>
                        }
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Domaine de formation:</label>
                          <select className="form-control" id="inputbakeli_domaine_id" name="bakeliste_domaine_id" value={this.state.domaineFormationValue} onChange={this.updateHandleChange}>

                            <option> --Choisir un domaine-- </option>
                            {this.state.domaines.map((x, y) => <option value={x.id} key={y} >{x.name}</option>)}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group " >
                          <label >Paiement a l'inscription:</label>
                          <select className="form-control" id="inputpaiement_inscription" name="paiement_inscription" value={this.state.paiementInscriptionValue} onChange={this.updateHandleChange}>
                            <option> --Choisir une option-- </option>
                            <option value={1}>OUI</option>
                            <option value={0}>NON</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label >Date de début:</label>
                          <input type="date" className="form-control" id="inputdebut_formation" name="debut_formation" value={this.state.debutFormationValue} onChange={this.updateHandleChange} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label >Date de fin:</label>
                          <input type="date" className="form-control" id="inputfin_formation" name="fin_formation" value={this.state.finFormationValue} onChange={this.updateHandleChange} />
                        </div>
                      </div>

                      {this.state.updateBakelisteRequestError &&
                        <div className="col-md-12 alert alert-danger updateBakelisteError">
                          Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                      </div>
                      }
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Quitter
                    </button>
                      {!this.state.updateInProgress &&
                        <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.updateBakelisteInfos(e)}>
                          <i className="fas fa-edit"></i>&nbsp;
                          Modifier
                      </button>
                      }
                      {this.state.updateInProgress &&
                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                          Modification en cours &nbsp;
                        <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Assignation de coach */}
          <div className="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="myModalLabel">Selection du coach</h4>
                </div>
                <div className="modal-body">
                  <div className="form-group " >
                    <label >Coach:</label>
                    <select className="form-control" id="inputbakeli_domaine_id" name="coachSelect" onChange={this.handleChange}>
                      <option>--Choisir un coach--</option>
                      {this.state.coachs.map((z, y) => <option key={y} value={z.id}>{z.first_name} {z.last_name} - {'[ ' + z.nbreBakeliste + ' bakeliste(s) ]'}</option>)}
                    </select>
                  </div>
                  {this.state.errorAssignationAlert === true &&
                    <div className="row error-row">
                      <div className="col-md-12 assignation-error">
                        <div className="alert alert-danger assignation-error-content">
                          Un problème est survenu lors de l'assignation. Veuillez réessayer plus tard. Si le problème persiste, vous pouvez contacter l'équipe technique en envoyant un e-mail à <b>contact@bakeli.tech</b>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal">Quitter</button>
                  {!this.state.assignationEncours &&
                    <button type="button" id="ass" className="btn btn-primary" onClick={(e) => this.onCoachAssignation(e)}>Assigner</button>
                  }
                  {this.state.assignationEncours &&
                    <button type="button" className="btn btn-warning">
                      Assignation en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>

          {/* Bakeliste infos modal */}
          <div className="modal fade" id="binfosModal" tabindex="-1" role="dialog" aria-labelledby="binfosModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="binfosModalLabel">Detail sur {this.state.bakelisteInfos.first_name}&nbsp;{this.state.bakelisteInfos.last_name}</h4>
                </div>
                <div className="modal-body">
                  <div className="email_container">
                    <span className="item_label">Email : </span> <span className="label_content">{this.state.bakelisteInfos.email}</span>
                  </div> <hr />
                  <div className="objectif_container">
                    <span className="item_label">Objectifs : </span> <span className="label_content">{this.state.bakelisteInfos.objectifs}</span>
                  </div> <hr />

                  {this.state.bakelisteInfos.ecole !== '' &&
                    <div className="ecole_container">
                      <span className="item_label">Ecole : </span> <span className="label_content">{this.state.bakelisteInfos.ecole}</span> <hr />
                    </div>

                  }
                  <div className="email_container">
                    <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.bakelisteInfos.phone}</span>
                  </div> <hr />
                  {this.state.bakelisteInfos.boursier === 1 &&
                    <div className="ecole_container">
                      <span className="item_label">Boursier : </span> <span className="label_content">NON</span> <hr />
                    </div>

                  }
                  {this.state.bakelisteInfos.boursier === 0 &&
                    <div className="ecole_container">
                      <span className="item_label">Boursier : </span> <span className="label_content">OUI</span> <hr />
                    </div>

                  }
                  <div className="email_container">
                    <span className="item_label">lieu_formation : </span> <span className="label_content">{this.state.bakelisteInfos.lieu_formation}</span>
                  </div> <hr />
                  <div className="email_container">
                    <span className="item_label">Type de formation : </span> <span className="label_content">{this.state.bakelisteInfos.type_formation}</span>
                  </div> <hr />
                  {this.state.bakelisteInfos.paiement_inscription === 1 &&
                    <div className="ecole_container">
                      <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">NON</span> <hr />
                    </div>

                  }
                  {this.state.bakelisteInfos.paiement_inscription === 0 &&
                    <div className="ecole_container">
                      <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">OUI</span> <hr />
                    </div>

                  }
                  <div className="email_container">
                    <span className="item_label">Date de début de la formation : </span> <span className="label_content">{this.state.bakelisteInfos.debut_formation}</span>
                  </div> <hr />
                  <div className="email_container">
                    <span className="item_label">Date de fin de la formation : </span> <span className="label_content">{this.state.bakelisteInfos.fin_formation}</span>
                  </div> <hr />
                </div>
                <div className="modal-footer">

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Succeccfull updated toast */}
        {this.state.successAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
            Bakeliste infos modifié avec succès.
          </div>
        }

        {/* Success completed formation toast alert */}
        {this.state.successAssignationAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
            La réassignation a été effectuée avec succès.
          </div>
        }
      </div>
    );
  }
}
