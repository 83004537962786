import React, {Component} from 'react';
import './UserProfile.css'
import axios from 'axios';
import API from '../../../variablesGlobales';
import Menu from './../../menu/Menu';
import { Link } from 'react-router-dom';

class UserProfile extends Component {
  
  	constructor (props) {
		
		super(props);
		
		const token = window.sessionStorage.getItem('token');
		
		if(!token) window.history.back();

		const userId = window.sessionStorage.getItem('user_id');

		this.state = {
			api: API.API,
			userId: userId,
			userStatus: window.sessionStorage.getItem('user_status'),
			userFullName: window.sessionStorage.getItem('userFullName'),
			userPhone: window.sessionStorage.getItem('user_phone'),
			userAddress: window.sessionStorage.getItem('user_address'),
			userBio: '',
			userFirstName: window.sessionStorage.getItem('user_first_name'),
			userLastName: window.sessionStorage.getItem('user_last_name'),
			defaultUploadLabel: 'Charger ma photo de profil'
		};

		axios.get(API.API + 'get-profile/' + this.state.userId).then(res => {
			const userData = res.data.data;
			this.setState({userFullName: userData.first_name + ' ' + userData.last_name});
			this.setState({userFirstName: userData.first_name}); 
			this.setState({userLastName: userData.last_name}); 
			this.setState({userPhone: userData.phone}); 
			this.setState({userAddress: userData.address}); 
			this.setState({userBio: userData.bio}); 
		});	
	}

	toggleHandler = () => {
		this.setState((prevState, props) => ({ hasHandler: !prevState.hasHandler }));
	}

	labellizeIt = (e) => {
		let value = e.target.value.split('\\').pop()
		value && this.setState({defaultUploadLabel: value});
	}

	render() {
		return (
			<div>
				<Menu />
				<div className="profile-container">
					<div className="profile-photo" onMouseEnter={this.toggleHandler} onMouseLeave={this.toggleHandler}>
						<div 
							className={this.state.hasHandler ? "upload-image-handler" : "upload-image-handler is-hidden"} 
							data-toggle="modal" 
							data-target="#uploadImage"
						><i className="fa fa-upload"></i>
						</div> 
						<img src={require('./../../../images/user-icon.jpg')} className="profile-img" alt="Avatar"/>
					</div>

					<div className="profile-name">
						<h2 className="text-center">{this.state.userFullName}</h2>
						<p className="text-center"><em>{this.state.userStatus === 'admin' ? 'Administrateur' : this.state.userStatus === 'bakeliste' ? 'Bakeliste' : this.state.userStatus === 'coach' ? 'Coach' : ''}</em></p>
					</div>

					<span className="profile-divider"></span>
					
					<div className="profile-description">
						<p>{this.state.userBio}</p>
					</div>
					
					<div className="profile-action">
						<Link to="/editer-mon-profil" className="edit-profile">
							<i className="fa fa-edit"></i> Modifier mon profil
						</Link>
						&nbsp;&nbsp;
						<Link to="/modifier-mon-mot-de-passe" className="edit-password">
							<i className="fa fa-key"></i> Modifier mon mot de passe
						</Link>
					</div>
				</div>

				{/* Image upload modal */}
				<div className="modal fade" id="uploadImage" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">Uploader une photo de profil</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<form encType="multipart/form-data">
								<div className="modal-body">
									<div className="form-group">
										<label htmlFor="image"><i className="fa fa-upload"></i> {this.state.defaultUploadLabel}</label>
										<input onChange={(e) => this.labellizeIt(e)} type="file" name="image" id="image" className="form-control" />
									</div>
								</div>
								<div className="modal-footer">
									<button type="submit" className="btn btn-primary btn-block"><i className="fa fa-check"></i> Sauvegarder</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}

  
}

UserProfile.propTypes = {
}

export default UserProfile;