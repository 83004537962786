import React, {Component} from 'react';
//import PropTypes from 'prop-types';
import Menu from './../../menu/Menu';
//import { Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../../variablesGlobales';

class EditPasswordProfile extends Component {
  constructor(props){
    super(props);
    const userId = window.sessionStorage.getItem('user_id');
    this.state = {
      api: API.API,
      userId: userId,
      password: '',
      passwordConfirmation: '',
      hasFlash: false,
      flashMessage: '',
      flashType: 'success',
    };
  }

  componentDidMount = () => {
    document.querySelector('input#password').focus();
  }

  resetForm = () => {
    document.querySelector('input#password').value = '';
    document.querySelector('input#password_confirmation').value = '';
    document.querySelector('input#password').focus();
  }

  handleChange = (e) => {
    let value = e.target.value;
    if(e.target.name === 'password'){
      this.setState({password: value});
    }else if(e.target.name === 'password_confirmation'){
      this.setState({passwordConfirmation: value});
    }
  }

  hideAlertAfter = (duration) => {
    setTimeout(() => {
      this.setState({hasFlash: false});
    }, duration*1000);
  }

  updatePassword = (e) => {
    e.preventDefault();
    //const old_password = this.state.oldPassword;
    const password = this.state.password;
    const passwordConfirmation = this.state.passwordConfirmation;
    
    if(password.length === 0 || passwordConfirmation.length === 0) {
      this.setState({hasFlash: true,flashType: 'danger', flashMessage: 'Veuilez remplir tous les champs SVP.'});
      this.hideAlertAfter(2)
    } else {
      if(password.length <= 6) {
        this.setState({hasFlash: true,flashType: 'danger', flashMessage: 'Vous devez saisir au moins 6 caractères.'});
        this.hideAlertAfter(2)
      } else {
        if (password === passwordConfirmation) {
          axios.put(this.state.api + 'update-profile-password/' + this.state.userId, { password }).then(res => {
            if(res.data.success) {
              this.setState({hasFlash: true,flashType: 'success', flashMessage: 'Votre mot de passe a bien modifié.'});
              this.hideAlertAfter(2);
              this.resetForm();
            }
          }).catch(error => {
            console.log(error);
          })
        } else {
          this.setState({hasFlash: true,flashType: 'danger', flashMessage: 'Les mots de passe doivent identiques.'});
          this.hideAlertAfter(2)
        }
      }
      
      
    }
  }

  render(){
    return (
      <div>
        <Menu />
        <h1 className="text-center"><i className="fa fa-key"></i> Modifier mon mot de passe</h1>
        <hr />
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <form onSubmit={e => this.updatePassword(e)}>
              <div className="form-group">
                <label htmlFor="password">Nouveau mot de passe</label>
                <input type="password" name="password" id="password" className="form-control"  onChange={e => this.handleChange(e)}/>
              </div>
              <div className="form-group">
                <label htmlFor="password_confirmation">Confirmez le mot de passe</label>
                <input type="password" name="password_confirmation" id="password_confirmation" className="form-control"  onChange={e => this.handleChange(e)}/>
              </div>
              <div className="form-group">
                <button type="submit" className='btn btn-warning addBtn button submit'>
                    <i className="fas fa-edit"></i> &nbsp;
                    Enregistrer les modifications
                </button>
              </div>
            </form>
          </div>
        </div>

        {this.state.hasFlash === true &&
            <div className={`alert alert-${this.state.flashType} text-center upsuccess-alert animated bounceIn`}>
                <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                {this.state.flashMessage}
            </div>
        }
      </div>
    )
  }
}
EditPasswordProfile.propTypes = {
}

export default EditPasswordProfile;