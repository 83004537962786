import React, { options, Component } from "react";
import Menu from "../menu/Menu";
import "../livrableCoach/livrableCoach.css";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
} from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

import {
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBCol,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
} from "mdbreact";

import axios from "axios";
import API from "../../variablesGlobales";
import base_api_url from "../../variablesGlobales";
import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";
import FeatherIcon from "feather-icons-react";

import { Amplitude } from "@amplitude/react-amplitude";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import { Container, Row, Col } from "shards-react";
import { textSpanIntersectsWithPosition } from "typescript";
class LivrableCoach extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api: API.API,
      base_api_url: base_api_url.base_api_url,
      fields: {},
      livraison: [],
      is_empty_tache_file: false,
      isInvalidFileExtension: false,
      userID: window.sessionStorage.getItem("user_id"),
      bakelistesByCoach: [],
      liked: false,
      bakeliste_id: 0,
      notliked: false,
      bakeliste: [],
      showBloc: false,
      CoachComments: [],
      livrable_id: 0,
      commentEnCours: false,
      newComment: {
        commentaire: "",
        isOk: "",
        isnOk: "",
        bakeliste_id: 0,
        coach_id: 0,
        livrable_id: 0,
      },
    };
    this.getBakelisteByCoach = this.getBakelisteByCoach.bind(this);
    this.isLiked = this.isLiked.bind(this);
    this.isnotLiked = this.isnotLiked.bind(this);
    this.addComment = this.addComment.bind(this);
    this.addReactiononLivrable = this.addReactiononLivrable.bind(this);
  }

  componentDidMount() {
    this.getLivraison();
    this.getBakelisteByCoach();
  }
  getLivraison = () => {
    var api = this.state.api;
    axios.get(api + "livraison-coach/" + this.state.userID).then((res) => {
      if (res.data.length > 0) {
        const alllivraisons = res;
        const tab = [];

        for (let item of alllivraisons.data) {
          for (let i of item) {
            tab.push(i);
          }
        }
        console.log("livraison", tab);
        this.setState({
          livraison: tab,
          feedbacks: tab.feedbacks,
        });
      } else {
        console.log(this.state.livraison, "part");
      }
    });
  };

  getBakelisteByCoach() {
    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.userID)
      .then((response) => {
        var data = response.data.data;
        console.log("mes bakelistes", data);

        if (data !== undefined) {
          this.setState({
            bakelistesByCoach: data,
          });
        }
        data.forEach((item) => {
          let infos = {
            label: item.first_name + " " + item.last_name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  }

  isLiked = () => {
    this.setState({
      liked: !this.state.liked,
      notliked: false,
    });
    console.log("aimer", this.state.liked, this.state.notliked);
  };
  isnotLiked = () => {
    this.setState({
      notliked: !this.state.notliked,
      liked: false,
    });
  };

  addFeedbackonLivrable = () => {
    var isOk = this.state.liked;
    var isnOk = this.state.notliked;
  };

  addComment = (id, idl) => {
    this.setState({
      bakeliste_id: id,
      livrable_id: idl,
    });

    console.log("livrable id", idl);
  };

  addReactiononLivrable = () => {
    this.setState({
      commentEnCours: true,
    });
    let { newComment } = this.state;
    newComment.isOk = this.state.liked;
    newComment.isnOk = this.state.notliked;
    newComment.coach_id = this.state.userID;
    newComment.bakeliste_id = this.state.bakeliste_id;
    newComment.livrable_id = this.state.livrable_id;

    this.setState({ newComment });
    axios
      .post(this.state.api + "coach_livrable_feedbacks", this.state.newComment)
      .then((response) => {
        console.log(response);
        this.setState({
          commentEnCours: false,
          showBloc: false,
        });

        $(".close").click();
        document.getElementById("comment-livrable").reset();

        if (response.success === "true") {
          toast.success("Commentaire ajoute avec succès !");
        }
      })

      .catch((error) => {
        console.log(error.message);
        toast.error(
          "Oupsss! Une erreur est survenue lors de l'envoi,Veuillez reessayer"
        );
      });
  };

  showComment = () => {
    if (this.state.showBloc) {
      this.setState({
        showBloc: false,
      });
    } else {
      this.setState({
        showBloc: true,
      });
    }
    this.getLivraison();
  };

  render() {
    let stylenotOk = {},
      stylenotLike = {},
      style = {},
      styleLike = {};
    if (this.state.liked) {
      style = {
        marginTop: "5px",
        animation: "storm 2s 1s",
      };
      styleLike = {
        color: "#009688",
        animation: "like 3s",
      };
    } else {
      style = {
        backgroundColor: "#009688",
        marginBottom: "-10px",
      };
      styleLike = {
        color: "white",
      };
    }
    if (this.state.notliked) {
      stylenotOk = {
        marginTop: "5px",
        animation: "storm 2s 1s",
      };
      stylenotLike = {
        color: "#f34336",
        animation: "like 3s",
      };
    } else {
      stylenotOk = {
        backgroundColor: "#f34336",
        marginBottom: "-10px",
      };
      stylenotLike = {
        color: "white",
      };
    }

    return (
      <div>
        <Menu />
        <MDBRow className="d-flex  justify-content-center">
          <MDBCol
            md="10"
            style={{ backgroundColor: "#f8f8f8", marginTop: "15%" }}
          >
            {this.state.livraison.map((index) => (
              <MDBCard personal className="my-5" key={index.id}>
                <MDBCardImage
                  style={{ width: "100%" }}
                  top
                  src={this.state.base_api_url + index.tache_file}
                  alt="MDBCard image cap"
                />
                <MDBCardBody key={index.id}>
                  <MDBCardTitle></MDBCardTitle>
                  <MDBCardText style={{ fontSize: "16px" }}>
                    {index.commentaire}
                  </MDBCardText>

                  <p style={{ fontSize: "16px" }}>
                    Livrable envoyé par: {index.bakelisteFullname}
                  </p>
                  <div className="row btn-group" key={index.id}>
                    <span
                      data-toggle="modal"
                      data-target={`#commentLivrable${index.id}`}
                      className="hearth-circle btn-bakeli-orange"
                      title="Commenter livrable"
                      onClick={() =>
                        this.addComment(index.bakeliste_user_id, index.id)
                      }
                    >
                      <i className="fa fa-comment fa-feedback"></i>&nbsp;
                    </span>
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      marginLeft: "78%",
                    }}
                  >
                    Envoyé le: {index.created_at}
                  </p>
                  <hr />
                </MDBCardBody>
                <br />
                {/* Ajout et affichage des commentaires sur un livrable */}
                <div
                  className="modal fade"
                  id={`commentLivrable${index.id}`}
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="commentLivrableLabel"
                  data-keyboard="false"
                  data-backdrop="static"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => this.setState({ showBloc: false })}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4
                          className="modal-title"
                          id="assignSyllabusModalLabele"
                        >
                          Ajouter un commentaire
                        </h4>
                      </div>
                      <div className="modal-body">
                        <div className="comments-container">
                          <div className="toggle-btn-container">
                            <button
                              className="btn btn-primary toggle-btn"
                              onClick={this.showComment}
                            >
                              Voir les commentaires
                            </button>
                          </div>
                          {this.state.showBloc && (
                            <div className="comment-items">
                              {index.feedbacks.length !== 0 ? (
                                index.feedbacks
                                  .filter((l) => l.livrable_id === index.id)
                                  .map((f) => {
                                    return (
                                      <div key={index} className="comment-item">
                                        <b>Commentaire du {f.created_at}</b>{" "}
                                        <br />
                                        <p> {f.commentaire} </p>
                                        <hr />
                                      </div>
                                    );
                                  })
                              ) : (
                                <div className="alert alert-warning empty-comment-alert">
                                  Aucun commentaire pour cette livraison.
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <br />
                        <form id="comment-livrable">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              id="title"
                              name="title"
                              value={this.state.newComment.commentaire}
                              onChange={(e) => {
                                let { newComment } = this.state;
                                newComment.commentaire = e.target.value;
                                this.setState({ newComment });
                              }}
                            />
                          </div>

                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-default bg-vred cancel-btn pull-left"
                              data-dismiss="modal"
                              onClick={(e) =>
                                this.setState({ showBloc: false })
                              }
                            >
                              <span className="cancel_btn_label">
                                {" "}
                                Quitter{" "}
                              </span>
                              <img
                                className="cancel-icon"
                                src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png"
                                alt="Annuler"
                              />
                            </button>
                            {!this.state.commentEnCours && (
                              <button
                                type="button"
                                className="btn btn-primary btn_for_assignation button addBtn"
                                onClick={() => this.addReactiononLivrable()}
                              >
                                <i className="fas fa-user-check"></i>&nbsp;
                                Commenter
                              </button>
                            )}
                            {this.state.commentEnCours && (
                              <button
                                type="button"
                                className="btn btn-warning button addBtn btn_for_wait"
                              >
                                Loading &nbsp;
                                <i className="fas fa-spinner fa-spin"></i>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCard>
            ))}
            <br />
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
export default LivrableCoach;
