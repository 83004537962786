import React, { Component } from 'react';
import './createBakeliste.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Menu from './../menu/Menu';
import API from '../../variablesGlobales';


class createBakeliste extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');

        if (!token) {
            window.history.back()
        }
        if (userStatus === 'bakeliste' || userStatus === 'coach') {
            window.history.back();
        }
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.state = {
            api: API.API,
            fields: {},
            _phFields: {},
            errors: {},
            value: '',
            domaines: [],
            loading: false,
            isPro: false,
            isEtudiant: false,
            isFormationCourte: false,
            isFormationDiplomante: false,
            openStatutProfessionnelFC: false,
            openStatutFD: false,
            isSemaine: false,
            requestIsDone: false,
            fn_empty: false,
            ln_empty: false,
            email_empty: false,
            phone_empty: false,
            tf_empty: false,
            boursier_empty: false,
            lf_empty: false,
            df_empty: false,
            paiement_empty: false,
            fs_empty: false,
            ecole_empty: false,
            obj_empty: false,
            sp_empty: false,
            pe_empty: false,
            j1_empty: false,
            j2_empty: false,
            j3_empty: false,
            civility_empty: false,
            ms_empty: false,
            address_empty: false,
            isEtudiantSemaineChoice: false,
            isEtudiantSemaineLSChoice: false,
            isExistUser: false,
            isSuccessAddedBakelisteAlert: false,
            level_empty: false,


        };

    }
    componentDidMount() {
        axios.get(this.state.api + 'domaines')
            .then(res => {
                const domains = res.data;
                this.setState({
                    domaines: domains.data,
                });
            })
            
    }
    

    handleShowAndHideAlert(input){
        setTimeout(() => {
            if(input === 'firstName'){
                this.setState({
                    fn_empty: false
                });
            }
            if(input === 'lastName'){
                this.setState({
                    ln_empty: false
                });
            }
            if(input === 'email'){
                this.setState({
                    email_empty: false
                });
            }
            if(input === 'phone'){
                this.setState({
                    phone_empty: false
                });
            }
            if(input === 'type_formation'){
                this.setState({
                    tf_empty: false
                });
            }
            if(input === 'ispro'){
                this.setState({
                    ispro_empty: false
                });
            }
            if(input === 'domaine_formation'){
                this.setState({
                    df_empty: false
                });
            }
            if(input === 'formation_diplomante'){
                this.setState({
                    fd_empty: false
                });
            }
            if(input === 'paiement_inscription'){
                this.setState({
                    paiement_empty: false
                });
            }
            if(input === 'formation_suivie'){
                this.setState({
                    fs_empty: false
                });
            }
            
            if(input === 'objectifs'){
                this.setState({
                    obj_empty: false
                });
            }
            if(input === 'lieu_formation'){
                this.setState({
                    lf_empty: false
                });
            }
            if(input === 'boursier'){
                this.setState({
                    boursier_empty: false
                });
            }
            if(input === 'statut_professionnel'){
                this.setState({
                    sp_empty: false
                });
            }
            if(input === 'pro_etudiant'){
                this.setState({
                    pe_empty: false
                });
            }
            if(input === 'jour_1'){
                this.setState({
                    j1_empty: false
                });
            }
            if(input === 'jour_2'){
                this.setState({
                    j2_empty: false
                });
            }
            if(input === 'jour_3'){
                this.setState({
                    j3_empty: false
                });
            }
            if(input === 'civility'){
                this.setState({
                    civility_empty: false
                });
            }
            if(input === 'marital_status'){
                this.setState({
                    ms_empty: false
                });
            }
            if(input === 'address'){
                this.setState({
                    address_empty: false
                });
            }
            if(input === 'bakeliste_level'){
                this.setState({
                    level_empty: false
                });
            }
            if(input === 'isExistUser'){
                this.setState({
                    isExistUser: false
                })
            }
        }, 5000);
    }


    validateForm() {
        let fields = this.state.fields;
        let _phFields = this.state._phFields;
        let errors = {};
        let formIsValid = true;
        if (!fields["civility"]) {
            console.log('civility')
            formIsValid = false;
            this.setState({
                civility_empty: true
            })
            this.handleShowAndHideAlert('civility');
            errors["civility"] = "*Ce champ est obligatoire";
        }
        if (!fields["marital_status"]) {
            console.log('marital')
            formIsValid = false;
            this.setState({
                ms_empty: true
            })
            this.handleShowAndHideAlert('marital_status');
            errors["marital_status"] = "*Ce champ est obligatoire";
        }
        if (!fields["first_name"]) {
            console.log('first_name')
            formIsValid = false;
            this.setState({
                fn_empty: true
            })
            this.handleShowAndHideAlert('firstName');
            errors["first_name"] = "*Ce champ est obligatoire";
        }

        if (typeof fields["first_name"] !== "undefined") {
            console.log('first_name2')
            if (!fields["first_name"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
                formIsValid = false;
                this.setState({
                    fn_empty: true
                })
                this.handleShowAndHideAlert('firstName');
                errors["first_name"] = "*SVP que des lettres.";
            }
        }

        if (!fields["last_name"]) {
            console.log('last_name')
            formIsValid = false;
            this.setState({
                ln_empty: true
            })
            this.handleShowAndHideAlert('lastName');
            errors["last_name"] = "*Ce champ est obligatoire";
        }

        if (typeof fields["last_name"] !== "undefined") {
            console.log('last_name2')
            if (!fields["last_name"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
                formIsValid = false;
                this.setState({
                    ln_empty: true
                })
                this.handleShowAndHideAlert('lastName');
                errors["last_name"] = "*SVP que des lettres.";
            }
        }

        if (!fields["email"]) {
            console.log('email')
            formIsValid = false;
            this.setState({
                email_empty: true
            })
            this.handleShowAndHideAlert('email');
            errors["email"] = "*Ce champ est obligatoire.";
        }

        if (typeof fields["email"] !== "undefined") {
            console.log('rmail2')
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                this.setState({
                    email_empty: true
                })
                this.handleShowAndHideAlert('email');
                errors["email"] = "*Adresse email invalide.";
            }
        }

        if (!fields["phone"]) {
            console.log('phone')
            formIsValid = false;
            this.setState({
                phone_empty: true
            })
            this.handleShowAndHideAlert('phone');
            errors["phone"] = "*Ce champ est obligatoire.";
        }

        if (!fields["bakeliste_level"]) {
            console.log('bakeliste_level')
            formIsValid = false;
            this.setState({
                level_empty: true
            })
            this.handleShowAndHideAlert('bakeliste_level');
            errors["bakeliste_level"] = "*Ce champ est obligatoire.";
        }

        // if (typeof fields["phone"] !== "undefined") {
        //     if (!fields["phone"].match(/^[0-9]{9}$/)) {
        //         formIsValid = false;
        //         errors["phone"] = "*Numéro de telephone invalide.";
        //     }
        // }

        if (!fields["address"]) {
            console.log('address')
            formIsValid = false;
            this.setState({
                address_empty: true
            })
            this.handleShowAndHideAlert('address');
            errors["address"] = "*Ce champ est obligatoire";
        }
        if (!fields["boursier"]) {
            console.log('boursier')
            formIsValid = false;
            this.setState({
                boursier_empty: true
            })
            this.handleShowAndHideAlert('boursier');
            errors["boursier"] = "*Ce champ est obligatoire.";
        }
        if (!fields["type_formation"]) {
            console.log('type_formation')
            formIsValid = false;
            this.setState({
                tf_empty: true
            })
            this.handleShowAndHideAlert('type_formation');
            errors["type_formation"] = "*Ce champ est obligatoire.";
        }
        if (!fields["lieu_formation"]) {
            console.log('lieu_dormation')
            formIsValid = false;
            this.setState({
                lf_empty: true
            })
            this.handleShowAndHideAlert('lieu_formation');
            errors["lieu_formation"] = "*Ce champ est obligatoire.";
        }
        if (!_phFields["bakeliste_domaine_id"]) {
            console.log('domaine_id')
            formIsValid = false;
            this.setState({
                df_empty: true
            })
            this.handleShowAndHideAlert('domaine_formation');
            errors["domaine_formation"] = "*Ce champ est obligatoire.";
        }
        if (!fields["paiement_inscription"]) {
            console.log('paiement inscription')
            formIsValid = false;
            this.setState({
                paiement_empty: true
            })
            this.handleShowAndHideAlert('paiement_inscription');
            errors["paiement_inscription"] = "*Ce champ est obligatoire.";
        }
        if (!fields["formation_suivie"]) {
            console.log('formation suivie')
            formIsValid = false;
            this.setState({
                fs_empty: true
            })
            this.handleShowAndHideAlert('formation_suivie');
            errors["formation_suivie"] = "*Ce champ est obligatoire.";
        }
        
        
        if (!fields["objectifs"]) {
            console.log('objectifs')
            formIsValid = false;
            this.setState({
                obj_empty: true
            })
            this.handleShowAndHideAlert('objectifs');
            errors["objectifs"] = "*Ce champ est obligatoire.";
        }
        if (fields["type_formation"] && fields["type_formation"] === 'courte' && !fields["isproetudiant"]) {
            console.log('jour is pro etudiant')
            formIsValid = false;
            this.setState({
                sp_empty: true
            })
            this.handleShowAndHideAlert('statut_professionnel');
            errors["statut_professionnel"] = "*Vous devez choisir un statut professionnel.";
        }
        if (fields["type_formation"] && fields["isproetudiant"] && fields["isproetudiant"] === 'professionnel' && !fields["isWE"]) {
            console.log('jour is week end')
            formIsValid = false;
            this.setState({
                pe_empty: true
            })
            this.handleShowAndHideAlert('pro_etudiant');
         
            errors["pro_etudiant"] = "*Vous devez choisir un type de présence.";
        }
        if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isWE"] === 'semaine' && !fields["jour_1"]) {
            console.log('jour 1')
            formIsValid = false;
            this.setState({
                j1_empty: true
            })
            this.handleShowAndHideAlert('jour_1');
            errors["jour_1"] = "*Ce champ ne doit pas être vide.";
        }
        if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isWE"] === 'semaine' && !fields["jour_2"]) {
            console.log('jour 2')
            formIsValid = false;
            this.setState({
                j2_empty: true
            })
            this.handleShowAndHideAlert('jour_2');
            errors["jour_2"] = "*Ce champ ne doit pas être vide.";
        }
        if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isproetudiant"] === 'etudiant' && !fields["jour_3"]) {
            console.log('jour 3')
            formIsValid = false;
            this.setState({
                j3_empty: true
            })
            this.handleShowAndHideAlert('jour_3');
            errors["jour_3"] = "*Ce champ ne doit pas être vide.";
        }
        // if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isproetudiant"] == 'etudiant' && !fields["jour_2"]) {
        //     console.log('jour 2')
        //     formIsValid = false;
        //     this.state.j2_empty = true;
        //     this.handleShowAndHideAlert('jour_2');
        //     errors["jour_2"] = "*Ce champ ne doit pas être vide.";
        // }
        this.setState({
            errors: errors
        });
        console.log(formIsValid)
        return formIsValid;
    }

    handleChange(e) {
        let fields = this.state.fields;
        let _phFields = this.state._phFields;

        fields[e.target.name] = e.target.value;
        _phFields[e.target.name] = e.target.value;
        console.log("handle change", e.target.value)
        if (e.target.name === "bakeliste_domaine_id") {
            this.state.domaines.forEach((x) => {
                if (x.name === e.target.value) {
                    this.setState({
                        idSelect: x.id
                    })
                }
            })
            fields[e.target.name] = this.state.idSelect;
        }
        if (e.target.name === 'type_formation') {
            fields[e.target.name] = e.target.value;
            if (e.target.value === 'courte') {
                _phFields["jour_fd"] = "";
                this.setState({
                    openStatutProfessionnelFC: true,
                    openStatutFD: false
                });
            }else if(e.target.value === 'diplomante'){
                _phFields["nbre_jours"] = 5;
                _phFields["is_etudiant"] = 1;
                _phFields["is_week"] = 1;
                _phFields["is_weekend"] = 0;
                _phFields["jour_fd"] = "lundi au vendredi";
                _phFields["jour_1"] = "Lundi";
                _phFields["jour_2"] = "Mardi";
                _phFields["jour_3"] = "Mercredi";
                _phFields["jour_4"] = "Jeudi";
                _phFields["jour_5"] = "Vendredi";
                _phFields["jour_6"] = "";
                this.setState({
                    openStatutProfessionnelFC: false,
                    openStatutFD: true,
                    isSemaine: false,
                    isPro: false,
                    isEtudiant: false
                })
            }

        }
        if(e.target.name === 'isproetudiant'){
            if(e.target.value === 'professionnel' ){
                _phFields["is_pro"] = 1;  
                _phFields["is_etudiant"] = 0;                
                this.setState({
                    isPro: true,
                    isEtudiant: false,
                    isEtudiantSemaineChoice: false,
                    isEtudiantSemaineLSChoice: false
                });
            }else if(e.target.value === 'etudiant'){
                _phFields["is_etudiant_a_tester"] = 0;
                _phFields["is_etudiant"] = 1;
                _phFields["is_pro"] = 0; 
                _phFields["nbre_jours"] = 3;
                _phFields["jour_4"] = "";
                _phFields["jour_5"] = "";
                _phFields["jour_6"] = "";
                this.setState({
                    isPro: false,
                    isEtudiant: true,
                    isSemaine: false
                });
            }else if(e.target.value === 'a_tester'){
                _phFields["is_etudiant_a_tester"] = 1;
                _phFields["is_etudiant"] = 1;
                _phFields["is_pro"] = 0; 
                _phFields["nbre_jours"] = 3;
                _phFields["jour_4"] = "";
                _phFields["jour_5"] = "";
                _phFields["jour_6"] = "";
                this.setState({
                    isPro: false,
                    isEtudiant: true,
                    isSemaine: false
                });
            }
        }
        if(e.target.name === 'isWE'){
            if(e.target.value === 'semaine'){
                _phFields["is_etudiant"] = 0;
                _phFields["is_week"] = 1;
                _phFields["is_weekend"] = 0;
                _phFields["nbre_jours"] = 2;
                _phFields["horaire"] = "17h30-19h30";
                _phFields["jour_3"] = "";
                _phFields["jour_4"] = "";
                _phFields["jour_5"] = "";
                _phFields["jour_6"] = "";
                this.setState({
                    isSemaine: true
                });
            }
            else if(e.target.value === 'weekend'){
                _phFields["is_weekend"] = 1;
                _phFields["is_week"] = 0;
                _phFields["horaire"] = '9h-13h';
                _phFields["nbre_jours"] = 1;
                _phFields["jour_1"] = "Samedi";
                _phFields["jour_2"] = "";
                _phFields["jour_3"] = "";
                _phFields["jour_4"] = "";
                _phFields["jour_5"] = "";
                _phFields["jour_6"] = "";
                this.setState({
                    isSemaine: false
                });
            }
            if(e.target.name === 'email'){
                _phFields["email"] = e.target.value;
            }
        }
        if(e.target.name === 'isWEE'){
            if(e.target.value === 'semaine'){
                _phFields["is_etudiant"] = 1;
                _phFields["is_week"] = 1;
                _phFields["is_weekend"] = 0;
                _phFields["nbre_jours"] = 3;
                _phFields["jour_4"] = "";
                _phFields["jour_5"] = "";
                _phFields["jour_6"] = "";
                this.setState({
                    isEtudiantSemaineChoice: true,
                    isEtudiantSemaineLSChoice: false
                })
            }
            else if(e.target.value === 'weekend'){
                _phFields["is_weekend"] = 1;
                
                _phFields["is_week"] = 0;
                _phFields["horaire"] = '9h-17h';
                _phFields["nbre_jours"] = 2;
                _phFields["jour_1"] = "Samedi";
                _phFields["jour_2"] = "Dimanche";
                _phFields["jour_3"] = "";
                _phFields["jour_4"] = "";
                _phFields["jour_5"] = "";
                _phFields["jour_6"] = "";
                this.setState({
                    isEtudiantSemaineChoice: false,
                    isEtudiantSemaineLSChoice: false
                })

            }else if(e.target.value === 'semainels'){
                _phFields["is_etudiant"] = 1;
                _phFields["is_week"] = 1;
                _phFields["is_weekend"] = 0;
                _phFields["nbre_jours"] = 5;
                _phFields["jour_1"] = "Lundi";
                _phFields["jour_2"] = "Mardi";
                _phFields["jour_3"] = "Mercredi";
                _phFields["jour_4"] = "Jeudi";
                _phFields["jour_5"] = "Vendredi";
                _phFields["jour_6"] = "";
                console.log(_phFields)
                
                this.setState({
                    isEtudiantSemaineChoice: false,
                    isEtudiantSemaineLSChoice: true
                })
            }
        }
        this.setState({
            fields,
            _phFields
        });
        console.log("handle change", this.state.fields)
        console.log("phFields", this.state._phFields)
    }

    submituserRegistrationForm(e) {
        e.preventDefault();

        if (this.validateForm()) {
            console.log("hello")
            this.setState({
                loading: true
            });

            // this.setState({ fields: fields, _phFields: _phFields });
            const dataBakeliste = this.state.fields;
            const dataPHBakeliste = this.state._phFields;
            console.log(dataBakeliste);
            console.log(dataPHBakeliste);
            axios.post(this.state.api + 'add-bakeliste', dataPHBakeliste)
                .then(res => {
                    console.log(res)
                    if (res.data.success) {
                        // this.createNewBakelistePH(dataPHBakeliste); 
                        
                        this.setState({
                            isSuccessAddedBakelisteAlert: true
                        }) 
                        setTimeout(() => {
                            window.location = 'liste-bakelistes';   
                        }, 5000)   
                        this.setState({
                            loading: false
                        })   
                    }else if(res.data.success === false && res.data.exist === true){
                        this.setState({
                            loading: false,
                            isExistUser: true
                        })
                        this.handleShowAndHideAlert('isExistUser');
                    }
                    else {
                        console.log(res)
                        this.setState({
                            loading: false,
                        });
                    }
                })
                .catch(error => {
                    console.log(error.message)
                    this.setState({
                        loading: false
                    })
                })
            
        }
    }

    // createNewBakelistePH(data){
    //     axios.post(this.state.api + 'saved-bakeliste-ph', data)
    //         .then((response) => {
    //             console.log('ph response', response);          
    //         })
    //         .catch((error) => {
    //             console.log('Error ', error)
    //         })
    // }
    render() {
        return (
            <div>
                <Menu />

                <div className="createBakeliste row" >
                    <div className="col-lg-12">
                        <h1 className="page-header">
                            <span className="page-title">Ajouter un Bakeliste</span>
                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard-admin">Accueil</Link>
                                    </li>
                                    <li className="active">Ajouter un Bakeliste</li>
                                </ol>
                            </div>
                        </h1>
                    </div>
                    <div className="col-md-8 col-md-offset-2 add_bakeliste_form_container">
                        <form onSubmit={this.submituserRegistrationForm}  >
                                <div className="form-row row">
                                    <div>
                                        <h1 id="titreCoach">Ajouter un Bakeliste</h1>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Civilité</label>
                                            <select name="civility" className="form-control" id="civilityInput" value={this.state.fields.civility} onChange={this.handleChange}>
                                                <option> --Choisir un statut -- </option>
                                                <option value="Monsieur"> Monsieur </option>
                                                <option value="Madame"> Madame </option>
                                                <option value="Mademoiselle"> Mademoiselle </option>
                                            </select>
                                            {this.state.civility_empty &&
                                            <div className="errorMsg">{this.state.errors.civility}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Situation matrimoniale</label>
                                            <select name="marital_status" className="form-control" id="marital_statusInput" value={this.state.fields.marital_status} onChange={this.handleChange}>
                                                <option> --Choisir un statut -- </option>
                                                <option value="Marié(e)"> Marié(e) </option>
                                                <option value="Celibataire"> Célibataire </option>
                                            </select>
                                        </div>
                                        {this.state.ms_empty &&
                                            <div className="errorMsg">{this.state.errors.marital_status}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label >Prénom:</label>
                                            <input type="text" className="form-control" id="inputpFirstName" placeholder="Prénom" name="first_name" value={this.state.fields.first_name} onChange={this.handleChange} />
                                        </div>
                                        {this.state.fn_empty &&
                                            <div className="errorMsg">{this.state.errors.first_name}</div>
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label >Nom:</label>
                                            <input type="text" className="form-control" id="inputLastName" placeholder="Nom" name="last_name" value={this.state.fields.last_name} onChange={this.handleChange} />
                                        </div>
                                        {this.state.ln_empty &&
                                            <div className="errorMsg">{this.state.errors.last_name}</div>
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Email:</label>
                                            <input type="Email" className="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.fields.email} onChange={this.handleChange} />
                                        </div>
                                        {this.state.email_empty &&
                                            <div className="errorMsg">{this.state.errors.email}</div>
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label >Téléphone:</label>
                                            <input type="text" className="form-control" id="inputNumero" placeholder="Téléphone" name="phone" onChange={this.handleChange} />
                                        </div>
                                        {this.state.phone_empty &&
                                            <div className="errorMsg">{this.state.errors.phone}</div>
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label >Adresse:</label>
                                            <input type="text" className="form-control" id="inputAdresse" placeholder="Adresse du bakeliste" name="address" onChange={this.handleChange} />
                                        </div>
                                        {this.state.address_empty &&
                                            <div className="errorMsg">{this.state.errors.address}</div>
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label >Formation suivie:</label>
                                            <input type="text" className="form-control" id="inputformationsuivie" placeholder="Formation suivie" name="formation_suivie" onChange={this.handleChange} />
                                        </div>
                                        {this.state.fs_empty &&
                                            <div className="errorMsg">{this.state.errors.formation_suivie}</div>
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Ecole:</label>
                                            <input type="text" className="form-control" id="inputEcole" placeholder="Ecole" name="ecole" onChange={this.handleChange} />
                                        </div>
                                        {this.state.ecole_empty &&
                                            <div className="errorMsg">{this.state.errors.ecole}</div>
                                        }
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group " >
                                            <label >Objectifs:</label>
                                            <textarea className="form-control" id="inputObjectifs" placeholder="Objectifs" name="objectifs" onChange={this.handleChange} ></textarea>
                                        </div>
                                        {this.state.obj_empty && 
                                            <div className="errorMsg">{this.state.errors.objectifs}</div>
                                        }
                                    </div>
                                    
                                    <div className="col-md-12">
                                        <div className="form-group conditionnal-input" >
                                            <label >Formation:</label> <br />
                                            <input type="radio" className="radio-bsi" name="type_formation" value="courte" onChange={this.handleChange} />
                                            <span>Formation courte</span> <br />
                                            <input type="radio" className="radio-bsi" name="type_formation" value="diplomante" onChange={this.handleChange} />
                                            <span>Formation diplômante</span>
                                        </div>
                                        {this.state.tf_empty &&
                                            <div className="errorMsg">{this.state.errors.type_formation}</div>
                                        }
                                    </div>

                                    {this.state.openStatutProfessionnelFC && 
                                        <div className="pro_etudiant_container col-md-12">
                                            <div className="form-group">
                                                <label>Statut professionnel</label> <br />
                                                <input type="radio" className="radio-bsi" name="isproetudiant" value="professionnel" onChange={this.handleChange} />
                                                <span>Professionnel</span> <br />
                                                <input type="radio" className="radio-bsi" name="isproetudiant" value="etudiant" onChange={this.handleChange} />
                                                <span>Etudiant</span> <br />
                                                <input type="radio" className="radio-bsi" name="isproetudiant" value="a_tester" onChange={this.handleChange} />
                                                <span>Etudiant à tester</span> <br />
                                            </div>
                                            {this.state.sp_empty &&
                                                <div className="errorMsg">{this.state.errors.statut_professionnel}</div>
                                            }
                                        </div>
                                    }

                                    {this.state.isEtudiant &&
                                        <div className="etudiant_jhp_container col-md-12">
                                            <div className="form-group">
                                                <label>Type de présence : </label> <br />
                                                <input type="radio" className="radio-bsi" name="isWEE" value="semaine" onChange={this.handleChange} />
                                                <span>Semaine (3 jours de présence )</span> <br />
                                                <input type="radio" className="radio-bsi" name="isWEE" value="weekend" onChange={this.handleChange} />
                                                <span>Week-End (Samedi et Dimanche de 9h à 17h)</span> <br />
                                                <input type="radio" className="radio-bsi" name="isWEE" value="semainels" onChange={this.handleChange} />
                                                <span>Semaine (Du Lundi au Vendredi )</span>
                                            </div>
                                            {this.state.pe_empty &&
                                                <div className="errorMsg">{this.state.errors.pro_etudiant}</div>
                                            }
                                        </div>
                                    }

                                    {this.state.isEtudiantSemaineLSChoice && 
                                        <div className="etudiant_jhp_container col-md-12">
                                            <div className="form-group">
                                                <label >Choix horaire :</label>
                                                <select className="form-control" id="horaire" name="horaire" onChange={this.handleChange}>
                                                    <option> --Choisir un horaire-- </option>
                                                    <option value="8h-17h">8h - 17h</option>
                                                    <option value="8h-12h30">8h - 12h30</option>
                                                    <option value="12h30-17h">12h30 - 17h</option>
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    {this.state.isEtudiantSemaineChoice && 
                                        <div className="etudiant_jhp_container">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Choix horaire :</label>
                                                <select className="form-control" id="horaire" name="horaire" onChange={this.handleChange}>
                                                    <option> --Choisir un horaire-- </option>
                                                    <option value="8h-17h">8h - 17h</option>
                                                    <option value="8h-12h30">8h - 12h30</option>
                                                    <option value="12h30-17h">12h30 - 17h</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Choix Jour 1:</label>
                                                <select className="form-control" id="jour_1" name="jour_1" onChange={this.handleChange}>
                                                    <option> --Choisir un jour-- </option>
                                                    <option value="Lundi">Lundi</option>
                                                    <option value="Mardi">Mardi</option>
                                                    <option value="Mercredi">Mercredi</option>
                                                    <option value="Jeudi">Jeudi</option>
                                                    <option value="Vendredi">Vendredi</option>
                                                    <option value="Samedi">Samedi</option>
                                                </select>
                                            </div>
                                            {this.state.j1_empty &&
                                                <div className="errorMsg">{this.state.errors.jour_1}</div>
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Choix Jour 2:</label>
                                                <select className="form-control" id="jour_2" name="jour_2" onChange={this.handleChange}>
                                                    <option> --Choisir un jour-- </option>
                                                    <option value="Lundi">Lundi</option>
                                                    <option value="Mardi">Mardi</option>
                                                    <option value="Mercredi">Mercredi</option>
                                                    <option value="Jeudi">Jeudi</option>
                                                    <option value="Vendredi">Vendredi</option>
                                                    <option value="Samedi">Samedi</option>
                                                </select>
                                            </div>
                                            {this.state.j2_empty &&
                                                <div className="errorMsg">{this.state.errors.jour_2}</div>
                                            }
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Choix Jour 3:</label>
                                                <select className="form-control" id="jour_3" name="jour_3" onChange={this.handleChange}>
                                                    <option> --Choisir un jour-- </option>
                                                    <option value="Lundi">Lundi</option>
                                                    <option value="Mardi">Mardi</option>
                                                    <option value="Mercredi">Mercredi</option>
                                                    <option value="Jeudi">Jeudi</option>
                                                    <option value="Vendredi">Vendredi</option>
                                                    <option value="Samedi">Samedi</option>
                                                </select>
                                            </div>
                                            {this.state.j3_empty &&
                                                <div className="errorMsg">{this.state.errors.jour_3}</div>
                                            }
                                        </div>
                                    </div>
                                    }

                                    {this.state.isPro && 
                                        <div className="pro_jhp_container col-md-12">
                                            <div className="form-group">
                                                <label>Type de présence : </label> <br />
                                                <input type="radio" className="radio-bsi" name="isWE" value="semaine" onChange={this.handleChange} />
                                                <span>Semaine (2 jours de présence de 17h30 à 19h30)</span> <br />
                                                <input type="radio" className="radio-bsi" name="isWE" value="weekend" onChange={this.handleChange} />
                                                <span>Week-End (Samedi de 9h à 13h)</span>
                                            </div>
                                            {this.state.pe_empty &&
                                                <div className="errorMsg">{this.state.errors.pro_etudiant}</div>
                                            }
                                        </div>
                                    }
                                    {this.state.isSemaine &&
                                        <div className="jour_presence_container">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Choix Jour 1:</label>
                                                    <select className="form-control" id="jour_1" name="jour_1" onChange={this.handleChange}>
                                                        <option> --Choisir un jour-- </option>
                                                        <option value="Lundi">Lundi</option>
                                                        <option value="Mardi">Mardi</option>
                                                        <option value="Mercredi">Mercredi</option>
                                                        <option value="Jeudi">Jeudi</option>
                                                        <option value="Vendredi">Vendredi</option>
                                                    </select>
                                                </div>
                                                {this.state.j1_empty &&
                                                    <div className="errorMsg">{this.state.errors.jour_1}</div>
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label >Choix Jour 2:</label>
                                                    <select className="form-control" id="jour_1" name="jour_2" onChange={this.handleChange}>
                                                        <option> --Choisir un jour-- </option>
                                                        <option value="Lundi">Lundi</option>
                                                        <option value="Mardi">Mardi</option>
                                                        <option value="Mercredi">Mercredi</option>
                                                        <option value="Jeudi">Jeudi</option>
                                                        <option value="Vendredi">Vendredi</option>
                                                    </select>
                                                </div>
                                                {this.state.j2_empty &&
                                                    <div className="errorMsg">{this.state.errors.jour_2}</div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {this.state.openStatutFD && 
                                        <div className="fd_choice_container col-md-12">
                                            <div className="form-group">
                                                <label >Choix horaire : (Du Lundi au Vendredi) </label>
                                                <select className="form-control" id="horaire" name="horaire" onChange={this.handleChange}>
                                                    <option> --Choisir un horaire-- </option>
                                                    <option value="8h-12h30">8h - 12h30</option>
                                                    <option value="12h30-17h">12h30 - 17h</option>
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Boursier:</label>
                                            <select className="form-control" id="inputBoursier" name="boursier" onChange={this.handleChange}>
                                                <option> --Choisir une option-- </option>
                                                <option value={1}>OUI</option>
                                                <option value={0}>NON</option>
                                            </select>
                                        </div>
                                        {this.state.boursier_empty &&
                                            <div className="errorMsg">{this.state.errors.boursier}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Demi-Boursier:</label>
                                            <select className="form-control" id="inputDBoursier" name="demi_boursier" onChange={this.handleChange}>
                                                <option> --Choisir une option-- </option>
                                                <option value={1}>OUI</option>
                                                <option value={0}>NON</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label>Formation payante :</label>
                                            <select className="form-control" id="inputFormationP" name="is_formation_payante" onChange={this.handleChange}>
                                                <option> --Choisir une option-- </option>
                                                <option value={1}>OUI</option>
                                                <option value={0}>NON</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Stage de formation :</label>
                                            <select className="form-control" id="inputSFormation" name="is_stagede_formation" onChange={this.handleChange}>
                                                <option> --Choisir une option-- </option>
                                                <option value={1}>OUI</option>
                                                <option value={0}>NON</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Contrat ISA :</label>
                                            <select className="form-control" id="inputISA" name="is_isa_contract" onChange={this.handleChange}>
                                                <option> --Choisir une option-- </option>
                                                <option value={1}>OUI</option>
                                                <option value={0}>NON</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Lieu de formation:</label>
                                            <select className="form-control" id="inputlieu_formation" name="lieu_formation" onChange={this.handleChange}>
                                                <option> --Choisir un lieu-- </option>
                                                <option value="Sicap Foire">Sicap Foire</option>
                                                <option value="Grand Yoff 1">Grand Yoff 1</option>
                                                <option value="Grand Yoff 2">Grand Yoff 2</option>
                                                <option value="Thiés">Thiés</option>
                                                <option value="Guinée">Labé (Guinée)</option>
                                            </select>
                                        </div>
                                        {this.state.lf_empty &&
                                            <div className="errorMsg">{this.state.errors.lieu_formation}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Domaine de formation:</label>
                                            <select className="form-control" id="inputbakeli_domaine_id" name="bakeliste_domaine_id" onChange={this.handleChange}>
                                                
                                            <option> --Choisir un domaine-- </option>    
                                                {this.state.domaines.map((x, y) => <option value={x.id} key={y} >{x.name}</option>)}
                                            </select>
                                        </div>
                                        {this.state.df_empty &&
                                            <div className="errorMsg">{this.state.errors.domaine_formation}</div>
                                        }
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Niveau par rapport au domaine choisi :</label>
                                            <select className="form-control" id="inputpaiement_inscriptionvg" name="bakeliste_level" onChange={this.handleChange}>
                                                <option> --Choisir un niveau-- </option>
                                                <option value={'débutant'}>Débutant</option>
                                                <option value={'intermédiaire'}>Intermédiaire</option>
                                                <option value={'avancé'}>Avancé</option>
                                            </select>
                                        </div>
                                        {this.state.level_empty &&
                                            <div className="errorMsg">{this.state.errors.bakeliste_level}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Paiement inscription :</label>
                                            <select className="form-control" id="inputSFormationvg" name="paiement_inscription" onChange={this.handleChange}>
                                                <option> --Choisir une option-- </option>
                                                <option value={1}>OUI</option>
                                                <option value={0}>NON</option>
                                            </select>
                                        </div>
                                        {this.state.paiement_empty &&
                                            <div className="errorMsg">{this.state.errors.paiement_inscription}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Offre spéciale :</label>
                                            <select className="form-control" id="inputSFormationvg" name="offre_speciale" onChange={this.handleChange}>
                                                <option> --Choisir une option-- </option>
                                                <option value={1}>OUI</option>
                                                <option value={0}>NON</option>
                                            </select>
                                        </div>
                                          {this.state.level_empty &&
                                            <div className="errorMsg">{this.state.errors.bakeliste_level}</div>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label >Date de début:</label>
                                            <input type="date" className="form-control" id="inputdebut_formation" name="debut_formation" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label >Date de fin:</label>
                                            <input type="date" className="form-control" id="inputfin_formation" name="fin_formation" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    
                                     <div className="col-md-12">
                                        <div className="form-group " >
                                            <label >Commentaire:</label>
                                            <textarea className="form-control" id="inputCommentaire" placeholder="Ajouter un commentaire" name="commentaire" onChange={this.handleChange} ></textarea>
                                        </div>
                                        {this.state.obj_empty && 
                                            <div className="errorMsg">{this.state.errors.commentaire}</div>
                                        }
                                    </div>
                                    {this.state.isSuccessAddedBakelisteAlert &&
                                        <div className="col-md-12 add_success_alerty_container">
                                            <div className="form-group alerty-group">
                                                <div className="alert alert-success add_success_alerty text-center">
                                                    Le bakeliste a été bien enregistré. Vous allez être redirigé dans 5 secondes ...
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-8 col-md-offset-2 submit-btn-container">
                                        <div className="form-group " >
                                            {!this.state.loading ?
                                                <button type="submit" className='btn btn-warning add-item-btn'>
                                                    <i className="fas fa-plus-circle"></i> &nbsp;
                                                    Ajouter
                                                </button>:
                                                <button type="submit" className='btn btn-warning loading-btn'>
                                                    Envoie en cours &nbsp;
                                                    <i className="fas fa-spin fa-spinner"></i>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                        </form>
                    </div>
                </div>

                {/* Exist user error toast alert */}
                {this.state.isExistUser &&
                    <div className="alert alert-danger text-center toast-error-alert">
                        <i className="fas fa-exclamation-triangle"></i> &nbsp;&nbsp;
                        Cet utilisateur avec ce mail existe déjà dans le système. <br />
                        Merci de le réintégrer !
                    </div>
                }
            </div>
        );
    }
}

export default createBakeliste;

