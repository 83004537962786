import React, { Component } from "react";
import "././listeUtilisateurs.scss";
import "./listeUtilisateur.css";
import { Link, NavLink } from "react-router-dom";
import API from "../../variablesGlobales";
import axios from "axios";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import Menu from "./../menu/Menu";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import $ from "jquery";
import "../../../node_modules/admin-lte/dist/css/AdminLTE.min.css";
import "../../../node_modules/admin-lte/dist/css/AdminLTE.css";
import "../../../node_modules/admin-lte/dist/css/skins/_all-skins.css";
import * as Sentry from "@sentry/browser";
import { Icon } from "semantic-ui-react";
import { Form, TextArea } from "semantic-ui-react-form-validator";
import COACH from "../listeCoach/listeCoach.js";
import Baklist from "../liste-bakelistes/ListeBakelistes";

export default class ListeUtilisateurs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: false,
      isLoading: false,
      api: API.API,
      users: [],
      bakelistes: [],
      bakeliste: [],
      coachs: [],
      nouveaux: [],
      domaines: [],
      participants: [],
      user: [],
      selectedBakelist: [],
      dataBakeliste: {},
      upFields: {},
      fields: {},
      bakelisteIDForUpdate: 0,
      bakelisteFullname: "",
      bakelisteID: 0,
      BakelisteFullname: "",
      bakeliste_fn: "",
      bakeliste_ln: "",
      BakelisteEmail: "",
      BakelisteDomaine: "",
      BakelisteEcole: "",
      BakelistePhone: "",
      BakelisteDebutFormation: "",
      BakelisteFinFormation: "",
      BakelistecoachFullname: "",
      emailValue: "",
      phoneValue: "",
      boursierValue: false,
      demiBoursierValue: false,
      adresseValue: "",
      ecoleValue: "",
      stageFormationValue: false,
      isISAContractValue: false,
      lieuFormationValue: "",
      horaireValue: "",
      paiementInscriptionValue: false,
      debutFormationValue: "",
      finFormationValue: "",
      firstNameValue: "",
      lastNameValue: "",
      loading: true,
      getBakelisteRequestError: false,
      isEmptyData: false,
      updateInProgress: false,
      successAlert: false,
      updateBakelisteRequestError: false,
      marital_status: "",
      civility: "",
      successToActivateAccountAlert: false,
      successSendMailToUserAlert: false,
      successSendMailToAllUserAlert: false,
      errorToActivateAccountRequste: false,
      errorSendMailToUserRequste: false,
      errorSendMailToAllUserRequste: false,
      changeToActivateAccountProgress: false,
      changeToSendMailProgress: false,
      changeTosendMailUsersProgress: false,
      //loading: true,
      loading2: false,

      // isEmptyData: false,
      isListBakelisteRequestError: false,
      updateBakelisteInfosInProgress: false,
      successUpdateBakelisteInfosAlert: false,
      updateBakelisteInfosRequestError: false,
      _phFields: {},
      messageToUsers: {},
      isPro: false,
      isEtudiant: false,
      isFormationCourte: false,
      isFormationDiplomante: false,
      openStatutProfessionnelFC: false,
      openStatutFD: false,
      isSemaine: false,
      isEtudiantSemaineChoice: false,
      isEtudiantSemaineLSChoice: false,
      isExistUser: false,
      isSuccessAddedBakelisteAlert: false,
      isCoachAssignationSuccessAlert: false,
      isCoachAssignationRequestError: false,
      // lsite coach
      coach: [],

      errors: {},
      value: "",

      fn_empty: false,
      ln_empty: false,
      email_empty: false,
      phone_empty: false,
      df_empty: false,

      bakelistCoach: [],

      coachID: 0,
      coachFullname: "",
      coachDomaine: "",
      coachDomaineID: 0,
      coachStatus: "",
      coachNbreBakelistes: "",
      updatedCoachStatusInProgress: false,
      updatedError: false,
      updateFields: {},
      updateCoach: [],
      addFields: {},
      updateCoachInfosInProgress: false,
      updateCoachInfosSuccessAlert: false,
      updateCoachInfosRequestError: false,
      AjoutCoachInfosInProgress: false,
      AjoutCoachInfoSuccess: false,

      // manage user
      successAccessPointMeAlert: false,
      errorAccessPointMeRequest: false,
      changeAccessInProgress: false,
      // BF termines

      countBakeliste: 0,
      isRequestError: false,
      bakelisteInfos: [],
      BfBakelistes: [],
      //  bakelisteID: 0,

      tf_empty: false,
      boursier_empty: false,
      demi_boursier_empty: false,
      formation_payante_empty: false,
      stagede_formation_empty: false,
      lf_empty: false,

      paiement_empty: false,
      fs_empty: false,
      sp_empty: false,
      pe_empty: false,
      j1_empty: false,
      j2_empty: false,
      j3_empty: false,
      reIntSuccessAlert: false,
      reIntRequestError: false,
      updateInProgressBV: false,
      navs: [],
      tab_utilisateurs: true,
      tab_bakelistes: false,
      tab_all_bakelistes: false,
      tab_participants: false,
      tab_visiteurs: false,
      tab_coach: false,
      tab_bf_termines: false,
      tab_user_manager: false,
      tab_bakeliste_volkeno: false,
      tab_liste_bak: false,
    };
    let token = window.sessionStorage.getItem("token");
    let userStatus = window.sessionStorage.getItem("user_status");

    if (!token) {
      window.history.back();
    }
    if (userStatus === "bakeliste" || userStatus === "coach") {
      window.history.back();
    }

    this.handleToActivateAccountChange = this.handleToActivateAccountChange.bind(
      this
    );
    this.submitToActivateAccountRequest = this.submitToActivateAccountRequest.bind(
      this
    );
    this.submitToSendMailRequest = this.submitToSendMailRequest.bind(this);
    this.getBakelisteEnFormation = this.getBakelisteEnFormation.bind(this);
    this.updateHandleChangeVisiteur = this.updateHandleChangeVisiteur.bind(
      this
    );
    this.updateBakelisteInfos = this.updateBakelisteInfos.bind(this);
    this.submitSendToMailAllUser = this.submitSendToMailAllUser.bind(this);
    this.handleSendToMailAllUser = this.handleSendToMailAllUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.assignCoach = this.assignCoach.bind(this);
    //  list coach
    this.updateCoachStatus = this.updateCoachStatus.bind(this);
    this.updateCoachStatusHandleChange = this.updateCoachStatusHandleChange.bind(
      this
    );
    this.updateHandleChange = this.updateHandleChange.bind(this);
    this.updatedCoachData = this.updatedCoachData.bind(this);
    this.AddhandleChange = this.AddhandleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
    this.validateForm = this.validateForm.bind(this);
    // MAMAGE
    this.handleAccessChange = this.handleAccessChange.bind(this);
    this.submitAccessPMRequest = this.submitAccessPMRequest.bind(this);
    this.getBakelisteInfos = this.getBakelisteInfos.bind(this);
    this.updateBakelisteInfos = this.updateBakelisteInfos.bind(this);
    this.getSelectedBakeliste = this.getSelectedBakeliste.bind(this);
  }

  componentDidMount = () => {
    this.onGetUsersList();
    this.onGetparticipantsList();
    this.getListAllBakelistes();
    this.getListAllVisiteurs();
    this.getBakelisteEnFormation();
    this.getListCoach();
    this.getAllDomaines();
    this.getCompletedTrainingBakelistes();
    this.onGetUsersList();
  };

  onGetUsersList = () => {
    this.setState({
      isPageLoading: true,
    });
    var api = this.state.api;

    axios
      .get(api + "all-users")
      .then((response) => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            users: response.data.data,
          });
        } else {
          this.setState({
            isPageLoading: false,
          });
         // console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onGetUserInfos = (user) => {
    this.setState({
      user: user,
    });
  };

  onSendUserMail = (user) => {
    this.setState({
      user: user,
    });
  };
  // get all
  getListAllBakelistes = () => {
    axios
      .get(this.state.api + "liste-tous-les-bakelistes")
      .then((res) => {
        //console.log('Alls', res.data.data)
        if (res.data.success === true) {
          this.setState({
            bakelistes: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
          console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          getBakelisteRequestError: true,
        });
        console.log(error.message);
      });
  };

  // get bakeliste en formation
  getBakelisteEnFormation = () => {
    this.setState({
      isLoading: true,
    });
    axios
      .get(this.state.api + "liste-des-bakelistes-en-formation")
      .then((res) => {
        if (res.data.success) {
          let bakelistes = res.data.data;
          // console.log(bakelistes)
          this.setState({
            bakeliste: bakelistes.reverse(),
            isLoading: false,
          });
          // $('.export-btn').after('<div class="alerty_immersion"><i class="fas fa-exclamation-circle"></i> &nbsp; Les bakeliste en couleur <span class="verty">noire et en gras</span> sont ceux qui sont en immersion.</div>');
        } else {
          console.log(res);
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
          isListBakelisteRequestError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  // get all coach
  getAllActivesCoach = () => {
    axios
      .get(this.state.api + "liste-des-coachs")
      .then((res) => {
        const coachsData = res.data;
        const listActiveCoach = [];
        coachsData.data.forEach((item) => {
          if (item.coach_is_actif === 1) {
            listActiveCoach.push(item);
          }
        });
        this.setState({
          coachs: listActiveCoach,
        });
        //console.log("coach list: ", this.state.coachs)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  //list-bakeliste-self-subscribe
  getListAllVisiteurs = () => {
    axios.get(this.state.api + "list-bakeliste-self-subscribe").then((res) => {
      //console.log('Alls', res.data.data)
      if (res.data.success === true) {
        this.setState({
          nouveaux: res.data.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
          isEmptyData: true,
        });
        console.log(res);
      }
    });
  };

  // heand change visiteur
  updateHandleChangeVisiteur = (e) => {
    //let fields = this.state.fields;
    let _phFields = this.state._phFields;
    _phFields[e.target.name] = e.target.value;
    // _phFields[e.target.name] = e.target.value;
    _phFields["first_name"] = this.state.bakeliste_fn;
    _phFields["last_name"] = this.state.bakeliste_ln;
    _phFields["email"] = this.state.emailValue;

    if (e.target.name === "type_formation") {
      _phFields[e.target.name] = e.target.value;
      if (e.target.value === "courte") {
        _phFields["jour_fd"] = "";
        this.setState({
          openStatutProfessionnelFC: true,
          openStatutFD: false,
        });
      } else if (e.target.value === "diplomante") {
        _phFields["nbre_jours"] = 5;
        _phFields["is_etudiant"] = 1;
        _phFields["is_week"] = 1;
        _phFields["is_weekend"] = 0;
        _phFields["jour_fd"] = "lundi au vendredi";
        _phFields["jour_1"] = "Lundi";
        _phFields["jour_2"] = "Mardi";
        _phFields["jour_3"] = "Mercredi";
        _phFields["jour_4"] = "Jeudi";
        _phFields["jour_5"] = "Vendredi";
        _phFields["jour_6"] = "";
        this.setState({
          openStatutProfessionnelFC: false,
          openStatutFD: true,
          isSemaine: false,
          isPro: false,
          isEtudiant: false,
        });
      }
    }
    if (e.target.name === "isproetudiant") {
      if (e.target.value === "professionnel") {
        _phFields["is_pro"] = 1;
        _phFields["is_etudiant"] = 0;
        this.setState({
          isPro: true,
          isEtudiant: false,
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "etudiant") {
        _phFields["is_etudiant_a_tester"] = 0;
        _phFields["is_etudiant"] = 1;
        _phFields["is_pro"] = 0;
        _phFields["nbre_jours"] = 3;
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        this.setState({
          isPro: false,
          isEtudiant: true,
          isSemaine: false,
        });
      } else if (e.target.value === "a_tester") {
        _phFields["is_etudiant_a_tester"] = 1;
        _phFields["is_etudiant"] = 1;
        _phFields["is_pro"] = 0;
        _phFields["nbre_jours"] = 3;
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        this.setState({
          isPro: false,
          isEtudiant: true,
          isSemaine: false,
        });
      }
    }
    if (e.target.name === "isWE") {
      if (e.target.value === "semaine") {
        _phFields["is_etudiant"] = 0;
        _phFields["is_week"] = 1;
        _phFields["is_weekend"] = 0;
        _phFields["nbre_jours"] = 2;
        _phFields["horaire"] = "17h30-19h30";
        _phFields["jour_3"] = "";
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        this.setState({
          isSemaine: true,
        });
      } else if (e.target.value === "weekend") {
        _phFields["is_weekend"] = 1;
        _phFields["is_week"] = 0;
        _phFields["horaire"] = "9h-13h";
        _phFields["nbre_jours"] = 1;
        _phFields["jour_1"] = "Samedi";
        _phFields["jour_2"] = "";
        _phFields["jour_3"] = "";
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        this.setState({
          isSemaine: false,
        });
      }
      if (e.target.name === "email") {
        _phFields["email"] = e.target.value;
      }
    }
    if (e.target.name === "isWEE") {
      if (e.target.value === "semaine") {
        _phFields["is_etudiant"] = 1;
        _phFields["is_week"] = 1;
        _phFields["is_weekend"] = 0;
        _phFields["nbre_jours"] = 3;
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        this.setState({
          isEtudiantSemaineChoice: true,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "weekend") {
        _phFields["is_weekend"] = 1;

        _phFields["is_week"] = 0;
        _phFields["horaire"] = "9h-17h";
        _phFields["nbre_jours"] = 2;
        _phFields["jour_1"] = "Samedi";
        _phFields["jour_2"] = "Dimanche";
        _phFields["jour_3"] = "";
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        this.setState({
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "semainels") {
        _phFields["is_etudiant"] = 1;
        _phFields["is_week"] = 1;
        _phFields["is_weekend"] = 0;
        _phFields["nbre_jours"] = 5;
        _phFields["jour_1"] = "Lundi";
        _phFields["jour_2"] = "Mardi";
        _phFields["jour_3"] = "Mercredi";
        _phFields["jour_4"] = "Jeudi";
        _phFields["jour_5"] = "Vendredi";
        _phFields["jour_6"] = "";
        //console.log(_phFields);

        this.setState({
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: true,
        });
      }
    }
    this.setState({
      upFields: _phFields,
    });
    //console.log("phFields", this.state._phFields);
  };
  // assignation coach
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  // transformer visiteur en bakeliste

  validateBakelisteSelfSubscribe = (e) => {
    e.preventDefault();
    this.setState({
      updateBakelisteInfosInProgress: true,
    });
    let bakelisteID = this.state.bakelisteIDForUpdate;
    let data = this.state.upFields;

    //console.log(data);

    axios
      .put(
        this.state.api + "validate-bakeliste-self-subscribe/" + bakelisteID,
        data
      )
      .then((response) => {
        this.setState({
          updateBakelisteInfosInProgress: false,
        });
        if (response.data.success === true) {
          this.onGetparticipantsList();
          this.getListAllBakelistes();
          this.getListAllVisiteurs();
          this.getBakelisteEnFormation();
          $(".close").click();
          $("body").removeClass("modal-open");
          // console.log('open')
          $(".modal-backdrop").removeClass("modal-backdrop");
          // console.log('backdrop')
          $(".fade").removeClass("fade");
          $(".in").removeClass("in");
          this.setState({
            successUpdateBakelisteInfosAlert: true,
          });
          this.handleShowAndHideAlert("closeUpdateSuccessAlert");
        } else {
          this.setState({
            updateBakelisteInfosInProgress: false,
            updateBakelisteInfosRequestError: true,
          });
          this.handleShowAndHideAlert("closeUpdateErrorAlert");
        }
      })
      .catch((error) => {
        this.setState({
          updateBakelisteInfosInProgress: false,
          updateBakelisteInfosRequestError: true,
        });
        this.handleShowAndHideAlert("closeUpdateErrorAlert");
      });
  };

  // get domaine de formation
  getDomainesDeFormation = () => {
    axios.get(this.state.api + "domaines").then((res) => {
      const domains = res.data;
      this.setState({
        domaines: domains.data,
      });
    //  console.log(this.state.domaines);
    });
  };
  //  get participants
  onGetparticipantsList = () => {
    this.setState({
      isPageLoading: true,
    });
    var api = this.state.api;

    axios
      .get(api + "participants")
      .then((response) => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            participants: response.data.data,
          });
        } else {
          this.setState({
            isPageLoading: false,
          });
          //console.log(response);
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  handleToActivateAccountChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
    //console.log(fields);
  };

  handleSendToMailAllUser = (e) => {
    let messageToUsers = this.state.messageToUsers;
    messageToUsers[e.target.name] = e.target.value;

    this.setState({
      messageToUsers: messageToUsers,
    });
    console.log(messageToUsers);
  };

  assignCoach() {
    console.log("before assign", this.state);
    this.setState({
      assignationEncours: true,
    });
    const id_bakelist = this.state.selectedBakelist.id;

    const dataForAssign = {
      bakeliste_id: id_bakelist,
      coach_id: this.state.fields.coachSelect,
    };
    axios
      .post(this.state.api + "assigner-un-coach", dataForAssign)
      .then((res) => {
        if (res.data.success) {
          this.getHGY1Bakelistes();
          $(".close").click();
          this.setState({
            assignationEncours: false,
            isCoachAssignationSuccessAlert: true,
          });

          this.handleShowAndHideAlert("successAssignationCoach");
          // window.location.reload();
        } else {
          console.log(res);
          this.setState({
            assignationEncours: false,
            isCoachAssignationRequestError: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          assignationEncours: false,
          isCoachAssignationRequestError: true,
        });
      });
  }
  submitToActivateAccountRequest = (e) => {
    this.setState({
      changeToActivateAccountProgress: true,
    });

    var userID = this.state.user.id;
    var data = this.state.fields;
    var baseApi = this.state.api;

    axios
      .post(baseApi + "to_activate_account_user/" + userID, data)
      .then((response) => {
        this.setState({
          changeToActivateAccountProgress: false,
        });

        if (response.data.success) {
          this.getListAllVisiteurs();
          $(".close").click();
          this.setState({
            successToActivateAccountAlert: true,
          });
          this.handleShowAndHideAlert("successToActivateAccountAlert");
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          changeToActivateAccountProgress: false,
          errorToActivateAccountRequste: true,
        });
        this.handleShowAndHideAlert("errorToActivateAccountRequste");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  // to send mail the user

  submitToSendMailRequest = (e) => {
    this.setState({
      changeToSendMailProgress: true,
    });

    let fields = {};
    fields["first_name"] = this.state.user.first_name;
    fields["last_name"] = this.state.user.last_name;
    fields["email"] = this.state.user.email;
    fields["phone"] = this.state.user.phone;
    fields["rencontreName"] = this.state.user.rencontre;
    var data = fields;
    var baseApi = this.state.api;

    axios
      .post(baseApi + "envoyer-mail-au-participant", data)
      .then((response) => {
        this.setState({
          changeToSendMailProgress: false,
        });

        if (response.data.success) {
          //this.onGetUsersList();
          //$('#pointme-access-form')[0].reset();
          $(".close").click();
          $(".modal-backdrop").removeClass("modal-backdrop");
          $(".fade").removeClass("fade");
          $(".in").removeClass("in");
          this.setState({
            successSendMailToAllUserAlert: true,
            fields: {},
          });
          this.handleShowAndHideAlertSendMail("successSendMailToAllUserAlert");
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          changeToActivateAccountProgress: false,
          errorToActivateAccountRequste: true,
        });
        this.handleShowAndHideAlertSendMail("errorSendMailToUserRequste");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  // send mail all users
  submitSendToMailAllUser = (e) => {
    this.setState({
      changeTosendMailUsersProgress: true,
    });

    var baseApi = this.state.api;
    let messageToUsers = this.state.messageToUsers;

    messageToUsers["email"] = this.state.user.email;
    var data = messageToUsers;
    //console.log(data);
    axios
      .post(baseApi + "send-mail-to-all-users", data)
      .then((response) => {
        this.setState({
          changeTosendMailUsersProgress: false,
        });

        if (response.data.success) {
          $(".close").click();
          $(".modal-backdrop").removeClass("modal-backdrop");
          $(".fade").removeClass("fade");
          $(".in").removeClass("in");
          this.setState({
            successSendMailToUserAlert: true,
          });
          this.handleShowAndHideAlertSendMail("successSendMailToUserAlert");
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          changeToActivateAccountProgress: false,
          errorToActivateAccountRequste: true,
        });
        this.handleShowAndHideAlertSendMail("errorSendMailToAllUserRequste");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "successToActivateAccountAlert") {
        this.setState({
          successToActivateAccountAlert: false,
        });
      }
      if (label === "errorToActivateAccountRequste") {
        this.setState({
          errorToActivateAccountRequste: false,
        });
      }
      if (label === "successAssignationCoach") {
        this.setState({
          isCoachAssignationSuccessAlert: false,
        });
      }
      if (label === "closeUpdateSuccessAlert") {
        this.setState({
          successUpdateBakelisteInfosAlert: false,
        });
      }
      if (label === "closeUpdateErrorAlert") {
        this.setState({
          updateBakelisteInfosRequestError: false,
        });
      }
      if (label === "successHired") {
        this.setState({
          successHiredAlert: false,
        });
      }
      if (label === "errorHired") {
        this.setState({
          errorHiredAlert: false,
        });
      }
      if (label === "successCompletedFormation") {
        this.setState({
          successCompletedFormationAlert: false,
        });
      }
      if (label === "errorCompletedFormation") {
        this.setState({
          completedFormationRequestError: false,
        });
      }
    }, 5000);
  }
  // get bakeliste infos

  getBakelisteInfos = (bakeliste, e) => {
    e.preventDefault();
    //console.log(bakeliste);
    this.setState(
      {
        bakelisteID: bakeliste.id,
        BakelisteFullname: bakeliste.bakelisteFullname,
        BakelisteEmail: bakeliste.email,
        BakelisteDomaine: bakeliste.domaine,
        BakelisteEcole: bakeliste.ecole,
        BakelistePhone: bakeliste.phone,
        BakelistecoachFullname: bakeliste.coachFullname,
        BakelisteDebutFormation: bakeliste.startFrenchDateFormat,
        BakelisteFinFormation: bakeliste.endFrenchDateFormat,
      },
      () => {
        //console.log(this.state.BakelistecoachFullname)
      }
    );
    this.setState(
      {
        bakelisteIDForUpdate: bakeliste.id,
        bakelisteFullname: bakeliste.bakelisteFullname,
        bakeliste_fn: bakeliste.first_name,
        bakeliste_ln: bakeliste.last_name,
        emailValue: bakeliste.email,
        phoneValue: bakeliste.phone,
        boursierValue: bakeliste.boursier,
        demiBoursierValue: bakeliste.demi_boursier,
        adresseValue: bakeliste.address,
        ecoleValue: bakeliste.ecole,
        domaineFormationValue: bakeliste.bakeliste_domaine_id,
        stageFormationValue: bakeliste.is_stagede_formation,
        formationPayanteValue: bakeliste.is_formation_payante,
        isISAContractValue: bakeliste.is_isa_contract,
        lieuFormationValue: bakeliste.lieu_formation,
        nbreJoursValue: bakeliste.nbreJours,
        horaireValue: bakeliste.horaire,
        paiementInscriptionValue: bakeliste.paiement_inscription,
        debutFormationValue: bakeliste.debut,
        finFormationValue: bakeliste.fin,
        firstNameValue: bakeliste.first_name,
        lastNameValue: bakeliste.last_name,

        updateBakeliste: bakeliste,
      },
      () => {
        //console.log(this.state.updateBakeliste);
      }
    );
  };

  handleShowAndHideAlertSendMail(label) {
    setTimeout(() => {
      if (label === "successSendMailToUserAlert") {
        this.setState({
          successSendMailToUserAlert: false,
        });
      }
      if (label === "errorSendMailToUserRequste") {
        this.setState({
          errorSendMailToUserRequste: false,
        });
      }
      if (label === "successSendMailToAllUserAlert") {
        this.setState({
          successSendMailToAllUserAlert: false,
        });
      }
      if (label === "errorSendMailToAllUserRequste") {
        this.setState({
          errorSendMailToAllUserRequste: false,
        });
      }
    }, 5000);
  }

  // actionsFormatter = (cell, row) => {

  // }

  userFullnameFormatter = (cell, row) => {
    return (
      <span
        data-toggle="tooltip"
        data-placement="top"
        title="Voir plus"
        type="button"
        className="userFullname"
        id="uFullname"
      >
        {row.first_name + " " + row.last_name}
      </span>
    );
  };

  statusFormatter = (cell, row) => {
    if (cell === "admin") {
      return <span className="label label-success label-vgreen"> {cell} </span>;
    }
    if (cell === "coach") {
      return <span className="label label-warning"> {cell} </span>;
    }

    if (cell === "bakeliste") {
      return <span className="label label-primary"> {cell} </span>;
    }

    if (cell === "nouveau") {
      return <span className="label label-info"> {cell} </span>;
    }
  };

  activateOrDeactivateUser = (cell, row) => {
    if (cell === 1) {
      return (
        <div>
          <span
            className="activ"
            data-toggle="modal"
            data-target="#postAccessModal"
            onClick={(e) => this.onGetUserInfos(row, e)}
          >
            <Icon
              circular
              color="green"
              name="user"
              size="big"
              link
              title="activer le compte"
            />
          </span>
          <span
            data-toggle="modal"
            data-target="#sendMailalluserModal"
            className="action-user"
            onClick={(e) => this.onSendUserMail(row, e)}
          >
            <Icon
              link
              circular
              color="teal"
              name="send"
              size="large"
              title="envoyer e-mail"
            />
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span
            className="deactivate"
            data-toggle="modal"
            data-target="#postAccessModal"
            onClick={(e) => this.onGetUserInfos(row, e)}
          >
            <Icon.Group size="big" title="Désactiver le compte">
              <Icon link size="large" color="red" name="dont" />
              <Icon
                link
                color="black"
                name="user"
                title="désactiver le compte"
              />
            </Icon.Group>
          </span>
          <span
            data-toggle="modal"
            data-target="#sendMailalluserModal"
            className="action-user"
            onClick={(e) => this.onSendUserMail(row, e)}
          >
            <Icon
              link
              circular
              color="teal"
              name="send"
              size="large"
              title="envoyer e-mail"
            />
          </span>
        </div>
      );
    }
  };

  // send user mail

  toSendUserMail = (cell, row) => {
    const users = this.state.users;
    if (users.some((item) => item.email === cell) === true) {
      return (
        <div className="actions-btn-container">
          <span className="check circle-user">
            <Icon
              link
              circular
              color="green"
              name="check circle"
              size="large"
              title="Ce participant est déjà converti"
            />
          </span>{" "}
          &nbsp;&nbsp;
          <span
            data-toggle="modal"
            data-target="#sendMailalluserModal"
            className="action-user"
            onClick={(e) => this.onSendUserMail(row, e)}
          >
            <Icon
              link
              circular
              color="teal"
              name="send"
              size="large"
              title="envoyer e-mail"
            />
          </span>
        </div>
      );
    } else {
      return (
        <div className="actions-btn-container">
          <span
            data-toggle="modal"
            data-target="#sendMailTouser"
            className="action-user"
            onClick={(e) => this.onSendUserMail(row, e)}
          >
            <Icon
              link
              circular
              color="teal"
              name="send"
              size="large"
              title="envoyer e-mail"
            />
          </span>
        </div>
      );
    }
  };

  handleClearButtonClick = (onClick) => {
    //console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn-user" sizeClass="btn-group-md">
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  };

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <span
          data-toggle="modal"
          data-target="#sendMailalluserModal"
          className="action-user"
          onClick={(e) => this.onSendUserMail(row)}
        >
          <Icon link circular color="teal" name="send" size="large" />
        </span>
      </div>
    );
  };

  transformUserAbakeliste = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <span
          data-toggle="modal"
          className="action-user"
          data-target="#changeVisiteurModal"
          onClick={(e) => this.getBakelisteInfos(row, e)}
        >
          <Icon
            link
            circular
            inverted
            color="orange"
            name="undo"
            size="large"
          />
        </span>
        <span
          data-toggle="modal"
          data-target="#sendMailalluserModal"
          className="action-user"
          onClick={(e) => this.onSendUserMail(row, e)}
        >
          <Icon
            link
            circular
            color="teal"
            name="send"
            size="large"
            title="envoyer e-mail"
          />
        </span>
      </div>
    );
  };

  typeFormationFormatter = (cell, row) => {
    if (cell === "courte") {
      return (
        <span className="label label-success volkeno-green-bcolor">
          {" "}
          formation courte{" "}
        </span>
      );
    } else if (cell === "diplomante") {
      return (
        <span className="label label-warning volkeno-orange-bcolor">
          {" "}
          formation diplômante{" "}
        </span>
      );
    }
  };

  typeDeStageFormatter = (cell, row) => {
    if (row.is_stagede_formation === 1) {
      return (
        <span className="label label-success volkeno-green-bcolor">
          {" "}
          en stage de formation{" "}
        </span>
      );
    } else if (row.is_isa_contract === 1) {
      return (
        <span className="label label-warning volkeno-green-bcolor">
          {" "}
          sous contra ISA{" "}
        </span>
      );
    } else if (row.is_formation_payante === 1) {
      return (
        <span className="label label-danger volkeno-green-bcolor">
          {" "}
          formation payante{" "}
        </span>
      );
    } else {
      return (
        <span className="label label-info volkeno-green-bcolor">
          {" "}
          type de stage non trouvé{" "}
        </span>
      );
    }
  };
  Detail = (item) => {
    this.setState({
      selectedBakelist: item,
      dataBakeliste: item,
    });
  };
  bakelisteFullnameFormatter = (cell, row) => {
    return (
      <span
        className="fullname-link open-infos-modal"
        data-toggle="modal"
        data-target="#moModal"
        onClick={(e) => this.Detail(row, e)}
      >
        {cell}
      </span>
    );
  };
  // coach row formate
  coachRowFormatter = (cell, row) => {
    return (
      <div className="coach_row actions-btn-container">
        {row.coach_id ? (
          <Link to="#">{cell}</Link>
        ) : (
          <button
            type="button"
            className="btn btn-info"
            id="btn_assign"
            data-toggle="modal"
            data-target="#myModale"
            onClick={(e) => this.getSelectedBakeliste(row, e)}
          >
            <i className="fas fa-user-check"></i>
          </button>
        )}
      </div>
    );
  };

  getSelectedBakeliste(x) {
    this.getAllActivesCoach();
    this.setState({
      selectedBakelist: x,
    });
    return x.id;
  }

  typeFormationFormatter = (cell, row) => {
    if (cell === "courte") {
      return (
        <span className="label label-success volkeno-green-bcolor">
          {" "}
          formation courte{" "}
        </span>
      );
    } else if (cell === "diplomante") {
      return (
        <span className="label label-warning volkeno-orange-bcolor">
          {" "}
          formation diplômante{" "}
        </span>
      );
    }
  };
  // liste coach
  getListCoach = () => {
    axios.get(this.state.api + "liste-des-coachs").then((res) => {
      //console.log('coach', res.data.data)
      this.setState({
        coach: res.data.data,
        loading: false,
      });
      // console.log("j ves" + res)
      // console.log(this.state.coach)
    });
  };

  getAllDomaines = () => {
    axios.get(this.state.api + "domaines").then((res) => {
      const domains = res.data;
      this.setState({
        domaines: domains.data,
      });
     // console.log(this.state.domaines);
    });
  };

  renderNam(id) {
    this.state.bakelistCoach.forEach((z) => {
      console.log("function is called" + z[2]);
      if (z.id === id) {
        this.setState({
          nameBakelist: z.name,
        });
      }
    });
    return (
      <td>
        <Link to="#">{this.state.nameDomain} </Link>
      </td>
    );
  }

  renderName(id) {
    this.state.domaines.forEach((x) => {
      if (x.id === id) {
        this.setState({
          nameDomain: x.name,
        });
      }
    });
    return (
      <td>
        <Link to="#">{this.state.nameDomain} </Link>
      </td>
    );
  }
  renderNames(id) {
    this.state.bakelistCoach.forEach((z) => {
      if (z.id === id) {
        this.setState({
          nameCoache: z.name,
        });
      }
    });
  }

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  getCoachID = (coach) => {
    window.sessionStorage.setItem("lcoach_id", coach.id);
    //console.log(window.sessionStorage.getItem("lcoach_id"));
  };

  statusFormatterCoach(cell, row) {
    return (
      <span>
        {row.coach_is_actif === 1 && (
          <span
            className="label label-success"
            data-toggle="modal"
            data-target="#changeCoachStatusModale"
            onClick={() => this.getCoachID(row)}
          >
            actif
          </span>
        )}
        {row.coach_is_actif === 0 && (
          <span
            className="label label-danger"
            data-toggle="modal"
            data-target="#changeCoachStatusModale"
            onClick={() => this.getCoachID(row)}
          >
            non actif
          </span>
        )}
      </span>
    );
  }

  getCoachInfos = (coach) => {
    this.setState({
      coachID: coach.id,
      coachFullname: coach.coachFullname,
      coachEmail: coach.email,
      coachDomaine: coach.domaine,
      coachDomaineID: coach.coach_domaine_id,
      coachPhone: coach.phone,
      coachStatus: coach.coach_is_actif,
      coachNbreBakelistes: coach.nbreBakeliste,
      coachFirstname: coach.first_name,
      coachLastname: coach.last_name,
      updateCoach: coach,
    });
  };

  updateCoachStatusHandleChange = (e) => {
    let fields = this.state.updateFields;
    fields[e.target.name] = e.target.value;

    this.setState({
      updateFields: fields,
    });

    //console.log(fields);
  };

  updateCoachStatus = (e) => {
    e.preventDefault();

    this.setState({
      updatedCoachStatusInProgress: true,
    });

   // console.log(this.state.updateFields);

    let updatedData = this.state.updateFields;
    let coachID = window.sessionStorage.getItem("lcoach_id");

    axios
      .post(this.state.api + "update-coach-status/" + coachID, updatedData)
      .then((response) => {
        this.setState({
          updatedCoachStatusInProgress: false,
        });
        if (response.data.success === true) {
          this.getListCoach();
          $('.close').click();
        } else {
          this.setState({
            updatedError: true,
          });
        }
      });
  };

  actionsFormatterCoach(cell, row) {
    return (
      <span>
        <button
          onClick={() => {
            this.getCoachInfos(row);
          }}
          id="show_item"
          className="btn btn-info show-btn volkeno-green-bcolor"
          data-toggle="modal"
          data-target="#showInfosModalCoach"
        >
          <i class="fas fa-eye"></i>
        </button>
        &nbsp;&nbsp;
        <button
          onClick={() => {
            this.getCoachInfos(row);
          }}
          id="update_item"
          className="btn btn-default edit-btn volkeno-orange-bcolor"
          data-toggle="modal"
          data-target="#sendMsgModalCoach"
        >
          <i class="fas fa-edit"></i>
        </button>
        &nbsp;&nbsp;
        <button
          id="update_item"
          className="btn btn-info show-btn volkeno-green-bcolor"
          data-toggle="modal"
          data-target="#AddMsgModal"
        >
          <i className="fas fa-plus-circle"></i>
        </button>
      </span>
    );
  }

  //
  getCoachIDForRedirect = (coach) => {
    let coachFullname = coach.first_name + " " + coach.last_name;
    window.sessionStorage.setItem("coachIDForRedirect", coach.id);
    window.sessionStorage.setItem("coachFNForRedirect", coachFullname);
    window.location = "bakeliste-by-coach";
  };

  nbreBakelistesFormatter(cell, row) {
    return (
      <span>
        {/* <span className="label label-info volkeno-green-bcolor nbakelistes_link" onClick={() => this.getCoachIDForRedirect(row)}> {row.nbreBakeliste} </span> */}
        <button
          onClick={() => this.getCoachIDForRedirect(row)}
          class="btn btn-primary volkeno-green-bcolor nbakelistes_link"
          type="button"
        >
          <span class="badge"> {row.nbreBakeliste} </span>
        </button>
      </span>
    );
  }

  /**
   * Method to switch boolean variable
   */
  handleShowAndHideAlertCoach(label) {
    setTimeout(() => {
      if (label === "coachUpdateSuccess") {
        this.setState({
          updateCoachInfosSuccessAlert: false,
        });
      }
      if (label === "coachUpdateError") {
        this.setState({
          updateCoachInfosRequestError: false,
        });
      }
    }, 5000);
  }

  /**
   * HandleChange method to update coach infos
   */
  updateHandleChange = (e) => {
    let fields = this.state.updateFields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === "first_name") {
      this.setState({
        coachFirstname: e.target.value,
      });
    }
    if (e.target.name === "last_name") {
      this.setState({
        coachLastname: e.target.value,
      });
    }
    if (e.target.name === "email") {
      this.setState({
        coachEmail: e.target.value,
      });
    }
    if (e.target.name === "phone") {
      this.setState({
        coachPhone: e.target.value,
      });
    }
    if (e.target.name === "coach_domaine_id") {
      this.setState({
        coachDomaineID: e.target.value,
      });
    }

    if (!fields["first_name"]) {
      fields["first_name"] = this.state.updateCoach.first_name;
    }
    if (!fields["last_name"]) {
      fields["last_name"] = this.state.updateCoach.last_name;
    }
    if (!fields["email"]) {
      fields["email"] = this.state.updateCoach.email;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.updateCoach.phone;
    }
    if (!fields["coach_domaine_id"]) {
      fields["coach_domaine_id"] = this.state.updateCoach.coach_domaine_id;
    }

    this.setState({
      updateFields: fields,
    });

    //console.log(fields);
  };

  /**
   * Submit method to update coach infos
   */
  updatedCoachData = (e) => {
    e.preventDefault();

    //console.log(this.state.updateFields);

    this.setState({
      updateCoachInfosInProgress: true,
    });
    let idCoach = this.state.coachID;
    //console.log(idCoach);
    let data = this.state.updateFields;

    axios
      .put(this.state.api + "update-coach-infos/" + idCoach, data)
      .then((response) => {
        // console.log(response)
        if (response.data.success === true) {
          this.getListCoach();
          $(".close").click();
          this.setState({
            updateCoachInfosInProgress: false,
            updateCoachInfosSuccessAlert: true,
            updateFields: {},
          });
          this.handleShowAndHideAlertCoach("coachUpdateSuccess");
        } else {
         // console.log(response);
          this.setState({
            updateCoachInfosInProgress: false,
            updateCoachInfosRequestError: true,
          });
          this.handleShowAndHideAlertCoach("coachUpdateError");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateCoachInfosInProgress: false,
          updateCoachInfosRequestError: true,
        });
        this.handleShowAndHideAlertCoach("coachUpdateError");
      });
  };
  AddhandleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    //console.log("handle change", e.target.value)
    this.setState({
      fields,
    });
    //console.log("handle change", this.state.fields);
  }

  handleShowAndHideAlertAC(input) {
    setTimeout(() => {
      if (input === "firstName") {
        this.setState({
          fn_empty: false,
        });
      }
      if (input === "lastName") {
        this.setState({
          ln_empty: false,
        });
      }
      if (input === "email") {
        this.setState({
          email_empty: false,
        });
      }
      if (input === "phone") {
        this.setState({
          phone_empty: false,
        });
      }
      if (input === "domaine") {
        this.setState({
          df_empty: false,
        });
      }
    }, 5000);
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["first_name"]) {
      formIsValid = false;
      this.setState({
        fn_empty: true,
      });
      this.handleShowAndHideAlertAC("firstName");
      errors["first_name"] = "*Ce champ est obligatoire";
    }

    if (typeof fields["first_name"] !== "undefined") {
      if (
        !fields["first_name"].match(
          /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
        )
      ) {
        formIsValid = false;
        this.setState({
          fn_empty: true,
        });
        this.handleShowAndHideAlertAC("firstName");
        errors["first_name"] = "*Ce champs n'accepte que des lettres.";
      }
    }

    if (!fields["last_name"]) {
      formIsValid = false;
      this.setState({
        ln_empty: true,
      });
      this.handleShowAndHideAlertAC("lastName");
      errors["last_name"] = "*Ce champ est obligatoire";
    }

    if (typeof fields["last_name"] !== "undefined") {
      if (
        !fields["last_name"].match(
          /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
        )
      ) {
        formIsValid = false;
        this.setState({
          ln_empty: true,
        });
        this.handleShowAndHideAlertAC("lastName");
        errors["last_name"] = "*Ce champ ne doit contenir que des lettres.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      this.setState({
        email_empty: true,
      });
      this.handleShowAndHideAlertAC("email");
      errors["email"] = "*Ce champ est obligatoire.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        this.setState({
          email_empty: true,
        });
        this.handleShowAndHideAlertAC("email");
        errors["email"] = "*Adresse email invalide.";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      this.setState({
        phone_empty: true,
      });
      this.handleShowAndHideAlertAC("phone");
      errors["phone"] = "*Ce champ est obligatoire.";
    }

    if (!fields["coach_domaine_id"]) {
      formIsValid = false;
      this.setState({
        df_empty: true,
      });
      this.handleShowAndHideAlertAC("domaine");
      errors["domaine"] = "*Ce champ est obligatoire.";
    }

    // if (typeof fields["phone"] !== "undefined") {
    // if (!fields["phone"].match(/^[0-9]{9}$/)) {
    //     formIsValid = false;
    //     errors["phone"] = "*Numéro de telephone invalide.";
    // }
    // }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  submituserRegistrationForm(e) {
    e.preventDefault();

    if (this.validateForm()) {
      this.setState({
        AjoutCoachInfosInProgress: true,
      });
      let fields = {};
      fields["first_name"] = "";
      fields["last_name"] = "";
      fields["email"] = "";
      fields["phone"] = "";
      fields["coach_domaine_id"] = "";
      fields["status"] = "coach";
      this.setState({ fields: fields });
      const dataCoach = this.state.fields;
      console.log(dataCoach);
      axios.post(this.state.api + "add-coach", dataCoach).then((res) => {
        console.log(res);
        if (res.status === 201 && res.statusText === "Created") {
          //window.location = 'liste-coach';
          this.setState({
            AjoutCoachInfosInProgress: false,
            AjoutCoachInfoSuccess: true,
          });
        } else {
          this.setState({
            AjoutCoachInfosInProgress: false,
            // AjoutCoachInfoSuccess:true
          });
        }
      });
    }
  }
  // bf terminé
  /**
   * Get all completed training bakelistes
   */
  getCompletedTrainingBakelistes = () => {
    this.setState({
      isPageLoading: true,
    });

    axios
      .get(this.state.api + "bakelistes-with-completed-training")
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            bakelistes: response.data.data.reverse(),
            countBakeliste: response.data.count,
            isPageLoading: false,
          });
        } else {
          this.setState({
            bakelistes: response.data.data.reverse(),
            countBakeliste: response.data.count,
            isPageLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isRequestError: true,
          isPageLoading: false,
        });
      });
  };

  /**
   * Selected bakeliste infos
   */
  getSelectedBakelist = (bakeliste) => {
    this.setState(
      {
        bakelisteInfos: bakeliste,
        bakelisteID: bakeliste.id,
        emailValue: bakeliste.email,
        phoneValue: bakeliste.phone,
        boursierValue: bakeliste.boursier,
        demiBoursierValue: bakeliste.demi_boursier,
        stageFormationValue: bakeliste.is_stagede_formation,
        lieuFormationValue: bakeliste.lieu_formation,
        paiementInscriptionValue: bakeliste.paiement_inscription,
        debutFormationValue: bakeliste.debut_formation,
        finFormationValue: bakeliste.fin_formation,
        domaineFormationValue: bakeliste.bakeliste_domaine_id,
        formationPayanteValue: bakeliste.is_formation_payante,
      },
      () => {
        //console.log(this.state.bakelisteInfos)
      }
    );
  };

  /**
   * Action column data
   */
  actionsFormatterBF = (cell, row) => {
    return (
      <div className="actions-btn-container">
        {/* Bouton intégration bakeliste */}
        <button
          type="button"
          className="btn btn-success reIntegrate-btn volkeno-green-bcolor"
          id="reIntegrate-btn"
          data-toggle="modal"
          data-target="#reIntegrateModal"
          onClick={(e) => this.getSelectedBakelist(row, e)}
        >
          <i className="fas fa-undo"></i>
        </button>
      </div>
    );
  };

  fullnameFormatterBF = (cell, row) => {
    return (
      <span
        className="fullname-link"
        data-toggle="modal"
        data-target="#showHiredModal"
        onClick={(e) => this.getBakelisteInfos(row, e)}
      >
        {cell}
      </span>
    );
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  /**
   * Hide Alert message after 5s
   */
  handleShowAndHideAlertBF(input) {
    setTimeout(() => {
      if (input === "email") {
        this.setState({
          email_empty: false,
        });
      }
      if (input === "phone") {
        this.setState({
          phone_empty: false,
        });
      }
      if (input === "type_formation") {
        this.setState({
          tf_empty: false,
        });
      }
      if (input === "ispro") {
        this.setState({
          ispro_empty: false,
        });
      }
      if (input === "domaine_formation") {
        this.setState({
          df_empty: false,
        });
      }
      if (input === "formation_diplomante") {
        this.setState({
          fd_empty: false,
        });
      }
      if (input === "paiement_inscription") {
        this.setState({
          paiement_empty: false,
        });
      }
      if (input === "lieu_formation") {
        this.setState({
          lf_empty: false,
        });
      }
      if (input === "boursier") {
        this.setState({
          boursier_empty: false,
        });
      }
      if (input === "demi_boursier") {
        this.setState({
          demi_boursier_empty: false,
        });
      }
      if (input === "formation_payante") {
        this.setState({
          formation_payante_empty: false,
        });
      }
      if (input === "stagede_formation") {
        this.setState({
          stagede_formation_empty: false,
        });
      }
      if (input === "statut_professionnel") {
        this.setState({
          sp_empty: false,
        });
      }
      if (input === "pro_etudiant") {
        this.setState({
          pe_empty: false,
        });
      }
      if (input === "jour_1") {
        this.setState({
          j1_empty: false,
        });
      }
      if (input === "jour_2") {
        this.setState({
          j2_empty: false,
        });
      }
      if (input === "jour_3") {
        this.setState({
          j3_empty: false,
        });
      }
      if (input === "reIntSuccess") {
        this.setState({
          reIntSuccessAlert: false,
        });
      }
      if (input === "reIntError") {
        this.setState({
          reIntRequestError: false,
        });
      }
    }, 5000);
  }

  reIntHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["bakeliste_id"] = this.state.bakelisteID;

    if (e.target.name === "type_formation") {
      // test si c'est le champ type_formation
      fields[e.target.name] = e.target.value; // assignation de la valeur du input
      if (e.target.value === "courte") {
        // test si le choix est formation courte
        fields["jour_fd"] = ""; // déclarer null jour_fd
        this.setState({
          openStatutProfessionnelFC: true, // True pour afficher le bloc supplémentaire pour la formation courte
          openStatutFD: false, // False pour cacher le bloc complémentaire pour la formation diplômante
        });
      } else if (e.target.value === "diplomante") {
        // test si le choix est formation diplomante
        fields["nbre_jours"] = 5;
        fields["is_etudiant"] = 1;
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        fields["jour_fd"] = "lundi au vendredi";
        fields["jour_1"] = "Lundi";
        fields["jour_2"] = "Mardi";
        fields["jour_3"] = "Mercredi";
        fields["jour_4"] = "Jeudi";
        fields["jour_5"] = "Vendredi";
        fields["jour_6"] = "";
        this.setState({
          openStatutProfessionnelFC: false, // False pour cacher les champs complémentaire de la formation courte
          openStatutFD: true, // True pour ouvrir les champs complémnetaires de la formation diplomante
          isSemaine: false,
          isPro: false,
          isEtudiant: false,
        });
      }
    }

    if (e.target.name === "isproetudiant") {
      if (e.target.value === "professionnel") {
        // Test si le choix est professionnel
        fields["is_pro"] = 1;
        fields["is_etudiant"] = 0;
        this.setState({
          isPro: true,
          isEtudiant: false,
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "etudiant") {
        // Test si le choix est étudiant
        fields["is_etudiant"] = 1;
        fields["is_pro"] = 0;
        fields["nbre_jours"] = 3;
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isPro: false,
          isEtudiant: true,
          isSemaine: false,
        });
      }
    }
    if (e.target.name === "isWE") {
      // Test si c'est le week-end/week pro qui est choisi
      if (e.target.value === "semaine") {
        // Test si c'est la semaine qui est choisi
        fields["is_etudiant"] = 0;
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        fields["nbre_jours"] = 2;
        fields["horaire"] = "17h30-19h30";
        fields["jour_3"] = "";
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isSemaine: true,
        });
       // console.log(this.state.isSemaine);
      } else if (e.target.value === "weekend") {
        // Test si c'est le week-end qui est choisi
        fields["is_weekend"] = 1;
        fields["is_week"] = 0;
        fields["horaire"] = "9h-13h";
        fields["nbre_jours"] = 1;
        fields["jour_1"] = "Samedi";
        fields["jour_2"] = "";
        fields["jour_3"] = "";
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isSemaine: false,
        });
      }
      if (e.target.name === "email") {
        fields["email"] = e.target.value;
      }
    }
    if (e.target.name === "isWEE") {
      // Test si c'est un week-end/week étudiant qui est choisi
      if (e.target.value === "semaine") {
        // Test si c'est la semaine qui est choisie
        fields["is_etudiant"] = 1;
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        fields["nbre_jours"] = 3;
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isEtudiantSemaineChoice: true,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "weekend") {
        // Test si c'est le weeke end qui est choisi
        fields["is_weekend"] = 1;
        fields["is_week"] = 0;
        fields["horaire"] = "9h-13h";
        fields["nbre_jours"] = 1;
        fields["jour_1"] = "Samedi";
        fields["jour_2"] = "";
        fields["jour_3"] = "";
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "semainels") {
        // Test si c'est la semaine plus le samedi qui est choisi
        fields["is_etudiant"] = 1;
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        fields["nbre_jours"] = 6;
        fields["jour_1"] = "Lundi";
        fields["jour_2"] = "Mardi";
        fields["jour_3"] = "Mercredi";
        fields["jour_4"] = "Jeudi";
        fields["jour_5"] = "Vendredi";
        fields["jour_6"] = "Samedi";
       // console.log(fields);

        this.setState({
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: true,
        });
      }
    }

    if (e.target.name === "email") {
      this.setState({
        emailValue: e.target.value,
      });
    }
    if (e.target.name === "phone") {
      this.setState({
        phoneValue: e.target.value,
      });
    }
    if (e.target.name === "boursier") {
      this.setState({
        boursierValue: e.target.value,
      });
    }
    if (e.target.name === "demi_boursier") {
      this.setState({
        demiBoursierValue: e.target.value,
      });
    }
    if (e.target.name === "is_stagede_formation") {
      this.setState({
        stageFormationValue: e.target.value,
      });
    }
    if (e.target.name === "is_formation_payante") {
      this.setState({
        formationPayanteValue: e.target.value,
      });
    }
    if (e.target.name === "lieu_formation") {
      this.setState({
        lieuFormationValue: e.target.value,
      });
    }
    if (e.target.name === "bakeliste_domaine_id") {
      this.setState({
        domaineFormationValue: e.target.value,
      });
    }
    if (e.target.name === "paiement_inscription") {
      this.setState({
        paiementInscriptionValue: e.target.value,
      });
    }
    if (e.target.name === "debut_formation") {
      this.setState({
        debutFormationValue: e.target.value,
      });
    }
    if (e.target.name === "fin_formation") {
      this.setState({
        finFormationValue: e.target.valuephoneValue,
      });
    }

    if (!fields["email"]) {
      fields["email"] = this.state.emailValue;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.phoneValue;
    }
    if (!fields["boursier"]) {
      fields["boursier"] = this.state.boursierValue;
    }
    if (!fields["demi_boursier"]) {
      fields["demi_boursier"] = this.state.demiBoursierValue;
    }
    if (!fields["is_stagede_formation"]) {
      fields["is_stagede_formation"] = this.state.stageFormationValue;
    }
    if (!fields["is_formation_payante"]) {
      fields["is_formation_payante"] = this.state.formationPayanteValue;
    }
    if (!fields["lieu_formation"]) {
      fields["lieu_formation"] = this.state.lieuFormationValue;
    }
    if (!fields["bakeliste_domaine_id"]) {
      fields["bakeliste_domaine_id"] = this.state.domaineFormationValue;
    }
    if (!fields["paiement_inscription"]) {
      fields["paiement_inscription"] = this.state.paiementInscriptionValue;
    }
    if (!fields["debut_formation"]) {
      fields["debut_formation"] = this.state.debutFormationValue;
    }
    if (!fields["fin_formation"]) {
      fields["fin_formation"] = this.state.finFormationValue;
    }

    this.setState({
      fields,
    });
    //console.log(fields);
  };

  /**
   * Integrate bakeliste form validation
   */
  validateFormBF() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["email"]) {
      formIsValid = false;
      this.setState({
        email_empty: true,
      });
      this.handleShowAndHideAlertBF("email");
      errors["email"] = "*Ce champ est obligatoire.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        this.setState({
          email_empty: true,
        });
        this.handleShowAndHideAlertBF("email");
        errors["email"] = "*Adresse email invalide.";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      this.setState({
        phone_empty: true,
      });
      this.handleShowAndHideAlertBF("phone");
      errors["phone"] = "*Ce champ est obligatoire.";
    }

    // if (typeof fields["phone"] !== "undefined") {
    //     if (!fields["phone"].match(/^[0-9]{9}$/)) {
    //         formIsValid = false;
    //         errors["phone"] = "*Numéro de telephone invalide.";
    //     }
    // }
    if (!fields["boursier"]) {
      formIsValid = false;
      this.setState({
        boursier_empty: true,
      });
      this.handleShowAndHideAlertBF("boursier");
      errors["boursier"] = "*Ce champ est obligatoire.";
    }
    if (!fields["demi_boursier"]) {
      formIsValid = false;
      this.setState({
        demi_boursier_empty: true,
      });
      this.handleShowAndHideAlertBF("demi_boursier");
      errors["demi_boursier"] = "*Ce champ est obligatoire.";
    }
    if (!fields["is_formation_payante"]) {
      formIsValid = false;
      this.setState({
        formation_payante_empty: true,
      });
      this.handleShowAndHideAlertBF("formation_payante");
      errors["formation_payante"] = "*Ce champ est obligatoire.";
    }
    if (!fields["is_stagede_formation"]) {
      formIsValid = false;
      this.setState({
        stagede_formation_empty: true,
      });
      this.handleShowAndHideAlertBF("stagede_formation");
      errors["stagede_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["type_formation"]) {
      formIsValid = false;
      this.setState({
        tf_empty: true,
      });
      this.handleShowAndHideAlertBF("type_formation");
      errors["type_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["lieu_formation"]) {
      formIsValid = false;
      this.setState({
        lf_empty: true,
      });
      this.handleShowAndHideAlertBF("lieu_formation");
      errors["lieu_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["bakeliste_domaine_id"]) {
      formIsValid = false;
      this.setState({
        df_empty: true,
      });
      this.handleShowAndHideAlertBF("domaine_formation");
      errors["domaine_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["paiement_inscription"]) {
      formIsValid = false;
      this.setState({
        paiement_empty: true,
      });
      this.handleShowAndHideAlertBF("paiement_inscription");
      errors["paiement_inscription"] = "*Ce champ est obligatoire.";
    }
    if (
      fields["type_formation"] &&
      fields["type_formation"] === "courte" &&
      !fields["isproetudiant"]
    ) {
      //console.log("jour is pro etudiant");
      formIsValid = false;
      this.setState({
        sp_empty: true,
      });
      this.handleShowAndHideAlertBF("statut_professionnel");
      errors["statut_professionnel"] =
        "*Vous devez choisir un statut professionnel.";
    }
    if (
      fields["type_formation"] &&
      fields["isproetudiant"] &&
      fields["isproetudiant"] === "professionnel" &&
      !fields["isWE"]
    ) {
      //console.log("jour is week end");
      formIsValid = false;
      this.setState({
        pe_empty: true,
      });
      this.handleShowAndHideAlertBF("pro_etudiant");

      errors["pro_etudiant"] = "*Vous devez choisir un type de présence.";
    }
    if (
      fields["type_formation"] &&
      fields["isproetudiant"] &&
      fields["isWE"] &&
      fields["isWE"] === "semaine" &&
      !fields["jour_1"]
    ) {
      formIsValid = false;
      this.setState({
        j1_empty: true,
      });
      this.handleShowAndHideAlertBF("jour_1");
      errors["jour_1"] = "*Ce champ ne doit pas être vide.";
    }
    if (
      fields["type_formation"] &&
      fields["isproetudiant"] &&
      fields["isWE"] &&
      fields["isWE"] === "semaine" &&
      !fields["jour_2"]
    ) {
      formIsValid = false;
      this.setState({
        j2_empty: true,
      });
      this.handleShowAndHideAlertBF("jour_2");
      errors["jour_2"] = "*Ce champ ne doit pas être vide.";
    }
    if (
      fields["type_formation"] &&
      fields["isproetudiant"] &&
      fields["isWE"] &&
      fields["isproetudiant"] === "etudiant" &&
      !fields["jour_3"]
    ) {
      formIsValid = false;
      this.setState({
        j3_empty: true,
      });
      this.handleShowAndHideAlertBF("jour_3");
      errors["jour_3"] = "*Ce champ ne doit pas être vide.";
    }
    // if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isproetudiant"] == 'etudiant' && !fields["jour_2"]) {
    //     console.log('jour 2')
    //     formIsValid = false;
    //     this.state.j2_empty = true;
    //     this.handleShowAndHideAlert('jour_2');
    //     errors["jour_2"] = "*Ce champ ne doit pas être vide.";
    // }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  /**
   * Submit re-integrate bakeliste data
   */
  submitReIntData(e) {
    e.preventDefault();
    // if (this.validateForm()) { // Test si le formulaire est valide
    this.setState({
      updateInProgress: true,
    });

    const dataBakeliste = this.state.fields; // données du formulaire
    const bakelisteID = this.state.bakelisteID; // assignation de l'id du bakeliste

    axios
      .post(
        this.state.api + "re-integrate-bakeliste-training/" + bakelisteID,
        dataBakeliste
      )
      .then((response) => {
        if (response.data.success === true) {
          // Test si la requête est passée
          this.createNewBakelistePH(dataBakeliste); // Store des données formulaires sur la programmation horaire
        } else {
          // Au cas où la requête n'est pas passée
          this.setState({
            updateInProgress: false,
            reIntRequestError: true,
          });
          this.handleShowAndHideAlertBF("reIntError"); // Switch
        }
      });

    //}
  }
  /**
   * Create Bakeliste Programmation Horaire after reintegration
   *
   * @param Array data
   * @return Response
   */
  createNewBakelistePH(data) {
    axios
      .post(this.state.api + "saved-re-integrated-ph", data)
      .then((response) => {
        //console.log('ph response', response);
        if (response.data.success === true) {
          this.getCompletedTrainingBakelistes();
          $(".close").click();
          $("body").removeClass("modal-open");
          $(".modal-backdrop").remove();
          this.setState({
            updateInProgress: false,
            reIntSuccessAlert: true,
            fields: {},
          });
          this.handleShowAndHideAlertBF("reIntSuccess");
        } else {
          this.setState({
            updateInProgress: false,
            reIntRequestError: true,
          });
          this.handleShowAndHideAlertBF("reIntError");
        }
      })
      .catch((error) => {
        console.log("Error ", error);
        this.setState({
          updateInProgress: false,
          reIntRequestError: true,
        });
        this.handleShowAndHideAlertBF("reIntError");
      });
  }
  // user manage
  onGetUsersList = () => {
    this.setState({
      isPageLoading: true,
    });
    var api = this.state.api;

    axios
      .get(api + "list-admin-coach")
      .then((response) => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            users: response.data.data,
          });
        } else {
          this.setState({
            isPageLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onGetUserInfos = (user) => {
    this.setState({
      user: user,
    });
  };

  handleAccessChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };

  submitAccessPMRequest = (e) => {
    this.setState({
      changeAccessInProgress: true,
    });

    var userID = this.state.user.id;
    var data = this.state.fields;
    var baseApi = this.state.api;

    axios
      .post(baseApi + "can-access-pointme/" + userID, data)
      .then((response) => {
        this.setState({
          changeAccessInProgress: false,
        });

        if (response.data.success) {
          this.onGetUsersList();
          //$('#pointme-access-form')[0].reset();
          $(".close").click();
          $(".modal-backdrop").removeClass("modal-backdrop");
          $(".fade").removeClass("fade");
          $(".in").removeClass("in");
          this.setState({
            successAccessPointMeAlert: true,
            fields: {},
          });
          this.handleShowAndHideAlertMN("successAccessPointMeAlert");
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          changeAccessInProgress: false,
          errorAccessPointMeRequest: true,
        });
        this.handleShowAndHideAlertMN("errorAccessPointMeRequest");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handleShowAndHideAlertMN(label) {
    setTimeout(() => {
      if (label === "successAccessPointMeAlert") {
        this.setState({
          successAccessPointMeAlert: false,
        });
      }
      if (label === "errorAccessPointMeRequest") {
        this.setState({
          errorAccessPointMeRequest: false,
        });
      }
    }, 5000);
  }

  // actionsFormatter = (cell, row) => {

  // }

  userFullnameFormatterMN = (cell, row) => {
    return (
      <span
        data-toggle="tooltip"
        data-placement="top"
        title="Voir plus"
        type="button"
        className="userFullname"
        id="uFullname"
      >
        {row.first_name + " " + row.last_name}
      </span>
    );
  };

  statusFormatterMN = (cell, row) => {
    if (cell === "admin") {
      return <span className="label label-success label-vgreen"> {cell} </span>;
    }
    if (cell === "coach") {
      return <span className="label label-warning"> {cell} </span>;
    }
  };

  canAccessPointMeFormatter = (cell, row) => {
    if (cell === 1) {
      return (
        <span
          className="label label-success label-vgreen access_ok"
          data-toggle="modal"
          data-target="#postAccessModal"
          onClick={(e) => this.onGetUserInfos(row, e)}
        >
          {" "}
          <i className="fas fa-check"></i>{" "}
        </span>
      );
    } else {
      return (
        <span
          className="label label-danger bg-vred access_nok"
          data-toggle="modal"
          data-target="#postAccessModal"
          onClick={(e) => this.onGetUserInfos(row, e)}
        >
          {" "}
          <i className="fas fa-ban"></i>{" "}
        </span>
      );
    }
  };
  //  bv
  getListAllBakelistes = () => {
    axios
      .get(this.state.api + "liste-tous-les-bakelistes")
      .then((res) => {
        //console.log('All Bakelistes', res.data.data)
        if (res.data.success === true) {
          this.setState({
            bakeliste: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
          console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          getBakelisteRequestError: true,
        });
        console.log(error.message);
      });
  };

  // Action pour effacer les données du champs de recherche
  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  actionsFormatterBV(cell, row) {
    return (
      <span>
        <button
          onClick={() => {
            this.getBakelisteInfos(row);
          }}
          id="show_item"
          className="btn btn-info show-btn volkeno-green-bcolor"
          data-placement="top"
          title="Voir les infos du coach"
          data-toggle="modal"
          data-target="#showInfosModal"
        >
          <i class="fas fa-eye"></i>
        </button>
        &nbsp;&nbsp;
        <button
          type="button"
          className="btn btn-warning si-tooltip"
          id="btn_update"
          data-toggle="modal"
          data-target="#editBakelisteModal"
          onClick={(e) => this.getBakelisteInfos(row, e)}
        >
          <i className="fas fa-edit"></i>
          {/* <span className="tooltiptext">Modifier les infos</span> */}
        </button>
      </span>
    );
  }

  typeFormationFormatterBV = (cell, row) => {
    if (cell === "courte") {
      return (
        <span className="label label-success volkeno-green-bcolor">
          {" "}
          formation courte{" "}
        </span>
      );
    } else if (cell === "diplomante") {
      return (
        <span className="label label-warning volkeno-orange-bcolor">
          {" "}
          formation diplômante{" "}
        </span>
      );
    }
  };

  typeDeStageFormatterBV = (cell, row) => {
    if (row.is_stagede_formation === 1) {
      return (
        <span className="label label-success volkeno-green-bcolor">
          {" "}
          en stage de formation{" "}
        </span>
      );
    } else if (row.is_isa_contract === 1) {
      return (
        <span className="label label-warning volkeno-green-bcolor">
          {" "}
          sous contra ISA{" "}
        </span>
      );
    } else if (row.is_formation_payante === 1) {
      return (
        <span className="label label-danger volkeno-green-bcolor">
          {" "}
          formation payante{" "}
        </span>
      );
    } else {
      return (
        <span className="label label-info volkeno-green-bcolor">
          {" "}
          type de stage non trouvé{" "}
        </span>
      );
    }
  };
  updateHandleChange = (e) => {
    let fields = this.state.upFields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      this.setState({
        emailValue: e.target.value,
      });
    }
    if (e.target.name === "phone") {
      this.setState({
        phoneValue: e.target.value,
      });
    }
    if (e.target.name === "adresse") {
      this.setState({
        adresseValue: e.target.value,
      });
    }
    if (e.target.name === "ecole") {
      this.setState({
        ecoleValue: e.target.value,
      });
    }
    if (e.target.name === "boursier") {
      this.setState({
        boursierValue: e.target.value,
      });
    }
    if (e.target.name === "demi_boursier") {
      this.setState({
        demiBoursierValue: e.target.value,
      });
    }
    if (e.target.name === "is_stagede_formation") {
      this.setState({
        stageFormationValue: e.target.value,
      });
    }
    if (e.target.name === "is_formation_payante") {
      this.setState({
        formationPayanteValue: e.target.value,
      });
    }
    if (e.target.name === "is_isa_contract") {
      this.setState({
        isISAContractValue: e.target.value,
      });
    }
    if (e.target.name === "lieu_formation") {
      this.setState({
        lieuFormationValue: e.target.value,
      });
    }
    if (e.target.name === "horaire") {
      this.setState({
        horaireValue: e.target.value,
      });
    }
    if (e.target.name === "bakeliste_domaine_id") {
      this.setState({
        domaineFormationValue: e.target.value,
      });
    }
    if (e.target.name === "paiement_inscription") {
      this.setState({
        paiementInscriptionValue: e.target.value,
      });
    }
    if (e.target.name === "debut_formation") {
      this.setState({
        debutFormationValue: e.target.value,
      });
    }
    if (e.target.name === "fin_formation") {
      this.setState({
        finFormationValue: e.target.value,
      });
    }
    if (e.target.name === "first_name") {
      this.setState({
        firstNameValue: e.target.value,
      });
    }
    if (e.target.name === "last_name") {
      this.setState({
        lastNameValue: e.target.value,
      });
    }

    if (!fields["first_name"]) {
      fields["first_name"] = this.state.firstNameValue;
    }
    if (!fields["last_name"]) {
      fields["last_name"] = this.state.lastNameValue;
    }
    if (!fields["email"]) {
      fields["email"] = this.state.emailValue;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.phoneValue;
    }
    if (!fields["adresse"]) {
      fields["adresse"] = this.state.adresseValue;
    }
    if (!fields["ecole"]) {
      fields["ecole"] = this.state.ecoleValue;
    }
    if (!fields["boursier"]) {
      fields["boursier"] = this.state.updateBakeliste.boursier;
    }
    if (!fields["demi_boursier"]) {
      fields["demi_boursier"] = this.state.demiBoursierValue;
    }
    if (!fields["is_stagede_formation"]) {
      fields["is_stagede_formation"] = this.state.stageFormationValue;
    }
    if (!fields["is_formation_payante"]) {
      fields["is_formation_payante"] = this.state.formationPayanteValue;
    }
    if (!fields["is_isa_contract"]) {
      fields["is_isa_contract"] = this.state.isISAContractValue;
    }
    if (!fields["lieu_formation"]) {
      fields["lieu_formation"] = this.state.lieuFormationValue;
    }
    if (!fields["horaire"]) {
      fields["horaire"] = this.state.horaireValue;
    }
    if (!fields["bakeliste_domaine_id"]) {
      fields["bakeliste_domaine_id"] = this.state.domaineFormationValue;
    }
    if (!fields["paiement_inscription"]) {
      fields["paiement_inscription"] = this.state.paiementInscriptionValue;
    }
    if (!fields["debut_formation"]) {
      fields["debut_formation"] = this.state.debutFormationValue;
    }
    if (!fields["fin_formation"]) {
      fields["fin_formation"] = this.state.finFormationValue;
    }

    this.setState({
      upFields: fields,
    });

  };
  updateBakelisteInfos = (e) => {
    e.preventDefault();
    console.log(this.state.upFields);
    this.setState({
      updateInProgress: true,
    });
    let bakelisteID = this.state.bakelisteIDForUpdate;
    let data = this.state.upFields;
    let admin_id = window.sessionStorage.getItem("user_id");
    let historique = {};
    historique["bakeliste_id"] = bakelisteID;
    historique["admin_id"] = admin_id;
    historique["debut_date"] = this.state.debutFormationValue;
    historique["fin_date"] = this.state.finFormationValue;
    historique["text"] =
      "la formation de " +
      this.state.firstNameValue +
      " " +
      this.state.lastNameValue +
      " à été modifié";

    // data['admin_id'] =  admin_id
    axios
      .put(this.state.api + "update-bakeliste-infos/" + bakelisteID, data)
      .then((response) => {
        this.setState({
          updateInProgress: false,
        });
        //console.log(response.data.data)
        /* let  histo = this.historique(historique);
              console.log('historique',histo) */
        if (response.data.success === true) {
          // window.location = 'liste-bakelistes';
          //this.getBakelisteEnFormation();
          $(".close").click();
          $("body").removeClass("modal-open");
          $(".modal-backdrop").removeClass("modal-backdrop");
          $(".fade").removeClass("fade");
          $(".in").removeClass("in");
          this.setState({
            successAlert: true,
            upFields: {},
          });
          this.handleShowAndHideAlertBV("closeSuccessAlert");
        } else if (
          response.data.success === false &&
          response.data.exist === true
        ) {
          this.setState({
            isExistUser: true,
          });
          this.handleShowAndHideAlertBV("isExistUser");
        } else {
          console.log(response);
          this.setState({
            updateBakelisteRequestError: true,
          });
          this.handleShowAndHideAlertBV("closeErrorAlert");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateInProgress: false,
          updateBakelisteRequestError: true,
        });
        this.handleShowAndHideAlert("closeErrorAlert");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  handleShowAndHideAlertBV(label) {
    setTimeout(() => {
      if (label === "closeModal") {
        this.setState({
          isClosingModal: true,
        });
      }
      if (label === "closeSuccessAlert") {
        this.setState({
          successAlert: false,
        });
      }
      if (label === "closeErrorAlert") {
        this.setState({
          updateBakelisteRequestError: false,
        });
      }
      if (label === "successHired") {
        this.setState({
          successHiredAlert: false,
        });
      }
      if (label === "errorHired") {
        this.setState({
          errorHiredAlert: false,
        });
      }
      if (label === "successCompletedFormation") {
        this.setState({
          successCompletedFormationAlert: false,
        });
      }
      if (label === "errorCompletedFormation") {
        this.setState({
          completedFormationRequestError: false,
        });
      }
      if (label === "successImmersionAlert") {
        this.setState({
          successImmersionAlert: false,
        });
      }
      if (label === "errorImmersionAlert") {
        this.setState({
          errorImmersionAlert: false,
        });
      }
      if (label === "assignationSuccessAlert") {
        this.setState({
          assignationSuccessAlert: false,
        });
      }
      if (label === "assignationErrorAlert") {
        this.setState({
          assignationErrorAlert: false,
        });
      }
      if (label === "successRecruitable") {
        this.setState({
          successRecruitableAlert: false,
        });
      }
      if (label === "errorRecruitable") {
        this.setState({
          isRecruitableRequestError: false,
        });
      }
      if (label === "isExistUser") {
        this.setState({
          isExistUser: false,
        });
      }
    }, 5000);
  }

  setNavTabs(event) {
    let navs = this.state.navs;
    navs[event.target.name] = event.target.value;
    if (navs["drone"] === "utilisateurs") {
      this.setState({
        tab_utilisateurs: true,
        tab_bakelistes: false,
        tab_all_bakelistes: false,
        tab_participants: false,
        tab_visiteurs: false,
        tab_coach: false,
        tab_bf_termines: false,
        tab_user_manager: false,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: false,
      });
    }
    if (navs["drone"] === "bakelistes") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: true,
        tab_all_bakelistes: false,
        tab_participants: false,
        tab_visiteurs: false,
        tab_coach: false,
        tab_bf_termines: false,
        tab_user_manager: false,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: false,
      });
    }
    if (navs["drone"] === "all-bakelistes") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: false,
        tab_all_bakelistes: true,
        tab_participants: false,
        tab_visiteurs: false,
        tab_coach: false,
        tab_bf_termines: false,
        tab_user_manager: false,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: false,
      });
    }
    if (navs["drone"] === "participants") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: false,
        tab_all_bakelistes: false,
        tab_participants: true,
        tab_visiteurs: false,
        tab_coach: false,
        tab_bf_termines: false,
        tab_user_manager: false,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: false,
      });
    }
    if (navs["drone"] === "visiteurs") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: false,
        tab_all_bakelistes: false,
        tab_participants: false,
        tab_visiteurs: true,
        tab_coach: false,
        tab_bf_termines: false,
        tab_user_manager: false,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: false,
      });
    }
    if (navs["drone"] === "coach") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: false,
        tab_all_bakelistes: false,
        tab_participants: false,
        tab_visiteurs: false,
        tab_coach: true,
        tab_bf_termines: false,
        tab_user_manager: false,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: false,
      });
    }
    if (navs["drone"] === "bf-termines") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: false,
        tab_all_bakelistes: false,
        tab_participants: false,
        tab_visiteurs: false,
        tab_coach: false,
        tab_bf_termines: true,
        tab_user_manager: false,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: false,
      });
    }

    if (navs["drone"] === "user_manager") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: false,
        tab_all_bakelistes: false,
        tab_participants: false,
        tab_visiteurs: false,
        tab_coach: false,
        tab_bf_termines: false,
        tab_user_manager: true,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: false,
      });
    }
    if (navs["drone"] === "bakeliste_volkeno") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: false,
        tab_all_bakelistes: false,
        tab_participants: false,
        tab_visiteurs: false,
        tab_coach: false,
        tab_bf_termines: false,
        tab_user_manager: false,
        tab_bakeliste_volkeno: true,
        tab_liste_bak: false,
      });
    }

    if (navs["drone"] === "liste-bak") {
      this.setState({
        tab_utilisateurs: false,
        tab_bakelistes: false,
        tab_all_bakelistes: false,
        tab_participants: false,
        tab_visiteurs: false,
        tab_coach: false,
        tab_bf_termines: false,
        tab_user_manager: false,
        tab_bakeliste_volkeno: false,
        tab_liste_bak: true,
      });
    }
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
    };
    /* const selectRowProp = {
      mode: 'checkbox',
      showOnlySelected: true
    }; */

    if (this.state.isPageLoading) {
      return (
        <div className="component-manage-users">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">
            <section className="content-header">
              <h1>Utilisateurs</h1>
              <ol className="breadcrumb">
                <li>
                  <Link to="/dashboard-admin">Accueil</Link>
                </li>
                <li className="active">Utilisateurs</li>
              </ol>
            </section>

            <section className="content">
              <div className="list_users_container">
                <div id="loader"></div>
              </div>
            </section>
          </div>
        </div>
      );
    }

    return (
      <div className="component-manage-users">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
          Aprés avoir intégré AdminLTE */}

        <div className="row content-wrapper">
          <section className="content-header">
            <h1>Utilisateurs</h1>
            <ol className="breadcrumb">
              <li>
                <Link to="/dashboard-admin">Accueil</Link>
              </li>
              <li className="active">Utilisateurs</li>
            </ol>
          </section>

          <section className="content">
            {/* Blocs contain bakelistes delay list and absence list */}
            <div className="row tab-data-row">
              <div className="col-xs-2 tab-data-col">
                <form>
                  <div onChange={(event) => this.setNavTabs(event)}>
                    <div className="radiobtn">
                      <input
                        defaultChecked
                        type="radio"
                        value="utilisateurs"
                        name="drone"
                        id="user"
                        data-target="#users"
                      />
                      <label for="user">Utilisateurs</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="bakelistes"
                        name="drone"
                        id="bakeliste"
                        data-target="#bakeliste"
                      />
                      <label for="bakeliste">Bakeliste</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="all-bakelistes"
                        name="drone"
                        id="all_bak"
                        data-target="#all-bakelistes"
                      />
                      <label for="all_bak">All Bakelistes</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="participants"
                        name="drone"
                        id="part"
                        data-target="#participants"
                      />
                      <label for="part">Participants</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="visiteurs"
                        name="drone"
                        id="visiteur"
                        data-target="#visiteurs"
                      />
                      <label for="visiteur">Visiteurs</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="coach"
                        name="drone"
                        id="coach"
                        data-target="#ls"
                      />
                      <label for="coach">Coach</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="bf-termines"
                        name="drone"
                        id="bf_termine"
                        data-target="#lf"
                      />
                      <label for="bf_termine">BF termines</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="user_manager"
                        name="drone"
                        id="u_man"
                        data-target="#manage"
                      />
                      <label for="u_man">Users Manager</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="bakeliste_volkeno"
                        name="drone"
                        id="bak_volk"
                        data-target="#BV"
                      />
                      <label for="bak_volk">Bakelistes Volkeno</label>
                    </div>
                    <div className="radiobtn">
                      <input
                        type="radio"
                        value="liste-bak"
                        name="drone"
                        id="list_bak"
                        data-target="bak_list"
                      />
                      <label for="list_bak">Liste Bakeliste</label>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-xs-10">
                <div className="tab-content">
                  {this.state.tab_utilisateurs ? (
                    <div className="tab-pane active" id="users">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 prospectw-table-col">
                          <BootstrapTable
                            data={this.state.users}
                            striped={true}
                            hover={true}
                            condensed={true}
                            multiColumnSort={2}
                            options={options}
                            pagination
                            exportCSV={true}
                            search={true}
                          >
                            <TableHeaderColumn
                              dataAlign="center"
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataAlign="center"
                              dataField="last_name"
                              dataFormat={this.userFullnameFormatter.bind(this)}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              width="150"
                              dataSort={true}
                            >
                              Prénom et Nom
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataAlign="center"
                              dataField="email"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                              width="200"
                            >
                              Email
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataAlign="center"
                              width="150"
                              dataField="status"
                              dataFormat={this.statusFormatter.bind(this)}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Statuts
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="account_to_activate"
                              width="100"
                              dataAlign="center"
                              dataFormat={this.activateOrDeactivateUser.bind(
                                this
                              )}
                            >
                              {" "}
                              Actions{" "}
                            </TableHeaderColumn>

                            {/* <TableHeaderColumn row='1' dataField='event_bakeliste_validate_account' dataAlign="center"  width='100'
                                     dataFormat={this.toSendUserMail.bind(this)} >
                                  </TableHeaderColumn>   */}
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {this.state.isListBakelisteRequestError && (
                              <div className="row liste-bakeliste-request-error-row">
                                <div className="col-md-12 lbre-col">
                                  <div className="alert alert-danger lbre-alerty">
                                    Un probléme est survenu lors de la requête.
                                    Veuillez réessayer plus tard.
                                  </div>
                                </div>
                              </div>
                            )} */}
                  {this.state.tab_bakelistes ? (
                    <div className="tab-pane active" id="bakeliste">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-weekend-table-col">
                          <BootstrapTable
                            data={this.state.bakeliste}
                            striped={true}
                            hover={true}
                            condensed={true}
                            multiColumnSort={2}
                            options={options}
                            exportCSV
                            pagination
                            search={true}
                            trClassName={this.rowClassNameFormat}
                          >
                            <TableHeaderColumn
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="200"
                              dataField="bakelisteFullname"
                              dataFormat={this.bakelisteFullnameFormatter.bind(
                                this
                              )}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              Nom complet
                            </TableHeaderColumn>
                            <TableHeaderColumn width="200" dataField="email">
                              Email
                            </TableHeaderColumn>
                            <TableHeaderColumn width="200" dataField="phone">
                              Téléphone
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="230"
                              dataField="domaine"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              Domaine
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="coachFullname"
                              width="200"
                              dataFormat={this.coachRowFormatter.bind(this)}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              Coach
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="120"
                              dataField="type_formation"
                              dataFormat={this.typeFormationFormatter.bind(
                                this
                              )}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              Type de formation
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="150"
                              dataAlign="center"
                              dataField="id"
                              dataFormat={this.actionsFormatter.bind(this)}
                            >
                              Actions
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tab_all_bakelistes ? (
                    <div className="tab-pane active" id="all-bakelistes">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-ete-table-col">
                          <BootstrapTable
                            data={this.state.bakelistes}
                            striped={true}
                            hover={true}
                            condensed={true}
                            search={true}
                            scrollTop={"Bottom"}
                            pagination
                            options={options}
                            exportCSV
                          >
                            <TableHeaderColumn
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="bakelisteFullname"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              {" "}
                              Nom du bakeliste{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="domaine"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Domaine{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="type_formation"
                              dataFormat={this.typeFormationFormatter.bind(
                                this
                              )}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              {" "}
                              Type de formation{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="id"
                              dataFormat={this.typeDeStageFormatter.bind(this)}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              {" "}
                              Type de stage{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="ecole"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Ecole{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="phone"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Téléphone{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataAlign="center"
                              /* width='50' */ dataField="id"
                              dataFormat={this.actionsFormatter.bind(this)}
                            >
                              Actions
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tab_participants ? (
                    <div className="tab-pane active" id="participants">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-ete-table-col">
                          <BootstrapTable
                            data={this.state.participants}
                            striped={true}
                            hover={true}
                            condensed={true}
                            multiColumnSort={2}
                            options={options}
                            pagination
                            exportCSV
                            search={true}
                          >
                            <TableHeaderColumn
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="last_name"
                              dataFormat={this.userFullnameFormatter.bind(this)}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              width="150"
                              dataSort={true}
                            >
                              Prénom et Nom
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="email"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                              width="200"
                            >
                              Email
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataAlign="center"
                              width="150"
                              dataField="phone"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Téléphone
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="rencontre"
                              width="100"
                              dataAlign="center"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Rencontre
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="email"
                              dataAlign="center"
                              width="100"
                              dataFormat={this.toSendUserMail.bind(this)}
                            >
                              {" "}
                              Actions
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tab_visiteurs ? (
                    <div className="tab-pane active" id="visiteur">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-ete-table-col">
                          <BootstrapTable
                            data={this.state.nouveaux}
                            striped={true}
                            hover={true}
                            condensed={true}
                            multiColumnSort={2}
                            options={options}
                            pagination
                            exportCSV
                            search={true}
                          >
                            <TableHeaderColumn
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="last_name"
                              dataFormat={this.userFullnameFormatter.bind(this)}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              width="300"
                              dataSort={true}
                            >
                              Prénom et Nom
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="email"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                              width="250"
                            >
                              Email
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="phone">
                              Téléphone
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataAlign="center"
                              width="150"
                              dataField="status"
                              dataFormat={this.statusFormatter.bind(this)}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Statuts
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="id"
                              width="100"
                              dataAlign="center"
                              dataFormat={this.transformUserAbakeliste.bind(
                                this
                              )}
                            >
                              Actions
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tab_coach ? (
                    <div className="tab-pane active" id="ls">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-ete-table-col">
                          <BootstrapTable
                            data={this.state.coach}
                            striped={true}
                            hover={true}
                            condensed={true}
                            multiColumnSort={2}
                            options={options}
                            pagination
                            search={true}
                          >
                            <TableHeaderColumn
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="coachFullname">
                              Nom complet
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="domaine">
                              Domaine
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="nbreBakeliste"
                              dataFormat={this.nbreBakelistesFormatter.bind(
                                this
                              )}
                            >
                              Nombre Bakelistes
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="email">
                              Email
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="phone">
                              Téléphone
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="coach_is_actif"
                              dataFormat={this.statusFormatterCoach.bind(this)}
                              dataSort={true}
                            >
                              Status
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="id"
                              dataFormat={this.actionsFormatterCoach.bind(this)}
                            >
                              Actions
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tab_bf_termines ? (
                    <div className="tab-pane active" id="lBF">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-ete-table-col">
                          <BootstrapTable
                            data={this.state.bakelistes}
                            striped={true}
                            hover={true}
                            condensed={true}
                            multiColumnSort={2}
                            options={options}
                            exportCSV
                            pagination
                            search={true}
                          >
                            <TableHeaderColumn
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="bakelisteFullname"
                              dataFormat={this.fullnameFormatterBF.bind(this)}
                            >
                              Nom complet
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="email">
                              Email
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="phone">
                              Telephone
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="ecole">
                              Ecole
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="domaine">
                              Date de fin
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="id"
                              dataFormat={this.actionsFormatterBF.bind(this)}
                              dataSort={true}
                            >
                              Actions
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tab_user_manager ? (
                    <div className="tab-pane active" id="manage">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-ete-table-col">
                          <BootstrapTable
                            data={this.state.users}
                            striped={true}
                            hover={true}
                            condensed={true}
                            multiColumnSort={2}
                            options={options}
                            pagination
                            search={true}
                          >
                            <TableHeaderColumn
                              dataAlign="center"
                              width="50"
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            <TableHeaderColumn
                              width="200"
                              dataField="last_name"
                              dataFormat={this.userFullnameFormatterMN.bind(
                                this
                              )}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              Prénom et Nom
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              width="150"
                              dataField="email"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              Email
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataAlign="center"
                              width="100"
                              dataField="status"
                              dataFormat={this.statusFormatterMN.bind(this)}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Statuts
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataAlign="center"
                              width="100"
                              dataField="can_access_pointme"
                              dataFormat={this.canAccessPointMeFormatter.bind(
                                this
                              )}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Pointme Access
                            </TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tab_bakeliste_volkeno ? (
                    <div className="tab-pane active" id="BV">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-ete-table-col">
                          <BootstrapTable
                            data={this.state.bakeliste}
                            striped={true}
                            hover={true}
                            condensed={true}
                            search={true}
                            scrollTop={"Bottom"}
                            pagination
                            options={options}
                            exportCSV
                          >
                            <TableHeaderColumn
                              dataField="id"
                              isKey={true}
                              hidden={true}
                            >
                              {" "}
                              ID
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="bakelisteFullname"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              {" "}
                              Nom du bakeliste{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="domaine"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Domaine{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="type_formation"
                              dataFormat={this.typeFormationFormatterBV.bind(
                                this
                              )}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              {" "}
                              Type de formation{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="id"
                              dataFormat={this.typeDeStageFormatterBV.bind(
                                this
                              )}
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                              dataSort={true}
                            >
                              {" "}
                              Type de stage{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="ecole"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Ecole{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="phone"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Téléphone{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="coachFullname"
                              filter={{
                                type: "TextFilter",
                                delay: 1000,
                              }}
                            >
                              Coach{" "}
                            </TableHeaderColumn>
                            <TableHeaderColumn
                              dataField="id"
                              dataFormat={this.actionsFormatterBV.bind(this)}
                            >
                              Actions
                            </TableHeaderColumn>
                          </BootstrapTable>

                          {this.state.getBakelisteRequestError && (
                            <div className="row empty_container">
                              <div className="col-md-12 empty_content">
                                <div className="alert alert-danger danger-alerty">
                                  Un problème est survenu lors de la
                                  recupération des données. Veuillez réessayer
                                  plus tard. Si le probléme persite, veuillez
                                  contacter un administrateur du site en
                                  envoyant en email à<b> contact@bakeli.tech</b>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tab_liste_bak ? (
                    <div className="tab-pane active" id="bak_list">
                      <div className="row prospectw-table-row">
                        <div className="col-md-12 bakeli-ete-table-col">
                          {/* <Baklist /> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* end table */}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* modal */}
        <div
          className="modal fade"
          id="postAccessModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="postAccessModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="postAccessModalLabel">
                  {" "}
                  {this.state.user.first_name +
                    " " +
                    this.state.user.last_name}{" "}
                </h4>
              </div>
              <form id="pointme-access-form">
                <div className="modal-body">
                  {this.state.user.account_to_activate === 1 ? (
                    <div className="row pm_rowy">
                      <div className="col-md-12 pm_coly">
                        <div className="alert alert-success bg-vgreen">
                          <i className="fas fa-check"></i> Le compte est activé
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row pm_rowy">
                      <div className="col-md-12 pm_coly">
                        <div className="alert alert-danger bg-vred">
                          <i className="fas fa-ban"></i> Le compte est désactivé
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row pm_form_rowy">
                    <div className="col-md-12 pm_input_coly">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="account_to_activate"
                          onChange={this.handleToActivateAccountChange}
                        >
                          <option> --Choisir un état-- </option>
                          <option value={1}> Activé le compte </option>
                          <option value={0}> Désactivé le compte </option>
                        </select>
                      </div>
                    </div>
                    {this.state.errorToActivateAccountRequste && (
                      <div className="col-md-12 ri-error-alert-container">
                        <div className="alert alert-danger alerty-content">
                          Un problème est survenu lors de la modification.
                          Veuillez réessayer plus tard.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default cancel_btn cancel button"
                    data-dismiss="modal"
                  >
                    <i className="fas fa-times-circle"></i> &nbsp; Quitter
                  </button>
                  {!this.state.changeToActivateAccountProgress && (
                    <button
                      type="button"
                      className="btn btn-primary btn_for_assignation button addBtn"
                      onClick={(e) => this.submitToActivateAccountRequest(e)}
                    >
                      <i className="fas fa-user-check"></i>&nbsp; Modifier
                    </button>
                  )}
                  {this.state.changeToActivateAccountProgress && (
                    <button
                      type="button"
                      className="btn btn-warning button addBtn btn_for_wait"
                    >
                      Modification en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Success access form alert */}
        {this.state.successToActivateAccountAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; La modification
            a été effectuée avec succès.
          </div>
        )}
        {/* sned mail to user */}

        <div
          className="modal fade"
          id="sendMailTouser"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="postAccessModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="postAccessModalLabel">
                  {" "}
                  {this.state.user.first_name +
                    " " +
                    this.state.user.last_name}{" "}
                </h4>
              </div>
              <form id="pointme-access-form">
                <div className="modal-body">
                  <div className="row pm_rowy">
                    <div className="col-md-12 pm_coly send-to-mail">
                      <div className="alert alert">
                        <i className="fas fa-paper-plane"></i> Envoyer un e-mail
                        à {this.state.user.email} <br />
                        En fin de convertir son e-mail à un compter utilisateurs
                      </div>
                    </div>
                  </div>
                  <div className="row pm_form_rowy">
                    {this.state.errorSendMailToUserRequste && (
                      <div className="col-md-12 ri-error-alert-container">
                        <div className="alert alert-danger alerty-content">
                          Un problème est survenu lors de l'envoie. Veuillez
                          réessayer plus tard.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default cancel_btn cancel button"
                    data-dismiss="modal"
                  >
                    <i className="fas fa-times-circle"></i> &nbsp; Quitter
                  </button>
                  {!this.state.changeToSendMailProgress && (
                    <button
                      type="button"
                      className="btn btn-primary btn_for_assignation button addBtn"
                      onClick={(e) => this.submitToSendMailRequest(e)}
                    >
                      <i className="fas fa-paper-plane"></i>&nbsp; Envoyer
                    </button>
                  )}
                  {this.state.changeToSendMailProgress && (
                    <button
                      type="button"
                      className="btn btn-warning button addBtn btn_for_wait"
                    >
                      Envoie mail en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Success access form alert */}
        {this.state.successSendMailToUserAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; L'envoie a été
            effectuée avec succès.
          </div>
        )}

        {/* Details sur le abkeliste */}

        <div
          className="modal fade"
          id="moModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="myModalLabel">
                  Detail sur {this.state.dataBakeliste.first_name}&nbsp;
                  {this.state.dataBakeliste.last_name}
                </h4>
              </div>
              <div className="modal-body">
                {this.state.dataBakeliste.type_formation === "courte" ? (
                  this.state.dataBakeliste.nbreJours === "3" ? (
                    <div className="content_container">
                      <b>Jours de présences : </b> <br />
                      <span>
                        {" "}
                        {this.state.dataBakeliste.jour1},{" "}
                        {this.state.dataBakeliste.jour2},{" "}
                        {this.state.dataBakeliste.jour3}{" "}
                      </span>{" "}
                      <br />
                      <b>Heure de présence</b> <br />
                      <span className="horaire-bakeliste-item">
                        {" "}
                        {this.state.dataBakeliste.horaire}{" "}
                      </span>
                    </div>
                  ) : this.state.dataBakeliste.nbreJours === "5" ? (
                    <div className="content_container">
                      <b>Jours de présences : </b> <br />
                      <span>
                        {" "}
                        {this.state.dataBakeliste.jour1},{" "}
                        {this.state.dataBakeliste.jour2},{" "}
                        {this.state.dataBakeliste.jour3},{" "}
                        {this.state.dataBakeliste.jour4},{" "}
                        {this.state.dataBakeliste.jour5}{" "}
                      </span>{" "}
                      <br />
                      <b>Heure de présence</b> <br />
                      <span className="horaire-bakeliste-item">
                        {" "}
                        {this.state.dataBakeliste.horaire}{" "}
                      </span>
                    </div>
                  ) : (
                    this.state.dataBakeliste.nbreJours === "6" && (
                      <div className="content_container">
                        <b>Jours de présences : </b> <br />
                        <span>
                          {" "}
                          {this.state.dataBakeliste.jour1},{" "}
                          {this.state.dataBakeliste.jour2},{" "}
                          {this.state.dataBakeliste.jour3},{" "}
                          {this.state.dataBakeliste.jour4},{" "}
                          {this.state.dataBakeliste.jour5},{" "}
                          {this.state.dataBakeliste.jour6}{" "}
                        </span>{" "}
                        <br />
                        <b>Heure de présence</b> <br />
                        <span className="horaire-bakeliste-item">
                          {" "}
                          {this.state.dataBakeliste.horaire}{" "}
                        </span>
                      </div>
                    )
                  )
                ) : (
                  this.state.dataBakeliste.type_formation === "diplomante" && (
                    <div className="content_container">
                      <b>Jours de présences : </b> <br />
                      <span> Du Lundi au Vendredi </span> <br />
                      <b>Heure de présence</b> <br />
                      <span className="horaire-bakeliste-item">
                        {" "}
                        {this.state.dataBakeliste.horaire}{" "}
                      </span>
                    </div>
                  )
                )}
                <div className="email_container">
                  <span className="item_label">Email : </span>{" "}
                  <span className="label_content">
                    {this.state.dataBakeliste.email}
                  </span>
                </div>{" "}
                <hr />
                <div className="objectif_container">
                  <span className="item_label">Objectifs : </span>{" "}
                  <span className="label_content">
                    {this.state.dataBakeliste.objectifs}
                  </span>
                </div>{" "}
                <hr />
                {this.state.dataBakeliste.ecole !== "" && (
                  <div className="ecole_container">
                    <span className="item_label">Ecole : </span>{" "}
                    <span className="label_content">
                      {this.state.dataBakeliste.ecole}
                    </span>{" "}
                    <hr />
                  </div>
                )}
                <div className="email_container">
                  <span className="item_label">Téléphone : </span>{" "}
                  <span className="label_content">
                    {this.state.dataBakeliste.phone}
                  </span>
                </div>{" "}
                <hr />
                <div className="ecole_container">
                  <span className="item_label">Boursier : </span>
                  {this.state.dataBakeliste.boursier === 1 ? (
                    <span className="label_content">OUI</span>
                  ) : (
                    <span className="label_content">NON</span>
                  )}
                </div>{" "}
                <hr />
                <div className="email_container">
                  <span className="item_label">Lieu de formation : </span>{" "}
                  <span className="label_content">
                    {this.state.dataBakeliste.lieu_formation}
                  </span>
                </div>{" "}
                <hr />
                <div className="email_container">
                  <span className="item_label">Type de formation : </span>{" "}
                  <span className="label_content">
                    {this.state.dataBakeliste.type_formation}
                  </span>
                </div>{" "}
                <hr />
                <div className="ecole_container">
                  <span className="item_label">Formation payante : </span>
                  {this.state.dataBakeliste.is_formation_payante === 1 ? (
                    <span className="label_content">OUI</span>
                  ) : (
                    <span className="label_content">NON</span>
                  )}
                </div>{" "}
                <hr />
                <div className="email_container">
                  <span className="item_label">
                    Date de début de la formation :{" "}
                  </span>{" "}
                  <span className="label_content">
                    {this.state.dataBakeliste.startFrenchDateFormat}
                  </span>
                </div>{" "}
                <hr />
                <div className="email_container">
                  <span className="item_label">
                    Date de fin de la formation :{" "}
                  </span>{" "}
                  <span className="label_content">
                    {this.state.dataBakeliste.endFrenchDateFormat}
                  </span>
                </div>{" "}
                <hr />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
        {/* transformer le visiteur en un bakeliste */}
        <div
          className="modal fade"
          id="changeVisiteurModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="changeVisiteurModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="changeVisiteurModalLabel">
                  Transformer : {this.state.bakelisteFullname}&nbsp; en
                  Bakeliste
                </h4>
              </div>

              <div className="modal-body">
                <div className="edit-bakeliste-container">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Email:</label>
                      <input
                        type="Email"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Email"
                        name="email"
                        value={this.state.emailValue}
                        onChange={this.updateHandleChangeVisiteur}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Téléphone:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputNumero"
                        placeholder="Téléphone"
                        name="phone"
                        value={this.state.phoneValue}
                        onChange={this.updateHandleChangeVisiteur}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Civilité</label>
                      <select
                        name="civility"
                        className="form-control"
                        id="civilityInput"
                        value={this.state._phFields.civility}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir un statut -- </option>
                        <option value="Monsieur"> Monsieur </option>
                        <option value="Madame"> Madame </option>
                        <option value="Mademoiselle"> Mademoiselle </option>
                      </select>
                      {/*  {this.state.civility_empty &&
                                            <div className="errorMsg">{this.state.errors.civility}</div>
                                            } */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Situation matrimoniale</label>
                      <select
                        name="marital_status"
                        className="form-control"
                        id="marital_statusInput"
                        value={this.state._phFields.marital_status}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir un statut -- </option>
                        <option value="Marié(e)"> Marié(e) </option>
                        <option value="Celibataire"> Célibataire </option>
                      </select>
                    </div>
                    {/* {this.state.ms_empty &&
                                            <div className="errorMsg">{this.state.errors.marital_status}</div>
                                        } */}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Adresse:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputAdresse"
                        placeholder="Adresse du bakeliste"
                        name="address"
                        onChange={this.updateHandleChangeVisiteur}
                      />
                    </div>
                    {/*  {this.state.address_empty &&
                                            <div className="errorMsg">{this.state.errors.address}</div>
                                        } */}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Formation suivie:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputformationsuivie"
                        placeholder="Formation suivie"
                        name="formation"
                        onChange={this.updateHandleChangeVisiteur}
                      />
                    </div>
                    {/* {this.state.fs_empty &&
                                            <div className="errorMsg">{this.state.errors.formation_suivie}</div>
                                        } */}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Ecole:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputEcole"
                        placeholder="Ecole"
                        name="ecole"
                        onChange={this.updateHandleChangeVisiteur}
                      />
                    </div>
                    {/* {this.state.ecole_empty &&
                                            <div className="errorMsg">{this.state.errors.ecole}</div>
                                        } */}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Bakeliste level</label>
                      <select
                        name="bakeliste_level"
                        className="form-control"
                        id="marital_statusInput"
                        /* value={this.state._phFields.marital_status} */ onChange={
                          this.updateHandleChangeVisiteur
                        }
                      >
                        <option> --Choisir un niveau -- </option>
                        <option value="débutant"> Débutant </option>
                        <option value="intermédiaire"> Intermédiaire</option>
                        <option value="avancé"> Avancé</option>
                      </select>
                    </div>
                    {/* {this.state.ms_empty &&
                                            <div className="errorMsg">{this.state.errors.marital_status}</div>
                                        } */}
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label>Objectifs:</label>
                      <textarea
                        className="form-control"
                        id="inputObjectifs"
                        placeholder="Objectifs"
                        name="objectifs"
                        onChange={this.updateHandleChangeVisiteur}
                      ></textarea>
                    </div>
                    {/*  {this.state.obj_empty && 
                                            <div className="errorMsg">{this.state.errors.objectifs}</div>
                                        } */}
                  </div>
                  <div className="col-md-12">
                    <div className="form-group conditionnal-input">
                      <label>Formation:</label> <br />
                      <input
                        type="radio"
                        className="radio-bsi"
                        name="type_formation"
                        value="courte"
                        onChange={this.updateHandleChangeVisiteur}
                      />
                      <span>Formation courte</span> <br />
                      <input
                        type="radio"
                        className="radio-bsi"
                        name="type_formation"
                        value="diplomante"
                        onChange={this.updateHandleChangeVisiteur}
                      />
                      <span>Formation diplômante</span>
                    </div>
                    {/*  {this.state.tf_empty &&
                                            <div className="errorMsg">{this.state.errors.type_formation}</div>
                                        } */}
                  </div>
                  {this.state.openStatutProfessionnelFC && (
                    <div className="pro_etudiant_container col-md-12">
                      <div className="form-group">
                        <label>Statut professionnel</label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="professionnel"
                          onChange={this.updateHandleChangeVisiteur}
                        />
                        <span>Professionnel</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="etudiant"
                          onChange={this.updateHandleChangeVisiteur}
                        />
                        <span>Etudiant</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="a_tester"
                          onChange={this.updateHandleChangeVisiteur}
                        />
                        <span>Etudiant à tester</span> <br />
                      </div>
                    </div>
                  )}
                  {this.state.isEtudiant && (
                    <div className="etudiant_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Type de présence : </label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semaine"
                          onChange={this.updateHandleChangeVisiteur}
                        />
                        <span>Semaine (3 jours de présence )</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="weekend"
                          onChange={this.updateHandleChangeVisiteur}
                        />
                        <span>Week-End (Samedi et Dimanche de 9h à 17h)</span>{" "}
                        <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semainels"
                          onChange={this.updateHandleChangeVisiteur}
                        />
                        <span>Semaine (Du Lundi au Vendredi )</span>
                      </div>
                      {/* {this.state.pe_empty &&
                                                <div className="errorMsg">{this.state.errors.pro_etudiant}</div>
                                            } */}
                    </div>
                  )}

                  {this.state.isEtudiantSemaineLSChoice && (
                    <div className="etudiant_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Choix horaire :</label>
                        <select
                          className="form-control"
                          id="horaire"
                          name="horaire"
                          onChange={this.updateHandleChangeVisiteur}
                        >
                          <option> --Choisir un horaire-- </option>
                          <option value="8h-17h">8h - 17h</option>
                          <option value="8h-12h30">8h - 12h30</option>
                          <option value="12h30-17h">12h30 - 17h</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {this.state.isEtudiantSemaineChoice && (
                    <div className="etudiant_jhp_container">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix horaire :</label>
                          <select
                            className="form-control"
                            id="horaire"
                            name="horaire"
                            onChange={this.updateHandleChangeVisiteur}
                          >
                            <option> --Choisir un horaire-- </option>
                            <option value="8h-17h">8h - 17h</option>
                            <option value="8h-12h30">8h - 12h30</option>
                            <option value="12h30-17h">12h30 - 17h</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 1:</label>
                          <select
                            className="form-control"
                            id="jour_1"
                            name="jour_1"
                            onChange={this.updateHandleChangeVisiteur}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>
                        </div>
                        s{" "}
                        {/* {this.state.j1_empty &&
                                                <div className="errorMsg">{this.state.errors.jour_1}</div>
                                            } */}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 2:</label>
                          <select
                            className="form-control"
                            id="jour_2"
                            name="jour_2"
                            onChange={this.updateHandleChangeVisiteur}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>
                        </div>
                        {/* {this.state.j2_empty &&
                                                <div className="errorMsg">{this.state.errors.jour_2}</div>
                                            } */}
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 3:</label>
                          <select
                            className="form-control"
                            id="jour_3"
                            name="jour_3"
                            onChange={this.updateHandleChangeVisiteur}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>
                        </div>
                        {/*  {this.state.j3_empty &&
                                                <div className="errorMsg">{this.state.errors.jour_3}</div>
                                            } */}
                      </div>
                    </div>
                  )}

                  {this.state.isPro && (
                    <div className="pro_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Type de présence : </label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWE"
                          value="semaine"
                          onChange={this.updateHandleChangeVisiteur}
                        />
                        <span>
                          Semaine (2 jours de présence de 17h30 à 19h30)
                        </span>{" "}
                        <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWE"
                          value="weekend"
                          onChange={this.updateHandleChangeVisiteur}
                        />
                        <span>Week-End (Samedi de 9h à 13h)</span>
                      </div>
                      {/*  {this.state.pe_empty &&
                                                <div className="errorMsg">{this.state.errors.pro_etudiant}</div>
                                            } */}
                    </div>
                  )}
                  {this.state.isSemaine && (
                    <div className="jour_presence_container">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 1:</label>
                          <select
                            className="form-control"
                            id="jour_1"
                            name="jour_1"
                            onChange={this.updateHandleChangeVisiteur}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                          </select>
                        </div>
                        {this.state.j1_empty && (
                          <div className="errorMsg">
                            {this.state.errors.jour_1}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 2:</label>
                          <select
                            className="form-control"
                            id="jour_1"
                            name="jour_2"
                            onChange={this.updateHandleChangeVisiteur}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                          </select>
                        </div>
                        {/* {this.state.j2_empty &&
                                                    <div className="errorMsg">{this.state.errors.jour_2}</div>
                                                } */}
                      </div>
                    </div>
                  )}
                  {this.state.openStatutFD && (
                    <div className="fd_choice_container col-md-12">
                      <div className="form-group">
                        <label>Choix horaire : (Du Lundi au Vendredi) </label>
                        <select
                          className="form-control"
                          id="horaire"
                          name="horaire"
                          onChange={this.updateHandleChangeVisiteur}
                        >
                          <option> --Choisir un horaire-- </option>
                          <option value="8h-12h30">8h - 12h30</option>
                          <option value="12h30-17h">12h30 - 17h</option>
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Boursier:</label>
                      <select
                        className="form-control"
                        id="inputBoursier"
                        name="boursier"
                        value={this.state._phFields.boursier}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Demi-Boursier:</label>
                      <select
                        className="form-control"
                        id="inputDBoursier"
                        name="demi_boursier"
                        value={this.state._phFields.demi_boursier}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Formation payante :</label>
                      <select
                        className="form-control"
                        id="inputFormationP"
                        name="is_formation_payante"
                        value={this.state._phFields.is_formation_payante}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Stage de formation :</label>
                      <select
                        className="form-control"
                        id="inputSFormation"
                        name="is_stagede_formation"
                        value={this.state._phFields.is_stagede_formation}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Contrat ISA :</label>
                      <select
                        className="form-control"
                        id="inputISA"
                        name="is_isa_contract"
                        value={this.state._phFields.is_isa_contract}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Lieu de formation:</label>
                      <select
                        className="form-control"
                        id="inputlieu_formation"
                        name="lieu_formation"
                        value={this.state._phFields.lieu_formation}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir un lieu-- </option>
                        <option value="Sicap Foire">Sicap Foire</option>
                        <option value="Grand Yoff 1">Grand Yoff 1</option>
                        <option value="Grand Yoff 2">Grand Yoff 2</option>
                        <option value="Thiés">Thiés</option>
                        <option value="Tambacounda">Tambacounda</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Domaine de formation:</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="bakeliste_domaine_id"
                        value={this.state._phFields.bakeliste_domaine_id}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir un domaine-- </option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Paiement a l'inscription:</label>
                      <select
                        className="form-control"
                        id="inputpaiement_inscription"
                        name="paiement_inscription"
                        value={this.state._phFields.paiement_inscription}
                        onChange={this.updateHandleChangeVisiteur}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Date de début:</label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputdebut_formation"
                        name="debut_formation"
                        value={this.state._phFields.debut_formation}
                        onChange={this.updateHandleChangeVisiteur}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Date de fin:</label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputfin_formation"
                        name="fin_formation"
                        value={this.state._phFields.fin_formation}
                        onChange={this.updateHandleChangeVisiteur}
                      />
                    </div>
                  </div>

                  {this.state.updateBakelisteInfosRequestError === true && (
                    <div className="row update-error">
                      <div className="col-md-12 update-error-content alert alert-danger">
                        Un probléme est survenu lors de la modification.
                        Veuillez réessayer plus tard.
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-default cancel_btn cancel button"
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i> &nbsp; Quitter
                      </button>
                      {!this.state.updateBakelisteInfosInProgress && (
                        <button
                          type="button"
                          className="btn btn-primary btn_for_assignation button addBtn"
                          onClick={(e) =>
                            this.validateBakelisteSelfSubscribe(e)
                          }
                        >
                          <i className="fas fa-undo"></i>&nbsp; Transformer
                        </button>
                      )}
                      {this.state.updateBakelisteInfosInProgress && (
                        <button
                          type="button"
                          className="btn btn-warning button addBtn btn_for_wait"
                        >
                          Transformation en cours &nbsp;
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end trqnsfor, bakeliste */}

        {/* modal send mail to all user sendMailalluserModal */}

        <div
          className="modal fade"
          id="sendMailalluserModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="postAccessModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="postAccessModalLabel">
                  {" "}
                  Envoyer e-mail à &nbsp;
                  {this.state.user.first_name +
                    " " +
                    this.state.user.last_name}{" "}
                </h4>
              </div>
              <br />
              <br />
              <Form
                color="teal"
                size="large"
                id="register-form"
                ref="form"
                onSubmit={this.handleSubmit}
              >
                <TextArea
                  placeholder="Que voulez vous dire ?"
                  validators={["required"]}
                  name="description"
                  errorMessages={["CAnnot Be empty"]}
                  value={this.state.messageToUsers.description}
                  onChange={this.handleSendToMailAllUser}
                  style={{ minHeight: 100 }}
                  size="huge"
                  className="textarea-message"
                />
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default cancel_btn cancel button"
                    data-dismiss="modal"
                  >
                    <i className="fas fa-times-circle"></i> &nbsp; Quitter
                  </button>
                  {!this.state.changeTosendMailUsersProgress && (
                    <button
                      type="button"
                      className="btn btn-primary btn_for_assignation button addBtn"
                      onClick={(e) => this.submitSendToMailAllUser(e)}
                    >
                      <i className="fas fa-paper-plane"></i>&nbsp; Envoyer
                    </button>
                  )}
                  {this.state.changeTosendMailUsersProgress && (
                    <button
                      type="button"
                      className="btn btn-warning button addBtn btn_for_wait"
                    >
                      Envoie mail en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                  <div className="row pm_form_rowy">
                    {this.state.errorSendMailToAllUserRequste && (
                      <div className="col-md-12 ri-error-alert-container">
                        <div className="alert alert-danger alerty-content">
                          Un problème est survenu lors de l'envoie. Veuillez
                          réessayer plus tard.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>

        {/* Success access form alert */}
        {this.state.successSendMailToAllUserAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; L'envoie a été
            effectuée avec succès.
          </div>
        )}

        {/* assignation coach */}
        <div
          className="modal fade"
          id="myModale"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModaleLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="myModaleLabel">
                  Selection du coach
                </h4>
              </div>
              <div className="modal-body">
                <div className="form-group ">
                  <label>Coach:</label>
                  <select
                    className="form-control"
                    id="inputbakeli_domaine_id"
                    name="coachSelect"
                    onChange={this.handleChange}
                  >
                    <option>--Choisir un coach--</option>
                    {this.state.coachs.map((z, y) => (
                      <option value={z.id} key={y}>
                        {z.first_name} {z.last_name} {"-"} {z.nbreBakeliste}{" "}
                        {"bakeliste(s)"}
                      </option>
                    ))}
                  </select>
                </div>
                {this.isCoachAssignationRequestError && (
                  <div className="row assignation-error">
                    <div className="col-md-12 error-message-alert">
                      Un problème est survenu lors de l'assignation. veuillez
                      réessayer plus tard.
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default cancel_btn cancel button"
                  data-dismiss="modal"
                >
                  <i className="fas fa-times-circle"></i> &nbsp; Quitter
                </button>
                {!this.state.assignationEncours && (
                  <button
                    type="button"
                    id="ass"
                    className="btn btn-primary btn_for_assignation button addBtn"
                    onClick={(e) => this.assignCoach(e)}
                  >
                    <i className="fas fa-user-check"></i>&nbsp; Assigner
                  </button>
                )}
                {this.state.assignationEncours && (
                  <button
                    type="button"
                    className="btn btn-warning button addBtn btn_for_wait"
                  >
                    Assignation en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
              </div>
            </div>
            {/* liste coach */}
            {/* Modification du statut du coach */}    {/* Updated coach success toast alert */}
            {this.state.updateCoachInfosSuccessAlert === true && (
              <div className="alert alert-success text-center upsuccess-alert">
                <i class="fas fa-check-double"></i> &nbsp;&nbsp; Coach infos
                modifié avec succès.
              </div>
            )}
          </div>
        </div>

        {/* Success coach assignation toast alert */}
        {this.state.isCoachAssignationSuccessAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; Assignation
            effectuée avec succès.
          </div>
        )}

        {this.state.successUpdateBakelisteInfosAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; Inscription
            Validee avec succes.
          </div>
        )}
        {/* user manage modal */}
        <div
          className="modal fade"
          id="postAccessModalManage"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="postAccessModalManageLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="postAccessModalManageLabel">
                  {" "}
                  {this.state.user.first_name +
                    " " +
                    this.state.user.last_name}{" "}
                </h4>
              </div>
              <form id="pointme-access-form">
                <div className="modal-body">
                  {this.state.user.can_access_pointme === 1 ? (
                    <div className="row pm_rowy">
                      <div className="col-md-12 pm_coly">
                        <div className="alert alert-success bg-vgreen">
                          <i className="fas fa-check"></i> Accès activé sur
                          POINTme
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row pm_rowy">
                      <div className="col-md-12 pm_coly">
                        <div className="alert alert-danger bg-vred">
                          <i className="fas fa-ban"></i> Accès non activé sur
                          POINTme
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row pm_form_rowy">
                    <div className="col-md-12 pm_input_coly">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="can_access_pointme"
                          onChange={this.handleAccessChange}
                        >
                          <option> --Choisir un état-- </option>
                          <option value={1}> Activé l'accès </option>
                          <option value={0}> Désactivé l'accès </option>
                        </select>
                      </div>
                    </div>
                    {/* {this.state.errorAccessPointMeRequest && (
                      <div className="col-md-12 ri-error-alert-container">
                        <div className="alert alert-danger alerty-content">
                          Un problème est survenu lors de la modification.
                          Veuillez réessayer plus tard.
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default cancel_btn cancel button"
                    data-dismiss="modal"
                  >
                    <i className="fas fa-times-circle"></i> &nbsp; Quitter
                  </button>
                  {!this.state.changeAccessInProgress && (
                    <button
                      type="button"
                      className="btn btn-primary btn_for_assignation button addBtn"
                      onClick={(e) => this.submitAccessPMRequest(e)}
                    >
                      <i className="fas fa-user-check"></i>&nbsp; Modifier
                    </button>
                  )}
                  {this.state.changeAccessInProgress && (
                    <button
                      type="button"
                      className="btn btn-warning button addBtn btn_for_wait"
                    >
                      Modification en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Success access form alert */}
        {this.state.successAccessPointMeAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; La modification
            a été effectuée avec succès.
          </div>
        )}
        {/* end user manage */}
        {/* re-integrate bakeliste */}
        <div
          className="modal fade"
          id="reIntegrateModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="editBakelisteModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="editBakelisteModalLabel">
                  Réintégrer : {this.state.bakelisteInfos.bakelisteFullname}
                </h4>
              </div>
              <div className="modal-body">
                <div className="edit-bakeliste-container">
                  <div className="col-md-12">
                    <div className="form-group conditionnal-input">
                      <label>Formation :</label> <br />
                      <input
                        type="radio"
                        className="radio-bsi"
                        name="type_formation"
                        value="courte"
                        onChange={this.reIntHandleChange}
                      />
                      <span>Formation courte</span> <br />
                      <input
                        type="radio"
                        className="radio-bsi"
                        name="type_formation"
                        value="diplomante"
                        onChange={this.reIntHandleChange}
                      />
                      <span>Formation diplômante</span>
                    </div>
                    {this.state.type_formation && (
                      <div className="errorMsg">
                        {this.state.errors.type_formation}
                      </div>
                    )}
                  </div>

                  {/* Affiché si la formation courte est checké */}
                  {this.state.openStatutProfessionnelFC && (
                    <div className="pro_etudiant_container col-md-12">
                      <div className="form-group">
                        <label>Statut professionnel</label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="professionnel"
                          onChange={this.reIntHandleChange}
                        />
                        <span>Professionnel</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="etudiant"
                          onChange={this.reIntHandleChange}
                        />
                        <span>Etudiant</span>
                      </div>
                      {this.state.sp_empty && (
                        <div className="errorMsg">
                          {this.state.errors.statut_professionnel}
                        </div>
                      )}
                    </div>
                  )}
                  {/* Afficher si c'est un étudiant */}
                  {this.state.isEtudiant && (
                    <div className="etudiant_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Type de présence : </label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semaine"
                          onChange={this.reIntHandleChange}
                        />
                        <span>Semaine (3 jours de présence )</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="weekend"
                          onChange={this.reIntHandleChange}
                        />
                        <span>Week-End (Samedi de 9h à 13h)</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semainels"
                          onChange={this.reIntHandleChange}
                        />
                        <span>Semaine (Du Lundi au Vendredi )</span>
                      </div>
                      {this.state.pe_empty && (
                        <div className="errorMsg">
                          {this.state.errors.pro_etudiant}
                        </div>
                      )}
                    </div>
                  )}

                  {/* Affiché si c'est la semaine de 6 jours */}
                  {this.state.isEtudiantSemaineLSChoice && (
                    <div className="etudiant_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Choix horaire :</label>
                        <select
                          className="form-control"
                          id="horaire"
                          name="horaire"
                          onChange={this.reIntHandleChange}
                        >
                          <option> --Choisir un horaire-- </option>
                          <option value="8h-17h">8h - 17h</option>
                          <option value="8h-12h30">8h - 12h30</option>
                          <option value="12h30-17h">12h30 - 17h</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {/* Affiché si c'est la semaine choix étudiant */}
                  {this.state.isEtudiantSemaineChoice && (
                    <div className="etudiant_jhp_container">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix horaire :</label>
                          <select
                            className="form-control"
                            id="horaire"
                            name="horaire"
                            onChange={this.reIntHandleChange}
                          >
                            <option> --Choisir un horaire-- </option>
                            <option value="8h-17h">8h - 17h</option>
                            <option value="8h-12h30">8h - 12h30</option>
                            <option value="12h30-17h">12h30 - 17h</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 1:</label>
                          <select
                            className="form-control"
                            id="jour_1"
                            name="jour_1"
                            onChange={this.reIntHandleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>
                        </div>
                        {this.state.j1_empty && (
                          <div className="errorMsg">
                            {this.state.errors.jour_1}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 2:</label>
                          <select
                            className="form-control"
                            id="jour_2"
                            name="jour_2"
                            onChange={this.reIntHandleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>
                        </div>
                        {this.state.j2_empty && (
                          <div className="errorMsg">
                            {this.state.errors.jour_2}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 3:</label>
                          <select
                            className="form-control"
                            id="jour_3"
                            name="jour_3"
                            onChange={this.reIntHandleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>
                        </div>
                        {this.state.j3_empty && (
                          <div className="errorMsg">
                            {this.state.errors.jour_3}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Affiché si c'est un PRO */}
                  {this.state.isPro && (
                    <div className="pro_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Type de présence : </label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWE"
                          value="semaine"
                          onChange={this.reIntHandleChange}
                        />
                        <span>
                          Semaine (2 jours de présence de 17h30 à 19h30)
                        </span>{" "}
                        <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWE"
                          value="weekend"
                          onChange={this.reIntHandleChange}
                        />
                        <span>Week-End (Samedi de 9h à 13h)</span>
                      </div>
                      {this.state.pe_empty && (
                        <div className="errorMsg">
                          {this.state.errors.pro_etudiant}
                        </div>
                      )}
                    </div>
                  )}

                  {/* Affiché si c'est la semaine pro */}
                  {this.state.isSemaine && (
                    <div className="jour_presence_container">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 1:</label>
                          <select
                            className="form-control"
                            id="jour_1"
                            name="jour_1"
                            onChange={this.reIntHandleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                          </select>
                        </div>
                        {this.state.j1_empty && (
                          <div className="errorMsg">
                            {this.state.errors.jour_1}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 2:</label>
                          <select
                            className="form-control"
                            id="jour_1"
                            name="jour_2"
                            onChange={this.reIntHandleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                          </select>
                        </div>
                        {this.state.j2_empty && (
                          <div className="errorMsg">
                            {this.state.errors.jour_2}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Affiché si c'est la formation diplômante qui est choisie */}
                  {this.state.openStatutFD && (
                    <div className="fd_choice_container col-md-12">
                      <div className="form-group">
                        <label>Choix horaire : (Du Lundi au Vendredi) </label>
                        <select
                          className="form-control"
                          id="horaire"
                          name="horaire"
                          onChange={this.reIntHandleChange}
                        >
                          <option> --Choisir un horaire-- </option>
                          <option value="8h-12h30">8h - 12h30</option>
                          <option value="12h30-17h">12h30 - 17h</option>
                        </select>
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Email:</label>
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        placeholder="Email"
                        name="email"
                        value={this.state.emailValue}
                        onChange={this.reIntHandleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Téléphone:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputNumero"
                        placeholder="Téléphone"
                        name="phone"
                        value={this.state.phoneValue}
                        onChange={this.reIntHandleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Boursier:</label>
                      <select
                        className="form-control"
                        id="inputBoursier"
                        name="boursier"
                        value={this.state.boursierValue}
                        onChange={this.reIntHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Demi-Boursier:</label>
                      <select
                        className="form-control"
                        id="inputDBoursier"
                        name="demi_boursier"
                        value={this.state.demiBoursierValue}
                        onChange={this.reIntHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Formation payante :</label>
                      <select
                        className="form-control"
                        id="inputFormationP"
                        name="is_formation_payante"
                        value={this.state.formationPayanteValue}
                        onChange={this.reIntHandleChange}
                      >
                        <option> --Choisir une Modificationoption-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Stage de formation :</label>
                      <select
                        className="form-control"
                        id="inputSFormation"
                        name="is_stagede_formation"
                        value={this.state.stageFormationValue}
                        onChange={this.reIntHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Lieu de formation:</label>
                      <select
                        className="form-control"
                        id="inputlieu_formation"
                        name="lieu_formation"
                        value={this.state.lieuFormationValue}
                        onChange={this.reIntHandleChange}
                      >
                        <option> --Choisir un lieu-- </option>
                        <option value="Sicap Foire">Sicap Foire</option>
                        <option value="Grand Yoff 1">Grand Yoff 1</option>
                        <option value="Grand Yoff 2">Grand Yoff 2</option>
                        <option value="Thiés">Thiés</option>
                        <option value="Labé">Labé (Guinée)</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Domaine de formation:</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="bakeliste_domaine_id"
                        value={this.state.domaineFormationValue}
                        onChange={this.reIntHandleChange}
                      >
                        <option> --Choisir un domaine-- </option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Paiement a l'inscription:</label>
                      <select
                        className="form-control"
                        id="inputpaiement_inscription"
                        name="paiement_inscription"
                        value={this.state.paiementInscriptionValue}
                        onChange={this.reIntHandleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Date de début:</label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputdebut_formation"
                        name="debut_formation"
                        value={this.state.debutFormationValue}
                        onChange={this.reIntHandleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Date de fin:</label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputfin_formation"
                        name="fin_formation"
                        value={this.state.finFormationValue}
                        onChange={this.reIntHandleChange}
                      />
                    </div>
                  </div>

                  {/* {this.state.updateBakelisteRequestError && (
                    <div className="col-md-12 alert alert-danger updateBakelisteError">
                      Un problème est survenu lors de la modification. Veuillez
                      réessayer plus tard.
                    </div>
                  )} */}
                </div>
                <hr />
                {/* {this.state.reIntRequestError && (
                  <div className="col-md-12 alert alert-danger request-error">
                    Un problème est survenu lors de la sauvegarde. Veuillez
                    réessayer plus tard.
                  </div>
                )} */}
                <div className="modal-footer">
                  <div className="col-md-12 actions-col">
                    <button
                      type="button"
                      className="btn btn-default cancel_btn cancel button"
                      data-dismiss="modal"
                    >
                      <i className="fas fa-times-circle"></i> &nbsp; Quitter
                    </button>
                    {!this.state.updateInProgress && (
                      <button
                        type="button"
                        className="btn btn-primary btn_for_assignation button addBtn"
                        onClick={(e) => this.submitReIntData(e)}
                      >
                        <i className="fas fa-edit"></i>&nbsp; Ré-intégrer
                      </button>
                    )}
                    {this.state.updateInProgress && (
                      <button
                        type="button"
                        className="btn btn-warning button addBtn btn_for_wait"
                      >
                        Validation en cours &nbsp;
                        <i className="fas fa-spinner fa-spin"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Successfull reintegration toast */}
        {this.state.reIntSuccessAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp; Bakeliste
            réintégré avec succès.
          </div>
        )}
        {/* Afficher les infos du coach */}
        <div
          className="modal fade"
          id="showInfosModalCoach"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="showInfosModalCoachLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showInfosModalCoachLabel">
                  Detail sur {this.state.coachFullname}
                </h4>
              </div>
              <div className="modal-body">
                <div className="coachInfos-container">
                  <div className="fullname_container">
                    <span className="item_label">Prénom et Nom : </span>{" "}
                    <span className="label_content">
                      {this.state.coachFullname}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="email_container">
                    <span className="item_label">Email : </span>{" "}
                    <span className="label_content">
                      {this.state.coachEmail}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="phone_container">
                    <span className="item_label">Téléphone : </span>{" "}
                    <span className="label_content">
                      {this.state.coachPhone}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="domaine_container">
                    <span className="item_label">Domaine de compétence : </span>{" "}
                    <span className="label_content">
                      {this.state.coachDomaine}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="count_container">
                    <span className="item_label nbakelistes">
                      Nombre de bakelistes :{" "}
                    </span>{" "}
                    <br />
                    <span className="label_content cbakelistes">
                      {this.state.coachNbreBakelistes}
                    </span>
                  </div>{" "}
                  <hr />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
        {/* end info coach */}
        {/* Ajouter un  coach */}
        <div
          className="modal fade"
          id="AddMsgModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddMsgModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="AddMsgModalLabel">
                  Ajouter Coach
                </h4>
              </div>

              <div className="modal-body">
                <div className="row edit-coach-container">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Prénom : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.AddhandleChange}
                        name="first_name"
                      />
                    </div>
                  </div>
                  {this.state.fn_empty && (
                    <div className="errorMsg">
                      {this.state.errors.first_name}
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Nom : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.AddhandleChange}
                        name="last_name"
                      />
                    </div>
                  </div>
                  {this.state.ln_empty && (
                    <div className="errorMsg">
                      {this.state.errors.last_name}
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Email : </label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={this.AddhandleChange}
                        name="email"
                      />
                    </div>
                  </div>
                  {this.state.email_empty && (
                    <div className="errorMsg">{this.state.errors.email}</div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Téléphone : </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={this.AddhandleChange}
                        name="phone"
                      />
                    </div>
                  </div>
                  {this.state.phone_empty && (
                    <div className="errorMsg">{this.state.errors.phone}</div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Domaine :</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="coach_domaine_id"
                        onChange={this.AddhandleChange}
                      >
                        <option> -- Choisir un domaine -- </option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {this.state.df_empty && (
                    <div className="errorMsg">{this.state.errors.email}</div>
                  )}
                  {/* {this.state.updateCoachInfosRequestError &&
                                                <div className="alerty-danger-container col-md-12 alert alert-danger form-group">
                                                    <div className="alerty-danger">
                                                        Un probléme est survenu lors de l'ajout. Veuillez réessayer plus tard.
                                                    </div>
                                                </div>
                                            } */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default cancel_btn cancel button"
                  data-dismiss="modal"
                >
                  <i class="fas fa-times-circle"></i> &nbsp; Quitter
                </button>

                {!this.state.AjoutCoachInfosInProgress && (
                  <button
                    type="button"
                    className="btn btn-primary btn_for_assignation button addBtn"
                    onClick={(e) => this.submituserRegistrationForm(e)}
                  >
                    <i class="fas fa-edit"></i>&nbsp; Ajouter
                  </button>
                )}
                {this.state.AjoutCoachInfosInProgress && (
                  <button
                    type="button"
                    className="btn btn-warning button addBtn btn_for_wait"
                  >
                    Ajout en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
                {/* {this.state.updatedError &&
                                            <div className="row update-error-container">
                                                <div className="col-md-12 error-content alert alert-danger">
                                                    Oups...Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                    </div>
                                            </div>
                                        } */}
              </div>
            </div>
          </div>
        </div>
        {this.state.AjoutCoachInfoSuccess === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Coach ajouté avec
            succès.
          </div>
        )}
        {/* Editer les infos du coach */}
        <div
          className="modal fade"
          id="sendMsgModalCoach"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="sendMsgModalCoachLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="sendMsgModalCoachLabel">
                  Editer {this.state.coachFullname}
                </h4>
              </div>

              <div className="modal-body">
                <div className="row edit-coach-container">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Prénom : </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.coachFirstname}
                        onChange={this.updateHandleChangeCoach}
                        name="first_name"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Nom : </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.coachLastname}
                        onChange={this.updateHandleChangeCoach}
                        name="last_name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Email : </label>
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.coachEmail}
                        onChange={this.updateHandleChangeCoach}
                        name="email"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Téléphone : </label>
                      <input
                        type="number"
                        className="form-control"
                        value={this.state.coachPhone}
                        onChange={this.updateHandleChangeCoach}
                        name="phone"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Domaine :</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="coach_domaine_id"
                        value={this.state.coachDomaineID}
                        onChange={this.updateHandleChangeCoach}
                      >
                        <option> -- Choisir un domaine -- </option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* {this.state.updateCoachInfosRequestError && (
                    <div className="alerty-danger-container col-md-12 alert alert-danger form-group">
                      <div className="alerty-danger">
                        Un probléme est survenu lors de la modification.
                        Veuillez réessayer plus tard.
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default cancel_btn cancel button"
                  data-dismiss="modal"
                >
                  <i class="fas fa-times-circle"></i> &nbsp; Quitter
                </button>

                {!this.state.updateCoachInfosInProgress && (
                  <button
                    type="button"
                    className="btn btn-primary btn_for_assignation button addBtn"
                    onClick={(e) => this.updatedCoachData(e)}
                  >
                    <i class="fas fa-edit"></i>&nbsp; Modifier
                  </button>
                )}
                {this.state.updateCoachInfosInProgress && (
                  <button
                    type="button"
                    className="btn btn-warning button addBtn btn_for_wait"
                  >
                    Modification en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Updated coach success toast alert */}
        {this.state.updateCoachInfosSuccessAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Coach infos modifié
            avec succès.
          </div>
        )}



                <div
                  className="modal fade"
                  id="changeCoachStatusModale"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="changeCoachStatusModaleLabel"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4
                          className="modal-title"
                          id="changeCoachStatusModaleLabel"
                        >
                          Detail sur{" "}
                        </h4>
                      </div>
                      <div className="modal-body">
                        <div className="update-coach-status-container">
                          <div class="form-group ">
                            <select
                              class="form-control"
                              id="inputStatus_Reporting"
                              name="coach_is_actif"
                              onClick={this.updateCoachStatusHandleChange}
                            >
                              <option>--Choisir un statut--</option>
                              <option value={1}>Activer</option>
                              <option value={0}>Désactivé</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-default cancel_btn cancel button"
                            data-dismiss="modal"
                          >
                            <i class="fas fa-times-circle"></i> &nbsp; Quitter
                          </button>
                          {!this.state.updatedCoachStatusInProgress && (
                            <button
                              type="button"
                              className="btn btn-primary btn_for_assignation button addBtn"
                              onClick={(e) => this.updateCoachStatus(e)}
                            >
                              <i class="fas fa-user-check"></i>&nbsp; Modifier
                            </button>
                          )}
                          {this.state.updatedCoachStatusInProgress && (
                            <button
                              type="button"
                              className="btn btn-warning button addBtn btn_for_wait"
                            >
                              Modification en cours &nbsp;
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          )}
                          {this.state.updatedError && (
                            <div className="row update-error-container">
                              <div className="col-md-12 error-content alert alert-danger">
                                Oups...Un problème est survenu lors de la
                                modification. Veuillez réessayer plus tard.
                              </div>
                            </div>
                          )}
                        </div>
                     
                    </div>
                  </div>
                </div>
      </div>
    );
  }
}
