import React, { Component } from 'react';
import './Reportings.css';
import axios from 'axios';
import API from '../../variablesGlobales';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import $ from 'jquery';
import * as Sentry from '@sentry/browser'
import { NavLink } from 'react-router-dom';
//import { CSVReader } from 'react-papaparse';
import amplitude from "amplitude-js";
import {
  Amplitude
} from "@amplitude/react-amplitude";
 //Sentry.init({dsn: "https://6368bf52547c42ffa1512f91c39113a3@sentry.io/1731980"});
 Sentry.init({dsn: "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964"});
  
class Reportings extends Component {
    data = [];
    static defaultProps = {
        required: true
    }
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        if (!token) {
            window.history.back()
        }
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.onUpdateReportingStatus = this.onUpdateReportingStatus.bind(this);
        this.onUpdateReportingRequest = this.onUpdateReportingRequest.bind(this);
        this.updateHandleChange = this.updateHandleChange.bind(this);
        this.filterList = this.filterList.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fileInput = React.createRef();
        this.fileUploadHandler = this.fileUploadHandler.bind(this);
        this.state = {
            reportings: [],
            domains: [],
            api: API.API,
            fields: {},
            reportingEncours: false,
            addReportingError: false,
            userStatus: window.sessionStorage.getItem('user_status'),
            currentConnecteduserID: window.sessionStorage.getItem('user_id'),
            
            coachID: window.sessionStorage.getItem('coach_id'),
            isAdmin: false,
            isCoach: false,
            isBakeliste: false,
            bakelistesByCoach: [],
            allBakeliste: [],
            listBakeliste: [],
            reportingByBakeliste: [],
            bakelistReportsTab: [],
            bakelistEmptyReportsTab: {
                id: "",
                date: "",
                tache: "",
                status: "",
                commentaire: "",
                using_links: "",
                lieu: "",
                app_name: "",
                technology: "",
                bakeliste_id: "",
                coach_id: "",
                created_at: "",
                updated_at: "",
                deleted_at: ""
            },
            reporting_id: 0,
            dateValue: '',
            dateFRValue: '',
            tacheValue: '',
            statutValue: '',
            commentaireValue: '',
            using_linksValue: '',
            lieuValue: '',
            appNameValue: '',
            technologyValue: '',
            bakelisteIDValue: 0,
            updateDateValue: '',
            coachIDValue: 0,
            updateFields: {},
            updateReportingPending: false,
            updateReportingID: 0,
            bakelisteAddReportLoading: false,
            currentPage: 1,
            itemPerPage: 10,
            addReportingSuccessAlert: false,
            updateReportingSuccessAlert: false,
            updateReportingStatusSuccessAlert: false,
            isUpdateStatusError: false,
            errors: {},
            emptyDate: false,
            emptyLieu: false,
            emptyStatus: false,
            emptyTache: false,
            isLoadingBakelisteData: false,
            reporting_lastfill:[],
            last_date: 0,
            search:null,
            staatus:'Terminé',
         date:'',
         lieeu:'bakeli',
         app_namee:'name_app',
         technologye:'techno',
         bakelister_id: window.sessionStorage.getItem('user_id'),
         using_links:'',
         commentaire:'',
         file:'',
         importInProgress: false,
        successImportAlert: false
        }
        Sentry.init({dsn: "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964"});

        this.handleClick = this.handleClick.bind(this);
      /*  this.onSearchClickExample = this.onSearchClickExample.bind(this); */
    }

    handleClick(event) {
        this.setState({
          currentPage: Number(event.target.id)
        });
      }


    fadeOutBakelisteReports = (x) => {
        axios.get(this.state.api + `reporting-by-bakeliste/${x}`)
            .then(res => {
                let bakelistReportsTab = res.data.data;
                this.setState({
                    bakelistReportsTab,
                    bakelistEmptyReportsTab: this.state.bakelistReportsTab
                });
                //console.log(this.state.bakelistReportsTab, "bakhna")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })

    }


    componentDidMount() {
        this.fadeOutBakelisteReports();
        this.getAllReportings();
        this.getBakelisteByCoach();
        this.getReportingByBakeliste();
        this.getAllBakeliste();
        this.amplitudeScript('0168d5bcb0d33437d15f2a6220fb054a');
      //  this.amplitudeScript('7a9faefb1bb6a856ed9b89eaaf1e8944');
        this.setState({
            allBakeliste: this.state.allBakeliste
        })

        if (this.state.userStatus === 'coach') {
            this.setState({
                isCoach: true,
                isAdmin: false,
                isBakeliste: false
            })
        } else if (this.state.userStatus === 'admin') {
            this.setState({
                isCoach: false,
                isAdmin: true,
                isBakeliste: false
            })
        } else if (this.state.userStatus === 'bakeliste') {
            this.setState({
                isCoach: false,
                isAdmin: false,
                isBakeliste: true
            })
        }
       
    }
    amplitudeScript = (api_key) => {
        (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
          ;r.type="text/javascript"
          ;r.integrity="sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta"
          ;r.crossOrigin="anonymous";r.async=true
          ;r.src="https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js"
          ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
          console.log("[Amplitude] Error: could not load SDK")}}
          ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
          ;function s(e,t){e.prototype[t]=function(){
          this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
          var o=function(){this._q=[];return this}
          ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
          ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
          ;return this}
          ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
          ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
          ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
          ;function v(e){function t(t){e[t]=function(){
          e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
          for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
          e=(!e||e.length===0?"$default_instance":e).toLowerCase()
          ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
          ;e.amplitude=n})(window,document);
    
          amplitude.getInstance().init(api_key);
      }
    handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'addSuccessAlert') {
                this.setState({
                    addReportingSuccessAlert: false
                });
            }
            if (label === 'updateSuccessAlert') {
                this.setState({
                    updateReportingSuccessAlert: false
                });
            }
            if(label === 'updateStatusSuccessAlert'){
                this.setState({
                    updateReportingStatusSuccessAlert: false
                })
            }
            if(label === 'addReportErrorAlert'){
                this.setState({
                    updateReportingSuccessAlert: false
                })
            }
            if(label === 'updateErrorAlert'){
                this.setState({
                    updateReportingError: false
                })
            }
            if(label === 'emptyLieu'){
                this.setState({
                    emptyLieu: false
                })
            }
            if(label === 'emptyTache'){
                this.setState({
                    emptyTache: false
                })
            }
            if(label === 'emptyDate'){
                this.setState({
                    emptyDate: false
                })
            }
            if(label === 'emptyStatus'){
                this.setState({
                    emptyStatus: false
                })
            }
        }, 5000);
    }

    getAllReportings = () => {
        axios.get(this.state.api + 'reporting_bakelistes')
            .then(res => {
                 let reportings = res.data.data;
              
                this.setState({
                    reportings: reportings
                })
                //console.log(this.state.reportings);
                //this.getBakelisteNotFillreporting(this.state.reportings);
               
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getBakelisteByCoach = () => {
        axios.get(this.state.api + `bakelistes-by-coach/${this.state.currentConnecteduserID}`)
            .then(res => {
                var data = res.data.data;
                if (data !== undefined) {
                    this.setState({
                        bakelistesByCoach: data,
                    });
                }

                //console.log(this.state.bakelistesByCoach, "lolo")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }


   getAllBakeliste = () => {
        axios.get(this.state.api + `liste-des-bakelistes-en-formation`)
            .then(res => {
                var data = res.data.data;
                if (data !== undefined) {
                    this.setState({  
                        allBakeliste: data,
                        listBakeliste:data
                    });
                }

                //console.log(this.state.allBakeliste, "lolo")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getReportingByBakeliste = () => {
        this.setState({
            isLoadingBakelisteData: true
        })
        axios.get(this.state.api + `reporting-by-bakeliste/${this.state.currentConnecteduserID}`)
            .then(res => {
                var data = res.data.data;
                console.log(data,"reporting")
                this.setState({
                    isLoadingBakelisteData: false
                })
                if (data !== undefined) {
                    this.setState({
                        reportingByBakeliste: data.reverse(),
                    });
                }
               // console.log(this.state.reportingByBakeliste, "lolo")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }

    getReportingInfos(reporting_id) {
        this.setState({
            reporting_id: reporting_id
        })
    }

    onUpdateReportingStatus = (e) => {
        e.preventDefault();
        this.setState({
            updateReportingStatusInProgress: true
        })
        var api = API.API;
        console.log(this.state.reporting_id)
        axios.post(api + 'update-reporting-status/' + this.state.reporting_id, this.state.fields)
            .then(response => {
                if(response.data.success === true){
                    this.getReportingByBakeliste();
                    $('.close').click();
                    
                    this.setState({
                        updateReportingStatusSuccessAlert: true,
                        updateReportingStatusInProgress: false
                    });

                    this.handleShowAndHideAlert('updateStatusSuccessAlert');
                }else{
                    console.log(response);
                    this.setState({
                        updateReportingStatusInProgress: false,
                        isUpdateStatusError: true
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    updateReportingStatusInProgress: false,
                    isUpdateStatusError: true
                });
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }


    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        console.log("handle change", e.target.value)
        fields["bakeliste_id"] = window.sessionStorage.getItem('user_id')
        fields["coach_id"] = window.sessionStorage.getItem('coach_id')
        this.setState({
            fields
        });
        console.log("handle change", this.state.fields)
    }
    
    onValidateAddReportingForm = () => {
        let fields = this.state.fields;
        let errors = {};
        let isValidForm = true;

        if (!fields["lieu"]) {
            isValidForm = false;
            this.setState({
                emptyLieu: true
            })
            this.handleShowAndHideAlert('emptyLieu');
            errors["emptyLieu"] = "*Ce champ est obligatoire";
        }
        if (!fields["tache"]) {
            isValidForm = false;
            this.setState({
                emptyTache: true
            })
            this.handleShowAndHideAlert('emptyTache');
            errors["emptyTache"] = "*Ce champ est obligatoire";
        }
        if (!fields["date"]) {
            isValidForm = false;
            this.setState({
                emptyDate: true
            })
            this.handleShowAndHideAlert('emptyDate');
            errors["emptyDate"] = "*Ce champ est obligatoire";
        }
        if (!fields["status"] || fields["status"] === '--Choisir un status--') {
            if(fields["status"] === '--Choisir un status--'){
                fields["status"] = ''
            }
            isValidForm = false;
            this.setState({
                emptyStatus: true
            })
            this.handleShowAndHideAlert('emptyStatus');
            errors["emptyStatus"] = "*Ce champ est obligatoire";
        }

        this.setState({
            errors: errors
        })

        return isValidForm;
    }

    addReporting() {
        console.log("report add", this.state.fields);
        if(this.onValidateAddReportingForm()){
            this.setState({
                reportingEncours: true
            })
            var eventProperties = {
                'user_email': this.state.email
               };
            const dataCoach = this.state.fields;
            console.log(dataCoach)
            console.log()
            axios.post(this.state.api + 'reporting_bakelistes', this.state.fields)
                .then(res => {
                    if (res.status === 200) {
                        this.getReportingByBakeliste();
                        $('.close').click();
                        this.handleShowAndHideAlert('addSuccessAlert');
                        amplitude.getInstance().logEvent('REPORTING_BAKELISTE_TYPE', eventProperties);
                        $('#add-reporting-form')[0].reset()
                        this.setState({
                            addReportingSuccessAlert: true,
                            reportingEncours: false,
                            fields: {}
                        });
                        
                        
                    }
                })
                .catch(error => {
                    this.setState({
                        reportingEncours: false,
                        addReportingError: true
                    });
                    this.handleShowAndHideAlert('addReportErrorAlert');
                    amplitude.getInstance().logEvent('Reporting_BAKELISTE_Error', eventProperties);
                    console.log(error);
                    if (process.env.NODE_ENV !== 'production') {
                        return;
                    }
                    Sentry.captureException(error);
                });
        }
        
    }



    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }


    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    getReportingInfosForUpdated = (reporting) => {
        this.setState({
            updateReportingID: reporting.id,
            dateValue: reporting.date,
            dateFRValue: reporting.date_fr,
            tacheValue: reporting.tache,
            updateDateValue: reporting.forUpdateDateFormat,
            statutValue: reporting.status,
            commentaireValue: reporting.commentaire,
            using_linksValue: reporting.using_links,
            lieuValue: reporting.lieu,
            appNameValue: reporting.app_name,
            technologyValue: reporting.technology,
            bakelisteIDValue: reporting.bakeliste_id,
            coachIDValue: reporting.coach_id,
            isUpdateStatusError: false
        })
        console.log(reporting)
    }

    updateHandleChange(e) {
        let fields = this.state.updateFields;
        fields[e.target.name] = e.target.value;
        if (e.target.name === 'tache') {
            this.setState({
                tacheValue: e.target.value
            })
        }
        if (e.target.name === 'technology') {
            this.setState({
                technologyValue: e.target.value
            })
        }
        if (e.target.name === 'using_links') {
            this.setState({
                using_linksValue: e.target.value
            })
        }
        if (e.target.name === 'app_name') {
            this.setState({
                appNameValue: e.target.value
            })
        }
        if (e.target.name === 'commentaire') {
            this.setState({
                commentaireValue: e.target.value
            })
        }
        if (e.target.name === 'status') {
            this.setState({
                statutValue: e.target.value
            })
        }
        if (e.target.name === 'lieu') {
            this.setState({
                lieuValue: e.target.value
            })
        }
        if (e.target.name === 'date') {
            this.setState({
                updateDateValue: e.target.value
            })
        }

        if (!fields["tache"]) {
            fields["tache"] = this.state.tacheValue;
        }
        if (!fields["technology"]) {
            fields["technology"] = this.state.technologyValue;
        }
        if (!fields["using_links"]) {
            fields["using_links"] = this.state.using_linksValue;
        }
        if (!fields["app_name"]) {
            fields["app_name"] = this.state.appNameValue;
        }
        if (!fields["commentaire"]) {
            fields["commentaire"] = this.state.commentaireValue;
        }
        if (!fields["status"]) {
            fields["status"] = this.state.statutValue;
        }
        if (!fields["lieu"]) {
            fields["lieu"] = this.state.lieuValue;
        }
        if (!fields["date"]) {
            fields["date"] = this.state.updateDateValue;
        }
        //fields["date"] = this.state.dateValue;
        fields["bakeliste_id"] = this.state.bakelisteIDValue;
        fields["coach_id"] = this.state.coachID;
        this.setState({
            updateFields: fields
        });

        console.log(fields);
    }

    onUpdateReportingRequest = (e) => {
        e.preventDefault();
        this.setState({
            updateReportingPending: true
        });
        let updateData = this.state.updateFields;
        axios.put(this.state.api + 'reporting_bakelistes/' + this.state.updateReportingID, updateData)
            .then(response => {
                // let reportingBakeliste = response.data.data;

                if(response.data.success === true){
                    this.getReportingByBakeliste();
                    $('.close').click();
                    this.setState({
                        updateReportingPending: false,
                        updateReportingSuccessAlert: true
                    });
                    this.handleShowAndHideAlert('updateSuccessAlert');
                }else{
                    this.setState({
                        updateReportingPending: false,
                        updateReportingError: true
                    });
                    this.handleShowAndHideAlert('updateErrorAlert');
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    updateReportingPending: false,
                    updateReportingError: true
                });
                this.handleShowAndHideAlert('updateErrorAlert');
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }
      
    statusFormatter = (cell) => {
        return ( 
            <span>
                {cell === 'A Faire' &&
                    <span className="label label-danger">{cell}</span>
                }
                {cell === 'En Cours' &&
                    <span className="label label-warning">{cell}</span>
                }
                {cell === 'Terminé' &&
                    <span className="label label-success">{cell}</span>
                }
            </span>
        );
    }
    nbjoursFormatter(lastReportingDays, lastReportingMonths){
        
        const tab1 =[2,3,4];
        const tab2 =[5,6,7,8];
        const tab3 =[9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
       
        if(lastReportingDays === 0 && lastReportingMonths > 0){
            return (
                <span className="badge badge-danger"> {lastReportingMonths} &nbsp;mois</span>
            )
        }
  
        if(lastReportingDays > 0 && lastReportingMonths > 0){
            return (
                <span className="badge badge-danger"> {lastReportingMonths} mois &nbsp;{lastReportingDays}&nbsp;j</span>
            )
        }
        
        if(lastReportingDays > 0 && lastReportingMonths === 0){
        
        for (const i of tab1) {
            if (i === lastReportingDays) {
                return (
                    <span className="badge badge-info"> {lastReportingDays} &nbsp;jours</span>
                )
            }  
        }

        for (const j of tab2) {
            if (j === lastReportingDays) {
                return (
                    <span className="badge badge-warning"> {lastReportingDays} &nbsp;jours</span>
                )
            }  
        }

        for (const k of tab3) {
            if (k === lastReportingDays) {
                return (
                    <span className="badge badge-danger"> {lastReportingDays} &nbsp;jours</span>
                )
            }  
        }
     }
        if (lastReportingDays ===1 && lastReportingMonths ===0) {
            return (
                <span className="badge badge-success"> {lastReportingDays} &nbsp;jour</span>
            )
        } 
     
    }
  

    bakelistesRepStatusFormatter = (cell, row) => {
        return (
            <div>
                {cell === 'A Faire' &&
                    <span className="label label-danger bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
                }
                {cell === 'En Cours' &&
                    <span className="label label-warning bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
                }
                {cell === 'Terminé' &&
                    <span className="label label-success bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
                }
            </div>
        );
    }

    commentFormatter = (cell, row) => {
        return (
            <div>
                {cell !== null &&
                    <p>{cell}</p>
                }
                {cell === null &&
                    <span className="label label-warning empty-comment">Aucun commentaire sur cette tâche</span>
                }
            </div>
        );
    }

    tacheFormatter = (cell, row) => {
        return(
            <span>
                <span className="tache-link" data-toggle="modal" data-target="#showReportingItemModal" onClick={() => this.getReportingInfosForUpdated(row)}> {cell} </span>
            </span>
        );
    }

    actionsFormatter = (cell, row) => {
        return (
            <div className="actions-btn-container">
                <button onClick={() => this.getReportingInfosForUpdated(row)} type="button" className="btn btn-warning bsi-action-update-btn" id="btn_update" data-toggle="modal" data-target="#updateReportingModal">
                    <i className="fas fa-edit"></i>
                </button>
                <button onClick={() => this.getReportingInfosForUpdated(row)} type="button" className="btn btn-info bsi-action-show-btn" id="btn_update" data-toggle="modal" data-target="#showReportingItemModal">
                    <i className="fas fa-eye"></i>
                </button>
            </div>
        );
    }

    filterList = (event) => {
        var updatedList = this.state.allBakeliste;
        
            updatedList = updatedList.filter((item) => {
                return item.first_name.toLowerCase().search(
                    event.target.value.toLowerCase()) !== -1 || item.last_name.toLowerCase().search(
                        event.target.value.toLowerCase()) !== -1 || item.email.search(
                            event.target.value.toLowerCase()) !== -1  ;              
            });
            this.setState({listBakeliste: updatedList });
    }
    customHeaderClass() {
        return 'custom-header-tache';
      }
      columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {

        return rowIdx % 2 === 0 ? 'td-column-function-even-example' : 'td-column-function-odd-example';
      }
      
  componentWillMount(){
    this.setState({
        listBakeliste: this.state.allBakeliste
    })
  }
  handleInputChange(event) {
     this.setState({
    importInProgress: true
    });
    const toto = new Date();
    const newLocal = this.state;
    newLocal.date = toto.getFullYear() + '-' + (toto.getMonth() + 1) + '-' + toto.getDate();
    
    const fields = this.state.fields;
     fields["date"]= this.state.date;
     fields["bakeliste_id"]=this.state.bakelister_id;
     fields["status"]=this.state.staatus;
     fields["lieu"]=this.state.lieeu;
     fields["app_name"]=this.state.app_namee;
     fields["technology"]=this.state.technologye;
     fields["coach_id"]=this.state.coachID;
     fields["using_links"]=this.state.using_links;
     fields["commentaire"]=this.state.commentaire;
     if (event.target.name === 'tache') {
      console.log(event.target.files)
      this.createImage(event.target.files[0]);
      fields["tache"] = event.target.files[0]
    } else {
      fields[event.target.name] = event.target.value;
    }
    this.setState({
      fields
    })
  
    console.log(fields);
    this.fileUploadHandler();
  }
  createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        file: e.target.result
      })
    };
    console.log(this.state.file)
    reader.readAsDataURL(file);
  }
  fileUploadHandler = () => {
    this.setState({
      importInProgress: true
    })
    const filer = new FormData();
    //  filer.append('date',this.state.fields.date);
      filer.append('tache', this.state.fields.tache);
      filer.append('coach_id', this.state.fields.coach_id);
      filer.append('bakeliste_id',this.state.fields.bakeliste_id);
      filer.append('status',this.state.fields.status);
      filer.append('lieu',this.state.fields.lieu);
      filer.append('app_name',this.state.fields.app_name);
      filer.append('technology',this.state.fields.technology);
      filer.append('using_links',this.state.fields.using_links);
      filer.append('commentaire',this.state.fields.commentaire);
    // let data = this.state.fields;
     console.log(this.state.fields,"data")
      //console.log(this.state.reporting_id,'id')
        axios.post(this.state.api +'reporting_import_file',filer)
          .then(response => {
            //console.log(response.status)
            if (response.status === 200) {
           $('.close').click();
              console.log('click')
              $('body').removeClass('modal-open');
              console.log('open')
              $('.modal-backdrop').removeClass('modal-backdrop');
              console.log('backdrop')
              $('.fade').removeClass('fade')
              $('.in').removeClass('in')
              // this.handleShowAndHideAlerte('successImport');
              this.getReportingByBakeliste();
              this.setState({
                importInProgress: false,
                successImportAlert: true,
              })
              setTimeout(() => {
                this.setState({
                  successImportAlert: false
                })
              }, 5000);
             
          }else{  
              //console.log(response);
              console.log('non ok')
              this.setState({
                importInProgress: false
              });
          }
      })
      .catch(error => {
          console.log(error);
          this.setState({
            importInProgress: false
          });
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
      
     //console.log("ok");
  }
  triggerClick = () => {
    this.fileInput.current.click();
  }

    render() {
        const {required} = this.props;
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton
        };
       
           
          
        // Pagination
      /*   const indexOfLast = this.state.currentPage * this.state.itemPerPage;
        const indexOfFirst = indexOfLast - this.state.itemPerPage;
        const current = this.state.allBakeliste.slice(indexOfFirst, indexOfLast);
        const pageNumbers = []; */
        /* for (let i = 1; i <= Math.ceil(this.state.allBakeliste.length / this.state.itemPerPage); i++) {
            pageNumbers.push(i);
        } */

       /*  const renderPageNumbers = pageNumbers.map(number => {
            return (
                <li key={number} id={number} onClick={this.handleClick}>
                    {number}
                </li>
            );
        }); */
        // End pagination
                                
        return (
            <div>
                <Menu />
                <div className="row">
                    <div className="component-Reportings">
                        <div className="col-lg-12">
                            <h1 className="page-header">
                                <span className="page-title">Reporting</span>
                                <div className="pull-right">
                                    <ol className="breadcrumb">
                                        <li><NavLink to="#">Accueil</NavLink></li>
                                        <li className="active">Reporting</li>
                                    </ol>
                                </div>
                            </h1>
                        </div>
                        {/* section navlink */}
                        {/* <section className="content"> */}
                  {/* Blocs contain bakelistes delay list and absence list */}
                   {/* <div className="row tab-data-row">
                    <div className="col-md-12 tab-data-col">
                      <div className="col-md-12 reporting-container">
                        <div className="list_prospect_container">
                          <div className="row prospect-row-row">
                            <div className="col-md-12 prospect-col">
                              <div className="nav-tabs-custom">
                                <div className="col-md-12 da-list-col">
                                  <ul className="nav nav-tabs">
                                   
                                    <li className=""><NavLink to="/reglement-interieur">RI</NavLink></li>
                                    <li className="active"><NavLink to="/reportings">Reporting</NavLink></li>
                                    <li className=""><NavLink to="/gestion-pointages">Pointage</NavLink></li>
                                    <li className=""><NavLink to="/gestion-retards-absences-bakelistes">Retard/Absence</NavLink></li>
                                    <li className=""><NavLink to="/gestion-tache">Tâches</NavLink></li>
     
      
                                    {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                                   {/* </ul>
                                </div>
                                
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
      
                      </div>
                    </div>  */} 
                    {/* </section>  */}
                         
                        {/*   < ListReportingFill48hours 
                            list1={this.state.listReportingFill48hours}
                          /> */}
                        {this.state.isBakeliste === true &&
                            <div className="row add_prospect_container">
                                <div className="col-md-6 col-md-offset-3 add-reporting-btn-container">
                                    {/* <a data-toggle="tooltip" data-placement="top" title="Ajouter un reporting" to="/#" className="btn btn-success add_coach_btn button button addBtn">
                                            <i className="fas fa-plus-circle"></i>
                                        </a> 
                                    */}

                                    <button type="button" className="btn btn-success add_reporting_btn button addBtn" id="btn_report" data-toggle="modal" data-target="#myModal" >
                                        <i className="fas fa-plus-circle"></i>
                                        </button>&nbsp;&nbsp;
                                    <button type="button" className="btn btn-success liste_prospect button" data-toggle="modal" data-target="#importCSVModal" data-placement="top" title="importer reporting" style={{backgroundColor:"rgb(255,184,79)",top:'-2px'}}>
                                    <i class="fas fa-paperclip"></i>
                                    </button>
                                </div>
                            </div>
                           
                        }

                        <div className="bakeliste_container">
                            {this.state.isCoach === true &&
                                <div className="row">
                                    <div className="col-md-3" id="list_item">
                                        <ul className="nav nav-tabs tabs-left" >
                                            {this.state.bakelistesByCoach.map((x, y) =>
                                                y === 0 ?
                                                    <li className="active" key={x.id} onClick={(y) => this.fadeOutBakelisteReports(x.id)}><a href={'#' + y} data-toggle="tab">{x.first_name} {x.last_name}</a></li> 
                                                    :
                                                    <li  key={x.id} onClick={(y) => this.fadeOutBakelisteReports(x.id)}><a href={'#' + y} data-toggle="tab" onClick={(y) => this.fadeOutBakelisteReports(x.id)}>{x.first_name} {x.last_name}</a></li>
                                                   
                                            )}
                                        </ul>
                                    </div>
                                    <div className="col-md-9" id="item_content">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="key={y}">
                                                <div className="table_container">
                                                    <BootstrapTable data={this.state.bakelistReportsTab}
                                                        striped={true} hover={true} condensed={true}
                                                        multiColumnSort={2} options={options}
                                                        pagination
                                                        search={true}>
                                                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='frenchDateFormat' dataSort={true}>Date</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='tache' dataFormat={this.tacheFormatter.bind(this)}>Tache</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='commentaire' dataFormat={this.commentFormatter.bind(this)}>Commentaire</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='status' dataFormat={this.statusFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>  
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.isAdmin === true &&

                                <div className="row">
                                    <div className="col-md-3" id="list_item">
                                    <input className="form-control filter-input" type="text" placeholder="Search" onChange={this.filterList} />
                                      
                                        <ul className="nav nav-tabs tabs-left" >
                                            {this.state.listBakeliste.map((x, y) => 
                                                y === 0 ?
                                            <li className="active"  key={x.id} onClick={(y) => this.fadeOutBakelisteReports(x.id)}><a href={'#' + y} data-toggle="tab">{x.first_name} {x.last_name}{this.nbjoursFormatter(x.lastReportingDays,x.lastReportingMonths)}</a> </li>
                                                :
                                                <li  key={x.id} onClick={(y) => this.fadeOutBakelisteReports(x.id)}><a href={'#' + y} data-toggle="tab" onClick={(y) => this.fadeOutBakelisteReports(x.id)}>{x.first_name} {x.last_name} {this.nbjoursFormatter(x.lastReportingDays,x.lastReportingMonths)}</a></li>        
                                            )}
                                        </ul>
                                       {/*  <ul id="page-numbers" className="pagination">
                                            {renderPageNumbers}
                                        </ul> */}
                                    </div>
                                    <div className="col-md-9" id="item_content">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="key={y}">
                                                <div className="table_container">
                                                    <BootstrapTable data={this.state.bakelistReportsTab}
                                                        striped={true} hover={true} condensed={true}
                                                        multiColumnSort={2} options={options}
                                                        pagination
                                                        search={true}>
                                                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='frenchDateFormat' dataSort={true}>Date</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='tache' dataFormat={this.tacheFormatter.bind(this)}>Tache</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='commentaire' dataFormat={this.commentFormatter.bind(this)}>Commentaire</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='status' dataFormat={this.statusFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>  
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            }
                            
                            {this.state.isBakeliste === true ?
                                this.state.isLoadingBakelisteData ?
                                    <div className="table_container">
                                        <table className="table table-bordered table-responsive">
                                            <thead>
                                                <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Tâche</th>
                                                <th scope="col">Commentaires</th>
                                                <th scope="col">Statut</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <div className="loader-container-reporting">
                                                    <div id="loader"></div>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                :
                                <div className="col-md-12" id="item_content">
                                <div className="tab-content">
                                    <div className="tab-pane active" id="key={y}">
                                        <div className="table_container">
                                <BootstrapTable data={this.state.reportingByBakeliste}
                                    striped={true} hover={true} condensed={true}
                                    multiColumnSort={2} options={options}
                                    pagination
                                    search={true}>
                                    <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField='date_fr' width='120' dataSort={true}>Date</TableHeaderColumn>
                                    <TableHeaderColumn dataField='tache' width='250'dataFormat={this.tacheFormatter.bind(this)}
                                     columnClassName={ this.columnClassNameFormat }>Tache</TableHeaderColumn>
                                    <TableHeaderColumn dataField='commentaire' dataFormat={this.commentFormatter.bind(this)}>Commentaire</TableHeaderColumn>
                                    <TableHeaderColumn dataField='status' width='90' dataFormat={this.bakelistesRepStatusFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>
                                    <TableHeaderColumn dataField='id' width='140' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                                </BootstrapTable>
                                </div>
                                </div>
                                </div>
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>

                    {/* Modal pour ajouter un reporting  */}
                    <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title" id="myModalLabel">Ajouter un reporting </h4>
                                </div>
                                <form className="add-reporting-form">
                                <div className="modal-body">
                                    <div className="alerty-row row">
                                        <div className="col-md-12">
                                            <div className="alert alert-warning alerty-rep-textuel">
                                                Les champs contenant des astérix <sup className="required-field">(*)</sup> sont obligatoire.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group add-reporting-form-group" >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Nom du projet ou de l'application </label>
                                                <input type="text" className="form-control" id="inputAppName" name="app_name" placeholder="App name" onChange={this.handleChange} required={true} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Technologies / Outils </label>
                                                <input type="text" className="form-control" id="inputTech" name="technology" placeholder="Technologie utilisée" onChange={this.handleChange} required={required} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Lien utilisé </label>
                                                <input type="text" className="form-control" id="inputLink" name="using_links" placeholder="liens utilisés" onChange={this.handleChange} required={required} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Lieu de réalisation <i className="marky">(Bakeli ou A Domicile)</i> <sup className="required-field">*</sup> </label>
                                                <input type="text" className="form-control" id="inputLieux" name="lieu" placeholder="Lieux" onChange={this.handleChange} required={required} />
                                                {this.state.emptyLieu &&
                                                    <div className="errorMsg">{this.state.errors.emptyLieu}</div>
                                                }
                                            </div>
                                        </div>
                                        
                                        <div className="row ar-rowy">
                                            <div className="col-md-12">
                                                <div className="form-group ">
                                                    <label>Commentaires </label>
                                                <   textarea className="form-control" rows="5" id="appComment" name="commentaire" placeholder="Votre commentaire" onClick={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group " >
                                                    <label>Statut de la tâche <sup className="required-field">*</sup> </label>
                                                    <select className="form-control" id="inputStatus_Tache" name="status" onClick={this.handleChange} required={required}>
                                                        
                                                        <option value="A Faire">A Faire</option>
                                                        <option value="En Cours">En Cours</option>
                                                        <option value="Terminé">Terminé</option>
                                                    </select>
                                                    {this.state.emptyStatus &&
                                                        <div className="errorMsg">{this.state.errors.emptyStatus}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group ">
                                                    <label>Contenu de la tâche <sup className="required-field">*</sup> </label>
                                                    <textarea className="form-control" rows="3" id="tache" name="tache" placeholder="Tache à effectuer" onChange={this.handleChange} required={required}></textarea>
                                                    {this.state.emptyTache &&
                                                        <div className="errorMsg">{this.state.errors.emptyTache}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Date <sup className="required-field">*</sup> </label>
                                                    <input type="date" id="dateInput" name="date" className="form-control" onChange={this.handleChange} required={required} />
                                                    {this.state.emptyDate &&
                                                        <div className="errorMsg">{this.state.errors.emptyDate}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.addReportingError &&
                                        <div className="alert alert-danger addReporting-error">
                                            Une erreur est survenu lors de l'ajout de votre reporting. Veuillez réessayer plus tard.
                                        </div>
                                    }
                                    
                                </div>
                                </form>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger pull-left cancel_btn cancel button" data-dismiss="modal">
                                        <i className="fas fa-times-circle"></i> &nbsp;
                                        Quitter
                                    </button>
                                    {!this.state.reportingEncours &&
                                    <Amplitude>
                                    {({ logEvent }) =>
                                        <button type="button" className="btn btn-primary bsi-submit-btn" onClick={(e) => this.addReporting(e)}>
                                            <i className="fas fa-plus"></i> &nbsp;
                                            Ajouter
                                        </button>
                                        }
                                        </Amplitude>
                                    }
                                    {this.state.reportingEncours &&
                                        <button type="button" className="btn btn-warning bsi-inprogress-btn">
                                            Ajout en cours &nbsp;
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="statusModal" tabIndex="-1" role="dialog" aria-labelledby="statusModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title" id="statusModalLabel">Modifier le statut </h4>
                                </div>
                                <div className="modal-body">
                                    <div className="row update-status-row">
                                        <form>
                                            <div className="form-group " >
                                                <select className="form-control" id="inputStatus_Reporting" name="status" onClick={this.handleChange}>
                                                    <option>--Choisir un status--</option>
                                                    <option value="A Faire">A Faire</option>
                                                    <option value="En Cours">En Cours</option>
                                                    <option value="Terminé">Terminé</option>
                                                </select>
                                            </div>

                                            <div className="row">
                                                <div className="form-actions-btn col-md-12">
                                                    <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                        <i className="fas fa-times-circle"></i> &nbsp;
                                                        Quitter
                                                        </button>
                                                    {!this.state.updateReportingStatusInProgress &&
                                                        <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={this.onUpdateReportingStatus}>
                                                            <i className="fas fa-edit"></i>&nbsp;
                                                            Modifier
                                                            </button>
                                                    }
                                                    {this.state.updateReportingStatusInProgress &&
                                                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                            Modification en cours &nbsp;
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    {this.state.isUpdateStatusError && 
                                        <div className="alert alert-danger update-status-error text-center">
                                            Un problème est survenu lors de la mise à jour du statut. Veuillez réessayer plus tard.
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal pour modifier un reporting  */}
                    <div className="modal fade" id="updateReportingModal" tabIndex="-1" role="dialog" aria-labelledby="updateReportingModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title" id="updateReportingModalLabel">Modifier un reporting </h4>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group add-reporting-form-group" >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Date </label>
                                                    <input type="date" name="date" className="form-control" onChange={this.updateHandleChange} value={this.state.updateDateValue} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Nom du projet ou de l'application </label>
                                                <input type="text" className="form-control" id="appName" name="app_name" placeholder="App name" onChange={this.updateHandleChange} value={this.state.appNameValue} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Technologies / Outils </label>
                                                <input type="text" className="form-control" id="appTech" name="technology" placeholder="Technologie utilisée" onChange={this.updateHandleChange} value={this.state.technologyValue} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Lien utilisé </label>
                                                <input type="text" className="form-control" id="appLink" name="using_links" placeholder="liens utilisés" onChange={this.updateHandleChange} value={this.state.using_linksValue} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Lieu de réalisation (Bakeli / A domicile) </label>
                                                <input type="text" className="form-control" id="appLieu" name="lieu" placeholder="Lieux" onChange={this.updateHandleChange} value={this.state.lieuValue} />
                                            </div>
                                        </div>
                                        
                                        <div className="row ar-rowy">
                                            <div className="col-md-12">
                                                <div className="form-group ">
                                                    <label>Commentaires </label>
                                                    <textarea className="form-control" rows="5" id="appComment" name="commentaire" placeholder="Votre commentaire" onChange={this.updateHandleChange} value={this.state.commentaireValue} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group " >
                                                    <label>Statut de la tâche </label>
                                                    <select className="form-control" id="appStatut" name="status" onChange={this.updateHandleChange} value={this.state.statutValue}>
                                                        <option>--Choisir un status--</option>
                                                        <option value={"A Faire"}>A Faire</option>
                                                        <option value={"En Cours"}>En Cours</option>
                                                        <option value={"Terminé"}>Terminé</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group ">
                                                    <label>Contenu de la tâche</label>
                                                    <textarea className="form-control" rows="3" id="appTache" name="tache" placeholder="Tâche à effectuer" onChange={this.updateHandleChange} value={this.state.tacheValue} />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        {this.state.updateReportingError && 
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <div className="alert alert-danger text-center">
                                                    Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger pull-left cancel_btn cancel button" data-dismiss="modal">
                                        <i className="fas fa-times-circle"></i> &nbsp;
                                        Quitter
                                    </button>
                                    {!this.state.updateReportingPending &&
                                        <button type="button" className="btn btn-primary bsi-submit-btn" onClick={(e) => this.onUpdateReportingRequest(e)}>
                                            <i className="fas fa-edit"></i> &nbsp;
                                            Modifier
                                        </button>
                                    }
                                    {this.state.updateReportingPending &&
                                        <button type="button" className="btn btn-warning bsi-inprogress-btn">
                                            Modification en cours &nbsp;
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* Modal pour visualiser un reporting  */}
                    <div className="modal fade" id="showReportingItemModal" tabIndex="-1" role="dialog" aria-labelledby="showReportingItemModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <h4 className="modal-title" id="showReportingItemModalLabel">Visualiser un reporting </h4>
                                </div>
                                <div className="modal-body">
                                    <div className="reporting_content">
                                        <div className="date_container">
                                            <span className="item_label">Date : </span> <span className="label_content">{this.state.dateFRValue}</span>
                                        </div> <hr />
                                        <div className="tache_container">
                                            <span className="item_label">Tâche : </span> &nbsp;
                                            {this.state.statutValue === 'A Faire' &&
                                                <span className="label label-danger todoStatus" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(this.state.updateReportingID)}> {this.state.statutValue} </span>
                                            }
                                            {this.state.statutValue === 'En Cours' &&
                                                <span className="label label-warning doingStatus" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(this.state.updateReportingID)}> {this.state.statutValue} </span>
                                            }
                                            {this.state.statutValue === 'Terminé' &&
                                                <span className="label label-success doneStatus" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(this.state.updateReportingID)}> {this.state.statutValue} </span>
                                            }
                                            <br />
                                            <p className="label_content">{this.state.tacheValue}</p>
                                        </div> <hr />
                                        <div className="commentaire_container">
                                            <span className="item_label">Commentaire : </span> <br />
                                            {this.state.commentaireValue !== '' &&
                                                <p className="label_content">{this.state.commentaireValue}</p>
                                            }
                                            {this.state.commentaireValue === '' &&
                                                <span className="alert alert-warning label-content">Aucun commentaire sur cette tâche</span>
                                            }
                                        </div> <hr />
                                        <div className="lieu_container">
                                            <span className="item_label">Lieu de réalisation : </span> <br />
                                            <p className="label_content">{this.state.lieuValue}</p>
                                        </div> <hr />
                                        <div className="usingLinks_container">
                                            <span className="item_label">Lien utilisé : </span> <br />
                                            <p className="label_content">
                                                {this.state.using_linksValue !== '' &&
                                                    <a href={this.state.using_linksValue} target="_blank" className="usingLinks" rel="noopener noreferrer">Lien à cliquer</a>
                                                }
                                                {this.state.using_linksValue === '' &&
                                                    <span className="empty_link label label-warning">Aucun lien utilisé</span>
                                                }
                                            </p>
                                        </div> <hr />
                                        <div className="technology_container">
                                            <span className="item_label">Technologie / Framework : </span> <br />
                                            <p className="label_content">{this.state.technologyValue}</p>
                                        </div> <hr />
                                        <div className="appName_container">
                                            <span className="item_label">Nom de l'application : </span> <br />
                                            <p className="label_content">{this.state.appNameValue}</p>
                                        </div> <hr />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
                {this.state.addReportingSuccessAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        Reporting ajouté avec succès.
                    </div>
                }

                {this.state.updateReportingSuccessAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        Reporting modifié avec succès.
                    </div>
                }

                {this.state.updateReportingStatusSuccessAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        Statut du reporting modifié avec succès.
                    </div>
                }
             {/* Import CSV Modal  */}
        <div className="modal fade" id="importCSVModal" tabindex="-1" role="dialog" aria-labelledby="importCSVLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="importCSVLabel">Import de fichier vers le Reporting</h4>
              </div>
              <div className="modal-body">
                <div className="row import-input-row">
                  <div className="import-input col-md-8 col-md-offset-2">
                 {/*
                    <CSVReader
                      onFileLoaded={this.handleReadCSVForBakeliSiUser}
                      inputRef={this.fileInput}
                      style={{ display: 'none' }}
                      onError={this.handleOnError}
                    /> */
                 }
                    <input id="myInput" type="file" ref={(ref) => this.myInput = ref} style={{ display: 'none' }}
                          onChange={this.handleInputChange}  name="tache"
                          />
                       
                    <button 
                     onClick={(e) => this.myInput.click() }
                     onChange={this.fileUploadHandler}  >Chargez le fichier </button>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12 actions-col">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                  {this.state.importInProgress &&
                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                      Import en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Successfull import toast */}
        {this.state.successImportAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
            Les données ont été importées avec succès.
        </div>
        }

            </div>
        )
    }
}
export default Reportings
