
import React, {Component} from 'react';
import './register.scss'
import axios from 'axios';
import API from '../../variablesGlobales';
import Helmet from 'react-helmet';
import {Form, Input} from 'semantic-ui-react-form-validator';
import {Button , Divider, Header, Icon, Grid, Segment,Message} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import {
  Amplitude
} from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
export default class register extends Component {
    constructor(props) {
         super(props);
        this.state = {
          api: API.API,
          fields:{},
          isPending: false,
          errorConfirmPassword:false,
          errors:{},
          successRegister: false,
          loading: true,
          existe_user: false,
          error_existe_user:'' 
       };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    
    componentDidMount(){
      
      let x = document.getElementById('page-wrapper');
      x.style.marginLeft = 0;
      this.amplitudeScript('0168d5bcb0d33437d15f2a6220fb054a');
      //this.amplitudeScript('7a9faefb1bb6a856ed9b89eaaf1e8944');
    }

    amplitudeScript = (api_key) => {
      (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
        ;r.type="text/javascript"
        ;r.integrity="sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta"
        ;r.crossOrigin="anonymous";r.async=true
        ;r.src="https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js"
        ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
        console.log("[Amplitude] Error: could not load SDK")}}
        ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
        ;function s(e,t){e.prototype[t]=function(){
        this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
        var o=function(){this._q=[];return this}
        ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
        ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
        ;return this}
        ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
        ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
        ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
        ;function v(e){function t(t){e[t]=function(){
        e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
        for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
        e=(!e||e.length===0?"$default_instance":e).toLowerCase()
        ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
        ;e.amplitude=n})(window,document);
  
        amplitude.getInstance().init(api_key);
    }
    
    handleChange = (event) => {
  
        let fields = this.state.fields;
        fields[event.target.name] = event.target.value ;
        fields["status"] = 'nouveau' ;
        fields["user_fullname"] = this.state.fields.first_name+ ' '+this.state.fields.last_name ;

        this.setState({
           fields:fields
          
          },()=> {console.log(fields)}
          
          );
    
  }

 
  handleShowAndHideAlert(input){
     setTimeout( () =>{
       if (input === 'confirmPassword') {
        this.setState({
          errorConfirmPassword: false
      });
       }
     },5000);
  }


handleSubmit(e){
  e.preventDefault();
  let password = this.state.fields.password;
  let confirm_pwd = this.state.fields.confirmPassword;
   let errors = {}
   if(password === confirm_pwd){
        this.setState({
          loading: false,
          isPending: true
        });
        
        let data = this.state.fields;
        delete data.password_confirmation
          axios.post(this.state.api + 'user/bakeliste-subscribe', data)
            .then(response => {
              this.setState({
                isPending: false
              });
              var eventProperties = {
                'user_email': this.state.email
               };
                if (response.status === 201) {
                    this.setState({ successRegister:true });
                    console.log(response.data);
                    amplitude.getInstance().logEvent('Insription_BakelisteTYPE', eventProperties);
                  } else if(response.data.success === false && response.data.exist === true){
                    this.setState({
                      loading: true,
                      error_existe_user:'Veuillez utiliser un autre compte, l\'email existe déjà',
                      existe_user:true
                  });
                  }
            })
      }else{
        
        errors["confirmPassword"] = "Les deux mots de passe ne sont pas authentique";

        this.setState({
          errors:errors,
          errorConfirmPassword: true,
          
        });
        Sentry.captureException(errors);
      }
  
  
}

    render() {
    
      return    <div className="Connexion" style={{ backgroundImage: 'url()' }}>
      <Helmet>
      <title>Bakeli App | Register</title>
        <link rel="stylesheet" href="/vendor/bootstrap/css/bootstrap.min.css" />
      </Helmet>
      <div className="app_owner_logo_container">
        <img className="img-responsive app_owner_logo" src={require('../../images/logo_bak.png')} alt="logo bakeli" />
      </div>
      <div className="page-holder d-flex align-items-center register-form-container">
        <div className="container">
          <div className="row align-items-center py-5">
            {/* <div className="col-5 col-lg-7 mx-auto mb-5 mb-lg-0">
              <div className="pr-lg-5"><img src={require('../../images/undraw_youtube_tutorial_2gn3.svg')} alt="tutorial" className="ytb_tu" /></div>
            </div> */}
            <div className="col-lg-5 px-lg-4 offset-lg-4">
             {/* <div></div><div></div> */}
             <Divider horizontal width={4}>
                <Header as='h1' color='teal'>
                  <Icon name='signup' size='huge' />
                  BAKELI SI INSCRIPTION
                </Header>
              </Divider>
              {this.state.successRegister &&
                <Message color="teal">
                <Message.Header>Bravo !
                  {this.state.fields.first_name} {this.state.fields.last_name }
                </Message.Header>
                   <p>Votre compte a été bien créé avec success :</p>
                   <p>Vous pouvez utiliser le lien ci-dessous pour se connecter</p>
              </Message>
               }
              {this.state.successRegister &&
              
                <Message size='huge' color="teal">
                   <NavLink to="/" className="return-login">
                 &nbsp;
                  <Icon name='reply' size='huge' />
                    login
                </NavLink>
                </Message>
              }
              
             {!this.state.successRegister &&
              <Segment id="segment-form">
              <Form color='teal' size='large' id="register-form" ref="form"  onSubmit={this.handleSubmit}  >
               <Input type="text" size='large'  icon='user'  width={15}   iconPosition='left'
                placeholder=' Prénom'  onChange={this.handleChange}  name="first_name"
                validators={['required']} value={this.state.fields.first_name} 
                errorMessages={['* Ce champ est obligatoire']} 
               />
               <br/>
                <Input size='large' icon='user' width={15} iconPosition='left' placeholder='Nom'
                onChange={this.handleChange}  name="last_name"  value={this.state.fields.last_name}
                validators={['required']}   errorMessages={['* Ce champ est obligatoire']}  
               />
                <br/>
            <Input size='large' width={15}  icon='call'  iconPosition='left'  placeholder=' Numéro de téléphone '
               type="number"  onChange={this.handleChange}  name="phone"  value={this.state.fields.phone}
                validators={['required']}  errorMessages={['* Ce champ est obligatoire']}
                />
               <br/>
            <Input  size='large' width={15} icon='mail' name="email" iconPosition='left' placeholder='Email'
                type="email" onChange={this.handleChange} value={this.state.fields.email}
                validators={['required']}  errorMessages={['* Ce champ est obligatoire']}
               />
              {this.state.existe_user &&
                <div class="error field">
                    <div className="ui pointing below prompt label" role="alert"
                      id="form-input-first-name-error-message" aria-atomic="true" >
                      <h5>{this.state.error_existe_user}</h5>
                  </div>
                </div>
              }

                   <br/>   
            <Input  size='large'  width={15} icon='lock' iconPosition='left'  name="password"
              placeholder='Mot de passe'  type="password" onChange={this.handleChange}
              value={this.state.fields.password}  validators={['required']} 
               errorMessages={['* Ce champ est obligatoire']} 
                />
              <br/>
            <Input  size='large' icon='lock' width={15} iconPosition='left' type="password"
               placeholder='Confirmer votre mot de passe'  name='confirmPassword'
               onChange={this.handleChange} value={this.state.fields.confirmPassword}
                validators={['required']}  errorMessages={['* Ce champ est obligatoire']}  
              />
              {this.state.errorConfirmPassword &&
                 <div class="error field">
                    <div className="ui pointing below prompt label" role="alert"
                      id="form-input-first-name-error-message" aria-atomic="true"
                     >
                      {this.state.errors.confirmPassword}
                     </div>
                  </div>
              }
                <Grid.Column verticalAlign='middle'>
                  {!this.state.isPending &&
                  <Amplitude>
                  {({ logEvent }) =>
                  <Button color='teal' content='Sign up' 
                   icon='signup' size='huge'
                   id='btn-register'
                   fluid
                   /> 
                  }
                  </Amplitude>

                  }
                  
                 {this.state.isPending && 
                 <Button color='teal' size='huge' id='btn-register'>
                 <Icon loading name='spinner' />
                  inscription en cours
                 </Button>
                  } 
                </Grid.Column>
              </Form>
          </Segment>
           }
            </div>
          </div>
          <p className="mt-5 mb-0 text-gray-400 text-center sign_footer">By <a href="https://bakeli.tech" className="external text-gray-400" target="_blank" rel="noopener noreferrer">Bakeli - Learn to create</a></p>
        </div>
      </div>
        
    </div>;
    }
  }
