import React, {Component} from 'react';
import './listMessages.scss'
// import { Link} from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import Menu from './../menu/Menu';
import { Item,Header,Icon} from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
// import $ from 'jquery';
export default class ListeMessage extends Component {
    constructor(props) {
      super(props);
        this.state = {
          api: API.API,
          messages:[],
          mssagebyuser:[],
          first_name:'',
          last_name:'',
          loading:true
        };
     }

    componentDidMount() {
     
      this.getListAllMessage();
      this.getMessageByUser();

  }

// get all message
getListAllMessage = () => {
  axios.get(this.state.api + 'messages')
      .then(res => {
         // console.log('Alls', res.data.data)
          let data = res.data.data
             const tab =[];
               for (const key in data) {
                 if (data.hasOwnProperty(key)) {
                   const element = data[key];
                   tab.push(element);
                }
               }
              this.setState({
                  messages: tab,
                  loading: false
              });
      })
      .catch(error => {
          this.setState({
              loading: false,
          })
          console.log(error.message)
      })
}

// get all message by user
getMessageByUser = (email, first_name, last_name) => {
  axios.get(this.state.api + `messages/${email}`)
      .then(res => {
          //console.log('Alls', res.data.data)
          if (res.data.success === true) {
              this.setState({
                  mssagebyuser: res.data.data.reverse(),
                  first_name:first_name,
                  last_name:last_name
              });
              console.log(this.state.mssagebyuser)
          } else {
             
              console.log(res)
          }
      })
      .catch(error => {
         
          console.log(error.message)
          
      })
}


getFirstLetter(string1,string2){

  return string1.charAt(0).toUpperCase()
     +string2.charAt(0).toUpperCase();
  }
    render() {
      return (
        <div className="component">
          <Menu />


             
              <div className="row messages">
                  <div className="col-sm-12 mssage-nav">
                      <div className="col-sm-4 nav">
                          <nav class="navbar navbar-light bg-light message-left" >
                            <Header as='h3' style={{padding: '14px'}}>
                              <Icon name='users' size='huge' />
                                  Utilisateurs
                           </Header>
                          </nav>
                      </div>
                      <div className="col-sm-8 nav-right">
                          <nav class="navbar fixed-top navbar-light bg-light message-right">
                             {this.state.first_name === '' ?
                               <Header as='h3'>
                               <Icon name='user' size='huge' />
                                   User profil
                             </Header> 
                             :
                             <div className="mb-3 mx-auto">
                                <h5 className="rounded-circle" >
                                <i className='far fa-user-circle' 
                                  style={{fontSize:'25px', color:'#383131'}}></i>
                                 <span className="user-messages">{this.state.first_name+' '+this.state.last_name}</span>
                                </h5>
                              </div>
                            }
                          </nav>
                        </div>
                    </div>
                  <div className="col-sm-4 scrollpy-ms" data-spy="scroll" data-offset="0" >
                  <div className="row sideBar">
                    {this.state.messages.map( (item, key) =>
                      <div className="row sideBar-body"  key={item.id}  onClick={(key) => this.getMessageByUser(item.email,item.first_name,item.last_name)} >
                        <div className="col-sm-3 col-xs-3 sideBar-avatar">
                          <div className="avatar-icon">
                            {/* <img src={require('./../../images/wagaan_head.png')} alt="Avatar" className="bp_avatar_img-user" /> */}
                            <div className="mb-3 mx-auto reseaux">
                              <h3 className="rounded-circle" >{this.getFirstLetter(item.first_name,item.last_name)}</h3>
                          </div>
                          </div>
                        </div>
                        <div className="col-sm-9 col-xs-9 sideBar-main">
                          <div className="row">
                            <div className="col-sm-8 col-xs-8 sideBar-name">
                              <span className="name-meta"> 
                              {item.first_name.charAt(0).toUpperCase()+ 
                               item.first_name.slice(1)}
                              &nbsp;&nbsp;{item.last_name}
                            </span>
                            </div>
                            <div className="col-sm-4 col-xs-4 pull-right sideBar-time"  />
                              <span className="time-meta pull-right">
                                  <TimeAgo
                                    datetime={item.created_at}
                                    locale='vi'
                                  />
                            </span>
                            </div>
                          </div>
                        </div>
                        )}
                      </div>

                  </div>
                  <div className="col-sm-8">
                  <Item.Group style={{paddingTop: '63px'}}>
                    {this.state.mssagebyuser.map(item =>(
                      <Item className='item-liste-message'>
                      <Item.Content>
                        <Item.Description>
                          <p className='listMessanger'>{item.contenu}</p>
                          <span className="time-meta pull-right">
                                  <TimeAgo
                                    datetime={item.created_at}
                                    locale='vi'
                                  />
                            </span>
                        </Item.Description>
                      </Item.Content>
                    </Item>
                    ))}
                  </Item.Group> 

                  </div>
            </div>

          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
          {/* <div className="row no-content-wrapper"> */}

            {/* <section className="content-header">
              <h1>
                Les messages
              </h1>
              <ol className="breadcrumb">
                <li><Link to="/dashboard-admin">Accueil</Link></li>
                <li className="active">listMessages</li>
              </ol>
            </section>
            <Item.Group className='item-group-message'>
              {this.state.messages.map(item =>(
                <Item className='item-liste-message'>
                <Item.Image size='small' src={require('./../../images/wagaan_head.png')} alt="Avatar" className="bp_avatar_img-user" />
                <Item.Content>
                  <Item.Header as='a' id='header-list-message'>
                    {item.first_name}&nbsp;&nbsp;{item.last_name}</Item.Header>
                    <p className='email-message'>
                    <small>
                         <TimeAgo
                             datetime={item.created_at}
                              locale='vi'
                              />
                      </small>
                    </p>
                  <Item.Description>
                    <p className='listMessanger'>{item.contenu}</p>
                  </Item.Description>
                </Item.Content>
              </Item>
              ))
              
              }
             
            </Item.Group> */}
           


            </div>
      );
    
    }
  }
