import React, { Component } from 'react';
import './listeCoach.css';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from "../menu/Menu";
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import API from '../../variablesGlobales';
import $ from 'jquery';

class ListCoachs extends Component {

    constructor(props) {
        super(props);
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');

        if (!token) {
            window.history.back()
        }
        if (userStatus === 'bakeliste' || userStatus === 'coach') {
            window.history.back();
        }

        this.updateCoachStatus = this.updateCoachStatus.bind(this);
        this.updateCoachStatusHandleChange = this.updateCoachStatusHandleChange.bind(this);
        this.updateHandleChange = this.updateHandleChange.bind(this);
        this.updatedCoachData = this.updatedCoachData.bind(this);


        this.state = {
            coach: [],
            domaines: [],
            bakelistCoach: [],
            loading: true,
            api: API.API,
            coachID: 0,
            coachFullname: '',
            coachDomaine: '',
            coachDomaineID: 0,
            coachStatus: '',
            coachNbreBakelistes: '',
            updatedCoachStatusInProgress: false,
            updatedError: false,
            updateFields: {},
            updateCoach: [],
            is_archived: 0,
            updateCoachInfosInProgress: false,
            updateCoachInfosSuccessAlert: false,
            updateCoachInfosRequestError: false,

        }

    }
    componentDidMount() {
        this.getListCoach();
        this.getAllDomaines();
    }
    

    archivedCoach(id) {
        axios.post(this.state.api + "archive-user/" +id)
        .then(res => {
          console.log("archiver", res)
          this.getListCoach();
        })

        .catch((err) => {
          console.log(err);
        })
    }
    getListCoach = () => {
        axios.get(this.state.api + 'liste-des-coachs')
        .then(res => {
            //console.log('coach', res.data.data)
            this.setState({
                coach: res.data.data,
                loading: false
            });
            // console.log("j ves" + res)
            // console.log(this.state.coach)
        }) 
    }

    getAllDomaines = () => {
        axios.get(this.state.api + 'domaines')
        .then(res => {
            const domains = res.data;
            this.setState({
                domaines: domains.data,
            });
            console.log(this.state.domaines)
        })
    }

    renderNam(id) {
        this.state.bakelistCoach.forEach((z) => {
            console.log("function is called" + z[2]);
            if (z.id === id) {
                this.setState({
                    nameBakelist: z.name
                })
            }
        })
        return (
            <td><Link to="#">{this.state.nameDomain} </Link></td>
        )
    }

    renderName(id) {
        this.state.domaines.forEach((x) => {
            if (x.id === id) {
                this.setState({
                    nameDomain: x.name
                })
            }
        })
        return (
            <td><Link to="#">{this.state.nameDomain} </Link></td>
        )
    }
    renderNames(id) {
        this.state.bakelistCoach.forEach((z) => {
            if (z.id === id) {
                this.setState({
                    nameCoache: z.name
                })
            }
        })
    }

    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }


    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    getCoachID = (coach) => {
        window.sessionStorage.setItem('lcoach_id', coach.id);
        console.log(window.sessionStorage.getItem('lcoach_id'));
    }

    statusFormatter(cell, row) {
        return (
            <span>
                {row.coach_is_actif === 1 &&
                    <span className="label label-success" data-toggle="modal" data-target="#changeCoachStatusModal" onClick={() => this.getCoachID(row)}>actif</span>
                }
                {row.coach_is_actif === 0 &&
                    <span className="label label-danger" data-toggle="modal" data-target="#changeCoachStatusModal" onClick={() => this.getCoachID(row)}>non actif</span>
                }
            </span>
        );
    }

    getCoachInfos = (coach) => {
        this.setState({
            coachID: coach.id,
            coachFullname: coach.coachFullname,
            coachEmail: coach.email,
            coachDomaine: coach.domaine,
            coachDomaineID: coach.coach_domaine_id,
            coachPhone: coach.phone,
            coachStatus: coach.coach_is_actif,
            coachNbreBakelistes: coach.nbreBakeliste,
            coachFirstname: coach.first_name,
            coachLastname: coach.last_name,
            updateCoach: coach
        })
    }

    updateCoachStatusHandleChange = (e) => {
        let fields = this.state.updateFields;
        fields[e.target.name] = e.target.value;
        this.setState({
            updateFields: fields
        });
        console.log(fields);
    }

    updateCoachStatus = (e) => {
        e.preventDefault();
        this.setState({
            updatedCoachStatusInProgress: true
        });
        console.log(this.state.updateFields);
        let updatedData = this.state.updateFields;
        let coachID = window.sessionStorage.getItem('lcoach_id');
        axios.post(this.state.api + 'update-coach-status/' + coachID, updatedData)
            .then(response => {
            this.setState({
                updatedCoachStatusInProgress: false
            })
            if (response.data.success === true) {
                window.location = 'liste-coach';
            } else {
                this.setState({
                    updatedError: true
                })
            }
        })
    }

    actionsFormatter(cell, row) {
        return (
            <span>
                <button title="Voir" onClick={() => { this.getCoachInfos(row) }} id="show_item" className="update_item" className="btn btn-default edit-btn volkeno-green-bcolor" data-toggle="modal" data-target="#showInfosModal">
                    <i class="fas fa-eye"></i>
                </button>
                &nbsp;&nbsp;
                <button title="Modifier" onClick={() => { this.getCoachInfos(row) }} id="update_item" className="btn btn-default edit-btn volkeno-orange-bcolor" data-toggle="modal" data-target="#sendMsgModal">
                    <i class="fas fa-edit"></i>
                </button>
                &nbsp;&nbsp;
                <button title="Archiver" onClick={() => { this.archivedCoach(row.id)}} id="update_item" className="btn btn-default edit-btn volkeno-red-bcolor" data-toggle="modal">
                    <i class="fas fa-archive" ></i>
                </button>

            </span>
        );
    }

    //
    getCoachIDForRedirect = (coach) => {
        let coachFullname = coach.first_name + ' ' + coach.last_name;
        window.sessionStorage.setItem('coachIDForRedirect', coach.id);
        window.sessionStorage.setItem('coachFNForRedirect', coachFullname);
        window.location = 'bakeliste-by-coach';
    }

    nbreBakelistesFormatter(cell, row) {
        return (
            <span>
                {/* <span className="label label-info volkeno-green-bcolor nbakelistes_link" onClick={() => this.getCoachIDForRedirect(row)}> {row.nbreBakeliste} </span> */}
                <button onClick={() => this.getCoachIDForRedirect(row)} class="btn btn-primary volkeno-green-bcolor nbakelistes_link" type="button">
                    <span class="badge"> {row.nbreBakeliste} </span>
                </button>
            </span>
        );
    }

    /**
     * Method to switch boolean variable
     */
    handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'coachUpdateSuccess') {
                this.setState({
                    updateCoachInfosSuccessAlert: false
                });
            }
            if (label === 'coachUpdateError') {
                this.setState({
                    updateCoachInfosRequestError: false
                });
            }
        }, 5000);
    }

    /**
     * HandleChange method to update coach infos
     */
    updateHandleChange = (e) => {
        let fields = this.state.updateFields;
        fields[e.target.name] = e.target.value;

        if (e.target.name === 'first_name') {
            this.setState({
                coachFirstname: e.target.value
            })
        }
        if (e.target.name === 'last_name') {
            this.setState({
                coachLastname: e.target.value
            })
        }
        if (e.target.name === 'email') {
            this.setState({
                coachEmail: e.target.value
            })
        }
        if (e.target.name === 'phone') {
            this.setState({
                coachPhone: e.target.value
            })
        }
        if (e.target.name === 'coach_domaine_id') {
            this.setState({
                coachDomaineID: e.target.value
            })
        }

        if (!fields["first_name"]) {
            fields["first_name"] = this.state.updateCoach.first_name;
        }
        if (!fields["last_name"]) {
            fields["last_name"] = this.state.updateCoach.last_name;
        }
        if (!fields["email"]) {
            fields["email"] = this.state.updateCoach.email;
        }
        if (!fields["phone"]) {
            fields["phone"] = this.state.updateCoach.phone;
        }
        if (!fields["coach_domaine_id"]) {
            fields["coach_domaine_id"] = this.state.updateCoach.coach_domaine_id;
        }

        this.setState({
            updateFields: fields
        })

        console.log(fields)
    }

    /**
     * Submit method to update coach infos
     */
    updatedCoachData = (e) => {
        e.preventDefault();
        console.log(this.state.updateFields)
        this.setState({
            updateCoachInfosInProgress: true
        });
        let idCoach = this.state.coachID;
        let data = this.state.updateFields;
        axios.put(this.state.api + 'update-coach-infos/' + idCoach, data)
            .then(response => {
                if (response.data.success === true) {
                    this.getListCoach();
                    $('.close').click();
                    this.setState({
                        updateCoachInfosInProgress: false,
                        updateCoachInfosSuccessAlert: true,
                        updateFields: {}
                    })
                    this.handleShowAndHideAlert('coachUpdateSuccess');
                }
                else {
                    console.log(response)
                    this.setState({
                        updateCoachInfosInProgress: false,
                        updateCoachInfosRequestError: true
                    });
                    this.handleShowAndHideAlert('coachUpdateError');
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    updateCoachInfosInProgress: false,
                    updateCoachInfosRequestError: true
                });
                this.handleShowAndHideAlert('coachUpdateError');
            })
    }

    render() {
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton
        };

        if (this.state.loading) {
            return (
                <div>
                     <Menu />
                    <div className="ListProspects row" id="assignBody">
                         <div className="col-lg-12">
                            <h1 className="page-header">
                                <span className="page-title">Liste des coachs</span>
                                <div className="pull-right">
                                    <ol className="breadcrumb">
                                        <li>
                                            <Link to="/dashboard-admin">Accueil</Link>
                                        </li>
                                        <li className="active">Liste des coachs</li>
                                    </ol>
                                </div>
                            </h1>
                        </div> 
                        <div className="col-md-12 reporting-container">
                            <div className="row add_coach_btn_row">
                                <div className="col-md-6 col-md-offset-3 add-coach-btn-container">
                                    <Link data-toggle="tooltip" data-placement="top" title="Ajouter un prospect" to="/ajouter-un-coach" className="btn btn-success add_coach_btn button button addBtn">
                                        <i className="fas fa-plus-circle"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="bakeliste_container">
                                <div className="table_container">
                                    <table className="table table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Prénom et Nom</th>
                                                <th scope="col">Domaines</th>
                                                <th scope="col">Nombre Bakeliste</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Numéro</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div id="loader"></div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        }

        return (

            <div>
                <Menu />
                <div className="ListProspects row" id="assignBody">
                 <div className="col-lg-12">
                        <h1 className="page-header">
                            <span className="page-title">Liste des coachs</span>
                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard-admin">Accueil</Link>
                                    </li>
                                    <li className="active">Liste des coachs</li>
                                </ol>
                            </div>
                        </h1>
                    </div> 

                    <div className="col-md-12 reporting-container">
                        <div className="row add_coach_btn_row">
                            <div className="col-md-6 col-md-offset-3 add-coach-btn-container">
                                <Link data-toggle="tooltip" data-placement="top" title="Ajouter un prospect" to="/ajouter-un-coach" className="btn btn-success add_coach_btn button button addBtn">
                                    <i className="fas fa-plus-circle"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="coach_container">
                            <div className="table_container">
                                <BootstrapTable data={this.state.coach}
                                    striped={true} hover={true} condensed={true}
                                    multiColumnSort={2} options={options}
                                    pagination
                                    search={true}>
                                    <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField='coachFullname' >Nom complet</TableHeaderColumn>
                                    <TableHeaderColumn dataField='domaine'>Domaine</TableHeaderColumn>
                                    <TableHeaderColumn dataField='nbreBakeliste' dataFormat={this.nbreBakelistesFormatter.bind(this)}>Nombre Bakelistes</TableHeaderColumn>
                                    <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
                                    <TableHeaderColumn dataField='phone'>Téléphone</TableHeaderColumn>
                                    <TableHeaderColumn dataField='coach_is_actif' dataFormat={this.statusFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>
                                    <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)}>Actions</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        </div>
            
                        {/* Modification du statut du coach */}
                        <div className="modal fade" id="changeCoachStatusModal" tabIndex="-1" role="dialog" aria-labelledby="changeCoachStatusModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <h4 className="modal-title" id="changeCoachStatusModalLabel">Detail sur </h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="update-coach-status-container">
                                            <div class="form-group " >
                                                <select class="form-control" id="inputStatus_Reporting" name="coach_is_actif" onClick={this.updateCoachStatusHandleChange}>
                                                    <option>--Choisir un statut--</option>
                                                    <option value={1}>Activer</option>
                                                    <option value={0}>Désactivé</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                <i class="fas fa-times-circle"></i> &nbsp;
                                                Quitter
                                            </button>
                                            {!this.state.updatedCoachStatusInProgress &&
                                                <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.updateCoachStatus(e)}>
                                                    <i class="fas fa-user-check"></i>&nbsp;
                                                    Modifier
                                                </button>
                                            }
                                            {this.state.updatedCoachStatusInProgress &&
                                                <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                    Modification en cours &nbsp;
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                </button>
                                            }
                                            {this.state.updatedError &&
                                                <div className="row update-error-container">
                                                    <div className="col-md-12 error-content alert alert-danger">
                                                        Oups...Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Afficher les infos du coach */}
                        <div className="modal fade" id="showInfosModal" tabIndex="-1" role="dialog" aria-labelledby="showInfosModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <h4 className="modal-title" id="showInfosModalLabel">Detail sur {this.state.coachFullname}</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="coachInfos-container">
                                            <div className="fullname_container">
                                                <span className="item_label">Prénom et Nom : </span> <span className="label_content">{this.state.coachFullname}</span>
                                            </div> <hr />
                                            <div className="email_container">
                                                <span className="item_label">Email : </span> <span className="label_content">{this.state.coachEmail}</span>
                                            </div> <hr />
                                            <div className="phone_container">
                                                <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.coachPhone}</span>
                                            </div> <hr />
                                            <div className="domaine_container">
                                                <span className="item_label">Domaine de compétence : </span> <span className="label_content">{this.state.coachDomaine}</span>
                                            </div> <hr />
                                            <div className="count_container">
                                                <span className="item_label nbakelistes">Nombre de bakelistes : </span> <br />
                                                <span className="label_content cbakelistes">{this.state.coachNbreBakelistes}</span>
                                            </div> <hr />
                                        </div>
                                        <div className="modal-footer">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Editer les infos du coach */}
                        <div className="modal fade" id="sendMsgModal" tabIndex="-1" role="dialog" aria-labelledby="archiverModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <h4 className="modal-title" id="sendMsgModalLabel">Editer {this.state.coachFullname}</h4>
                                    </div>

                                    <div className="modal-body">

                                        <div className="row edit-coach-container">
                                            <div className="col-md-6">
                                                <div className="form-group " >
                                                    <label>Prénom : </label>
                                                    <input type="text" className="form-control" value={this.state.coachFirstname} onChange={this.updateHandleChange} name="first_name" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group ">
                                                    <label>Nom : </label>
                                                    <input type="text" className="form-control" value={this.state.coachLastname} onChange={this.updateHandleChange} name="last_name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group " >
                                                    <label>Email : </label>
                                                    <input type="email" className="form-control" value={this.state.coachEmail} onChange={this.updateHandleChange} name="email" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group " >
                                                    <label>Téléphone : </label>
                                                    <input type="number" className="form-control" value={this.state.coachPhone} onChange={this.updateHandleChange} name="phone" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Domaine :</label>
                                                    <select className="form-control" id="inputbakeli_domaine_id" name="coach_domaine_id" value={this.state.coachDomaineID} onChange={this.updateHandleChange}>
                                                        <option> -- Choisir un domaine -- </option>
                                                        {this.state.domaines.map((x, y) => <option value={x.id} key={y} >{x.name}</option>)}
                                                    </select>
                                                </div>
                                            </div>

                                            {this.state.updateCoachInfosRequestError &&
                                                <div className="alerty-danger-container col-md-12 alert alert-danger form-group">
                                                    <div className="alerty-danger">
                                                        Un probléme est survenu lors de la modification. Veuillez réessayer plus tard.
                                                    </div>
                                                </div>
                                            }

                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                            <i class="fas fa-times-circle"></i> &nbsp;
                                            Quitter
                                            </button>

                                        {!this.state.updateCoachInfosInProgress &&
                                            <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.updatedCoachData(e)}>
                                                <i class="fas fa-edit"></i>&nbsp;
                                                Modifier
                                            </button>
                                        }
                                        {this.state.updateCoachInfosInProgress &&
                                            <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                Modification en cours &nbsp;
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </button>
                                        }
                                        {this.state.updatedError &&
                                            <div className="row update-error-container">
                                                <div className="col-md-12 error-content alert alert-danger">
                                                    Oups...Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                    </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Archiver un coach */}
                        {/* 
                        <div className="modal fade" id="archiverModal" tabIndex="-1" role="dialog" aria-labelledby="archiverModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <h4 className="modal-title" id="archiverModalLabel">Voulez-vous archiver le coach{this.state.coachFullname}?</h4>
                                    </div>

                                    <div className="modal-body">

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                            <i class="fas fa-times-circle"></i> &nbsp;
                                            Archiver
                                        </button>
                                        <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                            <i class="fas fa-times-circle"></i> &nbsp;
                                            Quitter
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                       */}
                        {/* Updated coach success toast alert */}
                        {this.state.updateCoachInfosSuccessAlert === true &&
                            <div className="alert alert-success text-center upsuccess-alert">
                                <i class="fas fa-check-double"></i> &nbsp;&nbsp;
                                Coach infos modifié avec succès.
                            </div>
                        }
                    </div>

                </div>
            </div>
        );
    }
}

export default ListCoachs;
