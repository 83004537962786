import React, {Component} from 'react';
import './textualContentsViewByAdmin.css'
import { Link } from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
//import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';

export default class textualContentsViewByAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          isPageLoading: false,
          textualContents: [],
          textualContentItem: [],

        };
    }

    componentDidMount = () => {
      this.onGetAllTextualContents();
    }

    onGetAllTextualContents = () => {
      this.setState({
        isPageLoading: true
      })

      axios.get(this.state.api + 'textual-contents')
        .then(response => {
          this.setState({
            isPageLoading: false
          })

          if(response.data.success){
            this.setState({
              textualContents: response.data.data
            })
          }else{
            this.setState({
              emptyContent: true
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false,
          })
          if (process.env.NODE_ENV !== 'production') {
            return;
          }
          Sentry.captureException(error);
        })
    }

    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    getTextualContentItem = (item) => {
      this.setState({
        textualContentItem: item
      })
    }

    titleFormatter = (cell, row) => {
      return (
          <span className="fullname-link" data-toggle="modal" data-target="#showTextualContentItemModal" onClick={(e) => this.getTextualContentItem(row, e)}>{cell}</span>
      );
    }

    contentTypeFormatter = (cell, row) => {
      if(cell === 'résumé'){
        return(
          <span className="label label-success"> {cell} </span>
        )
      }
      if(cell === 'tutoriel'){
        return(
          <span className="label label-warning"> {cell} </span>
        )
      }
      if(cell === 'article'){
        return(
          <span className="label label-info"> {cell} </span>
        )
      }
      if(cell === 'rapport de stage'){
        return(
          <span className="label label-danger"> {cell} </span>
        )
      }
      if(cell === 'cahier de charges'){
        return(
          <span className="label label-vgreen"> {cell} </span>
        )
      }
      if(cell === 'backlog'){
        return(
          <span className="label label-violet"> {cell} </span>
        )
      }
    }

    actionsFormatter = (cell, row) => {
      return (
          <div className="actions-btn-container">
            <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#showTextualContentItemModal" onClick={(e) => this.getTextualContentItem(row)}>
              <i class="fas fa-eye"></i>
            </button> &nbsp;
          </div>
      );
    }

    render() {
      const options = {
        clearSearch: true,
        clearSearchBtn: this.createCustomClearButton
      };
      if(this.isPageLoading){
        return(
          <div className="component-textual-contents-view-by-admin">
            <Menu />
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
                <div className="row no-content-wrapper">
      
                  <section className="content-header">
                    <h1>
                      Liste des contenus textuels
                    </h1>
                    <ol class="breadcrumb">
                      <li><Link to="/dashboard-admin">Accueil</Link></li>
                      <li className="active">Liste des contenus textuels</li>
                    </ol>
                  </section>
      
                  <section className="content">
                    <div className="editeur_texte_container">
                      <div id="loader"></div>
                    </div>
                  </section>
      
                </div>
          </div>
        );
      }
      return(
        <div className="component-textual-contents-view-by-admin">
          <Menu />
              {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
              <div className="row no-content-wrapper">
    
                <section className="content-header">
                  <h1>
                  Liste des contenus textuels
                  </h1>
                  <ol class="breadcrumb">
                    <li><Link to="/dashboard-admin">Accueil</Link></li>
                    <li className="active">Liste des contenus textuels</li>
                  </ol>
                </section>
    
                <section className="content">
                  <div className="table_container">
                    <BootstrapTable data={this.state.textualContents}
                      striped={true} hover={true} condensed={true}
                      multiColumnSort={2} options={options}
                      pagination
                      search={true}>

                      <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                      <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                      <TableHeaderColumn dataAlign="center"
                          width='400'
                          dataField='title'
                          dataFormat={this.titleFormatter.bind(this)}
                          filter={{ type: 'TextFilter', delay: 1000 }}
                          dataSort={true} >Titre du contenu</TableHeaderColumn>
                      <TableHeaderColumn dataAlign="center"
                          width='150'
                          dataField='content_type'
                          dataFormat={this.contentTypeFormatter.bind(this)}
                          filter={{ type: 'TextFilter', delay: 1000 }}
                      >Type de contenu</TableHeaderColumn>
                      <TableHeaderColumn dataAlign="center"
                          width='250'
                          dataField='domaineName'
                          filter={{ type: 'TextFilter', delay: 1000 }}
                      >Domaine</TableHeaderColumn>
                      
                    </BootstrapTable>
                  </div>
                </section>
    
              </div>

              <div class="modal fade bs-example-modal-lg" id="showTextualContentItemModal" tabindex="-1" role="dialog" aria-labelledby="showTextualContentItemModalLabel">
                <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title" id="myModalLabel"> {this.state.textualContentItem.title} <sup> <span className="label label-danger"> {this.state.textualContentItem.content_type} </span> </sup> </h4>
                    </div>
                    <div class="modal-body">
                      <div className="row rowy">
                        <div className="col-md-12 tc-col">
                        <div className="content" dangerouslySetInnerHTML={{__html: this.state.textualContentItem.content}}></div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Fermer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      );
    }
  }