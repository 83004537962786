/*eslint no-unused-expressions: "error"*/
import React, {Component} from 'react';
import './demandesPlacementDirectes.css'
//import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
//import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
//import axios from 'axios';
import {  NavLink } from 'react-router-dom';
//import API from '../../variablesGlobales';
//import $ from 'jquery';
import ListeDemandesPlacementDirectes from './listeDemandesPlacementDirectes/ListeDemandesPlacementDirectes';

export default class demandesPlacementDirectes extends Component {
    constructor(props) {
      let token = window.sessionStorage.getItem('token');
      let userStatus = window.sessionStorage.getItem('user_status');

      if (!token) {
          window.history.back()
      }
      if (userStatus === 'bakeliste' || userStatus === 'coach') {
          window.history.back();
      }
      super(props);
      this.state = {

      };
    }
    render() {
      return(
        
        <div className="component-demandes-placement-directes">
          
  
          <ListeDemandesPlacementDirectes />
        </div>
      );
    }
  }
// export default connect(
//     ({ demandesPlacementDirectes }) => ({ ...demandesPlacementDirectes }),
//     dispatch => bindActionCreators({ ...demandesPlacementDirectesActions }, dispatch)
//   )( demandesPlacementDirectes );