import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";

import AdminHomeDashboard from "./components/adminHomeDashboard/adminHomeDashboard";
import Livrable from "./components/livrable/Livrable";
import Connexion from "./components/connexion/Connexion";
import register from "./components/register/register";

import AccountValidation from "./components/accountValidation/AccountValidation";
//import AssignerCoach from './components/assignerCoach/assignerCoach';
import * as serviceWorker from "./serviceWorker";
import CreateCoach from "./components/CreateCoach/CreateCoach";
import ListCoach from "./components/listeCoach/listeCoach";
import ListProspects from "./components/listeProspects/listProspects";
import AjoutBakeliste from "./components/createBakeliste/createBakeliste";
import Reportings from "./components/reportings/Reportings";
import AjoutProspect from "./components/createProspect/createProspect";
import ListeBakelistes from "./components/liste-bakelistes/ListeBakelistes";
import Calendrier from "./components/calendrier/calendrier";
import gestionRdv from "./components/gestionRdv/gestionRdv";
import addRdv from "./components/addRdv/addRdv";
import coachHomeDashboard from "./components/coachHomeDashboard/coachHomeDashboard";
import ListeBakelistesboursiers from "./components/liste-bakelistesboursiers/ListeBakelistesboursiers";
import ListeBakelistespayants from "./components/liste-bakelistespayants";
import gestionTache from "./components/gestionTache/gestionTache";
import archivedCompletedRdv from "./components/archivedCompletedRdv/archivedCompletedRdv";
import domaineFormation from "./components/domaineFormation";
import Meetings from "./components/meetings";

import bakelisteHomeDashboard from "./components/bakelisteHomeDashboard/bakelisteHomeDashboard";
import gestionCandidatureSpontanee from "./components/gestionCandidatureSpontanee/gestionCandidatureSpontanee";

import listBakelistesVF from "./components/listBakelistesVF/listBakelistesVF";
import listBakelistesGY1 from "./components/listBakelistesGY1/listBakelistesGY1";
import listBakelistesGY2 from "./components/listBakelistesGY2/listBakelistesGY2";
import bakelistesByCoach from "./components/bakelistesByCoach/bakelistesByCoach";
import passwordReset from "./components/passwordReset/passwordReset";
import viewforResetPassword from "./components/viewforResetPassword/viewforResetPassword";
import newFunctionality from "./components/newFunctionality/newFunctionality";
import reportingByBakeliste from "./components/reportingByBakeliste/reportingByBakeliste";
import participantByRencontre from "./components/participantByRencontre/participantByRencontre";
import hiredBakelistes from "./components/hiredBakelistes/hiredBakelistes";
import cvtheque from "./components/cvtheque/cvtheque";
import bakelisteCvView from "./components/bakelisteCvView/bakelisteCvView";
import completedBakelistes from "./components/completedBakelistes/completedBakelistes";
import importerCsv from "./components/importerCsv/importerCsv";
import bakelistesByDomaine from "./components/bakelistesByDomaine/bakelistesByDomaine";
import detailsRencontreByLevel from "./components/detailsRencontreByLevel/detailsRencontreByLevel";
import UserProfile from "./components/account/userProfile/UserProfile";
import EditProfile from "./components/account/editProfile/EditProfile";
import EditPasswordProfile from "./components/account/editPasswordProfile/EditPasswordProfile";
import allBakelistesListe from "./components/allBakelistesListe/allBakelistesListe";
import entreprisesPartenaires from "./components/entreprisesPartenaires/entreprisesPartenaires";
import demandesPlacementDirectes from "./components/demandesPlacementDirectes/demandesPlacementDirectes";
import delayAndAbsence from "./components/delayAndAbsence/delayAndAbsence";

import listeBakelisteDemiBoursier from "./components/listeBakelisteDemiBoursier/listeBakelisteDemiBoursier";
import listeBakelisteFormationDiplomante from "./components/listeBakelisteFormationDiplomante/listeBakelisteFormationDiplomante";
import listeBakelisteFormationCourte from "./components/listeBakelisteFormationCourte/listeBakelisteFormationCourte";
import listeBakelisteStageDeFormation from "./components/listeBakelisteStageDeFormation/listeBakelisteStageDeFormation";
import listeBakelisteEnContratIsa from "./components/listeBakelisteEnContratIsa/listeBakelisteEnContratIsa";
import listeBakelisteTambacounda from "./components/listeBakelisteTambacounda/listeBakelisteTambacounda";
// all users
import ListeUtilisateurs from "./components/allUsers/listeUtilisateurs";
import listeBakelisteThies from "./components/listeBakelisteThies/listeBakelisteThies";
import eventManager from "./components/eventManager/eventManager";

import recruitablesBakelistesViewByAdmin from "./components/recruitablesBakelistesViewByAdmin/recruitablesBakelistesViewByAdmin";
import listeParticipantKokutana from "./components/listeParticipantKokutana/listeParticipantKokutana";
import ratingsManager from "./components/ratingsManager/ratingsManager";
import listeBakelistesImmersion from "./components/listeBakelistesImmersion/listeBakelistesImmersion";
import editeurTexte from "./components/editeurTexte/editeurTexte";
import Editor from "./components/editeurTexte/editorMobile/editor";
import textualContentsByBakeliste from "./components/textualContentsByBakeliste/textualContentsByBakeliste";
import textualContentsViewByAdmin from "./components/textualContentsViewByAdmin/textualContentsViewByAdmin";
import gestionPointage from "./components/gestionPointage/gestionPointage";
import reglementInterieur from "./components/reglementInterieur/reglementInterieur";
import chatReporting from "./components/chatReporting/chat";
import manageUsers from "./components/manageUsers/manageUsers";
import BakeliProfile from "./components/bakelisteProfile/bakelisteProfile";
import ListeMessage from "./components/listeMessages/listeMessages";
import detailsMessage from "./components/detailsMessage/detailsMessage";
import historiques from "./components/historiques/historiques";

import GestionSyllabus from "./components/gestionSyllabus/gestionSyllabus";
import GestionSyllabusCoach from "./components/gestionSyllabusCoach/GestionSyllabusCoach";
import GestionSyllabusBakeliste from "./components/gestionSyllabusBakeliste/gestionSyllabusBakeliste";
import SyllabusContentManageView from "./components/syllabusContentManageView/syllabusContentManageView";
import SyllabusContentBakelisteView from "./components/syllabusContentBakelisteView/SyllabusContentBakelisteView";
import messages from "./components/messages/messages";
import noterMonCoach from "./components/noterMonCoach/noterMonCoach";
import tacheChat from "./components/gestionTacheChat/tacheChat.js";
import evenement from "./components/evenements/evenements.js";
import controles from "./components/controles/controles.js";
import entreprises from "./components/Entreprises/entreprises";
import Production from "./components/MenuBakeliste/Production";
import Bibliotheque from "./components/Bibliotheque/bibliotheque";
import contenu_bibliotheque from "./components/contenu_bibliotheque/Programmation";
import Home from "./components/bakelisteHomeDashboard/exemple";
import LivrableCoach from "./components/livrableCoach/livrableCoach";
import AssignationSyllabusByCoach from "./components/assignationSyllabusByCoach/assignationSyllabusByCoach";
import Demande from "./components/demandeAbsence/Demande";
import SuiviDemande from "./components/demandeAbsence/SuiviDemande";
import Tools from "./components/tools/Tools";
import Technologie from "./components/technologies/Technologie";
import Groupe from "./components/groupe/groupe";
import Bakelistegroupe from "./components/BakelisteGroupe/BakelsteGroup";

Sentry.init({
  dsn:
    "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
});
// Sentry.init({dsn: "https://6368bf52547c42ffa1512f91c39113a3@sentry.io/1731980"});
const RELEASE = "0.1.0";
if (process.env.NODE_ENV === "production") {
  // Sentry
  Sentry.init({
    //dsn: 'https://6368bf52547c42ffa1512f91c39113a3@sentry.io/1731980',
    dsn:
      "https://7eae0d8413514607ac6ef075da6edc92@o417215.ingest.sentry.io/5315964",
    debug: true,
    release: RELEASE,
  });
}

// Google Analytics
ReactGA.initialize("UA-153509665-1");
ReactGA.pageview(window.location.pathname + window.location.search);

document.addEventListener("DOMContentLoaded", function () {
  ReactDOM.render(
    <Router>
      <div className="router-container">
        <Route exact path="/" component={Connexion} />
        <Route exact path="/register" component={register} />
        <Route exact path="/signaler-un-probleme" component={messages} />
        <Route exact path="/dashboard-admin" component={AdminHomeDashboard} />
        <Route path="/ajouter-un-prospect" component={AjoutProspect} />
        <Route path="/ajouter-un-bakeliste" component={AjoutBakeliste} />
        {/* <Route path="/assigner-un-coach" component={AssignerCoach} /> */}
        <Route path="/liste-bakelistes" component={ListeBakelistes} />
        <Route
          path="/liste-bakelistes-boursiers"
          component={ListeBakelistesboursiers}
        />
        <Route
          path="/liste-bakelistes-payants"
          component={ListeBakelistespayants}
        />
        <Route path="/ajouter-un-coach" component={CreateCoach} />
        <Route path="/liste-coach" component={ListCoach} />
        <Route path="/liste-prospects" component={ListProspects} />
        <Route path="/reportings" component={Reportings} />
        <Route path="/calendrier" component={Calendrier} />
        <Route path="/gestion-des-rendez-vous" component={gestionRdv} />
        <Route path="/ajouter-un-rendez-vous" component={addRdv} />
        <Route path="/dashboard-coach" component={coachHomeDashboard} />
        <Route path="/gestion-tache" component={gestionTache} />
        <Route path="/rdv-archiver-valider" component={archivedCompletedRdv} />
        <Route path="/chat-Reporting" component={chatReporting} />
        <Route path="/groupes" component={Groupe} />
        
        <Route path="/gestions_groupes" component={Bakelistegroupe} />


        
        <Route path="/dashboard-bakeliste" component={bakelisteHomeDashboard} />
        <Route path="/home" component={Home} />
        <Route
          path="/candidature-spontanee"
          component={gestionCandidatureSpontanee}
        />
        <Route path="/Livrable" component={Livrable} />
        <Route
          path="/liste-bakelistes-volkeno-foire"
          component={listBakelistesVF}
        />
        <Route
          path="/liste-bakelistes-volkeno-hgrand-yoff1"
          component={listBakelistesGY1}
        />
        <Route
          path="/liste-bakelistes-volkeno-hgrand-yoff2"
          component={listBakelistesGY2}
        />
        <Route path="/all-users" component={ListeUtilisateurs} />
        <Route path="/livraisons" component={LivrableCoach} />

        <Route path="/domaine-formation" component={domaineFormation} />
        <Route path="/bakeliste-by-coach" component={bakelistesByCoach} />
        <Route
          path="/reinitialiser-mot-de-passe"
          component={viewforResetPassword}
        />
        <Route path="/password-reset/:id" component={passwordReset} />
        <Route path="/meetings" component={Meetings} />

        <Route path="/nouvelles-fonctionnalites" component={newFunctionality} />
        <Route
          path="/reportings-by-bakeliste"
          component={reportingByBakeliste}
        />
        <Route
          path="/participants-rencontre"
          component={participantByRencontre}
        />
        <Route
          path="/liste-des-bakelistes-en-poste"
          component={hiredBakelistes}
        />

        <Route path="/edition-cv-bakeliste" component={cvtheque} />
        <Route
          path="/show-bakeliste-cv/:bakeliste_id/:label"
          component={bakelisteCvView}
        />
        <Route
          path="/liste-bakelistes-formation-terminer"
          component={completedBakelistes}
        />
        <Route path="/manage-import-csv" component={importerCsv} />
        <Route
          path="/liste-bakelistes-by-domaine/:domaine_id"
          component={bakelistesByDomaine}
        />

        <Route
          path="/details-rencontre-by-level/:rencontre_id/:level"
          component={detailsRencontreByLevel}
        />
        <Route path="/mon-profil" component={UserProfile} />
        <Route path="/editer-mon-profil" component={EditProfile} />
        <Route
          path="/modifier-mon-mot-de-passe"
          component={EditPasswordProfile}
        />

        <Route
          path="/liste-tous-les-bakelistes"
          component={allBakelistesListe}
        />
        <Route
          path="/liste-entreprises-partenaires"
          component={entreprisesPartenaires}
        />
        <Route
          path="/demandes-placement-directes"
          component={demandesPlacementDirectes}
        />

        <Route
          path="/gestion-retards-absences-bakelistes"
          component={delayAndAbsence}
        />
        <Route
          path="/liste-bakelistes-demi-boursier"
          component={listeBakelisteDemiBoursier}
        />
        <Route
          path="/liste-bakelistes-formation-diplomante"
          component={listeBakelisteFormationDiplomante}
        />
        <Route
          path="/liste-bakelistes-formation-courte"
          component={listeBakelisteFormationCourte}
        />
        <Route
          path="/liste-bakelistes-en-stage-de-formation"
          component={listeBakelisteStageDeFormation}
        />
        <Route
          path="/liste-bakelistes-en-contrat-isa"
          component={listeBakelisteEnContratIsa}
        />
        <Route
          path="/liste-bakelistes-tambacounda"
          component={listeBakelisteTambacounda}
        />
        <Route path="/liste-bakelistes-thies" component={listeBakelisteThies} />
        <Route path="/event-manager" component={eventManager} />

        <Route
          path="/liste-bakelistes-recrutables"
          component={recruitablesBakelistesViewByAdmin}
        />
        <Route
          path="/liste-participants-kokutana2019"
          component={listeParticipantKokutana}
        />
        <Route path="/ratings-manager" component={ratingsManager} />

        <Route
          path="/liste-bakelistes-en-immersion"
          component={listeBakelistesImmersion}
        />
        <Route path="/editeur-texte" component={editeurTexte} />
        <Route path="/editeur-texte-mobile" component={Editor} />

        <Route
          path="/show-bakeliste-textual-contents/:bakeliste_id/:label"
          component={textualContentsByBakeliste}
        />
        <Route
          path="/liste-contenus-textuels"
          component={textualContentsViewByAdmin}
        />

        <Route path="/gestion-pointages" component={gestionPointage} />
        <Route path="/reglement-interieur" component={reglementInterieur} />

        <Route path="/manage-users" component={manageUsers} />
        <Route path="/evenement" component={evenement} />
        <Route path="/bakeliste-profile" component={BakeliProfile} />
        <Route path="/liste-message" component={ListeMessage} />
        <Route path="/detailsMessage/:id" component={detailsMessage} />
        <Route path="/historiques" component={historiques} />
        <Route
          exact
          path="/account-validation/:id"
          component={AccountValidation}
        />
        <Route path="/syllabus-manager" component={GestionSyllabus} />
        <Route path="/syllabus-coach" component={GestionSyllabusCoach} />
        <Route
          path="/syllabus-assign-manager/:syllabus_id"
          component={AssignationSyllabusByCoach}
        />

        <Route
          path="/syllabus-by-domaine/:user_id"
          component={GestionSyllabusBakeliste}
        />
        <Route
          path="/syllabus-content-manager/:syllabus_id"
          component={SyllabusContentManageView}
        />
        <Route
          path="/syllabus-content-bakeliste/:syllabus_id"
          component={SyllabusContentBakelisteView}
        />
        <Route path="/noterMonCoach" component={noterMonCoach} />
        <Route path="/tache-chat" component={tacheChat} />
        <Route path="/controles" component={controles} />
        <Route path="/entreprises" component={entreprises} />
        <Route path="/Production" component={Production} />
        <Route path="/demande-absence" component={Demande} />
        <Route path="/demandes-absences" component={SuiviDemande} />
        <Route path="/bibliotheque" component={Bibliotheque} />
        <Route
          path="/contenu_bibliotheque/:id"
          component={contenu_bibliotheque}
        />
        <Route path="/outils" component={Tools} />
        <Route path="/technologies" component={Technologie} />
      </div>
    </Router>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();
