/* eslint-disable import/first */
import React, { Component } from 'react';
import './ListeDemandesPlacementDirectes.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../../menu/Menu';;
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import API from '../../../variablesGlobales';
import $ from 'jquery';

export default class ListeDemandesPlacementDirectes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminID: window.sessionStorage.getItem('user_id'),
      api: API.API,
      isPageLoading: false,
      demandesPlacement: [],
      addDirectPlacementRequestInProgress: false,
      addDirectPlacementRequestError: false,
      addSuccessDPRAlert: false,
      fields: {},
      errors: {},
      companyIDEmpty: false,
      descriptionEmpty: false,
      conditionsEmpty: false,
      levelEmpty: false,
      isInputLoading: false,
      companies: [],
      isDPRError: false,
      placementRequestInfos: [],

    };
    this.addHandleChange = this.addHandleChange.bind(this);
    this.addNewDirectPlacementRequest = this.addNewDirectPlacementRequest.bind(this);
    this.getDirectPlacementRequestInfos = this.getDirectPlacementRequestInfos.bind(this);
  }

  componentDidMount() {
    this.getAllDirectPlacementRequest();
    this.getCompaniesList();
  }

  /**
   * Liste des demandes de placement directes
   */
  getAllDirectPlacementRequest = () => {
    this.setState({
      isPageLoading: true
    })

    axios.get(this.state.api + 'direct-placement-requests')
      .then(response => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            demandesPlacement: response.data.data
          })
        } else {
          console.log(response)
          this.setState({
            isPageLoading: false,
            isDPRError: true
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isPageLoading: false,
          isDPRError: true
        })
      })
  }

  /**
   * Liste des entreprises partenaires
   */
  getCompaniesList = () => {
    this.setState({
      isInputLoading: true
    })
    axios.get(this.state.api + 'companies')
      .then(response => {
        if (response.data.success) {
          console.log(response.data.data)
          this.setState({
            isInputLoading: false,
            companies: response.data.data
          })
        } else {
          this.setState({
            isInputLoading: false,
            isGetCompaniesRequestError: true
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isInputLoading: false,
          isGetCompaniesRequestError: true
        })
      })
  }

  /**
   * Get placement request infos
   */
  getDirectPlacementRequestInfos = (demande) => {
    let placement = demande
    this.setState({
      placementRequestInfos: placement
    }, () => {
      console.log(this.state.placementRequestInfos)
    })
    //return await this.state.placementRequestInfos;
  console.log(this.state.placementRequestInfos)
  }

  /**
   * Company name formatter
   */
  companyNameFormatter = (cell, row) => {
    return (
      <span className="fullname-link" data-toggle="modal" data-target="#directPlacementRequestModal" onClick={(e) => this.getDirectPlacementRequestInfos(row, e)}>{cell}</span>
    );
  }

  /**
   * Agent name formatter
   */
  agentNameFormatter = (cell, row) => {
    return (
      <span className="fullname-link" data-toggle="modal" data-target="#directPlacementRequestModal" onClick={(e) => this.getDirectPlacementRequestInfos(row, e)}>{cell}</span>
    );
  }

  /**
   * Profile level formatter
   */
  profileLevelFormatter = (cell, row) => {
    if(cell === 'junior'){
      return(
        <span className="label label-default label-green-bgcolor"> {cell} </span>
      );
    }else if(cell === 'intermédiaire'){
      return(
        <span className="label label-warning"> {cell} </span>
      );
    }else if(cell === 'confirmé'){
      return(
        <span className="label label-success"> {cell} </span>
      );
    }

  }

  /**
   * Actions buttons formatter
   */
  actionsFormatter = (cell, row) => {
    return (
      <span>
        <button onClick={() => { this.getDirectPlacementRequestInfos(row) }} id="show_item" className="btn btn-info show-item-btn" data-toggle="modal" data-target="#directPlacementRequestModal">
          <i className="fas fa-eye"></i>
        </button> &nbsp;&nbsp;
            {/* <button onClick={() => { this.getTacheInfos(row) }} id="edit_item" className="btn btn-info edit-btn volkeno-orange-bcolor" data-toggle="tooltip" data-placement="top" title="Modifier la tâche" data-toggle="modal" data-target="#editTacheModal">
                <i className="fas fa-edit"></i>
            </button> */}
      </span>

    );
  }

  /**
   * Budget Formatter
   */
  budgetFormatter = (cell, row) => {
    return(
      <span className="company_budget">
        {cell + ' FCFA'}
      </span>
    );
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
            className={ `btn btn-primary volkeno-red-bcolor` }>
            MyCustomBtn
          </button> */}
      </ButtonGroup>
    );
  }

  /**
   * Hide alert
   */
  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === 'company_id') {
        this.setState({
          companyIDEmpty: false
        });
      }
      if (input === 'offer_description') {
        this.setState({
          descriptionEmpty: false
        });
      }
      if (input === 'offer_conditions') {
        this.setState({
          conditionsEmpty: false
        });
      }
      if (input === 'profile_level') {
        this.setState({
          levelEmpty: false
        });
      }
      if(input === 'addSuccessDPRAlert'){
        this.setState({
          addSuccessDPRAlert: false
        })
      }
      if(input === 'addDirectPlacementRequestError'){
        this.setState({
          addDirectPlacementRequestError: false
        })
      }
    }, 5000);
  }

  /**
   * Handle change add form data
   */
  addHandleChange = (e) => {
    let fields = this.state.fields;
    fields["admin_id"] = this.state.adminID;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields
    })
    console.log(fields)
  }

  /**
   * Add form validation
   */
  addDPLValidation = () => {
    let isValidForm = true;
    let errors = this.state.errors;
    let fields = this.state.fields;

    if(!fields["company_id"]){
      isValidForm = false;
      errors["company_id"] = "Ce champ est obligatoire."
      this.setState({
        companyIDEmpty: true
      })
      this.handleClearButtonClick('compnay_id')
    }
    if(!fields["offer_description"]){
      isValidForm = false;
      errors["offer_description"] = "Ce champ est obligatoire."
      this.setState({
        descriptionEmpty: true
      })
      this.handleClearButtonClick('offer_description')
    }
    if(!fields["offer_conditions"]){
      isValidForm = false;
      errors["offer_conditions"] = "Ce champ est obligatoire."
      this.setState({
        conditionsEmpty: true
      })
      this.handleClearButtonClick('offer_conditions')
    }
    if(!fields["profile_level"]){
      isValidForm = false;
      errors["profile_level"] = "Ce champ est obligatoire."
      this.setState({
        levelEmpty: true
      })
      this.handleClearButtonClick('profile_level')
    }

    return isValidForm;
  }

  /**
   * Submit add form
   */
  addNewDirectPlacementRequest = (e) => {
    e.preventDefault();
    this.setState({
      addDirectPlacementRequestInProgress: true
    })

    if(this.addDPLValidation()){
      let data = this.state.fields;
      axios.post(this.state.api + 'direct-placement-requests', data)
        .then(response => {
          if(response.data.success){
            this.getAllDirectPlacementRequest();
            $('.close').click();
            $('.modal-backdrop').removeClass('modal-backdrop');
            $('.fade').removeClass('fade')
            $('.in').removeClass('in')
            document.getElementById('addNewDirectPlacementRequest').reset();
            this.setState({
              addDirectPlacementRequestInProgress: false,
              addSuccessDPRAlert: true
            })
            this.handleShowAndHideAlert('addSuccessDPRAlert')
          }else{
            console.log(response)
            this.setState({
              addDirectPlacementRequestError: true,
              addDirectPlacementRequestInProgress: false
            })
            this.handleShowAndHideAlert('addDirectPlacementRequestError')
          }
        })
        .catch(error => {
          console.log(error.message)
          this.setState({
            addDirectPlacementRequestError: true,
            addDirectPlacementRequestInProgress: false
          })
          this.handleShowAndHideAlert('addDirectPlacementRequestError')
        })
    }
  }



  
  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-liste-demandes-placement-directes">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Demandes de placement directes
            </h1>
              <ol className="breadcrumb">
                <li><NavLink to="/dashboard-admin" href="#"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                <li className="active">Demandes de placement directes</li>
              </ol>
            </section>

            <section className="content">
              <div className="table_container">
                <table className="table table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Entreprise</th>
                      <th scope="col">Représentant</th>
                      <th scope="col">Téléphone</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <div id="loader"></div>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>

      );
    }
    return (
      <div className="component-liste-demandes-placement-directes">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>
              Demandes de placement directes
            </h1>
            <ol className="breadcrumb">
              <li><NavLink to="/dashboard-admin" href="#"><i className="fa fa-dashboard"></i> Home</NavLink></li>
              <li className="active">Demandes de placement directes</li>
            </ol>
          </section>

          <section className="content">
            <div className="col-md-4 col-md-offset-4 add_container">
              <button className="btn btn-success add_coach_btn button" data-toggle="modal" data-target="#addRequestPlacementModal">
                <i className="fas fa-plus-circle"></i>
              </button>
            </div>
            <div className="col-md-12 bakelistes-container">
              <div className="bakeliste_container">
                <div className="table_container">
                  <BootstrapTable data={this.state.demandesPlacement}
                    striped={true} hover={true} condensed={true}
                    multiColumnSort={2} options={options}
                    exportCSV
                    pagination
                    search={true}>
                    <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                    <TableHeaderColumn width="250" dataField='frenchDateFormat' filter={{ type: 'TextFilter', delay: 1000 }} >Entreprise</TableHeaderColumn>
                    <TableHeaderColumn width="250" dataField='companyName' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.companyNameFormatter.bind(this)} >Entreprise</TableHeaderColumn>
                    <TableHeaderColumn width="250" dataField='agentFullname' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.agentNameFormatter.bind(this)}>Représentant</TableHeaderColumn>
                    <TableHeaderColumn width="250" dataField='companyPhone'>Téléphone</TableHeaderColumn>
                    <TableHeaderColumn width="250" dataField='companyEmail' filter={{ type: 'TextFilter', delay: 1000 }}>Email</TableHeaderColumn>
                    <TableHeaderColumn width="450" dataField='offer_description' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Description du besoin</TableHeaderColumn>
                    <TableHeaderColumn width="450" dataField='offer_conditions' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Conditions</TableHeaderColumn>
                    <TableHeaderColumn width="200" dataField='profile_level' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.profileLevelFormatter.bind(this)} dataSort={true}>Niveau souhaité</TableHeaderColumn>
                    <TableHeaderColumn width="250" dataField='company_budget' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.budgetFormatter.bind(this)} dataSort={true}>Budget</TableHeaderColumn>
                    <TableHeaderColumn width="200" dataField='id' dataFormat={this.actionsFormatter.bind(this)}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Ajouter une nouvelle demande de placement directe */}
        <div className="modal fade" id="addRequestPlacementModal" tabindex="-1" role="dialog" aria-labelledby="addRequestPlacementModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="addRequestPlacementModalLabel">Ajouter une demande de placement directe</h4>
              </div>
              <form id="addNewDirectPlacementRequest">
                <div className="modal-body">
                  <div className="add-dpr-row row">
                    <div className="col-md-12 form_item">
                      <div className="input-group">
                        <div className="row row_espace">
                        <label>Nom de l'entreprise</label>
                        <div className="col-md-10 select_espace">
                          
                          <select name="company_id" className="form-control input_item" onChange={this.addHandleChange}>
                            <option> -- Sélectionnez une entreprise -- </option>
                            {this.state.companies.map((item, index) => 
                              <option key={index} value={item.id}> {item.company_name} </option>
                            )}
                          </select>
                          {this.state.companyIDEmpty &&
                            <div className="errorMsg">{this.state.errors.company_id}</div>
                          }
                        </div>
                        <div className="col-md-2 add_espace">
                          <span className="add_company_btn_container">
                            <button className="btn btn-success add_company_btn" data-toggle="tooltip" data-placement="top" title="Ajouter une entreprise">
                              <i className="fas fa-plus"></i>
                            </button>
                          </span>
                        </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-md-12 form_item">
                      <div className="form-group">
                        <label>Description de la mission</label>
                        <textarea placeholder="Description de la mission" name="offer_description" className="form-control input_item" onChange={this.addHandleChange} />
                        {this.state.descriptionEmpty &&
                          <div className="errorMsg">{this.state.errors.offer_description}</div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12 form_item">
                      <div className="form-group">
                        <label>Conditions (Compétences requises)</label>
                        <textarea placeholder="Compétences requises" name="offer_conditions" className="form-control input_item" onChange={this.addHandleChange} />
                        {this.state.conditionsEmpty &&
                          <div className="errorMsg">{this.state.errors.offer_conditions}</div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12 form_item">
                      <div className="form-group">
                        <label>Niveau du profil souhaité</label>
                        <select name="profile_level" className="form-control input_item" onChange={this.addHandleChange}>
                          <option> -- Sélectionnez un type de profil -- </option>
                          <option value="junior"> Junior </option>
                          <option value="intermédiaire"> Intermédiaire </option>
                          <option value="confirmé"> Confirmé </option>
                        </select>
                        {this.state.levelEmpty &&
                          <div className="errorMsg">{this.state.errors.profile_level}</div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12 form_item">
                      <div className="form-group">
                        <label>Budget de l'entreprise <b className="empty_notation"><i>Ce champ peut être vide</i></b></label>
                        <span className="input-group-addon" id="basic-addon-addd">FCFA</span>
                        <input placeholder="Budget de l'entreprise" type="text" name="company_budget" aria-describedby="basic-addon-addd" className="form-control input_item" onChange={this.addHandleChange} />
                      </div>
                    </div>
                    {this.state.addDirectPlacementRequestError &&
                      <div className="col-md-12 alert-container">
                        <div className="alert alert-danger error-alert-content">
                          Un problème est survenu lors de l'ajout. Veuillez réessayer plus tard.
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="row sq-btn-container">
                    <div className="col-md-12 sq-btn-col">
                      <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Quitter
                        </button>
                      {!this.state.addDirectPlacementRequestInProgress &&
                        <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.addNewDirectPlacementRequest(e)}>
                          <i className="fas fa-plus"></i>&nbsp;
                          Ajouter
                          </button>
                      }
                      {this.state.addDirectPlacementRequestInProgress &&
                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                          Ajout en cours &nbsp;
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> 

        {/* Ajouter une nouvelle demande de placement directe */}
        <div className="modal fade" id="directPlacementRequestModal" tabindex="-1" role="dialog" aria-labelledby="directPlacementRequestModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="directPlacementRequestModalLabel">Détails de la demande de {this.state.placementRequestInfos.companyName}</h4>
              </div>
                <div className="modal-body">
                    <div className="show-row">
                      <div className="alert alert-success volkeno-orange-bgcolor date_alerty">
                        Demande effectuée le <br /> {this.state.placementRequestInfos.frenchDateFormat}
                      </div>
                      <div className="infos_item_container">
                          <span className="item_label">Entreprise : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.companyName}</span>
                      </div> <hr />
                      <div className="infos_item_container">
                          <span className="item_label">Prénom et Nom du représentant : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.agentFullname}</span>
                      </div> <hr />
                    </div>
                    <div className="infos_item_container">
                        <span className="item_label">Email : </span> <br />
                        <span className="label_content">{this.state.placementRequestInfos.companyEmail}</span>
                    </div> <hr />
                    <div className="infos_item_container">
                          <span className="item_label">Entreprise : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.companyPhone}</span>
                    </div> <hr />
                    <div className="infos_item_container">
                          <span className="item_label">Description : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.offer_description}</span>
                    </div> <hr />
                    <div className="infos_item_container">
                          <span className="item_label">Conditions : </span> <br />
                          <span className="label_content">{this.state.placementRequestInfos.offer_conditions}</span>
                    </div> <hr />
                    <div className="infos_item_container">
                          <span className="item_label">Niveau demandé : </span> <br />
                          {this.state.placementRequestInfos.profile_level === 'junior' ?
                            <span className="label label-default label-green-bgcolor"> {this.state.placementRequestInfos.profile_level} </span>
                            :
                            this.state.placementRequestInfos.profile_level === 'intermédiaire' ?
                              <span className="label label-warning"> {this.state.placementRequestInfos.profile_level} </span> :
                              this.state.placementRequestInfos.profile_level === 'confirmé' ?
                                <span className="label label-success"> {this.state.placementRequestInfos.profile_level} </span> :
                                  ''
                          }
                    </div> <hr />
                    <div className="infos_item_container">
                      <span className="item_label">Budget de l'entreprise : </span> <br />
                      {this.state.placementRequestInfos.company_budget !== null ?
                        <span className="label_content">{this.state.placementRequestInfos.company_budget + ' FCFA'}</span>
                        :
                        <span className="label label-info"> L'entreprise n'a pas fourni de budget </span>
                      }
                    </div> <hr />
                </div>
                <div className="modal-footer">
                  <div className="row sq-btn-container">
                    <div className="col-md-12 sq-btn-col">
                      <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Quitter
                        </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        {/* Success add new direct placement request toast alert */}
        {this.state.addSuccessDPRAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              La demande a été bien enregistrée.
          </div>
        }
      </div>
    );
  }
}
