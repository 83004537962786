import React, { Component } from 'react';
import './gestionRdv.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import API from '../../variablesGlobales';

class gestionRdv extends Component {
  constructor(props) {
    super(props);
    this.getSelectedRdv = this.getSelectedRdv.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updatedRdvID = this.handleUpdateRdv.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.state = {
      rdvs: [],
      domaines: [],
      dataRdv: [],
      loading: true,
      api: API.API,
      fields: {},
      errors: {},
      rdvDate: '',
      rdvDateValue: '',
      rdvFirstnameValue: '',
      rdvLastnameValue: '',
      rdvEmailValue: '',
      rdvPhoneValue: '',
      rdvCommentaireValue: '',
      rdvHeureValue: '',
      updatedRdvID: 0,
      noteValue: '',
      rdvUpdateInprogress: false,
    }

  }
  componentDidMount() {
    this.getAllrdv();
  }

  getAllrdv() {
    axios.get(this.state.api + 'rdvs')
      .then(res => {
        let rdvs = res.data.data;
        this.setState({
          rdvs: rdvs.reverse(),
          loading: false
        });
      })

  }
  getSelectedRdv(x) {
    console.log(x);
    this.setState({
      SelectedRdv: x
    })
    return x.id
  }
  Detail(x) {
    console.log("details rencontre: ", x);
    this.setState({
      SelectedRdv: x,
      dataRdv: x
    })

  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (e.target.name === 'first_name') {
      this.setState({
        rdvFirstnameValue: e.target.value
      })
    }
    if (e.target.name === 'last_name') {
      this.setState({
        rdvLastnameValue: e.target.value
      })
    }
    if (e.target.name === 'email') {
      this.setState({
        rdvEmailValue: e.target.value
      })
    }
    if (e.target.name === 'phone') {
      this.setState({
        rdvPhoneValue: e.target.value
      })
    }
    if (e.target.name === 'commentaires') {
      this.setState({
        rdvCommentaireValue: e.target.value
      })
    }
    if (e.target.name === 'date_rdv') {
      this.setState({
        rdvDate: e.target.value
      })
    }
    if (e.target.name === 'heure') {
      this.setState({
        rdvHeureValue: e.target.value
      })
    }
    if (e.target.name === 'notes') {
      this.setState({
        noteValue: e.target.value
      })
    }

    if (!fields["first_name"]) {
      fields["first_name"] = this.state.rdvFirstnameValue;
    }
    if (!fields["last_name"]) {
      fields["last_name"] = this.state.rdvLastnameValue;
    }
    if (!fields["email"]) {
      fields["email"] = this.state.rdvEmailValue;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.rdvPhoneValue;
    }
    if (!fields["commentaires"]) {
      fields["commentaires"] = this.state.rdvCommentaireValue;
    }
    if (!fields["date_rdv"]) {
      fields["date_rdv"] = this.state.rdvDate;
    }
    if (!fields["heure"]) {
      fields["heure"] = this.state.rdvHeureValue;
    }
    if (!fields["notes"]) {
      fields["notes"] = this.state.noteValue;
    }

    fields["user_id"] = window.sessionStorage.getItem('user_id')
    this.setState({
      fields
    });

    console.log(fields);
  }

  // Edit RDV Modal
  getEditModal = (rdv) => {
    console.log('rdv infos ', rdv);
    this.setState({
      rdvDate: rdv.date_rdv,
      rdvDateValue: rdv.frenchDateFormat,
      dateWithoutTimeValue: rdv.dateWithoutTimeENG,
      rdvFirstnameValue: rdv.first_name,
      rdvLastnameValue: rdv.last_name,
      rdvEmailValue: rdv.email,
      rdvPhoneValue: rdv.phone,
      rdvCommentaireValue: rdv.commentaires,
      rdvHeureValue: rdv.heure,
      updatedRdvID: rdv.id,
      noteValue: rdv.notes,
    })

  }

  // Show and hide alert error
  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === 'first_name') {
        this.setState({
          fn_empty: false
        });
      }
      if (input === 'last_name') {
        this.setState({
          ln_empty: false
        });
      }
      if (input === 'email') {
        this.setState({
          email_empty: false
        });
      }
      if (input === 'phone') {
        this.setState({
          phone_empty: false
        });
      }
      // if(input === 'commentaires'){
      //     this.setState({
      //         tf_empty: false
      //     });
      // }



    }, 5000);
  }

  // Validate form input before sending data
  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["first_name"]) {
      formIsValid = false;
      this.setState({
        fn_empty: true
      })
      this.handleShowAndHideAlert("first_name");
      errors["first_name"] = "*Ce champ est obligatoire";
    }

    if (typeof fields["first_name"] !== "undefined") {
      if (!fields["first_name"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
        formIsValid = false;
        this.setState({
          fn_empty: true
        })
        this.handleShowAndHideAlert("first_name");
        errors["first_name"] = "*SVP que des lettres.";
      }
    }

    if (!fields["last_name"]) {
      formIsValid = false;
      this.setState({
        ln_empty: true
      })
      this.handleShowAndHideAlert("last_name");
      errors["last_name"] = "*Ce champ est obligatoire";
    }

    if (typeof fields["last_name"] !== "undefined") {
      if (!fields["last_name"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
        formIsValid = false;
        this.setState({
          ln_empty: true
        })
        this.handleShowAndHideAlert("last_name");
        errors["last_name"] = "*SVP que des lettres.";
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      this.setState({
        email_empty: true
      })
      this.handleShowAndHideAlert("email");
      errors["email"] = "*Ce champ est obligatoire.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        this.setState({
          email_empty: true
        })
        this.handleShowAndHideAlert("email");
        errors["email"] = "*Adresse email invalide.";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      this.setState({
        phone_empty: true
      })
      this.handleShowAndHideAlert("phone");
      errors["phone"] = "*Ce champ est obligatoire.";
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{9}$/)) {
        formIsValid = false;
        this.setState({
          phone_empty: true
        })
        this.handleShowAndHideAlert("phone");
        errors["phone"] = "*Numéro de telephone invalide.";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  // Submit update data
  handleUpdateRdv = (e) => {
    this.setState({
      rdvUpdateInprogress: true
    })
    e.preventDefault();
    if (this.validateForm()) {
      e.preventDefault();
      let fields = this.state.fields;

      if (!fields["first_name"]) {
        fields["first_name"] = this.state.rdvFirstnameValue;
      }
      if (!fields["last_name"]) {
        fields["last_name"] = this.state.rdvLastnameValue;
      }
      if (!fields["email"]) {
        fields["email"] = this.state.rdvEmailValue;
      }
      if (!fields["phone"]) {
        fields["phone"] = this.state.rdvPhoneValue;
      }
      if (!fields["commentaires"]) {
        fields["commentaires"] = this.state.rdvCommentaireValue;
      }
      if (!fields["date_rdv"]) {
        fields["date_rdv"] = this.state.rdvDate;
      }
      if (!fields["heure"]) {
        fields["heure"] = this.state.rdvHeureValue;
      }
      if (!fields["notes"]) {
        fields["notes"] = this.state.noteValue;
      }

      this.setState({ fields: fields });
      const dataRdv = this.state.fields;
      console.log(dataRdv);
      axios.put(this.state.api + 'rdvs/' + this.state.updatedRdvID, dataRdv)
        .then(res => {
          this.setState({
            rdvUpdateInprogress: false
          })
          console.log(res.data);
          if (res.data.success === true) {
            window.location = 'gestion-des-rendez-vous';
          }
        })

    }
  }

  // Archiver un rdv
  handleArchivedRdv = (rdv_id) => {

    axios.post(this.state.api + 'archived-rdv/' + rdv_id)
      .then(response => {
        console.log(response.data);
        if (response.data.success === true) {
          console.log('success archived');
          window.location = 'gestion-des-rendez-vous'
        } else {
          alert('Erreur survenu lors de l\'archivage');
        }

      })
  }

  // Valider un RDV
  handleCompletedRdv = (rdv_id) => {
    this.handleArchivedRdv(rdv_id);
    axios.post(this.state.api + 'update-completed-rdv/' + rdv_id)
      .then(response => {
        console.log(response.data);
        if (response.data.success === true) {
          console.log('success validated');
          window.location = 'gestion-des-rendez-vous'
        } else {
          alert('Erreur survenu lors de la validation');
        }

      })
  }

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#moModal" onClick={(e) => this.Detail(row, e)}>
          <i className="fas fa-eye"></i>
        </button>
        <button type="button" className="btn btn-warning" id="btn_edit" data-toggle="modal" data-target="#editModal" onClick={(e) => this.getEditModal(row, e)}>
          <i className="fas fa-edit"></i>
        </button>
        <button type="button" className="btn btn-danger" id="btn_archived"
          onClick={e => window.confirm("Êtes-vous sûr de vouloir archiver ce rendez-vous ?") &&
            this.handleArchivedRdv(cell)
          }>
          <i className="fas fa-archive"></i>
        </button>
        <button data-toggle="tooltip" data-placement="top" title="Valider le RDV" type="button" className="btn btn-info volkeno-green-bcolor" id="btn_completed"
          onClick={e => window.confirm("Êtes-vous sûr de vouloir valider ce rendez-vous ?") &&
            this.handleCompletedRdv(cell)
          }>
          <i className="fas fa-check-double"></i>
        </button>
      </div>
    );
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  rowClassNameFormat = (row, rowIdx) => {
    return row.isToday === true ? 'todayClass' : '';
  }


  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton
    };

    if (this.state.loading) {
      return (
        <div className="component-gestionRdv">
          <Menu />
          <div className="Addrdv row" >
            <div className="col-lg-12">
              <h1 className="page-header">
                <span className="page-title">Liste des render-vous</span>
                <div className="pull-right">
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard-admin">Accueil</Link>
                    </li>
                    <li className="active">Liste des render-vous</li>
                  </ol>
                </div>
              </h1>
            </div>

            <div className="col-md-12 reporting-container">
              <div className="row add_bakeliste_container">
                <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                  <Link data-toggle="tooltip" data-placement="top" title="Ajouter un Rendez-vous" to="/ajouter-un-rendez-vous" className="btn btn-success add_coach_btn button">
                    <i className="fas fa-plus-circle"></i>
                  </Link>
                  &nbsp;&nbsp;
                  <Link data-toggle="tooltip" data-placement="top" title="Voir la liste des rdv archivés" to="/rdv-archiver-valider" className="btn btn-success avliste_btn button">
                    <i className="fas fa-plus-circle"></i>
                  </Link>
                </div>
              </div>
              <div className="list_bakeliste_container">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Prénom et Nom</th>
                        <th scope="col">Email</th>
                        <th scope="col">Date et Heure </th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>

      )
    }


    return (
      <div>
        <Menu />
        <div className="row Listrdvs" id="assignBody">
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">Liste des rendez-vous</span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard-admin">Accueil</Link>
                  </li>
                  <li className="active">Liste des rendez-vous</li>
                </ol>
              </div>
            </h1>
          </div>
          <div className="col-md-12 reporting-container">
            <div className="row add_prospect_container">
              <div className="col-md-6 col-md-offset-3 add-prospect-btn-container">
                <Link data-toggle="tooltip" data-placement="top" title="Ajouter un rendez-vous" to="/ajouter-un-rendez-vous" className="btn btn-success add_coach_btn button button addBtn">
                  <i className="fas fa-plus-circle"></i>
                </Link>
                &nbsp;&nbsp;
                <Link data-toggle="tooltip" data-placement="top" title="Voir la liste des rdv archivés" to="/rdv-archiver-valider" className="btn btn-danger avliste_btn button">
                  <i className="fas fa-eye"></i>
                </Link>
              </div>
            </div>
            <div className="list_rdvs_container">
              <div className="table-responsive rdv-table">
                <BootstrapTable data={this.state.rdvs}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  pagination
                  search={true} trClassName={this.rowClassNameFormat}>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='userFullname' >Nom complet</TableHeaderColumn>
                  <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
                  <TableHeaderColumn dataField='frenchDateFormat'>Date et Heure</TableHeaderColumn>
                  <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                </BootstrapTable>
                

                <div className="modal fade" id="moModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="modal-title" id="myModalLabel">Detail sur {this.state.dataRdv.first_name}&nbsp;{this.state.dataRdv.last_name}</h4>
                      </div>
                      <div className="modal-body">
                      {this.state.dataRdv.notes !== null ?
                          <div className="email_container notes-admin">
                            <span className="item_label">Notes : </span> <br />
                            <span className="label_content"> {this.state.dataRdv.notes}</span>
                          </div>
                          :
                          <div className="email_container">
                            <span className="item_label">Notes : </span> <br />
                            <span className="label_content badge">Aucune note pour le moment</span>
                          </div>
                        }
                        <hr />
                        <div className="email_container">
                          <span className="item_label">Email : </span> <span className="label_content">{this.state.dataRdv.email}</span>
                        </div> <hr />

                        <div className="email_container">
                          <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.dataRdv.phone}</span>
                        </div> <hr />

                        <div className="email_container">
                          <span className="item_label">Commentaire : </span> <span className="label_content">{this.state.dataRdv.commentaires}</span>
                        </div> <hr />
                        <div className="email_container">
                          <span className="item_label">Date du rvd : </span> <span className="label_content">{this.state.dataRdv.date_rdv_fr}</span>
                        </div> <hr />
                        <div className="email_container">
                          <span className="item_label">Heure du rdv : </span> <span className="label_content"> {this.state.dataRdv.heure}H</span>
                        </div> <hr />
                        <div className="modal-footer">

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Edit Modal */}
                <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="modal-title" id="myModalLabel2">Modifier un RDV</h4>
                      </div>
                      <div className="modal-body">

                        <form onSubmit={this.handleUpdateRdv}>
                          <div className="form-row row">
                            <div className="col-md-6 bloc_container">
                              <div className="form-group ">
                                <label >Prénom:</label>
                                <input type="text" className="form-control" id="inputpFirstName" placeholder="Prénom" name="first_name" value={this.state.rdvFirstnameValue} onChange={this.handleChange} />
                              </div>
                              {this.state.fn_empty &&
                                <div className="errorMsg">{this.state.errors.firstName}</div>
                              }
                            </div>
                            <div className="col-md-6 bloc_container">
                              <div className="form-group ">
                                <label >Nom:</label>
                                <input type="text" className="form-control" id="inputLastName" placeholder="Nom" name="last_name" value={this.state.rdvLastnameValue} onChange={this.handleChange} />
                              </div>
                              {this.state.ln_empty &&
                                <div className="errorMsg">{this.state.errors.lastName}</div>
                              }
                            </div>
                            <div className="col-md-6 bloc_container">
                              <div className="form-group " >
                                <label >Email:</label>
                                <input type="Email" className="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.rdvEmailValue} onChange={this.handleChange} />
                              </div>
                              {this.state.email_empty &&
                                <div className="errorMsg">{this.state.errors.email}</div>
                              }
                            </div>
                            <div className="col-md-6 bloc_container">
                              <div className="form-group ">
                                <label >Téléphone:</label>
                                <input type="text" className="form-control" id="inputNumero" placeholder="Téléphone" name="phone" value={this.state.rdvPhoneValue} onChange={this.handleChange} />
                              </div>
                              {this.state.phone_empty &&
                                <div className="errorMsg">{this.state.errors.phone}</div>
                              }
                            </div>
                            <div className="col-md-6 bloc_container">
                              <div className="form-group ">
                                <label >Commentaires:</label>
                                <textarea className="form-control" id="commentaireInput" placeholder="Vos commentaires" name="commentaires" value={this.state.rdvCommentaireValue} onChange={this.handleChange} />
                              </div>
                            </div>
                            <div className="col-md-6 bloc_container">
                              <div className="form-group notes-admin">
                                <label >Notes:</label>
                                <textarea className="form-control" id="notesInput" placeholder="Vos notes" value={this.state.noteValue} name="notes" onChange={this.handleChange} />
                              </div>
                            </div>
                            <div className="col-md-6 bloc_container">
                              <div className="form-group ">
                                <label >Date:</label>
                                <input type="date" className="form-control" id="inputpFirstName" placeholder="commentaire" name="date_rdv" value={this.state.dateWithoutTimeValue} onChange={this.handleChange} />
                              </div>
                            </div>
                            <div className="col-md-6 bloc_container">
                              <div className="form-group ">
                                <label >Heure:</label>
                                <input type="integer" className="form-control" id="inputpFirstName" placeholder="commentaire" name="heure" value={this.state.rdvHeureValue} onChange={this.handleChange} />
                              </div>
                            </div>
                          </div>
                          <div className="form-group" >
                            {!this.state.rdvUpdateInprogress ?
                              <button type="submit" className='btn btn-warning addBtn button submit'>
                                <i className="fas fa-plus-circle"></i> &nbsp;
                                Modifier
                                  </button>
                              :
                              <button type="button" className='btn btn-warning addBtn button submit'>
                                <i className="fas fa-spin fa-spinner"></i> &nbsp;
                                Modification en cours
                                </button>
                            }

                          </div>

                        </form>

                        <div className="modal-footer">

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Edit Modal */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default gestionRdv;
