import React, { Component } from 'react';
import './ListeBakelisteByTrainingPeriod.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
//import Menu from '../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import API from '../../variablesGlobales';
//import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import 'admin-lte/dist/js/adminlte';

export default class ListeBakelisteByTrainingPeriod extends Component {
  constructor(props) {
      super(props);
      this.state = {
        api: API.API,
        trainingPeriodListInProgress: false,
        dataBakelisteZeroMonth: [],
        dataBakelisteZeroMonthCount: 0,
        dataBakelisteOneMonth: [],
        dataBakelisteOneMonthCount: 0,
        dataBakelisteTwoMonth: [],
        dataBakelisteTwoMonthCount: 0,
        dataBakelisteThreeMonth: [],
        dataBakelisteThreeMonthCount: 0,
        dataBakelisteFourMonth: [],
        dataBakelisteFourMonthCount: 0,
        dataBakelisteFiveMonth: [],
        dataBakelisteFiveMonthCount: 0,
        dataBakelisteSixMonth: [],
        dataBakelisteSixMonthCount: 0,
        dataBakelisteMoreThanSixMonth: [],
        dataBakelisteMoreThanSixMonthCount: 0,
      };
  }

  componentDidMount(){
    this.onGetBakelisteByTrainingPeriod();
    this.getAllActivesCoach();
  }

  /**
   * 
   */
  getAllActivesCoach = () => {
    axios.get(this.state.api + 'liste-des-coachs')
        .then(res => {
            const coachsData = res.data;
            this.setState({
                coachs: coachsData.data,
            });
            console.log("coach list: ", this.state.coachs)
        })
  }

  /**
   * Liste des bakelistes par période de formation
   */
  onGetBakelisteByTrainingPeriod = () => {
    this.setState({
        trainingPeriodListInProgress: true,
    })
    axios.get(this.state.api + 'bakeliste-filter-by-training-period')
        .then(response => {
            if(response.data.success){
                this.setState({
                    trainingPeriodListInProgress: false,
                    dataBakelisteZeroMonth:  response.data.dataZeroMonth,
                    dataBakelisteZeroMonthCount: response.data.dataZeroMonthCount,
                    dataBakelisteOneMonth: response.data.dataOneMonth,
                    dataBakelisteOneMonthCount: response.data.dataOneMonthCount,
                    dataBakelisteTwoMonth: response.data.dataTwoMonth,
                    dataBakelisteTwoMonthCount: response.data.dataTwoMonthCount,
                    dataBakelisteThreeMonth: response.data.dataThreeMonth,
                    dataBakelisteThreeMonthCount: response.data.dataThreeMonthCount,
                    dataBakelisteFourMonth: response.data.dataFourMonth,
                    dataBakelisteFourMonthCount: response.data.dataFourMonthCount,
                    dataBakelisteFiveMonth: response.data.dataFiveMonth,
                    dataBakelisteFiveMonthCount: response.data.dataFiveMonthCount,
                    dataBakelisteSixMonth: response.data.dataSixMonth,
                    dataBakelisteSixMonthCount: response.data.dataSixMonthCount,
                    dataBakelisteMoreThanSixMonth: response.data.dataMoreThanSixMonth,
                    dataBakelisteMoreThanSixMonthCount: response.data.dataMoreThanSixMonthCount,
                })
            }else{
                this.setState({
                    trainingPeriodListInProgress: false
                })
                console.log(response)
            }
        })
        .catch(error => {
            this.setState({
                trainingPeriodListInProgress: false
            })
            console.log(error.message)
        })
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        { props.exportCSVBtn }
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  getBakelisteInfos = (bakeliste) => {
    this.setState({
      bakelisteInfos: bakeliste
    })
  }

  bakelisteFullnameFormatter = (cell, row) => {
    return (
        <span className="fullname-link" data-toggle="modal" data-target="#showInfosModal" onClick={(e) => this.getBakelisteInfos(row, e)}>{cell}</span>
    );
  }

  getSelectedBakelist(x) {
    this.getAllActivesCoach();
    this.setState({
        selectedBakelist: x
    })
    return x.id
  }

  coachRowFormatter = (cell, row) => {
    return (
        <div className="coach_row actions-btn-container">
            {row.coach_id ?
                <Link to="#">
                    { cell }
                </Link> :
                <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#assignationModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
                    <i className="fas fa-user-check"></i>
                </button>

            }
        </div>
    );
  }

  /**
   * 
   */
  typeFormationFormatter = (cell, row) => {
    if(cell === 'courte'){
        return(
            <span className="label label-success volkeno-green-bcolor"> formation courte </span>
        );
    }else if(cell === 'diplomante'){
        return(
            <span className="label label-warning volkeno-orange-bcolor"> formation diplômante </span>
        );
    }
  }

  /**
   * Immersion notification
   */
  rowClassNameFormat = (row, rowIdx) => {
    if(row.in_immersion === 1 && row.trainingIsCompleted === 1){
        return 'bakeliste_in_immersion is_completed_training';
    }
    if(row.in_immersion === 1 && row.trainingIsCompleted === 0){
        return 'bakeliste_in_immersion';
    }
    if(row.in_immersion === 0 && row.trainingIsCompleted === 1){
        return 'is_completed_training';
    }
  }

  /**
     * Training since formatter
     */
    inTrainingSinceFormatter = (cell, row) => {
      if(cell < 1){
          return(
              <span className="label label-success volkeno-green-bcolor"> {row.inTrainingSinceDays} jour(s) </span>
          )
      }else{
          return(
              <span className="label label-success volkeno-orange-bgcolor"> {row.inTrainingSinceMonths} mois </span>
          )
      }
  }


  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };

    return (
      <div className="component-liste-bakeliste-by-training-period col-md-12">
        <div className="col-md-12">
          <div className="box">
            <div className="box-header with-border  volkeno-green-bcolor widget-header">
              <h3 className="box-title">Liste des bakeliste par période de formation</h3>
              <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" data-widget="collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">
              <div className="header-container-training">
                <div className="row da-row">
                  <div className="nav-tabs-custom">
                    <div className="col-md-12 da-list-col">
                      <ul className="nav nav-tabs">
                        <li className="active"><a href="#zeromonth" data-toggle="tab" aria-expanded="true">Zéro mois <span className="label label-default volkeno-green-label"> {this.state.dataBakelisteZeroMonthCount} </span> </a></li>
                        <li className=""><a href="#onemonth" data-toggle="tab" aria-expanded="false">Un mois <span className="label label-default volkeno-green-label"> {this.state.dataBakelisteOneMonthCount} </span> </a></li>
                        <li className=""><a href="#twomonths" data-toggle="tab" aria-expanded="false">Deux mois <span className="label label-default volkeno-green-label"> {this.state.dataBakelisteTwoMonthCount} </span> </a></li>
                        <li className=""><a href="#threemonths" data-toggle="tab" aria-expanded="false">Trois mois <span className="label label-default volkeno-green-label"> {this.state.dataBakelisteThreeMonthCount} </span> </a></li>
                        <li className=""><a href="#fourmonths" data-toggle="tab" aria-expanded="false">Quatre mois <span className="label label-default volkeno-green-label"> {this.state.dataBakelisteFourMonthCount} </span> </a></li>
                        <li className=""><a href="#fivemonths" data-toggle="tab" aria-expanded="false">Cinq mois <span className="label label-default volkeno-green-label"> {this.state.dataBakelisteFiveMonthCount} </span> </a></li>
                        <li className=""><a href="#sixmonths" data-toggle="tab" aria-expanded="false">Six mois <span className="label label-default volkeno-green-label"> {this.state.dataBakelisteSixMonthCount} </span> </a></li>
                        <li className=""><a href="#morethansixmonths" data-toggle="tab" aria-expanded="false">+ de Six mois <span className="label label-default volkeno-green-label"> {this.state.dataBakelisteMoreThanSixMonthCount} </span> </a></li>
                        {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                      </ul>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane active" id="zeromonth">
                        <div className="row delay-table-row">
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            <BootstrapTable data={this.state.dataBakelisteZeroMonth}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}
                                trClassName={this.rowClassNameFormat}>

                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='type_formation'
                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="onemonth">
                        <div className="row delay-table-row">
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            <BootstrapTable data={this.state.dataBakelisteOneMonth}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}
                                trClassName={this.rowClassNameFormat}>

                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='type_formation'
                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="twomonths">
                        <div className="row delay-table-row">
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            <BootstrapTable data={this.state.dataBakelisteTwoMonth}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}
                                trClassName={this.rowClassNameFormat}>

                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='type_formation'
                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="threemonths">
                        <div className="row delay-table-row">
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            <BootstrapTable data={this.state.dataBakelisteThreeMonth}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}
                                trClassName={this.rowClassNameFormat}>

                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='type_formation'
                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="fourmonths">
                        <div className="row delay-table-row">
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            <BootstrapTable data={this.state.dataBakelisteFourMonth}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}
                                trClassName={this.rowClassNameFormat}>

                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='type_formation'
                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="fivemonths">
                        <div className="row delay-table-row">
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            <BootstrapTable data={this.state.dataBakelisteFiveMonth}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}
                                trClassName={this.rowClassNameFormat}>

                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='type_formation'
                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="sixmonths">
                        <div className="row delay-table-row">
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            <BootstrapTable data={this.state.dataBakelisteSixMonth}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}
                                trClassName={this.rowClassNameFormat}>

                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='type_formation'
                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="morethansixmonths">
                        <div className="row delay-table-row">
                          <div className="col-md-12 delay-table-col">
                            {/* TODO */}
                            <BootstrapTable data={this.state.dataBakelisteMoreThanSixMonth}
                                striped={true} hover={true} condensed={true}
                                multiColumnSort={2} options={options}
                                exportCSV
                                pagination
                                search={true}
                                trClassName={this.rowClassNameFormat}>

                                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='bakelisteFullname'
                                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true} >Nom complet</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='email'
                                >Email</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='phone'
                                >Téléphone</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='domaine'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Domaine</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='coachFullname'
                                    dataFormat={this.coachRowFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Coach</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='type_formation'
                                    dataFormat={this.typeFormationFormatter.bind(this)}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Type de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='200'
                                    dataField='lieu_formation'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Espace de formation</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='250'
                                    dataField='ecole'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Ecole</TableHeaderColumn>
                                <TableHeaderColumn dataAlign="center"
                                    width='150'
                                    dataField='horaire'
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>Horaire</TableHeaderColumn>
                                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End training period */}
      </div>
    );
  }
}