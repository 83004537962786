import React, {Component} from 'react';
import './listeBakelistesImmersion.css';
import { Link , NavLink} from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
//import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';

export default class listeBakelistesImmersion extends Component {
    constructor(props) {
      let token = window.sessionStorage.getItem('token');
      let userStatus = window.sessionStorage.getItem('user_status');

      if (!token) {
          window.history.back()
      }
      if (userStatus === 'bakeliste' || userStatus === 'coach') {
          window.history.back();
      }

        super(props);
        this.state = {
          isPageLoading: false,
          api: API.API,
          bakelistes: [],
          bakelisteInfos: [],
          completedInProgress: false,
          archivedActionSuccessAlert: false,
          archivedActionErrorAlert: false,

        };
    }

    componentDidMount = () => {
      this.onGetBakelistesImmersion();
    }

    componentDidCatch(err, info){
      Sentry.captureException(err);
    }

    onGetBakelistesImmersion = () => {
      this.setState({
        isPageLoading: true
      })
      axios.get(this.state.api + 'liste-bakelistes-en-immersion')
        .then(response => {
          if(response.data.success){
            this.setState({
              isPageLoading: false,
              bakelistes: response.data.data
            })
          }else{
            this.setState({
              isPageLoading: false
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false
          })
          if (process.env.NODE_ENV !== 'production') {
            return;
          }
          Sentry.captureException(error);
        })
    }

    bakelisteFullnameFormatter = (cell, row) => {
      return (
          <span className="fullname-link" data-toggle="modal" data-target="#bakelisteInfosModal" onClick={(e) => this.getBakelisteInfos(row, e)}>{cell}</span>
      );
    }

    handleShowAndHideAlert(label) {
      setTimeout(() => {
        if (label === 'archivedActionSuccessAlert') {
          this.setState({
            archivedActionSuccessAlert: false
          });
        }
        if (label === 'archivedActionErrorAlert') {
          this.setState({
            archivedActionErrorAlert: false
          });
        }
      }, 5000);
    }

    getBakelisteInfos = (bakeliste) => {
      this.setState({
        bakelisteInfos: bakeliste
      })
    }

    handleCompletedImmersion = (bakeliste) => {
      this.setState({
        completedInProgress: true
      })
      let bakelisteID = bakeliste.id;
      console.log(bakelisteID)
      axios.post(this.state.api + 'archived-immersion/' + bakelisteID)
        .then(response => {
            this.onGetBakelistesImmersion();
            this.setState({
              completedInProgress: false,
              archivedActionSuccessAlert: true
            })
            this.handleShowAndHideAlert('archivedActionSuccessAlert')
        })
        .catch(error => {
          //this.onGetBakelistesImmersion();
          console.log(error)
          this.setState({
            completedInProgress: false,
            archivedActionErrorAlert: true
          })
          this.handleShowAndHideAlert('archivedActionErrorAlert')

          if (process.env.NODE_ENV !== 'production') {
            return;
          }
          Sentry.captureException(error);
        })
    }

    actionsFormatter = (cell, row) => {
      return (
          <div className="actions-btn-container">
              <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#bakelisteInfosModal" onClick={(e) => this.getBakelisteInfos(row)}>
            <i class="fas fa-eye"></i>
          </button> &nbsp;
              <button data-toggle="tooltip" data-placement="top" title="Terminer l'immersion" type="button" className="btn btn-danger terminer-immersion" id="completed"
                  onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter l'immersion de ce bakeliste ?") &&
                      this.handleCompletedImmersion(row)}
              >
                  {this.state.completedInProgress ?
                      <i className="fas fa-spin fa-spinner"></i> :
                      <i class="fas fa-stop-circle"></i>
                  }
              </button>
          </div>
      );
    }

    rowClassNameFormat = () => {

    }

    createCustomButtonGroup = props => {
      return (
        <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
          { props.exportCSVBtn }
          {/* <button type='button'
            className={ `btn btn-primary volkeno-red-bcolor` }>
            MyCustomBtn
          </button> */}
        </ButtonGroup>
      );
    }

    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    coachRowFormatter = (cell, row) => {
      return (
          <div className="coach_row actions-btn-container">
              {row.coach_id ?
                  <Link to="#">
                      { cell }
                  </Link> :
                  <span className="empty-coach label label-warning"> non assigné </span>

              }
          </div>
      );
    }

    render() {
      const options = {
        clearSearch: true,
        clearSearchBtn: this.createCustomClearButton,
        btnGroup: this.createCustomButtonGroup
      };

      if (this.state.isPageLoading) {
        return (
          <div className="component-liste-bakelistes-immersion">
            <Menu />
            {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                    Aprés avoir intégré AdminLTE */}
            <div className="row no-content-wrapper">
  
              <section className="content-header">
                <h1>
                  Bakelistes en immersion
                </h1>
                <ol class="breadcrumb">
                  <li><Link to="/dashboard-admin">Accueil</Link></li>
                  <li className="active">Bakelistes en immersion</li>
                </ol>
              </section>
  
              <section className="content">
                <div className="immersion_loading_container">
                  <div className="table_container">
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Prénom et Nom</th>
                          <th scope="col">Entreprise hôte</th>
                          <th scope="col">Date de début</th>
                          <th scope="col">Date de fin</th>
                        </tr>
                      </thead>
                      <tbody>
                        <div id="loader"></div>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
  
            </div>
          </div>
        );
      }
      return(
        <div className="component-liste-bakelistes-immersion">
          <Menu />
            {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                    Aprés avoir intégré AdminLTE */}
            <div className="row no-content-wrapper">
  
              <section className="content-header">
                <h1>
                  Bakelistes en immersion
                </h1>
                <ol class="breadcrumb">
                  <li><Link to="/dashboard-admin">Accueil</Link></li>
                  <li className="active">Bakelistes en immersion</li>
                </ol>
              </section>
                   
          <section className="content">
            <div className="liste_immersion_table_container">
              <BootstrapTable data={this.state.bakelistes}
                striped={true} hover={true} condensed={true}
                multiColumnSort={2} options={options}
                exportCSV
                pagination
                search={true}
                trClassName={this.rowClassNameFormat}>

                <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                    width='300'
                    dataField='bakelisteFullname'
                    dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true} >Nom complet</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                    width='250'
                    dataField='companyName'
                >Entreprise</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                    width='250'
                    dataField='companyEmail'
                >Email</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                    width='200'
                    dataField='companyPhone'
                >Téléphone</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                    width='300'
                    dataField='domaine'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Domaine</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                    width='300'
                    dataField='immersionStartFrenchDate'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Début immersion</TableHeaderColumn>
                  <TableHeaderColumn dataAlign="center"
                    width='300'
                    dataField='immersionEndFrenchDate'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Fin immersion</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                    width='250'
                    dataField='coachFullname'
                    dataFormat={this.coachRowFormatter.bind(this)}
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Coach</TableHeaderColumn>
                {/* <TableHeaderColumn dataAlign="center"
                    width='250'
                    dataField='type_formation'
                    dataFormat={this.typeFormationFormatter.bind(this)}
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Type de formation</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataAlign="center"
                    width='200'
                    dataField='lieu_formation'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Espace de formation</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataAlign="center"
                    width='250'
                    dataField='ecole'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Ecole</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataAlign="center"
                    width='150'
                    dataField='horaire'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Horaire</TableHeaderColumn> */}
                <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
              </BootstrapTable>
            </div>      
          </section>
          </div>

          <div className="modal fade" id="bakelisteInfosModal" tabindex="-1" role="dialog" aria-labelledby="bakelisteInfosModalLabel">
              <div className="modal-dialog" role="document">
                  <div className="modal-content">
                      <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                          <h4 className="modal-title" id="bakelisteInfosModal">Detail sur {this.state.bakelisteInfos.first_name}&nbsp;{this.state.bakelisteInfos.last_name}</h4>
                      </div>
                      <div className="modal-body">
                          {this.state.bakelisteInfos.type_formation === 'courte' ? (
                              this.state.bakelisteInfos.nbreJours === '3' ? (
                                  <div className="content_container">
                                      <b>Jours de présences : </b> <br />
                                      <span> {this.state.bakelisteInfos.jour1}, {this.state.bakelisteInfos.jour2}, {this.state.bakelisteInfos.jour3} </span> <br />
                                      <b>Heure de présence</b> <br />
                                      <span className="horaire-bakeliste-item"> {this.state.bakelisteInfos.horaire} </span>

                                  </div>
                              ) :
                                  this.state.bakelisteInfos.nbreJours === '5' ? (
                                      <div className="content_container">
                                          <b>Jours de présences : </b> <br />
                                          <span> {this.state.bakelisteInfos.jour1}, {this.state.bakelisteInfos.jour2}, {this.state.bakelisteInfos.jour3}, {this.state.bakelisteInfos.jour4}, {this.state.bakelisteInfos.jour5} </span> <br />
                                          <b>Heure de présence</b> <br />
                                          <span className="horaire-bakeliste-item"> {this.state.bakelisteInfos.horaire} </span>

                                      </div>
                                  ) :
                                      this.state.bakelisteInfos.nbreJours === '6' && (
                                          <div className="content_container">
                                              <b>Jours de présences : </b> <br />
                                              <span> {this.state.bakelisteInfos.jour1}, {this.state.bakelisteInfos.jour2}, {this.state.bakelisteInfos.jour3}, {this.state.bakelisteInfos.jour4}, {this.state.bakelisteInfos.jour5}, {this.state.bakelisteInfos.jour6} </span> <br />
                                              <b>Heure de présence</b> <br />
                                              <span className="horaire-bakeliste-item"> {this.state.bakelisteInfos.horaire} </span>
                                          </div>
                                      )
                          ) :
                              this.state.bakelisteInfos.type_formation === 'diplomante' && (
                                  <div className="content_container">
                                      <b>Jours de présences : </b> <br />
                                      <span> Du Lundi au Vendredi </span> <br />
                                      <b>Heure de présence</b> <br />
                                      <span className="horaire-bakeliste-item"> {this.state.bakelisteInfos.horaire} </span>

                                  </div>
                              )
                          }
                          <div className="email_container">
                              <span className="item_label">Email : </span> <span className="label_content">{this.state.bakelisteInfos.email}</span>
                          </div> <hr />
                          <div className="objectif_container">
                              <span className="item_label">Entreprise hôte : </span> <span className="label_content">{this.state.bakelisteInfos.companyName}</span>
                          </div> <hr />

                          {/* {this.state.bakelisteInfos.ecole !== '' &&
                              <div className="ecole_container">
                                  <span className="item_label">Ecole : </span> <span className="label_content">{this.state.bakelisteInfos.ecole}</span> <hr />
                              </div>

                          } */}
                          <div className="email_container">
                              <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.bakelisteInfos.phone}</span>
                          </div> <hr />
                          {/* {this.state.bakelisteInfos.boursier === 1 ?
                              <div className="ecole_container">
                                  <span className="item_label">Boursier : </span> <span className="label_content">OUI</span> <hr />
                              </div> :
                              <div className="ecole_container">
                                  <span className="item_label">Boursier : </span> <span className="label_content">NON</span> <hr />
                              </div>

                          } */}
                          {/* <div className="email_container">
                              <span className="item_label">Lieu de formation : </span> <span className="label_content">{this.state.bakelisteInfos.lieu_formation}</span>
                          </div> <hr /> */}
                          <div className="email_container">
                              <span className="item_label">Type de formation : </span> <span className="label_content">{this.state.bakelisteInfos.type_formation}</span>
                          </div> <hr />
                          {/* {this.state.bakelisteInfos.paiement_inscription === 1 &&
                              <div className="ecole_container">
                                  <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">OUI</span> <hr />
                              </div>

                          } */}
                          {/* {this.state.bakelisteInfos.paiement_inscription === 0 &&
                              <div className="ecole_container">
                                  <span className="item_label">Paiement à l'inscription : </span> <span className="label_content">NON</span> <hr />
                              </div>

                          } */}
                          <div className="email_container">
                              <span className="item_label">Date de début de l'immersion : </span> <span className="label_content">{this.state.bakelisteInfos.immersionStartFrenchDate}</span>
                          </div> <hr />
                          <div className="email_container">
                              <span className="item_label">Date de fin de l'immersion : </span> <span className="label_content">{this.state.bakelisteInfos.immersionEndFrenchDate}</span>
                          </div> <hr />
                      </div>
                      <div className="modal-footer">

                      </div>
                  </div>
              </div>
          </div>

          {/* Success completed immersion toast alert */}
          {this.state.archivedActionSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
              <i class="fas fa-check-double"></i> &nbsp;&nbsp;
              L'arrêt de l'immersion a été effectué avec succès.
            </div>
          }
          {/* Error completed immersion toast alert */}
          {this.state.archivedActionErrorAlert === true &&
            <div className="alert alert-danger text-center upsuccess-alert">
              <i class="fas fa-check-double"></i> &nbsp;&nbsp;
              L'arrêt de l'immersion a échoué.
            </div>
          }
        </div>
      );
    }
  }