import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './detailsMessage.scss'
import API from '../../variablesGlobales';
import axios from 'axios';
import Menu from './../menu/Menu';
import {Item} from 'semantic-ui-react';
import TimeAgo from 'timeago-react';
export default class detailsMessage extends Component {
    constructor(props) {
        super(props);
         this.state = {
           message:{},
           api: API.API,
         };
     }

     componentDidMount(){
       let id = this.props.match.params.id ;
       console.log(id)
       this.getMessageById(id)
     }
      
      getMessageById = (id) => {
        axios.get(this.state.api + `show-message-by-id/${id}`)
            .then(res => {
              console.log(res.data.success)
                if (res.data.success === true) {
                    this.setState({
                        mssages: res.data.data,
                    });
                    console.log(this.state.message)
                } 
            })
            .catch(error => {
                console.log(error.message)  
            })
      }




    render() {
       let {message} = this.state;
      return(
      <div className="component-manage-users">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Messages
              </h1>
              <ol className="breadcrumb">
                <li><Link to="/dashboard-admin">Accueil</Link></li>
                <li className="active">Message</li>
              </ol>
            </section>
            <Item.Group className='item-group-message'>
                <Item className='item-liste-message'>
                <Item.Image size='small' src={require('./../../images/wagaan_head.png')} alt="Avatar" className="bp_avatar_img-user" />
                <Item.Content>
                  <Item.Header as='a' id='header-list-message'>
                    {message.first_name}&nbsp;&nbsp;{message.last_name}</Item.Header>
                    <p className='email-message'>
                    <small>
                         <TimeAgo
                             datetime={message.created_at}
                              locale='vi'
                              />
                      </small>
                    </p>
                  <Item.Description>
                    <p className='listMessanger'>{message.contenu}</p>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>
        </div>
      );
    }
  }
