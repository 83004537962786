import React, { Component } from 'react';
import './contenu.css' ;
import Menu from '../menu/Menu';
import { NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
// import ReadMoreReact from 'read-more-react';

export default class Programmation extends Component {
constructor(props){
    super(props);
    this.state = {
		api: API.API,
		domaines:[],
		bakelisteDomaine:window.sessionStorage.getItem('user_domaine'),
		addTextualContentSuccessAlert: false,
          textualContentInProgress: false,
          textualContents: [],
          textualContentsFilter: [],
          emptyContent: false,
    }
}
componentDidMount(){
  // const { match: { params } } = this.props;
  let id = this.props.match.params.id;
console.log(id,'id')
    this.onGetTextualContentsByDomaine(id);
    
}
getAllDomaines = () => {
    axios.get(this.state.api + 'domaines')
            .then(res => {
                if(res.data.success){
                    const domains = res.data;
                    this.setState({
                        domaines: domains.data,
                    });
				   
}
                else{
                    console.log(res)
                }
            })
            .catch(error => {
                console.log(error.message)
            })
            
}
onGetTextualContentsByDomaine = (bakelisteDomaine) => {
	this.setState({
	  textualContentInProgress: true
	})
	
	axios.get(this.state.api + 'textual-contents-by-domaine/' + bakelisteDomaine)
	  .then(response => {
		this.setState({
		  textualContentInProgress: false
		})

		if(response.data.success){
      if(response.data.data.length === 0){
        // 
        console.log('cool')
      }
      else{
		  this.setState({
			textualContents: response.data.data,
			textualContentsFilter: response.data.data,
		  })
		 
    }
  }
    })
	  .catch(error => {
		console.log(error,'error')
		this.setState({
		  textualContentInProgress: false,
      showTextualContentErrorAlert: true,
      emptyContent:true
		})
		if (process.env.NODE_ENV !== 'production') {
		  return;
		}
		Sentry.captureException(error);
	  })
  }
render(){
	
	if(this.state.textualContentInProgress){
    return(
        <div>
        <Menu />
        <div className="row">
            <div className="component-Reportings">
                <div className="col-lg-12">
                    <h1 className="page-header">
                        <span className="page-title">Bibliothèque</span>
                        <div className="pull-right">
                            <ol className="breadcrumb">
                                <li><NavLink to="#">Accueil</NavLink></li>
                                <li className="active">Bibliothèque</li>
                            </ol>
                        </div>
                    </h1>
                </div> <br/><br/>
				<section className="content">
                              <div className="loader-container">
                                <div id="loader" className="tc-loader"></div>
							  </div> 
							  </section>
				</div>
				</div>
				</div>
	);
	}
	return(
		<div>
        <Menu />
        <div className="row">
		<div className="component-Reportings">
                <div className="col-lg-12">
                    <h1 className="page-header">
                        <span className="page-title">Bibliothèque</span>
                        <div className="pull-right">
                            <ol className="breadcrumb">
                                <li><NavLink to="#">Accueil</NavLink></li>
                                <li className="active">Bibliothèque</li>
                            </ol>
                        </div>
                    </h1>
                </div> 
				</div>
                <div class="container">
     	        <div class="row">
			 
			 {/*    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
				{this.state.textualContents.map(item =>(
					<div class="box-part text-center" key={item.id}>
					<img src={require('../../../src/images/wagaan_head.png')} alt={''}/> 
					<div className="activer"> 
				<span>{item.fullName}</span>  
					</div>
                      <h2> <i class="fas fa-book-reader"></i> </h2> 
                        
						<div class="title">
				<h4>{item.title}</h4>
						</div>
                        
						<div class="text">
						<span  dangerouslySetInnerHTML={{__html:item.content}}></span>
						</div>
                        
						<a href="#">Learn More</a>
                        
					 </div>
				))}
				</div>	 
				 */}
				 {this.emptyContent  &&
                 <div className="container">Aucun contenu pour l'instant</div>

                 }
				 {this.state.textualContents.map((item) =>
				  <div class="col-md-4" key={item.id}>
               
				  <section id="section-module">
				  
              <div className="module_container" >
			  <img src={require('../../../src/images/wagaan_head.png')} alt={''}/> 
                <h3 className="section-title"> {item.fullName} </h3>
                <div className="module_item_container">
                  <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div className="panel panel-default">
                      <div className="panel-heading volkeno-green-bcolor" role="tab" id="heading1">
                        <h4 className="panel-title">
                          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseItem1" aria-expanded="true" aria-controls="collapse1">
						  {item.content_type}
                          </a>
                        </h4>
                      </div>
                      <div id="collapseItem1" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="heading1">
                        <div className="panel-body">
                          <div className="module_content_container">
                            <div className="module_description">
                              <h5 className="module_item_title">{item.title}</h5>
                              <div className="module_description_content">
								  
								 <span  dangerouslySetInnerHTML={{__html:item.content}}></span>
							  
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    

                  </div>
                </div>
              </div>
				  
            </section>
				</div>	  
				 )}

			
                </div>
                </div>
                </div>
                </div>
                
    )
}
}