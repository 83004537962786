import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import { Container, Row, Col } from "shards-react";
import Skeleton from "react-loading-skeleton";
import "./Connexion.css";

const ConnexionSkeleton = () => {
  return (
    <Row className="connexion-skeleton-row">
      {/* <Skeleton count={50} duration={10} /> */}
      <div className="container-fluid">
        <div className="Connexion" style={{ backgroundImage: "url()" }}>
          <Helmet>
            <Skeleton width={`80%`} />
          </Helmet>
          <div className="page-holder d-flex align-items-center">
            <div className="container-fluid">
              <div className="row align-items-center login-form-container">
                <div className="col-md-6 ">
                  <div className="row">
                    <div className="col-md-12 mb-4 white-text text-center">
                      <Container className="contact-content debug-border">
                        <Row className="justify-content-center">
                          <Col style={{ float: "left", marginLeft: "4em" }}>
                            <br />
                            <br />
                            <Skeleton circle={true} height={70} width={70} />
                          </Col>
                        </Row>
                      </Container>
                      <h5
                        className="display-3 font-weight-bold"
                        style={{ color: "white", marginTop: "20%" }}
                      >
                        <Skeleton width={`70%`} height={"30"} />
                        <br />
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 px-lg-4" style={{ marginTop: "10%" }}>
                  <div className="">
                    <h2 className="conex" style={{ Color: "rgb(7,149,115)" }}>
                      <Skeleton width={`50%`} />
                    </h2>{" "}
                    <form id="loginForm" className="mt-4">
                      {/* <Skeleton height={180} width={`90%`} /> */}

                      <div className="form-group">
                        <Skeleton height={60} width={`90%`} />
                      </div>
                      <div className="form-group">
                        <Skeleton height={60} width={`90%`} />
                      </div>
                    </form>
                    <div id="forget_container">
                      <NavLink
                        className="forget-pwd-link"
                        to="/reinitialiser-mot-de-passe"
                      >
                        <Skeleton width={`30%`} />
                      </NavLink>
                      &emsp; &emsp; &emsp; &emsp;
                      <NavLink className="forget-pwd-link" to="/register">
                        <Skeleton width={`15%`} />
                      </NavLink>
                      &emsp; &emsp; &emsp; &emsp;
                      <NavLink className="forget-pwd-link" to="/register">
                        <Skeleton width={`20%`} />
                      </NavLink>
                      &emsp; &emsp; &emsp; &emsp;
                      <NavLink
                        className="forget-pwd-link"
                        to="/signaler-un-probleme"
                      >
                        <Skeleton width={`30%`} />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Row>
  );
};

export default ConnexionSkeleton;
