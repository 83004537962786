import React, { Component } from 'react';
import './completedBakelistes.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import $ from 'jquery';


export default class completedBakelistes extends Component {
  constructor(props) {
    super(props);

    let token = window.sessionStorage.getItem('token');
    let userStatus = window.sessionStorage.getItem('user_status');

    if (!token) {
      window.history.back()
    }
    if (userStatus === 'bakeliste' || userStatus === 'coach') {
      window.history.back();
    }

    this.reIntHandleChange = this.reIntHandleChange.bind(this);
    this.submitReIntData = this.submitReIntData.bind(this);


    this.state = {
      api: API.API,
      isPageLoading: false,
      bakelistes: [],
      countBakeliste: 0,
      isRequestError: false,
      bakelisteInfos: [],
      emailValue: '',
      phoneValue: '',
      boursierValue: false,
      demiBoursierValue: false,
      stageFormationValue: false,
      lieuFormationValue: '',
      paiementInscriptionValue: false,
      debutFormationValue: '',
      finFormationValue: '',
      formationPayanteValue: false,
      domaineFormationValue: 0,
      domaines: [],
      fields: {},
      openStatutProfessionnelFC: false,
      openStatutFD: false,
      isPro: false,
      isEtudiant: false,
      isEtudiantSemaineChoice: false,
      isEtudiantSemaineLSChoice: false,
      isSemaine: false,
      bakelisteID: 0,
      errors: {},
      email_empty: false,
      phone_empty: false,
      tf_empty: false,
      boursier_empty: false,
      demi_boursier_empty: false,
      formation_payante_empty: false,
      stagede_formation_empty: false,
      lf_empty: false,
      df_empty: false,
      paiement_empty: false,
      fs_empty: false,
      sp_empty: false,
      pe_empty: false,
      j1_empty: false,
      j2_empty: false,
      j3_empty: false,
      reIntSuccessAlert: false,
      reIntRequestError: false,

    };
  }

  componentDidMount() {
    this.getCompletedTrainingBakelistes();
    this.getAllDomaines();
  }

  /**
   * Get training domain
   */
  getAllDomaines = () => {
    axios.get(this.state.api + 'domaines')
      .then(res => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
        //console.log(this.state.domaines)
      });
  }

  /**
   * Get all completed training bakelistes
   */
  getCompletedTrainingBakelistes = () => {
    this.setState({
      isPageLoading: true
    });

    axios.get(this.state.api + 'bakelistes-with-completed-training')
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            bakelistes: response.data.data.reverse(),
            countBakeliste: response.data.count,
            isPageLoading: false
          })
        } else {
          this.setState({
            bakelistes: response.data.data.reverse(),
            countBakeliste: response.data.count,
            isPageLoading: false
          })
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isRequestError: true,
          isPageLoading: false
        })
      })
  }

  /**
   * Selected bakeliste infos
   */
  getSelectedBakelist = (bakeliste) => {
    this.setState({
      bakelisteInfos: bakeliste,
      bakelisteID: bakeliste.id,
      emailValue: bakeliste.email,
      phoneValue: bakeliste.phone,
      boursierValue: bakeliste.boursier,
      demiBoursierValue: bakeliste.demi_boursier,
      stageFormationValue: bakeliste.is_stagede_formation,
      lieuFormationValue: bakeliste.lieu_formation,
      paiementInscriptionValue: bakeliste.paiement_inscription,
      debutFormationValue: bakeliste.debut_formation,
      finFormationValue: bakeliste.fin_formation,
      domaineFormationValue: bakeliste.bakeliste_domaine_id,
      formationPayanteValue: bakeliste.is_formation_payante
    }, () => {
      //console.log(this.state.bakelisteInfos)
    })
  }

  /**
   * Action column data
   */
  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        {/* Bouton intégration bakeliste */}
        <button type="button" className="btn btn-success reIntegrate-btn volkeno-green-bcolor" id="reIntegrate-btn" data-toggle="modal" data-target="#reIntegrateModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
          <i className="fas fa-undo"></i>
        </button>
      </div>
    );
  }

  /**
   * Get bakeliste full infos
   */
  getBakelisteInfos = (bakeliste) => {

  }

  fullnameFormatter = (cell, row) => {
    return (
      <span className="fullname-link" data-toggle="modal" data-target="#showHiredModal" onClick={(e) => this.getBakelisteInfos(row, e)}>
        {cell}
      </span>
    );
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  /**
   * Hide Alert message after 5s
   */
  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === 'email') {
        this.setState({
          email_empty: false
        });
      }
      if (input === 'phone') {
        this.setState({
          phone_empty: false
        });
      }
      if (input === 'type_formation') {
        this.setState({
          tf_empty: false
        });
      }
      if (input === 'ispro') {
        this.setState({
          ispro_empty: false
        });
      }
      if (input === 'domaine_formation') {
        this.setState({
          df_empty: false
        });
      }
      if (input === 'formation_diplomante') {
        this.setState({
          fd_empty: false
        });
      }
      if (input === 'paiement_inscription') {
        this.setState({
          paiement_empty: false
        });
      }
      if (input === 'lieu_formation') {
        this.setState({
          lf_empty: false
        });
      }
      if (input === 'boursier') {
        this.setState({
          boursier_empty: false
        });
      }
      if (input === 'demi_boursier') {
        this.setState({
          demi_boursier_empty: false
        });
      }
      if (input === 'formation_payante') {
        this.setState({
          formation_payante_empty: false
        });
      }
      if (input === 'stagede_formation') {
        this.setState({
          stagede_formation_empty: false
        });
      }
      if (input === 'statut_professionnel') {
        this.setState({
          sp_empty: false
        });
      }
      if (input === 'pro_etudiant') {
        this.setState({
          pe_empty: false
        });
      }
      if (input === 'jour_1') {
        this.setState({
          j1_empty: false
        });
      }
      if (input === 'jour_2') {
        this.setState({
          j2_empty: false
        });
      }
      if (input === 'jour_3') {
        this.setState({
          j3_empty: false
        });
      }
      if (input === 'reIntSuccess') {
        this.setState({
          reIntSuccessAlert: false
        })
      }
      if (input === 'reIntError') {
        this.setState({
          reIntRequestError: false
        })
      }
    }, 5000);
  }

  reIntHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["bakeliste_id"] = this.state.bakelisteID;

    if (e.target.name === 'type_formation') { // test si c'est le champ type_formation
      fields[e.target.name] = e.target.value; // assignation de la valeur du input
      if (e.target.value === 'courte') { // test si le choix est formation courte
        fields["jour_fd"] = ""; // déclarer null jour_fd
        this.setState({
          openStatutProfessionnelFC: true, // True pour afficher le bloc supplémentaire pour la formation courte
          openStatutFD: false // False pour cacher le bloc complémentaire pour la formation diplômante
        });
      } else if (e.target.value === 'diplomante') { // test si le choix est formation diplomante
        fields["nbre_jours"] = 5;
        fields["is_etudiant"] = 1;
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        fields["jour_fd"] = "lundi au vendredi";
        fields["jour_1"] = "Lundi";
        fields["jour_2"] = "Mardi";
        fields["jour_3"] = "Mercredi";
        fields["jour_4"] = "Jeudi";
        fields["jour_5"] = "Vendredi";
        fields["jour_6"] = "";
        this.setState({
          openStatutProfessionnelFC: false, // False pour cacher les champs complémentaire de la formation courte
          openStatutFD: true, // True pour ouvrir les champs complémnetaires de la formation diplomante
          isSemaine: false,
          isPro: false,
          isEtudiant: false
        })
      }
    }

    if (e.target.name === 'isproetudiant') {
      if (e.target.value === 'professionnel') { // Test si le choix est professionnel
        fields["is_pro"] = 1;
        fields["is_etudiant"] = 0;
        this.setState({
          isPro: true,
          isEtudiant: false,
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false
        });
      } else if (e.target.value === 'etudiant') { // Test si le choix est étudiant
        fields["is_etudiant"] = 1;
        fields["is_pro"] = 0;
        fields["nbre_jours"] = 3;
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isPro: false,
          isEtudiant: true,
          isSemaine: false
        });
      }
    }
    if (e.target.name === 'isWE') { // Test si c'est le week-end/week pro qui est choisi
      if (e.target.value === 'semaine') { // Test si c'est la semaine qui est choisi
        fields["is_etudiant"] = 0;
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        fields["nbre_jours"] = 2;
        fields["horaire"] = "17h30-19h30";
        fields["jour_3"] = "";
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isSemaine: true
        });
        console.log(this.state.isSemaine)
      }
      else if (e.target.value === 'weekend') { // Test si c'est le week-end qui est choisi
        fields["is_weekend"] = 1;
        fields["is_week"] = 0;
        fields["horaire"] = '9h-13h';
        fields["nbre_jours"] = 1;
        fields["jour_1"] = "Samedi";
        fields["jour_2"] = "";
        fields["jour_3"] = "";
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isSemaine: false
        });
      }
      if (e.target.name === 'email') {
        fields["email"] = e.target.value;
      }
    }
    if (e.target.name === 'isWEE') { // Test si c'est un week-end/week étudiant qui est choisi
      if (e.target.value === 'semaine') { // Test si c'est la semaine qui est choisie
        fields["is_etudiant"] = 1;
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        fields["nbre_jours"] = 3;
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isEtudiantSemaineChoice: true,
          isEtudiantSemaineLSChoice: false
        })
      }
      else if (e.target.value === 'weekend') { // Test si c'est le weeke end qui est choisi
        fields["is_weekend"] = 1;
        fields["is_week"] = 0;
        fields["horaire"] = '9h-13h';
        fields["nbre_jours"] = 1;
        fields["jour_1"] = "Samedi";
        fields["jour_2"] = "";
        fields["jour_3"] = "";
        fields["jour_4"] = "";
        fields["jour_5"] = "";
        fields["jour_6"] = "";
        this.setState({
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false
        })

      } else if (e.target.value === 'semainels') { // Test si c'est la semaine plus le samedi qui est choisi
        fields["is_etudiant"] = 1;
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        fields["nbre_jours"] = 6;
        fields["jour_1"] = "Lundi";
        fields["jour_2"] = "Mardi";
        fields["jour_3"] = "Mercredi";
        fields["jour_4"] = "Jeudi";
        fields["jour_5"] = "Vendredi";
        fields["jour_6"] = "Samedi";
        console.log(fields)

        this.setState({
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: true
        })
      }
    }

    if (e.target.name === 'email') {
      this.setState({
        emailValue: e.target.value
      })
    }
    if (e.target.name === 'phone') {
      this.setState({
        phoneValue: e.target.value
      })
    }
    if (e.target.name === 'boursier') {
      this.setState({
        boursierValue: e.target.value
      })
    }
    if (e.target.name === 'demi_boursier') {
      this.setState({
        demiBoursierValue: e.target.value
      })
    }
    if (e.target.name === 'is_stagede_formation') {
      this.setState({
        stageFormationValue: e.target.value
      })
    }
    if (e.target.name === 'is_formation_payante') {
      this.setState({
        formationPayanteValue: e.target.value
      })
    }
    if (e.target.name === 'lieu_formation') {
      this.setState({
        lieuFormationValue: e.target.value
      })
    }
    if (e.target.name === 'bakeliste_domaine_id') {
      this.setState({
        domaineFormationValue: e.target.value
      })
    }
    if (e.target.name === 'paiement_inscription') {
      this.setState({
        paiementInscriptionValue: e.target.value
      })
    }
    if (e.target.name === 'debut_formation') {
      this.setState({
        debutFormationValue: e.target.value
      })
    }
    if (e.target.name === 'fin_formation') {
      this.setState({
        finFormationValue: e.target.valuephoneValue
      })
    }

    if (!fields["email"]) {
      fields["email"] = this.state.emailValue;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.phoneValue;
    }
    if (!fields["boursier"]) {
      fields["boursier"] = this.state.boursierValue;
    }
    if (!fields["demi_boursier"]) {
      fields["demi_boursier"] = this.state.demiBoursierValue;
    }
    if (!fields["is_stagede_formation"]) {
      fields["is_stagede_formation"] = this.state.stageFormationValue;
    }
    if (!fields["is_formation_payante"]) {
      fields["is_formation_payante"] = this.state.formationPayanteValue;
    }
    if (!fields["lieu_formation"]) {
      fields["lieu_formation"] = this.state.lieuFormationValue;
    }
    if (!fields["bakeliste_domaine_id"]) {
      fields["bakeliste_domaine_id"] = this.state.domaineFormationValue;
    }
    if (!fields["paiement_inscription"]) {
      fields["paiement_inscription"] = this.state.paiementInscriptionValue;
    }
    if (!fields["debut_formation"]) {
      fields["debut_formation"] = this.state.debutFormationValue;
    }
    if (!fields["fin_formation"]) {
      fields["fin_formation"] = this.state.finFormationValue;
    }

    this.setState({
      fields
    })
    console.log(fields)
  }

  /**
   * Integrate bakeliste form validation
   */
  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["email"]) {
      formIsValid = false;
      this.setState({
        email_empty: true
      })
      this.handleShowAndHideAlert('email');
      errors["email"] = "*Ce champ est obligatoire.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        this.setState({
          email_empty: true
        })
        this.handleShowAndHideAlert('email');
        errors["email"] = "*Adresse email invalide.";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      this.setState({
        phone_empty: true
      })
      this.handleShowAndHideAlert('phone');
      errors["phone"] = "*Ce champ est obligatoire.";
    }

    // if (typeof fields["phone"] !== "undefined") {
    //     if (!fields["phone"].match(/^[0-9]{9}$/)) {
    //         formIsValid = false;
    //         errors["phone"] = "*Numéro de telephone invalide.";
    //     }
    // }
    if (!fields["boursier"]) {
      formIsValid = false;
      this.setState({
        boursier_empty: true
      })
      this.handleShowAndHideAlert('boursier');
      errors["boursier"] = "*Ce champ est obligatoire.";
    }
    if (!fields["demi_boursier"]) {
      formIsValid = false;
      this.setState({
        demi_boursier_empty: true
      })
      this.handleShowAndHideAlert('demi_boursier');
      errors["demi_boursier"] = "*Ce champ est obligatoire.";
    }
    if (!fields["is_formation_payante"]) {
      formIsValid = false;
      this.setState({
        formation_payante_empty: true
      })
      this.handleShowAndHideAlert('formation_payante');
      errors["formation_payante"] = "*Ce champ est obligatoire.";
    }
    if (!fields["is_stagede_formation"]) {
      formIsValid = false;
      this.setState({
        stagede_formation_empty: true
      })
      this.handleShowAndHideAlert('stagede_formation');
      errors["stagede_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["type_formation"]) {
      formIsValid = false;
      this.setState({
        tf_empty: true
      })
      this.handleShowAndHideAlert('type_formation');
      errors["type_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["lieu_formation"]) {
      formIsValid = false;
      this.setState({
        lf_empty: true
      })
      this.handleShowAndHideAlert('lieu_formation');
      errors["lieu_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["bakeliste_domaine_id"]) {
      formIsValid = false;
      this.setState({
        df_empty: true
      })
      this.handleShowAndHideAlert('domaine_formation');
      errors["domaine_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["paiement_inscription"]) {
      formIsValid = false;
      this.setState({
        paiement_empty: true
      })
      this.handleShowAndHideAlert('paiement_inscription');
      errors["paiement_inscription"] = "*Ce champ est obligatoire.";
    }
    if (fields["type_formation"] && fields["type_formation"] === 'courte' && !fields["isproetudiant"]) {
      console.log('jour is pro etudiant')
      formIsValid = false;
      this.setState({
        sp_empty: true
      })
      this.handleShowAndHideAlert('statut_professionnel');
      errors["statut_professionnel"] = "*Vous devez choisir un statut professionnel.";
    }
    if (fields["type_formation"] && fields["isproetudiant"] && fields["isproetudiant"] === 'professionnel' && !fields["isWE"]) {
      console.log('jour is week end')
      formIsValid = false;
      this.setState({
        pe_empty: true
      })
      this.handleShowAndHideAlert('pro_etudiant');

      errors["pro_etudiant"] = "*Vous devez choisir un type de présence.";
    }
    if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isWE"] === 'semaine' && !fields["jour_1"]) {
      console.log('jour 1')
      formIsValid = false;
      this.setState({
        j1_empty: true
      })
      this.handleShowAndHideAlert('jour_1');
      errors["jour_1"] = "*Ce champ ne doit pas être vide.";
    }
    if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isWE"] === 'semaine' && !fields["jour_2"]) {
      console.log('jour 2')
      formIsValid = false;
      this.setState({
        j2_empty: true
      })
      this.handleShowAndHideAlert('jour_2');
      errors["jour_2"] = "*Ce champ ne doit pas être vide.";
    }
    if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isproetudiant"] === 'etudiant' && !fields["jour_3"]) {
      console.log('jour 3')
      formIsValid = false;
      this.setState({
        j3_empty: true
      })
      this.handleShowAndHideAlert('jour_3');
      errors["jour_3"] = "*Ce champ ne doit pas être vide.";
    }
    // if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isproetudiant"] == 'etudiant' && !fields["jour_2"]) {
    //     console.log('jour 2')
    //     formIsValid = false;
    //     this.state.j2_empty = true;
    //     this.handleShowAndHideAlert('jour_2');
    //     errors["jour_2"] = "*Ce champ ne doit pas être vide.";
    // }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  /**
   * Submit re-integrate bakeliste data
   */
  submitReIntData(e) {
    e.preventDefault();
    console.log('dasoi')
    // if (this.validateForm()) { // Test si le formulaire est valide
    console.log("hello")
    this.setState({
      updateInProgress: true
    });

    const dataBakeliste = this.state.fields; // données du formulaire
    const bakelisteID = this.state.bakelisteID; // assignation de l'id du bakeliste

    axios.post(this.state.api + 're-integrate-bakeliste-training/' + bakelisteID, dataBakeliste)
      .then(response => {
        if (response.data.success === true) { // Test si la requête est passée
          this.createNewBakelistePH(dataBakeliste); // Store des données formulaires sur la programmation horaire       
        } else { // Au cas où la requête n'est pas passée
          this.setState({
            updateInProgress: false,
            reIntRequestError: true
          })
          this.handleShowAndHideAlert('reIntError') // Switch
        }
      });

    //}
  }
  /**
   * Create Bakeliste Programmation Horaire after reintegration
   * 
   * @param Array data 
   * @return Response
   */
  createNewBakelistePH(data) {
    axios.post(this.state.api + 'saved-re-integrated-ph', data)
      .then((response) => {
        //console.log('ph response', response);   
        if (response.data.success === true) {
          this.getCompletedTrainingBakelistes();
          $('.close').click();
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          this.setState({
            updateInProgress: false,
            reIntSuccessAlert: true,
            fields: {}
          })
          this.handleShowAndHideAlert('reIntSuccess')
        } else {
          this.setState({
            updateInProgress: false,
            reIntRequestError: true
          })
          this.handleShowAndHideAlert('reIntError')
        }
      })
      .catch((error) => {
        console.log('Error ', error)
        this.setState({
          updateInProgress: false,
          reIntRequestError: true
        })
        this.handleShowAndHideAlert('reIntError')
      })
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        { props.exportCSVBtn }
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }


  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      noDataText: 'Aucune donnée pour le moment',
      btnGroup: this.createCustomButtonGroup
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-completed-bakelistes">
          <Menu />
          <div className="completed-bakelistes row" >
            <div className="col-lg-12">
              <h1 className="page-header">
                <span className="page-title">Bakeliste Formation Terminée</span>
                <div className="pull-right">
                  <ol className="breadcrumb">
                    <li>
                      <NavLink to="/dashboard-admin">Accueil</NavLink>
                    </li>
                    <li className="active">Formation terminée</li>
                  </ol>
                </div>
              </h1>
            </div>

            <div className="col-md-12 completedBakelistes-container">
              {/* <div className="row">
                <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                  {/* Empty */}
             {/*}   </div>
              </div> */}
              <div className="list_completedBakeliste_container">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Prénom et Nom</th>
                        <th scope="col">Email</th>
                        <th scope="col">Téléphone</th>
                        <th scope="col">Domaine</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="component-completed-bakelistes">
        <Menu />
        <div className="completed-bakelistes row" >
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">Bakeliste Formation Terminée</span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <NavLink to="/dashboard-admin">Accueil</NavLink>
                  </li>
                  <li className="active">Formation terminée</li>
                </ol>
              </div>
            </h1>
          </div>
          
          <div className="row counting-row">
            <div className="col-md-4 counting-col">
              <div className="panel panel-widget panel-volkeno-green">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-md-12 meeting-heading">
                      <div className="title">Formation terminée</div>
                      <div>
                        <div className="text-huge">{this.state.countBakeliste}</div>
                        <span className="participantLabel">bakeliste(s)</span>
                        <div className="clearfix"></div>
                        <hr />
                        {/* <div className="meeting-form-url">
                          <span className="meeting_url_label"> <b> URL du formulaire : </b> </span>
                          <span className="meeting_register_url">
                            <a href={'http://apply.bakeli.tech/#/inscription-evenement/' + x.rencontre_slug} className="form-link" target="_blank"> {'http://apply.bakeli.tech/#/inscription-evenement/' + x.rencontre_slug} </a>
                          </span>
                        </div> */}
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <a hred="#" className="moreInfosLink">
                  <div className="panel-footer">
                    <span className="pull-left">Détails</span>
                    <span className="pull-right"><i className="fas fa-arrow-circle-right"></i></span>
                    <div className="clearfix"></div>
                  </div>
                </a> */}
              </div>
            </div>
          </div>

          <div className="col-md-12 completedBakelistesContainer">
            <div className="completedBakelisteTable">
              <div className="table-container">
                <BootstrapTable data={this.state.bakelistes}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  exportCSV
                  pagination
                  search={true}>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='bakelisteFullname' dataFormat={this.fullnameFormatter.bind(this)} >Nom complet</TableHeaderColumn>
                  <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
                  <TableHeaderColumn dataField='phone'>Telephone</TableHeaderColumn>
                  <TableHeaderColumn dataField='ecole'>Ecole</TableHeaderColumn>
                  <TableHeaderColumn dataField='domaine'>Date de fin</TableHeaderColumn>
                  <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          </div>

          {/* re-integrate bakeliste */}
          <div className="modal fade" id="reIntegrateModal" tabIndex="-1" role="dialog" aria-labelledby="editBakelisteModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="editBakelisteModalLabel">Réintégrer : {this.state.bakelisteInfos.bakelisteFullname}</h4>
                </div>
                <div className="modal-body">
                  <div className="edit-bakeliste-container">
                    <div className="col-md-12">
                      <div className="form-group conditionnal-input" >
                        <label >Formation :</label> <br />
                        <input type="radio" className="radio-bsi" name="type_formation" value="courte" onChange={this.reIntHandleChange} />
                        <span>Formation courte</span> <br />
                        <input type="radio" className="radio-bsi" name="type_formation" value="diplomante" onChange={this.reIntHandleChange} />
                        <span>Formation diplômante</span>
                      </div>
                      {this.state.type_formation &&
                        <div className="errorMsg">{this.state.errors.type_formation}</div>
                      }
                    </div>

                    {/* Affiché si la formation courte est checké */}
                    {this.state.openStatutProfessionnelFC &&
                      <div className="pro_etudiant_container col-md-12">
                        <div className="form-group">
                          <label>Statut professionnel</label> <br />
                          <input type="radio" className="radio-bsi" name="isproetudiant" value="professionnel" onChange={this.reIntHandleChange} />
                          <span>Professionnel</span> <br />
                          <input type="radio" className="radio-bsi" name="isproetudiant" value="etudiant" onChange={this.reIntHandleChange} />
                          <span>Etudiant</span>
                        </div>
                        {this.state.sp_empty &&
                          <div className="errorMsg">{this.state.errors.statut_professionnel}</div>
                        }
                      </div>
                    }
                    {/* Afficher si c'est un étudiant */}
                    {this.state.isEtudiant &&
                      <div className="etudiant_jhp_container col-md-12">
                        <div className="form-group">
                          <label>Type de présence : </label> <br />
                          <input type="radio" className="radio-bsi" name="isWEE" value="semaine" onChange={this.reIntHandleChange} />
                          <span>Semaine (3 jours de présence )</span> <br />
                          <input type="radio" className="radio-bsi" name="isWEE" value="weekend" onChange={this.reIntHandleChange} />
                          <span>Week-End (Samedi de 9h à 13h)</span> <br />
                          <input type="radio" className="radio-bsi" name="isWEE" value="semainels" onChange={this.reIntHandleChange} />
                          <span>Semaine (Du Lundi au Vendredi )</span>
                        </div>
                        {this.state.pe_empty &&
                          <div className="errorMsg">{this.state.errors.pro_etudiant}</div>
                        }
                      </div>
                    }

                    {/* Affiché si c'est la semaine de 6 jours */}
                    {this.state.isEtudiantSemaineLSChoice &&
                      <div className="etudiant_jhp_container col-md-12">
                        <div className="form-group">
                          <label >Choix horaire :</label>
                          <select className="form-control" id="horaire" name="horaire" onChange={this.reIntHandleChange}>
                            <option> --Choisir un horaire-- </option>
                            <option value="8h-17h">8h - 17h</option>
                            <option value="8h-12h30">8h - 12h30</option>
                            <option value="12h30-17h">12h30 - 17h</option>
                          </select>
                        </div>
                      </div>
                    }

                    {/* Affiché si c'est la semaine choix étudiant */}
                    {this.state.isEtudiantSemaineChoice &&
                      <div className="etudiant_jhp_container">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label >Choix horaire :</label>
                            <select className="form-control" id="horaire" name="horaire" onChange={this.reIntHandleChange}>
                              <option> --Choisir un horaire-- </option>
                              <option value="8h-17h">8h - 17h</option>
                              <option value="8h-12h30">8h - 12h30</option>
                              <option value="12h30-17h">12h30 - 17h</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label >Choix Jour 1:</label>
                            <select className="form-control" id="jour_1" name="jour_1" onChange={this.reIntHandleChange}>
                              <option> --Choisir un jour-- </option>
                              <option value="Lundi">Lundi</option>
                              <option value="Mardi">Mardi</option>
                              <option value="Mercredi">Mercredi</option>
                              <option value="Jeudi">Jeudi</option>
                              <option value="Vendredi">Vendredi</option>
                              <option value="Samedi">Samedi</option>
                            </select>
                          </div>
                          {this.state.j1_empty &&
                            <div className="errorMsg">{this.state.errors.jour_1}</div>
                          }
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label >Choix Jour 2:</label>
                            <select className="form-control" id="jour_2" name="jour_2" onChange={this.reIntHandleChange}>
                              <option> --Choisir un jour-- </option>
                              <option value="Lundi">Lundi</option>
                              <option value="Mardi">Mardi</option>
                              <option value="Mercredi">Mercredi</option>
                              <option value="Jeudi">Jeudi</option>
                              <option value="Vendredi">Vendredi</option>
                              <option value="Samedi">Samedi</option>
                            </select>
                          </div>
                          {this.state.j2_empty &&
                            <div className="errorMsg">{this.state.errors.jour_2}</div>
                          }
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label >Choix Jour 3:</label>
                            <select className="form-control" id="jour_3" name="jour_3" onChange={this.reIntHandleChange}>
                              <option> --Choisir un jour-- </option>
                              <option value="Lundi">Lundi</option>
                              <option value="Mardi">Mardi</option>
                              <option value="Mercredi">Mercredi</option>
                              <option value="Jeudi">Jeudi</option>
                              <option value="Vendredi">Vendredi</option>
                              <option value="Samedi">Samedi</option>
                            </select>
                          </div>
                          {this.state.j3_empty &&
                            <div className="errorMsg">{this.state.errors.jour_3}</div>
                          }
                        </div>
                      </div>
                    }

                    {/* Affiché si c'est un PRO */}
                    {this.state.isPro &&
                      <div className="pro_jhp_container col-md-12">
                        <div className="form-group">
                          <label>Type de présence : </label> <br />
                          <input type="radio" className="radio-bsi" name="isWE" value="semaine" onChange={this.reIntHandleChange} />
                          <span>Semaine (2 jours de présence de 17h30 à 19h30)</span> <br />
                          <input type="radio" className="radio-bsi" name="isWE" value="weekend" onChange={this.reIntHandleChange} />
                          <span>Week-End (Samedi de 9h à 13h)</span>
                        </div>
                        {this.state.pe_empty &&
                          <div className="errorMsg">{this.state.errors.pro_etudiant}</div>
                        }
                      </div>
                    }

                    {/* Affiché si c'est la semaine pro */}
                    {this.state.isSemaine &&
                      <div className="jour_presence_container">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label >Choix Jour 1:</label>
                            <select className="form-control" id="jour_1" name="jour_1" onChange={this.reIntHandleChange}>
                              <option> --Choisir un jour-- </option>
                              <option value="Lundi">Lundi</option>
                              <option value="Mardi">Mardi</option>
                              <option value="Mercredi">Mercredi</option>
                              <option value="Jeudi">Jeudi</option>
                              <option value="Vendredi">Vendredi</option>
                            </select>
                          </div>
                          {this.state.j1_empty &&
                            <div className="errorMsg">{this.state.errors.jour_1}</div>
                          }
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label >Choix Jour 2:</label>
                            <select className="form-control" id="jour_1" name="jour_2" onChange={this.reIntHandleChange}>
                              <option> --Choisir un jour-- </option>
                              <option value="Lundi">Lundi</option>
                              <option value="Mardi">Mardi</option>
                              <option value="Mercredi">Mercredi</option>
                              <option value="Jeudi">Jeudi</option>
                              <option value="Vendredi">Vendredi</option>
                            </select>
                          </div>
                          {this.state.j2_empty &&
                            <div className="errorMsg">{this.state.errors.jour_2}</div>
                          }
                        </div>
                      </div>
                    }

                    {/* Affiché si c'est la formation diplômante qui est choisie */}
                    {this.state.openStatutFD &&
                      <div className="fd_choice_container col-md-12">
                        <div className="form-group">
                          <label >Choix horaire : (Du Lundi au Vendredi) </label>
                          <select className="form-control" id="horaire" name="horaire" onChange={this.reIntHandleChange}>
                            <option> --Choisir un horaire-- </option>
                            <option value="8h-12h30">8h - 12h30</option>
                            <option value="12h30-17h">12h30 - 17h</option>
                          </select>
                        </div>
                      </div>
                    }
                    <hr />
                    <div className="col-md-6">
                      <div className="form-group " >
                        <label >Email:</label>
                        <input type="email" className="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.emailValue} onChange={this.reIntHandleChange} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <label >Téléphone:</label>
                        <input type="text" className="form-control" id="inputNumero" placeholder="Téléphone" name="phone" value={this.state.phoneValue} onChange={this.reIntHandleChange} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group " >
                        <label >Boursier:</label>
                        <select className="form-control" id="inputBoursier" name="boursier" value={this.state.boursierValue} onChange={this.reIntHandleChange}>
                          <option> --Choisir une option-- </option>
                          <option value={1}>OUI</option>
                          <option value={0}>NON</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group " >
                        <label >Demi-Boursier:</label>
                        <select className="form-control" id="inputDBoursier" name="demi_boursier" value={this.state.demiBoursierValue} onChange={this.reIntHandleChange}>
                          <option> --Choisir une option-- </option>
                          <option value={1}>OUI</option>
                          <option value={0}>NON</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group " >
                        <label>Formation payante :</label>
                        <select className="form-control" id="inputFormationP" name="is_formation_payante" value={this.state.formationPayanteValue} onChange={this.reIntHandleChange}>
                          <option> --Choisir une Modificationoption-- </option>
                          <option value={1}>OUI</option>
                          <option value={0}>NON</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group " >
                        <label >Stage de formation :</label>
                        <select className="form-control" id="inputSFormation" name="is_stagede_formation" value={this.state.stageFormationValue} onChange={this.reIntHandleChange}>
                          <option> --Choisir une option-- </option>
                          <option value={1}>OUI</option>
                          <option value={0}>NON</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group " >
                        <label >Lieu de formation:</label>
                        <select className="form-control" id="inputlieu_formation" name="lieu_formation" value={this.state.lieuFormationValue} onChange={this.reIntHandleChange}>
                          <option> --Choisir un lieu-- </option>
                          <option value="Sicap Foire">Sicap Foire</option>
                          <option value="Grand Yoff 1">Grand Yoff 1</option>
                          <option value="Grand Yoff 2">Grand Yoff 2</option>
                          <option value="Thiés">Thiés</option>
                          <option value="Labé">Labé (Guinée)</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group " >
                        <label >Domaine de formation:</label>
                        <select className="form-control" id="inputbakeli_domaine_id" name="bakeliste_domaine_id" value={this.state.domaineFormationValue} onChange={this.reIntHandleChange}>

                          <option> --Choisir un domaine-- </option>
                          {this.state.domaines.map((x, y) => <option value={x.id} key={y} >{x.name}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group " >
                        <label >Paiement a l'inscription:</label>
                        <select className="form-control" id="inputpaiement_inscription" name="paiement_inscription" value={this.state.paiementInscriptionValue} onChange={this.reIntHandleChange}>
                          <option> --Choisir une option-- </option>
                          <option value={1}>OUI</option>
                          <option value={0}>NON</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label >Date de début:</label>
                        <input type="date" className="form-control" id="inputdebut_formation" name="debut_formation" value={this.state.debutFormationValue} onChange={this.reIntHandleChange} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label >Date de fin:</label>
                        <input type="date" className="form-control" id="inputfin_formation" name="fin_formation" value={this.state.finFormationValue} onChange={this.reIntHandleChange} />
                      </div>
                    </div>

                    {this.state.updateBakelisteRequestError &&
                      <div className="col-md-12 alert alert-danger updateBakelisteError">
                        Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                      </div>
                    }
                  </div>
                  <hr />
                  {this.state.reIntRequestError &&
                    <div className="col-md-12 alert alert-danger request-error">
                      Un problème est survenu lors de la sauvegarde. Veuillez réessayer plus tard.
                    </div>
                  }
                  <div className="modal-footer">
                    <div className="col-md-12 actions-col">
                      <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Quitter
                      </button>
                      {!this.state.updateInProgress &&
                        <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.submitReIntData(e)}>
                          <i className="fas fa-edit"></i>&nbsp;
                          Ré-intégrer
                        </button>
                      }
                      {this.state.updateInProgress &&
                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                          Validation en cours &nbsp;
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Successfull reintegration toast */}
          {this.state.reIntSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              Bakeliste réintégré avec succès.
          </div>
          }
        </div>
      </div>
    );
  }
}
