import React, {Component} from 'react';
import './CoachViewDelayAbsence.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
//import Menu from './../../menu/Menu';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
//import { Link, NavLink } from 'react-router-dom';
import API from '../../../variablesGlobales';
//import $ from 'jquery';
import '../../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import '../../../../node_modules/admin-lte/dist/js/adminlte.js';

export default class CoachViewDelayAbsence extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          isPageLoading: false,
          delays: [],
          currentMonthDelays: [],
          currentMonthDelayCount: 0,
          currentDayDelayData: [],
          currentDayDelayCount: 0,
          absences: [],
          currentMonthAbsences: [],
          currentMonthAbsenceCount: 0,
          currentDayAbsenceData: [],
          currentDayAbsenceCount: 0,
          delaysAbsences: [],
          coachID: window.sessionStorage.getItem('user_id'),

        };
    }

    componentDidMount(){
      console.log(this.state.coachID)
      this.onGetDelaysAndAbsences(this.state.coachID);
    }


    /**
     * Display delay and absence
     */
    onGetDelaysAndAbsences = (coachID) => {
      this.setState({
        isPageLoading: true
      })

      axios.get(this.state.api + 'delay-absence-by-coach-bakeliste/' + coachID)
        .then(response => {
          this.setState({
            isPageLoading: false
          })
          console.log(response)
          if(response.data.success){
            this.setState({
              delays: response.data.delayData,
              currentMonthDelays: response.data.currentMonthDelayData,
              currentMonthDelayCount: response.data.currentMonthDelayDataCount,
              currentDayDelayData: response.data.currentDayDelayData,
              currentDayDelayCount: response.data.currentDayDelayDataCount,
              absences: response.data.absenceData,
              currentMonthAbsences: response.data.currentMonthAbsenceData,
              currentMonthAbsenceCount: response.data.currentMonthAbsenceDataCount,
              currentDayAbsenceData: response.data.currentDayAbsenceData,
              currentDayAbsenceCount: response.data.currentDayAbsenceDataCount,
              delaysAbsences: response.data.data,
            })
          }
        })
        .catch(error => {
          console.log(error.message)
          this.setState({
            isPageLoading: false
          })
        })
    }

    actionsFormatter = (cell, row) => {

    }

    createCustomButtonGroup = props => {
      return (
        <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
          { props.exportCSVBtn }
          {/* <button type='button'
            className={ `btn btn-primary volkeno-red-bcolor` }>
            MyCustomBtn
          </button> */}
        </ButtonGroup>
      );
    }

    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }
  
    createCustomClearButton = (onClick) => {
      return (
        <ClearSearchButton
          btnText='Effacer'
          btnContextual='btn-warning'
          className='my-custom-class'
          onClick={e => this.handleClearButtonClick(onClick)} />
      );
    }

    delayAbsenceDateFormatter = (cell, row) => {
      if(cell != null){
        return(
          <span> {cell} </span>
        );
      }else{
        return(
          <span className="label label-warning"> Ce champ est vide </span>
        );
      }
    }

    justifiedStatusFormatter = (cell, row) => {
      if(cell === true){
        return(
          <span className="label label-success"> justifié </span>
        )
      }else{
        return(
          <span className="label label-danger"> non justifié </span>
        )
      }
    }

    render() {
      const options = {
        clearSearch: true,
        clearSearchBtn: this.createCustomClearButton,
        btnGroup: this.createCustomButtonGroup
      };

      if(this.state.isPageLoading){
        return(
          <div className="component-coach-view-delay-absence">
            Hello! component coachViewDelayAbsence
          </div>
        );
      }

      return(
        <div className="component-coach-view-delay-absence">
          <div className="row da-count-row">
            <div className="col-md-6 delay-count-container">
              <h4 className="delay-count-title"> Retardataires </h4>
              <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Mensuel</span>
                    <span className="info-box-number">
                    {this.state.currentMonthDelayCount}
                    {/* <small>%</small> */}
                    </span>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Journalier</span>
                    <span className="info-box-number">
                    {this.state.currentDayDelayCount}
                    {/* <small>%</small> */}
                    </span>
                </div>
                </div>
            </div>
            </div>

            <div className="col-md-6 delay-count-container">
              <h4 className="delay-count-title"> Absents </h4>
              <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Mensuel</span>
                    <span className="info-box-number">
                    {this.state.currentMonthAbsenceCount}
                    {/* <small>%</small> */}
                    </span>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Journalier</span>
                    <span className="info-box-number">
                    {this.state.currentDayAbsenceCount}
                    {/* <small>%</small> */}
                    </span>
                </div>
                </div>
            </div>
            </div>
          </div>
          <hr />
          <div className="row da-row">
          <div className="nav-tabs-custom">
            <div className="col-md-12 da-list-col">
            <ul className="nav nav-tabs">
              <li className="active"><a href="#delay_data" data-toggle="tab" aria-expanded="true">Retard</a></li>
              <li className=""><a href="#absence_data" data-toggle="tab" aria-expanded="false">Absence</a></li>
              <li className=""><a href="#delays_absences" data-toggle="tab" aria-expanded="false">Tous les retards et absences</a></li>
              {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
            </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="delay_data">
                <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.delays}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='delayFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='arriving_time' width="200" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Heure d'arrivée</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="absence_data">
              <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.absences}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='absenceFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="delays_absences">
              <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.delaysAbsences}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={this.delayAbsenceDateFormatter.bind(this)} dataField='delayFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date du retard</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={this.delayAbsenceDateFormatter.bind(this)} dataField='absenceFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date de l'absence</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='arriving_time' width="200" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Heure d'arrivée</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      );
    }
}
