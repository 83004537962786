import React, { Component } from 'react';
import './newFunctionality.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import API from '../../variablesGlobales';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';

export default class newFunctionality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      isPageLoading: false,
      newFunctionalities: [],
      releaseDate: '',
      releaseContent: '',
      releaseTypeInterface: '',
      releaseDevFullname: ''
    };
  }

  componentDidMount() {
    this.getAllNewFunctionalities();
  }

  getAllNewFunctionalities = () => {
    this.setState({
      isPageLoading: true
    })

    axios.get(this.state.api + 'new-functionalities')
      .then(response => {
        if (response.data.success === true) {
          console.log(response.data.data)
          this.setState({
            isPageLoading: false
          })
          this.setState({
            newFunctionalities: response.data.data.reverse()
          })
        } else {
          this.setState({
            isPageLoading: false
          })
          console.log(response)
        }
      })
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }


  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  getReleaseInfos = (release) => {
    console.log(release);
    this.setState({
      releaseDate: release.frenchDateFormat,
      releaseContent: release.content,
      releaseTypeInterface: release.user_type_interface,
      releaseDevFullname: release.userFullname
    })
  }

  releaseContentFormatter = (cell, row) => {
    return (
      <span className="release-link-modal fullname-link" data-toggle="modal" data-target="#releaseInfosModal" onClick={() => this.getReleaseInfos(row)}> {cell} </span>
    )
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-new-functionality">
          <div>
            <Menu />
            <div className="newFonctionalities row" id="newFonctionalities">
              <div className="col-lg-12">
                <h1 className="page-header">
                  <span className="page-title">Releases Bakeli SI</span>
                  <div className="pull-right">
                    <ol className="breadcrumb">
                      <li>
                        <Link to="/dashboard-admin">Accueil</Link>
                      </li>
                      <li className="active">Release Bakeli SI</li>
                    </ol>
                  </div>
                </h1>
              </div>
              <div className="col-md-12 release-container">
                <div className="releasesi_container">
                  <div className="table_container">
                    <table className="table table-bordered table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Date</th>
                          <th scope="col">Fonctionnalités</th>
                          <th scope="col">Interfaces</th>
                          <th scope="col">Développeurs</th>
                          {/* <th scope="col">Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <div id="loader"></div>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="component-new-functionality">
        <div>
          <Menu />
          <div className="newFonctionalities row" id="newFonctionalities">
            <div className="col-lg-12">
              <h1 className="page-header">
                <span className="page-title">Releases Bakeli SI</span>
                <div className="pull-right">
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard-admin">Accueil</Link>
                    </li>
                    <li className="active">Release Bakeli SI</li>
                  </ol>
                </div>
              </h1>
            </div>
            <div className="col-md-12 release-container">
              <div className="releasesi_container">
                <div className="table_container">
                  <BootstrapTable data={this.state.newFunctionalities}
                    striped={true} hover={true} condensed={true}
                    multiColumnSort={2} options={options}
                    pagination
                    search={true}>
                    <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='frenchDateFormat' >Date</TableHeaderColumn>
                    <TableHeaderColumn dataField='content' dataFormat={this.releaseContentFormatter.bind(this)}>Fonctionnalités</TableHeaderColumn>
                    <TableHeaderColumn dataField='user_type_interface'>Type d'interface</TableHeaderColumn>
                    <TableHeaderColumn dataField='userFullname'>Développeur</TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>

              {/* Afficher les détails du release */}
              <div className="modal fade" id="releaseInfosModal" tabIndex="-1" role="dialog" aria-labelledby="releaseInfosModalLabel">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 className="modal-title" id="releaseInfosModalLabel">Detaild release</h4>
                    </div>
                    <div className="modal-body">
                      <div className="coachInfos-container">
                        <div className="releaseDate_container">
                          <span className="item_label">Date : </span> <span className="label_content">{this.state.releaseDate}</span>
                        </div> <hr />
                        <div className="releaseContent_container">
                          <span className="item_label">Fonctionnalité : </span> <br />
                          <span className="label_content">{this.state.releaseContent}</span>
                        </div> <hr />
                        <div className="releaseTypeInt_container">
                          <span className="item_label">Type d'interface : </span> <br />
                          <span className="label_content">{this.state.releaseTypeInterface}</span>
                        </div> <hr />
                        <div className="releaseDevFullname_container">
                          <span className="item_label">Développeur : </span> <br />
                          <span className="label_content">{this.state.releaseDevFullname}</span>
                        </div> <hr />
                      </div>
                      <div className="modal-footer">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
