import React, { Component } from 'react';
import './listBakelistesVF.css'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import API from '../../variablesGlobales';
import $ from 'jquery';

export default class listBakelistesVF extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');

        if (!token) {
            window.history.back()
        }
        if (userStatus === 'bakeliste' || userStatus === 'coach') {
            window.history.back();
        }
        
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getSelectedBakelist = this.getSelectedBakelist.bind(this);
        this.assignCoach = this.assignCoach.bind(this);
        this.renderNameCoach = this.renderNameCoach.bind(this);
        this.updateHandleChange = this.updateHandleChange.bind(this);
        this.updateBakelisteInfos = this.updateBakelisteInfos.bind(this);
        this.handleChangeHiredBakeliste = this.handleChangeHiredBakeliste.bind(this);
        this.onSubmitHiredBakeliste = this.onSubmitHiredBakeliste.bind(this);
        this.handleCompletedFormation = this.handleCompletedFormation.bind(this);

        this.state = {
            bakeliste: [],
            fields: {},
            domaines: [],
            coachs: [],
            loading: true,
            api: API.API,
            assignationEncours: false,
            ress: [],
            user: [],
            dataBakeliste: {},
            selectedBakelist: [],
            isCoachAssignationSuccessAlert: false,
            isCoachAssignationRequestError: false,
            bakelisteIDForUpdate: 0,
            bakelisteFullname: '',
            emailValue: '',
            phoneValue: '',
            boursierValue: false,
            demiBoursierValue: false,
            domaineFormationValue: 0,
            stageFormationValue: false,
            isISAContractValue: 0,
            formationPayanteValue: false,
            lieuFormationValue: '',
            paiementInscriptionValue: false,
            debutFormationValue: '',
            finFormationValue: '',
            upFields: {},
            updateBakelisteInfosInProgress: false,
            successUpdateBakelisteInfosAlert: false,
            updateBakelisteInfosRequestError: false,
            CoachComments: [],
            isGetCommentsRequestError: false,
            hiredFields: {},
            hiredBakelisteInProgress: false,
            successHiredAlert: false,
            errorHiredAlert: false,
            completedInProgress: false,
            completedFormationRequestError: false,
            successCompletedFormationAlert: false,
        };
    }

    componentDidMount() {
        this.getCicesBakelistes();
        this.getDomainesDeFormation();
        this.getListActiveCoach();
    }

    getCicesBakelistes = () => {
        axios
        .get(this.state.api + 'bakeliste-volkeno-foire')
        .then(res => {
            this.setState({
                bakeliste: res.data.data.reverse(),
                loading: false,
            });
        });
    }

    getDomainesDeFormation = () => {
        axios
        .get(this.state.api + 'domaines')
        .then(res => {
            this.setState({ domaines: res.data.data, });
        });
    }

    getListActiveCoach = () => {
        axios
        .get(this.state.api + 'liste-des-coachs')
        .then(res => {
            this.setState({ coachs: res.data.data });
        })
    }

    renderName(id) {
        if (id != null) {
            this.state.domaines.forEach((x) => {
                if (x.id === id) {
                    console.log(x)
                    this.setState({
                        nameDomain: x.name
                    })
                }
            })
            return (
                <td><Link to="#">{this.state.nameDomain}</Link></td>
            )
        }
        else {
            return (
                <td> Domaine inconnu</td>
            )
        }
    }
    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    getSelectedBakelist(x) {
        console.log(x);
        this.setState({
            selectedBakelist: x
        })
        return x.id
    }


    assignCoach() {
        console.log("before assign", this.state);
        this.setState({
            assignationEncours: true
        });
        const id_bakelist = this.state.selectedBakelist.id

        const dataForAssign = {
            bakeliste_id: id_bakelist,
            coach_id: this.state.fields.coachSelect

        }
        axios.post(this.state.api + 'assigner-un-coach', dataForAssign)
            .then(res => {
                if (res.data.success) {
                    this.getCicesBakelistes();
                    $('.close').click();
                    this.setState({
                        assignationEncours: false,
                        isCoachAssignationSuccessAlert: true
                    });

                    this.handleShowAndHideAlert('successAssignationCoach');
                    // window.location.reload();
                } else {
                    console.log(res);
                    this.setState({
                        assignationEncours: false,
                        isCoachAssignationRequestError: true
                    })
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    assignationEncours: false,
                    isCoachAssignationRequestError: true
                })
            })
    }
    renderNameCoach(coach_id) {
        let name = ""
        this.state.coachs.forEach((x) => {
            if (x.id === coach_id) {
                name = x.first_name + ' ' + x.last_name
            }
        })
        return name
    }

    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'successAssignationCoach') {
                this.setState({
                    isCoachAssignationSuccessAlert: false
                })
            }
            if (label === 'closeUpdateSuccessAlert') {
                this.setState({
                    successUpdateBakelisteInfosAlert: false
                })
            }
            if (label === 'closeUpdateErrorAlert') {
                this.setState({
                    updateBakelisteInfosRequestError: false
                })
            }
            if (label === 'successHired') {
                this.setState({
                    successHiredAlert: false
                })
            }
            if (label === 'errorHired') {
                this.setState({
                    errorHiredAlert: false
                })
            }
            if(label === 'successCompletedFormation'){
                this.setState({
                    successCompletedFormationAlert: false
                })
            }
            if(label === 'errorCompletedFormation'){
                this.setState({
                    completedFormationRequestError: false
                })
            }
        }, 5000);
    }

    getBakelisteInfos = (bakeliste) => {
        console.log(bakeliste);

        this.setState({
            bakelisteIDForUpdate: bakeliste.id,
            bakelisteFullname: bakeliste.bakelisteFullname,
            emailValue: bakeliste.email,
            phoneValue: bakeliste.phone,
            boursierValue: bakeliste.boursier,
            demiBoursierValue: bakeliste.demi_boursier,
            domaineFormationValue: bakeliste.bakeliste_domaine_id,
            stageFormationValue: bakeliste.is_stagede_formation,
            isISAContractValue: bakeliste.is_isa_contract,
            formationPayanteValue: bakeliste.is_formation_payante,
            lieuFormationValue: bakeliste.lieu_formation,
            paiementInscriptionValue: bakeliste.paiement_inscription,
            debutFormationValue: bakeliste.debut,
            finFormationValue: bakeliste.fin
        })
    }

    updateHandleChange = (e) => {
        let fields = this.state.upFields;
        fields[e.target.name] = e.target.value;

        if (e.target.name === 'email') {
            this.setState({
                emailValue: e.target.value
            })
        }
        if (e.target.name === 'phone') {
            this.setState({
                phoneValue: e.target.value
            })
        }
        if (e.target.name === 'boursier') {
            this.setState({
                boursierValue: e.target.value
            })
        }
        if (e.target.name === 'demi_boursier') {
            this.setState({
                demiBoursierValue: e.target.value
            })
        }
        if (e.target.name === 'is_formation_payante') {
            this.setState({
                formationPayanteValue: e.target.value
            })
        }
        if (e.target.name === 'is_stagede_formation') {
            this.setState({
                stageFormationValue: e.target.value
            })
        }
        if (e.target.name === 'lieu_formation') {
            this.setState({
                lieuFormationValue: e.target.value
            })
        }
        if (e.target.name === 'bakeliste_domaine_id') {
            this.setState({
                domaineFormationValue: e.target.value
            })
        }
        if (e.target.name === 'paiement_inscription') {
            this.setState({
                paiementInscriptionValue: e.target.value
            })
        }
        if (e.target.name === 'debut_formation') {
            this.setState({
                debutFormationValue: e.target.value
            })
        }
        if (e.target.name === 'fin_formation') {
            this.setState({
                finFormationValue: e.target.value
            })
        }

        if (e.target.name === 'is_isa_contract') {
            this.setState({
              isISAContractValue: e.target.value
            })
        }
      
        if (!fields["is_isa_contract"]) {
        fields["is_isa_contract"] = this.state.isISAContractValue;
        }

        if (!fields["email"]) {
            fields["email"] = this.state.emailValue;
        }
        if (!fields["phone"]) {
            fields["phone"] = this.state.phoneValue;
        }
        if (!fields["boursier"]) {
            fields["boursier"] = this.state.boursierValue;
        }
        if (!fields["demi_boursier"]) {
            fields["demi_boursier"] = this.state.demiBoursierValue;
        }
        if (!fields["is_formation_payante"]) {
            fields["is_formation_payante"] = this.state.formationPayanteValue;
        }
        if (!fields["is_stagede_formation"]) {
            fields["is_stagede_formation"] = this.state.stageFormationValue;
        }
        if (!fields["lieu_formation"]) {
            fields["lieu_formation"] = this.state.lieuFormationValue;
        }
        if (!fields["bakeliste_domaine_id"]) {
            fields["bakeliste_domaine_id"] = this.state.domaineFormationValue;
        }
        if (!fields["paiement_inscription"]) {

            fields["paiement_inscription"] = this.state.paiementInscriptionValue;
        }
        if (!fields["debut_formation"]) {
            fields["debut_formation"] = this.state.debutFormationValue;
        }
        if (!fields["fin_formation"]) {
            fields["fin_formation"] = this.state.finFormationValue;
        }

        this.setState({
            upFields: fields
        })

        console.log(fields)
    }

    updateBakelisteInfos = (e) => {
        e.preventDefault();
        this.setState({
            updateBakelisteInfosInProgress: true
        })
        let bakelisteID = this.state.bakelisteIDForUpdate;
        let data = this.state.upFields;
        

        console.log("Mes données", this.state);

        axios.put(this.state.api + 'update-bakeliste-infos/' + bakelisteID, data)
            .then(response => {
                this.setState({
                    updateBakelisteInfosInProgress: false
                });
                if (response.data.success === true) {
                    this.getCicesBakelistes();
                    $('.close').click();
                    this.setState({
                        successUpdateBakelisteInfosAlert: true
                    });
                    this.handleShowAndHideAlert('closeUpdateSuccessAlert');
                } else {
                    this.setState({
                        updateBakelisteInfosInProgress: false,
                        updateBakelisteInfosRequestError: true
                    });
                    this.handleShowAndHideAlert('closeUpdateErrorAlert');
                }
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    updateBakelisteInfosInProgress: false,
                    updateBakelisteInfosRequestError: true
                });
                this.handleShowAndHideAlert('closeUpdateErrorAlert');
            })
    }

    getCoachCommentToBakeliste = () => {

        let bakelisteID = window.sessionStorage.getItem('bakelisteIDForShowComment');

        axios.get(this.state.api + 'coach-comments-by-bakeliste/' + bakelisteID)
            .then(response => {
                if (response.data.success === true) {
                    console.log('success')
                    if (response.data.data !== undefined) {
                        this.setState({
                            CoachComments: response.data.data
                        });
                    }
                } else {
                    console.log('empty')
                    console.log('Commentaire non trouvé')
                }
            })
            .catch(error => {
                this.setState({
                    isGetCommentsRequestError: true
                });
                console.log(error)
            })
    }

    getBakelisteIDForShowingComment(x) {
        console.log(x.id);

        this.setState({
            CoachComments: [],
            bakelisteIDForComment: x.id
        });
        window.sessionStorage.setItem('bakelisteIDForShowComment', x.id)
        this.getCoachCommentToBakeliste();
    }

    handleCompletedFormation = (bakeliste) => {
        this.setState({
            completedInProgress: true
        });

        let bakelisteID = bakeliste.id;
        let data = {};

        axios.post(this.state.api + 'completed-bakelistes-formation/' + bakelisteID, data)
            .then(response => {
                this.setState({
                    completedInProgress: false
                });
                if (response.data.success === true) {
                    this.getCicesBakelistes();
                    this.setState({
                        successCompletedFormationAlert: true
                    })
                    this.handleShowAndHideAlert('successCompletedFormation');
                } else {
                    console.log(response);
                    this.setState({
                        completedFormationRequestError: true,
                        completedInProgress: false
                    })
                    this.handleShowAndHideAlert('errorCompletedFormation');
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    completedFormationRequestError: true,
                    completedInProgress: false
                })
                this.handleShowAndHideAlert('errorCompletedFormation');
            })
    }

    getSelectedBakelist = (bakeliste) => {
        this.setState({
            selectedBakelist: bakeliste
        })
    }

    bakelisteFullnameFormatter = (cell, row) => {
        return (
            <span className="fullname-link" data-toggle="modal" data-target="#showBakelisteModal" onClick={(e) => this.getSelectedBakelist(row, e)}>{cell}</span>
        );
    }

    coachRowFormatter = (cell, row) => {
        return (
            <div className="coach_row actions-btn-container">
                {row.coach_id ?
                    <Link to="#">
                        {this.renderNameCoach(cell)}
                    </Link> :

                    <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#myModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
                        <i class="fas fa-user-check"></i>
                    </button>

                }
            </div>
        );
    }

    actionsFormatter = (cell, row) => {
        return (
            <div className="actions-btn-container">
                <button type="button" className="btn btn-info reassigner" id="btn_assign" data-toggle="modal" data-target="#myModal" onClick={(e) => this.getSelectedBakelist(row, e)}>
                    <i class="fas fa-user-check"></i>
                </button>
                <button type="button" className="btn btn-warning" id="btn_update" data-toggle="modal" data-target="#editBakelisteModal" onClick={(e) => this.getBakelisteInfos(row, e)}>
                    <i class="fas fa-edit"></i>
                </button>
                {/*<button data-toggle="tooltip" data-placement="top" title="Archiver" type="button" className="btn btn-danger" id="btn_update" data-toggle="modal" data-target="#">
                    <i class="fas fa-archive"></i>
                </button> */}
                <button onClick={(e) => this.getBakelisteIDForShowingComment(row, e)} type="button" className="btn btn-success volkeno-green-bcolor" id="btn_update" data-toggle="modal" data-target="#CommentModal">
                    <i class="fas fa-comment"></i>
                </button>
                <button onClick={(e) => this.getBakelisteForHired(row, e)} type="button" className="btn btn-success volkeno-orange-color-wb" id="btn_update" data-toggle="modal" data-target="#hireBakelisteModal">
                    <i class="fab fa-hire-a-helper"></i>
                </button>
                <button type="button" className="btn btn-danger terminer-formation" id="completed"
                    onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter la formation de ce bakeliste ?") &&
                        this.handleCompletedFormation(row)}
                >
                    {this.state.completedInProgress ?
                        <i className="fas fa-spin fa-spinner"></i> :
                        <i class="fas fa-stop-circle"></i>
                    }

                </button>
            </div>
        );
    }

    getBakelisteForHired = (bakeliste) => {
        console.log(bakeliste)

        window.sessionStorage.setItem('hiredBakelisteID', bakeliste.id);
        window.sessionStorage.setItem('hiredBakelisteCoachID', bakeliste.coach_id);
        window.sessionStorage.setItem('hiredBakelisteFullname', bakeliste.bakelisteFullname);
    }

    handleChangeHiredBakeliste(e) {
        let fields = this.state.hiredFields;
        fields[e.target.name] = e.target.value;

        fields["bakeliste_id"] = window.sessionStorage.getItem('hiredBakelisteID');
        fields["coach_id"] = window.sessionStorage.getItem('hiredBakelisteCoachID');

        this.setState({
            hiredFields: fields
        })
        console.log(fields)
    }

    onSubmitHiredBakeliste = (e) => {
        e.preventDefault();
        this.setState({
            hiredBakelisteInProgress: true
        })

        let hiredData = this.state.hiredFields;
        let bakelisteID = window.sessionStorage.getItem('hiredBakelisteID');

        axios.post(this.state.api + 'placements', hiredData)
            .then(response => {
                if (response.data.success === true) {
                    this.onChangeBakelisteHiredStatus(bakelisteID);
                    this.getCicesBakelistes();
                    $('.close').click();

                    this.setState({
                        hiredBakelisteInProgress: false,
                        successHiredAlert: true
                    })
                    this.handleShowAndHideAlert('successHired')
                } else {
                    this.setState({
                        hiredBakelisteInProgress: false,
                        errorHiredAlert: true
                    })
                    this.handleShowAndHideAlert('errorHired')
                }
            })
            .catch(error => {
                this.setState({
                    hiredBakelisteInProgress: false,
                    errorHiredAlert: true
                })
                this.handleShowAndHideAlert('errorHired')
                console.log(error)
            })
    }

    onChangeBakelisteHiredStatus = (bakeliste_id) => {
        axios.post(this.state.api + 'hired-bakeliste-status/' + bakeliste_id)
            .then(response => {
                if (response.data.success === true) {
                    console.log(response)
                } else {
                    console.log(response)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        const options = {
            clearSearch: true,
            clearSearchBtn: this.createCustomClearButton
        };

        if (this.state.loading) {
            return (
                <div className="component-ListeBakelistes">
                    <Menu />
                    <div className="createBakeliste row" >
                        <div className="col-lg-12">
                            <h1 className="page-header">
                                <span className="page-title">Bakelistes de Volkeno (Foire) </span>
                                <div className="pull-right">
                                    <ol className="breadcrumb">
                                        <li>
                                            <Link to="/dashboard-admin">Accueil</Link>
                                        </li>
                                        <li className="active">Bakelistes de Volkeno (Foire)</li>
                                    </ol>
                                </div>
                            </h1>
                        </div>

                        <div className="col-md-12 reporting-container">
                          <div className="col-md-6 col-md-offset-3 search_container">
                              <div class="input-group">
                                  <input type="text" class="form-control searching-form" placeholder="Search for..." />
                                  
                                  <span class="input-group-btn">
                                      <button class="btn btn-warning" type="button">
                                          <i className="fas fa-search"></i>
                                      </button>
                                  </span>
                              </div>
                          </div>
                          <div className="row add_bakeliste_container">
                              <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                                  <Link data-toggle="tooltip" data-placement="top" title="Ajouter bakeliste" to="/ajouter-un-bakeliste" className="btn btn-success add_coach_btn button">
                                      <i className="fas fa-plus-circle"></i>
                                  </Link>
                              </div>
                          </div>
                          <div className="list_bakeliste_container">
                              <div class="table-responsive">
                                  <table class="table table-bordered">
                                      <thead>
                                          <tr>
                                              <th scope="col">#</th>
                                              <th scope="col">Prénom et Nom</th>
                                              <th scope="col">Domaines</th>
                                              <th scope="col">Coach</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <div id="loader"></div>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>

                        <div className="col-md-12 reporting-container">
                            <div className="row add_bakeliste_container">
                                <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                                    <Link data-toggle="tooltip" data-placement="top" title="Ajouter bakeliste" to="/ajouter-un-bakeliste" className="btn btn-success add_coach_btn button">
                                        <i className="fas fa-plus-circle"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="list_bakeliste_container">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Prénom et Nom</th>
                                                <th scope="col">Domaines</th>
                                                <th scope="col">Coach</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div id="loader"></div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            )
        }
        return (
            <div className="component-ListeBakelistes">
                <Menu />
                <div className="createBakeliste row" >
                    <div className="col-lg-12">
                        <h1 className="page-header">
                            <span className="page-title">Bakelistes de Volkeno (Foire)</span>
                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard-admin">Accueil</Link>
                                    </li>
                                    <li className="active">Bakelistes de Volkeno (Foire)</li>
                                </ol>
                            </div>
                        </h1>
                    </div>

                    <div className="col-md-12 reporting-container">
                        <div className="row add_bakeliste_container">
                            <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                                <Link data-toggle="tooltip" data-placement="top" title="Ajouter bakeliste" to="/ajouter-un-bakeliste" className="btn btn-success add_coach_btn button">
                                    <i className="fas fa-plus-circle"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="list_bakeliste_container">
                            <div className="gy2_container">
                                <BootstrapTable data={this.state.bakeliste}
                                    striped={true} hover={true} condensed={true}
                                    multiColumnSort={2} options={options}
                                    pagination
                                    search={true}>
                                    <TableHeaderColumn dataAlign="center"
                                        dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        dataField='bakelisteFullname'
                                        dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true} >Nom complet</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        dataField='phone'>Téléphone</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        dataField='domaine'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Domaine</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        dataField='coach_id'
                                        dataFormat={this.coachRowFormatter.bind(this)}
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Coach</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        dataField='ecole'
                                        filter={{ type: 'TextFilter', delay: 1000 }}
                                        dataSort={true}>Ecole</TableHeaderColumn>
                                    <TableHeaderColumn dataAlign="center"
                                        width='400' dataField='id'
                                        dataFormat={this.actionsFormatter.bind(this)}
                                        dataSort={true}>Actions</TableHeaderColumn>
                                </BootstrapTable>

                                <div className="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="myModalLabel">Selection du coach</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="form-group " >
                                                    <label >Coach:</label>
                                                    <select className="form-control" id="inputbakeli_domaine_id" name="coachSelect" onChange={this.handleChange}>
                                                        <option>--Choisir un coach--</option>
                                                        {this.state.coachs.map((z, y) => <option value={z.id} key={y}>{z.first_name} {z.last_name} {'-'} {z.nbreBakeliste} {'bakeliste(s)'}</option>)}
                                                    </select>
                                                </div>
                                                {this.isCoachAssignationRequestError &&
                                                    <div className="row assignation-error">
                                                        <div className="col-md-12 error-message-alert">
                                                            Un problème est survenu lors de l'assignation. veuillez réessayer plus tard.
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                    <i class="fas fa-times-circle"></i> &nbsp;
                                                    Quitter
                                                </button>
                                                {!this.state.assignationEncours &&
                                                    <button type="button" id="ass" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.assignCoach(e)}>
                                                        <i class="fas fa-user-check"></i>&nbsp;
                                                        Assigner
                                                    </button>
                                                }
                                                {this.state.assignationEncours &&
                                                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                        Assignation en cours &nbsp;
                                                      <i className="fas fa-spinner fa-spin"></i>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="modal fade" id="showBakelisteModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="myModalLabel">Detail sur {this.state.selectedBakelist.bakelisteFullname}&nbsp;</h4>
                                            </div>
                                            <div className="modal-body">
                                            {this.state.selectedBakelist.type_formation === 'courte' ? (
                                                    this.state.selectedBakelist.nbreJours === '3' ? (
                                                        <div className="content_container">
                                                            <b>Jours de présences : </b> <br />
                                                            <span> {this.state.selectedBakelist.jour1}, {this.state.selectedBakelist.jour2}, {this.state.selectedBakelist.jour3} </span> <br />
                                                            <b>Heure de présence</b> <br />
                                                            <span className="horaire-bakeliste-item"> {this.state.selectedBakelist.horaire} </span>

                                                        </div>
                                                    ) :
                                                        this.state.selectedBakelist.nbreJours === '5' ? (
                                                            <div className="content_container">
                                                                <b>Jours de présences : </b> <br />
                                                                <span> {this.state.selectedBakelist.jour1}, {this.state.selectedBakelist.jour2}, {this.state.selectedBakelist.jour3}, {this.state.selectedBakelist.jour4}, {this.state.selectedBakelist.jour5} </span> <br />
                                                                <b>Heure de présence</b> <br />
                                                                <span className="horaire-bakeliste-item"> {this.state.selectedBakelist.horaire} </span>

                                                            </div>
                                                        ) :
                                                            this.state.selectedBakelist.nbreJours === '6' && (
                                                                <div className="content_container">
                                                                    <b>Jours de présences : </b> <br />
                                                                    <span> {this.state.selectedBakelist.jour1}, {this.state.selectedBakelist.jour2}, {this.state.selectedBakelist.jour3}, {this.state.selectedBakelist.jour4}, {this.state.selectedBakelist.jour5}, {this.state.selectedBakelist.jour6} </span> <br />
                                                                    <b>Heure de présence</b> <br />
                                                                    <span className="horaire-bakeliste-item"> {this.state.selectedBakelist.horaire} </span>
                                                                </div>
                                                            )
                                                ) :
                                                    this.state.selectedBakelist.type_formation === 'diplomante' && (
                                                        <div className="content_container">
                                                            <b>Jours de présences : </b> <br />
                                                            <span> Du Lundi au Vendredi </span> <br />
                                                            <b>Heure de présence</b> <br />
                                                            <span className="horaire-bakeliste-item"> {this.state.selectedBakelist.horaire} </span>

                                                        </div>
                                                    )
                                                }
                                                <div className="email_container">
                                                    <span className="item_label">Email : </span> <span className="label_content">{this.state.selectedBakelist.email}</span>
                                                </div> <hr />
                                                <div className="objectif_container">
                                                    <span className="item_label">Objectifs : </span> <span className="label_content">{this.state.selectedBakelist.objectifs}</span>
                                                </div> <hr />

                                                {this.state.selectedBakelist.ecole !== '' &&
                                                    <div className="ecole_container">
                                                        <span className="item_label">Ecole : </span> <span className="label_content">{this.state.selectedBakelist.ecole}</span> <hr />
                                                    </div>

                                                }
                                                <div className="email_container">
                                                    <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.selectedBakelist.phone}</span>
                                                </div> <hr />
                                                <div className="ecole_container">
                                                    <span className="item_label">Boursier : </span> 
                                                    {this.state.selectedBakelist.boursier === 1 ?
                                                        <span className="label_content">OUI</span> :
                                                        <span className="label_content">NON</span> 
                                                    }
                                                    
                                                </div> <hr />
                                                <div className="email_container">
                                                    <span className="item_label">Lieu de formation : </span> <span className="label_content">{this.state.selectedBakelist.lieu_formation}</span>
                                                </div> <hr />
                                                <div className="email_container">
                                                    <span className="item_label">Type de formation : </span> <span className="label_content">{this.state.selectedBakelist.type_formation}</span>
                                                </div> <hr />
                                                <div className="ecole_container">
                                                    <span className="item_label">Formation payante : </span> 
                                                    {this.state.selectedBakelist.is_formation_payante === 1 ?
                                                        <span className="label_content">OUI</span> :
                                                        <span className="label_content">NON</span> 
                                                    }
                                                </div> <hr />
                                                <div className="email_container">
                                                    <span className="item_label">Date de début de la formation : </span> <span className="label_content">{this.state.selectedBakelist.debut_formation}</span>
                                                </div> <hr />
                                                <div className="email_container">
                                                    <span className="item_label">Date de fin de la formation : </span> <span className="label_content">{this.state.selectedBakelist.fin_formation}</span>
                                                </div> <hr />
                                            </div>
                                            <div className="modal-footer">

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* edit bakeliste */}
                                <div className="modal fade" id="editBakelisteModal" tabindex="-1" role="dialog" aria-labelledby="editBakelisteModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="editBakelisteModalLabel">Modifier : {this.state.bakelisteFullname}</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="edit-bakeliste-container">
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                            <label >Email:</label>
                                                            <input type="Email" class="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.emailValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div class="form-group ">
                                                            <label >Téléphone:</label>
                                                            <input type="text" class="form-control" id="inputNumero" placeholder="Téléphone" name="phone" value={this.state.phoneValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                            <label >Boursier:</label>
                                                            <select class="form-control" id="inputBoursier" name="boursier" value={this.state.boursierValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                            <label >Demi-Boursier:</label>
                                                            <select class="form-control" id="inputDBoursier" name="demi_boursier" value={this.state.demiBoursierValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                            <label>Formation payante :</label>
                                                            <select class="form-control" id="inputFormationP" name="is_formation_payante" value={this.state.formationPayanteValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                            <label >Stage de formation :</label>
                                                            <select class="form-control" id="inputSFormation" name="is_stagede_formation" value={this.state.stageFormationValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                            <label >Contrat ISA :</label>
                                                            <select class="form-control" id="inputISA" name="is_isa_contract" value={this.state.isISAContractValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                        <label >Lieu de formation:</label>
                                                        <select class="form-control" id="inputlieu_formation" name="lieu_formation" value={this.state.lieuFormationValue} onChange={this.updateHandleChange}>
                                                            <option> --Choisir un lieu-- </option>
                                                            <option value="Sicap Foire">Sicap Foire</option>
                                                            <option value="Grand Yoff 1">Grand Yoff 1</option>
                                                            <option value="Grand Yoff 2">Grand Yoff 2</option>
                                                            <option value="Thiés">Thiés</option>
                                                            <option value="Tambacounda">Tambacounda</option>
                                                        </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                            <label >Domaine de formation:</label>
                                                            <select class="form-control" id="inputbakeli_domaine_id" name="bakeliste_domaine_id" value={this.state.domaineFormationValue} onChange={this.updateHandleChange}>

                                                                <option> --Choisir un domaine-- </option>
                                                                {this.state.domaines.map((x, y) => <option value={x.id} key={y} >{x.name}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group " >
                                                            <label >Paiement a l'inscription:</label>
                                                            <select class="form-control" id="inputpaiement_inscription" name="paiement_inscription" value={this.state.paiementInscriptionValue} onChange={this.updateHandleChange}>
                                                                <option> --Choisir une option-- </option>
                                                                <option value={1}>OUI</option>
                                                                <option value={0}>NON</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group ">
                                                            <label >Date de début:</label>
                                                            <input type="date" class="form-control" id="inputdebut_formation" name="debut_formation" value={this.state.debutFormationValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="form-group ">
                                                            <label >Date de fin:</label>
                                                            <input type="date" class="form-control" id="inputfin_formation" name="fin_formation" value={this.state.finFormationValue} onChange={this.updateHandleChange} />
                                                        </div>
                                                    </div>

                                                    {this.state.updateBakelisteInfosRequestError === true &&
                                                        <div className="row update-error">
                                                            <div className="col-md-12 update-error-content alert alert-danger">
                                                                Un probléme est survenu lors de la modification. Veuillez réessayer plus tard.
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                                <div className="modal-footer">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                                <i class="fas fa-times-circle"></i> &nbsp;
                                                                Quitter
                                                    </button>
                                                            {!this.state.updateBakelisteInfosInProgress &&
                                                                <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.updateBakelisteInfos(e)}>
                                                                    <i class="fas fa-edit"></i>&nbsp;
                                                                    Modifier
                                                        </button>
                                                            }
                                                            {this.state.updateBakelisteInfosInProgress &&
                                                                <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                                    Modification en cours &nbsp;
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Commentaires */}
                                <div className="modal fade" id="CommentModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="myModalLabel">Commentaires</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="comments-container">
                                                    <div className="comment-items">
                                                        {this.state.CoachComments.length !== 0 ?
                                                            this.state.CoachComments.map((comment, index) =>

                                                                <div key={index} className="comment-item">
                                                                    <b>Commentaire du {comment.frenchDateFormat}</b> <br />
                                                                    <p> {comment.commentaire_content} </p>
                                                                    <hr />
                                                                </div>
                                                            )
                                                            :
                                                            <div className="alert alert-warning empty-comment-alert">
                                                                Aucun commentaire sur ce bakeliste.
                                                                </div>
                                                        }
                                                    </div>

                                                </div>
                                                <div className="modal-footer">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Déclarer un bakeliste placé */}
                                <div className="modal fade" id="hireBakelisteModal" tabindex="-1" role="dialog" aria-labelledby="hireBakelisteModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="hireBakelisteModalLabel">Bakeliste : {window.sessionStorage.getItem('hiredBakelisteFullname')}</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row edit-bakeliste-container">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Raison Social / Recruteur</label>
                                                            <input type="text" name="company_name" placeholder="Raison sociale ou Prénom et Nom du rectruteur" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Nombre de mois du contrat</label>
                                                            <input type="number" placeholder="Nombre de mois du contrat" name="contract_duration" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Montant mensuel</label>
                                                            <input type="text" placeholder="Montant mensuel du contract" name="contract_amount" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Date de début du contrat</label>
                                                            <input type="date" placeholder="Date de début" name="date_debut" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Date de fin du contrat</label>
                                                            <input type="date" placeholder="Date de fin" name="date_fin" className="form-control" onChange={this.handleChangeHiredBakeliste} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                        <i class="fas fa-times-circle"></i> &nbsp;
                                                        Quitter
                                                    </button>
                                                    {!this.state.hiredBakelisteInProgress &&
                                                        <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.onSubmitHiredBakeliste(e)}>
                                                            <i class="fas fa-edit"></i>&nbsp;
                                                            Valider
                                                        </button>
                                                    }
                                                    {this.state.hiredBakelisteInProgress &&
                                                        <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                                                            Validation en cours &nbsp;
                                                        <i className="fas fa-spinner fa-spin"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Success coach assignation toast alert */}
                {this.state.isCoachAssignationSuccessAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i class="fas fa-check-double"></i> &nbsp;&nbsp;
                        Assignation effectuée avec succès.
                    </div>
                }

                {/* Success update bakeliste infos toast alert */}
                {this.state.successUpdateBakelisteInfosAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i class="fas fa-check-double"></i> &nbsp;&nbsp;
                        Bakeliste infos modifié avec succès.
                    </div>
                }

                {/* Success hired bakeliste toast alert */}
                {this.state.successHiredAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i class="fas fa-check-double"></i> &nbsp;&nbsp;
                        Statut du bakeliste modifié avec succès.
                    </div>
                }

                {/* Success completed formation toast alert */}
                {this.state.successCompletedFormationAlert === true &&
                    <div className="alert alert-success text-center upsuccess-alert">
                        <i class="fas fa-check-double"></i> &nbsp;&nbsp;
                        La formation du bakeliste a été arrêtée avec succès.
                    </div>
                }

                {/* Error completed formation toast alert */}
                {this.state.completedFormationRequestError === true &&
                    <div className="alert alert-danger text-center toast-error-alert">
                        <i class="fas fa-exclamation-triangle"></i> &nbsp;&nbsp;
                        Un problème est survenu. Réessayer plus tard.
                    </div>
                }

            </div>
        )
    }
}
