import React, { Component } from "react";

import axios from "axios";
import * as Sentry from "@sentry/browser";
import { MagicSpinner } from "react-spinners-kit";
import API from "../../variablesGlobales";
import $ from "jquery";

import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import { Link, NavLink } from "react-router-dom";

import Menu from "../menu/Menu";
import { findDOMNode } from "react-dom";

export default class Bakelistegroupe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      fields: {},
      userID: window.sessionStorage.getItem("user_id"),
      Sous_groupes: [],
      liste:[],
      sgroupeInfos: []
    };
  }
  componentDidMount() {
    this.__getBakelisteBySousGroupes();
    this.__getSousGroupes();
  
  }

  __getSousGroupes = () => {
    axios
      .get(this.state.api + "sous__groupes" )
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            Sous_groupes: res.data.data.reverse(),
          });
        }
        console.log(this.state.Sous_groupes);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  __getBakelisteBySousGroupes = () => {
    axios
      .get(this.state.api + "liste_bakeliste_sous_groupe/30" )
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            liste: res.data.data.reverse(),
          });
        }
        console.log(this.state.liste);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //Sentry.captureException(error);
      });
  };

  onGetSousGroupeInfos(sgroup) {
    this.setState({
      sgroupeInfos: sgroup

    });
    console.log(this.state.sgroupeInfos, "bfuefgjb")
  }

 
  render() {
    const options = {
      // clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
    };
    return (
      <div>
        <Menu />
        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Liste des membres d'un groupes</h1>
            <ol class="breadcrumb">
              <li>
                <NavLink to="dashboard-admin">
                  <i class="fa fa-dashboard"></i> Home
                </NavLink>
              </li>
              <li className="active">Liste des membres d'un groupes</li>
            </ol>
          </section>
          <ul class="nav nav-tabs">
            {this.state.Sous_groupes.map((item, index) => (
            <li class="nav-item">
              <a class="nav-link active" href="#"  onClick={() => {this.onGetSousGroupeInfos() }} >{item.name}</a>
            </li>
            ))}
          </ul>


          <BootstrapTable
            data={this.state.liste}
            striped={true}
            hover={true}
            condensed={true}
            multiColumnSort={2}
            options={options}
            pagination
            search={true}
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              {" "}
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="group_name">Nom</TableHeaderColumn>
            <TableHeaderColumn dataField="group_name">Nom</TableHeaderColumn>
            <TableHeaderColumn dataField="group_name">Nom</TableHeaderColumn>
            <TableHeaderColumn dataField="group_name">Nom</TableHeaderColumn>
          </BootstrapTable>
       
          </div>
      </div>
    );
  }
}
