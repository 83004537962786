import React, {Component} from 'react';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton ,ButtonGroup  } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import API from '../../variablesGlobales';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';
import CKEditor from "react-ckeditor-component";

import {  NavLink } from 'react-router-dom';
import './ControleFonction.js';
import RI from '../reglementInterieur/reglementInterieur';
import Reporting from '../reportings/Reportings'
export default class Controles extends Component {
    constructor(props) {
        
            super(props);
            this.state = {
              isPageLoading: false,
              api: API.API,
              regInt: [],
          isEmptyData: false,
          isPageLoadingError: false,
          fields: {},
          errors: {},
          isAdmin: false,
          isCoach: false,
          isBakeliste: false,
          token: window.sessionStorage.getItem('token'),
          userStatus: window.sessionStorage.getItem('user_status'),
          userID: window.sessionStorage.getItem('user_id'),
          isHideEmptyText: false,
          isShowAddRIForm: false,
          content: '',
          addRIInProgress: false,
          isShowUpdateRIForm: false,
          regInItem: [],
          isShowingRIContent: false,
          _fields: {},
          updateRIInProgress: false,
          updateErrorAlert: false,
          updateRISuccessAlert: false,
          
              reportings: [],
            domains: [],
            // api: API.API,
            // fields: {},
            reportingEncours: false,
            addReportingError: false,
            // userStatus: window.sessionStorage.getItem('user_status'),
            currentConnecteduserID: window.sessionStorage.getItem('user_id'),
            coachID: window.sessionStorage.getItem('coach_id'),
            // isAdmin: false,
            // isCoach: false,
            // isBakeliste: false,
            bakelistesByCoach: [],
            allBakeliste: [],
            listBakeliste: [],
            reportingByBakeliste: [],
            bakelistReportsTab: [],
            bakelistEmptyReportsTab: {
                id: "",
                date: "",
                tache: "",
                status: "",
                commentaire: "",
                using_links: "",
                lieu: "",
                app_name: "",
                technology: "",
                bakeliste_id: "",
                coach_id: "",
                created_at: "",
                updated_at: "",
                deleted_at: ""
            },
            reporting_id: 0,
            dateValue: '',
            dateFRValue: '',
            tacheValue: '',
            statutValue: '',
            commentaireValue: '',
            using_linksValue: '',
            lieuValue: '',
            appNameValue: '',
            technologyValue: '',
            bakelisteIDValue: 0,
            updateDateValue: '',
            coachIDValue: 0,
            updateFields: {},
            updateReportingPending: false,
            updateReportingID: 0,
            bakelisteAddReportLoading: false,
            currentPage: 1,
            itemPerPage: 10,
            addReportingSuccessAlert: false,
            updateReportingSuccessAlert: false,
            updateReportingStatusSuccessAlert: false,
            isUpdateStatusError: false,
            //errors: {},
            emptyDate: false,
            emptyLieu: false,
            emptyStatus: false,
            emptyTache: false,
            isLoadingBakelisteData: false,
            reporting_lastfill:[],
            last_date: 0,
            search:null,
            staatus:'Terminé',
         date:'',
         lieeu:'bakeli',
         app_namee:'name_app',
         technologye:'techno',
         bakelister_id: window.sessionStorage.getItem('user_id'),
         using_links:'',
         commentaire:'',
         file:'',
         importInProgress: false,
        successImportAlert: false,
        countPointages: 0,
        todayPointages: [],
        todayPointagesCount: 0,
        yesterdayPointages: [],
        yesterdayPointagesCount: 0,
        weeklyPointages: [],
        weeklyPointagesCount: 0,
        monthlyPointages: [],
        monthlyPointagesCount: 0,

        delays: [],
          currentMonthDelays: [],
          currentMonthDelayCount: 0,
          currentDayDelayData: [],
          currentDayDelayCount: 0,
          absences: [],
          currentMonthAbsences: [],
          currentMonthAbsenceCount: 0,
          currentDayAbsenceData: [],
          currentDayAbsenceCount: 0,
          delaysAbsences: [],

          coach: [],
          tache: [],
          
          bakeliste: [],
          loading: true,
          
          assignationEncours: false,
          tachesByCoach: [],
          tachesByCoache: [],
          
          tachesByBakeliste: [],
          tacheStatusUpdateField: {},
          tacheUpdatedInProgress: false,
          updateTacheID: 0,
          isUpdatedError: false,
          tacheContent: '',
          tacheState: '',
          tacheDuration: '',
          tacheDate: '',
          tacheTodoDate: '',
          tacheCoachID: 0,
          tacheID: 0,
          isTache: true,
          tacheBakelisteID: 0,
          utFields: {},
          utInProgress: false,
          updateTacheStatusError: false,
          assignationSuccessAlert: false,
          isAssignationError: false,
          updateTacheStatusSuccess: false,
          updateTacheRequestSuccessAlert: false,
          updateTacheRequestError: false,
          selectOptions: [],
          selected: [],
          onlySelect: false,
          groupySelect: false,
          noSavedAndSavedTrueAlert: false,
          noSavedData: [],
    
            };
    
            this.handleChange = this.handleChange.bind(this);
            this.addRISubmitForm = this.addRISubmitForm.bind(this);
            this.onChange = this.onChange.bind(this);
            this.showUpdateRIForm = this.showUpdateRIForm.bind(this);
            this.updateRiHandleChange = this.updateRiHandleChange.bind(this);
            this.updateRiContentSubmit = this.updateRiContentSubmit.bind(this);
            this.handleChange = this.handleChange.bind(this);
        this.onUpdateReportingStatus = this.onUpdateReportingStatus.bind(this);
        this.onUpdateReportingRequest = this.onUpdateReportingRequest.bind(this);
        this.updateHandleChange = this.updateHandleChange.bind(this);
        this.filterList = this.filterList.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.fileInput = React.createRef();
        this.fileUploadHandler = this.fileUploadHandler.bind(this);
        this.utHandleChange = this.utHandleChange.bind(this);
        this.updateTacheByCoach = this.updateTacheByCoach.bind(this);
    }

    componentDidMount = () => {
      this.checkConnectedUserStatus();
      this.onGetReglementInterieur();
        // this.fadeOutBakelisteReports();
        this.getAllReportings();
        this.getBakelisteByCoach();
        this.getReportingByBakeliste();
        this.getAllBakeliste();
        this.onGetPeriodicalPointages();
        this.onGetAbsence();
      this.onGetDelay();
      this.onGetAllDelayAndAbsence();
      this.getAllTaches();
      this.getTachesAssignedByCoach();
      this.getBakelisteByCoach();
      this.getTachesByBakeliste();
        this.setState({
          isCoach: true,
          isAdmin: false,
          isBakeliste: false
        });
    }
    componentDidCatch(err, info){
        Sentry.captureException(err);
      }
  
      checkConnectedUserStatus = () => {
        let userStatus = this.state.userStatus;
        if(userStatus === 'admin'){
          this.setState({
            isAdmin: true
          })
        }else{
          this.setState({
            isCoach: true,
            isBakeliste: true
          })
        }
      }
  
      onGetReglementInterieur = () => {
        this.setState({
          isPageLoading: true
        })
        let api = this.state.api
        axios.get(api + 'reglement-interieurs')
          .then(response => {
            this.setState({
              isPageLoading: false
            })
            if(response.data.success){
              this.setState({
                regInt: response.data.data,
                isEmptyData: false,
                isShowingRIContent: true
              })
              response.data.data.forEach((item) => {
                this.setState({
                  idValue: item.id,
                  titleValue: item.title,
                  contentValue: item.ri_content
                })
              })
              console.log(this.state.titleValue)
            }else if(!response.data.success && response.data.message === 'empty-data'){
              this.setState({
                isEmptyData: true
              })
            }
          })
          .catch(error => {
            console.log(error.message)
            this.setState({
              isPageLoadingError: true
            })
            if (process.env.NODE_ENV !== 'production') {
              return;
            }
            Sentry.captureException(error);
          })
      }
  
      showOrHideForm = () => {
        if(!this.state.isHideEmptyText && !this.state.isShowAddRIForm){
          this.setState({
            isHideEmptyText: true,
            isShowAddRIForm: true
          })
        }else{
          this.setState({
            isHideEmptyText: false,
            isShowAddRIForm: false
          })
        }
      }
  
      handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
  
        this.setState({
          fields: fields
        })
        console.log(fields)
      }
  
      onChange(evt){
        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        
        this.setState({
          // fields: {
          //   content: newContent
          // },
          content: newContent
        })
      }
  
      onBlur(evt){
        console.log("onBlur event called with event info: ", evt);
      }
      
      afterPaste(evt){
        console.log("afterPaste event called with event info: ", evt);
      }
  
      addRISubmitForm = (e) => {
        e.preventDefault();
        this.setState({
          addRIInProgress: true
        })
        let data = this.state.fields;
        data["ri_content"] = this.state.content;
        console.log(this.state.fields)
  
        axios.post(this.state.api + 'reglement-interieurs', data)
          .then(response => {
            this.setState({
              addRIInProgress: false
            })
            if(response.data.success){
              $('#add-ri-form')[0].reset();
              this.onGetReglementInterieur();
              this.setState({
                content: '',
                fields: {}
              })
              this.setState({
                addRISuccessAlert: true
              })
              this.handleShowAndHideAlert('addRISuccessAlert')
            }else{
              this.setState({
                addRIErrorAlert: true
              })
              this.handleShowAndHideAlert('addRIErrorAlert')
            }
          })
          .catch(error => {
            console.log(error)
            this.setState({
              addRIInProgress: false,
              addRIErrorAlert: true
            })
            this.handleShowAndHideAlert('addRIErrorAlert')
            if (process.env.NODE_ENV !== 'production') {
              return;
            }
            Sentry.captureException(error);
          })
      }
  
      handleShowAndHideAlert(label) {
        setTimeout(() => {
          if (label === 'addRISuccessAlert') {
            this.setState({
              addRISuccessAlert: false
            });
          }
          if (label === 'addRIErrorAlert') {
            this.setState({
              addRIErrorAlert: false
            });
          }
          if(label === 'updateRISuccessAlert'){
            this.setState({
              updateRISuccessAlert: false
            })
          }
          if(label === 'updateErrorAlert'){
            this.setState({
              updateErrorAlert: false
            })
          }
        }, 5000);
      }
  
      showUpdateRIForm = () => {
        console.log('ok')
        this.setState({
          isShowAddRIForm: false,
          isShowingRIContent: false,
          regInItem: [],
          content: this.state.contentValue,
          updateErrorAlert: false
        })
      }
  
      updateRiHandleChange = (e) => {
        let _fields = this.state._fields;
        _fields[e.target.name] = e.target.value;
        _fields["ri_id"] = this.state.idValue;
  
        if(e.target.name === 'title'){
          this.setState({
            titleValue: e.target.value
          })
        }
        if (!_fields["title"]) {
          _fields["title"] = this.state.titleValue;
        }
        if(e.target.name === 'ri_content'){
          this.setState({
            contentValue: e.target.value
          })
        }
        if (!_fields["ri_content"]) {
          _fields["ri_content"] = this.state.contentValue;
        }
  
        this.setState({
          _fields: _fields
        })
        console.log(_fields)
      }
  
      updateRiContentSubmit = (e) => {
         e.preventDefault();
         this.setState({
           updateRIInProgress: true
         })
         let _data = this.state._fields;
         _data["ri_content"] = this.state.content;
         _data["title"] = this.state.titleValue;
         console.log(_data);
  
         var api = this.state.api;
         var ri_id = this.state.idValue;
         axios.post(api + 'update-reglement-interieur/' + ri_id, _data)
          .then(response => {
            this.setState({
              updateRIInProgress: false
            })
  
            if(response.data.success){
              this.onGetReglementInterieur();
              $('#update-ri-form')[0].reset();
              this.setState({
                isShowingRIContent: true,
                updateRISuccessAlert: true,
                _fields: {}
              })
              this.handleShowAndHideAlert('updateRISuccessAlert')
            }else{
              console.log(response);
              this.setState({
                updateErrorAlert: true,
                updateRIInProgress: false
              })
              this.handleShowAndHideAlert('updateErrorAlert')
            }
          })
          .catch(error => {
            console.log(error.message);
            this.setState({
              updateErrorAlert: true,
              updateRIInProgress: false
            })
            this.handleShowAndHideAlert('updateErrorAlert')
            if (process.env.NODE_ENV !== 'production') {
              return;
            }
            Sentry.captureException(error);
          })
  
      }
  
      returnBackRiContent = () => {
        this.setState({
          isShowingRIContent: false
        })
      }
  // reporting
  handleShowAndHideAlertRP(label) {
    setTimeout(() => {
        if (label === 'addSuccessAlert') {
            this.setState({
                addReportingSuccessAlert: false
            });
        }
        if (label === 'updateSuccessAlert') {
            this.setState({
                updateReportingSuccessAlert: false
            });
        }
        if(label === 'updateStatusSuccessAlert'){
            this.setState({
                updateReportingStatusSuccessAlert: false
            })
        }
        if(label === 'addReportErrorAlert'){
            this.setState({
                updateReportingSuccessAlert: false
            })
        }
        if(label === 'updateErrorAlert'){
            this.setState({
                updateReportingError: false
            })
        }
        if(label === 'emptyLieu'){
            this.setState({
                emptyLieu: false
            })
        }
        if(label === 'emptyTache'){
            this.setState({
                emptyTache: false
            })
        }
        if(label === 'emptyDate'){
            this.setState({
                emptyDate: false
            })
        }
        if(label === 'emptyStatus'){
            this.setState({
                emptyStatus: false
            })
        }
    }, 5000);
}

getAllReportings = () => {
    axios.get(this.state.api + 'reporting_bakelistes')
        .then(res => {
             let reportings = res.data.data;
          
            this.setState({
                reportings: reportings
            })
            //console.log(this.state.reportings);
            //this.getBakelisteNotFillreporting(this.state.reportings);
           
        })
        .catch(error => {
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}

getBakelisteByCoach = () => {
    axios.get(this.state.api + `bakelistes-by-coach/${this.state.currentConnecteduserID}`)
        .then(res => {
            var data = res.data.data;
            if (data !== undefined) {
                this.setState({
                    bakelistesByCoach: data,
                });
            }

            //console.log(this.state.bakelistesByCoach, "lolo")
        })
        .catch(error => {
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}


getAllBakeliste = () => {
    axios.get(this.state.api + `liste-des-bakelistes-en-formation`)
        .then(res => {
            var data = res.data.data;
            if (data !== undefined) {
                this.setState({  
                    allBakeliste: data,
                    listBakeliste:data
                });
            }

            console.log(this.state.allBakeliste, "lolo")
        })
        .catch(error => {
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}

getReportingByBakeliste = () => {
    this.setState({
        isLoadingBakelisteData: true
    })
    axios.get(this.state.api + `reporting-by-bakeliste/${this.state.currentConnecteduserID}`)
        .then(res => {
            var data = res.data.data;
            console.log(data,"reporting")
            this.setState({
                isLoadingBakelisteData: false
            })
            if (data !== undefined) {
                this.setState({
                    reportingByBakeliste: data.reverse(),
                });
            }
           // console.log(this.state.reportingByBakeliste, "lolo")
        })
        .catch(error => {
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}

getReportingInfos(reporting_id) {
    this.setState({
        reporting_id: reporting_id
    })
}

onUpdateReportingStatus = (e) => {
    e.preventDefault();
    this.setState({
        updateReportingStatusInProgress: true
    })
    var api = API.API;
    console.log(this.state.reporting_id)
    axios.post(api + 'update-reporting-status/' + this.state.reporting_id, this.state.fields)
        .then(response => {
            if(response.data.success === true){
                this.getReportingByBakeliste();
                $('.close').click();
                
                this.setState({
                    updateReportingStatusSuccessAlert: true,
                    updateReportingStatusInProgress: false
                });

                this.handleShowAndHideAlertRP('updateStatusSuccessAlert');
            }else{
                console.log(response);
                this.setState({
                    updateReportingStatusInProgress: false,
                    isUpdateStatusError: true
                });
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({
                updateReportingStatusInProgress: false,
                isUpdateStatusError: true
            });
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}


handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    console.log("handle change", e.target.value)
    fields["bakeliste_id"] = window.sessionStorage.getItem('user_id')
    fields["coach_id"] = window.sessionStorage.getItem('coach_id')
    this.setState({
        fields
    });
    console.log("handle change", this.state.fields)
}

onValidateAddReportingForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let isValidForm = true;

    if (!fields["lieu"]) {
        isValidForm = false;
        this.setState({
            emptyLieu: true
        })
        this.handleShowAndHideAlert('emptyLieu');
        errors["emptyLieu"] = "*Ce champ est obligatoire";
    }
    if (!fields["tache"]) {
        isValidForm = false;
        this.setState({
            emptyTache: true
        })
        this.handleShowAndHideAlert('emptyTache');
        errors["emptyTache"] = "*Ce champ est obligatoire";
    }
    if (!fields["date"]) {
        isValidForm = false;
        this.setState({
            emptyDate: true
        })
        this.handleShowAndHideAlert('emptyDate');
        errors["emptyDate"] = "*Ce champ est obligatoire";
    }
    if (!fields["status"] || fields["status"] === '--Choisir un status--') {
        if(fields["status"] === '--Choisir un status--'){
            fields["status"] = ''
        }
        isValidForm = false;
        this.setState({
            emptyStatus: true
        })
        this.handleShowAndHideAlert('emptyStatus');
        errors["emptyStatus"] = "*Ce champ est obligatoire";
    }

    this.setState({
        errors: errors
    })

    return isValidForm;
}

addReporting() {
    console.log("report add", this.state.fields);
    if(this.onValidateAddReportingForm()){
        this.setState({
            reportingEncours: true
        })
        var eventProperties = {
            'user_email': this.state.email
           };
        const dataCoach = this.state.fields;
        console.log(dataCoach)
        console.log()
        axios.post(this.state.api + 'reporting_bakelistes', this.state.fields)
            .then(res => {
                if (res.status === 200) {
                    this.getReportingByBakeliste();
                    $('.close').click();
                    this.handleShowAndHideAlertRP('addSuccessAlert');
                    // amplitude.getInstance().logEvent('REPORTING_BAKELISTE_TYPE', eventProperties);
                    $('#add-reporting-form')[0].reset()
                    this.setState({
                        addReportingSuccessAlert: true,
                        reportingEncours: false,
                        fields: {}
                    });
                    
                    
                }
            })
            .catch(error => {
                this.setState({
                    reportingEncours: false,
                    addReportingError: true
                });
                this.handleShowAndHideAlert('addReportErrorAlert');
               // amplitude.getInstance().logEvent('Reporting_BAKELISTE_Error', eventProperties);
                console.log(error);
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            });
    }
    
}



handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
}


createCustomClearButton = (onClick) => {
    return (
        <ClearSearchButton
            btnText='Effacer'
            btnContextual='btn-warning'
            className='my-custom-class'
            onClick={e => this.handleClearButtonClick(onClick)} />
    );
}

getReportingInfosForUpdated = (reporting) => {
    this.setState({
        updateReportingID: reporting.id,
        dateValue: reporting.date,
        dateFRValue: reporting.date_fr,
        tacheValue: reporting.tache,
        updateDateValue: reporting.forUpdateDateFormat,
        statutValue: reporting.status,
        commentaireValue: reporting.commentaire,
        using_linksValue: reporting.using_links,
        lieuValue: reporting.lieu,
        appNameValue: reporting.app_name,
        technologyValue: reporting.technology,
        bakelisteIDValue: reporting.bakeliste_id,
        coachIDValue: reporting.coach_id,
        isUpdateStatusError: false
    })
    console.log(reporting)
}

updateHandleChange(e) {
    let fields = this.state.updateFields;
    fields[e.target.name] = e.target.value;
    if (e.target.name === 'tache') {
        this.setState({
            tacheValue: e.target.value
        })
    }
    if (e.target.name === 'technology') {
        this.setState({
            technologyValue: e.target.value
        })
    }
    if (e.target.name === 'using_links') {
        this.setState({
            using_linksValue: e.target.value
        })
    }
    if (e.target.name === 'app_name') {
        this.setState({
            appNameValue: e.target.value
        })
    }
    if (e.target.name === 'commentaire') {
        this.setState({
            commentaireValue: e.target.value
        })
    }
    if (e.target.name === 'status') {
        this.setState({
            statutValue: e.target.value
        })
    }
    if (e.target.name === 'lieu') {
        this.setState({
            lieuValue: e.target.value
        })
    }
    if (e.target.name === 'date') {
        this.setState({
            updateDateValue: e.target.value
        })
    }

    if (!fields["tache"]) {
        fields["tache"] = this.state.tacheValue;
    }
    if (!fields["technology"]) {
        fields["technology"] = this.state.technologyValue;
    }
    if (!fields["using_links"]) {
        fields["using_links"] = this.state.using_linksValue;
    }
    if (!fields["app_name"]) {
        fields["app_name"] = this.state.appNameValue;
    }
    if (!fields["commentaire"]) {
        fields["commentaire"] = this.state.commentaireValue;
    }
    if (!fields["status"]) {
        fields["status"] = this.state.statutValue;
    }
    if (!fields["lieu"]) {
        fields["lieu"] = this.state.lieuValue;
    }
    if (!fields["date"]) {
        fields["date"] = this.state.updateDateValue;
    }
    //fields["date"] = this.state.dateValue;
    fields["bakeliste_id"] = this.state.bakelisteIDValue;
    fields["coach_id"] = this.state.coachID;
    this.setState({
        updateFields: fields
    });

    console.log(fields);
}

onUpdateReportingRequest = (e) => {
    e.preventDefault();
    this.setState({
        updateReportingPending: true
    });
    let updateData = this.state.updateFields;
    axios.put(this.state.api + 'reporting_bakelistes/' + this.state.updateReportingID, updateData)
        .then(response => {
            // let reportingBakeliste = response.data.data;

            if(response.data.success === true){
                this.getReportingByBakeliste();
                $('.close').click();
                this.setState({
                    updateReportingPending: false,
                    updateReportingSuccessAlert: true
                });
                this.handleShowAndHideAlert('updateSuccessAlert');
            }else{
                this.setState({
                    updateReportingPending: false,
                    updateReportingError: true
                });
                this.handleShowAndHideAlert('updateErrorAlert');
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({
                updateReportingPending: false,
                updateReportingError: true
            });
            this.handleShowAndHideAlert('updateErrorAlert');
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}
  
statusFormatter = (cell) => {
    return ( 
        <span>
            {cell === 'A Faire' &&
                <span className="label label-danger">{cell}</span>
            }
            {cell === 'En Cours' &&
                <span className="label label-warning">{cell}</span>
            }
            {cell === 'Terminé' &&
                <span className="label label-success">{cell}</span>
            }
        </span>
    );
}
nbjoursFormatter(lastReportingDays, lastReportingMonths){
    
    const tab1 =[2,3,4];
    const tab2 =[5,6,7,8];
    const tab3 =[9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
   
    if(lastReportingDays === 0 && lastReportingMonths > 0){
        return (
            <span className="badge badge-danger"> {lastReportingMonths} &nbsp;mois</span>
        )
    }

    if(lastReportingDays > 0 && lastReportingMonths > 0){
        return (
            <span className="badge badge-danger"> {lastReportingMonths} mois &nbsp;{lastReportingDays}&nbsp;j</span>
        )
    }
    
    if(lastReportingDays > 0 && lastReportingMonths === 0){
    
    for (const i of tab1) {
        if (i === lastReportingDays) {
            return (
                <span className="badge badge-info"> {lastReportingDays} &nbsp;jours</span>
            )
        }  
    }

    for (const j of tab2) {
        if (j === lastReportingDays) {
            return (
                <span className="badge badge-warning"> {lastReportingDays} &nbsp;jours</span>
            )
        }  
    }

    for (const k of tab3) {
        if (k === lastReportingDays) {
            return (
                <span className="badge badge-danger"> {lastReportingDays} &nbsp;jours</span>
            )
        }  
    }
 }
    if (lastReportingDays ===1 && lastReportingMonths ===0) {
        return (
            <span className="badge badge-success"> {lastReportingDays} &nbsp;jour</span>
        )
    } 
 
}


bakelistesRepStatusFormatter = (cell, row) => {
    return (
        <div>
            {cell === 'A Faire' &&
                <span className="label label-danger bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
            }
            {cell === 'En Cours' &&
                <span className="label label-warning bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
            }
            {cell === 'Terminé' &&
                <span className="label label-success bsi-status" data-toggle="modal" data-target="#statusModal" onClick={() => this.getReportingInfos(row.id)}> {cell} </span>
            }
        </div>
    );
}

commentFormatter = (cell, row) => {
    return (
        <div>
            {cell !== null &&
                <p>{cell}</p>
            }
            {cell === null &&
                <span className="label label-warning empty-comment">Aucun commentaire sur cette tâche</span>
            }
        </div>
    );
}

tacheFormatter = (cell, row) => {
    return(
        <span>
            <span className="tache-link" data-toggle="modal" data-target="#showReportingItemModal" onClick={() => this.getReportingInfosForUpdated(row)}> {cell} </span>
        </span>
    );
}

actionsFormatter = (cell, row) => {
    return (
        <div className="actions-btn-container">
            <button onClick={() => this.getReportingInfosForUpdated(row)} type="button" className="btn btn-warning bsi-action-update-btn" id="btn_update" data-toggle="modal" data-target="#updateReportingModal">
                <i className="fas fa-edit"></i>
            </button>
            <button onClick={() => this.getReportingInfosForUpdated(row)} type="button" className="btn btn-info bsi-action-show-btn" id="btn_update" data-toggle="modal" data-target="#showReportingItemModal">
                <i className="fas fa-eye"></i>
            </button>
        </div>
    );
}

filterList = (event) => {
    var updatedList = this.state.allBakeliste;
    
        updatedList = updatedList.filter((item) => {
            return item.first_name.toLowerCase().search(
                event.target.value.toLowerCase()) !== -1 || item.last_name.toLowerCase().search(
                    event.target.value.toLowerCase()) !== -1 || item.email.search(
                        event.target.value.toLowerCase()) !== -1  ;              
        });
        this.setState({listBakeliste: updatedList });
}
customHeaderClass() {
    return 'custom-header-tache';
  }
  columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {

    return rowIdx % 2 === 0 ? 'td-column-function-even-example' : 'td-column-function-odd-example';
  }
  
componentWillMount(){
this.setState({
    listBakeliste: this.state.allBakeliste
})
}
handleInputChange(event) {
 this.setState({
importInProgress: true
});
const toto = new Date();
const newLocal = this.state;
newLocal.date = toto.getFullYear() + '-' + (toto.getMonth() + 1) + '-' + toto.getDate();

const fields = this.state.fields;
 fields["date"]= this.state.date;
 fields["bakeliste_id"]=this.state.bakelister_id;
 fields["status"]=this.state.staatus;
 fields["lieu"]=this.state.lieeu;
 fields["app_name"]=this.state.app_namee;
 fields["technology"]=this.state.technologye;
 fields["coach_id"]=this.state.coachID;
 fields["using_links"]=this.state.using_links;
 fields["commentaire"]=this.state.commentaire;
 if (event.target.name === 'tache') {
  console.log(event.target.files)
  this.createImage(event.target.files[0]);
  fields["tache"] = event.target.files[0]
} else {
  fields[event.target.name] = event.target.value;
}
this.setState({
  fields
})

console.log(fields);
this.fileUploadHandler();
}
createImage = (file) => {
let reader = new FileReader();
reader.onload = (e) => {
  this.setState({
    file: e.target.result
  })
};
console.log(this.state.file)
reader.readAsDataURL(file);
}
fileUploadHandler = () => {
this.setState({
  importInProgress: true
})
const filer = new FormData();
//  filer.append('date',this.state.fields.date);
  filer.append('tache', this.state.fields.tache);
  filer.append('coach_id', this.state.fields.coach_id);
  filer.append('bakeliste_id',this.state.fields.bakeliste_id);
  filer.append('status',this.state.fields.status);
  filer.append('lieu',this.state.fields.lieu);
  filer.append('app_name',this.state.fields.app_name);
  filer.append('technology',this.state.fields.technology);
  filer.append('using_links',this.state.fields.using_links);
  filer.append('commentaire',this.state.fields.commentaire);
// let data = this.state.fields;
 console.log(this.state.fields,"data")
  //console.log(this.state.reporting_id,'id')
    axios.post(this.state.api +'reporting_import_file',filer)
      .then(response => {
        //console.log(response.status)
        if (response.status === 200) {
       $('.close').click();
          console.log('click')
          $('body').removeClass('modal-open');
          console.log('open')
          $('.modal-backdrop').removeClass('modal-backdrop');
          console.log('backdrop')
          $('.fade').removeClass('fade')
          $('.in').removeClass('in')
          // this.handleShowAndHideAlerte('successImport');
          this.getReportingByBakeliste();
          this.setState({
            importInProgress: false,
            successImportAlert: true,
          })
          setTimeout(() => {
            this.setState({
              successImportAlert: false
            })
          }, 5000);
         
      }else{  
          //console.log(response);
          console.log('non ok')
          this.setState({
            importInProgress: false
          });
      }
  })
  .catch(error => {
      console.log(error);
      this.setState({
        importInProgress: false
      });
      if (process.env.NODE_ENV !== 'production') {
          return;
      }
      Sentry.captureException(error);
  })
  
 //console.log("ok");
}
triggerClick = () => {
this.fileInput.current.click();
}
  //  pointage
  onGetAllPointages = () => {
    this.setState({
      isPageLoading: true
    })

    const baseApi = this.state.api;
    axios.get(baseApi + 'bakeliste-liste-presences')
      .then(response => {
        this.setState({
          isPageLoading: false
        })
        if (response.data.success) {
          this.setState({
            pointages: response.data.data
          })
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isPageLoading: false,
        })
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  onGetPeriodicalPointages = () => {
    this.setState({
      isPageLoading: true
    })

    const apiBaseUrl = this.state.api;
    axios.get(apiBaseUrl + 'pointme-infos')
      .then(response => {

        this.setState({
          pointages: response.data.dataAllPointage,
          countPointages: response.data.countDataAllPointage,
          todayPointages: response.data.dataDaily,
          todayPointagesCount: response.data.countDataDaily,
          yesterdayPointages: response.data.dataYesterday,
          yesterdayPointagesCount: response.data.countDataYesterday,
          weeklyPointages: response.data.dataWeekly,
          weeklyPointagesCount: response.data.countDataWeekly,
          monthlyPointages: response.data.dataMonthly,
          monthlyPointagesCount: response.data.countDataMonthly,
          isPageLoading: false,
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isPageLoading: false,
        })
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
              className={ `btn btn-primary volkeno-red-bcolor` }>
              MyCustomBtn
            </button> */}
      </ButtonGroup>
    );
  }

  // componentDidCatch(err, info) {
  //   Sentry.captureException(err);
  // }

  statusFormatterpoint = (cell, row) => {
    if(cell === 'admin'){
      return (
        <span className="label label-info"> {row.user_status} </span>
      )
    }
    if(cell === 'bakeliste'){
      return (
        <span className="label label-danger"> {row.user_status} </span>
      )
    }
    if(cell === 'coach'){
      return (
        <span className="label label-success label-vgreen"> {row.user_status} </span>
      )
    }
  }
     
// retard absnce
onGetAllDelayAndAbsence = () => {
  this.setState({
    isPageLoading: true
  })

  axios.get(this.state.api + 'delays-absences')
    .then(response => {
      if(response.data.success === true){
        this.setState({
          delaysAbsences: response.data.data,
          // currentMonthAbsences: response.data.currentMonthAbsenceData,
          // currentMonthAbsenceCount: response.data.currentMonthAbsenceDataCount,
          // currentDayAbsenceData: response.data.currentDayAbsenceData,
          // currentDayAbsenceCount: response.data.currentDayAbsenceDataCount
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
}

/**
 * Display delay
 */
onGetDelay = () => {
  this.setState({
    isPageLoading: true
  })

  axios.get(this.state.api + 'list-delays')
    .then(response => {
      this.setState({
        isPageLoading: false
      })
      if(response.data.success === true){
        this.setState({
          delays: response.data.delayData,
          currentMonthDelays: response.data.currentMonthDelayData,
          currentMonthDelayCount: response.data.currentMonthDelayDataCount,
          currentDayDelayData: response.data.currentDayDelayData,
          currentDayDelayCount: response.data.currentDayDelayDataCount
        })
      }
    })
    .catch(error => {
      this.setState({
        isPageLoading: false
      })
      console.log(error.message)
    })
}

/**
 * Display absence
 */
onGetAbsence = () => {
  this.setState({
    isPageLoading: true
  })

  axios.get(this.state.api + 'list-absences')
    .then(response => {
      if(response.data.success === true){
        this.setState({
          absences: response.data.absenceData,
          currentMonthAbsences: response.data.currentMonthAbsenceData,
          currentMonthAbsenceCount: response.data.currentMonthAbsenceDataCount,
          currentDayAbsenceData: response.data.currentDayAbsenceData,
          currentDayAbsenceCount: response.data.currentDayAbsenceDataCount
        })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
}

actionsFormatter = (cell, row) => {

}

createCustomButtonGroup = props => {
  return (
    <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
      { props.exportCSVBtn }
      {/* <button type='button'
        className={ `btn btn-primary volkeno-red-bcolor` }>
        MyCustomBtn
      </button> */}
    </ButtonGroup>
  );
}

handleClearButtonClick = (onClick) => {
  console.log('This is my custom function for ClearSearchButton click event');
  onClick();
}

createCustomClearButton = (onClick) => {
  return (
    <ClearSearchButton
      btnText='Effacer'
      btnContextual='btn-warning'
      className='my-custom-class'
      onClick={e => this.handleClearButtonClick(onClick)} />
  );
}

delayAbsenceDateFormatter = (cell, row) => {
  if(cell != null){
    return(
      <span> {cell} </span>
    );
  }else{
    return(
      <span className="label label-warning"> Ce champ est vide </span>
    );
  }
}

justifiedStatusFormatter = (cell, row) => {
  if(cell === true){
    return(
      <span className="label label-success"> justifié </span>
    )
  }else{
    return(
      <span className="label label-danger"> non justifié </span>
    )
  }
}
 // tache
 getAllTaches = () => {
  axios.get(this.state.api + 'taches')
      .then(res => {
          this.setState({
              loading: false
          })
          if(res.data.data !== undefined){
              this.setState({
                  tache: res.data.data.reverse(),
              });
          }
          //console.log(this.state.tache);
      })
      .catch(error => {
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
}

getTacheByCoach(coach_id) {
  //console.log(this.state.tachesByCoach + "baxma")
  let tachesByCoach = this.state.tachesByCoach;
  let allTaches = this.state.tache;
  //console.log(allTaches.length)
  if (allTaches.length > 0) {
      // allTaches.forEach((tache, index) => {
      //     if (coach_id === tache.coach_id) {
      //         tachesByCoach.push(tache)
      //     }
      // });
      allTaches.forEach(function(tache) {
          if (coach_id === tache.coach_id) {
              tachesByCoach.push(tache)
          }
      });
  }

  if (tachesByCoach !== undefined) {
      this.setState({
          tachesByCoach: tachesByCoach
      })
  }
}

getTachesAssignedByCoach = () => {
  axios.get(this.state.api + `taches-by-coach/${this.state.userID}`)
      .then(res => {
          if(res.data.data !== undefined){
              this.setState({
                  tachesByCoache: res.data.data.reverse(),
                  loading: false
              });
          }
      })
      .catch(error => {
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
}

// Bakeliste by coach
getBakelisteByCoach() {
  axios.get(this.state.api + 'bakelistes-by-coach/' + this.state.userID)
      .then(response => {
          var data = response.data.data;
          //console.log('coach bakelistes ', data)
          if (data !== undefined) {
              this.setState({
                  bakelistesByCoach: data
              })
          }
          data.forEach((item) => {
              let infos = 
                  {
                      label: item.first_name + ' ' + item.last_name,
                      value: item.id
                  }
              
              this.state.selectOptions.push(infos)
          })
          //console.log(this.state.selectOptions)
      })
      .catch(error => {
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
}

/**
* Activate only select assignation
*/
onOnlyAssignation = () => {
  this.setState({
      onlySelect: true,
      groupySelect: false
  })
}

/**
* Activate groupy select assignation
*/
onGroupyAssignation = () => {
  this.setState({
      onlySelect: false,
      groupySelect: true
  })
}

handleShowAndHideAlertt(label) {
  setTimeout(() => {
      if (label === 'successAssignation') {
          this.setState({
              assignationSuccessAlert: false
          });
      }
      if (label === 'errorAssignation') {
          this.setState({
              isAssignationError: false
          });
      }
      if(label === 'successStatusUpdate'){
          this.setState({
              updateTacheStatusSuccess: false
          })
      }
      if(label === 'tacheRequestSuccess'){
          this.setState({
              updateTacheRequestSuccessAlert: false
          })
      }
      if(label === 'tacheRequestError'){
          this.setState({
              updateTacheRequestError: false
          })
      }
      if(label === 'errorStatusUpdate'){
          this.setState({
              isUpdatedError: false,
              updateTacheStatusError: false
          })
      }
  }, 5000);
}

// assigner une tache a un bakeliste
assignTache() {
  this.setState({
      assignationEncours: true
  });

  let data = this.state.fields;

  if(this.state.onlySelect){
      data["is_only"] = 1;
      data["is_groupy"] = 0;
      data["selected_bakeliste"] = null;
  }else if(this.state.groupySelect){
      data["is_groupy"] = 1;
      data["is_only"] = 0;
      data["selected_bakeliste"] = this.state.selected;
  }

  console.log(data)

  axios.post(this.state.api + 'taches', data)
      .then(res => {
          if (res.data.success === true && res.data.nosavedStatus === false) {
              this.getTachesAssignedByCoach();
              $('.close').click();
              document.getElementById("create-tache-form").reset();
              $("#date_tach").val('');
              this.setState({
                  assignationEncours: false,
                  assignationSuccessAlert: true,
                  fields: {}
              });

              this.handleShowAndHideAlertt('successAssignation')
              
          }else if(res.data.success === true && res.data.nosavedStatus === true){
              this.getTachesAssignedByCoach();
              $('.close').click();
              document.getElementById("create-tache-form").reset();
              $("#date_tach").val('');
              this.setState({
                  assignationEncours: false,
                  assignationSuccessAlert: true,
                  noSavedAndSavedTrueAlert: true,
                  noSavedData: res.data.nosaved,
                  fields: {}
              });

              this.handleShowAndHideAlertt('successAssignation')
          }else if(res.data.success === false && res.data.nosavedStatus === true){
              this.setState({
                  assignationEncours: false,
                  isAssignationError: true
              });

              this.handleShowAndHideAlertt('errorAssignation')
          }else{
              this.setState({
                  assignationEncours: false,
                  isAssignationError: true
              });

              this.handleShowAndHideAlertt('errorAssignation')
          }
      })
      .catch(error => {
          console.log(error.message)
          this.setState({
              assignationEncours: false,
              isAssignationError: true
          });
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
}

renderNa(id) {
  if(this.state.tachesByCoach !== undefined){
      this.state.tachesByCoache.forEach((x) => {
          if (x.id === id) {
              this.setState({
                  nameTacheByCoache: x.name
              })
          }

          //console.log("super" + x.bakeli_user_id);
      })

      return (
          <td><Link to="#">{this.state.nameTache} </Link></td>
      )
  }
  
}

renderName(id) {
  if(this.state.tache !== undefined){
      this.state.tache.forEach((x) => {
          if (x.id === id) {
              this.setState({
                  nameTache: x.name
              })
          }

          //console.log("super" + x.bakeli_user_id);
      })

      return (
          <td><Link to="#">{this.state.nameTache} </Link></td>
      )
  }  
}


handleChanget(e) {
  let fields = this.state.fields;
  fields["coach_user_id"] = this.state.userID;
  fields[e.target.name] = e.target.value;
  this.setState({
      fields
  });
  console.log(fields)
}

// Retourne la liste de toutes les tâches assignées à un bakeliste
getTachesByBakeliste = () => {
  if (this.state.userStatus === 'bakeliste') {
      axios.get(this.state.api + 'taches-by-bakeliste/' + this.state.userID)
          .then(response => {
              if (response.data.success === true) {
                  let tachesByBakeliste = response.data.data;
                  //console.log(tachesByBakeliste)
                  if(tachesByBakeliste !== undefined){
                      this.setState({
                          tachesByBakeliste: tachesByBakeliste.reverse()
                      })
                  }
              } else {
                  console.log(response.data.success);
              }
          })
          .catch(error => {
              if (process.env.NODE_ENV !== 'production') {
                  return;
              }
              Sentry.captureException(error);
          })
  }
}

getTacheID = (tache) => {
  //console.log(tache.id)
  window.sessionStorage.setItem('btache_id', tache.id);
}

tacheStateFormatter(cell, row) {
  return (
      <span>
          {row.state === 'todo' &&
              <span className="label label-danger" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
          }
          {row.state === 'doing' &&
              <span className="label label-warning" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
          }
          {row.state === 'done' &&
              <span className="label label-success" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#utModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
          }
      </span>
  );
}

getTacheInfos = (tache) => {
 // console.log(tache);
  window.sessionStorage.setItem('tacheContent', tache.content);
  window.sessionStorage.setItem('tacheState', tache.state);
  window.sessionStorage.setItem('tacheDuration', tache.duration);

  if (this.state.isTache) {
      this.setState({
          tacheBakelisteID: tache.bakeli_user_id,
          tacheContent: tache.content,
          tacheState: tache.state,
          tacheDuration: tache.duration,
          tacheDate: tache.dateForEdit,
          tacheTodoDate: tache.todo_date,
          tacheCoachID: tache.coach_user_id,
          tacheURL: tache.tache_url,
          tacheID: tache.id
      })
  }
  //console.log(tache.bakeli_user_id)
}

tacheFormatter = (cell, row) => {
  return (
      <span className="modal-linky fullname-link" data-placement="top" title="Visualiser la tâche" data-toggle="modal" data-target="#tacheInfosModal" onClick={() => this.getTacheInfos(row)}><linkify> {row.content} </linkify></span>
  );
}

updateTBBHandle = (e) => {
  let fields = this.state.tacheStatusUpdateField;
  fields[e.target.name] = e.target.value;

  this.setState({
      tacheStatusUpdateField: fields
  });
  console.log(fields);
}

updateTacheByBakeliste = (e) => {
  e.preventDefault();

  this.setState({
      tacheUpdatedInProgress: true
  })
  let tacheID = window.sessionStorage.getItem('btache_id');
  //console.log(tacheID)
  let updateData = this.state.tacheStatusUpdateField;

  axios.post(this.state.api + 'update-tache-status-by-bakeliste/' + tacheID, updateData)
      .then(response => {
          if (response.data.success === true) {
              this.getAllTaches();
              this.getTachesByBakeliste();
              this.getTachesAssignedByCoach();
              $('.close').click();
              document.getElementById('update-tache-status-bybakeliste').reset();
              this.setState({
                  tacheUpdatedInProgress: false,
                  updateTacheStatusSuccess: true
              })

              this.handleShowAndHideAlertt('successStatusUpdate');
              
          }else {
              this.setState({
                  isUpdatedError: true,
                  tacheUpdatedInProgress: false,
                  updateTacheStatusError: true
              })

              this.handleShowAndHideAlertt('errorStatusUpdate');
          }
      })
      .catch(error => {
          console.log(error);
          this.setState({
              isUpdatedError: true,
              tacheUpdatedInProgress: false,
              updateTacheStatusError: true
          })
          this.handleShowAndHideAlertt('errorStatusUpdate');
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
}
actionsFormatter = (cell, row) => {
  return (
      <span>
          <button onClick={() => { this.getTacheInfos(row) }} id="show_item" className="btn btn-info show-item-btn" data-toggle="modal" data-target="#tacheInfosModal">
              <i className="fas fa-eye"></i>
          </button> &nbsp;&nbsp;
          <button onClick={() => { this.getTacheInfos(row) }} id="edit_item" className="btn btn-info edit-btn volkeno-orange-bcolor" data-toggle="modal" data-target="#editTacheModal">
              <i className="fas fa-edit"></i>
          </button>
      </span>

  );
}

utHandleChange = (e) => {
  let fields = this.state.utFields;
  fields[e.target.name] = e.target.value;
  fields["bakeli_user_id"] = this.state.tacheBakelisteID;
  fields["coach_user_id"] = this.state.tacheCoachID;
  //console.log(this.state.selected)
  if (e.target.name === 'content') {
      this.setState({
          tacheContent: e.target.value
      })
  }

  if (e.target.name === 'tache_url') {
      this.setState({
          tacheURL: e.target.value
      })
  }

  if (e.target.name === 'state') {
      this.setState({
          tacheState: e.target.value
      })
  }
  if (e.target.name === 'duration') {
      this.setState({
          tacheDuration: e.target.value
      })
  }
  if (e.target.name === 'todo_date') {
      this.setState({
          tacheTodoDate: e.target.value
      })
  }

  if (!fields["content"]) {
      fields["content"] = this.state.tacheContent;
  }
  if (!fields["tache_url"]) {
      fields["tache_url"] = this.state.tacheURL;
  }
  if (!fields["duration"]) {
      fields["duration"] = this.state.tacheDuration;
  }
  if (!fields["state"]) {
      fields["state"] = this.state.tacheState;
  }
  if (!fields["todo_date"]) {
      fields["todo_date"] = this.state.tacheTodoDate;
  }

  this.setState({
      utFields: fields
  })

  console.log(fields)
}

updateTacheByCoach = (e) => {
  e.preventDefault();
  //console.log(this.state.utFields);
  this.setState({
      utInProgress: true
  });

  let tacheID = this.state.tacheID;
  let updateData = this.state.utFields;

  axios.put(this.state.api + 'taches/' + tacheID, updateData)
      .then(response => {
          this.setState({
              utInProgress: false
          });
          if(response.data.success === true){
              this.getTachesAssignedByCoach();
              $('.close').click();
              document.getElementById('edit-tache-bycoach').reset();
              this.setState({
                  utFields: {},
                  updateTacheRequestSuccessAlert: true
              })
              this.handleShowAndHideAlertt('tacheRequestSuccess')
          }else{
              console.log(response);
              this.setState({
                  updateTacheRequestError: true
              })
              this.handleShowAndHideAlertt('tacheRequestError')
          }
      })
      .catch(error => {
          this.setState({
              updateTacheRequestError: true
          })
          this.handleShowAndHideAlertt('tacheRequestError')
          
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
}

coachFullnameFormatter = (cell, row) => {
  return(
      <NavLink to="#" className="bakeliste_coachname"> {cell} </NavLink>
  );
}
   
    render() {
      const {required} = this.props;
      const options = {
          clearSearch: true,
          clearSearchBtn: this.createCustomClearButton
      };
        if (this.state.isPageLoading) {
            return (
              <div className="component-manage-users">
                <Menu />
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
                <div className="row no-content-wrapper">
      
                  <section className="content-header">
                    <h1>
                    Contrôles
                    </h1>
                    <ol className="breadcrumb">
                      <li><Link to="/dashboard-admin">Accueil</Link></li>
                      <li className="active">Contrôles</li>
                    </ol>
                  </section>
      
                  <section className="content">
                    <div className="list_users_container">
                      <div id="loader"></div>
                    </div>
                  </section>
      
                </div>
              </div>
            );
          }
      
        return (
            <div className="component-manage-users">
              <Menu />
              {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
      
      
              <div className="row no-content-wrapper">
      
                <section className="content-header">
                  <h1>
                  Contrôles
                    </h1>
                  <ol className="breadcrumb">
                    <li><Link to="/dashboard-admin">Accueil</Link></li>
                    <li className="active">Contrôles</li>
                  </ol>
                </section>
                <section className="content">
            {/* Blocs contain bakelistes delay list and absence list */}
            <div className="row tab-data-row">
              <div className="col-md-12 tab-data-col">
                <div className="col-md-12 reporting-container">
                  <div className="list_prospect_container">
                    <div className="row prospect-row-row">
                      <div className="col-md-12 prospect-col">
                        <div className="nav-tabs-custom">
                          <div className="col-md-12 da-list-col">
                            <ul className="nav nav-tabs">
                              <li className="active"><NavLink to="#reporting" data-toggle="tab" aria-expanded="true">Reporting</NavLink></li>
                              <li className=""><NavLink to="#pointage" data-toggle="tab" aria-expanded="false">Pointage</NavLink></li>
                              <li className=""><NavLink to="#RA" data-toggle="tab" aria-expanded="false">Retard/Absence</NavLink></li>
                              <li className=""><NavLink to="#tache" data-toggle="tab" aria-expanded="false">Tâche</NavLink></li>
                              <li className=""><NavLink to="#RI" data-toggle="tab" aria-expanded="false">RI</NavLink></li>
                             
                            </ul>
                          </div>
                          <div className="tab-content">
                            <div className="tab-pane active" id="reporting">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 prospectw-table-col">
                                <div className="row">
                                    <div className="col-md-3" id="list_item">
                                    <input className="form-control filter-input" type="text" placeholder="Search" onChange={this.filterList} />
                                      
                                        <ul className="nav nav-tabs tabs-left" >
                                            {this.state.listBakeliste.map((x, y) => 
                                                y === 0 ?
                                            <li className="active"  key={x.id} onClick={(y) => this.fadeOutBakelisteReports(x.id)}><a href={'#' + y} data-toggle="tab">{x.first_name} {x.last_name}{this.nbjoursFormatter(x.lastReportingDays,x.lastReportingMonths)}</a> </li>
                                                :
                                                <li  key={x.id} onClick={(y) => this.fadeOutBakelisteReports(x.id)}><a href={'#' + y} data-toggle="tab" onClick={(y) => this.fadeOutBakelisteReports(x.id)}>{x.first_name} {x.last_name} {this.nbjoursFormatter(x.lastReportingDays,x.lastReportingMonths)}</a></li>        
                                            )}
                                        </ul>
                                       {/*  <ul id="page-numbers" className="pagination">
                                            {renderPageNumbers}
                                        </ul> */}
                                    </div>
                                    <div className="col-md-9" id="item_content">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="key={y}">
                                                <div className="table_container">
                                                    <BootstrapTable data={this.state.bakelistReportsTab}
                                                        striped={true} hover={true} condensed={true}
                                                        multiColumnSort={2} options={options}
                                                        pagination
                                                        search={true}>
                                                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='frenchDateFormat' dataSort={true}>Date</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='tache' dataFormat={this.tacheFormatter.bind(this)}>Tache</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='commentaire' dataFormat={this.commentFormatter.bind(this)}>Commentaire</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='status' dataFormat={this.statusFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>  
                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                </div>
                              </div>
                            </div>
                           
                            <div className="tab-pane" id="pointage">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-weekend-table-col">
                                <div className="box-body">
<div className="header-container-training">
 <div className="row da-row">
   <div className="nav-tabs-custom">
     <div className="col-md-12 da-list-col">
   <ul className="nav nav-tabs">
     <li className="active"><a href="#today" data-toggle="tab" aria-expanded="true">Aujourd'hui <span className="label label-default volkeno-green-label"> {this.state.todayPointagesCount} </span> </a></li>
     <li className=""><a href="#yesterday" data-toggle="tab" aria-expanded="false">Hier <span className="label label-default volkeno-green-label"> {this.state.yesterdayPointagesCount} </span> </a></li>
     <li className=""><a href="#weekly" data-toggle="tab" aria-expanded="false">Semaine <span className="label label-default volkeno-green-label"> {this.state.weeklyPointagesCount} </span> </a></li>
     <li className=""><a href="#monthly" data-toggle="tab" aria-expanded="false">Mois <span className="label label-default volkeno-green-label"> {this.state.monthlyPointagesCount} </span> </a></li>
     <li className=""><a href="#all" data-toggle="tab" aria-expanded="false"> Tout <span className="label label-default volkeno-green-label"> {this.state.countPointages} </span> </a></li>
     {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
   </ul>
</div>
<div className="tab-content">
 <div className="tab-pane active" id="today">
   <div className="row delay-table-row">
     <div className="col-md-12 delay-table-col">
       {/* TODO */}
       <BootstrapTable data={this.state.todayPointages}
         striped={true} hover={true} condensed={true}
         multiColumnSort={2} options={options}
         exportCSV
         pagination
         search={true}>

         <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
         {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
         <TableHeaderColumn dataAlign="center"
           width='200'
           dataField='frenchDateFormat'
           filter={{ type: 'TextFilter', delay: 1000 }}
           dataSort={true} >Date</TableHeaderColumn>
         <TableHeaderColumn dataAlign="center"
           width='200'
           dataField='userFullname'
           filter={{ type: 'TextFilter', delay: 1000 }}
         >Prénom et Nom</TableHeaderColumn>
         <TableHeaderColumn dataAlign="center"
           width='150'
           dataField='arriving_time'
           filter={{ type: 'TextFilter', delay: 1000 }}
         >Heure d'arrivée</TableHeaderColumn>
         <TableHeaderColumn dataAlign="center"
           width='150'
           dataField='departure_time'
           filter={{ type: 'TextFilter', delay: 1000 }}
         >Heure de départ</TableHeaderColumn>
         <TableHeaderColumn dataAlign="center"
           width='200'
           dataField='user_status'
           dataFormat={this.statusFormatterpoint.bind(this)}
           filter={{ type: 'TextFilter', delay: 1000 }}
         >Statuts</TableHeaderColumn>
         <TableHeaderColumn dataAlign="center"
           width='200'
           dataField='training_place'
           filter={{ type: 'TextFilter', delay: 1000 }}
         >Lieu</TableHeaderColumn>
       </BootstrapTable>
     </div>
   </div>
 </div>
 <div className="tab-pane" id="yesterday">
   <div className="row delay-table-row">
     <div className="col-md-12 delay-table-col">
       {/* TODO */}
       <BootstrapTable data={this.state.yesterdayPointages}
         striped={true} hover={true} condensed={true}
         multiColumnSort={2} options={options}
         exportCSV
         pagination
         search={true}>

       <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
       {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='frenchDateFormat'
         filter={{ type: 'TextFilter', delay: 1000 }}
         dataSort={true} >Date</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='userFullname'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Prénom et Nom</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='150'
         dataField='arriving_time'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Heure d'arrivée</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='150'
         dataField='departure_time'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Heure de départ</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='user_status'
         dataFormat={this.statusFormatterpoint.bind(this)}
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Statuts</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='training_place'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Lieu</TableHeaderColumn>
     </BootstrapTable>
   </div>
 </div>
</div>
<div className="tab-pane" id="weekly">
 <div className="row delay-table-row">
   <div className="col-md-12 delay-table-col">
     {/* TODO */}
     <BootstrapTable data={this.state.weeklyPointages}
       striped={true} hover={true} condensed={true}
       multiColumnSort={2} options={options}
       exportCSV
       pagination
       search={true}>

       <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
       {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='frenchDateFormat'
         filter={{ type: 'TextFilter', delay: 1000 }}
         dataSort={true} >Date</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='userFullname'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Prénom et Nom</TableHeaderColumn>
       {/* <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='name'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Nom de famille</TableHeaderColumn> */}
       <TableHeaderColumn dataAlign="center"
         width='150'
         dataField='arriving_time'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Heure d'arrivée</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='150'
         dataField='departure_time'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Heure de départ</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='user_status'
         dataFormat={this.statusFormatterpoint.bind(this)}
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Statuts</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='training_place'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Lieu</TableHeaderColumn>
     </BootstrapTable>
   </div>
 </div>
</div>
<div className="tab-pane" id="monthly">
 <div className="row delay-table-row">
   <div className="col-md-12 delay-table-col">
     {/* TODO */}
     <BootstrapTable data={this.state.monthlyPointages}
       striped={true} hover={true} condensed={true}
       multiColumnSort={2} options={options}
       exportCSV
       pagination
       search={true}>

       <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
       {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='frenchDateFormat'
         filter={{ type: 'TextFilter', delay: 1000 }}
         dataSort={true} >Date</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='userFullname'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Prénom et Nom</TableHeaderColumn>
       {/* <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='name'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Nom de famille</TableHeaderColumn> */}
       <TableHeaderColumn dataAlign="center"
         width='150'
         dataField='arriving_time'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Heure d'arrivée</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='150'
         dataField='departure_time'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Heure de départ</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='user_status'
         dataFormat={this.statusFormatterpoint.bind(this)}
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Statuts</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='training_place'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Lieu</TableHeaderColumn>
     </BootstrapTable>
   </div>
 </div>
</div>
<div className="tab-pane" id="all">
 <div className="row delay-table-row">
   <div className="col-md-12 delay-table-col">
     {/* TODO */}
     <BootstrapTable data={this.state.pointages}
       striped={true} hover={true} condensed={true}
       multiColumnSort={2} options={options}
       exportCSV
       pagination
       search={true}>

       <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
       {/* <TableHeaderColumn dataAlign="center" width='100' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='frenchDateFormat'
         filter={{ type: 'TextFilter', delay: 1000 }}
         dataSort={true} >Date</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='userFullname'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Prénom</TableHeaderColumn>
       
       <TableHeaderColumn dataAlign="center"
         width='150'
         dataField='arriving_time'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Heure d'arrivée</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='150'
         dataField='departure_time'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Heure de départ</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='user_status'
         dataFormat={this.statusFormatterpoint.bind(this)}
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Statuts</TableHeaderColumn>
       <TableHeaderColumn dataAlign="center"
         width='200'
         dataField='training_place'
         filter={{ type: 'TextFilter', delay: 1000 }}
       >Lieu</TableHeaderColumn>
     </BootstrapTable>
   </div>
 </div>
</div>
</div>
</div>
</div>
</div>
</div>

                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="RA">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-ete-table-col">
                                <div className="row da-count-row">
            <div className="col-md-6 delay-count-container">
              <h4 className="delay-count-title"> Retardataires </h4>
              <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Mensuel</span>
                    <span className="info-box-number">
                    {this.state.currentMonthDelayCount}
                    {/* <small>%</small> */}
                    </span>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Journalier</span>
                    <span className="info-box-number">
                    {this.state.currentDayDelayCount}
                    {/* <small>%</small> */}
                    </span>
                </div>
                </div>
            </div>
            </div>

            <div className="col-md-6 delay-count-container">
              <h4 className="delay-count-title"> Absents </h4>
              <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Mensuel</span>
                    <span className="info-box-number">
                    {this.state.currentMonthAbsenceCount}
                    {/* <small>%</small> */}
                    </span>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Journalier</span>
                    <span className="info-box-number">
                    {this.state.currentDayAbsenceCount}
                    {/* <small>%</small> */}
                    </span>
                </div>
                </div>
            </div>
            </div>
          </div>
          <hr />
          <div className="row da-row">
          <div className="nav-tabs-custom">
            <div className="col-md-12 da-list-col">
            <ul className="nav nav-tabs">
              <li className="active"><a href="#delay_data" data-toggle="tab" aria-expanded="true">Retard</a></li>
              <li className=""><a href="#absence_data" data-toggle="tab" aria-expanded="false">Absence</a></li>
              <li className=""><a href="#delays_absences" data-toggle="tab" aria-expanded="false">Tous les retards et absences</a></li>
              {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
            </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="delay_data">
                <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.delays}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='delayFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='coachFullname' width="200" 
                            filter={{ type: 'TextFilter', delay: 1000 }}>Coach</TableHeaderColumn>
                        <TableHeaderColumn dataField='arriving_time' width="200" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Heure d'arrivée</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="absence_data">
              <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.absences}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='absenceFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='coachFullname' width="200"
                          filter={{ type: 'TextFilter', delay: 1000 }} >Coach</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="delays_absences">
              <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.delaysAbsences}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={this.delayAbsenceDateFormatter.bind(this)} dataField='delayFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date du retard</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={this.delayAbsenceDateFormatter.bind(this)} dataField='absenceFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date de l'absence</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='coachFullname' width="200" 
                            filter={{ type: 'TextFilter', delay: 1000 }}>Coach</TableHeaderColumn>
                        <TableHeaderColumn dataField='arriving_time' width="200" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Heure d'arrivée</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>

{/* <div className="row da-count-row">
            <div className="col-md-6 delay-count-container">
              <h4 className="delay-count-title"> Retardataires </h4>
              <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Mensuel</span>
                    <span className="info-box-number">
                    {this.state.currentMonthDelayCount}
                    {/* <small>%</small> */}
                    {/* </span>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Journalier</span>
                    <span className="info-box-number">
                    {this.state.currentDayDelayCount} */}
                    {/* <small>%</small> */}
                    {/* </span>
                </div>
                </div>
            </div>
            </div>

            <div className="col-md-6 delay-count-container">
              <h4 className="delay-count-title"> Absents </h4>
              <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Mensuel</span>
                    <span className="info-box-number">
                    {this.state.currentMonthAbsenceCount}
                    {/* <small>%</small> */}
                    {/* </span>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                    <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                    <span className="info-box-text">Journalier</span>
                    <span className="info-box-number">
                    {this.state.currentDayAbsenceCount} */}
                    {/* <small>%</small> */}
                    {/* </span>
                </div>
                </div>
            </div>
            </div>
          </div> */}
          
          {/* <div className="row da-row">
          <div className="nav-tabs-custom">
            <div className="col-md-12 da-list-col">
            <ul className="nav nav-tabs">
              <li className="active"><a href="#delay_data" data-toggle="tab" aria-expanded="true">Retard</a></li>
              <li className=""><a href="#absence_data" data-toggle="tab" aria-expanded="false">Absence</a></li>
              <li className=""><a href="#delays_absences" data-toggle="tab" aria-expanded="false">Tous les retards et absences</a></li>
              {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
            {/* </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="delay_data">
                <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.delays}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='delayFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='coachFullname' width="200" 
                            filter={{ type: 'TextFilter', delay: 1000 }}>Coach</TableHeaderColumn>
                        <TableHeaderColumn dataField='arriving_time' width="200" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Heure d'arrivée</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="absence_data">
              <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.absences}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='absenceFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='coachFullname' width="200"
                          filter={{ type: 'TextFilter', delay: 1000 }} >Coach</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div> */}
              {/* <div className="tab-pane" id="delays_absences">
              <div className="row delay-table-row">
                  <div className="col-md-12 delay-table-col">
                    <BootstrapTable data={this.state.delaysAbsences}
                        striped={true} hover={true} condensed={true}
                        multiColumnSort={2} options={options}
                        exportCSV
                        pagination
                        search={true}>
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={this.delayAbsenceDateFormatter.bind(this)} dataField='delayFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date du retard</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={this.delayAbsenceDateFormatter.bind(this)} dataField='absenceFrenchDateFormat' width="250" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Date de l'absence</TableHeaderColumn>
                        <TableHeaderColumn dataField='bakelisteFullname'
                            width="300"
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true} >Nom complet</TableHeaderColumn>
                        <TableHeaderColumn dataField='coachFullname' width="200" 
                            filter={{ type: 'TextFilter', delay: 1000 }}>Coach</TableHeaderColumn>
                        <TableHeaderColumn dataField='arriving_time' width="200" 
                          filter={{ type: 'TextFilter', delay: 1000 }}>Heure d'arrivée</TableHeaderColumn>
                        <TableHeaderColumn dataField='is_justified' width="200"
                            dataFormat={this.justifiedStatusFormatter.bind(this)}
                            filter={{ type: 'TextFilter', delay: 1000 }}
                            dataSort={true}>Justification</TableHeaderColumn>
                        <TableHeaderColumn dataField='delay_absence_reasons'
                            filter={{ type: 'TextFilter', delay: 1000 }} width="200" >Motifs</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div> */} 
                                </div>
                              </div>
                            
                           
                            <div className="tab-pane" id="tache">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-ete-table-col">
                                <BootstrapTable data={this.state.tache}
                                            striped={true} hover={true} condensed={true}
                                            multiColumnSort={2} options={options}
                                            pagination
                                            search={true}>
                                            <TableHeaderColumn width="250" dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                                            <TableHeaderColumn width="250" dataField='frenchDateFormat' dataSort={true}>Date</TableHeaderColumn>
                                            <TableHeaderColumn width="250" dataField='bakeliste_fullname'
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataSort={true}>Nom complet</TableHeaderColumn>
                                            <TableHeaderColumn width="350" dataField='content' dataFormat={this.tacheFormatter.bind(this)}>Tâche</TableHeaderColumn>
                                            <TableHeaderColumn width="100" dataField='duration'
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataSort={true}>Délais</TableHeaderColumn>
                                            <TableHeaderColumn width="250" dataField='coachFullname'
                                                dataFormat={this.coachFullnameFormatter.bind(this)}
                                                filter={{ type: 'TextFilter', delay: 1000 }}
                                                dataSort={true}>Coach</TableHeaderColumn>
                                            <TableHeaderColumn width="250" dataField='state' filter={{ type: 'TextFilter', delay: 1000 }} dataFormat={this.tacheStateFormatter.bind(this)} dataSort={true}>Status</TableHeaderColumn>
                                            {/* <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                                        </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="RI">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-ete-table-col">
                                <section className="content">
              {/* Admin interface render */}
              
                
               { this.state.isEmptyData ?
                  <div className="row ri-row">
                    <div className="col-md-10 col-md-offset-1 ri-col-empty">
                      <div className="box box-danger">
                        <div className="box-header with-border">
                            <h3 className="box-title">RI non existant</h3>
                        </div>
                        <div className="box-body">
                          {/* Empty RI alert message interface */}
                          {this.state.isHideEmptyText === false && 
                            
                              <div className="empty-ri-content-textuel">
                                <p>Le réglement intérieur est actuellement vide. Veuillez l'ajouter</p>
                                <button className="btn btn-danger show-ri-add-form" onClick={this.showOrHideForm}><i className="fas fa-plus"></i> Ajouter le RI</button>
                            </div>  
                           
                          }
                          {/* Show add RI form interface */}
                          
                           { this.state.isShowAddRIForm === true &&
                            <div className="col-md-12 add-ri-form-container">
                              <form id="add-ri-form">
                                <div className="col-md-12 ri-input-item">
                                  <div className="form-group">
                                    <label>Titre</label>
                                    <input type="text" name="title" className="form-control ri-input-item" onChange={this.handleChange} />
                                  </div>
                                </div>
                                <div className="col-md-12 ri-input-item">
                                  <div className="form-group ">
                                      <label >Contenu :</label>
                                      <CKEditor 
                                        activeClass="p10" 
                                        content={this.state.content} 
                                        events={{
                                          "blur": this.onBlur,
                                          "afterPaste": this.afterPaste,
                                          "change": this.onChange
                                        }}
                                      />
                                  </div>
                                  {/* {this.state.textualContentEmpty &&
                                      <div className="errorMsg">*Ce champ est obligatoire</div>
                                  } */}

                                  {/* Add form error alert interface */}
                                  {this.state.addRIErrorAlert &&
                                    <div className="col-md-12 ri-error-alert-container">
                                      <div className="alert alert-danger alerty-content">
                                        Un problème est survenu lors de l'ajout. Veuillez réessayer plus tard.
                                      </div>
                                    </div>
                                  }

                                  <div className="ri-form-actions-btn-container">
                                    <button className="btn btn-danger pull-left cancelled-btn" onClick={this.showOrHideForm}>
                                      <i className="fas fa-times-circle"></i> &nbsp;
                                      Annuler
                                    </button>
                                    {!this.state.addRIInProgress ?
                                      <button className="btn btn-default pull-right submit-ri-btn bg-vgreen" onClick={(e) => this.addRISubmitForm(e)}>
                                        <i className="fas fa-plus"></i> &nbsp;
                                        Ajouter
                                      </button> :
                                      <button className="btn btn-default pull-right submit-ri-btn bg-vorange">
                                        <i className="fas fa-spinner fa-spin "></i> &nbsp;
                                        Ajout en cours
                                      </button>
                                    }
                                  </div>
                              </div>
                              </form>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div> :
                  // RI content render if he's not empty
                  <div className="row ri-row">
                    <div className="col-md-10 col-md-offset-1 release_info_col">
                      {this.state.isShowingRIContent ?
                        <div className="box box-warning box-solid">
                          <div className="box-header with-border">
                            <h3 className="box-title">{this.state.titleValue}</h3>
                            {/* Render update button if user is admin */}
                              {this.state.isAdmin &&
                                <button data-toggle="tooltip" data-placement="top" title="Modifier le réglement intérieur" className="label pull-right label-vgreen edit-btn" onClick={this.showUpdateRIForm}><i className="fas fa-edit"></i></button>
                              }
                          </div>
                          <div className="box-body">
                              <div className="ri-content" dangerouslySetInnerHTML={{__html: this.state.contentValue}}></div>
                          </div>
                        </div>
                        : this.state.isAdmin ?
                        //  Update RI Form 
                            <div className="col-md-12 add-ri-form-container">
                              <form id="update-ri-form">
                                <div className="col-md-12 ri-input-item">
                                  <div className="form-group">
                                    <label>Titre</label>
                                    <input type="text" name="title" value={this.state.titleValue} className="form-control ri-input-item" onChange={this.updateRiHandleChange} />
                                  </div>
                                </div>
                                <div className="col-md-12 ri-input-item">
                                  <div className="form-group ">
                                      <label >Contenu :</label>
                                      <CKEditor 
                                        activeClass="p10" 
                                        content={this.state.content} 
                                        events={{
                                          "blur": this.onBlur,
                                          "afterPaste": this.afterPaste,
                                          "change": this.onChange
                                        }}
                                      />
                                  </div>
                                  {/* {this.state.textualContentEmpty &&
                                      <div className="errorMsg">*Ce champ est obligatoire</div>
                                  } */}

                                  {this.state.updateErrorAlert &&
                                    <div className="col-md-12 ri-error-alert-container">
                                      <div className="alert alert-danger alerty-content">
                                        Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                      </div>
                                    </div>
                                  }

                                  <div className="ri-form-actions-btn-container">
                                    <button className="btn btn-danger pull-left cancelled-btn" onClick={(e) => this.returnBackRiContent(e)}>
                                      <i className="fas fa-times-circle"></i> &nbsp;
                                      Annuler
                                    </button>
                                    {!this.state.updateRIInProgress ?
                                      <button className="btn btn-default pull-right submit-ri-btn bg-vgreen" onClick={this.updateRiContentSubmit}>
                                        <i className="fas fa-plus"></i> &nbsp;
                                        Modifier
                                      </button> :
                                      <button className="btn btn-default pull-right submit-ri-btn bg-vorange">
                                        <i className="fas fa-spinner fa-spin "></i> &nbsp;
                                        Modification en cours
                                      </button>
                                    }
                                  </div>
                              </div>
                              </form>
                            </div> :

                            <div className="row ri-row">
                              <div className="col-md-10 col-md-offset-1 release_info_col">
                                <div className="alert alert-warning cb-empty-ri text-center">
                                Le réglement intérieur est actuellement en cours de conception. Il sera publié dans un bref délais. Merci de votre compréhension.
                                </div>
                              </div>
                            </div>
                          }
                    </div>
                  </div>
    }
            </section>

          

          {/* Success submit new ritoast alert */}
          {this.state.addRISuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                Le RI a été ajouté avec succès.
            </div>
          }

          {/* Success submit update ri toast alert */}
          {this.state.updateRISuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                Le RI a été modifié avec succès.
            </div>
          }
                                  </div>
                                  </div>
                                  </div>
            
                            {/* end table */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
        </div>
        </div>
                   
                   
            
          );
    }
    }
