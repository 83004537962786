import React, { Component } from 'react';
//import './archivedCompletedRdv.css';
import axios from 'axios';
import API from '../../variablesGlobales';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Link, NavLink } from 'react-router-dom';

export default class archivedCompletedRdv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      loadind: true,
      rdvs: [],
      fields: {},
      errors: {},
      rdvDate: '',
      rdvDateValue: '',
      rdvFirstnameValue: '',
      rdvLastnameValue: '',
      rdvEmailValue: '',
      rdvPhoneValue: '',
      rdvCommentaireValue: '',
      rdvHeureValue: '',
      updatedRdvID: 0,
      noteValue: '',
      rdvUpdateInprogress: false,
    };
  }

  componentDidMount() {
    this.getArchivedAndCompletedRDV();
  }

  getArchivedAndCompletedRDV = () => {
    axios.get(this.state.api + 'archived-completed-rdv')
      .then(res => {
        let rdvs = res.data.data;

        this.setState({
          rdvs: rdvs.reverse(),
          loading: false
        });
        console.log(this.state.rdvs)
      })
  }

  // Edit RDV Modal
  getEditModal = (rdv) => {
    console.log('rdv infos ', rdv);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateRdv = this.handleUpdateRdv.bind(this);

    this.setState({
      rdvDate: rdv.date_rdv,
      rdvDateValue: rdv.frenchDateFormat,
      dateWithoutTimeValue: rdv.dateWithoutTimeENG,
      rdvFirstnameValue: rdv.first_name,
      rdvLastnameValue: rdv.last_name,
      rdvEmailValue: rdv.email,
      rdvPhoneValue: rdv.phone,
      rdvCommentaireValue: rdv.commentaires,
      rdvHeureValue: rdv.heure,
      updatedRdvID: rdv.id,
      noteValue: rdv.notes,
      dataRdv: [],
    })

  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    if (e.target.name === 'first_name') {
      this.setState({
        rdvFirstnameValue: e.target.value
      })
    }
    if (e.target.name === 'last_name') {
      this.setState({
        rdvLastnameValue: e.target.value
      })
    }
    if (e.target.name === 'email') {
      this.setState({
        rdvEmailValue: e.target.value
      })
    }
    if (e.target.name === 'phone') {
      this.setState({
        rdvPhoneValue: e.target.value
      })
    }
    if (e.target.name === 'commentaires') {
      this.setState({
        rdvCommentaireValue: e.target.value
      })
    }
    if (e.target.name === 'date_rdv') {
      this.setState({
        rdvDate: e.target.value
      })
    }
    if (e.target.name === 'heure') {
      this.setState({
        rdvHeureValue: e.target.value
      })
    }
    if (e.target.name === 'notes') {
      this.setState({
        noteValue: e.target.value
      })
    }

    if (!fields["first_name"]) {
      fields["first_name"] = this.state.rdvFirstnameValue;
    }
    if (!fields["last_name"]) {
      fields["last_name"] = this.state.rdvLastnameValue;
    }
    if (!fields["email"]) {
      fields["email"] = this.state.rdvEmailValue;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.rdvPhoneValue;
    }
    if (!fields["commentaires"]) {
      fields["commentaires"] = this.state.rdvCommentaireValue;
    }
    if (!fields["date_rdv"]) {
      fields["date_rdv"] = this.state.rdvDate;
    }
    if (!fields["heure"]) {
      fields["heure"] = this.state.rdvHeureValue;
    }
    if (!fields["notes"]) {
      fields["notes"] = this.state.noteValue;
    }

    fields["id_user"] = window.sessionStorage.getItem('user_id')
    this.setState({
      fields
    });

    console.log(fields);
  }

  // Submit update data
  handleUpdateRdv = (e) => {
    this.setState({
      rdvUpdateInprogress: true
    })
    e.preventDefault();
    let fields = this.state.fields;

    if (!fields["first_name"]) {
      fields["first_name"] = this.state.rdvFirstnameValue;
    }
    if (!fields["last_name"]) {
      fields["last_name"] = this.state.rdvLastnameValue;
    }
    if (!fields["email"]) {
      fields["email"] = this.state.rdvEmailValue;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.rdvPhoneValue;
    }
    if (!fields["commentaires"]) {
      fields["commentaires"] = this.state.rdvCommentaireValue;
    }
    if (!fields["date_rdv"]) {
      fields["date_rdv"] = this.state.rdvDate;
    }
    if (!fields["heure"]) {
      fields["heure"] = this.state.rdvHeureValue;
    }
    if (!fields["notes"]) {
      fields["notes"] = this.state.noteValue;
    }

    this.setState({ fields: fields });
    const dataRdv = this.state.fields;
    console.log(dataRdv);
    axios.put(this.state.api + 'rdvs/' + this.state.updatedRdvID, dataRdv)
      .then(res => {
        this.setState({
          rdvUpdateInprogress: false
        })
        console.log(res.data);
        if (res.data.success === true) {
          window.location = 'rdv-archiver-valider';
        }
      })
  }

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#moModal" onClick={(e) => this.getEditModal(row, e)}>
          <i className="fas fa-eye"></i>
        </button>
        <button type="button" className="btn btn-warning" id="btn_edit" data-toggle="modal" data-target="#editModal" onClick={(e) => this.getEditModal(row, e)}>
          <i className="fas fa-edit"></i>
        </button>
      </div>
    );
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  rowClassNameFormat = (row, rowIdx) => {
    return row.isToday === true ? 'todayClass' : '';
  }


  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton
    };

    if (this.state.loading) {
      return (
        <div className="component-archived-completed-rdv">
          <Menu />
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">RDV archivés/validés</span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard-admin">Accueil</Link>
                  </li>
                  <li className="active">RDV archivés/validés</li>
                </ol>
              </div>
            </h1>
          </div>
          <div className="col-md-12 reporting-container">
            <div className="col-md-6 col-md-offset-3 search_container">
              <div className="input-group">
                <input type="text" className="form-control searching-form" placeholder="Search for..." />
                <span className="input-group-btn">
                  <button className="btn btn-warning" type="button">
                    <i className="fas fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div className="list_bakeliste_container">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Prénom et Nom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Date et Heure </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <div id="loader"></div>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }

    return (

      <div className="component-archived-completed-rdv">
        <Menu />
        <div className="col-lg-12">
          <h1 className="page-header">
            <span className="page-title">RDV archivés/validés</span>
            <div className="pull-right">
              <ol className="breadcrumb">
                <li>
                  <NavLink activeClassName="active" to="/dashboard-admin">Accueil</NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/gestion-des-rendez-vous">Gestion des RDV</NavLink>
                </li>
                <li className="active">RDV archivés/validés</li>
              </ol>
            </div>
          </h1>
        </div>
        <div className="list_bakeliste_container">
          <div className="table-responsive">

            <BootstrapTable data={this.state.rdvs}
              striped={true} hover={true} condensed={true}
              multiColumnSort={2} options={options}
              pagination
              search={true} trClassName={this.rowClassNameFormat}>
              <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
              <TableHeaderColumn dataField='userFullname' >Nom complet</TableHeaderColumn>
              <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
              <TableHeaderColumn dataField='frenchDateFormat'>Date et Heure</TableHeaderColumn>
              <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>

        <div className="modal fade" id="moModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="myModalLabel">Detail sur {this.state.rdvFirstnameValue}&nbsp;{this.state.rdvLastnameValue}</h4>
              </div>
              <div className="modal-body">
                {this.state.noteValue !== null ?
                  <div className="email_container notes-admin">
                    <span className="item_label">Notes : </span> <br />
                    <span className="label_content"> {this.state.noteValue}</span>
                  </div>
                  :
                  <div className="email_container">
                    <span className="item_label">Notes : </span> <br />
                    <span className="label_content badge">Aucune note pour le moment</span>
                  </div>
                }
                <hr />
                <div className="email_container">
                  <span className="item_label">Email : </span> <span className="label_content">{this.state.rdvEmailValue}</span>
                </div> <hr />

                <div className="email_container">
                  <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.rdvPhoneValue}</span>
                </div> <hr />

                <div className="email_container">
                  <span className="item_label">Commentaire : </span> <br />
                  <span className="label_content">{this.state.rdvCommentaireValue}</span>
                </div> <hr />
                <div className="email_container">
                  <span className="item_label">Date du rvd : </span> <span className="label_content">{this.state.rdvDateValue}</span>
                </div> <hr />

                <div className="modal-footer">

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Modal */}
        <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="myModalLabel2">Modifier un RDV</h4>
              </div>
              <div className="modal-body">

                <form onSubmit={this.handleUpdateRdv}>
                  <div className="form-row row">
                    <div className="col-md-6 bloc_container">
                      <div className="form-group ">
                        <label >Prénom:</label>
                        <input type="text" className="form-control" id="inputpFirstName" placeholder="Prénom" name="first_name" value={this.state.rdvFirstnameValue} onChange={this.handleChange} />
                      </div>
                      {this.state.fn_empty &&
                        <div className="errorMsg">{this.state.errors.firstName}</div>
                      }
                    </div>
                    <div className="col-md-6 bloc_container">
                      <div className="form-group ">
                        <label >Nom:</label>
                        <input type="text" className="form-control" id="inputLastName" placeholder="Nom" name="last_name" value={this.state.rdvLastnameValue} onChange={this.handleChange} />
                      </div>
                      {this.state.ln_empty &&
                        <div className="errorMsg">{this.state.errors.lastName}</div>
                      }
                    </div>
                    <div className="col-md-6 bloc_container">
                      <div className="form-group " >
                        <label >Email:</label>
                        <input type="Email" className="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.rdvEmailValue} onChange={this.handleChange} />
                      </div>
                      {this.state.email_empty &&
                        <div className="errorMsg">{this.state.errors.email}</div>
                      }
                    </div>
                    <div className="col-md-6 bloc_container">
                      <div className="form-group ">
                        <label >Téléphone:</label>
                        <input type="text" className="form-control" id="inputNumero" placeholder="Téléphone" name="phone" value={this.state.rdvPhoneValue} onChange={this.handleChange} />
                      </div>
                      {this.state.phone_empty &&
                        <div className="errorMsg">{this.state.errors.phone}</div>
                      }
                    </div>
                    <div className="col-md-6 bloc_container">
                      <div className="form-group ">
                        <label >Commentaires:</label>
                        <textarea className="form-control" id="commentaireInput" placeholder="Vos commentaires" name="commentaires" value={this.state.rdvCommentaireValue} onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="col-md-6 bloc_container">
                      <div className="form-group notes-admin">
                        <label >Notes:</label>
                        <textarea className="form-control" id="notesInput" placeholder="Vos notes" value={this.state.noteValue} name="notes" onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="col-md-6 bloc_container">
                      <div className="form-group ">
                        <label >Date:</label>
                        <input type="date" className="form-control" id="inputpFirstName" placeholder="commentaire" name="date_rdv" value={this.state.dateWithoutTimeValue} onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="col-md-6 bloc_container">
                      <div className="form-group ">
                        <label >Heure:</label>
                        <input type="integer" className="form-control" id="inputpFirstName" placeholder="commentaire" name="heure" value={this.state.rdvHeureValue} onChange={this.handleChange} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group" >
                    {!this.state.rdvUpdateInprogress ?
                      <button type="submit" className='btn btn-warning addBtn button submit'>
                        <i className="fas fa-plus-circle"></i> &nbsp;
                        Modifier
                                  </button>
                      :
                      <button type="button" className='btn btn-warning addBtn button submit'>
                        <i className="fas fa-spin fa-spinner"></i> &nbsp;
                        Modification en cours
                                </button>
                    }

                  </div>

                </form>

                <div className="modal-footer">

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Edit Modal */}
      </div>
    );

  }
}
