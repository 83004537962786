import React, {Component} from 'react';
import './reglementInterieur.css'
//import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import API from '../../variablesGlobales';
import axios from 'axios';
import { Link , NavLink} from 'react-router-dom';
import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';
import CKEditor from "react-ckeditor-component";


export default class reglementInterieur extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isPageLoading: false,
          api: API.API,
          regInt: [],
          isEmptyData: false,
          isPageLoadingError: false,
          fields: {},
          errors: {},
          isAdmin: false,
          isCoach: false,
          isBakeliste: false,
          token: window.sessionStorage.getItem('token'),
          userStatus: window.sessionStorage.getItem('user_status'),
          userID: window.sessionStorage.getItem('user_id'),
          isHideEmptyText: false,
          isShowAddRIForm: false,
          content: '',
          addRIInProgress: false,
          isShowUpdateRIForm: false,
          regInItem: [],
          isShowingRIContent: false,
          _fields: {},
          updateRIInProgress: false,
          updateErrorAlert: false,
          updateRISuccessAlert: false,

        };

        this.handleChange = this.handleChange.bind(this);
        this.addRISubmitForm = this.addRISubmitForm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showUpdateRIForm = this.showUpdateRIForm.bind(this);
        this.updateRiHandleChange = this.updateRiHandleChange.bind(this);
        this.updateRiContentSubmit = this.updateRiContentSubmit.bind(this);
    }

    componentDidMount = () => {
      this.checkConnectedUserStatus();
      this.onGetReglementInterieur();
    }

    componentDidCatch(err, info){
      Sentry.captureException(err);
    }

    checkConnectedUserStatus = () => {
      let userStatus = this.state.userStatus;
      if(userStatus === 'admin'){
        this.setState({
          isAdmin: true
        })
      }else{
        this.setState({
          isCoach: true,
          isBakeliste: true
        })
      }
    }

    onGetReglementInterieur = () => {
      this.setState({
        isPageLoading: true
      })
      let api = this.state.api
      axios.get(api + 'reglement-interieurs')
        .then(response => {
          this.setState({
            isPageLoading: false
          })
          if(response.data.success){
            this.setState({
              regInt: response.data.data,
              isEmptyData: false,
              isShowingRIContent: true
            })
            response.data.data.forEach((item) => {
              this.setState({
                idValue: item.id,
                titleValue: item.title,
                contentValue: item.ri_content
              })
            })
            console.log(this.state.titleValue)
          }else if(!response.data.success && response.data.message === 'empty-data'){
            this.setState({
              isEmptyData: true
            })
          }
        })
        .catch(error => {
          console.log(error.message)
          this.setState({
            isPageLoadingError: true
          })
          if (process.env.NODE_ENV !== 'production') {
            return;
          }
          Sentry.captureException(error);
        })
    }

    showOrHideForm = () => {
      if(!this.state.isHideEmptyText && !this.state.isShowAddRIForm){
        this.setState({
          isHideEmptyText: true,
          isShowAddRIForm: true
        })
      }else{
        this.setState({
          isHideEmptyText: false,
          isShowAddRIForm: false
        })
      }
    }

    handleChange = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;

      this.setState({
        fields: fields
      })
      console.log(fields)
    }

    onChange(evt){
      console.log("onChange fired with event info: ", evt);
      var newContent = evt.editor.getData();
      
      this.setState({
        // fields: {
        //   content: newContent
        // },
        content: newContent
      })
    }

    onBlur(evt){
      console.log("onBlur event called with event info: ", evt);
    }
    
    afterPaste(evt){
      console.log("afterPaste event called with event info: ", evt);
    }

    addRISubmitForm = (e) => {
      e.preventDefault();
      this.setState({
        addRIInProgress: true
      })
      let data = this.state.fields;
      data["ri_content"] = this.state.content;
      console.log(this.state.fields)

      axios.post(this.state.api + 'reglement-interieurs', data)
        .then(response => {
          this.setState({
            addRIInProgress: false
          })
          if(response.data.success){
            $('#add-ri-form')[0].reset();
            this.onGetReglementInterieur();
            this.setState({
              content: '',
              fields: {}
            })
            this.setState({
              addRISuccessAlert: true
            })
            this.handleShowAndHideAlert('addRISuccessAlert')
          }else{
            this.setState({
              addRIErrorAlert: true
            })
            this.handleShowAndHideAlert('addRIErrorAlert')
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            addRIInProgress: false,
            addRIErrorAlert: true
          })
          this.handleShowAndHideAlert('addRIErrorAlert')
          if (process.env.NODE_ENV !== 'production') {
            return;
          }
          Sentry.captureException(error);
        })
    }

    handleShowAndHideAlert(label) {
      setTimeout(() => {
        if (label === 'addRISuccessAlert') {
          this.setState({
            addRISuccessAlert: false
          });
        }
        if (label === 'addRIErrorAlert') {
          this.setState({
            addRIErrorAlert: false
          });
        }
        if(label === 'updateRISuccessAlert'){
          this.setState({
            updateRISuccessAlert: false
          })
        }
        if(label === 'updateErrorAlert'){
          this.setState({
            updateErrorAlert: false
          })
        }
      }, 5000);
    }

    showUpdateRIForm = () => {
      console.log('ok')
      this.setState({
        isShowAddRIForm: false,
        isShowingRIContent: false,
        regInItem: [],
        content: this.state.contentValue,
        updateErrorAlert: false
      })
    }

    updateRiHandleChange = (e) => {
      let _fields = this.state._fields;
      _fields[e.target.name] = e.target.value;
      _fields["ri_id"] = this.state.idValue;

      if(e.target.name === 'title'){
        this.setState({
          titleValue: e.target.value
        })
      }
      if (!_fields["title"]) {
        _fields["title"] = this.state.titleValue;
      }
      if(e.target.name === 'ri_content'){
        this.setState({
          contentValue: e.target.value
        })
      }
      if (!_fields["ri_content"]) {
        _fields["ri_content"] = this.state.contentValue;
      }

      this.setState({
        _fields: _fields
      })
      console.log(_fields)
    }

    updateRiContentSubmit = (e) => {
       e.preventDefault();
       this.setState({
         updateRIInProgress: true
       })
       let _data = this.state._fields;
       _data["ri_content"] = this.state.content;
       _data["title"] = this.state.titleValue;
       console.log(_data);

       var api = this.state.api;
       var ri_id = this.state.idValue;
       axios.post(api + 'update-reglement-interieur/' + ri_id, _data)
        .then(response => {
          this.setState({
            updateRIInProgress: false
          })

          if(response.data.success){
            this.onGetReglementInterieur();
            $('#update-ri-form')[0].reset();
            this.setState({
              isShowingRIContent: true,
              updateRISuccessAlert: true,
              _fields: {}
            })
            this.handleShowAndHideAlert('updateRISuccessAlert')
          }else{
            console.log(response);
            this.setState({
              updateErrorAlert: true,
              updateRIInProgress: false
            })
            this.handleShowAndHideAlert('updateErrorAlert')
          }
        })
        .catch(error => {
          console.log(error.message);
          this.setState({
            updateErrorAlert: true,
            updateRIInProgress: false
          })
          this.handleShowAndHideAlert('updateErrorAlert')
          if (process.env.NODE_ENV !== 'production') {
            return;
          }
          Sentry.captureException(error);
        })

    }

    returnBackRiContent = () => {
      this.setState({
        isShowingRIContent: false
      })
    }

    render() {
      if(this.state.isPageLoading){
        return(
          <div className="component-reglement-interieur">
            <Menu />
            {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
              Aprés avoir intégré AdminLTE */}
            <div className="row no-content-wrapper">
  
              <section className="content-header">
                <h1>
                  Réglement Intérieur
                </h1>
                <ol className="breadcrumb">
                  {this.state.isAdmin &&
                    <li><Link to="/dashboard-coach">Accueil</Link></li>
                  }
                  {this.state.isCoach &&
                    <li><Link to="/dashboard-admin">Accueil</Link></li>
                  }
                  {this.state.isBakeliste &&
                    <li><Link to="/dashboard-bakeliste">Accueil</Link></li>
                  }
                  <li className="active">Réglement intérieur</li>
                </ol>
              </section>
  
              <section className="content">
                <div className="loader_container">
                  <div id="loader"></div>
                </div>
              </section>
  
            </div>
          </div>
        );
      }
      return(
        <div className="component-reglement-interieur">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Réglement Intérieur
              </h1>
              <ol className="breadcrumb">
                {this.state.isAdmin &&
                  <li><Link to="/dashboard-coach">Accueil</Link></li>
                }
                {this.state.isCoach &&
                  <li><Link to="/dashboard-admin">Accueil</Link></li>
                }
                {this.state.isBakeliste &&
                  <li><Link to="/dashboard-bakeliste">Accueil</Link></li>
                }
                <li className="active">Réglement intérieur</li>
              </ol>
            </section>


            <section className="content">
              {/* Admin interface render */}
              {this.state.isAdmin &&
                // Empty RI interface
                this.state.isEmptyData ?
                  <div className="row ri-row">
                    <div className="col-md-10 col-md-offset-1 ri-col-empty">
                      <div className="box box-danger">
                        <div className="box-header with-border">
                          {this.state.isAdmin && 
                            <h3 className="box-title">RI non existant</h3>
                          }
                          {this.state.isCoach &&
                            <h3 className="box-title">RI en cours de conception</h3>
                          }
                          {this.state.isBakeliste &&
                            <h3 className="box-title">RI en cours de conception</h3>
                          }
                          {/* <span className="label label-danger pull-right"><i className="fa fa-database"></i></span> */}
                        </div>
                        <div className="box-body">
                          {/* Empty RI alert message interface */}
                          {this.state.isHideEmptyText === false && 
                            this.state.isAdmin ?
                              <div className="empty-ri-content-textuel">
                                <p>Le réglement intérieur est actuellement vide. Veuillez l'ajouter</p>
                                <button className="btn btn-danger show-ri-add-form" onClick={this.showOrHideForm}><i className="fas fa-plus"></i> Ajouter le RI</button>
                            </div> : 
                            <div className="empty-ri-content-textuel">
                              <p>Le réglement intérieur est actuellement en cours de conception. Il sera publié dans un bref délais. Merci de votre compréhension.</p>
                            </div>
                          }
                          {/* Show add RI form interface */}
                          {this.state.isAdmin &&
                            this.state.isShowAddRIForm === true &&
                            <div className="col-md-12 add-ri-form-container">
                              <form id="add-ri-form">
                                <div className="col-md-12 ri-input-item">
                                  <div className="form-group">
                                    <label>Titre</label>
                                    <input type="text" name="title" className="form-control ri-input-item" onChange={this.handleChange} />
                                  </div>
                                </div>
                                <div className="col-md-12 ri-input-item">
                                  <div className="form-group ">
                                      <label >Contenu :</label>
                                      <CKEditor 
                                        activeClass="p10" 
                                        content={this.state.content} 
                                        events={{
                                          "blur": this.onBlur,
                                          "afterPaste": this.afterPaste,
                                          "change": this.onChange
                                        }}
                                      />
                                  </div>
                                  {/* {this.state.textualContentEmpty &&
                                      <div className="errorMsg">*Ce champ est obligatoire</div>
                                  } */}

                                  {/* Add form error alert interface */}
                                  {this.state.addRIErrorAlert &&
                                    <div className="col-md-12 ri-error-alert-container">
                                      <div className="alert alert-danger alerty-content">
                                        Un problème est survenu lors de l'ajout. Veuillez réessayer plus tard.
                                      </div>
                                    </div>
                                  }

                                  <div className="ri-form-actions-btn-container">
                                    <button className="btn btn-danger pull-left cancelled-btn" onClick={this.showOrHideForm}>
                                      <i className="fas fa-times-circle"></i> &nbsp;
                                      Annuler
                                    </button>
                                    {!this.state.addRIInProgress ?
                                      <button className="btn btn-default pull-right submit-ri-btn bg-vgreen" onClick={(e) => this.addRISubmitForm(e)}>
                                        <i className="fas fa-plus"></i> &nbsp;
                                        Ajouter
                                      </button> :
                                      <button className="btn btn-default pull-right submit-ri-btn bg-vorange">
                                        <i className="fas fa-spinner fa-spin "></i> &nbsp;
                                        Ajout en cours
                                      </button>
                                    }
                                  </div>
                              </div>
                              </form>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div> :
                  // RI content render if he's not empty
                  <div className="row ri-row">
                    <div className="col-md-10 col-md-offset-1 release_info_col">
                      {this.state.isShowingRIContent ?
                        <div className="box box-warning box-solid">
                          <div className="box-header with-border">
                            <h3 className="box-title">{this.state.titleValue}</h3>
                            {/* Render update button if user is admin */}
                              {this.state.isAdmin &&
                                <button data-toggle="tooltip" data-placement="top" title="Modifier le réglement intérieur" className="label pull-right label-vgreen edit-btn" onClick={this.showUpdateRIForm}><i className="fas fa-edit"></i></button>
                              }
                          </div>
                          <div className="box-body">
                              <div className="ri-content" dangerouslySetInnerHTML={{__html: this.state.contentValue}}></div>
                          </div>
                        </div>
                        : this.state.isAdmin ?
                        //  Update RI Form 
                            <div className="col-md-12 add-ri-form-container">
                              <form id="update-ri-form">
                                <div className="col-md-12 ri-input-item">
                                  <div className="form-group">
                                    <label>Titre</label>
                                    <input type="text" name="title" value={this.state.titleValue} className="form-control ri-input-item" onChange={this.updateRiHandleChange} />
                                  </div>
                                </div>
                                <div className="col-md-12 ri-input-item">
                                  <div className="form-group ">
                                      <label >Contenu :</label>
                                      <CKEditor 
                                        activeClass="p10" 
                                        content={this.state.content} 
                                        events={{
                                          "blur": this.onBlur,
                                          "afterPaste": this.afterPaste,
                                          "change": this.onChange
                                        }}
                                      />
                                  </div>
                                  {/* {this.state.textualContentEmpty &&
                                      <div className="errorMsg">*Ce champ est obligatoire</div>
                                  } */}

                                  {this.state.updateErrorAlert &&
                                    <div className="col-md-12 ri-error-alert-container">
                                      <div className="alert alert-danger alerty-content">
                                        Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                      </div>
                                    </div>
                                  }

                                  <div className="ri-form-actions-btn-container">
                                    <button className="btn btn-danger pull-left cancelled-btn" onClick={(e) => this.returnBackRiContent(e)}>
                                      <i className="fas fa-times-circle"></i> &nbsp;
                                      Annuler
                                    </button>
                                    {!this.state.updateRIInProgress ?
                                      <button className="btn btn-default pull-right submit-ri-btn bg-vgreen" onClick={this.updateRiContentSubmit}>
                                        <i className="fas fa-plus"></i> &nbsp;
                                        Modifier
                                      </button> :
                                      <button className="btn btn-default pull-right submit-ri-btn bg-vorange">
                                        <i className="fas fa-spinner fa-spin "></i> &nbsp;
                                        Modification en cours
                                      </button>
                                    }
                                  </div>
                              </div>
                              </form>
                            </div> :

                            <div className="row ri-row">
                              <div className="col-md-10 col-md-offset-1 release_info_col">
                                <div className="alert alert-warning cb-empty-ri text-center">
                                Le réglement intérieur est actuellement en cours de conception. Il sera publié dans un bref délais. Merci de votre compréhension.
                                </div>
                              </div>
                            </div>
                          }
                    </div>
                  </div>
              }
            </section>

          </div>

          {/* Success submit new ritoast alert */}
          {this.state.addRISuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                Le RI a été ajouté avec succès.
            </div>
          }

          {/* Success submit update ri toast alert */}
          {this.state.updateRISuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                Le RI a été modifié avec succès.
            </div>
          }
        </div>
      );
    }
  }
