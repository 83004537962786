import React, { Component } from "react";
import './gestionSyllabusBakeliste.css';
import Menu from "./../menu/Menu";
import { NavLink } from "react-router-dom";
import { MagicSpinner } from "react-spinners-kit";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import API from "../../variablesGlobales";
//import $ from 'jquery';

export default class GestionSyllabusBakeliste extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem("token");
    let userStatus = window.sessionStorage.getItem("user_status");
    // let userID = window.sessionStorage.getItem('user_id');

    if (!token) {
      window.location = "/";
    }
    if (userStatus === "coach") {
      window.history.back();
    }

    super(props);
    this.state = {
      userID: window.sessionStorage.getItem("user_id"),
      isPageLoading: true,
      api: API.API,
      syllabusDatas: [],
      domaines: [],
      fields: {},
      errors: {},
      sname: false,
      sdescription: false,

      technologies: [],
      tools: [],
      stechnotool_empty: false,
      screatedby_empty: false,
      slevel_empty: false,
      sdomaineid_empty: false,
      addSyllabusInProgress: false,
      addSyllabusSuccessAlert: false,
      addSyllabusErrorAlert: false,
      remaining_char: 245,
    };
  }

  componentDidMount = () => {
    /*  this.getAllDomaines(); */
    this.onGetSyllabus();

    //this.getAllTechnologies();
    //this.getAllTools();
  };


  getAllTechnologies() {
    axios
      .get(this.state.api + "technologies/")
      .then((response) => {
        var data = response.data.data;

        if (data !== undefined) {
          this.setState({
            technologies: data,
          });
          //console.log("les technologies :", this.state.technologies);
          {
            this.state.technologies.map((index) => {
             // console.log(index.id);
            });
          }
        }

        data.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions1.push(infos);
        });
        //console.log("Technologies ", this.state.selectOptions1);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }


  //get tools
  getAllTools() {
    axios
      .get(this.state.api + "tools/")
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            tools: data,
          });
          //console.log("les technologies :", this.state.technologies);
          {
            this.state.tools.map((index) => {
             // console.log(index.id);
            });
          }
        }

        data.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions2.push(infos);
        });
        //console.log("Tools ", this.state.selectOptions2);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  onGetSyllabus = () => {
    this.setState({
      isPageLoading: true,
    });
    //var baseApiUrl = this.state.api;
    let id = this.state.userID;

    axios
      .get(this.state.api + "syllabus-bakeliste/" + id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          let syllabusDatas = []
          for (let s of response.data.data){
            for (let item of s ){
              syllabusDatas.push({...item,technologies: item.technologies || []})
            }
          }
          this.setState({
            syllabusDatas
          });
          
         // console.log("sylqbus",syllabusDatas);
          
        }

      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        this.onSwitchAlertStatus("isGetSyllabusRequestError");
      });
  };
  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === "addSyllabusSuccessAlert") {
        this.setState({
          addSyllabusSuccessAlert: false,
        });
      }
      if (label === "addSyllabusErrorAlert") {
        this.setState({
          addSyllabusErrorAlert: false,
        });
      }
      if (label === "isGetSyllabusRequestError") {
        this.setState({
          isGetSyllabusRequestError: false,
        });
      }
      if (label === "sname_empty") {
        this.setState({
          sname_empty: false,
        });
      }
      if (label === "sdescription_empty") {
        this.setState({
          sdescription_empty: false,
        });
      }
      if (label === "stechnotool_empty") {
        this.setState({
          stechnotool_empty: false,
        });
      }
      if (label === "screatedby_empty") {
        this.setState({
          screatedby_empty: false,
        });
      }
      if (label === "slevel_empty") {
        this.setState({
          slevel_empty: false,
        });
      }
      if (label === "sdomaineid_empty") {
        this.setState({
          sdomaineid_empty: false,
        });
      }
    }, 5000);
  };
  render() {
    if (this.state.isPageLoading) {
      return (
        <div className="component-gestion-syllabus">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">
            <section className="content-header">
              <h1>Bakeliste Syllabus</h1>
              <ol className="breadcrumb">
                <li>
                  <NavLink to="/dashboard-bakeliste">
                    <i className="fa fa-dashboard"></i> Accueil
                  </NavLink>
                </li>
                <li className="active">Syllabus Bakeliste</li>
              </ol>
            </section>

            <section className="content">
              <div className="loader-row row">
                <div className="col-md-4 col-md-offset-4 loader-container">
                  <MagicSpinner
                    size={150}
                    color="#009688"
                    loading={this.state.isPageLoading}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }
    return (
      <div className="component-gestion-syllabus">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
          Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Bakeliste Syllabus</h1>
            <ol className="breadcrumb">
              <li>
                <NavLink to="/dashboard-bakeliste">
                  <i className="fa fa-dashboard"></i> Accueil
                </NavLink>
              </li>
              <li className="active">Bakeliste Syllabus</li>
            </ol>
          </section>

          <section className="content">
            <div className="col-md-12 syllabus-container">
              <div className="row syllabus_content_row">
                {this.state.syllabusDatas === undefined ? (
                  <div className="col-md-12 empty_data_alerty">
                    <div className="alert alert danger empty_alert">
                      Vous n'avez pas encore de syllabus!
                    </div>
                  </div>
                ) : (
                  this.state.syllabusDatas.map((item,index)=> (
                    <div
                      key={index}
                      className="col-md-5 syllabus_item_container"
                    >
                      <div className="box box-solid box-default box_container">
                        <div className="box-header with-border bg-vgreen">
                          <h3 className="box-title"> {item.syllabus_name} </h3>
                          <span
                            className={
                              "label pull-right " +
                              (item.syllabus_level === "débutant"
                                ? "bg-vred"
                                : item.syllabus_level === "intermédiaire"
                                ? "bg-vorange"
                                : item.syllabus_level === "avancé"
                                ? "bg-vblack"
                                : "")
                            }
                          >
                            {item.syllabus_level}
                          </span>
                        </div>
                            <div className="description_container">
                              <span className="syllabus_description_bloc">
                                {" "}
                                Description :{" "}
                              </span>
                              <p> {item.syllabus_description} </p>
                            </div>
                            <div key={index}  className="syllabus_tt_container">
                              {item.technologies.map((t, index) =>(
                                <p> - {t.name}  </p>
                              ))}
                              {item.tools.map((to, index) =>(
                                <p>  - {to.name} </p>
                              ))}
                            </div>
                            <NavLink
                                to={"/syllabus-content-bakeliste/" + item.id}
                                className="syllabus_more_infos_link"
                            >
                                <img
                                className="more_infos_icon"
                                src="https://img.icons8.com/ios-glyphs/50/009688/visible.png"
                                alt="Détails"
                                title="Détails"
                                />
                            </NavLink>
                      </div>
                    </div>
                    
                  ))
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
