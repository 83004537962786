import React, { Component } from "react";
import "./listProspects.css";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import Menu from "./../menu/Menu";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import API from "../../variablesGlobales";
import $ from "jquery";
import "../../../node_modules/admin-lte/dist/css/AdminLTE.min.css";
import "../../../node_modules/admin-lte/dist/css/AdminLTE.css";
import "../../../node_modules/admin-lte/dist/css/skins/_all-skins.css";
import "../../../node_modules/admin-lte/dist/js/adminlte.js";
import * as Sentry from "@sentry/browser";

class ListProspects extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem("token");
    let userStatus = window.sessionStorage.getItem("user_status");

    if (!token) {
      window.history.back();
    }
    if (userStatus === "bakeliste" || userStatus === "coach") {
      window.history.back();
    }
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.getSelectedProspect = this.getSelectedProspect.bind(this);
    this.getProspectDetails = this.getProspectDetails.bind(this);
    this.validateSignUp = this.validateSignUp.bind(this);
    this.validateDataFields = this.validateDataFields.bind(this);
    this.onCalledOthersProspectsStatement = this.onCalledOthersProspectsStatement.bind(
      this
    );
    this.handleAdminCommentChange = this.handleAdminCommentChange.bind(this);
    this.handleAddAdminComment = this.handleAddAdminComment.bind(this);
    this.state = {
      adminID: window.sessionStorage.getItem("user_id"),
      prospects: [],
      domaines: [],
      loading: true,
      api: API.API,
      fields: {},
      _phFields: {},
      isEtudiant: false,
      isPro: false,
      isFC: false,
      isFD: false,
      isSemaine: false,
      prospectFullname: "",
      isConversionProgress: false,
      obj_empty: false,
      phone_empty: false,
      lf_empty: false,
      boursier_empty: false,
      paiement_empty: false,
      prospectStatus: "",
      prospectItem: [],
      successCalledAlert: false,
      errorCalledAlert: false,
      calledStatementInProgress: false,
      prospectsWeekend: [],
      prospectsEte: [],
      isProspectWeekendLoading: false,
      isProspectEteLoading: false,
      prospectwkete: [],
      calledWeekendProspectInProgress: false,
      calledEteProspectInProgress: false,
      successWeekendProspectCalledAlert: false,
      successEteProspectCalledAlert: false,
      errorEteProspectCalledAlert: false,
      errorWeekendProspectCalledAlert: false,
      commentOnProspectID: 0,
      showBloc: false,
      adminComments: [],
      addCommentInProgress: false,
      isAddCommentRequestError: false,
      acFields: {},
      addCommentByAdminSuccess: false,
      commentsIsLoading: false,
    };
  }
  componentDidMount() {
    this.getProspectList();
    this.getProspectsWeekend();
    this.getProspectsEte();
    this.getDomaines();
  }

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  getProspectList = () => {
    axios
      .get(this.state.api + "prospects")
      .then((res) => {
        let prospects = res.data.data;
        this.setState({
          prospects: prospects,
          loading: false,
        });
        //console.log(res.data.data)
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          loading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getProspectsWeekend = () => {
    axios
      .get(this.state.api + "prospects-masterclass-weekend")
      .then((response) => {
        if (response.data.success) {
          this.setState({
            prospectsWeekend: response.data.data,
            isProspectWeekendLoading: false,
          });
        } else {
          this.setState({
            isProspectWeekendLoading: false,
          });
          console.log(response);
          console.log("empty data");
        }
      })
      .catch((error) => {
        this.setState({
          isProspectWeekendLoading: false,
        });
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getProspectsEte = () => {
    this.setState({
      isProspectEteLoading: true,
    });
    axios
      .get(this.state.api + "prospectbakeli-etes")
      .then((response) => {
        this.setState({
          isProspectEteLoading: false,
          prospectsEte: response.data.data,
        });
        console.log(this.state.prospectsEte);
      })
      .catch((error) => {
        this.setState({
          isProspectEteLoading: false,
        });
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
      })
      //console.log(this.state.domaines)
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  renderName(id) {
    this.state.domaines.forEach((x) => {
      if (x.id === id) {
        this.setState({
          nameDomain: x.name,
        });
      }
    });
    return (
      <td>
        {" "}
        <Link to="#">{this.state.nameDomain}</Link>{" "}
      </td>
    );
  }

  handleChange(e) {
    let fields = this.state.fields;
    console.log(fields);
    let _phFields = this.state._phFields;
    fields[e.target.name] = e.target.value;
    // _phFields[e.target.name] = e.target.value;
    if (e.target.name === "isWE") {
      if (e.target.value === "semaine") {
        if (fields["is_pro"] === 1) {
          // _phFields["is_pro"] = 1;
          // _phFields["is_etudiant"] = 0;
          fields["is_week"] = 1;
          fields["is_weekend"] = 0;
          fields["nbre_jours"] = 2;
          fields["horaire"] = "17h30-19h30";
          fields["is_pro"] = 1;
          fields["is_etudiant"] = 0;
          this.setState({
            isSemaine: true,
          });
        } else {
          fields["is_week"] = 1;
          fields["is_weekend"] = 0;
          fields["nbre_jours"] = 3;
          fields["is_pro"] = 0;
          fields["is_etudiant"] = 1;
          this.setState({
            isSemaine: true,
          });
        }
      } else if (e.target.value === "weekend") {
        if (fields["is_pro"] === 1) {
          fields["is_week"] = 0;
          fields["is_weekend"] = 1;
          fields["nbre_jours"] = 1;
          fields["horaire"] = "9h-13h";
          fields["jour_1"] = "";
          fields["jour_2"] = "";
          fields["jour_3"] = "";
          this.setState({
            isSemaine: false,
          });
        } else {
          fields["is_week"] = 0;
          fields["is_weekend"] = 1;
          fields["nbre_jours"] = 2;
          this.setState({
            isSemaine: false,
          });
        }
      }
    }
    this.setState({
      fields: fields,
      _phFields: _phFields,
    });
    console.log("handle change", this.state.fields);
    // console.log("handle change _", this.state._phFields)
  }

  getSelectedProspect(x) {
    console.log("pro or not  ", x.ispro);
    console.log("type  ", x.fd_type);
    console.log("prospect infos", x);
    let prospectFullname = x.firstName + " " + x.lastName;
    this.setState({
      prospectFullname: prospectFullname,
    });
    let fields = x;
    //let _phFields = this.state._phFields;
    fields["bakeliste_domaine_id"] = x.study_choice;
    fields["first_name"] = x.firstName;
    fields["last_name"] = x.lastName;
    if (x.ispro === false) {
      this.setState({
        prospectStatus: "ETUDIANT",
      });
      if (x.type_formation === "courte") {
        fields["is_pro"] = 0;
        fields["is_etudiant"] = 1;
        fields["nbre_jours"] = 3;
        this.setState({
          isFC: true,
          isFD: false,
          isEtudiant: true,
          isPro: false,
        });
      } else {
        fields["type_formation"] = "diplomante";
        fields["fd_name"] = x.fd_type;
        fields["is_pro"] = 0;
        fields["is_etudiant"] = 1;
        fields["nbre_jours"] = 5;
        fields["jour_fd"] = "Lundi au Vendredi";
        fields["is_week"] = 1;
        fields["is_weekend"] = 0;
        this.setState({
          isFC: false,
          isFD: true,
          isEtudiant: true,
          isPro: false,
        });
      }
    } else {
      this.setState({
        prospectStatus: "PROFESSIONNEL",
      });
      fields["is_pro"] = 1;
      fields["is_etudiant"] = 0;
      if (x.type_formation === "courte") {
        fields["nbre_jours"] = 2;
        this.setState({
          isFC: true,
          isFD: false,
          isEtudiant: false,
          isPro: true,
        });
      } else {
        fields["type_formation"] = "diplomante";
        fields["fd_name"] = x.fd_type;
        fields["nbre_jours"] = 5;
        this.setState({
          isFC: false,
          isFD: true,
        });
      }
      this.setState({
        isEtudiant: false,
        isPro: true,
        isFC: false,
        isFD: true,
      });
    }
    this.setState({
      selectedProspect: x,
      fields: fields,
    });
  }

  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === "objectifs") {
        this.setState({
          obj_empty: false,
        });
      }
      if (input === "phone") {
        this.setState({
          phone_empty: false,
        });
      }
      if (input === "lieu_formation") {
        this.setState({
          lf_empty: false,
        });
      }
      if (input === "boursier") {
        this.setState({
          boursier_empty: false,
        });
      }
      if (input === "paiement") {
        this.setState({
          paiement_empty: false,
        });
      }
      if (input === "successCalledAlert") {
        this.setState({
          successCalledAlert: false,
        });
      }
      if (input === "errorCalledAlert") {
        this.setState({
          errorCalledAlert: false,
        });
      }
      if (input === "successWeekendProspectCalledAlert") {
        this.setState({
          successWeekendProspectCalledAlert: false,
        });
      }
      if (input === "successEteProspectCalledAlert") {
        this.setState({
          successEteProspectCalledAlert: false,
        });
      }
      if (input === "errorWeekendProspectCalledAlert") {
        this.setState({
          errorWeekendProspectCalledAlert: false,
        });
      }
      if (input === "errorEteProspectCalledAlert") {
        this.setState({
          errorEteProspectCalledAlert: false,
        });
      }
      if (input === "addCommentByAdminSuccess") {
        this.setState({
          addCommentByAdminSuccess: false,
        });
      }
    }, 5000);
  }

  validateDataFields() {
    let fields = this.state.fields;
    let errors = {};
    let isValidForm = true;

    if (!fields["objectifs"]) {
      isValidForm = false;
      this.setState({
        obj_empty: true,
      });
      this.handleShowAndHideAlert("objectifs");
      errors["objectifs"] = "*Ce champ est obligatoire";
    }
    // if(!fields["phone"]){
    //   isValidForm = false;
    //   this.state.phone_empty = true;
    //   this.handleShowAndHideAlert('phone');
    //   errors["phone"] = "*Ce champ est obligatoire";
    // }
    if (!fields["lieu_formation"]) {
      isValidForm = false;
      this.setState({
        lf_empty: true,
      });
      this.handleShowAndHideAlert("lieu_formation");
      errors["lieu_formation"] = "*Ce champ est obligatoire";
    }
    if (!fields["boursier"]) {
      isValidForm = false;
      this.setState({
        boursier_empty: true,
      });
      this.handleShowAndHideAlert("boursier");
      errors["boursier"] = "*Ce champ est obligatoire";
    }
    if (!fields["paiement_inscription"]) {
      isValidForm = false;
      this.setState({
        paiement_empty: true,
      });
      this.handleShowAndHideAlert("paiement");
      errors["paiement_inscription"] = "*Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  validateSignUp(e) {
    e.preventDefault();
    if (this.validateDataFields()) {
      this.setState({
        isConversionProgress: true,
      });
      console.log(" validation d'inscription de: ", this.state.fields);
      let id_prospect = this.state.fields["id"];
      axios
        .post(this.state.api + "add-bakeliste", this.state.fields)
        .then((res) => {
          //console.log(res)
          axios
            .delete(this.state.api + "prospects/" + id_prospect)
            .then((res) => {
              console.log(res);
              console.log(res.data);
            });
          this.createNewBakelistePH(this.state.fields);
        })
        .catch((error) => {
          console.log(error);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  }

  createNewBakelistePH(data) {
    axios
      .post(this.state.api + "saved-bakeliste-ph", data)
      .then((response) => {
        console.log("ph response", response);
        this.setState({
          isConversionProgress: false,
        });
        window.location = "liste-bakelistes";
      })
      .catch((error) => {
        console.log("Error ", error);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  getProspectDetails = (prospect) => {
    this.setState({
      prospectItem: prospect,
    });
  };

  getProspectswkete = (prospect) => {
    this.setState({
      prospectwkete: prospect,
    });
    console.log(prospect);
  };

  onCalledStatement = (prospect) => {
    this.setState({
      calledStatementInProgress: true,
    });

    var prospectID = prospect.id;

    axios
      .post(this.state.api + "contacted-prospect/" + prospectID)
      .then((response) => {
        if (response.data.success) {
          this.getProspectList();
          this.setState({
            calledStatementInProgress: false,
            successCalledAlert: true,
          });
          this.handleShowAndHideAlert("successCalledAlert");
        } else {
          this.setState({
            calledStatementInProgress: false,
            errorCalledAlert: true,
          });
          this.handleShowAndHideAlert("errorCalledAlert");
        }
      })
      .catch((error) => {
        this.setState({
          calledStatementInProgress: false,
          errorCalledAlert: true,
        });
        this.handleShowAndHideAlert("errorCalledAlert");
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onCalledOthersProspectsStatement = (prospect, label) => {
    if (label === "weekend") {
      this.setState({
        calledWeekendProspectInProgress: true,
      });

      var prospectID = prospect.id;

      axios
        .post(this.state.api + "contacted-prospect-weekend/" + prospectID)
        .then((response) => {
          if (response.data.success) {
            this.getProspectsWeekend();
            this.setState({
              calledWeekendProspectInProgress: false,
              successWeekendProspectCalledAlert: true,
            });
            this.handleShowAndHideAlert("successWeekendProspectCalledAlert");
          } else {
            this.setState({
              calledWeekendProspectInProgress: false,
              errorWeekendProspectCalledAlert: true,
            });
            this.handleShowAndHideAlert("errorWeekendProspectCalledAlert");
          }
        })
        .catch((error) => {
          this.setState({
            calledWeekendProspectInProgress: false,
            errorWeekendProspectCalledAlert: true,
          });
          this.handleShowAndHideAlert("errorWeekendProspectCalledAlert");
          console.log(error.message);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    } else if (label === "été") {
      this.setState({
        calledEteProspectInProgress: true,
      });

      var prospectID_ = prospect.id;

      axios
        .post(this.state.api + "contacted-prospect-ete/" + prospectID_)
        .then((response) => {
          if (response.data.success) {
            this.getProspectsEte();
            this.setState({
              calledEteProspectInProgress: false,
              successEteProspectCalledAlert: true,
            });
            this.handleShowAndHideAlert("successEteProspectCalledAlert");
          } else {
            this.setState({
              calledEteProspectInProgress: false,
              errorEteProspectCalledAlert: true,
            });
            this.handleShowAndHideAlert("errorEteProspectCalledAlert");
          }
        })
        .catch((error) => {
          this.setState({
            calledEteProspectInProgress: false,
            errorEteProspectCalledAlert: true,
          });
          this.handleShowAndHideAlert("errorEteProspectCalledAlert");
          console.log(error.message);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  getProspectId = (prospect) => {
    this.getCommentOnProspect(prospect.id);
    this.setState({
      commentOnProspectID: prospect.id,
    });
  };

  getCommentOnProspect = (prospectID) => {
    this.setState({
      commentsIsLoading: true,
    });
    axios
      .get(this.state.api + "comments-by-prospect/" + prospectID)
      .then((response) => {
        if (response.data.success === true) {
          console.log("success");
          if (response.data.data !== undefined) {
            this.setState({
              adminComments: response.data.data,
              commentsIsLoading: false,
            });
          }
        } else {
          console.log("empty");
          console.log("Commentaire non trouvé");
          this.setState({
            commentsIsLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isGetCommentsRequestError: true,
          commentsIsLoading: true,
        });
        console.log(error);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  showComment = () => {
    if (this.state.showBloc) {
      this.setState({
        showBloc: false,
      });
    } else {
      this.setState({
        showBloc: true,
      });
    }
    this.getCommentOnProspect(this.state.commentOnProspectID);
  };

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button
          type="button"
          className="btn btn-info"
          id="btn_assign"
          data-toggle="modal"
          data-target="#prospectModal"
          onClick={(e) => this.getProspectDetails(row)}
        >
          <i class="fas fa-eye"></i>
        </button>{" "}
        &nbsp;
        <button
          onClick={() => this.getProspectId(row)}
          type="button"
          className="btn btn-success volkeno-green-bcolor pseudo-comment"
          id="btn_update"
          data-toggle="modal"
          data-target="#CommentModal"
        >
          <i className="fas fa-comment"></i>
          <span className="comment-counter"> {row.commentsCount} </span>
        </button>{" "}
        &nbsp;
        <button
          data-toggle="tooltip"
          data-placement="top"
          title="Déclaration contact"
          type="button"
          className="btn btn-success called-action volkeno-green-bcolor"
          id="btn_called"
          onClick={(e) =>
            window.confirm(
              "Êtes-vous sûr de vouloir déclarer avoir appelé ce prospect ?"
            ) && this.onCalledStatement(row)
          }
        >
          {this.state.calledStatementInProgress === false ? (
            <i className="fas fa-phone"></i>
          ) : (
            <i className="fas fa-spin fa-spinner"></i>
          )}
        </button>
      </div>
    );
  };

  actionswekeFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button
          type="button"
          className="btn btn-info"
          id="btn_assign"
          data-toggle="modal"
          data-target="#prospectswketeModal"
          onClick={(e) => this.getProspectswkete(row)}
        >
          <i class="fas fa-eye"></i>
        </button>{" "}
        &nbsp;
        <button
          data-toggle="tooltip"
          data-placement="top"
          title="Déclaration contact"
          type="button"
          className="btn btn-success called-action volkeno-green-bcolor"
          id="btn_called"
          onClick={(e) =>
            window.confirm(
              "Êtes-vous sûr de vouloir déclarer avoir appelé ce prospect ?"
            ) && row.type === "weekend"
              ? this.onCalledOthersProspectsStatement(row, "weekend")
              : this.onCalledOthersProspectsStatement(row, "été")
          }
        >
          {this.state.calledWeekendProspectInProgress === false ||
          this.state.calledEteProspectInProgress === false ? (
            <i className="fas fa-phone"></i>
          ) : this.state.calledWeekendProspectInProgress === true ||
            this.state.calledEteProspectInProgress === true ? (
            <i className="fas fa-spin fa-spinner"></i>
          ) : (
            ""
          )}
        </button>
      </div>
    );
  };

  rowClassNameFormat = (row, rowIdx) => {
    return row.is_called === true ? "called-prospect" : "";
  };
  rowWEClassNameFormat = (row, rowIdx) => {
    return row.is_contacted === 1 ? "called-prospect" : "";
  };
  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  fullnameFormatter = (cell, row) => {
    return (
      <span
        className="fullname-link"
        data-toggle="modal"
        data-target="#prospectModal"
        onClick={(e) => this.getProspectDetails(row)}
      >
        {row.firstName} {row.lastName}
      </span>
    );
  };

  csvFullNameFormatter = (cell, row) => {
    return row.firstName + " " + row.lastName;
  };

  fullnamepwFormatter = (cell, row) => {
    return (
      <span
        className="fullname-link"
        data-toggle="modal"
        data-target="#prospectswketeModal"
        onClick={(e) => this.getProspectswkete(row)}
      >
        {cell}
      </span>
    );
  };

  statusFormatter = (cell, row) => {
    if (cell === true) {
      return (
        <span className="label label-success pro-label">
          Ce prospect est un professionnel
        </span>
      );
    }
    if (cell === false) {
      return (
        <span className="label label-warning etudiant-label">
          Ce prospect est un étudiant{" "}
        </span>
      );
    }
    // if (cell === "Ce prospect est un éléve") {
    //   return <span className="label label-danger eleve-label"> {cell} </span>;
    // }
  };

  csvstatusFormatter = (cell, row) => {
    if (cell === true) {
      return "Ce prospect est un professionnel";
    }
    if (cell === false) {
      return "Ce prospect est un étudiant";
    }
    // if (cell === "Ce prospect est un éléve") {
    //   return <span className="label label-danger eleve-label"> {cell} </span>;
    // }
  };
  handleAdminCommentChange = (e) => {
    let fields = this.state.acFields;
    fields["admin_id"] = this.state.adminID;
    fields["prospect_id"] = this.state.commentOnProspectID;

    fields[e.target.name] = e.target.value;

    this.setState({
      acFields: fields,
    });

    console.log(fields);
  };

  handleAddAdminComment = (e) => {
    e.preventDefault();
    this.setState({
      addCommentInProgress: true,
    });

    let addData = this.state.acFields;
    axios
      .post(this.state.api + "admin-do-comment-prospects", addData)
      .then((response) => {
        this.setState({
          addCommentInProgress: false,
        });
        // console.log(response.data);
        if (response.data.success === true) {
          this.getProspectList();
          $(".close").click();
          $(".comment_input").val("");
          this.setState({
            fields: {},
            addCommentByAdminSuccess: true,
          });
          this.handleShowAndHideAlert("addCommentByAdminSuccess");
          //window.location = 'dashboard-coach';
        } else {
          this.setState({
            addCommentInProgress: false,
          });
          console.log("error");
        }
      })
      .catch((error) => {
        this.setState({
          addCommentInProgress: false,
          isAddCommentRequestError: true,
        });
        console.log(error);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  villeResidendeFormatter = (cell, row) => {
    if (cell !== null) {
      return (
        <span className="vr_prospect label label-success">
          {" "}
          {row.ville_residence}{" "}
        </span>
      );
    } else {
      return (
        <span className="vr_prospect_empty label label-danger">
          {" "}
          ville non renseignée{" "}
        </span>
      );
    }
  };
  csvvilleResidendeFormatter = (cell, row) => {
    if (cell !== null) {
      return row.ville_residence;
    } else {
      return " ville non renseignée";
    }
  };

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
    };

    if (this.state.loading) {
      return (
        <div className="ListProspects">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">
            <section className="content-header">
              <h1>Liste des prospects</h1>
              <ol class="breadcrumb">
                <li>
                  <NavLink to="dashboard-admin">
                    <i class="fa fa-dashboard"></i> Home
                  </NavLink>
                </li>
                <li className="active">Liste des prospects</li>
              </ol>
            </section>

            <section className="content">
              {/* Blocs contain bakeliste delay list and absence list */}
              <div className="row tab-data-row">
                <div className="col-md-12 tab-data-col">
                  <div className="col-md-12 reporting-container">
                    <div className="row add_prospect_container">
                      <div className="col-md-6 col-md-offset-3 add-prospect-btn-container">
                        <Link
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Ajouter un prospect"
                          to="/ajouter-un-prospect"
                          className="btn btn-success add_coach_btn button button addBtn"
                        >
                          <i className="fas fa-plus-circle"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="list_prospect_container">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Prénom et Nom</th>
                              <th scope="col">Domaines</th>
                              <th scope="col">Date inscription</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <div id="loader"></div>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }

    return (
      <div className="ListProspects">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Liste des prospects</h1>
            <ol class="breadcrumb">
              <li>
                <NavLink to="dashboard-admin">
                  <i class="fa fa-dashboard"></i> Home
                </NavLink>
              </li>
              <li className="active">Liste des prospects</li>
            </ol>
          </section>

          <section className="content">
            {/* Blocs contain bakeliste delay list and absence list */}
            <div className="row tab-data-row">
              <div className="col-md-12 tab-data-col">
                <div className="col-md-12 reporting-container">
                  <div className="row add_prospect_container">
                    <div className="col-md-6 col-md-offset-3 add-prospect-btn-container">
                      <Link
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Ajouter un prospect"
                        to="/ajouter-un-prospect"
                        className="btn btn-success add_coach_btn button button addBtn"
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="list_prospect_container">
                    <div className="row prospect-row-row">
                      <div className="col-md-12 prospect-col">
                        <div className="nav-tabs-custom">
                          <div className="col-md-12 da-list-col">
                            <ul className="nav nav-tabs">
                              <li className="active">
                                <NavLink
                                  to="#bakeli-week"
                                  data-toggle="tab"
                                  aria-expanded="true"
                                >
                                  Prospects Bakeli
                                </NavLink>
                              </li>
                              <li className="">
                                <NavLink
                                  to="#bakeli-weekend"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Prospects MasterClass Weekend
                                </NavLink>
                              </li>
                              <li className="">
                                <NavLink
                                  to="#bakeli-ete"
                                  data-toggle="tab"
                                  aria-expanded="false"
                                >
                                  Prospects Bakeli Été
                                </NavLink>
                              </li>
                              {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                            </ul>
                          </div>
                          <div className="tab-content">
                            <div className="tab-pane active" id="bakeli-week">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 prospectw-table-col">
                                  <BootstrapTable
                                    data={this.state.prospects}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                    trClassName={this.rowClassNameFormat}
                                  >
                                    <TableHeaderColumn
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="frenchDateFormat"
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="firstName"
                                      dataFormat={this.fullnameFormatter.bind(
                                        this
                                      )}
                                      csvFormat={this.csvFullNameFormatter.bind(
                                        this
                                      )}
                                    >
                                      Prénom et Nom{" "}
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="session"
                                    >
                                      Session
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="email"
                                      width="250"
                                    >
                                      Email
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="phone"
                                      width="250"
                                    >
                                      Téléphone
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="motivations"
                                      width="350"
                                    >
                                      Motivations
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      dataFormat={this.villeResidendeFormatter.bind(
                                        this
                                      )}
                                      csvFormat={this.csvvilleResidendeFormatter.bind(
                                        this
                                      )}
                                      width="300"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="ville_residence"
                                    >
                                      Ville de résidence
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="ispro"
                                      csvFormat={this.csvstatusFormatter.bind(
                                        this
                                      )}
                                      dataFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                    >
                                      Statut
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="study_choice"
                                    >
                                      Domaine
                                    </TableHeaderColumn>
                                    {/* <TableHeaderColumn dataSort={true} width="250" filter={{ type: 'TextFilter', delay: 1000 }} dataField='level'>Niveau d'étude</TableHeaderColumn> */}
                                    <TableHeaderColumn
                                      dataField="id"
                                      width="250"
                                      dataFormat={this.actionsFormatter.bind(
                                        this
                                      )}
                                    >
                                      Actions
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="bakeli-weekend">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-weekend-table-col">
                                  <BootstrapTable
                                    data={this.state.prospectsWeekend}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                    trClassName={this.rowWEClassNameFormat}
                                  >
                                    <TableHeaderColumn
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="frenchDateFormat"
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="prospectFullname"
                                      csvFormat={this.fullnameFormatter.bind(
                                        this
                                      )}
                                      dataFormat={this.csvFullNameFormatter.bind(
                                        this
                                      )}
                                    >
                                      Nom complet
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="email"
                                      width="250"
                                    >
                                      Email
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="phone"
                                      width="250"
                                    >
                                      Téléphone
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="ispro"
                                      csvFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                      dataFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                    >
                                      Statut
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="study_choice"
                                    >
                                      Domaine
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="level"
                                    >
                                      Niveau d'étude
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="motivations"
                                      width="350"
                                    >
                                      Motivations
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="id"
                                      width="250"
                                      dataFormat={this.actionswekeFormatter.bind(
                                        this
                                      )}
                                    >
                                      Actions
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="bakeli-ete">
                              <div className="row prospectw-table-row">
                                <div className="col-md-12 bakeli-ete-table-col">
                                  <BootstrapTable
                                    data={this.state.prospectsEte}
                                    striped={true}
                                    hover={true}
                                    condensed={true}
                                    multiColumnSort={2}
                                    options={options}
                                    exportCSV
                                    pagination
                                    search={true}
                                    trClassName={this.rowWEClassNameFormat}
                                  >
                                    <TableHeaderColumn
                                      dataField="id"
                                      isKey={true}
                                      hidden={true}
                                    >
                                      {" "}
                                      ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="applyFrenchDateFormat"
                                    >
                                      Date
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="prospectFullname"
                                      dataFormat={this.fullnamepwFormatter.bind(
                                        this
                                      )}
                                    >
                                      Nom complet
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="email"
                                      width="250"
                                    >
                                      Email
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="phone"
                                      width="250"
                                    >
                                      Téléphone
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="ispro"
                                      dataFormat={this.statusFormatter.bind(
                                        this
                                      )}
                                    >
                                      Statuts
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="domaine"
                                    >
                                      Domaine
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataSort={true}
                                      width="250"
                                      filter={{
                                        type: "TextFilter",
                                        delay: 1000,
                                      }}
                                      dataField="study_level"
                                    >
                                      Niveau d'étude
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="motivations"
                                      width="350"
                                    >
                                      Motivations
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                      dataField="id"
                                      width="250"
                                      dataFormat={this.actionswekeFormatter.bind(
                                        this
                                      )}
                                    >
                                      Actions
                                    </TableHeaderColumn>
                                  </BootstrapTable>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Prospect details infos modal */}
        <div
          className="modal fade"
          id="prospectModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="prospectModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="prospectModallLabel">
                  Detail sur {this.state.prospectItem.firstName}&nbsp;
                  {this.state.prospectItem.lastName}
                </h4>
              </div>
              <div className="modal-body">
                <div className="prospect_infos_container">
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Prénom et Nom :</b>
                    </span>
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectItem.firstName} &nbsp;{" "}
                      {this.state.prospectItem.lastName}{" "}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Email :</b>
                    </span>
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectItem.email}{" "}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Téléphone :</b>
                    </span>
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectItem.phone}{" "}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Ville de résidence :</b>
                    </span>
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectItem.ville_residence}{" "}
                    </span>
                  </div>{" "}
                  <hr />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>

        {/* Prospect weekend and summer details infos modal */}
        <div
          className="modal fade"
          id="prospectswketeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="prospectswketeModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="prospectswketeModalLabel">
                  Detail sur {this.state.prospectwkete.prospectFullname}
                </h4>
              </div>
              <div className="modal-body">
                <div className="prospect_infos_container">
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Prénom et Nom :</b>
                    </span>{" "}
                    <br />
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectwkete.prospectFullname}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Email :</b>
                    </span>{" "}
                    <br />
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectwkete.email}{" "}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Téléphone :</b>
                    </span>{" "}
                    <br />
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectwkete.phone}{" "}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Domaine de formation choisie :</b>
                    </span>{" "}
                    <br />
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectwkete.domaine}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Statut professionel :</b>
                    </span>{" "}
                    <br />
                    <span className="prospect_item_content">
                      {this.state.prospectwkete.is_pro === true && (
                        <span className="label label-success">
                          Ce prospect est un professionnel. <br />
                        </span>
                      )}
                      {this.state.prospectwkete.is_etudiant === true && (
                        <span className="label label-warning">
                          Ce prospect est un étudiant. <br />
                        </span>
                      )}
                      {this.state.prospectwkete.is_eleve === true && (
                        <span className="label label-danger">
                          Ce prospect est un éléve. <br />
                        </span>
                      )}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Niveau d'étude :</b>
                    </span>{" "}
                    <br />
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectwkete.study_level}{" "}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="prospect_item_container">
                    <span className="prospect_item_label">
                      <b>Motivations :</b>
                    </span>{" "}
                    <br />
                    <span className="prospect_item_content">
                      {" "}
                      {this.state.prospectwkete.motivations}{" "}
                    </span>
                  </div>{" "}
                  <hr />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>

        {/* Commentaires */}
        <div
          className="modal fade"
          id="CommentModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="myModalLabel">
                  Commentaires
                </h4>
              </div>
              <div className="modal-body">
                <div className="comments-prospects-container">
                  <div className="toggle-btn-container">
                    <button
                      className="btn btn-primary toggle-btn"
                      onClick={this.showComment}
                    >
                      Voir les commentaires
                    </button>
                  </div>
                  {this.state.showBloc ? (
                    <div className="comment-items">
                      {this.state.commentsIsLoading ? (
                        <div className="loader-container">
                          <div id="loader2"></div>
                          <i>chargement en cours ...</i>
                        </div>
                      ) : this.state.adminComments.length !== 0 ? (
                        this.state.adminComments.map((comment, index) => (
                          <div key={index} className="comment-item">
                            <div className="comment-title">
                              Commentaire de {comment.commenterFullname} du{" "}
                              {comment.frenchDateFormat}
                            </div>
                            <div className="comment-content-container">
                              <p> {comment.comment_content} </p>
                            </div>
                            <hr />
                          </div>
                        ))
                      ) : (
                        <div className="alert alert-warning empty-comment-alert">
                          Aucun commentaire sur ce bakeliste.
                        </div>
                      )}
                      {/* {this.state.adminComments.length !== 0 ?
                                                this.state.adminComments.map((comment, index) =>

                                                    <div key={index} className="comment-item">
                                                        <div className="comment-title">Commentaire de {comment.commenterFullname} du {comment.frenchDateFormat}</div>
                                                        <div className="comment-content-container">
                                                          <p> {comment.comment_content} </p>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                )
                                                :
                                                <div className="alert alert-warning empty-comment-alert">
                                                  Aucun commentaire sur ce bakeliste.
                                                </div>
                                            } */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <hr />
                <div className="form-group">
                  <textarea
                    placeholder="Ajouter un commentaire"
                    name="comment_content"
                    className="form-control comment_input"
                    onChange={this.handleAdminCommentChange}
                  />
                </div>
                {this.state.isAddCommentRequestError && (
                  <div className="alert alert-danger comment-alerty text-center">
                    Un problème est survenu lors de l'ajout de commentaire.
                    Veuillez réessayer plus tard.
                  </div>
                )}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default cancel_btn cancel button"
                    data-dismiss="modal"
                  >
                    <i class="fas fa-times-circle"></i> &nbsp; Quitter
                  </button>
                  {!this.state.addCommentInProgress ? (
                    <button
                      type="button"
                      className="btn btn-primary btn_for_assignation button addBtn"
                      onClick={(e) => this.handleAddAdminComment(e)}
                    >
                      <i class="fas fa-check"></i>&nbsp; Ajouter
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-warning button addBtn btn_for_wait"
                    >
                      Ajout en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Succeccfull called statement toast */}
        {this.state.successCalledAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Appel déclaré avec
            succès.
          </div>
        )}

        {/* Succeccfull called statement toast */}
        {this.state.successEteProspectCalledAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Appel déclaré avec
            succès.
          </div>
        )}

        {/* Succeccfull called statement toast */}
        {this.state.successWeekendProspectCalledAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Appel déclaré avec
            succès.
          </div>
        )}

        {/* Error called statement toast */}
        {this.state.errorCalledAlert === true && (
          <div className="alert alert-danger text-center error-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Oupss ! La
            déclaration a échoué.
          </div>
        )}
        {/* Error called statement toast */}
        {this.state.errorWeekendProspectCalledAlert === true && (
          <div className="alert alert-danger text-center error-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Oupss ! La
            déclaration a échoué.
          </div>
        )}
        {/* Error called statement toast */}
        {this.state.errorEteProspectCalledAlert === true && (
          <div className="alert alert-danger text-center error-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Oupss ! La
            déclaration a échoué.
          </div>
        )}

        {/* Success delay declaration toast alert */}
        {this.state.addCommentByAdminSuccess === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; La déclaration de
            retard a été effectuée avec succès.
          </div>
        )}
      </div>
    );
  }
}

export default ListProspects;
