import React, { Component } from 'react';
import './adminHomeDashboard.css';
//import { Line } from 'react-chartjs-2';
import Menu from '../menu/Menu';
import API from '../../variablesGlobales';
import axios from 'axios';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import '../../../node_modules/admin-lte/dist/js/adminlte.js';
import AdminHomeBakelistesCounter from '../adminHomeBakelistesCounter/AdminHomeBakelistesCounter';
import AdminDashboardChart from './adminDashboardChart/AdminDashboardChart';
import BakelistesPerDay from './../bakelistesPerDay/BakelistesPerDay';
import ListeBakelisteByTrainingPeriod from '../listeBakelisteByTrainingPeriod/ListeBakelisteByTrainingPeriod';
//import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';


class App extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');

        if (!token) {
            window.history.back()
        }
        if (userStatus === 'bakeliste' || userStatus === 'coach') {
            window.history.back();
        }

        super(props);
        this.state = {
            token: '',
            api: API.API,
            tenLastBakelistes: [],
            fields: {},
            domaines: [],
            coachs: [],
            dataBakeliste : {},
            assignationEncours: false,
            userEmptyFeedbakTab:{
                user_id: "",
                feedback_type: "",
                feedback_content: "",
                feedback_priority: "",
                feedback_file:[]
                },
                feedback_id: 0,
                      typeValue: '',
                      contentValue: '',
                      prioriteValue: '',
                      file_pathValue:[0],
                      EmptyType: false,
                      EmptyCommentaire: false,
                      EmptyPriorite: false,
                      errors: {},
                      addFeedbackSuccessAlert: false,
                      addFeedbackProgress: false,
                      addFeedbackError: false,
                      feedbackEnCours:false,
                      isFeedbackAddSuccessAlert:false,
                      image: '',
        }
        this.handleFeedback = this.handleFeedback.bind(this);
        
    }

    componentDidMount() {
        this.getTenLastBakelisteSubscribed();
        this.getAllDomaines();
        this.getAllCoachs();
    }

   
    getAllDomaines() {
        axios.get(this.state.api + 'domaines')
            .then(response => {
               // console.log('domaines ', response.data)
                const domaines = response.data;
                this.setState({
                    domaines: domaines.data,
                });
            })
    }

    getAllCoachs() {
        axios.get(this.state.api + 'liste-des-coachs')
            .then(response => {
               // console.log(response.data)
                const coachsData = response.data;
                this.setState({
                    coachs: coachsData.data,
                });
            })
    }

    getTenLastBakelisteSubscribed() {
        let tenLastBakelistes = {};
        axios.get(this.state.api + 'last-bakelistes-inscrits')
            .then((response) => {
                tenLastBakelistes = response.data.data;
                console.log(tenLastBakelistes);
                this.setState({
                    tenLastBakelistes: tenLastBakelistes
                });
            })
            .catch((error) => {
                //console.log('Erreur lors de la requête ', error);
            });
    }
     /* feedback*/
     handleFeedback = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        //console.log("handle change", e.target.value)
        fields["user_id"] = window.sessionStorage.getItem('user_id')
        
        if(e.target.name === 'feedback_file'){
          fields["feedback_file"] = e.target.files[0]
        }
        if(e.target.name === 'feedback_content'){
          fields[e.target.name]= e.target.value;
        }
        if(e.target.name === 'feedback_priority'){
          fields[e.target.name] = e.target.value;
        }
        this.setState({
            fields
        });
        //console.log("handle change", this.state.fields)
    }
    
        onValidateFeedbackForm = () => {
          let fields = this.state.fields;
          let errors = {};
          let isValidForm = true;
          if (!fields["feedback_content"]) {
            isValidForm = false;
            this.setState({
                EmptyCommentaire: true
            })
            this.handleShowAndHideAlert('EmptyCommentaire');
            errors["EmptyCommentaire"] = "*Ce champ est obligatoire";
        }
        if (!fields["feedback_file"]) {
          isValidForm = false;
          this.setState({
              EmptyFile: true
          })
          
      }
          
          if (!fields["feedback_priority"]) {
              isValidForm = false;
              this.setState({
                  EmptyPriorite: true
              })
              this.handleShowAndHideAlert('EmptyPriorite');
              errors["EmptyPriorite"] = "*Ce champ est obligatoire";
          }
          if (!fields["feedback_type"] || fields["feedback_type"] === '--Choisir un type --') {
              if(fields["feedback_type"] === '--Choisir un type --'){
                  fields["feedback_type"] = ''
              }
              isValidForm = false;
              this.setState({
                  emptyStatus: true
              })
              this.handleShowAndHideAlert('EmptyType');
              errors["EmptyType"] = "*Ce champ est obligatoire";
          }
      
          this.setState({
              errors: errors
          })
      
          return isValidForm;
      }
      
      handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'addSuccessAlert') {
                this.setState({
                    addFeedbackSuccessAlert: false
                });
            }
            if (label === 'isFeedbackAddSuccessAlert') {
                this.setState({
                    isFeedbackAddSuccessAlert: false
                });
              }
            if(label === 'addErrorAlert'){
              this.setState({
                  addFeedbackError: false
              })
          }
         
            if(label === 'EmptyType'){
                this.setState({
                    EmptyType: false
                })
            }
            if(label === 'EmptyCommentaire'){
                this.setState({
                    EmptyCommentaire: false
                })
            }
            if(label === 'EmptyPriorite'){
                this.setState({
                    EmptyPriorite: false
                })
            }
           
           
        }, 5000);
      }
      addFeedback() {
        console.log("report add", this.state.fields);
        if(this.onValidateFeedbackForm()){
            this.setState({
                feedbackEncours: true
            })
            const data = this.state.fields;
           // console.log(dataBakeliste,"donne")
           // console.log(this.state.fields.feedback_file,"fedb")
            const fd = new FormData();
           fd.append('user_id',data.user_id);
          fd.append('feedback_type',data.feedback_type);
          fd.append('feedback_priority',data.feedback_priority)
          fd.append('feedback_content',data.feedback_content);
          fd.append('feedback_file',data.feedback_file);
          //data.set('fields',this.state.fields);
            //console.log(fd,"feed")
            axios.post(this.state.api + 'feedbacks',fd)
                .then(res => {
                    if (res.status === 200) {
                        $('.close').click();
                        this.handleShowAndHideAlert('addSuccessAlert');
                        $('#add-feedback-form')[0].reset();
                        this.setState({
                            addFeedbackSuccessAlert: true,
                           feedbackEnCours: false,
                           isFeedbackAddSuccessAlert:true,
                            fields: {}
                        });
                        this.handleShowAndHideAlert('isFeedbackAddSuccessAlert') ;
                    }
                })
                .catch(error => {
                    this.setState({
                       feedbackEnCours: false,
                        addFeedbackError: true
                    });
                    this.handleShowAndHideAlert('addFeedbackError');
                    console.log(error);
                    if (process.env.NODE_ENV !== 'production') {
                        return;
                    }
                   // Sentry.captureException(error);
                });
        }
        
      }
      
      
      
      
      
      
    render() {
        return (
            <div className="dashboard-container">
                <Menu />
                {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
                <div className="row no-content-wrapper">

                    <section className="content-header">
                        <h1>
                            Dashboard
                            <small>Version 2.0</small>
                        </h1>
                        <ol className="breadcrumb">
                          
                            <li><NavLink to="#"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </section>

                    <section className="content">
                        {/* Blocs contain bakeliste counter */}
                        <AdminHomeBakelistesCounter />
                        <AdminDashboardChart />
                    </section>
                    
                    
                    <div className="row add_bakeliste_container">
                        <div className="col-md-6 col-md-offset-3 add-coach-btn-container">
                            <a href="/ajouter-un-bakeliste" className="btn btn-primary add_coach_btn button" data-toggle="tooltip" data-placement="top" title="Ajouter bakeliste">
                                <i className="fas fa-plus-circle"></i>
                            </a> &nbsp;
                        <a href="/liste-prospects" className="btn btn-success liste_prospect button" data-toggle="tooltip" data-placement="top" title="Voir la liste des prospects">
                                <i className="fas fa-eye"></i>
                            </a>
                        </div>
                    </div>
                    <div className="row bakeliste-training-period-row">
                        <ListeBakelisteByTrainingPeriod />
                    </div>
                    <div className="row bakeliste-per-day-container">
                        <BakelistesPerDay />
                    </div>
                    <div className="row add_bakeliste_container">
                        <div className="col-md-6 col-md-offset-3 add-coach-btn-container pull-right">
                        <a href class="floate" data-target="#infos" data-toggle="modal">
<i class="fas fa-envelope my-floate" style={{color:"white",width:"30px"}}></i>
</a>
                            </div>
                            </div>
                            
                            <div class="modal fade" id="infos">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="close">
          <span>&times;</span>
        </button>
        <h4 class="modal-title">Feedback</h4>
      </div>
      <div class="modal-body">
      <div class="modal-body row">
            <form className="add-feedback-form col">
            <div className="form-group">
              <label>Type feedback <sup className="required-field">*</sup> </label>
               <select className="form-control" id="inputType_Feedback" name="feedback_type" onChange={this.handleFeedback} required={true} >
                <option> --Choisir un type -- </option>
                 <option value="Administratif"> Administratif </option>
                  <option value="Technique"> Technique </option>
                    </select>
                    {this.state.EmptyType &&
                    <div className="errorMsg">{this.state.errors.EmptyType}</div>
                     }
                   </div>
                  

              <div className="form-group green-border-focus">
  <label className="feedback_content">commentaire <sup className="required-field">*</sup> </label>
  <   textarea className="form-control" rows="5" id="appComment" name="feedback_content" placeholder="Votre commentaire" onChange={this.handleFeedback} required={true} />
  {this.state.EmptyCommentaire &&
                    <div className="errorMsg">{this.state.errors.EmptyCommentaire}</div>
                     }
</div>
<div className="form-group">

  <div className="custom-file">
    <input type="file" className="form-control" id="inputGroupFile01" name="feedback_file" onChange={this.handleChange}
      aria-describedby="inputGroupFileAddon01"/>
    <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
  </div>

</div>
<div className="form-group">
<label>Priorité <sup className="required-field">*</sup>:</label>
<label class="checkbox-inline">
  <input type="checkbox" name="feedback_priority" value="utile" onChange={this.handleFeedback}/>1
    </label>
    <label class="checkbox-inline">
      <input type="checkbox" name="feedback_priority" value="Moyen" onChange={this.handleFeedback}/>2
    </label>
    <label class="checkbox-inline">
      <input type="checkbox" name="feedback_priority" value="necessaire" onChange={this.handleFeedback}/>3
    </label>
    <label class="checkbox-inline">
      <input type="checkbox" name="feedback_priority" value="Important" onChange={this.handleFeedback}/>4
    </label>
    <label class="checkbox-inline">
      <input type="checkbox" name="feedback_priority" value="Trés Important" onChange={this.handleFeedback}/>5
    </label>
    {this.state.EmptyPriorite &&
                    <div className="errorMsg">{this.state.errors.EmptyPriorite}</div>
                     }
                     {this.state.addFeedbackError &&
                                        <div className="alert alert-danger addReporting-error">
                                            Une erreur est survenu lors de l'ajout de votre feedback. Veuillez réessayer plus tard.
                                        </div>
                                    }
</div> 

</form>
 <div class="modal-footer">
 <button type="button" className="btn btn-danger pull-left cancel_btn cancel button" data-dismiss="modal">
  <i className="fas fa-times-circle"></i> &nbsp;
 Quitter
 </button>
{!this.state.feedbackEnCours &&
<button type="button" className="btn btn-primary bsi-submit-btn" onClick={(e) => this.addFeedback(e)}>
<i className="fas fa-plus"></i> &nbsp;
Ajouter
</button> }
{this.state.feedbackEnCours  &&
<button type="button" className="btn btn-warning bsi-inprogress-btn">
Ajout en cours &nbsp;
<i className="fas fa-spinner fa-spin"></i>
 </button>
}  
 </div>
</div>
</div>
</div>
</div>
</div>
 {/* add feedback success toast */}
 {this.state.isFeedbackAddSuccessAlert === true &&
<div className="alert alert-success text-center upsuccess-alert">
 Feedback ajouté avec succès.</div>
        }
 </div>
   </div>
            
        );
    }
}

export default App;
