import React, {Component} from 'react';
import './tacheChat.css';
import Menu from './../menu/Menu';
//import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import API from '../../variablesGlobales';
import axios from 'axios';
//import { Form, TextArea } from 'semantic-ui-react-form-validator';
import * as Sentry from '@sentry/browser';
import MultiSelect from "@khanacademy/react-multi-select";

export default class tacheChat extends Component {
    constructor(props){
        super(props);
        this.state = {
			api: API.API,
		    coach: [],
			tache: [],
			bakelistesByCoach: [],
			userStatus: window.sessionStorage.getItem('user_status'),
		    userID: window.sessionStorage.getItem('user_id'),
			userFullName: window.sessionStorage.getItem('userFullName'),
			editorHtml:'',
			bakelistEmptyTacheTab:{},
			bakelistTacheTab:[],
			fullName:'',
			toto:'',
			date:'',
			fields:{
				duration:'6',
				state:'todo',
				todo_date:"",
				tache_url:'',
				content:''
				
			},
			bakeli_user_id:'0',
			isSend:false,
			messages:'', 
		   current_messag:"",
		   noSavedAndSavedTrueAlert: false,
		   noSavedData: [],
		   assignationSuccessAlert: false,
			isAssignationError: false,
			is_only: 1,
		   is_groupy: 0,
		   onlySelect: true,
            groupySelect: false,
           firstbakeliste:[],
		  showMessage:false,
		  nonSelectBakeliste:true,
		  selectOptions: [],
		  selected: [],
		  showgroup:false,
		  startTS: null,
      diff: null,
      suspended: 0,
      interval: null

		}
		this.handSend = this.handSend.bind(this);
		this.onChange = this.onChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
	//	this.assignTache= this.assignTache.bind(this);
		this._handleKeyPress = this._handleKeyPress.bind(this);
		this.handleShowAndHideAlert = this.handleShowAndHideAlert.bind(this);
		this.fadeOutBakelisteTaches = this.fadeOutBakelisteTaches.bind(this);
		this.onHandleSubmit = this.onHandleSubmit.bind(this);
		this.scrollToBottom = this.scrollToBottom.bind(this);
		
	}
	componentDidMount () {
	this.getBakelisteByCoach();
	// window.addEventListener('keydown', this.onKeyDown);
	this.scrollToBottom();
	
	}
	componentWillMount (){
	
	}
	getheure = () =>{
        let rem = new Date()
        this.setState({
          date : rem.getFullYear() + '-' + (rem.getMonth() + 1) + '-' + rem.getDate(),
          heure:rem.getHours(),
          min:rem.getMinutes()

        })
      }
	handleClick(e){
	e.preventDefault();
	this.onHandleSubmit(e);
		this.handSend(e);

	  }
	//    Activate groupy select assignation
     
    onGroupyAssignation = () => {
        this.setState({
			showgroup:true,
            onlySelect: false,
			groupySelect: true,
			is_only:0,
			showMessage:false
			
        })
    }
	  onChange(e) {
		const toto = new Date();
    
		// eslint-disable-next-line react/no-direct-mutation-state
		this.state.date = toto.getFullYear() + '-' + (toto.getMonth() + 1) + '-' + toto.getDate();
		  let fields=this.state.fields;
		  if(this.state.onlySelect){
            fields["is_only"] = 1;
            fields["is_groupy"] = 0;
            fields["selected_bakeliste"] = null;
        }else if(this.state.groupySelect){
            fields["is_groupy"] = 1;
            fields["is_only"] = 0;
            fields["selected_bakeliste"] = this.state.selected;
        }
		  fields["coach_user_id"] = this.state.userID;
		  fields["bakeli_user_id"]= this.state.bakeli_user_id;
		  fields["is_only"] = this.state.is_only;
		  fields["todo_date"]=this.state.date;
		  fields[e.target.name] = e.target.value;
		this.setState({
		  fields:fields
		});  
		console.log(fields,"test")
		//const newLocal = this.state;
		//newLocal.messages=this.state.current_message;
		
	  }
	  handSend = (enter=true) => {
		
		this.assignTache();
		this.handleShowAndHideAlert('successAssignation');
	  }
	  handleClearText (){
		this.setState({
		  content:''
		})
	  }
	  /*
	  _handleKeyPress = (e) => {
		//let enter_pressed = false;
		if(e.key === "Enter"){
		//  enter_pressed = true;
		  e.preventDefault(); 
		}
		
		this.handSend(e)
		this.handleClearText();
	  }
	 */ 
	_handleKeyPress = (event) => {
	
			var code = event.which || event.keyCode; //Selon le navigateur c'est which ou keyCode
			if (code === 13) { //le code de la touche Enter
				event.preventDefault(); 
				this.handSend(event);
				this.handleClearText();	
			}
		}
	
	getBakelisteByCoach = () => {
        axios.get(this.state.api + 'bakelistes-by-coach/' + this.state.userID)
            .then(response => {
                var data = response.data.data;
                console.log('coach bakelistes ', data)
                if (response.data.success === true) {
                    this.setState({
						bakelistesByCoach:data,
						//fullName:data[0].bakelisteFullname
					})
					console.log(this.state.bakelistesByCoach,'liste')
					console.log(this.state.firstbakeliste,"bo")
                }
				data.forEach((item) => {
                    let infos = 
                        {
                            label: item.first_name + ' ' + item.last_name,
                            value: item.id
                        }
                    
                    this.state.selectOptions.push(infos)
                })
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
			})
			
	}
	
	fadeOutBakelisteTaches = (id,first_name,last_name) => {
        axios.get(this.state.api + `taches-by-bakeliste/${id}`)
            .then(res => {
				let bakelistTacheTab = res.data.data;
				
                this.setState({
					bakelistTacheTab, 
					bakelistEmptyTacheTab: bakelistTacheTab,
					fullName:first_name +' '+ last_name,
					bakeli_user_id:id,
					showMessage:true,
					showgroup:false,
					is_groupy:0

					//nonSelectBakeliste:false
				});
				this.start();
                console.log(this.state.bakelistEmptyTacheTab, "bakhna")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })

	}
	handleShowAndHideAlert(label) {
        setTimeout(() => {
            if (label === 'successAssignation') {
                this.setState({
                    assignationSuccessAlert: false
                });
            }
            if (label === 'errorAssignation') {
                this.setState({
                    isAssignationError: false
                });
            }
            
            
        }, 5000);
    }
	  // assigner une tache a un bakeliste

 assignTache = (e) => {
	 let data = this.state.fields;
	 
   console.log(data,"data")
   //console.log(this.state.date,"date")
        axios.post(this.state.api + 'taches',this.state.fields)
            .then(res => {
              // console.log(res)
			   console.log(this.state.fields,"fields")
			  if(res.data.success === true ){
				//  this.fadeOutBakelisteTaches();
				this.handleShowAndHideAlert('successAssignation');
			  this.setState({
				//  isSend:true,
				  assignationSuccessAlert:true 
			  });
			  this.stop();
			  console.log(this.state.messages,'vu')
			  this.handleClearText();
			}    
            })
            .catch(error => {
                console.log(error.message)
                
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
	}
	getFirstLetter(string1,string2){

		return string1.charAt(0).toUpperCase()
		   +string2.charAt(0).toUpperCase();
		}
		onHandleSubmit(e) {
			// e.preventDefault();
			//const messages = this.state.messages;
			//this.props.onSearchTermChange(messages);
			this.setState({
			  messages: ''
			});
		}
		scrollToBottom = () => {
    
			window.scrollTo({
			  top:document.documentElement.scrollHeight,
			  behavior:"smooth"
			})
			};
			/*La fonction "start" démarre un appel répétitive de la fonction update_chrono par une cadence de 100 milliseconde en utilisant setInterval et désactive le bouton "start" */
			getInitialState = () => {
				return {
				  startTS: null,
				  diff: null,
				  suspended: 0,
				  interval: null
				};
			  }
			onKeyDown = (evt) =>{
				evt.preventDefault();
				// start|stop on [space]
				evt.keyCode === 32 && this[!this.state.startTS ? 'start' : 'stop']();
				// reset on [escape]
				evt.keyCode === 27 && this.reset();
			  }
			
			  start = () =>{
				if (this.state.startTS) {
				  // prevent multi clicks on start
				  return;
				}
				this.setState({
				  startTS: +new Date() - this.state.suspended,
				  interval: requestAnimationFrame(this.tick),
				  suspended: 0
				});
			  }
			
			  stop =() =>{
				cancelAnimationFrame(this.state.interval);
				this.setState({
				  startTS: null,
				  suspended: +this.state.diff
				});
			  }
			
			  reset = () =>{
				cancelAnimationFrame(this.state.interval);
				this.setState(this.getInitialState());
			  }
			
			  tick = ()=>{
				this.setState({
				  diff: new Date(+new Date() - this.state.startTS),
				  interval: requestAnimationFrame(this.tick)
				});
			  }
			
			  addZero =(n) =>{
				return n < 10 ? '0' + n : n;
			  }
   
		render(){
			var diff = this.state.diff;
			var hundredths = diff ? Math.round(this.state.diff.getMilliseconds()/10) : 0;
			var seconds = diff ? this.state.diff.getSeconds() : 0;
			// var minutes = diff ? this.state.diff.getMinutes() : 0;
		
			if (hundredths === 100) hundredths = 0;
        return (
			<div className="dashboard-coach-container">
			<Menu />
			<div id="frame">
	       <div id="sidepanel">
		    <div id="profile">
			<div class="wrap">
				<img id="profile-img" src="https://ptetutorials.com/images/user-profile.png" class="online" alt="" />
		       <p>Coach {this.state.userFullName}</p>
				<i class="fa fa-chevron-down expand-button" aria-hidden="true"></i>
			</div>
		   </div>
		<div id="search">
			<label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
			<input type="text" placeholder="Search contacts..." />
		</div>							
		<div id="contacts">
			 <div class="panel-heading c-list">
			 <span class="title">Groupe</span>
			   <ul class="pull-right c-controls">
				 <span  ><h3><i class="fas fa-plus" data-toggle="modal" data-target="#addContact"  onClick={this.onGroupyAssignation}></i></h3></span>
														
				 </ul>
				 </div>
		{this.state.bakelistesByCoach.map((x, y) =>
			<ul value={x.id} key={y}>
				<li class="contact" >
					<div class="wrap" onClick={(y) => this.fadeOutBakelisteTaches(x.id,x.first_name,x.last_name)} >
					<h6 className="rounded-circle" >
                             { x.first_name}&nbsp;{x.last_name}
                            </h6>
						<span class="contact-status online" ></span>
						<img src={require('../../../src/images/wagaan_head.png')} alt="" />
						<div class="meta">
							<p class="name" 
							>{x.first_name}&nbsp;{x.last_name}</p>
							<p class="preview"></p>
							
						</div>
						
					</div>
				</li>
				
			</ul>
			)}
			
		</div>


	</div>
	<div className="modal fade" id="addContact" tabIndex="-1" role="dialog" aria-labelledby="AddContactLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <h4 className="modal-title" id="addContactLabel">Ajout de bakeliste </h4>
                                    </div>
                                    <div className="modal-body" style={{overflowX:"auto",overflowY:"auto", maxHeight:"1000px", maxWidth:"95%"}}>
									<div className="update-coach-status-container">
                                            <div class="form-group " >
											<label>selectionner les bakelistes  : </label>
											<MultiSelect
                                                                overrideStrings={{
                                                                    selectSomeItems: "Choisissez les bakelistes",
                                                                    allItemsAreSelected: "Tous vos bakelistes on été choisis",
                                                                    selectAll: "Sélectionnez tout",
                                                                    search: "Rechercher",
                                                                }}
                                                                options={this.state.selectOptions}
																selected={this.state.selected}
																
                                                                onSelectedChanged={selected => this.setState({selected})}
                                                            />
                                            </div> 
											<div class="form-group " ></div>
                                        </div>
                                        
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                                                <i class="fas fa-times-circle"></i> &nbsp;
                                                Quitter
                                            </button>
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
	{ this.state.showMessage &&
	<div class="content">
	
		<div class="contact-profile"   >
		
			<img src={require('../../../src/images/wagaan_head.png')}alt="" />
		<p><h4>{this.state.fullName}</h4></p>
		
		</div>

		
		<div class="messages">
			<ul>
				 {this.state.bakelistTacheTab.map((x, y) =>
					<li class="sent" key={y} >
						<img src="https://ptetutorials.com/images/user-profile.png" alt="" />
				          <p dangerouslySetInnerHTML={{__html: x.content}} ></p>
			
					</li>
					
					
				)
				} 
				{ this.state.isSend &&
					<li class="replies">
					<img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
				<p>{this.state.content}</p>
				
				</li>
	        }
			</ul>
		
		</div>
		
		<div class="message-input" >
			<div class="wrap">
			  <form >
		               <input type="text" className="chat" placeholder="Ecrire Votre message..." 
		                onChange={this.onChange}
						name="content" 
						/> 
						           
			            <button class="submiter"><h3> <i class="fa fa-paper-plane" 
			            onClick={this.handleClick} 
			           	aria-hidden="true"></i></h3></button>
		           </form>
	

	{/* add tache success toast */}
	{this.state.assignationSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                tâche ajoutée avec succès en {this.addZero(seconds)} secondes
            </div>
	}
	{/* ajout groupe */}
	
			</div> 
		</div>
	</div>
	}
	{/* message groupé */}
	{ this.state.showgroup &&
	<div class="content">
	{ console.log(this.state.selectOptions)}
		<div class="contact-profile"   >
			<img src={require('../../../src/images/wagaan_head.png')}alt="" />
		<p><h4>Groupe</h4></p>
		</div>

		
		<div class="messages">
			<ul>
				 {this.state.bakelistTacheTab.map((x, y) =>
					<li class="sent" key={y} >
						<img src="https://ptetutorials.com/images/user-profile.png" alt="" />
				          <p dangerouslySetInnerHTML={{__html: x.content}} ></p>
			
					</li>
					
					
				)
				} 
				
			</ul>
		
		</div>
		
		<div class="message-input" >
			<div class="wrap">
			<form >
		             <input type="text" className="chat_input" placeholder="Ecrire Votre message..." 
						//value={this.state.fields.content}
		               onChange={this.onChange}
						name="content" onKeyPress={this._handleKeyPress}
						_handleKeyPress={this._handleKeyPress}  
						// value= {this.setState.messages} 
						/>
		
			           {/*<i class="fa fa-paperclip attachment" aria-hidden="true"></i> */}
			            <button class="submiter"><h3> <i class="fa fa-paper-plane" 
			            onClick={this.handleClick} 
			           aria-hidden="true"></i></h3></button>
		           </form>
	

	{/* add tache success toast */}
	{/* {this.state.assignationSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                tâche ajoutée avec succès.
            </div>
	} */}
	{/* ajout groupe */}
	
			</div> 
		</div>
	</div>
	}
</div>
		</div>
		
		)
		
    }
    
}
 /* 
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  tacheChat.modules = {
	toolbar: [
		
		
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{'list': 'ordered'}, {'list': 'bullet'}],
		['link',],
		[{ 'color': [] }, { 'background': [] }],
		
	  ],
	  clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	  }
  }
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  tacheChat.formats = [
	'header', 'font', 'size',
	'bold', 'italic', 'underline', 'strike', 'blockquote',
	'list', 'bullet', 'indent',
	'link', 'image',
	'color', 'background'
  ]
 