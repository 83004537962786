import React, { Component } from 'react';
//import './addRdv.css';
import axios from 'axios';
import API from '../../variablesGlobales';
import Menu from './../menu/Menu';
import { Link } from 'react-router-dom';


class addRdv extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.state = {
            fields: {},
            errors: {},
            value: '',
            
            api: API.API,
            fn_empty: false,
            ln_empty: false,
            email_empty: false,
            phone_empty: false,
            tf_empty: false,
            df_empty: false,
            fd_empty: false,
            addRdvInProgress: false
        };
    }

    handleSubmit(event) {
        //alert('An essay was submitted: ' + this.state.firstName);
        event.preventDefault();
    }

    

    handleShowAndHideAlert(input){
        setTimeout(() => {
            if(input === 'first_name'){
                this.setState({
                    fn_empty: false
                });
            }
            if(input === 'last_name'){
                this.setState({
                    ln_empty: false
                });
            }
            if(input === 'email'){
                this.setState({
                    email_empty: false
                });
            }
            if(input === 'phone'){
                this.setState({
                    phone_empty: false
                });
            }
            // if(input == 'commentaires'){
            //     this.setState({
            //         tf_empty: false
            //     });
            // }
            
            
            
        }, 5000);
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["first_name"]) {
            formIsValid = false;
            this.setState({
                fn_empty: true
            })
            this.handleShowAndHideAlert("first_name");
            errors["first_name"] = "*Ce champ est obligatoire";
        }

        if (typeof fields["first_name"] !== "undefined") {
            if (!fields["first_name"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
                formIsValid = false;
                this.setState({
                    fn_empty: true
                })
                this.handleShowAndHideAlert("first_name");
                errors["first_name"] = "*SVP que des lettres.";
            }
        }

        if (!fields["last_name"]) {
            formIsValid = false;
            this.setState({
                ln_empty: true
            })
            this.handleShowAndHideAlert("last_name");
            errors["last_name"] = "*Ce champ est obligatoire";
        }

        if (typeof fields["last_name"] !== "undefined") {
            if (!fields["last_name"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
                formIsValid = false;
                this.setState({
                    ln_empty: true
                })
                this.handleShowAndHideAlert("last_name");
                errors["last_name"] = "*SVP que des lettres.";
            }
        }

        if (!fields["email"]) {
            formIsValid = false;
            this.setState({
                email_empty: true
            })
            this.handleShowAndHideAlert("email");
            errors["email"] = "*Ce champ est obligatoire.";
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                this.setState({
                    email_empty: true
                })
                this.handleShowAndHideAlert("email");
                errors["email"] = "*Adresse email invalide.";
            }
        }

        if (!fields["phone"]) {
            formIsValid = false;
            this.setState({
                phone_empty: true
            })
            this.handleShowAndHideAlert("phone");
            errors["phone"] = "*Ce champ est obligatoire.";
        }

        if (typeof fields["phone"] !== "undefined") {
            if (!fields["phone"].match(/^[0-9]{9}$/)) {
                formIsValid = false;
                this.setState({
                    phone_empty: true
                })
                this.handleShowAndHideAlert("phone");
                errors["phone"] = "*Numéro de telephone invalide.";
            }
        }
       
        // if (!fields["commentaires"]) {
        //     formIsValid = false;
        //     this.state.tf_empty = true;
        //     this.handleShowAndHideAlert("commentaires");
        //     errors["commentaires"] = "*Ce champ est obligatoire.";
        // }
        
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        fields["user_id"] = parseInt(window.sessionStorage.getItem('user_id'), 10)
        this.setState({
            fields: fields
        });

       // console.log(fields)
    }

    submituserRegistrationForm(e) {
        e.preventDefault();
        if (this.validateForm()) {
            this.setState({
                addRdvInProgress: true
            })
            let fields = {};
            fields["first_name"] = "";
            fields["last_name"] = "";
            fields["email"] = "";
            fields["phone"] = "";
            fields["commentaires"] = "";
            fields["date_rdv"] = "";
            fields["heure"] = "";
           
            this.setState({ fields: fields });
            const dataRdv = this.state.fields;
            //console.log(dataRdv);
            axios.post(this.state.api + 'rdvs', dataRdv)
            .then(res => {
               // console.log(res);
                //console.log(res.data);
                if (res.data.success === true) {
                    this.setState({
                        addRdvInProgress: false
                    })
                    window.location = 'gestion-des-rendez-vous';
                }else{
                    this.setState({
                        addRdvInProgress: false,
                        isAddRequestError: true
                    })
                    console.log(res)
                }
            })
            .catch(error => {
                console.log(error.message)
                this.setState({
                    addRdvInProgress: false,
                    isAddRequestError: true
                })
            })
            
        }
    }

   


    render() {
        return (
            <div>
                <Menu />

                <div className="addRdv row" >
                    <div className="col-lg-12">
                        <h1 className="page-header">
                            <span className="page-title">Ajouter un rendez-vous</span>
                            <div className="pull-right">
                                <ol className="breadcrumb">
                                    <li>
                                        <Link to="/dashboard-admin">Accueil</Link>
                                    </li>
                                    <li className="active">Ajouter un rendez-vous</li>
                                </ol>
                            </div>
                        </h1>
                    </div>
                    <div className="col-md-8 col-md-offset-2 add_coach_form_container">
                        <form onSubmit={this.submituserRegistrationForm}  >
                            <div class="form-row">
                                <div>
                                    <h1 id="titreCoach">Ajouter un rendez-vous</h1>
                                </div>
                                <div class="form-group ">
                                    <label >Prénom:</label>
                                    <input type="text" class="form-control" id="inputpFirstName" placeholder="Prénom" name="first_name" value={this.state.fields.first_name} onChange={this.handleChange} />
                                </div>
                                {this.state.fn_empty &&
                                    <div className="errorMsg">{this.state.errors.firstName}</div>
                                }
                                <div class="form-group ">
                                    <label >Nom:</label>
                                    <input type="text" class="form-control" id="inputLastName" placeholder="Nom" name="last_name" value={this.state.fields.last_name} onChange={this.handleChange} />
                                </div>
                                {this.state.ln_empty &&
                                    <div className="errorMsg">{this.state.errors.lastName}</div>
                                }

                                <div class="form-group " >
                                    <label >Email:</label>
                                    <input type="Email" class="form-control" id="inputEmail" placeholder="Email" name="email" value={this.state.fields.email} onChange={this.handleChange} />
                                </div>
                                {this.state.email_empty &&
                                    <div className="errorMsg">{this.state.errors.email}</div>
                                }
                                
                                <div class="form-group ">
                                    <label >Téléphone:</label>
                                    <input type="text" class="form-control" id="inputNumero" placeholder="Téléphone" name="phone" onChange={this.handleChange} />
                                </div>
                                {this.state.phone_empty &&
                                    <div className="errorMsg">{this.state.errors.phone}</div>
                                }

                                <div class="form-group ">
                                    <label >Commentaires:</label>
                                    <textarea class="form-control" id="inputpFirstName" placeholder="Ajoutez un commentaire" name="commentaires" value={this.state.fields.commentaires} onChange={this.handleChange} />
                                </div>

                                <div class="form-group ">
                                    <label >Date du RDV:</label>
                                    <input type="date" class="form-control" id="inputpFirstName" placeholder="commentaire" name="date_rdv" value={this.state.fields.date_rdv} onChange={this.handleChange} />
                                </div>
                                <div class="form-group ">
                                    <label >Heure:</label>
                                    <input type="integer" class="form-control" id="inputpFirstName" placeholder="Heure du RDV (Exemple : 10h30)" name="heure" value={this.state.fields.heure} onChange={this.handleChange} />
                                </div>
                                </div>


                                {/* {this.state.addRdvInProgress === false ?
                                    <button type="submit" className='btn btn-warning addBtn button submit'>
                                        <i className="fas fa-plus-circle"></i> &nbsp;
                                        Ajouter
                                    </button> :
                                    <button type="submit" className='btn btn-warning spinnerBtn button submit'>
                                        <i className="fas fa-spin fa-spinner"></i> &nbsp;
                                        Ajout en cours
                                    </button>
                                } */}
                                <div className="col-md-8 col-md-offset-2 submit-btn-container">
                                    <div className="form-group " >
                                        {!this.state.addRdvInProgress ?
                                            <button type="submit" className='btn btn-warning add-item-btn'>
                                                <i className="fas fa-plus-circle"></i> &nbsp;
                                                Ajouter
                                            </button>:
                                            <button type="submit" className='btn btn-warning loading-btn'>
                                                Ajout en cours &nbsp;
                                                <i className="fas fa-spin fa-spinner"></i>
                                            </button>
                                        }
                                    </div>
                                </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default addRdv;

