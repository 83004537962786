import React, { Component } from "react";

import axios from "axios";
import * as Sentry from "@sentry/browser";
import { MagicSpinner } from "react-spinners-kit";
import API from "../../variablesGlobales";
import $ from "jquery";

import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import { Link, NavLink } from "react-router-dom";

import Menu from "../menu/Menu";

export default class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      api: API.API,
      addToolInProgress: false,
      newTool: {
        name: "",
      },
      tools: [],
    };
  }
  componentDidMount() {
    this.__getTools();
  }
  __getTools = () => {
    axios
      .get(this.state.api + "tools")
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            tools: res.data.data.reverse(),
          });
        }
        //console.log(this.state.tache);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  deleteTool = (tool) => {
    axios
      .delete(this.state.api + "tools/" + tool.id)
      .then((res) => {
        if (res.data.success) {
          this.__getTools();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  actionsFormatter(cell, row) {
    return (
      <span>
        <button
          onClick={() => {
            this.deleteTool(row);
          }}
          id="delete_tool"
          className="btn btn-info show-btn volkeno-red-bcolor"
        >
          <i class="fa fa-trash"></i>
        </button>
        &nbsp;&nbsp;
      </span>
    );
  }

  addTools() {
    this.setState({
      addToolInProgress: true,
    });

    axios
      .post(this.state.api + "tools", this.state.newTool)
      .then((res) => {
        if (res.data.success) {
          this.__getTools();
          $(".close").click();
          this.setState({
            addToolInProgress: false,
            name: ""
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const options = {
      // clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
    };
    return (
      <div>
        <Menu />
        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Liste des Outils</h1>
            <ol class="breadcrumb">
              <li>
                <NavLink to="dashboard-admin">
                  <i class="fa fa-dashboard"></i> Home
                </NavLink>
              </li>
              <li className="active">Liste des outils</li>
            </ol>
          </section>
          <div className="row add_prospect_container">
            <div className="col-md-6 col-md-offset-3 add-prospect-btn-container">
              <span
                data-toggle="modal"
                className="btn btn-success add_coach_btn button button addBtn"
                data-placement="top"
                data-target="#addToolsModal"
              >
                <i className="fas fa-plus-circle"></i>
              </span>
            </div>
          </div>

          {/* Modal Add Tools */}

          <div
            className="modal fade"
            id="addToolsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addToolsModalLabel"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="addToolsModalLabel">
                    Ajouter un outil
                  </h4>
                </div>

                <div className="modal-body">
                  <div className="edit-bakeliste-container">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label>Nom:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="Nom"
                          name="name"
                          value={this.state.name}
                          onChange={(e) => {
                            let newTool = this.state;
                            newTool.name = e.target.value;
                            this.setState({
                              newTool,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="button"
                          className="btn btn-default cancel_btn cancel button"
                          data-dismiss="modal"
                        >
                          <i className="fas fa-times-circle"></i> &nbsp; Quitter
                        </button>
                        {!this.state.addToolInProgress ? (
                          <button
                            type="button"
                            className="btn btn-primary btn_for_assignation button addBtn"
                            onClick={(e) => this.addTools(e)}
                          >
                            <i className="fas fa-undo"></i>&nbsp; Ajouter
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning button addBtn btn_for_wait"
                          >
                            Ajouter en cours &nbsp;
                            <i className="fas fa-spinner fa-spin"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <BootstrapTable
            data={this.state.tools}
            striped={true}
            hover={true}
            condensed={true}
            multiColumnSort={2}
            options={options}
            pagination
            search={true}
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              {" "}
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name">Nom</TableHeaderColumn>

            <TableHeaderColumn
              dataField="id"
              dataFormat={this.actionsFormatter.bind(this)}
              dataSort={true}
            >
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    );
  }
}
