import React, { Component } from 'react';
import './BakelistesPerDay.css';
//import { Line } from 'react-chartjs-2';
//import Menu from '../menu/Menu';
import API from '../../variablesGlobales';
import axios from 'axios';
//import { NavLink } from 'react-router-dom';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import '../../../node_modules/admin-lte/dist/js/adminlte.js';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';

export default class BakelistesPerDay extends Component {
  constructor(props) {
    super(props);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.bakelisteFilter = this.bakelisteFilter.bind(this);

    this.state = {
      api: API.API,
      currentDayname: '',
      isWidgetDataLoading: false,
      isBakelisteFilterData: false,
      isDataRequestError: false,
      fields: {},
      filterBakelistesData: [],
      filterInProgress: false,
      noFilterAction: true,
      spinnerFilterContent: false,
      errors: {},
      isFilterRequestError: false,
      showFilterResult: false,
      countData: 0,
      dayName: '',
      horaire: '',
      espace: ''
    };

    this.renderCoachName = this.renderCoachName.bind(this)
  }

  componentDidMount() {
    this.getCurrentDayName();
    this.getBakelistesPresentAtCurrentDay();
  }


  getCurrentDayName = () => {
    var a = new Date();
    var days = new Array(7);
    days[0] = "Dimanche";
    days[1] = "Lundi";
    days[2] = "Mardi";
    days[3] = "Mercredi";
    days[4] = "Jeudi";
    days[5] = "Vendredi";
    days[6] = "Samedi";
    var dayname = days[a.getDay()];

    // console.log(dayname)
    // return dayname;
    this.setState({
      currentDayname: dayname
    })
  }

  /**
   * Get liste bakeliste present at the current day
   */
  getBakelistesPresentAtCurrentDay = () => {

    // // this.setState({
    // //   currentDayname: this.getCurrentDayName()
    // // })
    // var currentDayname = this.state.currentDayname;
    // console.log(this.state.currentDayname)
    // //console.log(currentDayname);
    var a = new Date();
    var days = new Array(7);
    days[0] = "Dimanche";
    days[1] = "Lundi";
    days[2] = "Mardi";
    days[3] = "Mercredi";
    days[4] = "Jeudi";
    days[5] = "Vendredi";
    days[6] = "Samedi";
    var dayname = days[a.getDay()];

    //var currentDayname = dayname;
    //console.log(currentDayname);
    this.setState({
      isWidgetDataLoading: true
    })

    axios.get(this.state.api + 'liste-bakeliste-par-jour/' + dayname)
      .then(response => {
        if (response.data.success === true) {
          this.setState({
            isWidgetDataLoading: false,
            volkenoFoire8h13h: response.data.premierVF, // bakeliste présent à Volkeno Foire à la date du jour de 8h à 13h
            volkenoFoire13h18h: response.data.deuxiemeVF, // bakeliste présent à Volkeno Foire à la date du jour de 13h 18h
            volkenoFoire8h18h: response.data.troisiemeVF, // bakeliste présent à Volkeno Foire à la date du jour de 8h 18h
            volkenoFoire17h3019h30: response.data.quatriemeVF, // bakeliste présent à Volkeno Foire à la date du jour de 17h30 19h30

            volkenoGY18h13h: response.data.premierGY1, // bakeliste présent à Volkeno GY1 à la date du jour de 8h à 13h
            volkenoGY113h18h: response.data.deuxiemeGY1, // bakeliste présent à Volkeno GY1 à la date du jour de 13h 18h
            volkenoGY18h18h: response.data.troisiemeGY1, // bakeliste présent à Volkeno GY1 à la date du jour de 8h 18h
            volkenoGY117h3019h30: response.data.quatriemeGY1, // bakeliste présent à Volkeno GY1 à la date du jour de 17h30 19h30

            volkenoGY28h13h: response.data.premierGY2, // bakeliste présent à Volkeno GY2 à la date du jour de 8h à 13h
            volkenoGY213h18h: response.data.deuxiemeGY2, // bakeliste présent à Volkeno GY2 à la date du jour de 13h 18h
            volkenoGY28h18h: response.data.troisiemeGY2, // bakeliste présent à Volkeno GY2 à la date du jour de 8h 18h
            volkenoGY217h3019h30: response.data.quatriemeGY2, // bakeliste présent à Volkeno GY2 à la date du jour de 17h30 19h30
          })
        } else {
          console.log(response)
          this.setState({
            isWidgetDataLoading: false,
            isDataRequestError: true
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isWidgetDataLoading: false,
          isDataRequestError: true
        })
      })
  }

  /**
   * Go to bakeliste filter view
   */
  switchToBakelisteFilter = () => {
    this.setState({
      isBakelisteFilterData: true
    })
  }

  /**
   * Go to bakeliste dayname countig
   */
  switchToCurrentDateBakeliste = () => {
    this.setState({
      isBakelisteFilterData: false,
      spinnerFilterContent: false,
      filterInProgress: false
    })
    document.getElementById('bakeliste-filter-form').reset();
  }

  /**
   * Hide alert message block after 5s
   */
  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === 'dayname') {
        this.setState({
          daynameError: false
        });
      }
      if (input === 'horaire') {
        this.setState({
          horaireError: false
        });
      }
      if (input === 'lieu') {
        this.setState({
          lieuFormationError: false
        });
      }
    }, 5000);
  }

  /**
   * Handle Change Filter Form
   */
  handleChangeFilter = (e) => {
    var fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields
    })
    console.log(fields)
  }

  /**
   * Filter form validation
   * 
   * @return boolean
   */
  validateFilterForm = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    let isValidInput = true;

    if (!fields["dayname"]) {
      isValidInput = false;
      this.setState({
        daynameError: true
      })
      errors["dayname"] = 'Ce champ ne doit pas être vide';
      this.handleShowAndHideAlert("dayname")
    }
    if (!fields["horaire"]) {
      isValidInput = false;
      this.setState({
        horaireError: true
      })
      errors["horaire"] = 'Ce champ ne doit pas être vide';
      this.handleShowAndHideAlert("horaire")
    }
    if (!fields["lieu_formation"]) {
      isValidInput = false;
      this.setState({
        lieuFormationError: true
      })
      errors["lieu_formation"] = 'Ce champ ne doit pas être vide';
      this.handleShowAndHideAlert("lieu")
    }

    return isValidInput;
  }

  /**
   * Get filter form result
   */
  bakelisteFilter = (e) => {
    e.preventDefault();

    if (this.validateFilterForm()) {
      this.setState({
        filterInProgress: true,
        noFilterAction: false,
        spinnerFilterContent: true,
      })

      var dayname = this.state.fields.dayname;
      var horaire = this.state.fields.horaire;
      var lieu_formation = this.state.fields.lieu_formation;
      //console.log(this.state.api + 'filter-liste-bakeliste/' + dayname + '/' + horaire + '/' + lieu_formation)
      //console.log(dayname)
      axios.get(this.state.api + 'filter-liste-bakeliste/' + dayname + '/' + horaire + '/' + lieu_formation)
        .then(response => {
          if (response.data.success === true) {
            //console.log(response.data.data)
            document.getElementById('bakeliste-filter-form').reset();
            this.setState({
              filterInProgress: false,
              spinnerFilterContent: false,
              filterBakelistesData: response.data.data,
              countData: response.data.count,
              showFilterResult: true,
              dayName: dayname,
              horaire: horaire,
              espace: lieu_formation
            })
            console.log(this.state.filterBakelistesData)
          } else {
            this.setState({
              isFilterRequestError: true,
              filterInProgress: false,
              spinnerFilterContent: false
            })
            console.log(response)
          }
        })
        .catch(error => {

        })
    }
  }

  renderCoachName = (coach_id) => {
    let coachs = [];
    var coachFullname = ''
    axios.get(this.state.api + 'liste-des-coachs')
      .then(response => {
        if (response.data.success === true) {
          coachs = response.data.data;
          coachs.forEach(item => {
            if (item.id === coach_id) {
              coachFullname = item.first_name + ' ' + item.last_name
            }
          })
          console.log(coachFullname)
          return (coachFullname);
        } else {
          console.log(response)
          return 'Coach non trouvé'
        }
      })
      .catch(error => {
        console.log(error)
        return 'Coach non retrouvé'
      })
  }

  renderDomaineName = (domaine_id) => {
    let domaines = [];
    var domaineName = ''
    axios.get(this.state.api + 'domaines')
      .then(response => {
        if (response.data.success === true) {
          domaines = response.data.data;
          domaines.forEach(item => {
            if (item.id === domaine_id) {
              domaineName = item.name
            }
            console.log(domaineName)
          })

          return domaineName;
        } else {
          console.log(response)
          return 'Domaine non trouvé'
        }
      })
      .catch(error => {
        console.log(error)
        return 'Domaine non trouvé'
      })
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  bakelisteFullnameFormatter = (cell, row) => {
    return (
      <span data-toggle="modal" data-target="#detailsBakelisteModal" className="fullname-link">{cell}</span>
    );
  }

  domaineFormatter = (cell, row) => {
    return (
      <span>
        { cell }
      </span>
    );
  }

  coachNameFormatter = (cell, row) => {
    return (
      <span>
        { cell }
      </span>
    );
  }

  actionsFormatter = (cell, row) => {

  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };

    return (
      <div className="component-bakelistes-per-day col-md-12">
        <div className="col-md-12">
          <div className="box">
            <div className="box-header with-border  volkeno-green-bcolor widget-header">
              <h3 className="box-title">Présence des bakelistes</h3>
              <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" data-widget="collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">
              {this.state.isBakelisteFilterData === false ?
                <div className="current-date-bakeliste-container row">
                  <div className="row title-and-btn-container">
                    <h3 className="cdb-title col-md-6"> Bakeliste présents ce {this.state.currentDayname} </h3>
                    <div className="cdb-switcher-btn-container col-md-6">
                      <button className="volkeno-orange-bgcolor btn btn-warning" onClick={this.switchToBakelisteFilter}>
                        Accéder au bakeliste filter
                  </button>
                    </div>
                  </div>
                  <div className="volkeno-foire-container row">
                    <h4 className="push-left lieu_title">Volkeno Foire</h4>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 8h - 13h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoFoire8h13h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 13h - 18h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoFoire13h18h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 8h - 18h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoFoire8h18h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 17h30 - 19h30
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoFoire17h3019h30}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="volkeno-foire-container row">
                    <h4 className="push-left lieu_title">Volkeno HML GRAND YOFF 1</h4>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 8h - 13h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoGY18h13h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 13h - 18h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoGY113h18h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 8h - 18h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoGY18h18h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 17h30 - 19h30
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoGY117h3019h30}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="volkeno-foire-container row">
                    <h4 className="push-left lieu_title">Volkeno HML GRAND YOFF 2</h4>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 8h - 13h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoGY28h13h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 13h - 18h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoGY213h18h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 8h - 18h
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoGY28h18h}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 cdb-item">
                      <div className="col-intermediaire col-md-12">
                        <div className="cdb-item-title">
                          Bakelistes 17h30 - 19h30
                    </div>
                        <div className="cdb-item-count">
                          {this.state.volkenoGY217h3019h30}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div> :

                <div className="filter-bakeliste-container">
                  <div className="row title-and-btn-container">
                    <h3 className="cdb-title col-md-6"> Bakeliste Filter </h3>
                    <div className="cdb-switcher-btn-container col-md-6">
                      <button className="volkeno-orange-bgcolor btn btn-warning" onClick={this.switchToCurrentDateBakeliste}>
                        Accéder au filtre du jour
                  </button>
                    </div>
                  </div>

                  <div className="bakeliste-filter-form-container">
                    <div className="col-md-3 filter-form">
                      <form onSubmit={this.bakelisteFilter} id="bakeliste-filter-form">
                        <div className="col-md-12 form-group filter-item">
                          <label>Jour de présence</label>
                          <select required className="form-control finput-item" name="dayname" onChange={this.handleChangeFilter}>
                            <option>-- Choisir un jour --</option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                            <option value="Dimanche">Dimanche</option>
                          </select>
                          {this.state.daynameError &&
                            <div className="col-md-12 alert alert-danger danger-alerty">
                              {this.state.errors.dayname}
                            </div>
                          }
                        </div>
                        <div className="col-md-12 form-group filter-item">
                          <label>Heure de présence</label>
                          <select required className="form-control finput-item" name="horaire" onChange={this.handleChangeFilter}>
                            <option>-- Choisir une plage horaire --</option>
                            <option value="8h-13h">8h - 13h</option>
                            <option value="13h-18h">13h - 18h</option>
                            <option value="8h-18h">8h - 18h</option>
                            <option value="17h30-19h30">17h 30 - 19h 30</option>
                          </select>
                          {this.state.horaireError &&
                            <div className="col-md-12 alert alert-danger danger-alerty">
                              {this.state.errors.horaire}
                            </div>
                          }
                        </div>
                        <div className="col-md-12 form-group filter-item">
                          <label>Lieu de formation</label>
                          <select required className="form-control finput-item" name="lieu_formation" onChange={this.handleChangeFilter}>
                            <option>-- Choisir un lieu de formation --</option>
                            <option value="Sicap Foire">Sicap Foire</option>
                            <option value="Grand Yoff 1">Grand Yoff 1</option>
                            <option value="Grand Yoff 2">Grand Yoff 2</option>
                            <option value="Thiés">Thiés</option>
                            <option value="Tambacounda">Tambacounda</option>
                          </select>
                          {this.state.lieuFormationError &&
                            <div className="col-md-12 alert alert-danger danger-alerty">
                              {this.state.errors.lieu_formation}
                            </div>
                          }
                        </div>
                        <div className="col-md-12 form-group finput-item">
                          {this.state.filterInProgress === false ?
                            <button className="btn btn-success volkeno-green-bcolor filter-submit">
                              <i className="fas fa-filter"></i> &nbsp; Valider
                            </button> :
                            <button className="btn btn-success volkeno-orange-bcolor filter-submit">
                              <i className="fas fa-spin fa-spinner"></i> &nbsp; En cours
                            </button>
                          }
                        </div>
                      </form>
                    </div>

                    <div className="col-md-9 filter-result-container">
                      {this.state.noFilterAction === true &&
                        <div className="noFilter-container">
                          <div className="col-md-12 noFilter-alert">
                            <div className="alert alert-warning filter-alerty">
                              Veuillez utiliser le formulaire pour effectuer votre recherche !
                            </div>
                          </div>
                        </div>
                      }
                      {this.state.spinnerFilterContent === true &&
                        <div className="filter-in-progress">
                          <div className="col-md-6 col-md-offset-3 text-center spinner-filter">
                            <i className="fas fa-spin fa-spinner fa-5x"></i>
                          </div>
                        </div>
                      }
                      {this.state.showFilterResult === true &&
                        <div className="filter-result-data-container">
                          <div className="volkeno-foire-container row">
                            <h4 className="push-left lieu_title"> {this.state.espace} - {this.state.dayName} </h4>
                            <div className="col-md-3 cdb-item">
                              <div className="col-intermediaire col-md-12">
                                <div className="cdb-item-title">
                                  {this.state.horaire}
                                </div>
                                <div className="cdb-item-count">
                                  {this.state.countData}
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="col-md-12 result-data-table-container">
                            <BootstrapTable data={this.state.filterBakelistesData}
                              striped={true} hover={true} condensed={true}
                              multiColumnSort={2} options={options}
                              exportCSV
                              pagination
                              search={true}>

                              <TableHeaderColumn dataAlign="center" width='50' dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                              <TableHeaderColumn dataAlign="center"
                                width='300'
                                dataField='bakelisteFullname'
                                dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                                filter={{ type: 'TextFilter', delay: 1000 }}
                                dataSort={true} >Nom complet</TableHeaderColumn>
                              <TableHeaderColumn dataAlign="center"
                                width='200'
                                dataField='email'
                                filter={{ type: 'TextFilter', delay: 1000 }}
                                dataSort={true}>Email</TableHeaderColumn>
                              <TableHeaderColumn dataAlign="center"
                                width='200'
                                dataField='phone'
                              >Téléphone</TableHeaderColumn>
                              <TableHeaderColumn dataAlign="center"
                                width='200'
                                dataField='domaine'
                                dataFormat={this.domaineFormatter.bind(this)}
                                filter={{ type: 'TextFilter', delay: 1000 }}
                                dataSort={true}>Domaine</TableHeaderColumn>
                              <TableHeaderColumn dataAlign="center"
                                width='250'
                                dataField='coachFullname'
                                dataFormat={this.coachNameFormatter.bind(this)}
                                filter={{ type: 'TextFilter', delay: 1000 }}
                                dataSort={true}>Coach</TableHeaderColumn>
                              {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

      </div>
    );
  }
}
