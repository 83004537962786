import React, { Component } from 'react';
import './importerCsv.css';
import { Link } from 'react-router-dom';
import API from '../../variablesGlobales';
import APIBE from '../../variablesGlobales';
import axios from 'axios';
import Menu from '../menu/Menu';
import $ from 'jquery';
import { CSVReader } from 'react-papaparse';

export default class importerCsv extends Component {
  constructor(props) {
    super(props);

    let token = window.sessionStorage.getItem('token');
    let userStatus = window.sessionStorage.getItem('user_status');

    if (!token) {
      window.history.back()
    }
    if (userStatus === 'bakeliste' || userStatus === 'coach') {
      window.history.back();
    }

    this.handleReadCSV = this.handleReadCSV.bind(this);
    this.handleReadCSVForBakeliSiUser = this.handleReadCSVForBakeliSiUser.bind(this);

    this.state = {
      apibsi: API.API,
      apibeng: APIBE.APIBE,
      importInProgress: false,
      successImportAlert: false,
      noregisters: [],
      showNoRegisterListName: false,

    };
    this.fileInput = React.createRef();
  }

  componentDidMount() {

  }

  /**
 * Hide Alert message after 5s
 */
  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === 'successImport') {
        this.setState({
          successImportAlert: false
        });
      }
    }, 5000);
  }


  handleReadCSV = (data) => {
    this.setState({
      importInProgress: true
    })
    //console.log(data.data)
    var datalength = data.data.length;
    // console.log(data);
    let importer = data.data;
    let noregisters = this.state.noregisters;
    importer.forEach((item, index) => {
      let fields = {};
      if (index !== 0) {
        fields["first_name"] = item[0];
        fields["last_name"] = item[1];
        fields["email"] = item[2];
        fields["phone"] = item[3];
        fields["password"] = 'studentpasserty';
        fields["paid"] = true;
        fields["present"] = true;
        var fullname = fields["first_name"] + ' ' + fields["last_name"];
        // todo route api engine pour tester si le user existe déjà
        var config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
          },
          withCredentials: true
        }

        var payload = JSON.stringify({fields: fields});
        // console.log(payload)

        axios.post('https://network.andu.io/stageapi/register/', payload, config)
          .then(response => {
            if (response.data.status === 'success') {
              console.log('ok for ', fullname);
            } else {
              this.setState(prevState => ({
                noregisters: [...prevState.noregisters, fullname]
              }))
              console.log('no ok for ', fullname)
            }
          })
          .catch(error => {
            noregisters.push(fullname);
            console.log('no ok for ', fullname)
          })

        console.log(fields)

        datalength = datalength - 1;
        console.log(datalength)
        if (datalength === 1) {
          this.setState({
            importInProgress: false,
            successImportAlert: true
          })
          $('.close').click();
          this.handleShowAndHideAlert('successImport');
          console.log(this.state.noregisters.length)
          console.log(this.state.noregisters)
          if (this.state.noregisters.length !== undefined) {
            this.setState({
              showNoRegisterListName: true
            })
          }
          this.onActiveAllUser();
        }
      }
    })

  }

  onActiveAllUser = () => {
    axios.get(this.state.apibeng + 'activeall')
      .then(response => {

      })
      .catch(error => {

      })
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  }

  handleImportOffer = () => {
    this.fileInput.current.click();
  }

  hideAlert = () => {
    this.setState({
      showNoRegisterListName: false
    })
  }

  handleReadCSVForBakeliSiUser = (data) => {
    this.setState({
      importInProgress: true
    })
    //console.log(data.data)
    var datalength = data.data.length;
    // console.log(data);
    let importer = data.data;
    let noregisters = this.state.noregisters;

    importer.forEach((item, index) => {
      let fields = {};
      if (index !== 0) {
        fields["email"] = item[0];
        fields["fullname"] = item[1];
        fields["password"] = 'bakelipasserty';

        axios.post(this.state.apibsi + 'store-slack-bakeli-users', fields)
          .then(response => {
            if (response.data.status === true) {
              console.log('ok for ', fields["fullname"]);
            } else {
              // this.setState(prevState => ({
              //   noregisters: [...prevState.noregisters, fullname]
              // }))
              noregisters.push(fields["fullname"]);
              console.log('no ok for ', fields["fullname"])
            }
          })
          .catch(error => {
            noregisters.push(fields["fullname"]);
            console.log('no ok for ', fields["fullname"])
          })

        console.log(fields)

        datalength = datalength - 1;
        console.log(datalength)
        if (datalength === 1) {
          this.setState({
            importInProgress: false,
            successImportAlert: true
          })
          $('.close').click();
          this.handleShowAndHideAlert('successImport');
          console.log(this.state.noregisters.length)
          console.log(this.state.noregisters)
          if (this.state.noregisters.length !== null) {
            this.setState({
              showNoRegisterListName: true
            })
          }
        }
      }
    })
  }


  render() {
    return (
      <div className="component-import-csv">
        <Menu />
        <div className="col-lg-12">
          <h1 className="page-header">
            <span className="page-title"> Import de fichiers CSV </span>
            <div className="pull-right">
              <ol className="breadcrumb">
                <li>
                  <Link to="/dashboard-admin">Accueil</Link>
                </li>
                <li className="active">Import de fichiers CSV</li>
              </ol>
            </div>
          </h1>
        </div>

        {/* Content */}
        <div className="row content-row">
          {this.state.showNoRegisterListName &&
            <div className="row noregister-row">
              <div className="col-md-4 col-md-offset-4 noregister alert alert-danger"> 
              <button onClick={this.hideAlert} type="button" className="close close-alert" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <span> Ces utilisateurs n'ont pas été sauvegardé car ils ont déjà un compte sur Bakeli Engine : </span>
                <hr />
                {this.state.noregisters.map((item, index) =>
                  <span key={index}>
                    <span className="existing-user-item">
                      {item}
                    </span>, &nbsp;
                  </span>
                )}
              </div>
            </div>
          }

          <div className="col-md-4 import-item-panel-container">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">
                  <i className="fas fa-file-import"></i> &nbsp;
                  BAKELI ENGINE USER
                </h3>
              </div>
              <div className="panel-body">
                Importer un fichier CSV vers la table User de Bakeli Engine.
                <div className="alert alert-info alerty-info">
                  <span className="alerty-heading"> 
                    <i className="fas fa-exclamation-triangle"></i> &nbsp;
                    IMPORTANT :
                  </span>
                  <div className="alerty-content">
                    Vous devez mettre dans le fichier CSV les libellés des entêtes et ils doivent se suivre ainsi : Prénom, Nom de famille, Email et Téléphone.
                  </div>
                </div>
              </div>
              <div className="panel-footer" data-toggle="modal" data-target="#importCSVModal">
                <i className="fas fa-folder-open"></i> &nbsp;
                Ouvrir
              </div>
            </div>
          </div>

          <div className="col-md-4 import-item-panel-container">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">
                  <i className="fas fa-file-import"></i> &nbsp;
                  BAKELI SI USER
                </h3>
              </div>
              <div className="panel-body">
                Importer un fichier CSV vers la table User de Bakeli SI.
                <div className="alert alert-info alerty-info">
                  <span className="alerty-heading"> 
                    <i className="fas fa-exclamation-triangle"></i> &nbsp;
                    IMPORTANT :
                  </span>
                  <div className="alerty-content">
                    Vous devez mettre dans le fichier CSV les libellés des entêtes et ils doivent se suivre ainsi : Prénom, Nom de famille, Email et Téléphone.
                  </div>
                </div>
              </div>
              <div className="panel-footer" data-toggle="modal" data-target="#importCSVModalForBSIUser">
                <i className="fas fa-folder-open"></i> &nbsp;
                Ouvrir
              </div>
            </div>
          </div>

        </div>

        {/* Import CSV Modal Bakeli Engine  */}
        <div className="modal fade" id="importCSVModal" tabindex="-1" role="dialog" aria-labelledby="importCSVLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="importCSVLabel">Import de fichier CSV vers Bakeli Engine</h4>
              </div>
              <div className="modal-body">
                <div className="row import-input-row">
                  <div className="import-input col-md-8 col-md-offset-2">

                    <CSVReader
                      onFileLoaded={this.handleReadCSV}
                      inputRef={this.fileInput}
                      style={{ display: 'none' }}
                      onError={this.handleOnError}
                    />
                    <button onClick={this.handleImportOffer}>Chargez le CSV</button>

                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12 actions-col">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                  {this.state.importInProgress &&
                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                      Import en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Import CSV Modal  */}
        <div className="modal fade" id="importCSVModalForBSIUser" tabindex="-1" role="dialog" aria-labelledby="importCSVLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="importCSVLabel">Import de fichier CSV vers Bakeli SI User</h4>
              </div>
              <div className="modal-body">
                <div className="row import-input-row">
                  <div className="import-input col-md-8 col-md-offset-2">

                    <CSVReader
                      onFileLoaded={this.handleReadCSVForBakeliSiUser}
                      inputRef={this.fileInput}
                      style={{ display: 'none' }}
                      onError={this.handleOnError}
                    />
                    <button onClick={this.handleImportOffer}>Chargez le CSV</button>

                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12 actions-col">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                  {this.state.importInProgress &&
                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                      Import en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Existing User alert Modal  */}
        {/* <div className="modal fade" id="existingUserModal" tabindex="-1" role="dialog" aria-labelledby="existingUserModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="existingUserModalLabel">Utilisateurs avec un compte existant</h4>
              </div>
              <div className="modal-body">
                  <h3>Ces utilisateurs ont déjà un compte sur Bakeli Engine</h3>
                {this.state.noregisters.map((item, index) => 
                  <div key={index}>
                  <div className="existing-user-item">
                    { item }
                  </div> <hr />
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <div className="col-md-12 actions-col">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Successfull import toast */}
        {this.state.successImportAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
            Les données ont été importées avec succès.
        </div>
        }
      </div>
    );
  }
}
