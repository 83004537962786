import React, { Component } from 'react';
//import './syllabusContentManageView.css';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import API from '../../variablesGlobales';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import { MagicSpinner } from "react-spinners-kit";
import * as Sentry from '@sentry/browser';

export default class SyllabusContentBakelisteView extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem('token');
    let userStatus = window.sessionStorage.getItem('user_status');
    //let userID = window.sessionStorage.getItem('user_id');

    if (!token) {
      window.location = '/';
    }
    if (userStatus === 'coach') {
      window.history.back();
    }
    super(props);
    this.state = {
      api: API.API,
      isPageLoading: true,
      userID: window.sessionStorage.getItem('user_id'),
      syllabusDatas: [],
      syllabusInfos: [],
      addSyllabusContentInProgress: false,
      syllabusID: 0,
      errors: {},
      addSuccessDetailsAlert: false,
      addErrorDetailsAlert: false,
      sskills_empty: false,
      stask_empty: false,
      scompletiontime_empty: false,
      sweeknumber_empty: false,
      dsInfos: [],
      upInfos: [],
      sSkillValue: '',
      sTaskValue: '',
      sCompletionTimeValue: 0,
      sCompletionWeek: 0.0,
      sWeekNumber: 0,
      editSyllabusContentInProgress: false,
      editSuccessDetailsAlert: false,
      editErrorDetailsAlert: false,
      _fields: {},
      isPending: false,
      fields: {},
      detail_syllabus_id: 0,
      successValidateTache:false
    };
  this.addTacheHandleChange = this.addTacheHandleChange.bind(this);
    
   /*this.onSubmitDetailsSyllabus = this.onSubmitDetailsSyllabus.bind(this);

    this.editDetailsHandleChange = this.editDetailsHandleChange.bind(this);
    this.onSubmitEditDetailsSyllabus = this.onSubmitEditDetailsSyllabus.bind(this); */
  }

  componentDidMount = () => {
    const { match: { params } } = this.props;
    this.setState({
      syllabusID: params.syllabus_id,

    })
    this.onGetSyllabusInfos(params.syllabus_id);
    this.onGetDetailsBySyllabus(params.syllabus_id);
   

  }
 

  onGetSyllabusInfos = (syllabus_id) => {
    var baseApiUrl = this.state.api;

    axios.get(baseApiUrl + 'syllabus/' + syllabus_id)
      .then(response => {
        if (response.data.success) {
          //console.log(response.data.data)
          this.setState({
            syllabusInfos: response.data.data
          })
          console.log("hjgvr",this.state.syllabusInfos)
        }
      })
      .catch(error => {
        console.log(error.message)
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  onGetDetailsBySyllabus = (syllabus_id) => {
    this.setState({
      isPageLoading: true
    })

    var baseApiUrl = this.state.api;

    axios.get(baseApiUrl + 'syllabus-taches/' + syllabus_id)
      .then(response => {
        this.setState({
          isPageLoading: false
        })

        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            syllabusDatas: response.data.data
          })
        }
        console.log(this.state.syllabusDatas)
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isPageLoading: false
        })
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  onRowClick = (row) => {
    this.setState({
      dsInfos: row
    })
    $('.open-infos-modal').click();
  }

  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === 'addSyllabusContentInProgress') {
        this.setState({
          addSyllabusContentInProgress: false
        })
      }
      if (label === 'addSuccessDetailsAlert') {
        this.setState({
          addSuccessDetailsAlert: false
        })
      }
      if (label === 'addErrorDetailsAlert') {
        this.setState({
          addErrorDetailsAlert: false
        })
      }
      if (label === 'sskills_empty') {
        this.setState({
          sskills_empty: false
        })
      }
      if (label === 'stask_empty') {
        this.setState({
          stask_empty: false
        })
      }
      if (label === 'scompletiontime_empty') {
        this.setState({
          scompletiontime_empty: false
        })
      }
      if (label === 'sweeknumber_empty') {
        this.setState({
          sweeknumber_empty: false
        })
      }
      if (label === 'editSuccessDetailsAlert') {
        this.setState({
          editSuccessDetailsAlert: false
        })
      }
      if (label === 'editErrorDetailsAlert') {
        this.setState({
          editErrorDetailsAlert: false
        })
      }
    }, 5000)
  }

  weekNumberFormatter = (cell, row) => {
    return (
      <span className="semaine_number_container"> Semaine {cell} </span>
    )
  }

  completionWeekFormatter = (cell, row) => {
    return (
      <span className="semaine_completion_container"> {cell + ' semaine(s)'} </span>
    )
  }

  getDetailsInfos = (item) => {

    this.setState({
      upInfos: item,
      dsInfos: item,
      sSkillValue: item.syllabus_skills,
      sTaskValue: item.syllabus_task,
      sCompletionTimeValue: item.syllabus_completion_time,
      sCompletionWeek: item.syllabus_completion_week,
      sWeekNumber: item.syllabus_week_number,
     /*  detail_syllabus_id: item.id, */
    })
  }
  addTacheHandleChange = (item) => {
     
    let bakeliste_id = window.sessionStorage.getItem('user_id');
    let fields = this.state.fields;
    fields["bakeliste_id"] = bakeliste_id;
    fields["syllabus_id"]= this.state.syllabusInfos.id;
    fields["detail_syllabus_id"]= item.id;
    fields["coach_id"]= window.sessionStorage.getItem('coach_id');
    fields["task_status"]= 'en cours';
    fields["skills_is_acquired"]= 'non ok';
     this.setState({
       fields:fields
       },console.log(fields))
     

       var data = this.state.fields;
       var baseApiUrl = this.state.api;
 
       axios.post(baseApiUrl+ 'task-executions' , data)
      .then(response => {
        this.setState({
          isPending: true
        });

        if (response.data.success === true) {
          this.setState({
            successValidateTache: true,

          });
        } else {
          console.log('Erreur lors de la modification')
        }
      })

   }
  actionsFormatter = (cell, row) => {
    return(
      <div className="actions-btn-container">
        <button data-toggle="modal" data-target="#detailsInfosModal" className="action-btn-item show_icon" onClick={(e) => this.getDetailsInfos(row)}>
          <img src="https://img.icons8.com/material-rounded/20/FFFFFF/visible.png" className="actions_icon" alt="Visualiser" title="Visualiser"/>
        </button> 
           {/* task execution modal */}
        <button data-toggle="modal" data-target="#taskExecutionModal" className="action-btn-item edit_icon"  onClick={(e) => this.addTacheHandleChange(row)}>
        <img src="https://img.icons8.com/ios-glyphs/20/FFFFFF/ok.png" className="actions_icon" alt="Choisir la tache" title="Choisir la tache"/>
        </button>
      </div>
    )
  }


  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
      noDataText: 'Aucune donnée pour le moment sur ce syllabus'
    };
   /*  const { bakeliste_id, syllabus_id, detail_syllabus_id, task_status, skills_is_acquired } = this.state;
    const values = { bakeliste_id, syllabus_id, detail_syllabus_id, task_status, skills_is_acquired };
 */
    if (this.state.isPageLoading) {
      return (
        <div className="component-syllabus-content-manage-view">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Syllabus : {this.state.syllabusInfos.syllabus_name}
              </h1>
              <ol className="breadcrumb">
                <li><NavLink to="/dashboard-bakeliste"> Accueil</NavLink></li>
                <li><NavLink to={"/syllabus-bakeliste/" +this.state.userID}> Syllabus Manager</NavLink></li>
                <li className="active">Syllabus : {this.state.syllabusInfos.syllabus_name} </li>
              </ol>
            </section>

            <section className="content">
              <div className="loader-row row">
                <div className="col-md-4 col-md-offset-4 loader-container">
                  <MagicSpinner size={150} color="#009688" loading={this.state.isPageLoading} />
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }

    return (
      <div className="component-syllabus-content-manage-view">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>
              Syllabus : {this.state.syllabusInfos.syllabus_name}
            </h1>
            <ol className="breadcrumb">
              <li><NavLink to="/dashboard-bakeliste"> Accueil</NavLink></li>
              <li><NavLink to={"/syllabus-bakeliste/" +this.state.userID}> Syllabus Bakeliste</NavLink></li>
              <li className="active">Syllabus : {this.state.syllabusInfos.syllabus_name} </li>
            </ol>
          </section>

          <section className="content">
            <div className="row scm_row">
              <div className="col-md-12 scm_table_container">
                <button className="export_pdf_btn btn btn-success bg-vgreen">
                  <img className="pdf_icon_img" src="https://img.icons8.com/material-rounded/14/FFFFFF/pdf.png" alt="PDF ICON" />
                  Export to PDF
                    </button>
                <BootstrapTable data={this.state.syllabusDatas}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  pagination
                  exportCSV>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='content'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Contenues</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='duration'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Durée</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='tache_url'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Url</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          </section>
        </div>


        <div className="modal fade" id="detailsInfosModal" tabIndex="-1" role="dialog" aria-labelledby="ratingInfosModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="ratingInfosModalLabel">Détails de la tâche</h4>
              </div>
              <div className="modal-body">
                <div className="row modal-row">
                  <div className="col-md-12 rating-item">
                    <div className="row ds-row">
                      <div className="col-md-12 item_ds_bloc">
                        <div className="item-ds-title"> Compétences à acquérir </div>
                        <div className="item-ds-infos"> {this.state.dsInfos.syllabus_skills} </div>
                      </div>
                      <div className="col-md-12 item_ds_bloc">
                        <div className="item-ds-title"> Tâche </div>
                        <div className="item-ds-infos"> {this.state.dsInfos.syllabus_task} </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial"> Délais en Heure </div>
                        <div className="item-ds-infos partial"> {this.state.dsInfos.syllabus_completion_time + ' heure(s)'} </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial"> Délais en Semaine </div>
                        <div className="item-ds-infos partial"> {this.state.dsInfos.syllabus_completion_week + ' semaine(s)'} </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial"> N° de la semaine </div>
                        <div className="item-ds-infos partial"> {this.state.dsInfos.syllabus_week_number} </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        
       

        {/* task execution */}
      <br/>
       <div className="modal fade" id="taskExecutionModal" tabIndex="-1" role="alert" aria-labelledby="taskExecutionModalLabel">
          <div className="modal-dialog" role="alert">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <br/>
                {this.state.isPending &&
                      <div class="alert aler" role="alert">
                      <h4 class="alert-heading">Bravo bon courage! </h4>
                      <p>Votre tâche a été bien enregistre. Nous vous souhaitons une bonne expérience  </p>
                      <p>Veuillez finir la tâche dans le délai défin</p>
                    </div>
                    }      
              </div>

                             
              </div>     
           </div>
       </div>
        {/* end  task execution */}
      </div>
    );
  }
}
