import React, { Component } from 'react';
import './gestionCandidatureSpontanee.css';
import Menu from '../menu/Menu';
import axios from 'axios';
import { Link } from 'react-router-dom';
import API from '../../variablesGlobales';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';

function linkFormatter(cell, row) {
  return (
    <span>
      <a href={cell} alt="CV Link" target="_blank" rel="noopener noreferrer" className="cv_link text-center"><i className="fas fa-file"></i></a>
    </span>
  );
}

export default class gestionCandidatureSpontanee extends Component {
  constructor(props) {
    super(props);

    let token = window.sessionStorage.getItem('token');
    let userStatus = window.sessionStorage.getItem('user_status');

    if (!token) {
      window.history.back()
    }
    if (userStatus === 'bakeliste' || userStatus === 'coach') {
      window.history.back();
    }

    this.getSpontaneousInfos = this.getSpontaneousInfos.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendConvocationToCandidate = this.sendConvocationToCandidate.bind(this);

    this.state = {
      api: API.API,
      isPageLoading: false,
      spontaneousApplication: [],
      isRequestError: false,
      spontaneousInfos: [],
      fields: {},
      isSendingEmail: false,
      firstNameValue: '',
      lastNameValue: '',
      emailValue: '',
      phoneValue: '',
      candidate_id: 0,
      archived_inProgress: false,
      archivedRequestError: false,
      archivedSuccessAlert: false,
      spinThis: false,

    };
  }

  componentDidMount() {
    this.getSpontaneousApplication();
  }

  getSpontaneousApplication = () => {
    this.setState({
      isPageLoading: true
    });

    axios.get(this.state.api + 'spontaneous-applications')
      .then(response => {
        let sapp = response.data.data;
        //let volkenoStages = response.data.dataVolkenoStage;
        if (response.data.success === true) {
          this.setState({
            spontaneousApplication: sapp,
            volkenoStages: response.data.dataVolkenoStage
          })
        } else {
          this.setState({
            isRequestError: true
          })
        }
      })
  }

  handleShowAndHideAlert(label){
    setTimeout(() => {
        if(label === 'successArchivedCandidate'){
            this.setState({
                archivedSuccessAlert: false
            });
        }
        if(label === 'errorArchivedCandidate'){
            this.setState({
                archivedRequestError: false
            })
        }
        if(label === 'convocationSuccessAlert'){
          this.setState({
            convocationSuccessAlert: false
          })
        }
        if(label === 'convocationErrorAlert'){
          this.setState({
            convocationErrorAlert: false
          })
        }
    }, 5000);
  }

  getSpontaneousInfos = (sapp) => {
    this.setState({
      spontaneousInfos: sapp,
      firstNameValue: sapp.first_name,
      lastNameValue: sapp.last_name,
      emailValue: sapp.email,
      phoneValue: sapp.phone,
      candidate_id: sapp.id
    })

  }

  handleArchivedCandidate = (candidate, label) => {
    this.setState({
      archived_inProgress: true,
      spinThis: true
    });

    let spontaneousID = candidate.id;
    let data = {};
    data["label"] = label;

    axios.post(this.state.api + 'archived-spontaneous-application/' + spontaneousID, data)
      .then(response => {
        if(response.data.success === true){
          this.getSpontaneousApplication();

          this.setState({
            archived_inProgress: false,
            archivedSuccessAlert: true,
            spinThis: false
          })
          this.handleShowAndHideAlert('successArchivedCandidate');
        }else{
          console.log(response);
          this.setState({
            archived_inProgress: false,
            archivedRequestError: true,
            spinThis: false
          })
          this.handleShowAndHideAlert('errorArchivedCandidate');
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          archived_inProgress: false,
          archivedRequestError: true,
          ['spinThis' + (candidate.id)]: false
        })
        this.handleShowAndHideAlert('errorArchivedCandidate');
      })
  }

  actionsFormatterBakeli(cell, row) {
    return (
      <span>
        <button onClick={() => { this.getSpontaneousInfos(row) }} id="show_item" className="btn btn-info show-btn volkeno-green-bcolor" data-toggle="modal" data-target="#showSAModal">
          <i className="fas fa-eye"></i>
        </button>
        &nbsp;&nbsp;
        <button onClick={() => { this.getSpontaneousInfos(row) }} id="sending_item" className="btn btn-default send-msg-btn volkeno-orange-bcolor" data-toggle="modal" data-target="#sendMsgModal">
          <i className="fas fa-at"></i>
        </button>
        &nbsp;&nbsp;
        {this.state.spinThis === false ?
          <button data-toggle="tooltip" data-placement="top" title="Archiver" type="button" className="btn btn-danger" id="archived_btn"
            onClick={e => window.confirm("Êtes-vous sûr de vouloir archiver cette candidature ?") &&
            this.handleArchivedCandidate(row, 'bakeli')}>
              <i className="fas fa-archive"></i>
          </button> :
          <button data-toggle="tooltip" data-placement="top" title="Archiver" type="button" className="btn btn-danger" id="archived_btn">
              <i className="fas fa-spin fa-spinner"></i>
          </button>
        }
      </span>
    );
  }

  actionsFormatterVolkeno(cell, row) {
    return (
      <span>
        <button onClick={() => { this.getSpontaneousInfos(row) }} id="show_item" className="btn btn-info show-btn volkeno-green-bcolor" data-toggle="modal" data-target="#showSAModal">
          <i className="fas fa-eye"></i>
        </button>
        &nbsp;&nbsp;
        <button onClick={() => { this.getSpontaneousInfos(row) }} id="sending_item" className="btn btn-default send-msg-btn volkeno-orange-bcolor" data-toggle="modal" data-target="#sendMsgModal">
          <i className="fas fa-at"></i>
        </button>
        &nbsp;&nbsp;
        {this.state.spinThis === false ?
          <button data-toggle="tooltip" data-placement="top" title="Archiver" type="button" className="btn btn-danger" id="archived_btn"
            onClick={e => window.confirm("Êtes-vous sûr de vouloir archiver cette candidature ?") &&
            this.handleArchivedCandidate(row, 'volkeno')}>
              <i className="fas fa-archive"></i>
          </button> :
          <button data-toggle="tooltip" data-placement="top" title="Archiver" type="button" className="btn btn-danger" id="archived_btn">
              <i className="fas fa-spin fa-spinner"></i>
          </button>
        }
      </span>
    );
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === 'first_name') {
      this.setState({
        firstNameValue: e.target.value
      })
      fields["first_name"] = this.state.firstNameValue;
    }
    if (e.target.name === 'last_name') {
      this.setState({
        lastNameValue: e.target.value
      })
      fields["last_name"] = this.state.lastNameValue;
    }
    if (e.target.name === 'email') {
      this.setState({
        emailValue: e.target.value
      })
      fields["email"] = this.state.emailValue;
    }
    if (e.target.name === 'phone') {
      this.setState({
        phoneValue: e.target.value
      })
      fields["phone"] = this.state.phoneValue;
    }

    this.setState({
      fields: fields
    })

    console.log('input data', fields)
  }

  sendConvocationToCandidate = (e) => {
    e.preventDefault();

    this.setState({
      isSendingEmail: true
    });

    const msgData = this.state.fields;
    msgData["first_name"] = this.state.firstNameValue;
    msgData["last_name"] = this.state.lastNameValue;
    msgData["email"] = this.state.emailValue;
    msgData["phone"] = this.state.phoneValue;
    msgData["candidate_id"] = this.state.candidate_id;
    console.log(msgData)

    axios.post(this.state.api + 'sending-convocation', msgData)
      .then(response => {
        if(response.data.success === true){
          //let fb = response.data;
          this.getSpontaneousApplication();
          $('.close').click();
          document.getElementById('sending-convocation-form').reset();
          this.setState({
            isSendingEmail: false,
            convocationSuccessAlert: true
          })
          this.handleShowAndHideAlert('convocationSuccessAlert')
        }else{
          this.setState({
            isSendingEmail: false,
            convocationErrorAlert: true
          })
          this.handleShowAndHideAlert('convocationErrorAlert')
        }
      })
      .catch(error => {
        this.setState({
          isSendingEmail: false,
          convocationErrorAlert: true
        })
        this.handleShowAndHideAlert('convocationErrorAlert')
        console.log(error)
      })
  }

  conveneFormatter(cell, row) {
    let htmlReturn;
    if (row.is_convene === 1) {
      htmlReturn = <span className="label label-success">Déjà convoqué</span>
    } else {
      htmlReturn = <span className="label label-danger">A convoquer</span>
    }
    return (
      htmlReturn
    );
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }


  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        { props.exportCSVBtn }
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };
    return (
      <div className="component-gestion-candidature-spontanee row">
        <Menu />
        <div className="col-lg-12">
          <h1 className="page-header">
            <span className="page-title">Candidature Spontanée</span>
            <div className="pull-right">
              <ol className="breadcrumb">
                <li>
                  <Link to="/dashboard-admin">Accueil</Link>
                </li>
                <li className="active">Candidature Spontanée</li>
              </ol>
            </div>
          </h1>
        </div>

        <div className="spontaneous_application_list_container">
          <div className="col-md-12" id="item_content">
            <div className="nav-tabs-custom">
                <div className="col-md-12 da-list-col">
                  <ul className="nav nav-tabs">
                    <li className="active"><NavLink to="#volkeno-stage" data-toggle="tab" aria-expanded="true">Stage Volkeno</NavLink></li>
                    <li className=""><NavLink to="#bakeli-stage" data-toggle="tab" aria-expanded="false">Archive stage Bakeli</NavLink></li>
                  </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane active" id="volkeno-stage">
                      <div className="vs-table-row">
                        <div className="col-md-12 vs-table-col">
                          <BootstrapTable data={this.state.volkenoStages}
                            striped={true} hover={true} condensed={true} exportCSV
                            multiColumnSort={2} options={options}
                            pagination
                            search={true}>
                            <TableHeaderColumn dataField='id' width="200" dataFormat={this.actionsFormatterVolkeno.bind(this)}>Actions</TableHeaderColumn>
                            <TableHeaderColumn dataField='is_convene' width='150' dataFormat={this.conveneFormatter} dataSort={true}>Statut</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='first_name' width='250'>Prénom</TableHeaderColumn>
                            <TableHeaderColumn dataField='last_name' width='250'>Nom</TableHeaderColumn>
                            <TableHeaderColumn dataField='email'  width='350' dataSort={true}>Email</TableHeaderColumn>
                            <TableHeaderColumn dataField='phone' width='250' dataSort={true}>Téléphone</TableHeaderColumn>
                            <TableHeaderColumn dataField='ville_residence' width='150' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Ville de résidence</TableHeaderColumn>
                            <TableHeaderColumn dataField='cv_url' width="100" dataFormat={linkFormatter} dataSort={true}>CV</TableHeaderColumn>
                            {/* <TableHeaderColumn dataField='motivation' dataSort={true}>Motivation</TableHeaderColumn> */}
                            
                          </BootstrapTable>
                        </div>
                      </div> 
                    </div>  

                    <div className="tab-pane" id="bakeli-stage">
                      <div className="vs-table-row">
                        <div className="col-md-12 vs-table-col">
                          <BootstrapTable data={this.state.spontaneousApplication}
                            striped={true} hover={true} condensed={true} exportCSV
                            multiColumnSort={2} options={options}
                            pagination
                            search={true}>
                            <TableHeaderColumn dataField='id' width="200" dataFormat={this.actionsFormatterVolkeno.bind(this)}>Actions</TableHeaderColumn>
                            <TableHeaderColumn dataField='is_convene' width='150' dataFormat={this.conveneFormatter} dataSort={true}>Statut</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='first_name' width='250'>Prénom</TableHeaderColumn>
                            <TableHeaderColumn dataField='last_name' width='250'>Nom</TableHeaderColumn>
                            <TableHeaderColumn dataField='email'  width='350' dataSort={true}>Email</TableHeaderColumn>
                            <TableHeaderColumn dataField='phone' width='250' dataSort={true}>Téléphone</TableHeaderColumn>
                            <TableHeaderColumn dataField='ville_residence' width='150' filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}>Ville de résidence</TableHeaderColumn>
                            <TableHeaderColumn dataField='cv_url' width="100" dataFormat={linkFormatter} dataSort={true}>CV</TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div> 
                    </div>        
                </div>
              </div>
          </div>

          {/* Show Spontaneous Application item */}
          <div className="modal fade" id="showSAModal" tabIndex="-1" role="dialog" aria-labelledby="showSAModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="showSAModalLabel">Detail sur {this.state.spontaneousInfos.first_name}&nbsp;{this.state.spontaneousInfos.last_name}</h4>
                </div>
                <div className="modal-body">
                  <div className="is_convene_return">
                    {this.state.spontaneousInfos.is_convene === 1 ?
                      <div className="alert alert-success">
                        <div className="text-center convene_text">
                          Ce candidat a été déjà convoqué
                        </div>
                      </div> :
                      <div className="alert alert-danger">
                        <div className="text-center convene_text">
                          Ce candidat n'est pas encore convoqué
                    </div>
                      </div>
                    }
                  </div>

                  <div className="row created_date_row">
                    <div className="col-md-8 col-md-offset-2 created_date_content text-center">
                      <span className="created_at_label"> Date de candidature : </span> <br />
                      <span className="created_at_item"> {this.state.spontaneousInfos.frenchCreatedAtDateFormat} </span>
                    </div>
                  </div> <hr />

                  <div className="fullname_container">
                    <span className="item_label"><b>Prénom et Nom :</b> </span> <span className="label_content">{this.state.spontaneousInfos.first_name}&nbsp;{this.state.spontaneousInfos.last_name}</span>
                  </div> <hr />
                  <div className="email_container">
                    <span className="item_label"><b>Email : </b></span> <span className="label_content">{this.state.spontaneousInfos.email}</span>
                  </div> <hr />
                  <div className="phonecontainer">
                    <span className="item_label"><b>Téléphone :</b> </span> <span className="label_content">{this.state.spontaneousInfos.phone}</span>
                  </div> <hr />
                  <div className="ville_container">
                    <span className="item_label"><b>Ville de résidence :</b> </span> <span className="label_content">{this.state.spontaneousInfos.email}</span>
                  </div> <hr />
                  <div className="motivation_container">
                    <span className="item_label"><b>Motivations :</b> </span> <br />
                    {/* <span className="label_content">{this.state.spontaneousInfos.motivation}</span> */}
                    <span className="label_content" dangerouslySetInnerHTML={{__html: this.state.spontaneousInfos.motivation}}></span>
                  </div> <hr />
                  <div className="row cv_download">
                    <div className="cv_container col-md-12">
                      <a href={this.state.spontaneousInfos.cv_url} alt="CV" className="cv_link_inModal" target="_blank" rel="noopener noreferrer">
                        <button className="volkeno-green-bcolor btn btn-default">
                          <span className="item_label"><b>CV à Télécharger</b> </span> <br />
                          <i className="fas fa-eye"></i>
                        </button>
                      </a>
                    </div> <hr />
                  </div>

                  <div className="modal-footer">

                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Send email to spontaneous candidate */}
          <div className="modal fade" id="sendMsgModal" tabIndex="-1" role="dialog" aria-labelledby="sendMsgModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="sendMsgModalLabel">Detail sur {this.state.spontaneousInfos.first_name}&nbsp;{this.state.spontaneousInfos.last_name}</h4>
                </div>
                <div className="modal-body">
                  <div className="convocation_form_container">
                    <form onSubmit={this.sendConvocationToCandidate} id="sending-convocation-form">
                      <div className="form-row">
                        <div>
                          <h1 id="form_title">Envoyer un mail de convocation</h1>
                        </div> <hr />
                        <div className="row form-row-item">
                          <div className="col-md-6 form-item">
                            <div className="form-group ">
                              <label htmlFor="first_name">Prenom :</label>
                              <input type="text" className="form-control" id="first_name" placeholder="Prénom" name="first_name" value={this.state.firstNameValue} onChange={this.handleChange} />
                            </div>
                          </div>
                          <div className="col-md-6 form-item">
                            <div className="form-group ">
                              <label for="first_name">Nom :</label>
                              <input type="text" className="form-control" id="last_name" placeholder="Nom" name="last_name" value={this.state.lastNameValue} onChange={this.handleChange} />
                            </div>
                          </div>
                        </div>
                        <div className="row form-row-item">
                          <div className="col-md-6 form-item">
                            <div className="form-group ">
                              <label for="first_name">Email :</label>
                              <input type="text" className="form-control" id="email" placeholder="Email" name="email" value={this.state.emailValue} onChange={this.handleChange} />
                            </div>
                          </div>
                          <div className="col-md-6 form-item">
                            <div className="form-group ">
                              <label for="first_name">Nom :</label>
                              <input type="text" className="form-control" id="phone" placeholder="Téléphone" name="phone" value={this.state.phoneValue} onChange={this.handleChange} />
                            </div>
                          </div>
                        </div>
                        <div className="row form-row-item">
                          <div className="col-md-6 form-item">
                            <div className="form-group ">
                              <label for="first_name">Ville de convocation :</label>
                              <input type="text" className="form-control" id="ville_convocation" placeholder="Ville de convocation" name="ville_convocation" onChange={this.handleChange} />
                            </div>
                          </div>
                          <div className="col-md-6 form-item">
                            <div className="form-group ">
                              <label for="first_name">Adresse de convocation :</label>
                              <input type="text" className="form-control" id="adresse_convocation" placeholder="Adresse de convocation" name="adresse_convocation" onChange={this.handleChange} />
                            </div>
                          </div>
                        </div>
                        <div className="row form-row-item">
                          <div className="col-md-6 form-item">
                            <div className="form-group ">
                              <label for="first_name">Date de convocation :</label>
                              <input type="date" className="form-control" id="date_convocation" placeholder="Date de la convocation" name="date_convocation" onChange={this.handleChange} />
                            </div>
                          </div>
                          <div className="col-md-6 form-item">
                            <div className="form-group ">
                              <label for="first_name">Heure de convocation :</label>
                              <input type="text" className="form-control" id="heure_convocation" placeholder="Heure de convocation" name="heure_convocation" onChange={this.handleChange} />
                            </div>
                          </div>

                          {this.state.convocationErrorAlert === true &&
                            <div className="col-md-12 form-item">
                              <div className="alert alert-danger convocation-alert-mesg">
                                Un problème lors de l'envoie de la requête. Veuillez réessayer plus tard.
                              </div>
                            </div>
                          }
                        </div>

                        <div className="row form-actions-button">
                          {this.state.isSendingEmail === false ?
                            <button type="submit" className='btn btn-warning addBtn button submit'>
                              <i className="fas fa-plus-circle"></i> &nbsp;
                              Envoyer la convocation
                            </button> :
                            <button type="submit" className='btn btn-warning addBtn button submit'>
                              Envoie en cours  &nbsp;
                                <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          }
                        </div>

                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* Successfull archived toast */}
        {this.state.archivedSuccessAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              Candidature archivée avec succès.
          </div>
        }

        {/* Error archived toast alert */}
        {this.state.archivedRequestError === true &&
            <div className="alert alert-danger text-center toast-error-alert">
                <i className="fas fa-exclamation-triangle"></i> &nbsp;&nbsp;
                Un problème est survenu. Réessayer plus tard.
            </div>
        }

        {/* Successfull convocation toast */}
        {this.state.convocationSuccessAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              Convocation envoyée avec succès.
          </div>
        }

      </div>
    )
  }
}
