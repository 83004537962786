/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "./Menu.css";
import "../adminHomeDashboard/adminHomeDashboard.css";
import { NavLink, Link } from "react-router-dom";
import API from "../../variablesGlobales";
import axios from "axios";
import Echo from "laravel-echo";

//import { Redirect } from 'react-router';
import $ from "jquery";
import * as Sentry from "@sentry/browser";
//import jQuery from 'jquery';
import ReactModal from "react-modal";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
import { Icon, List, Dropdown, Image } from "semantic-ui-react";
import TimeAgo from "timeago-react";
import { Amplitude } from "@amplitude/react-amplitude";

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFullName: window.sessionStorage.getItem("userFullName"),
      token: window.sessionStorage.getItem("token"),
      userStatus: window.sessionStorage.getItem("user_status"),
      userID: window.sessionStorage.getItem("user_id"),
      api: API.API,
      listCoach: [],
      bakelisteCoachInfos: [],
      coachFullname: window.sessionStorage.getItem("coach_fullname"),
      coachEmail: window.sessionStorage.getItem("coach_email"),
      coachPhone: window.sessionStorage.getItem("coach_phone"),
      coachID: window.sessionStorage.getItem("coach_id"),
      showModal: false,
      showModal2: false,
      showModal3: false,
      fields: {},
      searchInProgress: false,
      searchRequestError: false,
      searchRequestSuccess: false,
      findBakelistes: [],
      emptyBakelisteData: false,
      findBakelistesCount: 0,
      bakeliste: [],
      messages: [],
      historiques: [],
      key: "",
      loading: true,
      MessageNotificationBakelist: "",
      nbMessage: "",
      nbHistoriques: "",
      nombreNotificationAdmin: 1,
      nombreNotificationCoach: 1,
      nombreNotificationBakelist: 0,
      hideNotification: true,
      isreadMessage: 1,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModalInfos = this.handleOpenModalInfos.bind(this);
    this.handleCloseModalInfos = this.handleCloseModalInfos.bind(this);
    this.handleOpenModalError = this.handleOpenModalError.bind(this);
    this.handleCloseModalError = this.handleCloseModalError.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.isReadMessage = this.isReadMessage.bind(this);
    this.historiqueisRead = this.historiqueisRead.bind(this);
    this.dropMenu = this.dropMenu.bind(this);
  }

  componentDidMount() {
    this.getListAllMessage();
    this.getNoteRead();
    this.dropMenu();

    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "167714ba60a47c2185ae",
      cluster: "ap1",
      forceTLS: true,
    });
    var channel = window.Echo.channel("BakelistChannel");
    channel.listen(
      ".AssignationTache",
      function (data) {
        this.setState({ MessageNotificationBakelist: data.message });
      }.bind(this)
    );

    var as = {};

    as.toggleSidebar = function () {
      if (window.innerWidth > 767) {
        $("body").toggleClass("sidebar-collapse");
      } else {
        $("body").toggleClass("sidebar-open");
      }

      return false;
    };
    as.init = function () {
      $("#sidebar-toggle").click(as.toggleSidebar);
    };

    $(document).ready(as.init);
  }

  // get all message
  getListAllMessage() {
    axios
      .get(this.state.api + "messages-non-lu")
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            messages: res.data.data.reverse(),
            nbMessage: res.data.data.length,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  }

  // get history note readf
  getNoteRead() {
    axios
      .get(this.state.api + "history-note-read")
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            historiques: res.data.data.reverse(),
            nbHistoriques: res.data.data.length,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  }

  isReadMessage = (e, id) => {
    var data = { isread: 1 };
    console.log(data);
    axios
      .post(this.state.api + "isread-message/" + id, data)
      .then((response) => {
        if (response.data.success === true) {
          this.getListAllMessage();
          //window.location.reload(false);
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  historiqueisRead = (e, id) => {
    var data = { isread: 1 };
    console.log(data);
    axios
      .post(this.state.api + "update-history-isread/" + id, data)
      .then((response) => {
        if (response.data.success === true) {
          this.getNoteRead();
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  handlecliskiconeAdmin() {
    this.setState({ nombreNotificationAdmin: 0 });
  }
  handleOpenModal() {
    this.setState({ showModal: true, showModal2: false });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleOpenModalInfos() {
    this.setState({ showModal2: true, showModal: false });
  }

  handleCloseModalInfos() {
    this.setState({ showModal2: false, showModal: true });
  }

  handleOpenModalError() {
    this.setState({ showModal3: true });
  }

  handleCloseModalError() {
    this.setState({ showModal3: false });
  }

  doLogout = () => {
    window.sessionStorage.removeItem("token");
    axios.get(this.state.api + "user-logout").then((response) => {
      const result = response.data;
      console.log(result);
      if (result.success === true) {
        window.location = "/";
      }
    });
  };

  handleSearchChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
      key: e.target.value,
    });
    console.log(fields);
  };

  handleSubmitSearch = (e) => {
    this.setState({
      searchInProgress: true,
    });

    var data = this.state.fields;
    var baseApiUrl = this.state.api;

    axios
      .post(baseApiUrl + "search-bakeliste", data)
      .then((response) => {
        this.setState({
          searchInProgress: false,
        });

        if (response.data.success) {
          this.setState({
            findBakelistes: response.data.data,
            findBakelistesCount: response.data.count,
            searchRequestSuccess: true,
          });
          this.handleOpenModal();
        } else if (response.data.message === "empty") {
          console.log(response);
          this.setState({
            emptyBakelisteData: true,
          });
          this.handleOpenModalError();
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          searchRequestError: true,
          searchInProgress: false,
        });
        this.handleOpenModalError();
      });
  };

  getBakelisteInfo = (bakeliste) => {
    this.setState({
      bakeliste: bakeliste,
    });
    this.handleOpenModalInfos();
  };

  bakelisteFullnameFormatter = (cell, row) => {
    return (
      <span
        className="fullname-link"
        onClick={(e) => this.getBakelisteInfo(row, e)}
      >
        {cell}
      </span>
    );
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  coachRowFormatter = (cell, row) => {
    return (
      <div className="coach_row actions-btn-container">
        {row.coach_id ? <Link to="#">{cell}</Link> : <i> Non assigné </i>}
      </div>
    );
  };

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeclassName="btn-group-md">
        {props.exportCSVBtn}
        {/* <button type='button'
              className={ `btn btn-primary volkeno-red-bcolor` }>
              MyCustomBtn
            </button> */}
      </ButtonGroup>
    );
  };

  typeFormationFormatter = (cell, row) => {
    if (cell === "courte") {
      return (
        <span className="label label-success volkeno-green-bcolor">
          {" "}
          formation courte{" "}
        </span>
      );
    } else if (cell === "diplomante") {
      return (
        <span className="label label-warning volkeno-orange-bcolor">
          {" "}
          formation diplômante{" "}
        </span>
      );
    }
  };

  handlecliskiconeAdmin = () => {
    this.setState({
      nombreNotificationAdmin: 0,
    });
  };
  handlecliskiconeCoach = () => {
    this.setState({
      nombreNotificationCoach: 0,
    });
  };
  handlecliskiconeBakelist = () => {
    this.setState({
      nombreNotificationBakelist: 0,
    });
  };
  dropMenu = () => {
    var dropdown = document.getElementsByClassName("dropdown-btn");
    var i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  };
  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
    };
    const appointment = (
      <span>
        <i className="far fa-bell" id="icon-mobile"></i>
      </span>
    );
    const comments = (
      <span>
        <i className="far fa-comment-alt" id="icon-mobile"></i>
      </span>
    );

    const historics = (
      <span>
        <Image
          src={require("./../../images/history-black.svg")}
          alt="Avatar"
          id="icon-mobile"
        />
      </span>
    );

    const user_avatar = (
      <span className="user-mobile-avatar">
        <i
          className="far fa-user-circle"
          style={{ fontSize: "25px", color: "#383131" }}
          id="user_avatar-mobile"
        ></i>
        <h6 id="user-avatar-fullname">{this.state.userFullName} </h6>
      </span>
    );

    return (
      <div className="component-Menu">
        <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-header">
              <NavLink
                className="navbar-brand"
                to="#"
                style={{ padding: "7px 0 0 0" }}
              >
                <img
                  src="/images/logo_bakeli.png"
                  alt="Logo Bakeli"
                  className="logo_img"
                />
              </NavLink>
            </div>

            <div id="navbar" className="navbar-collapse">
              <NavLink to="#" id="sidebar-toggle" className="btn">
                <i className="navbar-icon fas fa-bars icon"></i>
              </NavLink>

              <ul className="nav navbar-nav navbar-right">
                <List horizontal relaxed="very" id="collapseMobile-list">
                  {/* navbar-right admin */}{" "}
                  {this.state.userStatus === "admin" && (
                    <List.Item>
                      <div
                        className="ui large icon input"
                        id="search-bakeliste"
                      >
                        <input
                          type="text"
                          name="search_term"
                          onChange={this.handleSearchChange}
                          placeholder="Chercher un bakeliste"
                          required
                          id="input-search-bakeliste"
                        />
                        <i
                          aria-hidden="true"
                          className="search circular teal inverted link icon"
                          onClick={this.handleSubmitSearch}
                          id="icon-search-bakeliste"
                        ></i>
                      </div>
                    </List.Item>
                  )}
                  {this.state.userStatus === "admin" && (
                    <List.Item>
                      {/*  {this.state.MessageNotificationBakelist.length > 0 && */}
                      <span
                        className="badge badge-pill badge-danger"
                        id="label-notification"
                      >
                        {this.state.nombreNotificationAdmin}
                      </span>
                      {/*   } */}
                      <Dropdown
                        pointing="top left"
                        className="link item"
                        trigger={appointment}
                        icon={null}
                        onClick={this.handlecliskiconeBakelist}
                      >
                        <Dropdown.Menu id="item-dropdown-bell">
                          {/*  {this.state.MessageNotificationBakelist === ''? */}
                          <Dropdown.Header id="no-data-to-display">
                            Il n'y a aucune donnée à afficher
                          </Dropdown.Header>
                          {/*  :
                                            <Dropdown.Header><NavLink to="/gestion-tache">{this.state.MessageNotificationBakelist}</NavLink></Dropdown.Header>
                                            } */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </List.Item>
                  )}
                  {this.state.userStatus === "admin" && (
                    <List.Item>
                      {this.state.nbMessage !== 0 && (
                        <span
                          className="badge badge-pill badge-danger"
                          id="label-notification"
                        >
                          {this.state.nbMessage}
                        </span>
                      )}
                      <Dropdown
                        pointing
                        className="link item"
                        trigger={comments}
                        icon={null}
                      >
                        <Dropdown.Menu id="item-comment">
                          {this.state.nbMessage !== 0 ? (
                            this.state.messages.map((item) => (
                              <Dropdown.Item key={item.id}>
                                <NavLink
                                  to={`/detailsMessage/${item.id}`}
                                  className="item-message"
                                  onClick={(e) =>
                                    this.isReadMessage(e, item.id)
                                  }
                                >
                                  {" "}
                                  <b>
                                    {item.first_name + " " + item.last_name}
                                  </b>
                                  &nbsp;{" "}
                                  <span id="message-envoyer">
                                    {" "}
                                    à envoyé un message
                                  </span>
                                  <small id="time-ago">
                                    {" "}
                                    il y a &nbsp;
                                    <TimeAgo
                                      datetime={item.created_at}
                                      locale="vi"
                                    />
                                  </small>
                                </NavLink>
                              </Dropdown.Item>
                            ))
                          ) : (
                            <Dropdown.Item>
                              Il n'y a aucun message à afficher
                            </Dropdown.Item>
                          )}
                          <Dropdown.Divider />
                          <Dropdown.Item>
                            <NavLink to="/liste-message" className="voir-plus">
                              Voir tous les messages
                            </NavLink>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </List.Item>
                  )}
                  {this.state.userStatus === "admin" && (
                    <List.Item>
                      {this.state.nbHistoriques !== 0 && (
                        <span
                          className="badge badge-pill badge-danger"
                          id="label-history"
                        >
                          {this.state.nbHistoriques}
                        </span>
                      )}
                      <Dropdown
                        pointing
                        className="link item"
                        trigger={historics}
                        icon={null}
                      >
                        <Dropdown.Menu id="item-history">
                          {this.state.nbHistoriques !== 0 ? (
                            this.state.historiques.map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                id="historiques-after"
                              >
                                <NavLink
                                  to="/historiques"
                                  className="item-historiques"
                                  onClick={(e) =>
                                    this.historiqueisRead(e, item.id)
                                  }
                                >
                                  {" "}
                                  <b>{item.text}</b>
                                  <small id="time-ago">
                                    {" "}
                                    il y a &nbsp;
                                    <TimeAgo
                                      datetime={item.created_at}
                                      locale="vi"
                                    />
                                  </small>
                                </NavLink>
                              </Dropdown.Item>
                            ))
                          ) : (
                            <Dropdown.Item>
                              Il n'y a aucun historie à afficher
                            </Dropdown.Item>
                          )}
                          <Dropdown.Divider />
                          <Dropdown.Item>
                            <NavLink to="/historiques" className="voir-plus">
                              Voir toutes les historiques
                            </NavLink>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </List.Item>
                  )}
                  {/* navbar-right coach */}
                  {this.state.userStatus === "coach" && (
                    <List.Item>
                      {/* {this.state.MessageNotificationBakelist.length > 0 && */}
                      <span
                        className="badge badge-pill badge-danger"
                        id="label-notification"
                      >
                        {this.state.nombreNotificationCoach}
                      </span>
                      {/*  } */}
                      <Dropdown
                        pointing="top left"
                        className="link item"
                        trigger={appointment}
                        icon={null}
                        onClick={this.handlecliskiconeBakelist}
                      >
                        <Dropdown.Menu id="item-dropdown-bell">
                          {/* {this.state.MessageNotificationBakelist === ''? */}
                          <Dropdown.Header id="no-data-to-display">
                            Il n'y a aucune donnée à afficher
                          </Dropdown.Header>
                          {/*   :
                                        <Dropdown.Header><NavLink to="/gestion-tache">{this.state.MessageNotificationBakelist}</NavLink></Dropdown.Header>
                                            } */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </List.Item>
                  )}
                  {/* navbar-right bakeliste */}
                  {this.state.userStatus === "bakeliste" && (
                    <List.Item>
                      {this.state.MessageNotificationBakelist.length > 0 && (
                        <span
                          className="badge badge-pill badge-danger"
                          id="label-notification"
                        >
                          {this.state.nombreNotificationBakelist + 1}
                        </span>
                      )}
                      <Dropdown
                        pointing="top left"
                        className="link item"
                        trigger={appointment}
                        icon={null}
                        onClick={this.handlecliskiconeBakelist}
                      >
                        <Dropdown.Menu id="item-dropdown-bell">
                          {this.state.MessageNotificationBakelist === "" ? (
                            <Dropdown.Header id="no-data-to-display">
                              Il n'y a aucune donnée à afficher
                            </Dropdown.Header>
                          ) : (
                            <Dropdown.Header>
                              <NavLink to="/gestion-tache">
                                {this.state.MessageNotificationBakelist}
                              </NavLink>
                            </Dropdown.Header>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </List.Item>
                  )}
                  <List.Item id="item-user-avatar">
                    <Dropdown
                      pointing="top right"
                      className="link item"
                      fluid
                      trigger={user_avatar}
                    >
                      <Dropdown.Menu id="item-dropdown-mobile">
                        <Dropdown.Item>
                          <NavLink to="mon-profil" className="user-compte">
                            <Icon name="user" size="large" /> Mon Compte
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink
                            to="editer-mon-profil"
                            className="user-compte"
                          >
                            <Icon name="edit" size="large" /> Modifier mon
                            profil
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink
                            to="modifier-mon-mot-de-passe"
                            className="user-compte"
                          >
                            <Icon name="lock" size="large" /> Modifier mon mot
                            de passe
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          className="user-compte"
                          onClick={this.doLogout}
                        >
                          <Image
                            src="https://img.icons8.com/ios/20/000000/logout-rounded.png"
                            onClick={this.doLogout}
                            id="logout-btn"
                          />
                          <b id="logout-avatar" onClick={this.doLogout}>
                            Déconnexion
                          </b>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </List.Item>
                </List>
              </ul>
            </div>
          </div>
        </nav>

        <div className="navbar-default sidebar" role="navigation">
          <div className="sidebar-nav navbar-collapse">
            <ul className="nav" id="side-menu">
              {/* <li><NavLink to="/dashboard-admin" activeClassName="active">Accueil</NavLink></li> */}
              {/* <li><NavLink to="/reportings" activeClassName="active">Reporting</NavLink></li>  */}
              {this.state.userStatus === "admin" && (
                <div className="only_admin_li">
                  {/* <hr /> */}

                  <li>
                    <NavLink to="/dashboard-admin" activeClassName="active">
                      <i className="fas fa-tachometer-alt"></i> &nbsp; Accueil{" "}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/controles" activeClassName="active">
                      <i className="fa fa-clipboard"></i> &nbsp; Contrôle
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/entreprises" activeClassName="active">
                      <i className="fas fa-address-book"></i> &nbsp; Entreprises
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/reglement-interieur" activeClassName="active">
                      <i className="fas fa-gavel"></i> &nbsp; RI
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/reportings" activeClassName="active">
                      <i className="fas fa-align-justify"></i> &nbsp; Reporting
                    </NavLink>
                  </li>
                  {/* <li><NavLink to="/liste-entreprises-partenaires" activeClassName="active">
                                        <i className="fas fa-address-book"></i> &nbsp;
                                        Entreprises
                                        </NavLink>
                                    </li> */}
                  <li>
                    <NavLink
                      to="/demandes-placement-directes"
                      activeClassName="active"
                    >
                      <i className="fas fa-place-of-worship"></i> &nbsp;
                      Demandes entreprises
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/gestion-pointages" activeClassName="active">
                      <i className="fas fa-place-of-worship"></i> &nbsp;
                      Pointage
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/manage-users" activeClassName="active">
                      <i className="fas fa-users"></i> &nbsp; U. Manager
                    </NavLink>
                  </li>
                  <li className="beta-link">
                    <NavLink to="/all-users" activeClassName="active">
                      <i className="fas fa-users"></i> &nbsp; Utilisateur (Bêta)
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/domaine-formation" activeClassName="active">
                      <i className="fas fa-university"></i> &nbsp; Domaines{" "}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/liste-tous-les-bakelistes"
                      activeClassName="active"
                    >
                      <i className="fas fa-users"></i> &nbsp; All Bakelistes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/liste-prospects" activeClassName="active">
                      <i className="fas fa-list-ul"></i> &nbsp; Prospects
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/liste-bakelistes" activeClassName="active">
                      <i className="fas fa-user-graduate"></i> &nbsp; Bakelistes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/liste-bakelistes-recrutables"
                      activeClassName="active"
                    >
                      <i className="fas fa-user-graduate"></i> &nbsp;
                      Recrutables
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/liste-bakelistes-en-immersion"
                      activeClassName="active"
                    >
                      <i className="fas fa-user-graduate"></i> &nbsp; B.
                      Immersion
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/gestion-retards-absences-bakelistes"
                      activeClassName="active"
                    >
                      <i className="fas fa-hourglass-half"></i> &nbsp;
                      Retard/Absence
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/liste-des-bakelistes-en-poste"
                      activeClassName="active"
                    >
                      <i className="fas fa-briefcase"></i> &nbsp; Placements
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/liste-bakelistes-formation-terminer"
                      activeClassName="active"
                    >
                      <i className="fas fa-times-circle"></i> &nbsp; BF Terminés
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/liste-coach" activeClassName="active">
                      <i className="fas fa-user-tie"></i> &nbsp; Coachs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/demandes-absences" activeClassName="active">
                      <i className="fas fa-sticky-note"></i> &nbsp; Demande
                      absence
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/gestion-tache" activeClassName="active">
                      <i className="fas fa-tasks"></i> &nbsp; Tâches
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/liste-participants-kokutana2019"
                      activeClassName="active"
                    >
                      <i className="fas fa-handshake"></i> &nbsp; Kokutana2019
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/event-manager" activeClassName="active">
                      <i className="fas fa-ad"></i> &nbsp; Event Manager
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/evenement" activeClassName="active">
                      <i className="fas fa-ad"></i> &nbsp; Evénements
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/meetings" activeClassName="active">
                      <i className="fas fa-handshake"></i> &nbsp; Rencontres
                    </NavLink>
                  </li>
                  <div>
                    <button className="dropdown-btn">
                      {" "}
                      &nbsp; Autres
                      <i className="fa fa-caret-down"></i>
                    </button>

                    <div className="dropdown-container">
                      <li>
                        <NavLink
                          to="/gestion-des-rendez-vous"
                          activeClassName="active"
                        >
                          <i className="far fa-handshake"></i> &nbsp;
                          Rendez-vous
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/candidature-spontanee"
                          activeClassName="active"
                        >
                          <i className="fas fa-chalkboard-teacher"></i> &nbsp;
                          Candidature{" "}
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/manage-import-csv"
                          activeClassName="active"
                        >
                          <i className="fas fa-file-import"></i>&nbsp; Import
                          CSV
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/ratings-manager" activeClassName="active">
                          <i className="fas fa-user-graduate"></i> &nbsp;
                          Ratings
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/liste-contenus-textuels"
                          activeClassName="active"
                        >
                          <i className="fas fa-edit"></i> &nbsp; Contenus{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/outils" activeClassName="active">
                          <i className="fa fa-gear"></i> &nbsp; Outils
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/technologies" activeClassName="active">
                          <i className="fa fa-desktop"></i> &nbsp; Technologies
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/groupes" activeClassName="active">
                          <i className="fas fa-users"></i> &nbsp; Groupes
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/gestions_groupes"
                          activeClassName="active"
                        >
                          <i className="fas fa-users"></i> &nbsp; Participant
                          groupes
                        </NavLink>
                      </li>

                      <li className="beta-link">
                        <NavLink
                          to="/syllabus-manager"
                          activeClassName="active"
                        >
                          <i className="fas fa-list"></i> &nbsp; Syllabus (Bêta)
                        </NavLink>
                      </li>

                      <Link to="/nouvelles-fonctionnalites">
                        <div className="relase-container">Releases</div>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {this.state.userStatus === "coach" && (
                <div className="only_coach_li">
                  <li>
                    <NavLink to="/dashboard-coach" activeClassName="active">
                      <i className="fas fa-tachometer-alt"></i> &nbsp; Accueil
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/syllabus-coach" activeClassName="active">
                      <i className="fas fa-list"></i> &nbsp; Syllabus
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/reglement-interieur" activeClassName="active">
                      <i className="fas fa-gavel"></i> &nbsp; RI
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/gestion-retards-absences-bakelistes"
                      activeClassName="active"
                    >
                      <i className="fas fa-hourglass-half"></i> &nbsp;
                      Retard/Absence
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/gestion-tache" activeClassName="active">
                      <i className="fas fa-tasks"></i> &nbsp; Tâches
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tache-chat" activeClassName="active">
                      <i className="fas fa-tasks"></i> &nbsp; Tâches - Chat
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/reportings" activeClassName="active">
                      <i className="fas fa-align-justify"></i> &nbsp; Reporting
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/livraisons" activeClassName="active">
                      <i className="fas fa-file-alt"></i> &nbsp; livrables
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/groupes" activeClassName="active">
                      <i className="fas fa-users"></i> &nbsp; Groupes
                    </NavLink>
                  </li>
                  {/*  <li>
                    <NavLink to="/gestions_groupes" activeClassName="active">
                      <i className="fas fa-users"></i> &nbsp;  Participant groupes
                    </NavLink>
                    </li> */}
                  {/* <li><NavLink to="/liste-bakelistes" activeClassName="active">Liste des bakelistes</NavLink></li> */}
                  {/* <li><NavLink to="/liste-coach" activeClassName="active">Liste des coachs</NavLink></li> */}
                </div>
              )}

              {this.state.userStatus === "bakeliste" && (
                <div className="only_bakeliste_li">
                  <li>
                    {" "}
                    <NavLink to="/dashboard-bakeliste" activeClassName="active">
                      <i className="fas fa-tachometer-alt"></i> &nbsp; Accueil
                    </NavLink>{" "}
                  </li>
                  {/*  <li>
                    {" "}
                    <NavLink to="/home" activeClassName="active">
                      <i className="fas fa-home"></i> &nbsp; Home
                    </NavLink>{" "}
                  </li> */}
                  <li>
                    {" "}
                    <NavLink to="/production" activeClassName="active">
                      <i className="far fa-tablet-rugged"></i> &nbsp; Production{" "}
                    </NavLink>{" "}
                  </li>
                  <li>
                    {" "}
                    <NavLink
                      to="/edition-cv-bakeliste"
                      activeClassName="active"
                    >
                      <i className="fas fa-edit"></i> &nbsp; CV{" "}
                    </NavLink>{" "}
                  </li>
                  {/*  <li className="beta-link"><NavLink to="/bakeliste-profile" activeClassName="active">
                                        <i className="fas fa-user"></i> &nbsp;
                                        Profil (Bêta)</NavLink></li> */}
                  {/* <li><NavLink to="/bibliotheque" activeClassName="active">
                                        <i class="fas fa-book-reader"></i> &nbsp;
                                        Bibliotheque</NavLink></li> */}

                  {/*  <li className="beta-link"><NavLink to="/bakeliste-profile" activeClassName="active">
                                        <i className="fas fa-user"></i> &nbsp;
                                        Profil (Bêta)</NavLink></li> */}
                  {/* <li><NavLink to="/bibliotheque" activeClassName="active">
                                        <i class="fas fa-book-reader"></i> &nbsp;
                                        Bibliotheque</NavLink></li> */}
                  <li>
                    <NavLink
                      to={"/syllabus-by-domaine/" + this.state.userID}
                      activeClassName="active"
                    >
                      <i className="fas fa-list"></i> &nbsp; Syllabus{" "}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={"/livrable/" + this.state.userID}
                      activeClassName="active"
                    >
                      <i className="fas fa-paper-plane"></i> &nbsp; Livrables
                      envoyés{" "}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/demande-absence/" + this.state.userID}
                      activeClassName="active"
                    >
                      <i className="fas fa-sticky-note "></i> &nbsp; Demande/
                      Absence{" "}
                    </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink to="/noterMonCoach" className="men-item">
                      <i className="fas fa-star-half-alt"></i> &nbsp; Noter
                      Bakeli
                    </NavLink>{" "}
                  </li>
                  <li>
                    <NavLink to="/reglement-interieur" activeClassName="active">
                      <i className="fas fa-gavel"></i> &nbsp; RI
                    </NavLink>
                  </li>
                  {/*  <Amplitude>
                                     {({ logEvent }) =>
                                    <li><NavLink to="/gestion-tache" activeClassName="active">
                                        <i className="fas fa-tasks"></i> &nbsp;
                                        Tâches</NavLink></li>  }
                                 </Amplitude>   */}
                  {/* <li> <NavLink to="/chat-Reporting" activeClassName="active">
                                        <i class="far fa-comment-dots"></i> &nbsp;
                                        Reporting Chat</NavLink> </li> */}
                  {/* <li> <NavLink to="/reportings" activeClassName="active">
                                        <i className="fas fa-align-justify"></i> &nbsp;
                                        Reporting</NavLink> </li>
                                        
                                    <li> <NavLink to="/editeur-texte" activeClassName="active">
                                        <i className="fas fa-edit"></i> &nbsp;
                                        Editeur de texte</NavLink> </li>
                                     */}

                  {this.state.coachID !== undefined && (
                    <div>
                      <hr />
                      <div className="alert alert-warning coach-infos">
                        <div className="cInfos-title">Votre coach </div>
                        <div className="cInfos-item-content">
                          <span className="cInfos-item-label">
                            Prénom et Nom
                          </span>{" "}
                          <br />
                          <span className="cInfos-item">
                            {" "}
                            {this.state.coachFullname}{" "}
                          </span>
                        </div>{" "}
                        <hr />
                        <div className="cInfos-item-content">
                          <span className="cInfos-item-label">Email</span>{" "}
                          <br />
                          <span className="cInfos-item">
                            {" "}
                            {this.state.coachEmail}{" "}
                          </span>
                        </div>{" "}
                        <hr />
                        <div className="cInfos-item-content">
                          <span className="cInfos-item-label">Téléphone</span>{" "}
                          <br />
                          <span className="cInfos-item">
                            {" "}
                            {this.state.coachPhone}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </ul>
          </div>
          {/* <!-- /.sidebar-collapse --> */}

          {/* Search result render modal */}
          <ReactModal
            isOpen={this.state.showModal}
            contentLabel="Modal #1 Global Style Override Example"
            onRequestClose={this.handleCloseModal}
          >
            <h4 className="text-center modaly-title">
              {" "}
              {this.state.findBakelistesCount +
                " résultats trouvés pour " +
                this.state.key}{" "}
            </h4>

            <div className="search-find-content">
              <BootstrapTable
                data={this.state.findBakelistes}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                exportCSV
                pagination
                search={true}
              >
                {/* <TableHeaderColumn dataAlign="center" width='400' dataField='id' dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
                <TableHeaderColumn
                  dataAlign="center"
                  width="50"
                  dataField="id"
                  isKey={true}
                  hidden={true}
                >
                  {" "}
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="300"
                  dataField="bakelisteFullname"
                  dataFormat={this.bakelisteFullnameFormatter.bind(this)}
                  filter={{ type: "TextFilter", delay: 1000 }}
                  dataSort={true}
                >
                  Nom complet
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="250"
                  dataField="email"
                >
                  Email
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="200"
                  dataField="phone"
                >
                  Téléphone
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="300"
                  dataField="domaine"
                  filter={{ type: "TextFilter", delay: 1000 }}
                  dataSort={true}
                >
                  Domaine
                </TableHeaderColumn>
                {/* <TableHeaderColumn dataAlign="center"
                                    width='300'
                                    dataField='inTrainingSinceMonths'
                                    dataFormat={this.inTrainingSinceFormatter}
                                    filter={{ type: 'TextFilter', delay: 1000 }}
                                    dataSort={true}>En formation depuis</TableHeaderColumn> */}
                <TableHeaderColumn
                  dataAlign="center"
                  width="250"
                  dataField="coachFullname"
                  dataFormat={this.coachRowFormatter.bind(this)}
                  filter={{ type: "TextFilter", delay: 1000 }}
                  dataSort={true}
                >
                  Coach
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="250"
                  dataField="type_formation"
                  dataFormat={this.typeFormationFormatter.bind(this)}
                  filter={{ type: "TextFilter", delay: 1000 }}
                  dataSort={true}
                >
                  Type de formation
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="200"
                  dataField="lieu_formation"
                  filter={{ type: "TextFilter", delay: 1000 }}
                  dataSort={true}
                >
                  Espace de formation
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="250"
                  dataField="ecole"
                  filter={{ type: "TextFilter", delay: 1000 }}
                  dataSort={true}
                >
                  Ecole
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataAlign="center"
                  width="150"
                  dataField="horaire"
                  filter={{ type: "TextFilter", delay: 1000 }}
                  dataSort={true}
                >
                  Horaire
                </TableHeaderColumn>
              </BootstrapTable>
            </div>

            <span className="closy-modal" onClick={this.handleCloseModal}>
              x
            </span>
          </ReactModal>

          {/* Search request error or empty render */}
          <ReactModal
            isOpen={this.state.showModal3}
            contentLabel="Modal #1 Global Style Override Example"
            onRequestClose={this.handleCloseModalError}
          >
            <h4 className="text-center modaly-error-title">
              {" "}
              Erreur de requête - Résultat vide{" "}
            </h4>
            {this.state.searchRequestError && (
              <div className="search-error-container">
                <div className="search-error-icon">
                  <i className="fa fa-bug fa-3x" aria-hidden="true"></i>
                </div>
                <div className="search-error-content">
                  Oupsss ! Une erreur est survenu lors de la recherche. Veuillez
                  réessayer plus tard.
                </div>
              </div>
            )}
            {this.state.emptyBakelisteData && (
              <div className="search-empty-container">
                <div className="search-empty-icon">
                  <i className="fas fa-empty-set fa-3x"></i>
                </div>
                <div className="search-error-content">
                  Oupsss ! Aucun résultat n'a été trouvé.
                </div>
              </div>
            )}
            <span className="closy-modal" onClick={this.handleCloseModalError}>
              x
            </span>
          </ReactModal>

          {/* Clicked bakeliste informations modal render */}
          <ReactModal
            isOpen={this.state.showModal2}
            contentLabel="Modal #2 Global Style Override Example"
            onRequestClose={this.handleCloseModalInfos}
          >
            <div className="modaly-body">
              <h4 className="modal-infos-title" id="BakelisteInfosModalLabel">
                Detail sur {this.state.bakeliste.first_name}&nbsp;
                {this.state.bakeliste.last_name}
              </h4>
              {this.state.bakeliste.type_formation === "courte" ? (
                this.state.bakeliste.nbreJours === "3" ? (
                  <div className="content_container">
                    <b>Jours de présences : </b> <br />
                    <span>
                      {" "}
                      {this.state.bakeliste.jour1}, {this.state.bakeliste.jour2}
                      , {this.state.bakeliste.jour3}{" "}
                    </span>{" "}
                    <br />
                    <b>Heure de présence</b> <br />
                    <span className="horaire-bakeliste-item">
                      {" "}
                      {this.state.bakeliste.horaire}{" "}
                    </span>
                  </div>
                ) : this.state.bakeliste.nbreJours === "5" ? (
                  <div className="content_container">
                    <b>Jours de présences : </b> <br />
                    <span>
                      {" "}
                      {this.state.bakeliste.jour1}, {this.state.bakeliste.jour2}
                      , {this.state.bakeliste.jour3},{" "}
                      {this.state.bakeliste.jour4}, {this.state.bakeliste.jour5}{" "}
                    </span>{" "}
                    <br />
                    <b>Heure de présence</b> <br />
                    <span className="horaire-bakeliste-item">
                      {" "}
                      {this.state.bakeliste.horaire}{" "}
                    </span>
                  </div>
                ) : (
                  this.state.bakeliste.nbreJours === "6" && (
                    <div className="content_container">
                      <b>Jours de présences : </b> <br />
                      <span>
                        {" "}
                        {this.state.bakeliste.jour1},{" "}
                        {this.state.bakeliste.jour2},{" "}
                        {this.state.bakeliste.jour3},{" "}
                        {this.state.bakeliste.jour4},{" "}
                        {this.state.bakeliste.jour5},{" "}
                        {this.state.bakeliste.jour6}{" "}
                      </span>{" "}
                      <br />
                      <b>Heure de présence</b> <br />
                      <span className="horaire-bakeliste-item">
                        {" "}
                        {this.state.bakeliste.horaire}{" "}
                      </span>
                    </div>
                  )
                )
              ) : (
                this.state.bakeliste.type_formation === "diplomante" && (
                  <div className="content_container">
                    <b>Jours de présences : </b> <br />
                    <span> Du Lundi au Vendredi </span> <br />
                    <b>Heure de présence</b> <br />
                    <span className="horaire-bakeliste-item">
                      {" "}
                      {this.state.bakeliste.horaire}{" "}
                    </span>
                  </div>
                )
              )}
              <div className="email_container">
                <span className="item_label">Email : </span>{" "}
                <span className="label_content">
                  {this.state.bakeliste.email}
                </span>
              </div>{" "}
              <hr />
              <div className="objectif_container">
                <span className="item_label">Objectifs : </span>{" "}
                <span className="label_content">
                  {this.state.bakeliste.objectifs}
                </span>
              </div>{" "}
              <hr />
              {this.state.bakeliste.ecole !== "" && (
                <div className="ecole_container">
                  <span className="item_label">Ecole : </span>{" "}
                  <span className="label_content">
                    {this.state.bakeliste.ecole}
                  </span>{" "}
                  <hr />
                </div>
              )}
              <div className="email_container">
                <span className="item_label">Téléphone : </span>{" "}
                <span className="label_content">
                  {this.state.bakeliste.phone}
                </span>
              </div>{" "}
              <hr />
              <div className="ecole_container">
                <span className="item_label">Boursier : </span>
                {this.state.bakeliste.boursier === 1 ? (
                  <span className="label_content">OUI</span>
                ) : (
                  <span className="label_content">NON</span>
                )}
              </div>{" "}
              <hr />
              <div className="email_container">
                <span className="item_label">Lieu de formation : </span>{" "}
                <span className="label_content">
                  {this.state.bakeliste.lieu_formation}
                </span>
              </div>{" "}
              <hr />
              <div className="email_container">
                <span className="item_label">Type de formation : </span>{" "}
                <span className="label_content">
                  {this.state.bakeliste.type_formation}
                </span>
              </div>{" "}
              <hr />
              <div className="ecole_container">
                <span className="item_label">Formation payante : </span>
                {this.state.bakeliste.is_formation_payante === 1 ? (
                  <span className="label_content">OUI</span>
                ) : (
                  <span className="label_content">NON</span>
                )}
              </div>{" "}
              <hr />
              <div className="email_container">
                <span className="item_label">
                  Date de début de la formation :{" "}
                </span>{" "}
                <span className="label_content">
                  {this.state.bakeliste.startFrenchDateFormat}
                </span>
              </div>{" "}
              <hr />
              <div className="email_container">
                <span className="item_label">
                  Date de fin de la formation :{" "}
                </span>{" "}
                <span className="label_content">
                  {this.state.bakeliste.endFrenchDateFormat}
                </span>
              </div>{" "}
              <hr />
            </div>
            <span className="closy-modal" onClick={this.handleCloseModalInfos}>
              x
            </span>
          </ReactModal>
        </div>

        {/* <div className="modal fade" id="BakelisteInfosModal" tabindex="-1" role="dialog" aria-labelledby="BakelisteInfosModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title" id="BakelisteInfosModalLabel">Detail sur {this.state.bakeliste.first_name}&nbsp;{this.state.bakeliste.last_name}</h4>
                            </div>
                            <div className="modal-body">
                                {this.state.bakeliste.type_formation === 'courte' ? (
                                    this.state.bakeliste.nbreJours === '3' ? (
                                        <div className="content_container">
                                            <b>Jours de présences : </b> <br />
                                            <span> {this.state.bakeliste.jour1}, {this.state.bakeliste.jour2}, {this.state.bakeliste.jour3} </span> <br />
                                            <b>Heure de présence</b> <br />
                                            <span className="horaire-bakeliste-item"> {this.state.bakeliste.horaire} </span>

                                        </div>
                                    ) :
                                        this.state.bakeliste.nbreJours === '5' ? (
                                            <div className="content_container">
                                                <b>Jours de présences : </b> <br />
                                                <span> {this.state.bakeliste.jour1}, {this.state.bakeliste.jour2}, {this.state.bakeliste.jour3}, {this.state.bakeliste.jour4}, {this.state.bakeliste.jour5} </span> <br />
                                                <b>Heure de présence</b> <br />
                                                <span className="horaire-bakeliste-item"> {this.state.bakeliste.horaire} </span>

                                            </div>
                                        ) :
                                            this.state.bakeliste.nbreJours === '6' && (
                                                <div className="content_container">
                                                    <b>Jours de présences : </b> <br />
                                                    <span> {this.state.bakeliste.jour1}, {this.state.bakeliste.jour2}, {this.state.bakeliste.jour3}, {this.state.bakeliste.jour4}, {this.state.bakeliste.jour5}, {this.state.bakeliste.jour6} </span> <br />
                                                    <b>Heure de présence</b> <br />
                                                    <span className="horaire-bakeliste-item"> {this.state.bakeliste.horaire} </span>
                                                </div>
                                            )
                                ) :
                                    this.state.bakeliste.type_formation === 'diplomante' && (
                                        <div className="content_container">
                                            <b>Jours de présences : </b> <br />
                                            <span> Du Lundi au Vendredi </span> <br />
                                            <b>Heure de présence</b> <br />
                                            <span className="horaire-bakeliste-item"> {this.state.bakeliste.horaire} </span>

                                        </div>
                                    )
                                }
                                <div className="email_container">
                                    <span className="item_label">Email : </span> <span className="label_content">{this.state.bakeliste.email}</span>
                                </div> <hr />
                                <div className="objectif_container">
                                    <span className="item_label">Objectifs : </span> <span className="label_content">{this.state.bakeliste.objectifs}</span>
                                </div> <hr />

                                {this.state.bakeliste.ecole !== '' &&
                                    <div className="ecole_container">
                                        <span className="item_label">Ecole : </span> <span className="label_content">{this.state.bakeliste.ecole}</span> <hr />
                                    </div>

                                }
                                <div className="email_container">
                                    <span className="item_label">Téléphone : </span> <span className="label_content">{this.state.bakeliste.phone}</span>
                                </div> <hr />
                                <div className="ecole_container">
                                    <span className="item_label">Boursier : </span>
                                    {this.state.bakeliste.boursier === 1 ?
                                        <span className="label_content">OUI</span> :
                                        <span className="label_content">NON</span>
                                    }

                                </div> <hr />
                                <div className="email_container">
                                    <span className="item_label">Lieu de formation : </span> <span className="label_content">{this.state.bakeliste.lieu_formation}</span>
                                </div> <hr />
                                <div className="email_container">
                                    <span className="item_label">Type de formation : </span> <span className="label_content">{this.state.bakeliste.type_formation}</span>
                                </div> <hr />
                                <div className="ecole_container">
                                    <span className="item_label">Formation payante : </span>
                                    {this.state.bakeliste.is_formation_payante === 1 ?
                                        <span className="label_content">OUI</span> :
                                        <span className="label_content">NON</span>
                                    }
                                </div> <hr />
                                <div className="email_container">
                                    <span className="item_label">Date de début de la formation : </span> <span className="label_content">{this.state.bakeliste.startFrenchDateFormat}</span>
                                </div> <hr />
                                <div className="email_container">
                                    <span className="item_label">Date de fin de la formation : </span> <span className="label_content">{this.state.bakeliste.endFrenchDateFormat}</span>
                                </div> <hr />
                            </div>
                            <div className="modal-footer">

                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }
}
