import React, { Component } from 'react';
import './bakelisteHomeDashboard.css';
//import { Line } from 'react-chartjs-2';
import Menu from '../menu/Menu';
import API from '../../variablesGlobales';
import axios from 'axios';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';
import { Form, TextArea } from 'semantic-ui-react-form-validator';
import {
  Amplitude
} from "@amplitude/react-amplitude";
import amplitude from "amplitude-js";
export default class bakelisteHomeDashboard extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem('token');

    if (!token) {
        window.history.back()
    }
    super(props);
    this.state = {
      api: API.API,
      tacheTodoCount: 0,
      tacheDoingCount: 0,
      tacheDoneCount: 0,
      bakeliste_id: window.sessionStorage.getItem('user_id'),
      email:window.sessionStorage.getItem('user_email'),
      events: [],
      showEvent: false,
      fields: {},
      errors: {},
      pointInProgress: false,
      isSuccessPointMe: false,
      isErrorPointAlert: false,
      isPoint: false,
      pointage: [],
      isSuccessPointDepartureAlert: false,
      isErrorPointDepartureAlert: false,
      departurePointInProgress: false,
      phone_syntax_error: false,
      userEmptyFeedbakTab:{
      user_id: "",
      feedback_type: "",
      feedback_content: "",
      feedback_priority: "",
     
      },
      feedback_id: 0,
            typeValue: '',
            contentValue: '',
            prioriteValue: '',
            file_pathValue:[0],
            EmptyType: false,
            EmptyCommentaire: false,
            EmptyPriorite: false,
            EmptyFile:false,
            
            addFeedbackSuccessAlert: false,
            addFeedbackProgress: false,
            addFeedbackError: false,
            feedbackEnCours:false,
            isFeedbackAddSuccessAlert:false,
            image: '',
            isValidForm:false,

        userFullName: window.sessionStorage.getItem('userFullName'),
        coachID: window.sessionStorage.getItem('coach_id'),
        // bakeliste_id: window.sessionStorage.getItem('user_id'),
         value: '',
         messages:'', 
         current_messag:"",
         status:'Terminé',
         date:'',
         lieu:'bakeli',
         app_name:'name_app',
         technology:'techno',
         tache:'',
         using_links:'',
         commentaire:'',
         file:'',
         reportingInprogess:false,
         reportingSucess:false
    };

    this.handlePointMeChange = this.handlePointMeChange.bind(this);
    this.submitPointMeArriving = this.submitPointMeArriving.bind(this);
    this.submitPointMeDeparture = this.submitPointMeDeparture.bind(this);
    this.handSend = this.handSend.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    //console.log('yes', window.sessionStorage.getItem('coach_firstName'));
    this.getTachesCountByBakeliste();
    this.onGetEvents();
    this.onCheckBakelisteDailyPointage();
    this.getheure();
    this.amplitudeScript('0168d5bcb0d33437d15f2a6220fb054a');
   // this.amplitudeScript('7a9faefb1bb6a856ed9b89eaaf1e8944');
  }
  amplitudeScript = (api_key) => {
    (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
      ;r.type="text/javascript"
      ;r.integrity="sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta"
      ;r.crossOrigin="anonymous";r.async=true
      ;r.src="https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js"
      ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
      console.log("[Amplitude] Error: could not load SDK")}}
      ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
      ;function s(e,t){e.prototype[t]=function(){
      this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
      var o=function(){this._q=[];return this}
      ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
      ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
      ;return this}
      ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
      ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
      ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
      ;function v(e){function t(t){e[t]=function(){
      e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
      for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
      e=(!e||e.length===0?"$default_instance":e).toLowerCase()
      ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
      ;e.amplitude=n})(window,document);

      amplitude.getInstance().init(api_key);
  }

  onCheckBakelisteDailyPointage = () => {
    
    axios.get(this.state.api + 'daily-pointage-by-bakeliste/' + this.state.bakeliste_id)
      .then(response => {
        if(response.data.success && response.data.ispoint){
          this.setState({
            isPoint: true,
            pointage: response.data.data,
          })
          //amplitude.getInstance().logEvent('POINTING_Bakeliste');
        }else if(response.data.success && !response.data.ispoint){
          this.setState({
            isPoint: false
          })
        }
      })
  }
 // compter le numbre de tache assigne a un bakelist
  getTachesCountByBakeliste = () => {
    let bakeliste_id = this.state.bakeliste_id;

    axios.get(this.state.api + 'count-taches-by-bakeliste/' + bakeliste_id)
      .then(response => {
        console.log('feedback', response.data)
        if(response.data.success === true){
          this.setState({
            tacheTodoCount: response.data.datatodo,
            tacheDoingCount: response.data.datadoing,
            tacheDoneCount: response.data.datadone
          })
        }
      })
  }

  // Get events
  onGetEvents = () => {
    axios.get(this.state.api + 'event-manager')
      .then(response => {
        if (response.data.success) {
          console.log(response.data.data)
          this.setState({
            events: response.data.data,
            showEvent: true
          })
        } else {
          this.setState({
            isEmptyData: true
          })
        }
      })
      .catch(error => {
        this.setState({
          isRequestError: true
        })
        console.log(error.message)
      })
  }

  handlePointMeChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields
    })
    console.log(fields)
  }

  handleValidatePointMeForm = () => {
    let fields = this.state.fields;
    var isValidForm = true;
    let errors = {};

    if(!fields["phone"]){
      isValidForm = false;
      this.setState({
        empty_pm_phone: true
      })
      this.handleSwitchStatut('phone');
      errors["phone"] = 'Ce champ est obligatoire';
    }
    /* if (typeof fields["phone"] !== "undefined") {
      var str = fields["phone"];
      str = str.replace(/\s/g, '');
      //formdata.value.phone = str;
      console.log('data format',str)
      if (!str.match(/^[0-9]{9}$/)) {
        isValidForm = false;
        this.setState({
          phone_syntax_error: true
        })
        this.handleSwitchStatut('phone_syntax_error');
        errors["phone_syntax_error"] = 'Votre téléphone n\'est pas valide';
      }
    } */

    this.setState({
      errors: errors
    })

    return isValidForm;
  }

  handleSwitchStatut = (label) => {
    setTimeout(() => {
      if(label === 'phone'){
        this.setState({
          empty_pm_phone: false
        })
      }
      if(label === 'isSuccessPointMe'){
        this.setState({
          isSuccessPointMe: false
        })
      }
      if(label === 'isErrorPointAlert'){
        this.setState({
          isErrorPointAlert: false
        })
      }
      if(label === 'isSuccessPointDepartureAlert'){
        this.setState({
          isSuccessPointDepartureAlert: false
        })
      }
      if(label === 'isErrorPointDepartureAlert'){
        this.setState({
          isErrorPointDepartureAlert: false
        })
      }
      if(label === 'phone'){
        this.setState({
          empty_pm_phone: false
        })
      }
      if(label === 'phone_syntax_error'){
        this.setState({
          phone_syntax_error: false
        })
      }
    }, 5000)
  }

  submitPointMeArriving = (e) => {
    e.preventDefault();

    if(this.handleValidatePointMeForm()){
      this.setState({
        pointInProgress: true
      })
      var eventProperties = {
        'user_email': this.state.email
       };
      
      var data = this.state.fields;
  const apiBaseUrl = this.state.api;
  ///amplitude.getInstance().logEvent('POINTING_Bakeliste', eventProperties);
      axios.post(apiBaseUrl + 'store-pointages', data)
        .then(response => {
        
          if (response.data.success) {
            this.onCheckBakelisteDailyPointage();
            this.setState({
              pointInProgress: false,
              isSuccessPointMe: true,
              fields: {}
            })
            this.handleSwitchStatut('isSuccessPointMe')
            amplitude.getInstance().logEvent('Pointage_Bakeliste', eventProperties);
      
          } else {
            this.setState({
              pointInProgress: false,
              isErrorPointAlert: true
            })
            this.handleSwitchStatut('isErrorPointAlert');
            amplitude.getInstance().logEvent('Pointage_Bakeliste_Error', eventProperties);  
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            pointInProgress: false,
            isErrorPointAlert: true
          })
          this.handleSwitchStatut('isErrorPointAlert')
          amplitude.getInstance().logEvent('Pointage_Bakeliste_Error', eventProperties); 
        })
    }
  }

  submitPointMeDeparture = (item) => {
    this.setState({
      departurePointInProgress: true
    })
    var eventProperties = {
      'user_email': this.state.email
     };
    var pointItemID = item.id;
    var apiBaseUrl = this.state.api;

    axios.post(apiBaseUrl + 'store-departure-time/' + pointItemID)
      .then(response => {
        this.setState({
          departurePointInProgress: false
        })

        if(response.data.success){
          this.onCheckBakelisteDailyPointage();
          this.setState({
            isSuccessPointDepartureAlert: true
          })
          this.handleSwitchStatut('isSuccessPointDepartureAlert')
          amplitude.getInstance().logEvent('Depointage_Bakeliste', eventProperties);
        }else{
          this.setState({
            isErrorPointDepartureAlert: true
          })
          this.handleSwitchStatut('isErrorPointDepartureAlert')
          amplitude.getInstance().logEvent('Depointage_Bakeliste_Error', eventProperties); 
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isErrorPointDepartureAlert: true
        })
        this.handleSwitchStatut('isErrorPointDepartureAlert')
        amplitude.getInstance().logEvent('Depointage_Bakeliste_Error', eventProperties);
      })
  }
  handleFeedback = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    console.log("handle change", e.target.value)
    fields["user_id"] = window.sessionStorage.getItem('user_id')
    
    if(e.target.name === 'feedback_file'){
      fields["feedback_file"] = e.target.files[0]
    }
    if(e.target.name === 'feedback_content'){
      fields[e.target.name]= e.target.value;
    }
    if(e.target.name === 'feedback_priority'){
      fields[e.target.name] = e.target.value;
    }
    this.setState({
        fields
    });
    console.log("handle change", this.state.fields)
}

  onValidateFeedbackForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let isValidForm = true;
    if (!fields["feedback_content"]) {
      isValidForm = false;
      this.setState({
          EmptyCommentaire: true
      })
      this.handleShowAndHideAlert('EmptyCommentaire');
      errors["EmptyCommentaire"] = "*Ce champ est obligatoire";
  }
  


    return isValidForm;
}

handleShowAndHideAlert(label) {
  setTimeout(() => {
      if (label === 'addSuccessAlert') {
          this.setState({
              addFeedbackSuccessAlert: false
          });
      }
      if (label === 'isFeedbackAddSuccessAlert') {
        this.setState({
            isFeedbackAddSuccessAlert: false
        });
      }
      if(label === 'addErrorAlert'){
        this.setState({
            addFeedbackError: false
        })
    }
   
      if(label === 'EmptyType'){
          this.setState({
              EmptyType: false
          })
      }
      if(label === 'EmptyCommentaire'){
          this.setState({
              EmptyCommentaire: false
          })
      }
      if(label === 'EmptyPriorite'){
          this.setState({
              EmptyPriorite: false
          })
      }
     
     
  }, 1000);
}
addFeedback() {
  console.log("report add", this.state.fields);
  if(this.onValidateFeedbackForm()){
      this.setState({
          feedbackEncours: true
      })
      const data = this.state.fields;
     // console.log(dataBakeliste,"donne")
      console.log(this.state.fields.feedback_file,"fedb")
      const fd = new FormData();
     fd.append('user_id',data.user_id);
    fd.append('feedback_type',data.feedback_type);
    fd.append('feedback_priority',data.feedback_priority)
    fd.append('feedback_content',data.feedback_content);
    fd.append('feedback_file',data.feedback_file);
    //data.set('fields',this.state.fields);
      console.log(fd,"feed")
      axios.post(this.state.api + 'feedbacks',fd)
          .then(res => {
              if (res.status === 200) {
                 
                $('.close').click();
                  this.handleShowAndHideAlert('addSuccessAlert');
                  
                  $('#add-feedback-form')[0].reset();
                  this.setState({
                      addFeedbackSuccessAlert: true,
                     feedbackEnCours: false,
                     isFeedbackAddSuccessAlert:true,
                      fields: {}
                  });
                  this.handleShowAndHideAlert('isFeedbackAddSuccessAlert') ;
              }
          })
          .catch(error => {
              this.setState({
                 feedbackEnCours: false,
                  addFeedbackError: true
              });
              this.handleShowAndHideAlert('addFeedbackError');
              console.log(error);
              if (process.env.NODE_ENV !== 'production') {
                  return;
              }
              Sentry.captureException(error);
          });
  }
  
}
getheure = () =>{
  let rem = new Date()
  this.setState({
    date : rem.getFullYear() + '-' + (rem.getMonth() + 1) + '-' + rem.getDate(),
    heure:rem.getHours(),
    min:rem.getMinutes()

  })
}
onPost = () =>{

  this.setState({
    reportingInprogess: true
})
  let current_message=this.state.current_message
  console.log(this.state,"state");
 let fields = this.state.fields;
 fields["date"]=this.state.date;
 console.log(this.state.date,"look date");
 fields["bakeliste_id"]=this.state.bakeliste_id;
 fields["status"]=this.state.status;
 fields["lieu"]=this.state.lieu;
 fields["app_name"]=this.state.app_name;
 fields["technology"]=this.state.technology;
 fields["coach_id"]=this.state.coachID;
 fields["tache"]=this.state.messages;
  console.log(this.state.fields,"feed");
  // eslint-disable-next-line react/no-direct-mutation-state
  this.state.tache=this.state.messages
 console.log(this.state.tache,"tache")
 const data = this.state.fields;
 console.log(data)
 this.setState({
  isLoadingBakelisteData: true
})
 axios.post(this.state.api + 'reporting_bakelistes',data)
 .then(res => {
     if (res.status === 200) {
         this.handleShowAndHideAlertR('addSuccessAlert');
       // this.getReportingByBakeliste();
         this.setState({
           current_message:current_message,
             reportingInprogess:false,
             reportingSucess:true
         });
         this.handleClearText();
         this.handleShowAndHideAlertR('isChatAddSuccessAlert') ;
     }
 })
 .catch(error => {
     console.log(error);
     if (process.env.NODE_ENV !== 'production') {
         return;
     }
     Sentry.captureException(error);
 });
}
handleClick(e){
   e.preventDefault();
  this.handSend();
  // this.handleClearText();
}
handleShowAndHideAlertR(label) {
  setTimeout(() => {
      if (label === 'reportingSucess') {
          this.setState({
              reportingInprogess: false
          });
        } 
       
       }, 1000);
      
    }
handSend = (enter=true) => {
    let current_message = this.state.current_message;
    console.log(this.state);
    if(current_message && enter){
    this.setState({
      messages:current_message,
      tache:current_message,
    });
    this.onPost();
  }
}
    onChange(e) {
      this.setState({
        current_message: e.target.value,
        messages:e.target.value
      });  
      const newLocal = this.state;
      newLocal.messages=this.state.current_message;
      
    }



  render() {
    return (
      <div className="component-bakeliste-home-dashboard">
        <Menu />
        <div className="page">
          <div className="col-lg-12">
            <h1 className="page-header"> 
              <span className="page-title">Bakeli SI Dashboard</span>
            <div className="pull-right">
                <ol className="breadcrumb">
                <li className="active "> Accueil - Tableau de bord</li>
               </ol>
             </div>
            </h1>
          </div>
        </div>
        

        <div className="row info_row_bakeliste">
           
          {/* side droite */}
          <div id="sidebare-wrapper">
                <ul class="nav-side">
                    {/* <li class="sidebar-brand"> <a href="#"> Start Bootstrap </a> </li> */}
                    <li> <NavLink to='#'>Ecrire a mon Coach</NavLink> </li>
                    <li> <NavLink to='#'>Voir mes réalisations </NavLink> </li>
                    <li> <NavLink to='#'>Demander une absence</NavLink> </li>
                    <li> <NavLink to='#'>RI</NavLink> </li>
                    <li> <NavLink to='#'>Paramétre</NavLink> </li>
                    
                </ul>
            </div>  

            <div className="col-md-8 bakeliste-profile-container">
            {/*section reporting */}
            {/* <section id="section-bio">
             
             <div className="bio_container">
               <h3 className="section-title"> reporting </h3>
               <p>
                 
                
                 </p>
             </div>
           </section> */}
           {/* section pointe me */}
           {/* <section id="section-bio">
             
              <div className="bio_container">
                <h3 className="section-title"> point me </h3>
                <p>
                
                 
                  </p>
              </div>
            </section> */}
            <div class="col-md-8 col-md-offset-3">
            <div class="panel panel-success panel-larg">
                <div class="panel-heading" style={{backgroundColor:"teal" , color:"white"}} >
                    <h3 class="panel-title" >Que voulez-vous faire ?</h3>
                    
                </div>
                <div class="panel-body">
                    <div class="tab-content">
                        <input type="text" className="control" placeholder="Ecrire Ici ........"  />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 col-md-offset-3">
            <div class="panel panel-success point-mee">
               
                <div class="panel-body">
                    <div class="tab-content">
                        <div className="pointe">
                        <div className="arrondi"><p class="arrondi2">
                        <div className="">
                  {/* <span className="point-label">POINT me</span> */}
                  <form id="pointme-form">  
                    <div className="input-group">
                       <span className="input-group-addon " id="basic-addon1" style={{backgroundColor:"teal", color:"white"}}>
                        POINT me
                      </span> 
                      <input type="tel"  name="phone" className="form-control" placeholder="Entrez votre numéro de téléphone" aria-describedby="basic-addon1" />
                      
                    </div>
                    </form>
                </div>
                          </p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
           </div>
           

            {this.state.showEvent && this.state.events !== undefined ?
              this.state.events.map((item, index) => 
                item.showEvent === 1 && item.is_completed === 0 ?
                  <div key={index} className="col-md-10 col-md-offset-1 event_info_bakeliste_col">
                    <div className="box box-warning box-solid">
                        <div className="box-header with-border">
                            <h3 className="box-title"> {'[EVENT INFO] - ' + item.event_name} </h3>

                            <div className="box-tools pull-right">
                                <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"></i></button>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="row img-row">
                              <div className="col-md-12 img-col">
                                {item.event_subscribe_link !== null && item.event_img_url !== null ?
                                  <a href={item.event_subscribe_link} className="linking_img" target="_blank" rel="noopener noreferrer">
                                    <img className="event_img img-responsive" src={item.event_img_url} alt={item.event_name} />
                                  </a> :
                                  <img className="event_img img-responsive" src={item.event_img_url} alt={item.event_name} />
                                }
                              </div> <hr />
                              <div className="col-md-12 img-col">
                                <p className="event_description_content">
                                  {item.event_description}
                                </p>
                              </div> <hr />
                              {item.event_subscribe_link !== null ?
                                <div className="event_subscribe_container col-md-6 col-md-offset-3">
                                  <h3 className="text-center subscribe_title"> Inscrivez-vous  </h3>
                                  <a href={item.event_subscribe_link} className="subscribe_btn btn btn-warning" target="_blank" rel="noopener noreferrer">
                                    Cliquez ici pour vous inscrire
                                  </a>
                                </div> : ''
                              }
                            </div>
                        </div>
                    </div>
                </div> : '' 
                
              )
              : ''
            }
        </div>

{/* add feedback success toast */}
{this.state.isFeedbackAddSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                Feedback ajouté avec succès.
            </div>
        }

        {/* Success completed formation toast alert */}
        {this.state.isSuccessPointMe === true &&
          <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              Le pointage a été effectué avec succès.
          </div>
        }

        {this.state.isSuccessPointDepartureAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              Le pointage a été effectué avec succès.
          </div>
        }
        {this.state.isErrorPointDepartureAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp;
              Pointage non effectué. Réessayer !
          </div>
        }
      </div>
      
    );
  }
}
