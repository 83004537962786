import React, { Component } from 'react';
import './eventManager.css';
import { NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';

export default class eventManager extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem('token');
    let userStatus = window.sessionStorage.getItem('user_status');
    //let adminID = window.sessionStorage.getItem('user_id');

    if (!token) {
        window.history.back()
    }
    if (userStatus === 'bakeliste' || userStatus === 'coach') {
        window.history.back();
    }
    super(props);
    this.handleAddEvent = this.handleAddEvent.bind(this);
    this.submitNewEvent = this.submitNewEvent.bind(this);
    this.handleUpdateEvent = this.handleUpdateEvent.bind(this);
    this.submitUpdateEvent = this.submitUpdateEvent.bind(this);
    this.state = {
      api: API.API,
      adminID: window.sessionStorage.getItem('user_id'),
      isPageLoading: false,
      events: [],
      isEmptyData: false,
      isRequestError: false,
      event: [],
      addEventInProgress: false,
      isAddEventRequestError: false,
      isEventAddSuccessAlert: false,
      fields: {},
      image: '',
      
      uFields: {},
      updateEventInProgress: false,
      isUpdateEventRequestError: false,
      isEventUpdateSuccessAlert: false,
      eventID: 0,

    };
  }

  // First called method if component load
  componentDidMount() {
    this.onGetEvents();
  }

  // Get events
  onGetEvents = () => {
    this.setState({
      isPageLoading: true
    })

    axios.get(this.state.api + 'event-manager')
      .then(response => {
        if (response.data.success) {
          console.log(response.data.data)
          this.setState({
            isPageLoading: false,
            events: response.data.data
          })
        } else {
          this.setState({
            isPageLoading: false,
            isEmptyData: true
          })
        }
      })
      .catch(error => {
        this.setState({
          isPageLoading: false,
          isRequestError: true
        })
        console.log(error.message)
      })
  }

  eventNameFormatter = (cell, row) => {
    return (
      <span type="button" className="event_name_link_modal fullname-link" id="pFullname" data-toggle="modal" data-target="#showEventModal" onClick={(e) => this.getEventInfos(row, e)}>
        {cell}
      </span>
    )
  }

  statusFormatter = (cell, row) => {
    if (cell === 0) {
      return (
        <span className="label label-success success-item"> en cours </span>
      )
    } else {
      return (
        <span className="label label-danger danger-item"> clôturé </span>
      )
    }
  }

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button type="button" className="btn btn-info" id="btn_assign" data-toggle="modal" data-target="#showEventModal" onClick={(e) => this.getEventInfos(row, e)}>
          <i className="fas fa-eye"></i>
        </button> &nbsp;
        <button type="button" className="btn btn-warning" id="btn_update" data-toggle="modal" data-target="#editEventModal" onClick={(e) => this.getEventInfos(row, e)}>
            <i className="fas fa-edit"></i>
        </button>
      </div>
    );
  }

  imgLinkFormatter = (cell, row) => {
    if(cell !== null){
      return (
        <a href={cell} className="img_link" target="_blank" rel="noopener noreferrer">
          <span className="label label-success"> Voir l'image </span>
        </a>
      )
    }else{
      return(
        <span className="label label-warning"> Aucune image </span>
      )
    }
  }

  getEventInfos = (event) => {
    this.setState({
      event: event,
      eventID: event.id
    })
    console.log(this.state.event)
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
            className={ `btn btn-primary volkeno-red-bcolor` }>
            MyCustomBtn
          </button> */}
      </ButtonGroup>
    );
  }

  /**
   * Handle add form data
   */
  handleAddEvent = (e) => {
    let fields = this.state.fields;
    fields["admin_id"] = this.state.adminID;

    if(e.target.name === 'event_img'){
      fields["event_img"] = e.target.files[0]
    }else{
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields
    })

    console.log(fields)
  }

  // createImage(file) {
  //   let reader = new FileReader();
  //   reader.onload = (e) => {
  //     this.setState({
  //       image: e.target.result
  //     })
  //   };
  //   console.log(this.state.image)
  //   reader.readAsDataURL(file);
  // }

  /**
   * Submit add form data
   */
  submitNewEvent = (e) => {
    this.setState({
      addEventInProgress: true
    })

    let data = this.state.fields;
    const fd = new FormData();
    fd.append('admin_id', data.admin_id);
    fd.append('event_name', data.event_name);
    fd.append('event_description', data.event_description);
    fd.append('event_start_date', data.event_start_date);
    fd.append('event_end_date', data.event_end_date);
    fd.append('event_subscribe_link', data.event_subscribe_link);
    fd.append('event_img', data.event_img);
    console.log(fd)
    axios.post(this.state.api + 'event-manager', fd)
      .then(res => {
        if(res.data.success){
          this.onGetEvents();
          $('.close').click();
          $('.modal-backdrop').removeClass('modal-backdrop');
          $('.fade').removeClass('fade')
          $('.in').removeClass('in')
          this.setState({
            addEventInProgress: false,
            isEventAddSuccessAlert: true,
            fields: {}
          })
          this.handleShowAndHideAlert('isEventAddSuccessAlert')
        }else{
          console.log(res)
          this.setState({
            addEventInProgress: false,
            isAddEventRequestError: true
          })
          this.handleShowAndHideAlert('isAddEventRequestError')
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          addEventInProgress: false,
          isAddEventRequestError: true
        })
        this.handleShowAndHideAlert('isAddEventRequestError')
      })
  }

  handleUpdateEvent = (e) => {
    let fields = this.state.uFields;
    fields["admin_id"] = this.state.adminID;
    

    if(e.target.name === 'event_img'){
      fields["event_img"] = e.target.files[0]
    }else{
      fields[e.target.name] = e.target.value;
    }

    if (e.target.name === 'event_name') {
      this.setState({
          event: {event_name: e.target.value}
      })
    }
    if (e.target.name === 'event_description') {
      this.setState({
          event: {event_description: e.target.value}
      })
    }
    if (e.target.name === 'event_start_date') {
      this.setState({
          event: {event_start_date: e.target.value}
      })
    }
    if (e.target.name === 'event_end_date') {
      this.setState({
          event: {event_end_date: e.target.value}
      })
    }
    if (e.target.name === 'event_subscribe_link'){
      this.setState({
        event: {event_subscribe_link: e.target.value}
      })
    }

    if (!fields["event_name"]) {
        fields["event_name"] = this.state.event.event_name;
    }
    if (!fields["event_description"]) {
      fields["event_description"] = this.state.event.event_description;
    }
    if (!fields["event_start_date"]) {
      fields["event_start_date"] = this.state.event.event_start_date;
    }
    if (!fields["event_end_date"]) {
      fields["event_end_date"] = this.state.event.event_end_date;
    }
    if(!fields["event_subscribe_link"]){
      fields["event_subscribe_link"] = this.state.event.event_subscribe_link;
    }
    if (!fields["event_img"]) {
      this.setState({
        event: {event_img_url: this.state.event.event_img_url}
      })
    }
    this.setState({
      uFields: fields
    })

    console.log(fields)
  }

  submitUpdateEvent = (e) => {
    this.setState({
      updateEventInProgress: true
    })

    //console.log(this.state.uFields)

    
    let data;

    if(this.state.uFields.event_img === undefined){
      data = this.state.uFields;
    }else{
      console.log(this.state.uFields)
      data = new FormData();
      data.append('admin_id', this.state.uFields.admin_id);
      data.append('event_name', this.state.uFields.event_name);
      data.append('event_description', this.state.uFields.event_description);
      data.append('event_start_date', this.state.uFields.event_start_date);
      data.append('event_end_date', this.state.uFields.event_end_date);
      data.append('event_subscribe_link', this.state.uFields.event_subscribe_link);
      data.append('event_img', this.state.uFields.event_img);
    }
    console.log(data)
    axios.post(this.state.api + 'update-event-manager-item/' + this.state.eventID, data)
      .then(res => {
        if(res.data.success){
          this.onGetEvents();
          $('.close').click();
          $('.modal-backdrop').removeClass('modal-backdrop');
          $('.fade').removeClass('fade')
          $('.in').removeClass('in')
          this.setState({
            updateEventInProgress: false,
            isEventUpdateSuccessAlert: true
          })
          this.handleShowAndHideAlert('isEventUpdateSuccessAlert')
        }else{
          console.log(res.data.message)
          this.setState({
            updateEventInProgress: false,
            isUpdateEventRequestError: true
          })
          this.handleShowAndHideAlert('isUpdateEventRequestError')
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          updateEventInProgress: false,
          isUpdateEventRequestError: true
        })
        this.handleShowAndHideAlert('isUpdateEventRequestError')
      })
  }

  /**
   * Hide alert box after 5s
   */
  handleShowAndHideAlert(label) {
    setTimeout(() => {
        if (label === 'isEventAddSuccessAlert') {
            this.setState({
                isEventAddSuccessAlert: false
            });
        }
        if (label === 'isAddEventRequestError') {
            this.setState({
                isAddEventRequestError: false
            });
        }
        if (label === 'isEventUpdateSuccessAlert') {
          this.setState({
            isEventUpdateSuccessAlert: false
          });
      }
      if (label === 'isUpdateEventRequestError') {
          this.setState({
            isUpdateEventRequestError: false
          });
      }
    }, 5000);
}

subscribeLinkFormatter = (cell, row) => {
  if(cell !== null){
    return(
      <a className="btn btn-success cliquable-btn" href={cell} target="_blank" rel="noopener noreferrer"><i className="fas fa-link"></i></a>
    )
  }else{
    return(
      <span className="label label-warning linky_warning"> aucun lien d'inscription </span>
    )
  }
}


  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-event-manager">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Event Manager
                          </h1>
              <ol className="breadcrumb">
                <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Home</NavLink></li>
                <li className="active">Event Manager</li>
              </ol>
            </section>

            <section className="content">
              <div className="row add_coach_btn_row">
                <div className="col-md-6 col-md-offset-3 add-coach-btn-container">
                  <button type="button" className="btn btn-info addBtn button" id="btn_assign" data-toggle="modal" data-target="#addEventModal" >
                    <i className="fas fa-plus-circle"></i>
                  </button>
                </div>
              </div>
              <div className="event_loading_container">
                <div className="table_container">
                  <table className="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Événement</th>
                        <th scope="col">Description</th>
                        <th scope="col">Date de début</th>
                        <th scope="col">Date de fin</th>Date
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

          </div>
        </div>
      );
    }
    return (
      <div className="component-event-manager">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>
              Event Manager
                        </h1>
            <ol className="breadcrumb">
              <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Home</NavLink></li>
              <li className="active">Event Manager</li>
            </ol>
          </section>
{/* section navlink */}
{/* <section className="content"> */}
              {/* Blocs contain bakelistes delay list and absence list */}
              <div className="row tab-data-row">
                <div className="col-md-12 tab-data-col">
                  <div className="col-md-12 reporting-container">
                    <div className="list_prospect_container">
                      <div className="row prospect-row-row">
                        <div className="col-md-12 prospect-col">
                          <div className="nav-tabs-custom">
                            <div className="col-md-12 da-list-col">
                              <ul className="nav nav-tabs">
                                <li className=""><NavLink to="/liste-participants-kokutana2019">Kokutuna</NavLink></li>
                                <li className="active"><NavLink to="/event-manager">Event manager</NavLink></li>
                                <li className=""><NavLink to="/meetings">Rencontre</NavLink></li>
                                {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                              </ul>
                            </div>
                              {/* end table */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                  </div>
                </div>
                {/* </section> */}
{/* end section navlink */}
          <section className="content">
            <div className="row add_coach_btn_row">
              <div className="col-md-6 col-md-offset-3 add-coach-btn-container">
                <button type="button" className="btn btn-info add_tache_btn addBtn button" id="btn_assign" data-toggle="modal" data-target="#addEventModal" >
                  <i className="fas fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div className="row event-table-row">
              <div className="col-md-12 event-table-col">
                <BootstrapTable data={this.state.events}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  exportCSV
                  pagination
                  search={true}>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn width='250' filter={{ type: 'TextFilter', delay: 1000 }} dataField='event_name' dataFormat={this.eventNameFormatter.bind(this)} >Événement</TableHeaderColumn>
                  <TableHeaderColumn width='300' dataField='event_description'>Description</TableHeaderColumn>
                  <TableHeaderColumn width='250' filter={{ type: 'TextFilter', delay: 1000 }} dataField='startFrenchDate'>Début campagne</TableHeaderColumn>
                  <TableHeaderColumn width='250' filter={{ type: 'TextFilter', delay: 1000 }} dataField='endFrenchDate'>Fin campagne</TableHeaderColumn>
                  <TableHeaderColumn width='200' dataField='event_subscribe_link' dataFormat={this.subscribeLinkFormatter.bind(this)}>Lien d'inscription</TableHeaderColumn>
                  <TableHeaderColumn width='150' dataField='event_img_url' dataFormat={this.imgLinkFormatter.bind(this)}>Image de com</TableHeaderColumn>
                  <TableHeaderColumn width='200' filter={{ type: 'TextFilter', delay: 1000 }} dataField='is_completed' dataFormat={this.statusFormatter.bind(this)}>Statut</TableHeaderColumn>
                  <TableHeaderColumn width='250' dataField='id' dataFormat={this.actionsFormatter.bind(this)}>Actions</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          </section>

        </div>

        {/* Show event infos modal */}
        <div className="modal fade" id="showEventModal" tabIndex="-1" role="dialog" aria-labelledby="showEventModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showEventModalLabel">Detail sur {this.state.event.event_name} </h4>
              </div>
              <div className="modal-body">
                {this.state.event.event_img_url !== null ?
                  <div className="item_container">
                    <img className="event_img img-responsive" src={this.state.event.event_img_url} alt={this.state.event.event_name} />
                    <hr />
                  </div>  : ''
                }
                <div className="item_container">
                  <span className="item_label">Description : </span> <br />
                  <span className="label_content"> {this.state.event.event_description} </span>
                </div> <hr />
                <div className="item_container">
                  <span className="item_label">Date de début de la campagne : </span> <br />
                  <span className="label_content"> {this.state.event.startFrenchDate} </span>
                </div> <hr />

                <div className="interview-details-participant">
                  <div className="item_container">
                    <span className="item_label">Date de fin de la campagne : </span> <br />
                    <span className="label_content"> {this.state.event.endFrenchDate} </span>
                  </div> <hr />
                  <div className="item_container">
                    <span className="item_label">Statut de la campagne : </span> <br />
                    {this.state.event.is_completed === 0 ?
                      <span className="label_content label label-success"> en cours </span> :
                      <span className="label_content label label-danger"> terminée </span>
                    }
                  </div> <hr />
                  {this.state.event.event_subscribe_link !== null ?
                    <div className="linky-container row">
                      <div className="col-md-4 col-md-offset-4 linky-btn">
                        <a href={this.state.event.event_subscribe_link} target="_blank" className="btn btn-default subscribe_link" rel="noopener noreferrer">
                          Lien pour postuler
                        </a>
                      </div>
                    </div> :
                    <div className="alert alert-warning subscribe_empty_link_alert">rel="noopener noreferrer"
                      Cet event n'a pas de lien d'inscription
                    </div>
                  }
                </div>

                <div className="modal-footer">

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add event modal */}
        <div className="modal fade" id="addEventModal" tabIndex="-1" role="dialog" aria-labelledby="addEventModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="addEventModalLabel">Ajouter un event </h4>
              </div>
              <form id="add-event-form">
                <div className="modal-body">
                  {/* Form here */}
                  <div className="row add_event_form_container">
                    <div className="col-md-12 input_item_container">
                      <div className="form-group">
                        <label>Nom de l'événement</label>
                        <input type="text" className="form-control input_item" placeholder="Titre de l'événement" name="event_name" onChange={this.handleAddEvent} />
                      </div>
                    </div>
                    <div className="col-md-12 input_item_container">
                      <div className="form-group">
                        <label>Description de l'événement</label>
                        <textarea className="form-control input_item" name="event_description" onChange={this.handleAddEvent} />
                      </div>
                    </div>
                    <div className="col-md-6 input_item_container">
                      <div className="form-group">
                        <label>Date de début de la campagne</label>
                        <input type="date" className="form-control input_item" name="event_start_date" onChange={this.handleAddEvent} />
                      </div>
                    </div>
                    <div className="col-md-6 input_item_container">
                      <div className="form-group">
                        <label>Date de fin de la campagne</label>
                        <input type="date" className="form-control input_item" name="event_end_date" onChange={this.handleAddEvent} />
                      </div>
                    </div>
                    <div className="col-md-12 input_item_container">
                      <div className="form-group">
                        <label>Lien d'inscription</label>
                        <input type="text" className="form-control input_item" placeholder="Lien d'inscription" name="event_subscribe_link" onChange={this.handleAddEvent} />
                      </div>
                    </div>
                    <div className="col-md-12 input_item_container">
                      <div className="form-group">
                        <label>Image de com associée à l'événement</label>
                        <input type="file" className="form-control input_item" name="event_img" onChange={this.handleAddEvent} />
                      </div>
                    </div>
                  </div>
                  {this.state.isAddEventRequestError &&
                      <div className="row error-row">
                        <div className="col-md-12 error-container">
                          <div className="alert alert-danger adding-error-alert">
                            Un problème est survénu lors de l'ajout de l'event. Veuillez réessayer plus tard !
                          </div>
                        </div>
                      </div>
                    }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                  {!this.state.addEventInProgress &&
                    <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.submitNewEvent(e)}>
                        <i className="fas fa-user-check"></i>&nbsp;
                        Ajouter
                    </button>
                  }
                  {this.state.addEventInProgress &&
                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                        Ajout en cours &nbsp;
                        <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Update event modal */}
        <div className="modal fade" id="editEventModal" tabIndex="-1" role="dialog" aria-labelledby="editEventModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="editEventModalLabel">Modifier un event </h4>
              </div>
              <form id="add-event-form">
                <div className="modal-body">
                  {/* Form here */}
                  <div className="row add_event_form_container">
                    <div className="col-md-12 input_item_container">
                      <div className="form-group">
                        <label>Nom de l'événement</label>
                        <input type="text" className="form-control input_item" value={this.state.event.event_name}  placeholder="Titre de l'événement" name="event_name" onChange={this.handleUpdateEvent} />
                      </div>
                    </div>
                    <div className="col-md-12 input_item_container">
                      <div className="form-group">
                        <label>Description de l'événement</label>
                        <textarea className="form-control input_item" value={this.state.event.event_description} name="event_description" onChange={this.handleUpdateEvent} />
                      </div>
                    </div>
                    <div className="col-md-6 input_item_container">
                      <div className="form-group">
                        <label>Date de début de la campagne</label>
                        <input type="date" className="form-control input_item" value={this.state.event.debut} name="event_start_date" onChange={this.handleUpdateEvent} />
                      </div>
                    </div>
                    <div className="col-md-6 input_item_container">
                      <div className="form-group">
                        <label>Date de fin de la campagne</label>
                        <input type="date" className="form-control input_item" value={this.state.event.fin} name="event_end_date" onChange={this.handleAddEvent} />
                      </div>
                    </div>
                    <div className="col-md-12 input_item_container">
                      <div className="form-group">
                        <label>Lien d'inscription</label>
                        <input type="text" className="form-control input_item" value={this.state.event.event_subscribe_link} placeholder="Lien d'inscription" name="event_subscribe_link" onChange={this.handleUpdateEvent} />
                      </div>
                    </div>
                    <div className="col-md-12 input_item_container">
                      <div className="form-group">
                        <label>Image de com associée à l'événement</label>
                        <input type="file" className="form-control input_item" name="event_img" onChange={this.handleUpdateEvent} />
                      </div>
                      {this.state.event.event_img_url !== null ?
                        <div className="exit-img-container item-container">
                          <label><b>Image existante : </b></label>
                          <img className="event_img img-responsive" src={this.state.event.event_img_url} alt={this.state.event.event_name} />
                        </div> :
                        <div className="alert alert-warning empty-img-alert">
                          Aucune image n'est pour le moment associé à cet event. Chargez une image sinon laissez ce champ vide.
                        </div>
                      }
                    </div>
                  </div>
                  {this.state.isUpdateEventRequestError &&
                      <div className="row error-row">
                        <div className="col-md-12 error-container">
                          <div className="alert alert-danger adding-error-alert">
                            Un problème est survénu lors de l'ajout de l'event. Veuillez réessayer plus tard !
                          </div>
                        </div>
                      </div>
                    }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                  {!this.state.updateEventInProgress &&
                    <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={(e) => this.submitUpdateEvent(e)}>
                        <i className="fas fa-user-check"></i>&nbsp;
                        Modifier
                    </button>
                  }
                  {this.state.updateEventInProgress &&
                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                        Update en cours &nbsp;
                        <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>


        {/* add event success toast */}
        {this.state.isEventAddSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                Event ajouté avec succès.
            </div>
        }

        {/* update event success toast */}
        {this.state.isEventUpdateSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                Event mmodifié avec succès.
            </div>
        }

      </div>
    );
  }
}