import React, { Component } from "react";
import "./viewforResetPassword.css";
//import { Link } from 'react-router-dom';
import axios from "axios";
import API from "../../variablesGlobales";

export default class viewforResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.sendingEmailForResetPassword = this.sendingEmailForResetPassword.bind(
      this
    );
    this.state = {
      api: API.API,
      showForm: true,
      fields: {},
      isPending: false,
      requestError: false,
    };
  }

  componentDidMount() {
    let x = document.getElementById("page-wrapper");
    x.style.marginLeft = 0;
  }

  handleChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };

  sendingEmailForResetPassword = (e) => {
    e.preventDefault();

    this.setState({
      isPending: true,
    });

    let emailData = this.state.fields;

    axios
      .post(this.state.api + "send-reset-password", emailData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            isPending: false,
            showForm: false,
          });
        } else {
          this.setState({
            isPending: false,
            requestError: true,
          });
          console.log(response);
        }
      });
  };

  render() {
    return (
      <div className="component-viewfor-reset-password">
        <section className="col-md-6 col-md-offset-3">
          <div className="logo_container">
            <img
              className="img-responsive logo_bakeli"
              src={require("../../images/logo_bak.png")}
              alt="Logo Bakeli"
            />
          </div>

          <h2 className="color1 connect-libelle">
            MODIFIER VOTRE MOT DE PASSE
          </h2>
          {this.state.showForm ? (
            <div className="reset-form-container">
              <form
                className="positionForm"
                onSubmit={this.sendingEmailForResetPassword}
              >
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Votre email"
                    name="email"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  {!this.state.isPending && (
                    <button className="btn btn-warning submitLoginBtn button">
                      <i class="fas fa-at"></i> &nbsp; Envoyer
                    </button>
                  )}
                  {this.state.isPending && (
                    <button className="btn btn-primary pendingLoginBtn button">
                      Envoie en cours &nbsp;
                      <i class="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          ) : (
            <div className="success-reset-form-container">
              <div className="alert alert-success success-message">
                <p>
                  Nous venons de vous envoyer un email dans lequel vous
                  trouverez le lien qui vous permettra de modifier votre mot de
                  passe.
                </p>
                <p>
                  Si vous ne voyez le message dans votre boîte de réception,
                  nous vous suggérons de consulter vos spams
                </p>
              </div>
            </div>
          )}
        </section>
      </div>
    );
  }
}
