import React, { Component } from 'react';
import './syllabusContentManageView.css';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import axios from 'axios';
import API from '../../variablesGlobales';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import { MagicSpinner } from "react-spinners-kit";
import * as Sentry from '@sentry/browser';
import MultiSelect from "@khanacademy/react-multi-select";

export default class SyllabusContentManageView extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem('token');
    let userStatus = window.sessionStorage.getItem('user_status');
    // let userID = window.sessionStorage.getItem('user_id');

    if (!token) {
      window.location = '/';
    }
    if (userStatus === 'bakeliste') {
      window.history.back();
    }
    super(props);
    this.state = {
      api: API.API,
      isPageLoading: true,
      userID: window.sessionStorage.getItem('user_id'),
      syllabusDatas: [],
      syllabusInfos: [],
      addSyllabusContentInProgress: false,
      syllabusID: 0,
      fields: {},
      errors: {},
      addSuccessDetailsAlert: false,
      addErrorDetailsAlert: false,
      sskills_empty: false,
      stask_empty: false,
      surl_empty: false,
      sdescription_empty: false,
      scompletiontime_empty: false,
      sweeknumber_empty: false,
      dsInfos: [],
      upInfos: [],
      sSkillValue: '',
      sTaskValue: '',
      sUrlValue: '',
      sDescriptionValue: '',
      sCompletionTimeValue: 0,
      sCompletionWeek: 0.0,
      sWeekNumber: 0,
      editSyllabusContentInProgress: false,
      editSuccessDetailsAlert: false,
      editErrorDetailsAlert: false,
      _fields: {},
      selectOptions: [],
      selected: [],
      updateTacheStatusSuccess: false,
      updateTacheRequestSuccessAlert: false,
      updateTacheRequestError: false,
      
    };

    this.addDetailsHandleChange = this.addDetailsHandleChange.bind(this);
    this.onSubmitDetailsSyllabus = this.onSubmitDetailsSyllabus.bind(this);
    this.getTacheID = this.getTacheID.bind(this);
    this.updateTacheBySyllabus = this.updateTacheBySyllabus.bind(this);

    this.editDetailsHandleChange = this.editDetailsHandleChange.bind(this);
    this.onSubmitEditDetailsSyllabus = this.onSubmitEditDetailsSyllabus.bind(this);
  }

  componentDidMount = () => {
    const { match: { params } } = this.props;
    this.setState({
      syllabusID: params.syllabus_id
    })
    //console.log(params)
    this.onGetSyllabusInfos(params.syllabus_id);
    this.onGetDetailsBySyllabus(params.syllabus_id);

    // setTimeout(() => {
    //   this.setState({
    //     isPageLoading: false
    //   })
    // }, 5000)

  }

  handleShowAndHideAlert(label) {
    setTimeout(() => {
        
        if(label === 'successStatusUpdate'){
            this.setState({
                updateTacheStatusSuccess: false
            })
        }
        if(label === 'tacheRequestSuccess'){
            this.setState({
                updateTacheRequestSuccessAlert: false
            })
        }
        if(label === 'tacheRequestError'){
            this.setState({
                updateTacheRequestError: false
            })
        }
        if(label === 'errorStatusUpdate'){
            this.setState({
                isUpdatedError: false,
                updateTacheStatusError: false
            })
        }
    }, 5000);
}


  updateTacheBySyllabus = (e) => {
    e.preventDefault();

    this.setState({
        tacheUpdatedInProgress: true
    })
    let tacheID = window.sessionStorage.getItem('btache_id');
    //console.log(tacheID)
    let updateData = this.state.tacheStatusUpdateField;

    axios.post(this.state.api + 'update-tache-status-by-bakeliste/' + tacheID, updateData)
        .then(response => {
            if (response.data.success === true) {
                //this.getAllTaches();
                this.onGetDetailsBySyllabus();
                //this.getTachesAssignedByCoach();
                $('.close').click();
                document.getElementById('update-tache-status-bybakeliste').reset();
                this.setState({
                    tacheUpdatedInProgress: false,
                    updateTacheStatusSuccess: true
                })

                this.handleShowAndHideAlert('successStatusUpdate');
                
            }else {
                this.setState({
                    isUpdatedError: true,
                    tacheUpdatedInProgress: false,
                    updateTacheStatusError: true
                })

                this.handleShowAndHideAlert('errorStatusUpdate');
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({
                isUpdatedError: true,
                tacheUpdatedInProgress: false,
                updateTacheStatusError: true
            })
            this.handleShowAndHideAlert('errorStatusUpdate');
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}

  onGetSyllabusInfos = (syllabus_id) => {
    var baseApiUrl = this.state.api;

    axios.get(baseApiUrl + 'syllabus/' + syllabus_id)
      .then(response => {
        if (response.data.success) {
          //console.log(response.data.data)
          this.setState({
            syllabusInfos: response.data.data
          })
          //console.log(this.state.syllabusInfos)
        }
      })
      .catch(error => {
        console.log(error.message)
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  onGetDetailsBySyllabus = (syllabus_id) => {
    this.setState({
      isPageLoading: true
    })

    var baseApiUrl = this.state.api;

    axios.get(baseApiUrl + 'details-by-syllabus/' + syllabus_id)
      .then(response => {
        this.setState({
          isPageLoading: false
        })

        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            syllabusDatas: response.data.data
          })
        }
        console.log(this.state.syllabusDatas)
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isPageLoading: false
        })
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  onRowClick = (row) => {
    this.setState({
      dsInfos: row
    })
    $('.open-infos-modal').click();
  }

  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === 'addSyllabusContentInProgress') {
        this.setState({
          addSyllabusContentInProgress: false
        })
      }
      if (label === 'addSuccessDetailsAlert') {
        this.setState({
          addSuccessDetailsAlert: false
        })
      }
      if (label === 'addErrorDetailsAlert') {
        this.setState({
          addErrorDetailsAlert: false
        })
      }
      if (label === 'sskills_empty') {
        this.setState({
          sskills_empty: false
        })
      }
      if (label === 'stask_empty') {
        this.setState({
          stask_empty: false
        })
      }
      if (label === 'surl_empty') {
        this.setState({
          surl_empty: false
        })
      }
      if (label === 'sdescription_empty') {
        this.setState({
          sdescription_empty: false
        })
      }
      if (label === 'scompletiontime_empty') {
        this.setState({
          scompletiontime_empty: false
        })
      }
      if (label === 'sweeknumber_empty') {
        this.setState({
          sweeknumber_empty: false
        })
      }
      if (label === 'editSuccessDetailsAlert') {
        this.setState({
          editSuccessDetailsAlert: false
        })
      }
      if (label === 'editErrorDetailsAlert') {
        this.setState({
          editErrorDetailsAlert: false
        })
      }
    }, 5000)
  }

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = {};
    var isValidForm = true;

    if (!fields["syllabus_skills"]) {
      isValidForm = false;
      this.setState({
        sskills_empty: true
      })
      this.onSwitchAlertStatus('sskills_empty');
      errors["sskills_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_task"]) {
      isValidForm = false;
      this.setState({
        stask_empty: true
      })
      this.onSwitchAlertStatus('stask_empty');
      errors["stask_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["tache_url"]) {
      isValidForm = false;
      this.setState({
        surl_empty: true
      })
      this.onSwitchAlertStatus('surl_empty');
      errors["surl_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["description"]) {
      isValidForm = false;
      this.setState({
        sdescription_empty: true
      })
      this.onSwitchAlertStatus('sdescription_empty');
      errors["sdescription_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_completion_time"]) {
      isValidForm = false;
      this.setState({
        scompletiontime_empty: true
      })
      this.onSwitchAlertStatus('scompletiontime_empty');
      errors["scompletiontime_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_week_number"]) {
      isValidForm = false;
      this.setState({
        sweeknumber_empty: true
      })
      this.onSwitchAlertStatus('sweeknumber_empty');
      errors["sweeknumber_empty"] = "*Ce champ est obligatoire";
    }

    this.setState({
      errors: errors
    })

    return isValidForm;
  }

  addDetailsHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.syllabusID;
    fields["created_by"] = this.state.userID

    if (e.target.name === 'syllabus_completion_time') {
      var sct = e.target.value;
      var week = (sct / 45).toFixed(1)
      fields["syllabus_completion_week"] = week;
    }

    this.setState({
      fields: fields
    })
    console.log(fields)
  }

  onSubmitDetailsSyllabus = (e) => {
    e.preventDefault();

    if (this.onValidateFormData()) {
      this.setState({
        addSyllabusContentInProgress: true
      })

      var data = this.state.fields;
      var baseApiUrl = this.state.api;

      axios.post(baseApiUrl + 'details-syllabus', data)
        .then(response => {
          this.setState({
            addSyllabusContentInProgress: true
          })

          if (response.data.success) {
            this.onGetDetailsBySyllabus(this.state.syllabusID);
            //$('.close').click();
            // $('#addSyllabusContentModal').modal('hide');
            this.setState({
              addSuccessDetailsAlert: true
              
            })
            this.onSwitchAlertStatus('addSuccessDetailsAlert')
            window.location.reload();
          } else {
            console.log(response);
            this.setState({
              addErrorDetailsAlert: true
            })
            this.onSwitchAlertStatus('addErrorDetailsAlert')
          }
        })
        .catch(error => {
          console.log(error.message);
          this.setState({
            addSyllabusContentInProgress: false,
            addErrorDetailsAlert: true
          })
          this.onSwitchAlertStatus('addErrorDetailsAlert')
          if (process.env.NODE_ENV !== 'production') {
            return;
          }
          Sentry.captureException(error);
        })
    }
  }

  weekNumberFormatter = (cell, row) => {
    return (
      <span className="semaine_number_container"> Semaine {cell} </span>
    )
  }

  completionWeekFormatter = (cell, row) => {
   
    return (
      <span className="semaine_completion_container"> {cell + ' semaine(s)'} </span>
    )
  }

  getDetailsInfos = (item) => {
    this.setState({
      upInfos: item,
      dsInfos: item,
      sSkillValue: item.syllabus_skills,
      sTaskValue: item.syllabus_task,
      sUrlValue: item.tache_url,
      sDescriptionValue: item.description,
      sCompletionTimeValue: item.syllabus_completion_time,
      sCompletionWeek: item.syllabus_completion_week,
      sWeekNumber: item.syllabus_week_number,
    })
  }

  actionsFormatter = (cell, row) => {
    return(
      <div className="actions-btn-container">
        <button data-toggle="modal" data-target="#detailsInfosModal" className="action-btn-item show_icon" onClick={(e) => this.getDetailsInfos(row)}>
          <img src="https://img.icons8.com/material-rounded/20/FFFFFF/visible.png" className="actions_icon" alt="Visualiser" title="Visualiser"/>
        </button> 
        <button data-toggle="modal" data-target="#editDetailsSyllabusModal" className="action-btn-item edit_icon" onClick={(e) => this.getDetailsInfos(row)}>
          <img src="https://img.icons8.com/ios-glyphs/20/FFFFFF/edit.png" className="actions_icon" alt="Modifier" title="Modifier"/>
        </button>
           {/* task execution modal */}
        {/* <button data-toggle="modal" data-target="#taskExecutionModal" className="action-btn-item show_icon" >
        <img src="https://img.icons8.com/ios-glyphs/20/FFFFFF/ok.png" className="actions_icon" alt="Choisir la tache" title="Choisir la tache"/>
        </button> */}
      </div>
    )
  }

  editDetailsHandleChange = (e) => {
    let fields = this.state._fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.syllabusID;
    fields["created_by"] = this.state.userID

    if (e.target.name === 'syllabus_completion_time') {
      var sct = e.target.value;
      var week = (sct / 45).toFixed(1)
      fields["syllabus_completion_week"] = week;
    }else{
      fields["syllabus_completion_week"] = this.state.sCompletionWeek;
    }

    if (e.target.name === 'syllabus_skills') {
      this.setState({
          sSkillValue: e.target.value
      })
    }
    if (e.target.name === 'tache_url') {
      this.setState({
          sUrlValue: e.target.value
      })
    }
    if (e.target.name === 'description') {
      this.setState({
          sDescriptionValue: e.target.value
      })
    }
    if (e.target.name === 'syllabus_task') {
      this.setState({
          sTaskValue: e.target.value
      })
    }
    if (e.target.name === 'syllabus_completion_time') {
      this.setState({
          sCompletionTimeValue: e.target.value
      })
    }
    if (e.target.name === 'syllabus_week_number') {
      this.setState({
          sWeekNumber: e.target.value
      })
    }

    if (!fields["syllabus_skills"]) {
      fields["syllabus_skills"] = this.state.sSkillValue;
    }
    if (!fields["syllabus_task"]) {
      fields["syllabus_task"] = this.state.sTaskValue;
    }
    if (!fields["tache_url"]) {
      fields["tache_url"] = this.state.sUrlValue;
    }
    if (!fields["description"]) {
      fields["description"] = this.state.sDescriptionValue;
    }
    if (!fields["syllabus_completion_time"]) {
      fields["syllabus_completion_time"] = this.state.sCompletionTimeValue;
    }
    if (!fields["syllabus_week_number"]) {
      fields["syllabus_week_number"] = this.state.sWeekNumber;
    }

    this.setState({
      _fields: fields
    })
    console.log(fields)
  }

  onSubmitEditDetailsSyllabus = (e) => {
    e.preventDefault()
    this.setState({
      addSyllabusContentInProgress: true
    })

    var data = this.state._fields;
    var baseApiUrl = this.state.api;
    var detailsID = this.state.upInfos.id;

    axios.put(baseApiUrl + 'details-syllabus/' + detailsID, data)
      .then(response => {
        this.setState({
          editSyllabusContentInProgress: true
        })

        if (response.data.success) {
          this.onGetDetailsBySyllabus(this.state.syllabusID);
          //$('.close').click();
          //$('#editSyllabusContentModal').modal('hide');
          this.setState({
            editSuccessDetailsAlert: true
          })
          this.onSwitchAlertStatus('editSuccessDetailsAlert')
          window.location.reload()
        } else {
          console.log(response);
          this.setState({
            editErrorDetailsAlert: true
          })
          this.onSwitchAlertStatus('editErrorDetailsAlert')
        }
      })
      .catch(error => {
        console.log(error.message);
        this.setState({
          editSyllabusContentInProgress: false,
          editErrorDetailsAlert: true
        })
        this.onSwitchAlertStatus('addErrorDetailsAlert')
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  
  }


  getTacheInfos(tache_id) {
    this.setState({
      tache_id: tache_id,
    });
  }

  tacheStateFormatter(cell, row) {
    return (
        <span>
            {row.state === 'todo' &&
                <span className="label label-danger" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#stateModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
            }
            {row.state === 'doing' &&
                <span className="label label-warning" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#stateModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
            }
            {row.state === 'done' &&
                <span className="label label-success" data-placement="top" title="Modifier le statut de la tâche" data-toggle="modal" data-target="#stateModal" onClick={() => this.getTacheID(row)}>{row.state}</span>
            }
        </span>
    );
}
getTacheID = (tache) => {
  //console.log(tache.id)
  window.sessionStorage.setItem('btache_id', tache.id);
}
  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
      noDataText: 'Aucune donnée pour le moment sur ce syllabus'
    };
   /*  const { bakeliste_id, syllabus_id, detail_syllabus_id, task_status, skills_is_acquired } = this.state;
    const values = { bakeliste_id, syllabus_id, detail_syllabus_id, task_status, skills_is_acquired };
 */
    if (this.state.isPageLoading) {
      return (
        <div className="component-syllabus-content-manage-view">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Syllabus : {this.state.syllabusInfos.syllabus_name}
              </h1>
              <ol className="breadcrumb">
                <li><NavLink to="/dashboard-admin"> Accueil</NavLink></li>
                <li><NavLink to="/syllabus-manager"> Syllabus Manager</NavLink></li>
                <li className="active">Syllabus : {this.state.syllabusInfos.syllabus_name} </li>
              </ol>
            </section>

            <section className="content">
              <div className="loader-row row">
                <div className="col-md-4 col-md-offset-4 loader-container">
                  <MagicSpinner size={150} color="#009688" loading={this.state.isPageLoading} />
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }

    return (
      <div className="component-syllabus-content-manage-view">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>
              Syllabus : {this.state.syllabusInfos.syllabus_name}
            </h1>
            <ol className="breadcrumb">
              <li><NavLink to="/dashboard-admin"> Accueil</NavLink></li>
              <li><NavLink to="/syllabus-manager"> Syllabus Manager</NavLink></li>
              <li className="active">Syllabus : {this.state.syllabusInfos.syllabus_name} </li>
            </ol>
          </section>

          <section className="content">
            <div className="row add_syllabus_container">
              <div className="col-md-6 col-md-offset-3 add-btn-container">
                <button data-toggle="modal" data-target="#addSyllabusContentModal" className="btn btn-success bg-vgreen add_btn button">
                  <img src="https://img.icons8.com/ios/50/FFFFFF/add.png" alt="Ajouter" />
                </button>
              </div>
            </div>
            <div className="row scm_row">
              <div className="col-md-12 scm_table_container">
                <button className="export_pdf_btn btn btn-success bg-vgreen">
                  <img className="pdf_icon_img" src="https://img.icons8.com/material-rounded/14/FFFFFF/pdf.png" alt="PDF ICON" />
                  Export to PDF
                    </button>
                <BootstrapTable data={this.state.syllabusDatas}
                  striped={true} hover={true} condensed={true}
                  multiColumnSort={2} options={options}
                  pagination
                  exportCSV>
                  <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='id' dataFormat={this.actionsFormatter.bind(this)}>Actions</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='syllabus_week_number'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataFormat={this.weekNumberFormatter.bind(this)}
                    dataSort={true}>Semaines</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='syllabus_skills'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Compétences</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='syllabus_task'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Tâches</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='tache_url'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Lien</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='description'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Description</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='syllabus_completion_time'
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataSort={true}>Nombre d'heure</TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='syllabus_completion_week'
                    dataFormat={this.completionWeekFormatter.bind(this)}
                    dataSort={true}>Conversion en semaine</TableHeaderColumn>
                  <TableHeaderColumn dataField='state' dataFormat={this.tacheStateFormatter.bind(this)} dataSort={true}>
                    Status
                  </TableHeaderColumn>

                </BootstrapTable>
              </div>
            </div>
          </section>
        </div>

        {/* Add item in syllabus */}
        <div className="modal fade" id="addSyllabusContentModal" tabIndex="-1" role="dialog" aria-labelledby="addSyllabusContentModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="addSyllabusContentModalLabel">Ajouter tâche dans syllabus</h4>
              </div>
              <form id="add_syllabus_content_form">
                <div className="modal-body">
                  <div className="col-md-12 form_col">
                  <label>Compétences</label>
                 
                  <textarea name="syllabus_skills" placeholder="Compétences" className="form-control input_item" onChange={this.addDetailsHandleChange} id="syllabus_skills" />
                    {this.state.sskills_empty &&
                      <div className="errorMsg">{this.state.errors.sskills_empty}</div>
                    }
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_task">Tâche</label>
                      <textarea name="syllabus_task" placeholder="Contenu de la tâche" className="form-control input_item" onChange={this.addDetailsHandleChange} id="syllabus_task" />
                    </div>
                    {this.state.stask_empty &&
                      <div className="errorMsg">{this.state.errors.stask_empty}</div>
                    }
                  </div>
                  <div className="col-md-12 form_col">                    <div className="form-group as_input_container">
                      <label htmlFor="description">Description</label>
                      <textarea name="description" placeholder="ajouter une description" className="form-control input_item" onChange={this.addDetailsHandleChange} id="description" />
                    </div>
                    {this.state.sdescription_empty &&
                      <div className="errorMsg">{this.state.errors.sdescription_empty}</div>
                    }
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="tache_url">Lien associé</label>
                      <textarea name="tache_url" placeholder="ajouter un lien" className="form-control input_item" onChange={this.addDetailsHandleChange} id="tache_url" />
                    </div>
                    {this.state.surl_empty &&
                      <div className="errorMsg">{this.state.errors.surl_empty}</div>
                    }
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_completion_time">Temps de réalisation en heure</label>
                      <input type="number" name="syllabus_completion_time" placeholder="Temps de réalisation en heure" className="form-control input_item" onChange={this.addDetailsHandleChange} id="syllabus_completion_time" />
                    </div>
                    {this.state.scompletiontime_empty &&
                      <div className="errorMsg">{this.state.errors.scompletiontime_empty}</div>
                    }
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_week_number">Numéro de semaine</label>
                      <input type="number" name="syllabus_week_number" placeholder="Numéro de la semaine" className="form-control input_item" onChange={this.addDetailsHandleChange} id="syllabus_week_number" />
                    </div>
                    {this.state.sweeknumber_empty &&
                      <div className="errorMsg">{this.state.errors.sweeknumber_empty}</div>
                    }
                  </div>

                  {this.state.addErrorDetailsAlert &&
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert alert alert-danger">
                        Un problème est survenu lors de l'ajout. Veuillez réessayer plus tard.
                          </div>
                    </div>
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default bg-vred cancel-btn pull-left" data-dismiss="modal">
                    <span className="cancel_btn_label"> Quitter </span>
                    <img className="cancel-icon" src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png" alt="Annuler" />
                  </button>

                  {!this.state.addSyllabusContentInProgress ?
                    <button className="btn btn-success bg-vgreen submit_btn_container pull-right" onClick={(e) => this.onSubmitDetailsSyllabus(e)}>
                      <span className="submit_btn_label"> Ajouter </span> &nbsp;
                            <img className="submit_icon" src="https://img.icons8.com/material-rounded/24/FFFFFF/filled-sent.png" alt="Ajouter" />
                    </button> :
                    <button className="btn btn-warning bg-vorange in-progress-btn-container pull-right">
                      <span className="in_progress_btn_label"> Ajout en cours </span> &nbsp;
                            <i className="fa fa-spin fa-spinner"></i>
                    </button>
                  }

                </div>
              </form>
            </div>
          </div>
        </div>


         {/* ==== Modifier Statut ==== */}
          
                                
                                <div className="modal fade" id="stateModal" tabIndex="-1" role="dialog" aria-labelledby="stateModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <h4 className="modal-title" id="stateModalLabel">Modifier le status d'une tâche</h4>
                                            </div>
                                            <div className="modal-body">
                                                <div className="ut_form_container">
                                                    <form id="update-tache-status-bybakeliste">
                                                        <div className="form-group " >
                                                            <select className="form-control" id="tstatus" name="state" onChange={this.updateTBBHandle}>
                                                                <option>--Choisir un statut--</option>
                                                                <option value="todo">A Faire</option>
                                                                <option value="doing">En Cours</option>
                                                                <option value="done">Terminé</option>
                                                            </select>
                                                        </div>
                                                        {this.state.updateTacheStatusError &&
                                                            <div className="form-group alert alert-danger update-error">
                                                                Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                                                            </div>
                                                        }
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-danger pull-left bsi-cancel-btn" data-dismiss="modal">
                                                                <i className="fas fa-times-circle"></i> &nbsp;
                                                                Quitter
                                                            </button>
                                                            {!this.state.tacheUpdatedInProgress &&
                                                                <button onClick={(e) => this.updateTacheBySyllabus(e)} type="submit" className="btn btn-primary bsi-submit-btn pull-right">
                                                                    <i className="fas fa-edit"></i> &nbsp;
                                                                    Modifier
                                                                </button>
                                                            }
                                                            {this.state.tacheUpdatedInProgress &&
                                                                <button type="button" className="btn btn-warning bsi-inprogress-btn pull-right">
                                                                    Modification en cours &nbsp;
                                                                    <i className="fas fa-spinner fa-spin"></i>
                                                                </button>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            {/* <div className="modal-footer">

                                            </div> */}
                                        </div>
                                    </div>
                                </div>




        {/* <div className="dyn_btn_container">
          <button className="open-infos-modal" data-toggle="modal" data-target="#detailsInfosModal">Open Infos Modal</button>
        </div> */}

        <div className="modal fade" id="detailsInfosModal" tabIndex="-1" role="dialog" aria-labelledby="ratingInfosModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="ratingInfosModalLabel">Détails de la tâche</h4>
              </div>
              <div className="modal-body">
                <div className="row modal-row">
                  <div className="col-md-12 rating-item">
                    <div className="row ds-row">
                      <div className="col-md-12 item_ds_bloc">
                        <div className="item-ds-title"> Compétences à acquérir </div>
                        <div className="item-ds-infos"> {this.state.dsInfos.syllabus_skills} </div>
                      </div>
                      <div className="col-md-12 item_ds_bloc">
                        <div className="item-ds-title"> Tâche </div>
                        <div className="item-ds-infos"> {this.state.dsInfos.syllabus_task} </div>
                      </div>
                      <div className="col-md-12 item_ds_bloc">
                        <div className="item-ds-title"> Description </div>
                        <div className="item-ds-infos"> {this.state.dsInfos.description} </div>
                      </div>
                      <div className="col-md-12 item_ds_bloc">
                        <div className="item-ds-title"> Lien </div>
                        <div className="item-ds-infos"> {this.state.dsInfos.tache_url} </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial"> Délais en Heure </div>
                        <div className="item-ds-infos partial"> {this.state.dsInfos.syllabus_completion_time + ' heure(s)'} </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial"> Délais en Semaine </div>
                        <div className="item-ds-infos partial"> {this.state.dsInfos.syllabus_completion_week + ' semaine(s)'} </div>
                      </div>
                      <div className="col-md-4 item_ds_bloc">
                        <div className="item-ds-title partial"> N° de la semaine </div>
                        <div className="item-ds-infos partial"> {this.state.dsInfos.syllabus_week_number} </div>
                      </div>
                    </div>

                    {/* {this.state.ratingInfos.commentaires !== null ?
                      <div className="row comments-row">
                        <div className="col-md-12 comments-col">
                          <div className="commentaire_container">
                            <h4 className="comment-title"> Commentaire </h4>
                            <div className="comment_content">
                              {this.state.ratingInfos.commentaires}
                            </div>
                          </div>
                        </div>
                      </div> :
                      <div className="bg-vorange empty_comment_container">
                        Aucun commentaire n'a été ajouté avec cette notation.
                                </div>
                    } */}
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        
        {/* Edit syllabus details */}
        <div className="modal fade" id="editDetailsSyllabusModal" tabIndex="-1" role="dialog" aria-labelledby="editDetailsSyllabusModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="editDetailsSyllabusModalLabel">Modifier</h4>
              </div>
              <form id="edit_syllabus_content_form">
                <div className="modal-body">
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_skills">Compétences</label>
                      <textarea value={this.state.sSkillValue} onChange={this.editDetailsHandleChange} name="syllabus_skills" placeholder="Compétences à acquérir" className="form-control input_item" id="syllabus_skills" />
                    </div>
                    {/* {this.state.sskills_empty &&
                      <div className="errorMsg">{this.state.errors.sskills_empty}</div>
                    } */}
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_task">Tâche</label>
                      <textarea value={this.state.sTaskValue} onChange={this.editDetailsHandleChange} name="syllabus_task" placeholder="Contenu de la tâche" className="form-control input_item" id="syllabus_task" />
                    </div>
                    {/* {this.state.stask_empty &&
                      <div className="errorMsg">{this.state.errors.stask_empty}</div>
                    } */}
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_task">Lien</label>
                      <textarea value={this.state.sUrlValue} onChange={this.editDetailsHandleChange} name="tache_url" placeholder="lien associé" className="form-control input_item" id="tache_url" />
                    </div>
                    {/* {this.state.surl_empty &&
                      <div className="errorMsg">{this.state.errors.surl_empty}</div>
                    } */}
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="description">Description</label>
                      <textarea value={this.state.sDescriptionValue} onChange={this.editDetailsHandleChange} name="description" placeholder="Description" className="form-control input_item" id="description" />
                    </div>
                    {/* {this.state.sdescription_empty &&
                      <div className="errorMsg">{this.state.errors.sdescription_empty}</div>
                    } */}
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_completion_time">Temps de réalisation en heure</label>
                      <input type="number" value={this.state.sCompletionTimeValue} onChange={this.editDetailsHandleChange} name="syllabus_completion_time" placeholder="Temps de réalisation en heure" className="form-control input_item" id="syllabus_completion_time" />
                    </div>
                    {/* {this.state.scompletiontime_empty &&
                      <div className="errorMsg">{this.state.errors.scompletiontime_empty}</div>
                    } */}
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_week_number">Numéro de semaine</label>
                      <input type="number" value={this.state.sWeekNumber} onChange={this.editDetailsHandleChange} name="syllabus_week_number" placeholder="Numéro de la semaine" className="form-control input_item" id="syllabus_week_number" />
                    </div>
                    {/* {this.state.sweeknumber_empty &&
                      <div className="errorMsg">{this.state.errors.sweeknumber_empty}</div>
                    } */}
                  </div>

                  {this.state.editErrorDetailsAlert &&
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert alert alert-danger">
                        Un problème est survenu lors de la modification. Veuillez réessayer plus tard.
                          </div>
                    </div>
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default bg-vred cancel-btn pull-left" data-dismiss="modal">
                    <span className="cancel_btn_label"> Quitter </span>
                    <img className="cancel-icon" src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png" alt="Annuler" />
                  </button>

                  {!this.state.addSyllabusContentInProgress ?
                    <button className="btn btn-success bg-vgreen submit_btn_container pull-right" onClick={(e) => this.onSubmitEditDetailsSyllabus(e)}>
                      <span className="submit_btn_label"> Modifier </span> &nbsp;
                            <img className="submit_icon" src="https://img.icons8.com/material-rounded/24/FFFFFF/filled-sent.png" alt="Ajouter" />
                    </button> :
                    <button className="btn btn-warning bg-vorange in-progress-btn-container pull-right">
                      <span className="in_progress_btn_label"> Modification en cours </span> &nbsp;
                            <i className="fa fa-spin fa-spinner"></i>
                    </button>
                  }

                </div>
              </form>
            </div>
          </div>
        </div>


        {/* Success submit tost alert */}
        {this.state.addSuccessDetailsAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
            La tâche a été ajoutée avec succès.
          </div>
        }
        {/* Success submit tost alert */}
        {this.state.editSuccessDetailsAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
            La tâche a été modifiée avec succès.
          </div>
        }

      </div>
    );
  }
}
