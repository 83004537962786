import React, { Component } from 'react';
import './CreateCoach.css';
//import '../listeCoach/listeCoach.css'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Menu from './../menu/Menu';
import API from '../../variablesGlobales';
import { NavLink } from 'react-router-dom';

class CreateCoach extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.state = {
            fields: {},
            errors: {},
            value: '',
            domaines: [],
            loading:false,
            api: API.API,
            fn_empty: false,
            ln_empty: false,
            email_empty: false,
            phone_empty: false,
            df_empty: false
        };
    }
    componentDidMount() {
        this.getAllDomaine();
    }

    getAllDomaine = () => {
        axios.get(this.state.api + 'domaines')
            .then(res => {
                const domains = res.data;
                this.setState({
                    domaines: domains.data,
                });
            })
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        //console.log("handle change", e.target.value)
        this.setState({
            fields
        });
        console.log("handle change", this.state.fields)


    }

    handleShowAndHideAlert(input){
        setTimeout(() => {
            if(input === 'firstName'){
                this.setState({
                    fn_empty: false
                });
            }
            if(input === 'lastName'){
                this.setState({
                    ln_empty: false
                });
            }
            if(input === 'email'){
                this.setState({
                    email_empty: false
                });
            }
            if(input === 'phone'){
                this.setState({
                    phone_empty: false
                });
            }
            if(input === 'domaine'){
                this.setState({
                    df_empty: false
                });
            }
        }, 5000);
      }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["first_name"]) {
            formIsValid = false;
            this.setState({
                fn_empty: true
            })
            this.handleShowAndHideAlert('firstName');
            errors["first_name"] = "*Ce champ est obligatoire";           
        }

        if (typeof fields["first_name"] !== "undefined") {
            if (!fields["first_name"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
                formIsValid = false;
                this.setState({
                    fn_empty: true
                })
                this.handleShowAndHideAlert('firstName');
                errors["first_name"] = "*Ce champs n'accepte que des lettres.";
            }
        }

        if (!fields["last_name"]) {
            formIsValid = false;
            this.setState({
                ln_empty: true
            })
            this.handleShowAndHideAlert('lastName');
            errors["last_name"] = "*Ce champ est obligatoire";
        }

        if (typeof fields["last_name"] !== "undefined") {
            if (!fields["last_name"].match(/^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/)) {
                formIsValid = false;
                this.setState({
                    ln_empty: true
                })
                this.handleShowAndHideAlert('lastName');
                errors["last_name"] = "*Ce champ ne doit contenir que des lettres.";
            }
        }

        if (!fields["email"]) {
            formIsValid = false;
            this.setState({
                email_empty: true
            })
            this.handleShowAndHideAlert('email');
            errors["email"] = "*Ce champ est obligatoire.";
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                this.setState({
                    email_empty: true
                })
                this.handleShowAndHideAlert('email');
                errors["email"] = "*Adresse email invalide.";
            }
        }

        if (!fields["phone"]) {
            formIsValid = false;
            this.setState({
                phone_empty: true
            })
            this.handleShowAndHideAlert('phone');
            errors["phone"] = "*Ce champ est obligatoire.";
        }

        if (!fields["coach_domaine_id"]) {
            formIsValid = false;
            this.setState({
                df_empty: true
            })
            this.handleShowAndHideAlert('domaine');
            errors["domaine"] = "*Ce champ est obligatoire.";
        }

        // if (typeof fields["phone"] !== "undefined") {
        // if (!fields["phone"].match(/^[0-9]{9}$/)) {
        //     formIsValid = false;
        //     errors["phone"] = "*Numéro de telephone invalide.";
        // }
        // }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    submituserRegistrationForm(e) {
        e.preventDefault();

        if (this.validateForm()) {
            this.setState({
                loading: true
            });
            let fields = {};
            fields["first_name"] = "";
            fields["last_name"] = "";
            fields["email"] = "";
            fields["phone"] = "";
            fields["coach_domaine_id"] = "";
            fields["status"] = "coach";
            this.setState({ fields: fields });
            const dataCoach = this.state.fields;
            console.log(dataCoach)
            axios.post(this.state.api + 'add-coach', dataCoach)
                .then(res => {
                    console.log(res)
                    if (res.status === 201 && res.statusText === "Created") {
                        window.location = 'liste-coach';
                    }
                    else {
                        this.setState({
                            loading: false,
                        });
                    }

                })
        }

    }



    render() {
        return (
            <div className="dashboard-container">
                <Menu />
                <div className="row no-content-wrapper">

                    <section className="content-header">
                        <h1>
                            Ajouter un coach
                        </h1>
                        <ol className="breadcrumb">
                          
                            <li><NavLink to="#"><i className="fa fa-dashboard"></i> Accueil</NavLink></li>
                            <li><NavLink to="/liste-coach"><i className="fa fa-dashboard"></i> Liste des coachs</NavLink></li>
                            <li className="active">Ajouter un coach</li>
                        </ol>
                    </section>
                    <div className="col-md-8 col-md-offset-2 add_coach_form_container">
                    
                        <form onSubmit={this.submituserRegistrationForm}>
                           
                                <div className="form-row">
                                    <div>
                                        <h1 id="titreCoach">Ajouter un coach</h1>
                                    </div>
                                    
                                    <div className="form-group ">
                                        <label for="first_name">Prenom:</label>
                                        <input type="text" className="form-control" id="first_name" placeholder="Prénom" name="first_name" value={this.state.fields.first_name} onChange={this.handleChange} />
                                    </div>
                                    {this.state.fn_empty &&
                                        <div className="errorMsg">{this.state.errors.first_name}</div>
                                    }
                                    <div className="form-group ">
                                        <label for="last_name">Nom:</label>
                                        <input type="text" className="form-control" id="last_name" placeholder="Nom" name="last_name" value={this.state.fields.last_name} onChange={this.handleChange} />
                                    </div>
                                    {this.state.ln_empty &&
                                        <div className="errorMsg">{this.state.errors.last_name}</div>
                                    }
                                    <div className="form-group">
                                        <label for="email">Email:</label>
                                        <input type="email" className="form-control" id="email" placeholder="Email" name="email" value={this.state.fields.email} onChange={this.handleChange} />
                                    </div>
                                    {this.state.email_empty &&
                                        <div className="errorMsg">{this.state.errors.email}</div>
                                    }
                                    <div className="form-group">
                                        <label for="gtr">Domaine de formation:</label>
                                        <select className="form-control" id="inputbakeli_domaine_id" name="coach_domaine_id" onChange={this.handleChange}>
                                            <option> --Choisir un domaine-- </option>
                                            {this.state.domaines.map((x, y) => <option value={x.id} >{x.name}</option>)}
                                        </select>
                                    </div>
                                    {this.state.df_empty &&
                                        <div className="errorMsg">{this.state.errors.email}</div>
                                    }
                                    <div className="form-group ">
                                        <label for="telephone">Téléphone:</label>
                                        <input type="text" className="form-control" id="telephone" placeholder="Téléphone" name="phone" onChange={this.handleChange} />
                                    </div>
                                    {this.state.phone_empty &&
                                        <div className="errorMsg">{this.state.errors.phone}</div>
                                    }
                                    <div className="col-md-8 col-md-offset-2 submit-btn-container">
                                        <div className="form-group " >
                                            <button type="submit" className='btn btn-warning add-item-btn'>
                                                <i className="fas fa-plus-circle"></i> &nbsp;
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>

                            
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateCoach;