import React, { Component } from 'react';
import './calendrier.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Link } from 'react-router-dom';



import Menu from './../menu/Menu';

class Calendrier extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      prospects: [],
      startDate: new Date()
      
    };
    this.handleChange = this.handleChange.bind(this);
    this.state = {value: 'Volkeno Foire'};
    this._handleChange = this._handleChange.bind(this);
  }

  




  handleChange(date) {
    this.setState({ startDate: date })
    this.toggleCalendar()
  }
  toggleCalendar(e) {
    e && e.preventDefault()
    this.setState({ isOpen: !this.state.isOpen })
  }

  _handleChange(event) {
    this.setState({value: event.target.value});
  }

  render() {
    return (
      <div>
        <Menu />
        <div className="row">
          <div className="component-Reportings">
            <div className="col-lg-12">
              <h1 className="page-header">
                <span className="page-title">Calendrier</span>
                <div className="pull-right">
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard-admin">Accueil</Link>
                    </li>
                    <li className="active">Calendrier</li>
                  </ol>
                </div>
              </h1>
            </div>

              
            <div className="col-md-6 col-md-offset-3 search_container">
              <select class="form-control form-control-lg" id="lang" value={this.state.value} onChange={this._handleChange}>
                <option value="Volkeno Foire  ">Volkeno Foire</option>
                <option value="Volkeno Grand Yoff 1">Volkeno Grand Yoff 1</option>
                <option value="Volkeno Grand Yoff 2">Volkeno Grand Yoff 2</option>
              </select>
            </div>


            <div class="container mtop " >
              <div class="row " >
                <div className="col-md-2 pull-left" >
                  <div >
                    <DatePicker
                      inline
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                    />
                  </div>


                </div>
                <div class="col-md-9 ptop calendrier-container">
                  <table className="table table-bordered ">
                  <caption className="lieu_title volkeno-green-color">{this.state.value}</caption>
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Heure D'arrivée</th>
                        <th scope="col">Prénom et Nom</th>
                        <th scope="col">Coach</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="">20/03/2019</td>
                        <td colspan="">08h : 05mn</td>
                        <td colspan="">Cheikh Ahmed Tidiane Diao</td>
                        <td colspan="">Sadio Sangharé</td>

                      </tr>
                      <tr>
                        <td colspan="">20/03/2019</td>
                        <td colspan="">08h : 05mn</td>
                        <td colspan="">Cheikh Ahmed Tidiane Diao</td>
                        <td colspan="">Sadio Sangharé</td>

                      </tr>
                      <tr>
                        <td colspan="">20/03/2019</td>
                        <td colspan="">08h : 05mn</td>
                        <td colspan="">Cheikh Ahmed Tidiane Diao</td>
                        <td colspan="">Sadio Sangharé</td>

                      </tr>
                      
                    </tbody>
                  </table>

                </div>
              </div>

            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    )
  }
}
export default Calendrier;
