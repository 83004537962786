import React, { Component } from 'react';
import './listeParticipantKokutana.css'
import Menu from '../menu/Menu';
import API from '../../variablesGlobales';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import '../../../node_modules/admin-lte/dist/js/adminlte.js';
import { BootstrapTable, TableHeaderColumn, ClearSearchButton, ButtonGroup } from 'react-bootstrap-table';


export default class listeParticipantKokutana extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      isPageLoading: false,
      participants: [],
      isRequestError: false,

    };
  }

  componentDidMount = () => {
    this.getParticipantsKokutana();
  }

  getParticipantsKokutana = () => {
    this.setState({
      isPageLoading: true
    })

    axios.get(this.state.api + 'kokutana-presences')
      .then(response => {
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            participants: response.data.data
          })
        } else {
          console.log(response)
          this.setState({
            isPageLoading: false
          })
        }
      })
      .catch(error => {
        console.log(error.message)
        this.setState({
          isPageLoading: false
        })
      })
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  }

  handleClearButtonClick = (onClick) => {
    console.log('This is my custom function for ClearSearchButton click event');
    onClick();
  }

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText='Effacer'
        btnContextual='btn-warning'
        className='my-custom-class'
        onClick={e => this.handleClearButtonClick(onClick)} />
    );
  }

  render() {
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup
    };
    
    if (this.state.isPageLoading) {
      return (
        <div className="component-liste-participant-kokutana">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                  Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>Participant Kokùtana 2019</h1>
              <ol class="breadcrumb">
                <li><NavLink to="/"><i class="fa fa-dashboard"></i> Home</NavLink></li>
                <li className="active">Participant Kokùtana 2019</li>
              </ol>
            </section>

            <section className="content">
              <div className="list_bakeliste_container">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Prénom</th>
                        <th scope="col">Nom</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      <div id="loader"></div>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

          </div>
        </div>);
    }

    return (
      <div className="component-liste-participant-kokutana">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">

          <section className="content-header">
            <h1>Participant Kokùtana 2019</h1>
            <ol class="breadcrumb">
              <li><NavLink to="/"><i class="fa fa-dashboard"></i> Home</NavLink></li>
              <li className="active">Participant Kokùtana 2019</li>
            </ol>
          </section>
          {/* <section className="content"> */}
              {/* Blocs contain bakelistes delay list and absence list */}
              <div className="row tab-data-row">
                <div className="col-md-12 tab-data-col">
                  <div className="col-md-12 reporting-container">
                    <div className="list_prospect_container">
                      <div className="row prospect-row-row">
                        <div className="col-md-12 prospect-col">
                          <div className="nav-tabs-custom">
                            <div className="col-md-12 da-list-col">
                              <ul className="nav nav-tabs">
                                <li className="active"><NavLink to="/liste-participants-kokutana2019">Kokutuna</NavLink></li>
                                <li className=""><NavLink to="/event-manager">Event manager</NavLink></li>
                                <li className=""><NavLink to="/meetings">Rencontre</NavLink></li>
                                {/* <li className="pull-right"><a href="#" className="text-muted"><i className="fa fa-gear"></i></a></li> */}
                              </ul>
                            </div>
                             
                              {/* end table */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                  </div>
                </div>
                {/* </section> */}
          <section className="content">
            <div className="list_bakeliste_container">
              <BootstrapTable data={this.state.participants}
                striped={true} hover={true} condensed={true}
                multiColumnSort={2} options={options}
                pagination exportCSV
                search={true}>
                <TableHeaderColumn dataField='id' isKey={true} hidden={true}> ID</TableHeaderColumn>
                <TableHeaderColumn dataField='first_name'
                  width="300" filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}
                >Prénom</TableHeaderColumn>
                <TableHeaderColumn dataField='last_name'
                  width="300" filter={{ type: 'TextFilter', delay: 1000 }} dataSort={true}
                >Nom</TableHeaderColumn>
                <TableHeaderColumn dataField='email' width="250" >Email</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='id' width="350" dataFormat={this.actionsFormatter.bind(this)} dataSort={true}>Actions</TableHeaderColumn> */}
              </BootstrapTable>
            </div>
          </section>

        </div>
      </div>
    );
  }
}
