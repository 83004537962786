import React, { Component } from "react";
import Menu from "./../menu/Menu";
import { NavLink } from "react-router-dom";
import { MagicSpinner } from "react-spinners-kit";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import API from "../../variablesGlobales";
import "../gestionSyllabusCoach/gestionSyllabusCoach.css";
import { ToastContainer, toast } from "react-toastify";

import { Button, Modal } from "react-bootstrap";
import MultiSelect from "@khanacademy/react-multi-select";
import $ from "jquery";

export default class GestionSyllabusCoach extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem("token");
    let userStatus = window.sessionStorage.getItem("user_status");
    // let userID = window.sessionStorage.getItem('user_id');

    if (!token) {
      window.location = "/";
    }
    if (userStatus === "bakeliste") {
      window.history.back();
    }

    super(props);
    this.state = {
      isSuccessAssign: false,
      isErrorAssign: false,
      isAssignSyllabusInProgress: false,
      selectedOption: null,
      userID: window.sessionStorage.getItem("user_id"),
      showHide: false,
      isPageLoading: true,
      api: API.API,
      syllabusDatas: [],
      domaines: [],
      technologies: [],
      tools: [],
      fields: {},
      errors: {},
      sname: false,
      sdescription: false,
      stechnotool_empty: false,
      screatedby_empty: false,
      slevel_empty: false,
      sdomaineid_empty: false,
      addSyllabusInProgress: false,
      addSyllabusSuccessAlert: false,
      addSyllabusErrorAlert: false,
      remaining_char: 245,
      selectOptions: [],
      selectOptions1: [],
      selectOptions2: [],
      selectTechnologies: [],
      selectTools: [],
      syllabusID: 0,
      bakelisteID: 0,
      selected: [],
      selectedtools: [],
      onlySelect: false,
      groupySelect: false,
      bakelistesByCoach: [],
      bakeliste: [],
      assignationEncours: false,
      assignationSuccessAlert: false,
      isAssignationError: false,
      noSavedData: [],
      syllabusByCoache: [],
      bakelisteBySyllabus: [],
      sous_groupes:[],

    };

    this.addHandleChange = this.addHandleChange.bind(this);
    this.submitAddSyllabus = this.submitAddSyllabus.bind(this);
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
    this.assignSyllabus = this.assignSyllabus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addDetailsHandleChange = this.addDetailsHandleChange.bind(this);
    this.editDetailsHandleChange = this.editDetailsHandleChange.bind(this);
    this.onSubmitDetailsSyllabus = this.onSubmitDetailsSyllabus.bind(this);
    this.AssignerSyllabusModal = this.AssignerSyllabusModal.bind(this);
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;

    this.setState({
      bakelisteID: params.bakeliste_user_id,
      syllabusID: params.syllabus_id
    });
    this.onGetSyllabusDetails(params.syllabus_id);
    this.getAllDomaines();
    this.onGetSyllabus();
    this.getAllTechnologies();
    this.getAllTools();
    this.getSyllabusAssignedByCoach();
    this.getBakelisteByCoach();
    this.__getBakelisteBySyllabus();
    this.__getSousGroupes()
    setTimeout(() => {
      this.setState({
        isPageLoading: false,
        addSyllabusInProgress: false,
      });
    }, 5000);
  };




  onSubmitDetailsSyllabus = (e) => {
    e.preventDefault();

    if (this.onValidateFormData()) {
      this.setState({
        addSyllabusContentInProgress: true,
      });

      var data = this.state.fields;
      var baseApiUrl = this.state.api;
      data["selected_technologies"] = this.state.selected1;
      axios
        .post(baseApiUrl + "details-syllabus", data)
        .then((response) => {
          this.setState({
            addSyllabusContentInProgress: true,
          });

          if (response.data.success) {
            this.onGetDetailsBySyllabus(this.state.syllabusID);
            this.setState({
              addSuccessDetailsAlert: true,
            });
            this.onSwitchAlertStatus("addSuccessDetailsAlert");
            window.location.reload();
          } else {
           // console.log(response);
            this.setState({
              addErrorDetailsAlert: true,
            });
            this.onSwitchAlertStatus("addErrorDetailsAlert");
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.setState({
            addSyllabusContentInProgress: false,
            addErrorDetailsAlert: true,
          });
          this.onSwitchAlertStatus("addErrorDetailsAlert");
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  onGetDetailsBySyllabus = (syllabus_id) => {
    this.setState({
      isPageLoading: true,
    });

    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "details-by-syllabus/" + syllabus_id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });

        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            syllabusDatas: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  addDetailsHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.syllabusID;
    fields["created_by"] = this.state.userID;

    if (e.target.name === "syllabus_completion_time") {
      var sct = e.target.value;
      var week = (sct / 45).toFixed(1);
      fields["syllabus_completion_week"] = week;
    }

    this.setState({
      fields: fields,
    });
    //console.log(fields);
  };
  editDetailsHandleChange = (e) => {
    let fields = this.state._fields;
    fields[e.target.name] = e.target.value;
    fields["syllabus_id"] = this.state.syllabusID;
    fields["created_by"] = this.state.userID;

    if (e.target.name === "syllabus_completion_time") {
      var sct = e.target.value;
      var week = (sct / 45).toFixed(1);
      fields["syllabus_completion_week"] = week;
    } else {
      fields["syllabus_completion_week"] = this.state.sCompletionWeek;
    }

    if (e.target.name === "syllabus_skills") {
      this.setState({
        sSkillValue: e.target.value,
      });
    }
    if (e.target.name === "syllabus_task") {
      this.setState({
        sTaskValue: e.target.value,
      });
    }
    if (e.target.name === "syllabus_completion_time") {
      this.setState({
        sCompletionTimeValue: e.target.value,
      });
    }
    if (e.target.name === "syllabus_week_number") {
      this.setState({
        sWeekNumber: e.target.value,
      });
    }

    if (!fields["syllabus_skills"]) {
      fields["syllabus_skills"] = this.state.sSkillValue;
    }
    if (!fields["syllabus_task"]) {
      fields["syllabus_task"] = this.state.sTaskValue;
    }
    if (!fields["syllabus_completion_time"]) {
      fields["syllabus_completion_time"] = this.state.sCompletionTimeValue;
    }
    if (!fields["syllabus_week_number"]) {
      fields["syllabus_week_number"] = this.state.sWeekNumber;
    }

    this.setState({
      _fields: fields,
    });
    //console.log(fields);
  };

  handleChange(e) {
    e.preventDefault();
    var fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
   // console.log(this.state.fields);
  }

  onGetSyllabusInfos = (syllabus_id) => {
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "syllabus/" + syllabus_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            syllabusInfos: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  
  onGetSyllabusDetails = (syllabus_id) => {
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "all-details-syllabus/" + syllabus_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            syllabusDetails: response.data.data,
          });
        }
        console.log("syllabusDetails", response.data.data)
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  onGetSyllabus = () => {
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "syllabus-by-coach/" + this.state.userID)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            syllabusDatas: response.data.data,
          });
          //console.log(this.state.syllabusDatas);
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        this.onSwitchAlertStatus("isGetSyllabusRequestError");
      });
  };

  getAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
        //console.log(this.state.domaines);
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  onOnlyAssignation = () => {
    this.setState({
      onlySelect: true,
      groupySelect: false,
    });
  };

  /**
   * Activate groupy select assignation
   */
  onGroupyAssignation = () => {
    this.setState({
      onlySelect: false,
      groupySelect: true,
    });
  };

  addHandleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    fields["created_by"] = this.state.userID;
    fields["technologie_id"] = this.state.selected;
    fields["tool_id"] = this.state.selectedtools;
    if (e.target.name === "syllabus_description") {
      var chars = e.target.value.length;
      var remaining_char = 245 - chars;
      this.setState({
        remaining_char: remaining_char,
      });
    }
    this.setState({
      fields: fields,
    });

   // console.log(fields);
  };

  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === "addSyllabusSuccessAlert") {
        this.setState({
          addSyllabusSuccessAlert: false,
        });
      }
      if (label === "addSyllabusErrorAlert") {
        this.setState({
          addSyllabusErrorAlert: false,
        });
      }
      if (label === "isGetSyllabusRequestError") {
        this.setState({
          isGetSyllabusRequestError: false,
        });
      }
      if (label === "sname_empty") {
        this.setState({
          sname_empty: false,
        });
      }
      if (label === "sdescription_empty") {
        this.setState({
          sdescription_empty: false,
        });
      }
      /* if (label === "technologie_id_empty") {
        this.setState({
          technologie_id_empty: false,
        });
      }
      if (label === "tool_id_empty") {
        this.setState({
          tool_id_empty: false,
        });
      } */
      if (label === "screatedby_empty") {
        this.setState({
          screatedby_empty: false,
        });
      }
      if (label === "slevel_empty") {
        this.setState({
          slevel_empty: false,
        });
      }
      if (label === "sdomaineid_empty") {
        this.setState({
          sdomaineid_empty: false,
        });
      }
    }, 5000);
  };

  onValidateAddFormData = () => {
    let fields = this.state.fields;
    //console.log(fields);
    let errors = {};
    let isValidForm = true;

    if (!fields["syllabus_name"]) {
      isValidForm = false;
      this.setState({
        sname_empty: true,
      });
      this.onSwitchAlertStatus("sname_empty");
      errors["sname_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_description"]) {
      isValidForm = false;
      this.setState({
        sdescription_empty: true,
      });
      this.onSwitchAlertStatus("sdescription_empty");
      errors["sdescription_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["technologie_id"]) {
      isValidForm = false;
      this.setState({
        technologie_id_empty: true,
      });
      this.onSwitchAlertStatus("technologie_id_empty");
      errors["technologie_id_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["tool_id"]) {
      isValidForm = false;
      this.setState({
        tool_id_empty: true,
      });
      this.onSwitchAlertStatus("tool_id_empty");
      errors["tool_id_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["created_by"]) {
      isValidForm = false;
      this.setState({
        screatedby_empty: true,
      });
      this.onSwitchAlertStatus("screatedby_empty");
      errors["screatedby_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_level"]) {
      isValidForm = false;
      this.setState({
        slevel_empty: true,
      });
      this.onSwitchAlertStatus("slevel_empty");
      errors["slevel_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_domaine_id"]) {
      isValidForm = false;
      this.setState({
        sdomaineid_empty: true,
      });
      this.onSwitchAlertStatus("sdomaineid_empty");
      errors["sdomaineid_empty"] = "*Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
   // console.log("OK");
  }

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "successAssignation") {
        this.setState({
          assignationSuccessAlert: false,
        });
      }
      if (label === "errorAssignation") {
        this.setState({
          isAssignationError: false,
        });
      }
      if (label === "successStatusUpdate") {
        this.setState({
          updateTacheStatusSuccess: false,
        });
      }
      if (label === "tacheRequestSuccess") {
        this.setState({
          updateTacheRequestSuccessAlert: false,
        });
      }
      if (label === "tacheRequestError") {
        this.setState({
          updateTacheRequestError: false,
        });
      }
      if (label === "errorStatusUpdate") {
        this.setState({
          isUpdatedError: false,
          updateTacheStatusError: false,
        });
      }
    }, 5000);
  }

  // assigner sylabus a un bakeliste
  assignSyllabus() {
    this.setState({
      assignationEncours: true,
    });
    let data = this.state.fields;
    if (this.state.onlySelect) {
      /* for (let sel = 0; sel < this.state.selectOptions.length; sel++) {
        const element = this.state.selectOptions[sel];
        
      }*/
      data["syllabus_id"] = this.state.syllabusID;
      data["is_only"] = 1;
      data["is_groupy"] = 0;
      data["selected_bakeliste"] = this.state.fields.selected_bakeliste;
    } else if (this.state.groupySelect) {
      data["syllabus_id"] = this.state.syllabusID;
      data["is_groupy"] = 1;
      data["is_only"] = 0;
      data["selected_groupes"] = this.state.selected;
    }
   // console.log(data);
    axios
      .post(this.state.api + "assignation", data)
      .then((res) => {
        if (res.data.success === true && res.data.nosavedStatus === false) {
          $(".close").click();
          document.getElementById("create-syllabus-form").reset();
          $("#date_syllabus").val("");
          this.setState({
            assignationEncours: false,
            assignationSuccessAlert: true,
            fields: {},
          });
          this.handleShowAndHideAlert("successAssignation");
          this.setState({
            syllabusDatas: res.data.data,
          });
          //console.log(this.state.syllabusDatas);
        } else if (
          res.data.success === true &&
          res.data.nosavedStatus === true
        ) {
          //this.getSyllabusAssignedByCoach();

          document.getElementById("create-syllabus-form").reset();
          $("#date_syllabus").val("");
          this.setState({
            assignationEncours: false,
            assignationSuccessAlert: true,
            noSavedAndSavedTrueAlert: true,
            noSavedData: res.data.nosaved,
            fields: {},
          });

          this.handleShowAndHideAlert("successAssignation");
        } else if (
          res.data.success === false &&
          res.data.nosavedStatus === true
        ) {
          this.setState({
            assignationEncours: false,
            isAssignationError: true,
          });

          this.handleShowAndHideAlert("errorAssignation");
        } else {
          this.setState({
            assignationEncours: false,
            isAssignationError: true,
          });

          this.handleShowAndHideAlert("errorAssignation");
        }
        $(".close").click();
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          assignationEncours: false,
          isAssignationError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }
  getSyllabusAssignedByCoach = () => {
    axios
      .get(this.state.api + `syllabus-by-coach/${this.state.userID}`)
      .then((res) => {
        if (res.data.data !== undefined) {
          this.setState({
            syllabusByCoache: res.data.data.reverse(),
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  AssignerSyllabusModal = (id) => {
    this.setState({
      syllabusID: id,
    });
   // console.log("Syllabus id", this.state.syllabusID);
  };

  submitAddSyllabus = (e) => {
    e.preventDefault();
   // console.log(this.state.selected);
    if (this.onValidateAddFormData()) 
    {
      this.setState({
        addSyllabusInProgress: true,
      });

      var baseApiUrl = this.state.api;
      var data = this.state.fields;

      axios
        .post(baseApiUrl + "syllabus/create", data)
        .then((response) => {
          this.setState({
            addSyllabusInProgress: false,
          });
          if (response.data.success) {
            //this.onGetSyllabus();
            //$('.close').click();
            // $('.modal-backdrop').removeClass('modal-backdrop');
            // $('.fade').removeClass('fade')
            // $('.in').removeClass('in')
            //$('#add_syllabus_form')[0].reset();

            this.setState({
              addSyllabusSuccessAlert: true,
            });
            this.onSwitchAlertStatus("addSyllabusSuccessAlert");
            window.location.reload();
          } else {
           // console.log(response);
            this.setState({
              addSyllabusErrorAlert: true,
            });
            this.onSwitchAlertStatus("addSyllabusErrorAlert");
          }
          $(".close").click();
        })
        .catch((error) => {
          console.log(error.message);
          this.setState({
            addSyllabusInProgress: false,
            addSyllabusErrorAlert: true,
          });
          this.onSwitchAlertStatus("addSyllabusErrorAlert");
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  // Bakeliste by coach

  getBakelisteByCoach() {
    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.userID)
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            bakelistesByCoach: data,
          });
        }
       /*  data.forEach((item) => {
          let infos = {
            label: item.first_name + " " + item.last_name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        }); */
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }
/* get bakeliste par syllabus */ 
  __getBakelisteBySyllabus = (id) => {
    console.log(id)
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "bakelistes-by-syllabus/" + id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            bakelisteBySyllabus: response.data.data,
          });
        }
        console.log("bak par syllabus", response.data)
      })
      .catch((error) => {
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  

  //get technologies
  getAllTechnologies() {
    axios
      .get(this.state.api + "technologies/")
      .then((response) => {
        var data = response.data.data;

        if (data !== undefined) {
          this.setState({
            technologies: data,
          });
          //console.log("les technologies :", this.state.technologies);
          {
            this.state.technologies.map((index) => {
             // console.log(index.id);
            });
          }
        }

        data.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions1.push(infos);
        });
        //console.log("Technologies ", this.state.selectOptions1);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }


  //get sous-groupes by coach
  
  __getSousGroupes = () => {
    axios
      .get(this.state.api + "sous__groupes/coach/" + this.state.userID )
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            sous_groupes: res.data.data.reverse(),
          });
        }
         this.state.sous_groupes.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
        console.log("sous groupes",this.state.sous_groupes);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  //get tools
  getAllTools() {
    axios
      .get(this.state.api + "tools/")
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            tools: data,
          });
          //console.log("les technologies :", this.state.technologies);
          {
            this.state.tools.map((index) => {
             // console.log(index.id);
            });
          }
        }

        data.forEach((item) => {
          let infos = {
            label: item.name,
            value: item.id,
          };
          this.state.selectOptions2.push(infos);
        });
        //console.log("Tools ", this.state.selectOptions2);
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  render() {
    if (this.state.isPageLoading) {
      return (
        <div className="component-gestion-syllabus">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
              Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">
            <section className="content-header">
              <h1>Syllabus Manager</h1>
              <ol className="breadcrumb">
                <li>
                  <NavLink to="/dashboard-coach">
                    <i className="fa fa-dashboard"></i> Accueil
                  </NavLink>
                </li>
                <li className="active">Syllabus Manager</li>
              </ol>
            </section>

            <section className="content">
              <div className="loader-row row">
                <div className="col-md-4 col-md-offset-4 loader-container">
                  <MagicSpinner
                    size={150}
                    color="#009688"
                    loading={this.state.isPageLoading}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    }
    return (
      <div className="component-gestion-syllabus">
        <Menu />
        {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
        <div className="row no-content-wrapper">
          <section className="content-header">
            <h1>Syllabus Manager</h1>
            <ol className="breadcrumb">
              <li>
                <NavLink to="/dashboard-coach">
                  <i className="fa fa-dashboard"></i> Accueil
                </NavLink>
              </li>
              <li className="active">Syllabus Manager</li>
            </ol>
          </section>

          <section className="content">
            <div className="col-md-12 syllabus-container">
              <div className="row add_syllabus_container">
                <div className="col-md-6 col-md-offset-3 add-btn-container">
                  <button
                    data-toggle="modal"
                    data-target="#addSyllabusModal"
                    className="btn btn-success bg-vgreen add_btn button"
                  >
                    <img
                      src="https://img.icons8.com/ios/50/FFFFFF/add.png"
                      alt="Bouton Ajouter"
                    />
                  </button>
                </div>
              </div>

              <div className="row syllabus_content_row">
                {this.state.syllabusDatas === null && (
                  <div className="col-md-12 empty_data_alerty">
                    <div className="alert alert danger empty_alert">
                      Aucun syllabus n'est encoré créé. Veuillez en ajouter !
                    </div>
                  </div>
                )}
                {this.state.syllabusDatas.map((item, index) => (
                  <div key={index} className="col-md-5 syllabus_item_container">
                    <div className="box box-solid box-default box_container">
                      <NavLink to={"/syllabus-content-manager/" + item.id}>
                        <div className="box-header with-border bg-vgreen">
                          <h3 className="box-title"> {item.syllabus_name} </h3>
                          <span
                            className={
                              "label pull-right " +
                              (item.syllabus_level === "débutant"
                                ? "bg-vred"
                                : item.syllabus_level === "intermédiaire"
                                ? "bg-vorange"
                                : item.syllabus_level === "avancé"
                                ? "bg-vblack"
                                : "")
                            }
                          >
                            {item.syllabus_level}
                          </span>
                        </div>

                        <div className="box-body">
                          <div className="description_container">
                            <span className="syllabus_description_bloc">
                              {" "}
                              Description :{" "}
                            </span>
                            <p> {item.syllabus_description} </p>
                            
                          </div>
                          
                            <div key={index}  className="syllabus_tt_container">
                              
                              {item.technologies.map((t, index) =>(
                                <p>  { t.name} &nbsp; </p>
                              ))}
                              {item.tools.map((to, index) =>(
                                <p>   {to.name} &nbsp; </p>
                              ))}
                            </div>

                        </div>
                      </NavLink>
                      <div className="row">
                        <div className="col-md-6" style={{ display: "flex" }}>
                          <span
                            data-toggle="modal"
                            data-target="#assignSyllabusModale"
                            type="button"
                            className="btn btn-assignation syllabus-assign_modal"
                            title="Assigner Syllabus"
                            onClick={() => this.AssignerSyllabusModal(item.id)}
                          >
                            <i className="fas fa-user-check"></i>&nbsp; Assigner
                          </span>
                        </div>
                        <div className="col-md-6" style={{ display: "flex" }}>
                          <span
                            data-toggle="modal"
                            data-target="#voirListeModale"
                            type="button"
                            className="btn btn-assignation syllabus-assign_modal"
                            title="Voir la liste des bakeliste"
                            onClick={() => {
                              this.__getBakelisteBySyllabus(item.id)
                            } }
                          >
                            <i className="fas fa-users"></i>&nbsp; Bakelistes
                          </span>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>


         {/* Modal liste bakelistes dans un sous groupe */}

         <div
            className="modal fade"
            id="voirListeModale"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="voirListeModalLabel"
          >
            <div className="modal-dialog ajout" role="document"  >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title" id="voirListeModalLabel">
                    Liste des bakelistes qui suivent le syllabus
                  </h4>
                </div>
                <div className="modal-body">
                               
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Prenom</th>
                          <th scope="col">Nom</th>
                          <th scope="col">Email</th>
                          <th scope="col">Téléphone</th>
                        </tr>
                      </thead>
                      <tbody> 
                      {this.state.bakelisteBySyllabus.map((item, index) => ( 
                        <tr key={index}>

                          <td>{item.first_name}</td>
                          <td>{item.last_name}</td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                        </tr> 
                     ))}  
                      </tbody>
                    </table>
               
                </div>

              </div>
            </div>
          </div>
          

        <div
          className="modal fade"
          id="addSyllabusModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="addSyllabusModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="addSyllabusModalLabel">
                  Ajouter un syllabus
                </h4>
              </div>
              <form id="add_syllabus_form">
                <div className="modal-body">
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_title">Nom du syllabus</label>
                      <input
                        type="text"
                        name="syllabus_name"
                        placeholder="Nom du syllabus"
                        onChange={this.addHandleChange}
                        className="form-control input_item"
                        id="syllabus_title"
                      />
                    </div>
                    {this.state.sname_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sname_empty}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_description">
                        Description du syllabus
                      </label>
                      <textarea
                        name="syllabus_description"
                        maxLength="245"
                        placeholder="Description du syllabus"
                        onChange={this.addHandleChange}
                        className="form-control input_item"
                        id="syllabus_description"
                      />
                      {this.state.remaining_char >= 31 && (
                        <span className="restant_container_sup_30">
                          {" "}
                          {this.state.remaining_char} caractéres restants
                        </span>
                      )}
                      {this.state.remaining_char <= 30 &&
                        this.state.remaining_char > 1 && (
                          <span className="restant_container_inf_30">
                            {" "}
                            {this.state.remaining_char} caractéres restants
                          </span>
                        )}
                      {this.state.remaining_char <= 1 && (
                        <span className="restant_container_inf_30">
                          {" "}
                          {this.state.remaining_char} caractére restant
                        </span>
                      )}
                    </div>
                    {this.state.sdescription_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sdescription_empty}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="technologie_name">
                        Langages/Framework
                      </label>
                      <MultiSelect
                        overrideStrings={{
                          selectSomeItems: "Choisissez les technologies",
                          allItemsAreSelected:
                            "Tous vos technologies ont été choisis",
                          selectAll: "Sélectionnez tout",
                          search: "Rechercher",
                        }}
                        options={this.state.selectOptions1}
                        selected={this.state.selected}
                        onSelectedChanged={(selected) =>
                          this.setState(
                            { selected },
                          )
                        }
                      />
                    </div>
                    {this.state.technologie_id_empty && (
                      <div className="errorMsg">
                        {this.state.errors.technologie_id_empty}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="tool_id">Outils</label>
                      <MultiSelect
                        overrideStrings={{
                          selectSomeItems: "Choisissez les outils",
                          allItemsAreSelected:
                            "Tous vos outils ont été choisis",
                          selectAll: "Sélectionnez tout",
                          search: "Rechercher",
                        }}
                        options={this.state.selectOptions2}
                        selected={this.state.selectedtools}
                        onSelectedChanged={(selectedtools) =>
                          this.setState(
                            { selectedtools },
                          )
                        }
                      />
                    </div>
                    {this.state.stool_empty && (
                      <div className="errorMsg">
                        {this.state.errors.stool_empty}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_level">Niveau ciblé</label>{" "}
                      <br />
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="débutant"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label"> Débutant </span>
                      </span>
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="intermédiaire"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label">
                          {" "}
                          Intermédiaire{" "}
                        </span>
                      </span>
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="avancé"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label"> Avancé </span>
                      </span>
                      {this.state.slevel_empty && (
                        <div className="errorMsg">
                          {this.state.errors.slevel_empty}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 form_col">
                    <div className="form-group as_input_container">
                      <label htmlFor="syllabus_domaine_id">Domaines</label>
                      <select
                        name="syllabus_domaine_id"
                        onChange={this.addHandleChange}
                        className="form-control input_item"
                        id="syllabus_domaine_id"
                      >
                        <option> --Choisir un domaine-- </option>
                        {this.state.domaines.map((item, index) => (
                          <option key={index} value={item.id}>
                            {" "}
                            {item.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                    {this.state.sdomaineid_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sdomaineid_empty}
                      </div>
                    )}
                  </div>
                  {this.state.addSyllabusErrorAlert && (
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert">
                        Un problème est survenu lors de l'ajout. Veuillez
                        réessayer plus tard.
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default bg-vred cancel-btn pull-left"
                    data-dismiss="modal"
                  >
                    <span className="cancel_btn_label"> Quitter </span>
                    <img
                      className="cancel-icon"
                      src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png"
                      alt="Annuler"
                    />
                  </button>
                  {!this.state.addSyllabusInProgress ? (
                    <button
                      className="btn btn-success bg-vgreen submit_btn_container pull-right"
                      onClick={(e) => this.submitAddSyllabus(e)}
                    >
                      <span className="submit_btn_label"> Ajouter </span> &nbsp;
                      <img
                        className="submit_icon"
                        src="https://img.icons8.com/material-rounded/24/FFFFFF/filled-sent.png"
                        alt="Ajouter"
                      />
                    </button>
                  ) : (
                    <button className="btn btn-warning bg-vorange in-progress-btn-container pull-right">
                      <span className="in_progress_btn_label">
                        {" "}
                        Ajout en cours{" "}
                      </span>{" "}
                      &nbsp;
                      <i className="fa fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>

          {/* Success submit tost alert */}
          {this.state.addSyllabusSuccessAlert === true && (
            <div className="alert alert-success text-center upsuccess-alert">
              <i className="fas fa-check-double"></i> &nbsp;&nbsp; Syllabus
              ajouté avec succès.
            </div>
          )}
        </div>

        {/* Assign syllabus to bakeliste */}
        <div
          className="modal fade"
          id="assignSyllabusModale"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="assignSyllabusModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="assignSyllabusModalLabele">
                  Assigner un syllabus
                </h4>
              </div>
              <form id="add_syllabus_form">
                <div className="modal-body">
                  <div className="col-md-12 form-group assignation-type-choice">
                    <div className="item-label">
                      Choisir un type d'assignation
                    </div>
                    <input
                      type="radio"
                      name="only-groupy"
                      value="only"
                      className="item1"
                      onClick={this.onOnlyAssignation}
                    />{" "}
                    <span className="radioItem">Assignation unique</span> <br />
                    <input
                      type="radio"
                      name="only-groupy"
                      value="groupy"
                      className="item2"
                      onClick={this.onGroupyAssignation}
                    />{" "}
                    <span className="radioItem">Assignation groupée</span>
                  </div>
                  {/* Assignation unique select view */}
                  {this.state.onlySelect && (
                    <div className="form-group col-md-12">
                      <label>Bakeliste</label>
                      <select
                        className="form-control"
                        id=""
                        name="selected_bakeliste"
                        onChange={(e) => this.handleChange(e)}

                        // onChange={this.handleChange}
                      >
                        <option>--Choisir un bakeliste--</option>
                        {this.state.bakelistesByCoach.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.first_name}&nbsp;{x.last_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                
                  {/* Assignation groupée select view // */}
                  {this.state.groupySelect && (
                    <div className="form-group col-md-12">
                      <label>Groupe de Bakelistes</label>
                      <MultiSelect
                        overrideStrings={{
                          selectSomeItems: "Choisissez un groupe",
                          allItemsAreSelected:
                            "Tous vos groupes on été choisis",
                          selectAll: "Sélectionnez tout",
                          search: "Rechercher",
                        }}
                        options={this.state.selectOptions}
                        selected={this.state.selected}
                        onSelectedChanged={(selected) =>
                          this.setState({ selected })
                        }
                      />
                    </div>
                  )}
                  {this.state.addSyllabusErrorAlert && (
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert">
                        Un problème est survenu lors de l'ajout. Veuillez
                        réessayer plus tard.
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default bg-vred cancel-btn pull-left"
                    data-dismiss="modal"
                  >
                    <span className="cancel_btn_label"> Quitter </span>
                    <img
                      className="cancel-icon"
                      src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png"
                      alt="Annuler"
                    />
                  </button>
                  {!this.state.assignationEncours && (
                    <button
                      type="button"
                      className="btn btn-primary btn_for_assignation button addBtn"
                      onClick={() => this.assignSyllabus()}
                    >
                      <i className="fas fa-user-check"></i>&nbsp; Assigner
                    </button>
                  )}
                  {this.state.assignationEncours && (
                    <button
                      type="button"
                      className="btn btn-warning button addBtn btn_for_wait"
                    >
                      Assignation en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
