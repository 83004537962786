import React, { Component } from 'react';
import './AdminHomeBakelistesCounter.css'
import API from '../../variablesGlobales';
import axios from 'axios';
//import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';



export default class AdminHomeBakelistesCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      nbreBakeliste: 0,
      nbreBakelisteBoursier: 0,
      nbreBakelistePayant: 0,
      nbrebakelistesThies: 0,
      nbrebakelistesTamba: 0,
      nbreBakelisteISA: 0,
      nbreBakelisteStageDF: 0
    };
  }

  componentDidMount() {
    this.getNombreBakeliste();
  }

  /**
   * Get bakeliste count in training
   */
  getNombreBakeliste() {
    axios.get(this.state.api + 'nbre-bakeliste')
      .then((response) => {
        const nbreBakeliste = response.data.data1;
        const nbreBakelisteBoursier = response.data.data2;
        const nbreBakelistePayant = response.data.data3;
        const nbrebakelistesVF = response.data.data4;
        const nbrebakelistesGY1 = response.data.data5;
        const nbrebakelistesGY2 = response.data.data6;
        const nbrebakelistesThies = response.data.data7;
        const nbrebakelistesTamba = response.data.data8;
        const nbreBakelisteISA = response.data.data9;
        const nbreBakelisteStageDF = response.data.data10;
        const nbreBakelisteDemiBoursier = response.data.data11;
        const nbreBakelisteFormationDiplomante = response.data.data12;
        const nbreBakelisteFormationCourte = response.data.data13;


        //console.log('nombre bakeliste', response.data.data1);
        this.setState({
          nbreBakeliste: nbreBakeliste,
          nbreBakelisteBoursier: nbreBakelisteBoursier,
          nbreBakelistePayant: nbreBakelistePayant,
          nbrebakelistesVF: nbrebakelistesVF,
          nbrebakelistesGY2: nbrebakelistesGY2,
          nbrebakelistesGY1: nbrebakelistesGY1,
          nbrebakelistesThies: nbrebakelistesThies,
          nbrebakelistesTamba: nbrebakelistesTamba,
          nbreBakelisteISA: nbreBakelisteISA,
          nbreBakelisteStageDF: nbreBakelisteStageDF,
          nbreBakelisteDemiBoursier: nbreBakelisteDemiBoursier,
          nbreBakelisteFormationDiplomante: nbreBakelisteFormationDiplomante,
          nbreBakelisteFormationCourte: nbreBakelisteFormationCourte
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="component-admin-home-bakelistes-counter">
        <div className="row admin-dashboard-row">
          <NavLink to="/liste-bakelistes" className="counterLink">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="info-box">
              <span className="info-box-icon bg-vgreen">
                <i className="fas fa-user-graduate" ></i>
              </span>

              <div className="info-box-content">
                <span className="info-box-text">Bakelistes</span>
                <span className="info-box-number">
                  {this.state.nbreBakeliste}
                  {/* <small>%</small> */}
                </span>
              </div>
            </div>
          </div>
          </NavLink>

          <NavLink to="/liste-bakelistes-formation-diplomante" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Formation Diplômante</span>
                  <span className="info-box-number">
                    {this.state.nbreBakelisteFormationDiplomante}
                    {/* <small>%</small> */}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/liste-bakelistes-formation-courte" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Formation courte</span>
                  <span className="info-box-number">
                    {this.state.nbreBakelisteFormationCourte}
                    {/* <small>%</small> */}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/liste-bakelistes-payants" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Payants</span>
                  <span className="info-box-number">
                    {this.state.nbreBakelistePayant}
                    {/* <small>%</small> */}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>

          {/* <NavLink to="/liste-bakelistes-boursiers" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Boursiers</span>
                  <span className="info-box-number">
                    {this.state.nbreBakelisteBoursier}
                  </span>
                </div>
              </div>
            </div>
          </NavLink> */}

          {/* <NavLink to="/liste-bakelistes-demi-boursier" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Demi Boursier</span>
                  <span className="info-box-number">
                    {this.state.nbreBakelisteDemiBoursier}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>  */}

          <NavLink to="/liste-bakelistes-en-stage-de-formation" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Stage de formation</span>
                  <span className="info-box-number">
                    {this.state.nbreBakelisteStageDF}
                    {/* <small>%</small> */}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/liste-bakelistes-en-contrat-isa" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Contrat ISA</span>
                  <span className="info-box-number">
                    {this.state.nbreBakelisteISA}
                    {/* <small>%</small> */}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>

          {/* <NavLink to="/liste-bakelistes-volkeno-foire" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Volkeno Foire</span>
                  <span className="info-box-number">
                    {this.state.nbrebakelistesVF}
                  </span>
                </div>
              </div>
            </div>
          </NavLink> */}

          <NavLink to="/liste-bakelistes-volkeno-hgrand-yoff1" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">HML GY 1</span>
                  <span className="info-box-number">
                    {this.state.nbrebakelistesGY1}
                    {/* <small>%</small> */}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/liste-bakelistes-volkeno-hgrand-yoff2" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">HLM GY 2</span>
                  <span className="info-box-number">
                    {this.state.nbrebakelistesGY2}
                    {/* <small>%</small> */}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/liste-bakelistes-thies" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Thiés</span>
                  <span className="info-box-number">
                    {this.state.nbrebakelistesThies}
                    {/* <small>%</small> */}
                  </span>
                </div>
              </div>
            </div>
          </NavLink>

          {/* <NavLink to="/liste-bakelistes-tambacounda" className="counterLink">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="info-box">
                <span className="info-box-icon bg-vgreen">
                  <i className="fas fa-user-graduate" ></i>
                </span>

                <div className="info-box-content">
                  <span className="info-box-text">Tambacounda</span>
                  <span className="info-box-number">
                    {this.state.nbrebakelistesTamba}
                  </span>
                </div>
              </div>
            </div>
          </NavLink> */}
        </div>
      </div>
    );
  }
}
