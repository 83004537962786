import React, {Component} from 'react';
import './gestionSyllabus.css';
import Menu from './../menu/Menu';
import { NavLink } from 'react-router-dom';
import { MagicSpinner } from "react-spinners-kit";
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import API from '../../variablesGlobales';
//import $ from 'jquery';


export default class GestionSyllabus extends Component {
    constructor(props) {
        let token = window.sessionStorage.getItem('token');
        let userStatus = window.sessionStorage.getItem('user_status');
        // let userID = window.sessionStorage.getItem('user_id');

        if (!token) {
          window.location = '/';
        }
        if (userStatus === 'bakeliste') {
            window.history.back();
        }

        super(props);
        this.state = {
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: true,
          api: API.API,
          syllabusDatas: [],
          domaines: [],
          fields: {},
          errors: {},
          sname: false,
          sdescription: false,
          stechnotool_empty: false,
          screatedby_empty: false,
          slevel_empty: false,
          sdomaineid_empty: false,
          addSyllabusInProgress: false,
          addSyllabusSuccessAlert: false,
          addSyllabusErrorAlert: false,
          remaining_char: 245,
          technologies: [],


        };

        this.addHandleChange = this.addHandleChange.bind(this);
        this.submitAddSyllabus = this.submitAddSyllabus.bind(this);
    }

    componentDidMount = () => {
      this.getAllDomaines();
      this.onGetSyllabus();

      setTimeout(() => {
        this.setState({
          isPageLoading: false,
          addSyllabusInProgress: false,

        })
      }, 5000)
    }

    onGetSyllabus = () => {
      this.setState({
        isPageLoading: true
      })
      var baseApiUrl = this.state.api;

      axios.get(baseApiUrl + 'syllabus')
        .then(response => {
          this.setState({
            isPageLoading: false
          })
          if(response.data.success){
            this.setState({
              syllabusDatas: response.data.data
            })
            console.log(this.state.syllabusDatas)
          }
        })
        .catch(error => {
          console.log(error.message)
          this.setState({
            isPageLoading: false,
            isGetSyllabusRequestError: true
          })
          this.onSwitchAlertStatus('isGetSyllabusRequestError')
        })
    }

    getAllDomaines = () => {
      axios.get(this.state.api + 'domaines')
          .then(res => {
              const domains = res.data;
              this.setState({
                  domaines: domains.data,
              });
              console.log(this.state.domaines)
          })
          .catch(error => {
            console.log(error.message)
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
          })
    }

    addHandleChange = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      fields["created_by"] = this.state.userID;

      if(e.target.name === 'syllabus_description'){
        var chars = (e.target.value).length;
        var remaining_char = 245 - chars;
        this.setState({
          remaining_char: remaining_char
        })
      }
      this.setState({
        fields: fields
      })

      console.log(fields)
    }

    onSwitchAlertStatus = (label) => {
      setTimeout(() => {
        if(label === 'addSyllabusSuccessAlert'){
          this.setState({
            addSyllabusSuccessAlert: false
          })
        }
        if(label === 'addSyllabusErrorAlert'){
          this.setState({
            addSyllabusErrorAlert: false
          })
        }
        if(label === 'isGetSyllabusRequestError'){
          this.setState({
            isGetSyllabusRequestError: false
          })
        }
        if(label === 'sname_empty'){
          this.setState({
            sname_empty: false
          })
        }
        if(label === 'sdescription_empty'){
          this.setState({
            sdescription_empty: false
          })
        }
        if(label === 'stechnotool_empty'){
          this.setState({
            stechnotool_empty: false
          })
        }
        if(label === 'screatedby_empty'){
          this.setState({
            screatedby_empty: false
          })
        }
        if(label === 'slevel_empty'){
          this.setState({
            slevel_empty: false
          })
        }
        if(label === 'sdomaineid_empty'){
          this.setState({
            sdomaineid_empty: false
          })
        }
      }, 5000)
    }

    onValidateAddFormData = () => {
      let fields = this.state.fields;
      let errors = {};
      let isValidForm = true;

      if (!fields["syllabus_name"]) {
        isValidForm = false;
        this.setState({
            sname_empty: true
        })
        this.onSwitchAlertStatus('sname_empty');
        errors["sname_empty"] = "*Ce champ est obligatoire";
      }
      if (!fields["syllabus_description"]) {
        isValidForm = false;
        this.setState({
            sdescription_empty: true
        })
        this.onSwitchAlertStatus('sdescription_empty');
        errors["sdescription_empty"] = "*Ce champ est obligatoire";
      }
      if (!fields["syllabus_techno_tool"]) {
        isValidForm = false;
        this.setState({
            stechnotool_empty: true
        })
        this.onSwitchAlertStatus('stechnotool_empty');
        errors["stechnotool_empty"] = "*Ce champ est obligatoire";
      }
      if (!fields["created_by"]) {
        isValidForm = false;
        this.setState({
            screatedby_empty: true
        })
        this.onSwitchAlertStatus('screatedby_empty');
        errors["screatedby_empty"] = "*Ce champ est obligatoire";
      }
      if (!fields["syllabus_level"]) {
        isValidForm = false;
        this.setState({
            slevel_empty: true
        })
        this.onSwitchAlertStatus('slevel_empty');
        errors["slevel_empty"] = "*Ce champ est obligatoire";
      }
      if (!fields["syllabus_domaine_id"]) {
        isValidForm = false;
        this.setState({
            sdomaineid_empty: true
        })
        this.onSwitchAlertStatus('sdomaineid_empty');
        errors["sdomaineid_empty"] = "*Ce champ est obligatoire";
      }

      this.setState({
        errors: errors
      });

      return isValidForm;
    }

    submitAddSyllabus = (e) => {
      e.preventDefault();

      if(this.onValidateAddFormData()){
        this.setState({
          addSyllabusInProgress: true
        })
  
        var baseApiUrl = this.state.api;
        var data = this.state.fields;

        axios.post(baseApiUrl + 'syllabus', data)
          .then(response => {
            this.setState({
              addSyllabusInProgress: false
            })
            if(response.data.success){
              //this.onGetSyllabus();
              //$('.close').click();
              // $('.modal-backdrop').removeClass('modal-backdrop');
              // $('.fade').removeClass('fade')
              // $('.in').removeClass('in')
              //$('#add_syllabus_form')[0].reset();

              this.setState({
                addSyllabusSuccessAlert: true
              })
              this.onSwitchAlertStatus('addSyllabusSuccessAlert')
              window.location.reload();
            }else{
              console.log(response)
              this.setState({
                addSyllabusErrorAlert: true
              })
              this.onSwitchAlertStatus('addSyllabusErrorAlert')
            }
          })
          .catch(error => {
            console.log(error.message)
            this.setState({
              addSyllabusInProgress: false,
              addSyllabusErrorAlert: true
            })
            this.onSwitchAlertStatus('addSyllabusErrorAlert');
            if (process.env.NODE_ENV !== 'production') {
              return;
            }
            Sentry.captureException(error);
          })
      }


    }
    getAllTechnologies = () => {
      axios
        .get(this.state.api + "technologies")
        .then((res) => {
          const technos = res.data;
          this.setState({
            technologies: technos.data,
          });
          console.log(this.state.technologies);
        })
        .catch((error) => {
          console.log(error.message);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    };
    render() {
      if(this.state.isPageLoading){
        return(
          <div className="component-gestion-syllabus">
            <Menu />
            {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
            Aprés avoir intégré AdminLTE */}
            <div style={{marginTop:"8%"}}>
  
              <section className="content-header">
                <h1 >
                  Syllabus Manager
              </h1>
                <ol className="breadcrumb">
                  <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Accueil</NavLink></li>
                  <li className="active">Syllabus Manager</li>
                </ol>
              </section>
  
              <section className="content">
                <div className="loader-row row">
                  <div className="col-md-4 col-md-offset-4 loader-container">
                    <MagicSpinner size={150} color="#009688" loading={this.state.isPageLoading} />
                  </div>
                </div>
              </section>
            </div>
          </div>
        );
      }
      return(
        <div className="component-gestion-syllabus">
          <Menu />
          {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
          Aprés avoir intégré AdminLTE */}
          <div className="row no-content-wrapper">

            <section className="content-header">
              <h1>
                Syllabus Manager
            </h1>
              <ol className="breadcrumb">
                <li><NavLink to="/dashboard-admin"><i className="fa fa-dashboard"></i> Accueil</NavLink></li>
                <li className="active">Syllabus Manager</li>
              </ol>
            </section>

            <section className="content">
              <div className="col-md-12 syllabus-container">
                <div className="row add_syllabus_container">
                    <div className="col-md-6 col-md-offset-3 add-btn-container">
                        <button data-toggle="modal" data-target="#addSyllabusModal" className="btn btn-success bg-vgreen add_btn button">
                          <img src="https://img.icons8.com/ios/50/FFFFFF/add.png" alt="Bouton Ajouter" />
                        </button>
                    </div>
                </div>

                <div className="row syllabus_content_row">
                  {this.state.syllabusDatas === null &&
                    <div className="col-md-12 empty_data_alerty">
                      <div className="alert alert danger empty_alert">
                        Aucun syllabus n'est encoré créé. Veuillez en ajouter !
                      </div>
                    </div>
                  }
                  {this.state.syllabusDatas.map((item, index) => 
                    <div key={index} className="col-md-4 syllabus_item_container">
                      <div className="box box-solid box-default box_container">
                        <div className="box-header with-border bg-vgreen">
                          <h3 className="box-title"> { item.syllabus_name } </h3>
                          <span className={ "label pull-right " + (item.syllabus_level === 'débutant' ? 'bg-vred' : item.syllabus_level === 'intermédiaire' ? 'bg-vorange' : item.syllabus_level === 'avancé' ? 'bg-vblack' : '')}> 
                            { item.syllabus_level } 
                          </span>
                        </div>
                        <div className="box-body">
                          <div className="description_container">
                            <span className="syllabus_description_bloc"> Description : </span>
                            <p> {item.syllabus_description} </p>
                          </div>
                          <div className="syllabus_tt_container">
                            { item.syllabus_techno_tool }
                          </div>
                        </div>
                        <NavLink to={ "/syllabus-content-manager/" + item.id} className="syllabus_more_infos_link">
                          <img className="more_infos_icon" src="https://img.icons8.com/ios-glyphs/50/009688/visible.png" alt="Détails" />
                        </NavLink>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
          {/*Modal d'ajout de syllabus */}

          hg
          <div className="modal fade" id="addSyllabusModal" tabIndex="-1" role="dialog" aria-labelledby="addSyllabusModalLabel">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 className="modal-title" id="addSyllabusModalLabel">Ajouter un syllabus</h4>
                    </div>
                    <form id="add_syllabus_form">
                      <div className="modal-body">
                          <div className="col-md-12 form_col">
                            <div className="form-group as_input_container">
                              <label htmlFor="syllabus_title">Nom du syllabus</label>
                              <input type="text" name="syllabus_name" placeholder="Nom du syllabus" onChange={this.addHandleChange} className="form-control input_item" id="syllabus_title" />
                            </div>
                            {this.state.sname_empty &&
                              <div className="errorMsg">{this.state.errors.sname_empty}</div>
                            }
                          </div>
                          <div className="col-md-12 form_col">
                            <div className="form-group as_input_container">
                              <label htmlFor="syllabus_description">Description du syllabus</label>
                              <textarea name="syllabus_description" maxLength="245" placeholder="Description du syllabus" onChange={this.addHandleChange} className="form-control input_item" id="syllabus_description" />
                              {this.state.remaining_char >= 31 && 
                                <span className="restant_container_sup_30"> { this.state.remaining_char } caractéres restants</span>
                              }
                              {this.state.remaining_char <= 30 && this.state.remaining_char > 1 &&
                                <span className="restant_container_inf_30"> { this.state.remaining_char } caractéres restants</span>
                              }
                              {this.state.remaining_char <= 1 && 
                                <span className="restant_container_inf_30"> { this.state.remaining_char } caractére restant</span>
                              }
                            </div>
                            {this.state.sdescription_empty &&
                              <div className="errorMsg">{this.state.errors.sdescription_empty}</div>
                            }
                          </div>
                          <div className="col-md-12 form_col">
                            <div className="form-group as_input_container">
                              <label htmlFor="syllabus_tech_tool">Tehnologies et/ou Outils</label>
                              <input type="text" name="syllabus_techno_tool" placeholder="Technologies et/ou Outils" onChange={this.addHandleChange} className="form-control input_item" id="syllabus_tech_tool" />
                            </div>
                            {this.state.stechnotool_empty &&
                              <div className="errorMsg">{this.state.errors.stechnotool_empty}</div>
                            }
                          </div>
                          <div className="col-md-12 form_col">
                            <div className="form-group as_input_container">
                              <label htmlFor="syllabus_level">Niveau ciblé</label> <br />
                              <span className="radio_button_container">
                                <input type="radio" value="débutant" name="syllabus_level" onChange={this.addHandleChange} className="input_item" />
                                &nbsp; <span className="radio_button_label"> Débutant </span>
                              </span> 
                              <span className="radio_button_container">
                                <input type="radio" value="intermédiaire" name="syllabus_level" onChange={this.addHandleChange} className="input_item" />
                                &nbsp; <span className="radio_button_label"> Intermédiaire </span>
                              </span>
                              <span className="radio_button_container">
                                <input type="radio" value="avancé" name="syllabus_level" onChange={this.addHandleChange} className="input_item" />
                                &nbsp; <span className="radio_button_label"> Avancé </span>
                              </span>
                              {this.state.slevel_empty &&
                                <div className="errorMsg">{this.state.errors.slevel_empty}</div>
                              }
                            </div>
                          </div>
                          <div className="col-md-12 form_col">
                            <div className="form-group as_input_container">
                              <label htmlFor="syllabus_domaine_id">Domaines</label>
                              <select name="syllabus_domaine_id" onChange={this.addHandleChange} className="form-control input_item" id="syllabus_domaine_id">
                                <option> --Choisir un domaine-- </option>
                                {this.state.domaines.map((item, index) => 
                                    <option key={index} value={item.id}> { item.name } </option>
                                )}
                              </select>
                            </div>
                            {this.state.sdomaineid_empty &&
                              <div className="errorMsg">{this.state.errors.sdomaineid_empty}</div>
                            }
                          </div>
                          {this.state.addSyllabusErrorAlert &&
                              <div className="row assignation-error">
                                  <div className="col-md-12 error-message-alert">
                                      Un problème est survenu lors de l'ajout. Veuillez réessayer plus tard.
                                  </div>
                              </div>
                          }
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-default bg-vred cancel-btn pull-left" data-dismiss="modal">
                          <span className="cancel_btn_label"> Quitter </span>
                          <img className="cancel-icon" src="https://img.icons8.com/material/24/FFFFFF/cancel--v1.png" alt="Annuler" />
                        </button>

                        {!this.state.addSyllabusInProgress ?
                          <button className="btn btn-success bg-vgreen submit_btn_container pull-right" onClick={(e) => this.submitAddSyllabus(e)}>
                            <span className="submit_btn_label"> Ajouter </span> &nbsp;
                            <img className="submit_icon" src="https://img.icons8.com/material-rounded/24/FFFFFF/filled-sent.png" alt="Ajouter" />
                          </button> :
                          <button className="btn btn-warning bg-vorange in-progress-btn-container pull-right">
                            <span className="in_progress_btn_label"> Ajout en cours </span> &nbsp;
                            <i className="fa fa-spin fa-spinner"></i>
                          </button>
                        }
                          
                      </div>
                    </form>
                </div>
            </div>
          </div>

          {/* Success submit tost alert */}
          {this.state.addSyllabusSuccessAlert === true &&
              <div className="alert alert-success text-center upsuccess-alert">
                  <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                  Syllabus ajouté avec succès.
              </div>
          }
        </div>
      );
    }
  }