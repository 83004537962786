import React, { Component } from "react";
import "./participantByRencontre.css";
import Menu from "./../menu/Menu";
import { NavLink } from "react-router-dom";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import API from "../../variablesGlobales";
import axios from "axios";

export default class participantByRencontre extends Component {
  constructor(props) {
    super(props);
    this.getParticipantInfos = this.getParticipantInfos.bind(this);
    this.state = {
      api: API.API,
      rencontres: [],
      detailsRencontres: [],
      isPageLoading: false,
      rencontreID: window.sessionStorage.getItem("rencontreIDForRedirect"),
      rencontreName: window.sessionStorage.getItem("rencontreName"),
      rencontreType: window.sessionStorage.getItem("rencontreType"),
      participantFullname: "",
      participant: [],
      isInterview: false,
      isBootcamp: false,
      masterData: [],
      masterDataCount: 0,
      licenceData: [],
      licenceDataCount: 0,
      othersData: [],
      othersDataCount: 0,
      masterPercent: 0,
      licencePercent: 0,
      autresPercent: 0,
    };
  }

  componentDidMount() {
    console.log("okk", this.state.rencontreID);
    this.retrieveDataRelatedToEvents();
  }

  // Get event more infos
  retrieveDataRelatedToEvents = () => {
    this.setState({
      isPageLoading: true,
    });

    let rencontreID = this.state.rencontreID;
    console.log(rencontreID);

    axios
      .get(this.state.api + `details-by-rencontre/${rencontreID}`)
      .then((res) => {
        let detailsRencontre = res.data.data;
        console.log("okkk", detailsRencontre);
        this.setState({
          isPageLoading: false,
          detailsRencontres: detailsRencontre,
        });

        // if (
        //   this.state.rencontreType === "interview" ||
        //   this.state.rencontreType === "bootcamp"
        // ) {
        //   this.setState({
        //     participantCount: res.data.participantCount,
        //     masterData: res.data.dataMaster,
        //     masterDataCount: res.data.dataMasterCount,
        //     licenceData: res.data.dataLicence,
        //     licenceDataCount: res.data.dataLicenceCount,
        //     othersData: res.data.dataOthers,
        //     othersDataCount: res.data.dataOthersCount,
        //   });
        //   if (this.state.rencontreType === "interview") {
        //     this.setState({
        //       isInterview: true,
        //     });
        //   } else if (this.state.rencontreType === "bootcamp") {
        //     this.setState({
        //       detailsRencontres: res.data.dataBootCamp,
        //       isBootcamp: true,
        //     });
        //   }

        //   console.log(this.state.detailsRencontres);

        //   let total = this.state.participantCount;
        //   let master = this.state.masterDataCount;
        //   let licence = this.state.licenceDataCount;
        //   //let autres = this.state.othersDataCount;

        //   let masterPercent = Math.round((master * 100) / total);
        //   let licencePercent = Math.round((licence * 100) / total);
        //   let autresPercent = 100 - (masterPercent + licencePercent);

        //   this.setState({
        //     masterPercent: masterPercent,
        //     licencePercent: licencePercent,
        //     autresPercent: autresPercent,
        //   });
        // }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isPageLoading: false,
        });
      });
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  getParticipantInfos = (participant) => {
    console.log(participant);
    this.setState({
      participantFullname: participant.participantFullname,
      participantEmail: participant.participantEmail,
      participantPhone: participant.participantPhone,
      participantAddress: participant.participantAddress,
      participantVilleResidende: participant.ville_residence,
      participantFormationSuivie: participant.formation_suivie,
      participantNiveauEtude: participant.niveau_etude,
      participant: participant,
    });
  };

  actionsFormatter = (cell, row) => {
    return (
      <div className="actions-btn-container">
        <button
          type="button"
          className="btn btn-info"
          id="btn_assign"
          data-toggle="modal"
          data-target="#showInfosModal"
          onClick={(e) => this.getParticipantInfos(row, e)}
        >
          <i class="fas fa-eye"></i>
        </button>
      </div>
    );
  };

  fullnameFormatter = (cell, row) => {
    return (
      <span
        type="button"
        className="participantFullname fullname-link"
        id="pFullname"
        data-toggle="modal"
        data-target="#showInfosModal"
        onClick={(e) => this.getParticipantInfos(row, e)}
      >
        {cell}
      </span>
    );
  };

  cvFormatter = (cell, row) => {
    return (
      <span>
        <a
          href={cell}
          alt="CV Link"
          target="_blank"
          rel="noopener noreferrer"
          className="cv_link text-center"
        >
          <i class="fas fa-file"></i>
        </a>
      </span>
    );
  };

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
        {/* <button type='button'
          className={ `btn btn-primary volkeno-red-bcolor` }>
          MyCustomBtn
        </button> */}
      </ButtonGroup>
    );
  };

  canMoveFormatter = (cell, row) => {
    if (cell === 1) {
      return <span className="label label-success"> peut se déplacer </span>;
    } else {
      return (
        <span className="label label-danger"> ne peut pas se déplacer </span>
      );
    }
  };

  render() {
    console.log("jjjjjj", this.state.detailsRencontres);
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      btnGroup: this.createCustomButtonGroup,
    };

    if (this.state.isPageLoading) {
      return (
        <div className="component-participant-by-rencontre">
          <Menu />
          <div className="col-lg-12">
            <h1 className="page-header">
              <span className="page-title">
                Participants {this.state.rencontreName}{" "}
              </span>
              <div className="pull-right">
                <ol className="breadcrumb">
                  <li>
                    <NavLink to="/dashboard-admin" activeClassName="active">
                      Accueil
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/meetings" activeClassName="active">
                      Gestion des rencontres
                    </NavLink>
                  </li>
                  <li className="active">Gestion des participant</li>
                </ol>
              </div>
            </h1>
          </div>

          <div className="detailsRencontre_container">
            <div className="table_container">
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Prénom et Nom</th>
                    <th scope="col">Email</th>
                    <th scope="col">Téléphone</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <div id="loader"></div>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="component-participant-by-rencontre">
        <Menu />
        <div className="col-lg-12">
          <h1 className="page-header">
            <span className="page-title">
              Participants {this.state.rencontreName}{" "}
            </span>
            <div className="pull-right">
              <ol className="breadcrumb">
                <li>
                  <NavLink to="/dashboard-admin" activeClassName="active">
                    Accueil
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/meetings" activeClassName="active">
                    Gestion des rencontres
                  </NavLink>
                </li>
                <li className="active">Gestion des participant</li>
              </ol>
            </div>
          </h1>
        </div>

        <div className="detailsRencontre_container">
          {this.state.detailsRencontres === undefined && (
            // this.state.detailsRencontres.length === 0 &&
            <div className="row empty_meeting_row">
              <div className="alert alert-warning empty-details-rencontre text-center">
                <p>
                  Vous n'avez pour le moment aucun participant à cette
                  rencontre.
                </p>
                <hr />
                <div className="return-btn-container">
                  <NavLink
                    to="/evenement"
                    activeClassName="active"
                    className="btn btn-info volkeno-green-bcolor return-btn"
                  >
                    <i className="fas fa-arrow-circle-left"></i> &nbsp;&nbsp;
                    Gestion des rencontres
                  </NavLink>
                </div>
              </div>
            </div>
          )}
          {/* {this.state.rencontreType === "evenement" && ( */}
          <div className="table_container">
            <BootstrapTable
              data={this.state.detailsRencontres}
              striped={true}
              hover={true}
              condensed={true}
              multiColumnSort={2}
              options={options}
              pagination
              search={true}
              exportCSV={true}
            >
              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                {" "}
                ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="first_name"
                dataFormat={this.fullnameFormatter.bind(this)}
              >
                Prenom
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="last_name"
                dataFormat={this.fullnameFormatter.bind(this)}
              >
                Nom
              </TableHeaderColumn>
              <TableHeaderColumn dataField="email">Email</TableHeaderColumn>
              <TableHeaderColumn dataField="phone">Téléphone</TableHeaderColumn>
              <TableHeaderColumn dataField="canal_infos">
                Canal
              </TableHeaderColumn>
              {/* <TableHeaderColumn dataField="ville_residence">
                Ville de résidence
              </TableHeaderColumn>
              <TableHeaderColumn dataField="formation_suivie">
                Formation suivie
              </TableHeaderColumn> */}
              <TableHeaderColumn
                dataField="id"
                dataFormat={this.actionsFormatter.bind(this)}
              >
                Actions
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
          {/* )} */}

          {/* {this.state.rencontreType === "atelier" && (
            <div className="table_container">
              <BootstrapTable
                data={this.state.detailsRencontres}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                exportCSV
                pagination
                search={true}
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="participantFullname"
                  dataFormat={this.fullnameFormatter.bind(this)}
                >
                  Nom complet
                </TableHeaderColumn>
                <TableHeaderColumn dataField="participantEmail">
                  Email
                </TableHeaderColumn>
                <TableHeaderColumn dataField="participantPhone">
                  Téléphone
                </TableHeaderColumn>
                <TableHeaderColumn dataField="ville_residence">
                  Ville de résidence
                </TableHeaderColumn>
                <TableHeaderColumn dataField="formation_suivie">
                  Formation suivie
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataFormat={this.actionsFormatter.bind(this)}
                >
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          )}

          {this.state.rencontreType === "interview" && (
            <div>
              <hr />
              <div className="row count-item-row">
                <NavLink
                  to={
                    "details-rencontre-by-level/" +
                    this.state.rencontreID +
                    "/master"
                  }
                >
                  <div className="col-md-4 count-item">
                    <div className="row count-intern-row">
                      <div className="col-md-4 count-intern-icon-container">
                        <i class="fas fa-user-graduate fa-2x"></i>
                      </div>
                      <div className="col-md-8 count-intern-content">
                        <span className="degree-title"> Master </span> <br />
                        <span className="count-number">
                          {" "}
                          {this.state.masterDataCount} soit{" "}
                          {this.state.masterPercent}%{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </NavLink>
                <NavLink
                  to={
                    "details-rencontre-by-level/" +
                    this.state.rencontreID +
                    "/licence"
                  }
                >
                  <div className="col-md-4 count-item">
                    <div className="row count-intern-row">
                      <div className="col-md-4 count-intern-icon-container">
                        <i class="fas fa-user-graduate fa-2x"></i>
                      </div>
                      <div className="col-md-8 count-intern-content">
                        <span className="degree-title"> Licence </span> <br />
                        <span className="count-number">
                          {" "}
                          {this.state.licenceDataCount} soit{" "}
                          {this.state.licencePercent}%{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </NavLink>
                <NavLink
                  to={
                    "details-rencontre-by-level/" +
                    this.state.rencontreID +
                    "/autres"
                  }
                >
                  <div className="col-md-4 count-item">
                    <div className="row count-intern-row">
                      <div className="col-md-4 count-intern-icon-container">
                        <i class="fas fa-user-graduate fa-2x"></i>
                      </div>
                      <div className="col-md-8 count-intern-content">
                        <span className="degree-title"> Autres </span> <br />
                        <span className="count-number">
                          {" "}
                          {this.state.othersDataCount} soit{" "}
                          {this.state.autresPercent}%{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
              <hr />
              <div className="table_container">
                <BootstrapTable
                  data={this.state.detailsRencontres}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  exportCSV
                  pagination
                  search={true}
                >
                  <TableHeaderColumn
                    width="20"
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    {" "}
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="150"
                    dataField="participantFullname"
                    dataFormat={this.fullnameFormatter.bind(this)}
                  >
                    Nom complet
                  </TableHeaderColumn>
                  <TableHeaderColumn width="150" dataField="participantEmail">
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn width="150" dataField="participantPhone">
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField="formation_suivie">
                    Formation suivie
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="150"
                    filter={{ type: "TextFilter", delay: 1000 }}
                    dataField="ville_residence"
                  >
                    Ville de résidence
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="100"
                    filter={{ type: "TextFilter", delay: 1000 }}
                    dataField="niveau_etude"
                  >
                    Niveau d'étude
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="90"
                    filter={{ type: "TextFilter", delay: 1000 }}
                    dataField="diplome_obtenu"
                  >
                    Diplôme
                  </TableHeaderColumn>
                  <TableHeaderColumn width="250" dataField="motivations">
                    Motivations
                  </TableHeaderColumn>
                  <TableHeaderColumn width="250" dataField="nbre_mois_stage">
                    Stage effectué
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="70"
                    dataField="cvLink"
                    dataFormat={this.cvFormatter.bind(this)}
                  >
                    CV
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    width="80"
                    dataField="id"
                    dataFormat={this.actionsFormatter.bind(this)}
                  >
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          )}

          {this.state.rencontreType === "bootcamp" &&
          this.state.detailsRencontres.length !== undefined ? (
           
              <div className="table_container">
                <BootstrapTable
                  data={this.state.detailsRencontres}
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  exportCSV
                  pagination
                  search={true}
                >
                  <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                    {" "}
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="250"
                    dataField="participantFullname"
                    dataFormat={this.fullnameFormatter.bind(this)}
                  >
                    Nom complet
                  </TableHeaderColumn>
                  <TableHeaderColumn width="250" dataField="participantEmail">
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn width="250" dataField="participantPhone">
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn width="250" dataField="participantAddress">
                    Adresse
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="250"
                    filter={{ type: "TextFilter", delay: 1000 }}
                    dataField="domaine"
                  >
                    Formation suivie
                  </TableHeaderColumn>
                  <TableHeaderColumn width="250" dataField="session">
                    Session
                  </TableHeaderColumn>
                  
                  
                  <TableHeaderColumn width="250" dataField="canal_infos">
                    Canal
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    width="250"
                    dataField="id"
                    dataFormat={this.actionsFormatter.bind(this)}
                  >
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          ) : (
            ""
          )} */}
        </div>

        {/* Show participant infos modal */}
        <div
          className="modal fade"
          id="showInfosModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="showInfosModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showInfosModalLabel">
                  Detail sur {this.state.participantFullname}{" "}
                </h4>
              </div>
              <div className="modal-body">
                <div className="item_container">
                  <span className="item_label">Email du participant : </span>{" "}
                  <br />
                  <span className="label_content">
                    {" "}
                    {this.state.participantEmail}{" "}
                  </span>
                </div>{" "}
                <hr />
                <div className="item_container">
                  <span className="item_label">
                    Téléphone du participant :{" "}
                  </span>{" "}
                  <br />
                  <span className="label_content">
                    {" "}
                    {this.state.participantPhone}{" "}
                  </span>
                </div>{" "}
                <hr />
                {this.state.rencontreType === "evenement" && (
                  <div>
                    <div className="item_container">
                      <span className="item_label">Ville de résidence : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participantVilleResidende}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Formation suivie : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participantFormationSuivie}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Niveau d'étude : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participantNiveauEtude}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                  </div>
                )}
                {this.state.rencontreType === "atelier" && (
                  <div>
                    <div className="item_container">
                      <span className="item_label">Ville de résidence : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participantVilleResidende}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Formation suivie : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participantFormationSuivie}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Niveau d'étude : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participantNiveauEtude}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                  </div>
                )}
                {this.state.rencontreType === "interview" && (
                  <div>
                    <div className="item_container">
                      <span className="item_label">Motivations : </span> <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participant.motivations}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Formation suivie : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participant.formation_suivie}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Niveau d'étude : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participant.niveau_etude}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Diplôme obtenu : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participant.diplome_obtenu}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Stage effectué : </span>{" "}
                      <br />
                      {this.state.participant.stage_ornot === true ? (
                        <div className="label label-success">
                          Ce candidat a déjà effectué un stage de{" "}
                          {this.state.participant.nbre_mois_stage}{" "}
                        </div>
                      ) : (
                        <div className="label label-info">
                          {" "}
                          Ce candidat n'a jamais effectué un stage en entreprise{" "}
                        </div>
                      )}
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">
                        Ordinateur et/ou Smartphone :{" "}
                      </span>{" "}
                      <br />
                      <br />
                      {this.state.participant.pc_ornot === true ? (
                        <div className="label label-success">
                          {" "}
                          Ce candidat a un ordinateur.{" "}
                        </div>
                      ) : (
                        <div className="label label-info">
                          {" "}
                          Ce candidat n'a pas un ordinateur.{" "}
                        </div>
                      )}
                      {this.state.participant.smartphone_ornot === true ? (
                        <div className="label label-success">
                          {" "}
                          Ce candidat a un smartphone.{" "}
                        </div>
                      ) : (
                        <div className="label label-info">
                          {" "}
                          Ce candidat n'a pas un smartphone.{" "}
                        </div>
                      )}
                    </div>{" "}
                    <hr />
                  </div>
                )}
                {this.state.rencontreType === "bootcamp" && (
                  <div>
                    <div className="item_container">
                      <span className="item_label">Adresse : </span> <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participantAddress} -{" "}
                        {this.state.participant.ville_residence}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Motivations : </span> <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participant.motivations}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">Niveau d'étude : </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participant.niveau_etude}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    <div className="item_container">
                      <span className="item_label">
                        Technologies / Langages / Outils :{" "}
                      </span>{" "}
                      <br />
                      <span className="label_content">
                        {" "}
                        {this.state.participant.technologies}{" "}
                      </span>
                    </div>{" "}
                    <hr />
                    {this.state.participant.can_move === 1 ? (
                      <div className="item_container">
                        <div className="alert alert-success text-center can_move">
                          Ce participant peut se déplacer.
                        </div>
                      </div>
                    ) : (
                      <div className="item_container">
                        <div className="alert alert-danger text-center canot_move">
                          Ce participant ne peut pas se déplacer.
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
