import React, {Component} from 'react';
import './editeurTexte.css'
import { Link } from 'react-router-dom';
import API from '../../variablesGlobales';
import axios from 'axios';
import {ClearSearchButton } from 'react-bootstrap-table';
import Menu from './../menu/Menu';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css';
import $ from 'jquery';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import * as Sentry from '@sentry/browser';
import CKEditor from "react-ckeditor-component";

export default class editeurTexte extends Component {
    constructor(props) {
        super(props);
        this.updateContent = this.updateContent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmitTextualContent = this.onSubmitTextualContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateHandleChange = this.updateHandleChange.bind(this);
        this.submitUpdateTextualContent = this.submitUpdateTextualContent.bind(this)
        this.onUpdateChange = this.onUpdateChange.bind(this);
        this.state = {
          bakelisteID: window.sessionStorage.getItem('user_id'),
          userFullName: window.sessionStorage.getItem('userFullName'),
          bakelisteDomaine:window.sessionStorage.getItem('user_domaine'),
          api: API.API,
          isPageLoading: false,
          content: '',
          fields: {},
          errors: {},
          submitTextualInProgress: false,
          bakelisteInfos: [],
          addTextualContentErrorAlert: false,
          addTextualContentSuccessAlert: false,
          textualContentInProgress: false,
          textualContents: [],
          textualContentsFilter: [],
          emptyContent: false,
          showTextualContentErrorAlert: false,
          textualContentItem: [],
          titleEmpty: false,
          contentTypeEmpty: false,
          textualContentEmpty: false,
          updateTitleValue: '',
          updateContentTypeValue: '',
          updateContentValue: '',
          updateTextualContentInProgress: false,
          updateTextualContentSuccessAlert: false,
          updateTextualContentErrorAlert: false,
          _fields: {},

        };
    }

    componentDidMount = () => {
      var bakelisteID = this.state.bakelisteID
      this.onGetBakelisteInfos();
      this.onGetTextualContentsByBakeliste(bakelisteID)
    }

    componentDidCatch(err, info){
      Sentry.captureException(err);
    }

    onGetBakelisteInfos = () => {
      axios.get(this.state.api + 'bakeliste-by-id/' + this.state.bakelisteID)
        .then(response => {
          
          this.setState({
            bakelisteInfos: response.data.data
          })
        })
        .catch(error => {
          console.log(error)
        })
    }

    onGetTextualContentsByBakeliste = (bakelisteID) => {
      this.setState({
        textualContentInProgress: true
      })
      
      axios.get(this.state.api + 'textual-contents-by-bakeliste/' + bakelisteID)
        .then(response => {
          this.setState({
            textualContentInProgress: false
          })

          if(response.data.success){
            this.setState({
              textualContents: response.data.data,
              textualContentsFilter: response.data.data,
            })
          }else{
            this.setState({
              emptyContent: true
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            textualContentInProgress: false,
            showTextualContentErrorAlert: true
          })
          if (process.env.NODE_ENV !== 'production') {
            return;
          }
          Sentry.captureException(error);
        })
    }

    updateContent(newContent) {
      this.setState({
          content: newContent
      })
    }

    handleChange(evt){
      let fields = this.state.fields;
      fields[evt.target.name] = evt.target.value;
      fields["bakeliste_id"] = this.state.bakelisteID;
      fields["coach_id"] = this.state.bakelisteInfos.coach_id;
      this.setState({
        fields: fields
      })
      console.log(fields)
    }

    onChange(evt){
      console.log("onChange fired with event info: ", evt);
      var newContent = evt.editor.getData();
      
      this.setState({
        // fields: {
        //   content: newContent
        // },
        content: newContent
      })
    }

    onUpdateChange(e){
      console.log("onChange fired with event info: ", e);
      let fields = this.state._fields;
      fields["bakeliste_id"] = this.state.bakelisteID;
      fields["coach_id"] = this.state.coachIdValue;
      fields["fullName"] = this.state.fullName;
      fields["domaine_id"] = this.state.bakelisteDomaine;
      var updateContent = e.editor.getData();
      fields["content"] = updateContent;

      //this.updateHandleChange(e)
      
      this.setState({
        updateContentValue: updateContent,
        _fields: fields
      })

      console.log(fields)
    }

    terxtualContentFormValidation = () => {
      let fields = this.state.fields;
      let errors = {};
      let isValidForm = true;

      if (!fields["title"]) {
        isValidForm = false;
        this.setState({
          titleEmpty: true
        })
        this.handleShowAndHideAlert('titleEmpty');
        errors["titleEmpty"] = "*Ce champ est obligatoire";
      }
      if (!fields["content_type"]) {
        isValidForm = false;
        this.setState({
          contentTypeEmpty: true
        })
        this.handleShowAndHideAlert('contentTypeEmpty');
        errors["contentTypeEmpty"] = "*Ce champ est obligatoire";
      }

      this.setState({
        errors: errors
      });
  
      return isValidForm;
    }

    onSubmitTextualContent = (e) => {
      e.preventDefault();
      let data = this.state.fields;
      data["content"] = this.state.content;
      let isNotEmptyContent = true;
      let errors = {};

      if (!data["content"]) {
        isNotEmptyContent = false;
        this.setState({
          textualContentEmpty: true
        })
        this.handleShowAndHideAlert('textualContentEmpty');
        errors["textualContentEmpty"] = "*Ce champ est obligatoire";

        this.setState({
          errors: errors
        })
      }

      if(this.terxtualContentFormValidation() && isNotEmptyContent){
        console.log(this.state.fields)
        this.setState({
          submitTextualInProgress: true
        })

        console.log('sending data ', data)

        axios.post(this.state.api + 'textual-contents', data)
          .then(response => {
            this.setState({
              submitTextualInProgress: false
            })
            if(response.data.success){
              $('#tc-form')[0].reset();
              this.setState({
                content: '',
                fields: {}
              })
              var bakelisteID = this.state.bakelisteID;
              this.onGetTextualContentsByBakeliste(bakelisteID)
              this.setState({
                addTextualContentSuccessAlert: true
              })
              this.handleShowAndHideAlert('addTextualContentSuccessAlert')
            }else{
              this.setState({
                addTextualContentErrorAlert: true
              })
              this.handleShowAndHideAlert('addTextualContentErrorAlert')
            }
          })
          .catch(error => {
            console.log(error)
            this.setState({
              submitTextualInProgress: false,
              addTextualContentErrorAlert: true
            })
            this.handleShowAndHideAlert('addTextualContentErrorAlert')
            if (process.env.NODE_ENV !== 'production') {
              return;
            }
            Sentry.captureException(error);
          })
      }
    }

    handleShowAndHideAlert(label) {
      setTimeout(() => {
        if (label === 'addTextualContentSuccessAlert') {
          this.setState({
            addTextualContentSuccessAlert: false
          });
        }
        if (label === 'addTextualContentErrorAlert') {
          this.setState({
            addTextualContentErrorAlert: false
          });
        }
        if(label === 'titleEmpty'){
          this.setState({
            titleEmpty: false
          });
        }
        if(label === 'contentTypeEmpty'){
          this.setState({
            contentTypeEmpty: false
          });
        }
        if(label === 'textualContentEmpty'){
          this.setState({
            textualContentEmpty: false
          });
        }
        if(label === 'updateTextualContentErrorAlert'){
          this.setState({
            updateTextualContentErrorAlert: false
          })
        }
        if(label === 'updateTextualContentSuccessAlert'){
          this.setState({
            updateTextualContentSuccessAlert: false
          })
        }
      }, 5000);
    }
    
    onBlur(evt){
      console.log("onBlur event called with event info: ", evt);
    }
    
    afterPaste(evt){
      console.log("afterPaste event called with event info: ", evt);
    }

    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    getTextualContentItem = (id) => {
     
      axios.get(this.state.api + `textual-contents/`+id)
            .then(res => {
                var data = res.data.data;
                if (data !== undefined) {
                    this.setState({  
                      textualContentItem: data,
                    });
                }

                console.log(this.state.allBakeliste, "lolo")
            })
            .catch(error => {
                if (process.env.NODE_ENV !== 'production') {
                    return;
                }
                Sentry.captureException(error);
            })
    }


    actionsFormatter = (cell) => {
      return (
          <div className="actions-btn-container">
              <i aria-hidden="true" className="eye big green link icon" data-toggle="modal" data-target="#showTextualContentItemModal" 
                id='icon-edit' onClick={(e) => this.getTextualContentItem(cell)}></i> &nbsp;
               <i aria-hidden="true" className="edit big orange link icon" data-toggle="modal" data-target="#updateTextualContentItemModal"
               id='icon-edit' onClick={(e) => this.onGetTextualContentItemForUpdate(cell)}></i> 
          </div>
      );
    }

    onGetTextualContentItemForUpdate = (id) => {
      axios.get(this.state.api + `textual-contents/`+id)
      .then(res => {
          var item = res.data.data;
          if (item !== undefined) {
              
              this.setState({
                updateTitleValue: item.title,
                updateContentTypeValue: item.content_type,
                updateContentValue: item.content,
                itemID: item.id,
                coachIdValue: item.coach_id
              })
     
          }
      })
      .catch(error => {
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
    }

    updateHandleChange = (e) => {
      let fields = this.state._fields;
      fields[e.target.name] = e.target.value;
      fields["bakeliste_id"] = this.state.bakelisteID;
      fields["coach_id"] = this.state.coachIdValue;
      
      if(e.target.name === 'title'){
        this.setState({
          updateTitleValue: e.target.value
        })
      }
      if (!fields["title"]) {
        fields["title"] = this.state.tacheValue;
      }
      if(e.target.name === 'content_type'){
        this.setState({
          updateContentTypeValue: e.target.value
        })
      }
      if (!fields["content_type"]) {
        fields["content_type"] = this.state.updateContentTypeValue;
      }

      this.setState({
        _fields: fields
      })
      console.log(fields)
    }

    submitUpdateTextualContent = (e) => {
      e.preventDefault();
      let data = this.state._fields;
      // console.log(data)
      // data["content"] = this.state.updateContentValue;
      console.log(data)
      const apiBaseUrl = this.state.api;
      const contentID = this.state.itemID;
      this.setState({
        updateTextualContentInProgress: true
      })
      axios.post(apiBaseUrl + 'update-textual-content-item/' + contentID, data)
        .then(response => {
          this.onGetTextualContentsByBakeliste(this.state.bakelisteID);
          $('.close').click();
          this.setState({
            updateTextualContentInProgress: false,
            updateTextualContentSuccessAlert: true
          })
          this.handleShowAndHideAlert('updateTextualContentSuccessAlert')
        })
        .catch(error => {
          console.log(error.message)
          this.setState({
            updateTextualContentErrorAlert: true,
            updateTextualContentInProgress: false
          })
          this.handleShowAndHideAlert('updateTextualContentErrorAlert')
        })
    }
    
      formatdateFrence =(date) => {
        const date2 = new Date(date);

        const fullYear = date2.getUTCFullYear();
        const  day = date2.getDay();
        const  month = date2.getMonth();

        const event = new Date(Date.UTC(fullYear,month,day));

      const options = {year: 'numeric', month: 'long', day: 'numeric' };
      return date = event.toLocaleDateString(undefined, options);
      }

      filterList = (event) => {
        var updatedList = this.state.textualContentsFilter;
        
            updatedList = updatedList.filter((item) => {
                return item.title.toLowerCase().search(
                    event.target.value.toLowerCase()) !== -1 || item.content_type.toLowerCase().search(
                        event.target.value.toLowerCase()) !== -1  ;              
            });
            this.setState({textualContents: updatedList });
    } 
    
    componentWillMount(){
      this.setState({
        textualContents: this.state.textualContentsFilter
      })
    }

    render() {
      if(this.state.isPageLoading){
        return(
          <div className="component-editeur-texte">
            {/* <Menu /> */}
              {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                Aprés avoir intégré AdminLTE */}
              <div className="row no-content-wrapper">
    
                {/* <section className="content-header">
                  <h1>
                    Editeur de texte
                  </h1>
                  <ol className="breadcrumb">
                    <li><Link to="/dashboard-bakeliste">Accueil</Link></li>
                    <li className="active">Editeur de texte</li>
                  </ol>
                </section> */}
    
                <section className="content">
                  <div className="editeur_texte_container">
                    <div id="loader"></div>
                  </div>
                </section>
    
              </div>
        </div>
        )
        
      }
      return(
        <div className="component-editeur-texte">
          {/* <Menu /> */}
            {/* TODO - Changer le nom de classe no-content-wrapper à content-wrapper
                    Aprés avoir intégré AdminLTE */}
            <div className="row no-content-wrapper">
  
              {/* <section className="content-header">
                <h1>
                  Editeur de texte
                </h1>
                <ol className="breadcrumb">
                  <li><Link to="/dashboard-bakeliste">Accueil</Link></li>
                  <li className="active">Editeur de texte</li>
                </ol>
              </section> */}
  
              <section className="content">
                <div className="editeur_texte_container">
                  <div className="row editeur-textuel-row">
                    <div className="col-md-6 form-col">
                      <div className="box box-solid box-warning">
                        {/* <div className="box-header">
                          <h3 className="box-title">Editeur de texte</h3>
                        </div> */}
                        <div className="box-body">
                          <form id="tc-form">
                          <div className="col-md-12 tc-input-item">
                              <div className="form-group ">
                                  <label >Choix du type de contenu :</label>
                                  <select className="form-control" id="ct-select" name="content_type" onChange={this.handleChange}>
                                    <option> --Choisir un type de contenu-- </option>
                                    <option value="résumé"> Résumé de cours </option>
                                    <option value="article"> Article </option>
                                    <option value="tutoriel"> Tutoriel </option>
                                    <option value="rapport de stage"> Rapport de stage </option>
                                    <option value="cahier de charges"> Cahier de charges </option>
                                    <option value="backlog"> Backlog </option>
                                  </select>
                                  
                              </div>
                              {this.state.contentTypeEmpty &&
                                  <div className="errorMsg">{this.state.errors.contentTypeEmpty}</div>
                              }
                          </div>
                          <div className="col-md-12 tc-input-item">
                              <div className="form-group ">
                                  <label >Titre :</label>
                                  <input type="text" className="form-control" id="title-input" placeholder="Titre" name="title" onChange={this.handleChange} />
                              </div>
                              {this.state.titleEmpty &&
                                  <div className="errorMsg">{this.state.errors.titleEmpty}</div>
                              }
                          </div>
                          <div className="col-md-12 tc-input-item">
                              <div className="form-group ">
                                  <label >Contenu :</label>
                                  <CKEditor 
                                    activeclassName="p10" 
                                    content={this.state.content} 
                                    events={{
                                      "blur": this.onBlur,
                                      "afterPaste": this.afterPaste,
                                      "change": this.onChange
                                    }}
                                  />
                              </div>
                              {this.state.textualContentEmpty &&
                                  <div className="errorMsg">*Ce champ est obligatoire</div>
                              }
                          </div>
                          <div className="col-md-4 col-md-offset-4">
                            {this.state.submitTextualInProgress === false ?
                              <button className="btn btn-success submit-button" onClick={(e) => this.onSubmitTextualContent(e)}>
                                <i className="fas fa-paper-plane"></i> &nbsp;
                                Envoyer
                              </button> :
                              <button className="btn btn-warning submit-button" >
                                <i className="fas fa-spin fa-spinner"></i> &nbsp;
                                Envoie en cours
                              </button>
                            }
                          </div>
                        </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 list-textuel-col">
                    
                    <div role="list" className="ui horizontal list">
                      {/* <div role="listitem" className="item">
                      <i aria-hidden="true" className="edit big link icon"></i>
                      </div> */}
                      <div role="listitem" className="item">
                      <div class="eight wide field">
                        <div className="ui large left icon input">
                          <input type="text" placeholder="Chercher un contenu par son titre ou par son type" onChange={this.filterList} />
                          <i aria-hidden="true" className="search icon"></i>
                      </div>
                      </div>
                      </div>
                      <div role="listitem" className="item">
                        
                      </div>
                   </div>
                      <br/><br/>
                      {this.state.textualContentInProgress ?
                              <div className="loader-container">
                                <div id="loader" className="tc-loader"></div>
                              </div> :
                      <div className="list-group list-group-flush">
                      {this.state.textualContents.map(item =>(
                      <Link to="#" className="list-group-item list-group-item-action contenu" key={item.id}>
                        <b id='title-contenu'>{item.title} </b> 
                        <span className='content_type' dangerouslySetInnerHTML={{__html:item.content_type}}></span>
                        <span className='created_at' >{this.formatdateFrence(item.created_at)}</span>
                        <span className='action-format' >{this.actionsFormatter(item.id)}</span>
                        
                    </Link>
                         
                      ))
                      
                      }
                      </div>
                   }
                    {/* btn add content */}
                    <div className='btn-add-content'>
                      <Link to='/editeur-texte-mobile' >
                      <i aria-hidden="true" className="teal add circular inverted icon"></i></Link>
                    </div>
                    </div>
                  </div>
                  
                </div>
              </section>

              <div className="modal fade bs-example-modal-lg" id="showTextualContentItemModal" tabIndex="-1" role="dialog" aria-labelledby="showTextualContentItemModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title" id="myModalLabel"> {this.state.textualContentItem.title} <sup> <span className="label label-danger"> {this.state.textualContentItem.content_type} </span> </sup> </h4>
                    </div>
                    <div className="modal-body">
                      <div className="row rowy">
                        <div className="col-md-12 tc-col">
                        <div className="content" dangerouslySetInnerHTML={{__html: this.state.textualContentItem.content}}></div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Fermer
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade bs-example-modal-lg" id="updateTextualContentItemModal" tabIndex="-1" role="dialog" aria-labelledby="showTextualContentItemModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title" id="myModalLabel"> {this.state.updateTitleValue} </h4>
                    </div>
                    <form id="update-tc-form">
                    <div className="modal-body">
                          {/* <div className="col-md-12 tc-input-item">
                              <div className="form-group ">
                                  <label >Choix du type de contenu :</label>
                                  <select className="form-control" id="ct-select" name="content_type" onChange={this.updateHandleChange} value={this.state.updateContentTypeValue}>
                                    <option> --Choisir un type de contenu-- </option>
                                    <option value="résumé"> Résumé de cours </option>
                                    <option value="article"> Article </option>
                                    <option value="tutoriel"> Tutoriel </option>
                                    <option value="rapport de stage"> Rapport de stage </option>
                                    <option value="cahier de charges"> Cahier de charges </option>
                                    <option value="backlog"> Backlog </option>
                                  </select>
                                  
                              </div>
                          </div>*/}
                          <div className="col-md-12 tc-input-item">
                              <div className="form-group ">
                                  <label >Titre :</label>
                                  <input type="text" className="form-control" id="title-input" placeholder="Titre" name="title" onChange={this.updateHandleChange} value={this.state.updateTitleValue} />
                              </div>
                          </div> 
                          <div className="col-md-12 tc-input-item">
                              <div className="form-group ">
                                  <label >Contenu :</label>
                                  <CKEditor 
                                    activeclassName="p10" 
                                    content={this.state.updateContentValue} 
                                    events={{
                                      "blur": this.onBlur,
                                      "afterPaste": this.afterPaste,
                                      "change": this.onUpdateChange
                                    }}
                                  />
                              </div>
                          </div>
                          {this.state.updateTextualContentErrorAlert &&
                            <div className="col-md-12 alerty-danger-col">
                              <div className="alert alert-danger error-alerty">
                                Une erreur est survenue lors de l'ajout. Veuillez réessayer plus tard.
                              </div>
                            </div>
                          }
                        
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                        <i className="fas fa-times-circle"></i> &nbsp;
                        Fermer
                      </button>
                      {this.state.updateTextualContentInProgress === false ?
                        <button className="btn btn-primary bsi-submit-btn" onClick={(e) => this.submitUpdateTextualContent(e)}>
                          <i className="fas fa-edit"></i> &nbsp;
                          Modifier
                        </button> :
                        <button className="btn btn-warning bsi-inprogress-btn pull-right" >
                          <i className="fas fa-spin fa-spinner"></i> &nbsp;
                          Envoie en cours
                        </button>
                      }
                    </div>
                    </form>
                  </div>
                </div>
              </div>
  
            </div>
                
                {/* Success submit new textual content toast alert */}
                {this.state.addTextualContentSuccessAlert === true &&
                  <div className="alert alert-success text-center upsuccess-alert">
                    <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                      Votre contenu textuel a été ajouté avec succès.
                  </div>
                }
                {/* Success update textual content toast alert */}
                {this.state.updateTextualContentSuccessAlert === true &&
                  <div className="alert alert-success text-center upsuccess-alert">
                    <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                      Votre contenu textuel a été ajouté avec succès.
                  </div>
                }
                {/* Error completed immersion toast alert */}
                {/* {this.state.archivedActionErrorAlert === true &&
                  <div className="alert alert-danger text-center upsuccess-alert">
                    <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                    L'arrêt de l'immersion a échoué.
                  </div>
                } */}
        </div>
      );
    }
  }