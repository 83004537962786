import React, { Component } from 'react';
import Menu from './../menu/Menu';
import { NavLink } from 'react-router-dom';
import API from '../../variablesGlobales';
import $ from 'jquery';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import CKEditor from "react-ckeditor-component";
export default class Bibliotheque extends Component {
constructor(props){
 
    super(props);
    this.state = {
      isHideEmptyText: false,
      isShowAddRIForm: false,
      bakelisteID: window.sessionStorage.getItem('user_id'),
      userFullName: window.sessionStorage.getItem('userFullName'),
      bakelisteDomaine:window.sessionStorage.getItem('user_domaine'),
      coachID: window.sessionStorage.getItem('coach_id'),
          api: API.API,
          domaines:[],
          isPageLoading: false,
          content: '',
          fields: {},
          errors: {},
          submitTextualInProgress: false,
          bakelisteInfos: [],
          addTextualContentErrorAlert: false,
          addTextualContentSuccessAlert: false,
          textualContentInProgress: false,
          textualContents: [],
          textualContentsFilter: [],
          emptyContent: false,
          showTextualContentErrorAlert: false,
          textualContentItem: [],
          titleEmpty: false,
          contentTypeEmpty: false,
          textualContentEmpty: false,
    }
    this.handleChange = this.handleChange.bind(this);
        this.onSubmitTextualContent = this.onSubmitTextualContent.bind(this);
        this.onChange = this.onChange.bind(this);
}
componentDidMount(){
  this.getAllDomaines();
  var bakelisteID = this.state.bakelisteID
  console.log('doamine',this.state.bakelisteDomaine);
   
  this.onGetBakelisteInfos();
  this.onGetTextualContentsByBakeliste(bakelisteID)
}
getAllDomaines = () => {
  axios.get(this.state.api + 'domaines')
      .then(res => {
          const domains = res.data;
          this.setState({
              domaines: domains.data,
          });
          console.log(this.state.domaines,'dom')
      })
      .catch(error => {
          if (process.env.NODE_ENV !== 'production') {
              return;
          }
          Sentry.captureException(error);
      })
}
showOrHideForm = () => {
  if(!this.state.isHideEmptyText && !this.state.isShowAddRIForm){
    this.setState({
      isHideEmptyText: true,
      isShowAddRIForm: true
    })
  }else{
    this.setState({
      isHideEmptyText: false,
      isShowAddRIForm: false
    })
  }
}
onGetBakelisteInfos = () => {
  axios.get(this.state.api + 'bakeliste-by-id/' + this.state.bakelisteID)
    .then(response => {
      
      this.setState({
        bakelisteInfos: response.data.data
      })
    })
    .catch(error => {
      console.log(error)
    })
}

onGetTextualContentsByBakeliste = (bakelisteID) => {
  this.setState({
    textualContentInProgress: true
  })
  
  axios.get(this.state.api + 'textual-contents-by-bakeliste/' + bakelisteID)
    .then(response => {
      this.setState({
        textualContentInProgress: false
      })

      if(response.data.success){
        this.setState({
          textualContents: response.data.data,
          textualContentsFilter: response.data.data,
        })
      }else{
        this.setState({
          emptyContent: true
        })
      }
    })
    .catch(error => {
      console.log(error)
      this.setState({
        textualContentInProgress: false,
        showTextualContentErrorAlert: true
      })
      if (process.env.NODE_ENV !== 'production') {
        return;
      }
      Sentry.captureException(error);
    })
}
handleChange(evt){
  let fields = this.state.fields;
  fields[evt.target.name] = evt.target.value;
  fields["bakeliste_id"] = this.state.bakelisteID;
  fields["coach_id"] = this.state.coachID;
  fields["fullName"]=this.state.userFullName;
  fields["domaine_id"]=this.state.bakelisteDomaine;
  this.setState({
    fields: fields
  })
  console.log(fields)
}

onChange(evt){
  console.log("onChange fired with event info: ", evt);
  var newContent = evt.editor.getData();
  
  this.setState({
    // fields: {
    //   content: newContent
    // },
    content: newContent
  })
}

onUpdateChange(e){
  console.log("onChange fired with event info: ", e);
  let fields = this.state._fields;
  fields["bakeliste_id"] = this.state.bakelisteID;
  fields["coach_id"] = this.state.coachIdValue;
  
  var updateContent = e.editor.getData();
  fields["content"] = updateContent;

  //this.updateHandleChange(e)
  
  this.setState({
    updateContentValue: updateContent,
    _fields: fields
  })

  console.log(fields)
}

terxtualContentFormValidation = () => {
  let fields = this.state.fields;
  let errors = {};
  let isValidForm = true;

  if (!fields["title"]) {
    isValidForm = false;
    this.setState({
      titleEmpty: true
    })
    this.handleShowAndHideAlert('titleEmpty');
    errors["titleEmpty"] = "*Ce champ est obligatoire";
  }
  if (!fields["content_type"]) {
    isValidForm = false;
    this.setState({
      contentTypeEmpty: true
    })
    this.handleShowAndHideAlert('contentTypeEmpty');
    errors["contentTypeEmpty"] = "*Ce champ est obligatoire";
  }

  this.setState({
    errors: errors
  });

  return isValidForm;
}

onSubmitTextualContent = (e) => {
  e.preventDefault();
  let data = this.state.fields;
  data["content"] = this.state.content;
  let isNotEmptyContent = true;
  let errors = {};

  if (!data["content"]) {
    isNotEmptyContent = false;
    this.setState({
      textualContentEmpty: true
    })
    this.handleShowAndHideAlert('textualContentEmpty');
    errors["textualContentEmpty"] = "*Ce champ est obligatoire";

    this.setState({
      errors: errors
    })
  }

  if(this.terxtualContentFormValidation() && isNotEmptyContent){
    console.log(this.state.fields)
    this.setState({
      submitTextualInProgress: true
    })

    console.log('sending data ', data)

    axios.post(this.state.api + 'textual-contents', data)
      .then(response => {
        this.setState({
          submitTextualInProgress: false,
          isHideEmptyText: true,
          isShowAddRIForm: false
        })
        if(response.data.success){
          $('#tc-form')[0].reset();
          this.setState({
            content: '',
            fields: {}
          })
          var bakelisteID = this.state.bakelisteID;
          this.onGetTextualContentsByBakeliste(bakelisteID)
          this.setState({
            addTextualContentSuccessAlert: true
          })
          this.handleShowAndHideAlert('addTextualContentSuccessAlert')
        }else{
          this.setState({
            addTextualContentErrorAlert: true
          })
          this.handleShowAndHideAlert('addTextualContentErrorAlert')
        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          submitTextualInProgress: false,
          addTextualContentErrorAlert: true
        })
        this.handleShowAndHideAlert('addTextualContentErrorAlert')
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        Sentry.captureException(error);
      })
  }
}

handleShowAndHideAlert(label) {
  setTimeout(() => {
    if (label === 'addTextualContentSuccessAlert') {
      this.setState({
        addTextualContentSuccessAlert: false
      });
    }
    if (label === 'addTextualContentErrorAlert') {
      this.setState({
        addTextualContentErrorAlert: false
      });
    }
    if(label === 'titleEmpty'){
      this.setState({
        titleEmpty: false
      });
    }
    if(label === 'contentTypeEmpty'){
      this.setState({
        contentTypeEmpty: false
      });
    }
    if(label === 'textualContentEmpty'){
      this.setState({
        textualContentEmpty: false
      });
    }
    if(label === 'updateTextualContentErrorAlert'){
      this.setState({
        updateTextualContentErrorAlert: false
      })
    }
    if(label === 'updateTextualContentSuccessAlert'){
      this.setState({
        updateTextualContentSuccessAlert: false
      })
    }
  }, 5000);
}

onBlur(evt){
  console.log("onBlur event called with event info: ", evt);
}

afterPaste(evt){
  console.log("afterPaste event called with event info: ", evt);
}

handleClearButtonClick = (onClick) => {
  console.log('This is my custom function for ClearSearchButton click event');
  onClick();
}



getTextualContentItem = (id) => {
 
  axios.get(this.state.api + `textual-contents/`+id)
        .then(res => {
            var data = res.data.data;
            if (data !== undefined) {
                this.setState({  
                  textualContentItem: data,
                });
            }

            console.log(this.state.allBakeliste, "lolo")
        })
        .catch(error => {
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}
onGetTextualContentsByDomaine = (bakelisteDomaine) => {
	this.setState({
	  textualContentInProgress: true
	})
	
	axios.get(this.state.api + 'textual-contents-by-domaine/' + bakelisteDomaine)
	  .then(response => {
		this.setState({
		  textualContentInProgress: false
		})

		if(response.data.success){
		  this.setState({
			textualContents: response.data.data,
			textualContentsFilter: response.data.data,
		  })
		  console.log(this.state.textualContents)
		}else{
		  this.setState({
			emptyContent: true
		  })
		}
	  })
	  .catch(error => {
		console.log(error)
		this.setState({
		  textualContentInProgress: false,
		  showTextualContentErrorAlert: true
		})
		if (process.env.NODE_ENV !== 'production') {
		  return;
		}
		Sentry.captureException(error);
	  })
  }
  getDomaineName = (name) => {
    window.sessionStorage.setItem('domaineNameForRedirectedPage', name);
}
render(){
    return(
        <div>
        <Menu />
        <div className="row">
            <div className="component-Reportings">
                <div className="col-lg-12">
                    <h1 className="page-header">
                        <span className="page-title">Bibliothèque</span>
                        <div className="pull-right">
                            <ol className="breadcrumb">
                                <li><NavLink to="#">Accueil</NavLink></li>
                                <li className="active">Bibliothèque</li>
                            </ol>
                        </div>
                    </h1>
                </div> 
                <div className="row count-bloc-container">
                {this.state.domaines.map((domaine,index) =>
                        <div className="col-lg-4 col-md-6" key={domaine.id}>

                             <NavLink to={`/contenu_bibliotheque/`+ domaine.id} className="counterLink" onClick={this.getAllDomaines(domaine.name)}> 
                              
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                <span className="info-box-icon bg-vgreen">
                                <i class="fas fa-layer-group"></i>
                                </span>

                                <div className="info-box-content">
                                   <span className="info-box-text">{domaine.name} </span> 
                                    <span className="info-box-number">
                                  
                                    </span>
                                </div>
                                </div>
                            </div>
                             </NavLink> 
                        </div>
                )}
                  </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <NavLink to="/contenu_desing" className="counterLink">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                <span className="info-box-icon bg-vorange">
                                <i class="fas fa-layer-group"></i>
                                </span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Desing</span>
                                    <span className="info-box-number">
                                    
                                    </span>
                                </div>
                                </div>
                            </div>
                            </NavLink>
                        </div> */}

                        {/* <div className="col-lg-4 col-md-6">
                            <NavLink to="/contenu_programmation" className="counterLink">
                            <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                <span className="info-box-icon bg-vred">
                                <i class="fas fa-code"></i>
                                </span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Programmation</span>
                                    <span className="info-box-number">
                                    
                                    </span>
                                </div>
                                </div>
                            </div>
                            </NavLink>
                        </div> */}

                        {/* <div className="col-lg-4 col-md-6">
                            <NavLink to="/contenu_anglais" className="counterLink">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-vgreen">
                                    <i class="fas fa-language"></i>
                                    </span>

                                    <div className="info-box-content">
                                    <span className="info-box-text">Anglais</span>
                                    <span className="info-box-number">
                                        
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </NavLink>
                        </div> */}

                        {/* <div className="col-lg-4 col-md-6">
                            <NavLink to="/contenu_bureautique" className="counterLink">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-vorange">
                                    <i class="fas fa-desktop"></i>
                                    </span>

                                    <div className="info-box-content">
                                    <span className="info-box-text">Bureautique</span>
                                    <span className="info-box-number">
                                        
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </NavLink>
                        </div> */}

                        {/* <div className="col-lg-4 col-md-6">
                            <NavLink to="#" className="counterLink">
                                <div className="col-md-3 col-sm-6 col-xs-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-vred">
                                    <i class="fas fa-user-shield"></i>
                                    </span>

                                    <div className="info-box-content">
                                    <span className="info-box-text">RH</span>
                                    <span className="info-box-number">
                                        
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </NavLink>
                        </div> */}
                  

                    <div className="col-md-12 reporting-container">
                        <div className="row add_bakeliste_container">
                            <div className="col-md-6 col-md-offset-3 add-bakeliste-btn-container">
                                <h2>Nouveau Document</h2>
                                <button className="btn btn-success " onClick={this.showOrHideForm}><i className="fas fa-plus"></i> Ajouter un document</button>
                            </div>
                            </div>
                            { this.state.isShowAddRIForm === true &&
                            <div className="box-body">
                            <form id="tc-form">
                            <div className="col-md-12 tc-input-item">
                                <div className="form-group ">
                                    <label >Choix du type de contenu :</label>
                                    <select className="form-control" id="ct-select" name="content_type" onChange={this.handleChange}>
                                      <option> --Choisir un type de contenu-- </option>
                                      <option value="résumé"> Résumé de cours </option>
                                      <option value="article"> Article </option>
                                      <option value="tutoriel"> Tutoriel </option>
                                      <option value="rapport de stage"> Rapport de stage </option>
                                      <option value="cahier de charges"> Cahier de charges </option>
                                      <option value="backlog"> Backlog </option>
                                    </select>
                                    
                                </div>
                                {this.state.contentTypeEmpty &&
                                    <div className="errorMsg">{this.state.errors.contentTypeEmpty}</div>
                                }
                            </div>
                            <div className="col-md-12 tc-input-item">
                                <div className="form-group ">
                                    <label >Titre :</label>
                                    <input type="text" className="form-control" id="title-input" placeholder="Titre" name="title" onChange={this.handleChange} />
                                </div>
                                {this.state.titleEmpty &&
                                    <div className="errorMsg">{this.state.errors.titleEmpty}</div>
                                }
                            </div>
                            <div className="col-md-12 tc-input-item">
                                <div className="form-group ">
                                    <label >Contenu :</label>
                                    <CKEditor 
                                      activeclassName="p10" 
                                      content={this.state.content} 
                                      events={{
                                        "blur": this.onBlur,
                                        "afterPaste": this.afterPaste,
                                        "change": this.onChange
                                      }}
                                    />
                                </div>
                                {this.state.textualContentEmpty &&
                                    <div className="errorMsg">*Ce champ est obligatoire</div>
                                }
                            </div>
                            <div className="col-md-4 col-md-offset-4">
                              {this.state.submitTextualInProgress === false ?
                                <button className="btn btn-success submit-button" onClick={(e) => this.onSubmitTextualContent(e)}>
                                  <i className="fas fa-paper-plane"></i> &nbsp;
                                  Envoyer
                                </button> :
                                <button className="btn btn-warning submit-button" >
                                  <i className="fas fa-spin fa-spinner"></i> &nbsp;
                                  Envoie en cours
                                </button>
                              }
                            </div>
                          </form>
                          </div>
                          } 
                          </div>
                          {/* Success submit new textual content toast alert */}
                {this.state.addTextualContentSuccessAlert === true &&
                  <div className="alert alert-success text-center upsuccess-alert">
                    <i className="fas fa-check-double"></i> &nbsp;&nbsp;
                      Votre contenu textuel a été ajouté avec succès.
                  </div>
                }
                </div>
                </div>
                </div>
    )
}
}