/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import './chat.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import Menu from '../menu/Menu';
import API from '../../variablesGlobales';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.min.css';
import '../../../node_modules/admin-lte/dist/css/AdminLTE.css';
import '../../../node_modules/admin-lte/dist/css/skins/_all-skins.css';
import { Form, TextArea } from 'semantic-ui-react-form-validator';
import $ from 'jquery';
//import { CSVReader } from 'react-papaparse';
class Chat extends Component {
 constructor(props) {
    super(props);
    this.state = {
        messager: 'Bonjour cher Bakeliste veillez remplir votre tâche ',
        messager2: 'Merci!! votre avez ajouté votre tâche ',
        api: API.API,
        isSend:false,
        userFullName: window.sessionStorage.getItem('userFullName'),
        coachID: window.sessionStorage.getItem('coach_id'),
        bakeliste_id: window.sessionStorage.getItem('user_id'),
         value: '',
         messages:'', 
         current_messag:"",
         status:'Terminé',
         date:'',
         lieu:'bakeli',
         app_name:'name_app',
         technology:'techno',
         tache:'',
         using_links:'',
         commentaire:'',
         file:'',
         fields:{},
         errors:{},
         addSuccessAlert:false,
         addChatAlert:false,
         EmptyTache:false,
         isValidForm:false,
         isChatAddSuccessAlert:false,
        succes:false,
        min:'',
        heure:'',
        year:'',
        month:'',
        day:'',
        taches:[],
        ir:'',
        update_message:'',
        reporting_id:0,
        date_update:'',
        selectedFile:null,
        importInProgress: false,
        successImportAlert: false,
        isLoadingBakelisteData: false


       }
     
       this.handSend = this.handSend.bind(this);
       this.handUpdate=this.handUpdate.bind(this);
       this.handleClick = this.handleClick.bind(this);
       this.handleClickUpdate=this.handleClickUpdate.bind(this);
       this._handleKeyPress = this._handleKeyPress.bind(this);
       this._handleKeyPressUpdate= this._handleKeyPressUpdate.bind(this);
       this.onChange = this.onChange.bind(this);
       this.onChangeUpdate=this.onChangeUpdate.bind(this);
       this.fileInput = React.createRef();
     
       this.handleInputChange = this.handleInputChange.bind(this);
       this.fileUploadHandler = this.fileUploadHandler.bind(this);
       this.scrollToBottom = this.scrollToBottom.bind(this);
      // this.mesRef = React.createRef();
      }
      
      componentDidMount() {
    
      this.getheure();
      this.getReportingByBakeliste();
      this.scrollToBottom();
      }

    componentDidUpdate() {
        this.scrollToBottom();
    }
    scrollToBottom = () => {
    
    window.scrollTo({
      top:document.documentElement.scrollHeight,
      behavior:"smooth"
    })
    };
      getheure = () =>{
        let rem = new Date()
        this.setState({
          date : rem.getFullYear() + '-' + (rem.getMonth() + 1) + '-' + rem.getDate(),
          heure:rem.getHours(),
          min:rem.getMinutes()

        })
      }
handUpdate = (enter=true) => {
  let update_message = this.state.update_message;
    
  console.log(this.state);
  if(update_message && enter){
   // messages =this.state.current_message;
  this.setState({
    messages:update_message,
    
   // tache:update_message,
    
  });
  this.onUpdateMessage();
} 
console.log(this.state.messages,"messsage")
}
  handSend = (enter=true) => {
    let current_message = this.state.current_message;
    console.log(this.state);
    if(current_message && enter){
    this.setState({
      messages:current_message,
      tache:current_message,
    });
    this.onPost();
  }
    console.log(this.state.messages,"messsage")
  }
  onPost = () =>{
    let current_message=this.state.current_message
    console.log(this.state,"state");
   let fields = this.state.fields;
   fields["date"]=this.state.date;
   console.log(this.state.date,"look date");
   fields["bakeliste_id"]=this.state.bakeliste_id;
   fields["status"]=this.state.status;
   fields["lieu"]=this.state.lieu;
   fields["app_name"]=this.state.app_name;
   fields["technology"]=this.state.technology;
   fields["coach_id"]=this.state.coachID;
   fields["tache"]=this.state.messages;
    console.log(this.state.fields,"feed");
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.tache=this.state.messages
   console.log(this.state.tache,"tache")
   const data = this.state.fields;
   console.log(data)
   this.setState({
    isLoadingBakelisteData: true
})
   axios.post(this.state.api + 'reporting_bakelistes',data)
   .then(res => {
       if (res.status === 200) {
           this.handleShowAndHideAlert('addSuccessAlert');
         // this.getReportingByBakeliste();
           this.setState({
             current_message:current_message,
               addChatAlert: true,
               isChatAddSuccessAlertAddSuccessAlert:true,
               isSend:true,
              succes: true,
              isLoadingBakelisteData: false
           });
           this.handleClearText();
           this.handleShowAndHideAlert('isChatAddSuccessAlert') ;
       }
   })
   .catch(error => {
       console.log(error);
       if (process.env.NODE_ENV !== 'production') {
           return;
       }
       Sentry.captureException(error);
   });
  }
handleClickUpdate(e){
  e.preventDefault();
  this.handUpdate();
}
  handleClick(e){
    e.preventDefault();
    this.handSend();
    this.handleClearText();
  }
onChangeUpdate(e){
  this.setState({
    update_message: e.target.value,
    messages:e.target.value
  });  
  const newLocal = this.state;
  newLocal.messages=this.state.update_message; 
}
  onChange(e) {
    this.setState({
      current_message: e.target.value,
      messages:e.target.value
    });  
    const newLocal = this.state;
    newLocal.messages=this.state.current_message;
    
  }
_handleKeyPressUpdate(e){
  let enter_pressed = false;
    if(e.key === "Enter"){
      enter_pressed = true;
      e.preventDefault();
    }
    this.handUpdate(enter_pressed)
    this.handleClearText();
}
    _handleKeyPress(e) {
    let enter_pressed = false;
    if(e.key === "Enter"){
      enter_pressed = true;
      e.preventDefault();
    }
    this.handSend(enter_pressed)
    this.handleClearText();
  }
  handleClearText (){
    this.setState({
      current_message:''
    })
  }
  handleShowAndHideAlert(label) {
    setTimeout(() => {
        if (label === 'addSuccessAlert') {
            this.setState({
                addChatAlert: false
            });
          } 
          if (label === 'isChatAddSuccessAlert') {
            this.setState({
                isChatAddSuccessAlert: false
            });
          }
         }, 1000);
        
      }
      onValidateChatForm = () => {
        let fields = this.state.fields;
        let errors = {};
        let isValidForm = true;
        if (!fields["tache"]) {
          isValidForm = false;
          this.setState({
              EmptyTache: true
          })
          this.handleShowAndHideAlert('EmptyTache');
          errors["EmptyTache"] = "*Aucune tache saisie";
      }
      this.setState({
        errors: errors
    })

    return isValidForm;
    }
   
    formatdateFrence =(date) => {
      const date2 = new Date(date);
    
      const fullYear = date2.getUTCFullYear();
      const  day = date2.getDay();
      const  month = date2.getMonth();
    
      const event = new Date(Date.UTC(fullYear,month,day));
    
    const options = {year: 'numeric', month: 'long', day: 'numeric' };
    return date = event.toLocaleDateString(undefined, options);
    
    }
   
    getReportingByBakeliste = () => {
      this.setState({
          isLoadingBakelisteData: true
      })
      axios.get(this.state.api + `reporting-by-bakeliste/${this.state.bakeliste_id}`)    
            .then(res => {
            this.setState({
              taches :res.data.data,
              isLoadingBakelisteData: false
            })
              
              console.log(this.state.taches,"reporting")
             
          })
          .catch(error => {
              if (process.env.NODE_ENV !== 'production') {
                  return;
              }
              Sentry.captureException(error);
          })
          console.log(this.state.taches,"toto")
  }
  onUpdateMessage = (e) => {
    const toto = new Date();
  // eslint-disable-next-line react/no-direct-mutation-state
  this.state.date = toto.getFullYear() + '-' + (toto.getMonth() + 1) + '-' + toto.getDate();
 
  console.log(this.state,"state");
   let fields = this.state.fields;
   console.log(this.state.date,"dater");
   fields["date"]= this.state.date;
   fields["bakeliste_id"]=this.state.bakeliste_id;
   fields["status"]=this.state.status;
   fields["lieu"]=this.state.lieu;
   fields["app_name"]=this.state.app_name;
   fields["technology"]=this.state.technology;
   fields["coach_id"]=this.state.coachID;
   fields["tache"]=this.state.update_message;
   
    console.log(this.state.fields,"feed")
   // this.state.tache=this.state.update_message
   console.log(this.state.tache,"tache")
   const data = this.state.fields;
   console.log(data,"data")
    console.log(this.state.reporting_id,'id')
    axios.put(this.state.api + 'reporting_bakelistes/' + this.state.reporting_id,data)
        .then(response => {
            if(response.data.success === true){
              this.handleShowAndHideAlert('addSuccessAlert');
              this.getReportingByBakeliste();
                $('.close').click();
                $('.modal-backdrop').removeClass('modal-backdrop');
                $('.fade').removeClass('fade')
                $('.in').removeClass('in')
                
                this.setState({
                   // updateReportingStatusSuccessAlert: true,
                   // updateReportingStatusInProgress: false
                });

               // this.handleShowAndHideAlert('updateStatusSuccessAlert');
            }else{
                console.log(response);
                this.setState({
                  //  updateReportingStatusInProgress: false,
                    //isUpdateStatusError: true
                });
            }
        })
        .catch(error => {
            console.log(error);
            this.setState({
                updateReportingStatusInProgress: false,
                isUpdateStatusError: true
            });
            if (process.env.NODE_ENV !== 'production') {
                return;
            }
            Sentry.captureException(error);
        })
}

onGetMessageUpdate = (id) => {
  axios.get(this.state.api + `reporting_bakelistes/`+id)
  .then(res => {
      var item = res.data.data;
      if (item !== undefined) {
          
          this.setState({
            update_message: item.tache,
            reporting_id:item.id,
           date_update:item.created_at
          })
 console.log(this.state.reporting_id,"tache item ",item)
      }
  })
  .catch(error => {
      if (process.env.NODE_ENV !== 'production') {
          return;
      }
      Sentry.captureException(error);
  })
}
actionsFormatter = (cell) => {
  return (
      <div className="actions-btn-container">
          {/* <i aria-hidden="true" className="eye big green link icon" id='icon-edit' onClick={(e) => this.onGetMessageUpdate(cell)}></i> */}
          <div className="action-editer">
        <i aria-hidden="true" class="fas fa-edit" onClick={(e) => this.onGetMessageUpdate(cell)} data-toggle="modal" data-target="#updateTextualContentItemModal"></i>
        </div>
      
      </div>
  );
}
 


handleInputChange(event) {
  this.setState({
    importInProgress: true
  })
 // const toto = new Date();
 // const newLocal = this.state;
 // newLocal.date = toto.getFullYear() + '-' + (toto.getMonth() + 1) + '-' + toto.getDate();
  
  const fields = this.state.fields;
  // fields["date"]= this.state.date;
   fields["bakeliste_id"]=this.state.bakeliste_id;
   fields["status"]=this.state.status;
   fields["lieu"]=this.state.lieu;
   fields["app_name"]=this.state.app_name;
   fields["technology"]=this.state.technology;
   fields["coach_id"]=this.state.coachID;
   fields["using_links"]=this.state.using_links;
   fields["commentaire"]=this.state.commentaire;
   if (event.target.name === 'tache') {
    console.log(event.target.files)
    this.createImage(event.target.files[0]);
    fields["tache"] = event.target.files[0]
  } else {
    fields[event.target.name] = event.target.value;
  }
  this.setState({
    fields
  })

  console.log(fields);
  this.fileUploadHandler();
}
createImage = (file) => {
  let reader = new FileReader();
  reader.onload = (e) => {
    this.setState({
      file: e.target.result
    })
  };
  console.log(this.state.file)
  reader.readAsDataURL(file);
}
fileUploadHandler = () => {
  this.setState({
    importInProgress: true
  })
  const filer = new FormData();
  //  filer.append('date',this.state.fields.date);
    filer.append('tache', this.state.fields.tache);
    filer.append('coach_id', this.state.fields.coach_id);
    filer.append('bakeliste_id',this.state.fields.bakeliste_id);
    filer.append('status',this.state.fields.status);
    filer.append('lieu',this.state.fields.lieu);
    filer.append('app_name',this.state.fields.app_name);
    filer.append('technology',this.state.fields.technology);
    filer.append('using_links',this.state.fields.using_links);
    filer.append('commentaire',this.state.fields.commentaire);
  // let data = this.state.fields;
   console.log(this.state.fields,"data")
    //console.log(this.state.reporting_id,'id')
      axios.post(this.state.api +'reporting_import_file',filer)
        .then(response => {
          //console.log(response.status)
          if (response.status === 200) {
         $('.close').click();
            console.log('click')
            $('body').removeClass('modal-open');
            console.log('open')
            $('.modal-backdrop').removeClass('modal-backdrop');
            console.log('backdrop')
            $('.fade').removeClass('fade')
            $('.in').removeClass('in')
            $('#register-form textarea').val('');
            // this.handleShowAndHideAlerte('successImport');
            this.getReportingByBakeliste();
            this.setState({
              importInProgress: false,
              successImportAlert: true,
            })
            setTimeout(() => {
              this.setState({
                successImportAlert: false
              })
            }, 5000);
           
        }else{  
            //console.log(response);
            console.log('non ok')
            this.setState({
              importInProgress: false
            });
        }
    })
    .catch(error => {
        console.log(error);
        this.setState({
          importInProgress: false
        });
        if (process.env.NODE_ENV !== 'production') {
            return;
        }
        Sentry.captureException(error);
    })
    
   //console.log("ok");
}
triggerClick = () => {
  this.fileInput.current.click();
}

handleShowAndHideAlerte(label) {
  setTimeout(() => {
    if (label === 'successImport') {
      this.setState({
        successImportAlert: false
      });
    }
  }, 5000);
}

isUrl = (str) =>{
  const res = str.substr(0,8);
  if(res === "https://"){
return (<a href={str}>voir le reporting fichier</a>)
  }else{
    return str
  }
}
 render() {
   
 return (
    <div className="component-bakeliste-home-dashboard">
    <Menu />
 <div className="row info_row_bakeliste ">

<div class="messaging">
<div className="msg-header">
        <div className="msg-header-img">
          <img src={require('../../../src/images/wagaan_head.png')} alt={''}/>
        </div>
        <div className="activer">
<h4>{this.state.userFullName}</h4>
        </div>
       <div className="header-icons">
       <div data-toggle="modal" data-target="#importCSVModal">
  <h4><i class="fas fa-paperclip"></i></h4>
</div>
        </div>
      </div>
 
	  <div class="msg_history" >
      {this.state.isLoadingBakelisteData &&
    <div className="loader-container-reporting" style={{marginTop:"20%",alignItems:"center"}}>
          <div id="loader"></div>
             </div>}
    { this.state.taches.map((index) =>
      <div class="outgoing_msg" key={index.id}>
      <ul >
      <div class="col-sm-4 wrappers"><br/>
      <button type="button" class="btn  btn-sm mb-2" style={{backgroundColor:"rgb(255,184,79)"}}>{index.frenchDateFormat}</button>
   
  </div><br/><br/>
  
     <li><div className="outgoing-msg-img" >
     
      <img src={require('../../../src/images/wagaan_head.png')} alt={''} style={{float:"right"}}/>
         </div>
		  <div class="sent_msg">
     {/* <div className="action-editer">
         <span ><i class="fas fa-edit"></i>editer</span>
    </div> */}
      
      
  <p className="chat">{this.isUrl(index.tache)}</p>
      <span className="edit-message">{this.actionsFormatter(index.id)}</span>
      </div></li><br/><br/>
      
      
      </ul>
      
      </div>
     
    )}
    
<br/>
		<div class="incoming_msg">
		  <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" className="imge"/> </div>
		  <div class="received_msg">
			<div class="received_withd_msg">
          <p>{this.state.messager}</p>
			  <span class="time_date"> {this.state.heure}:{this.state.min} AM </span></div>
		  </div>
		</div>
    
   
      </div>
      
    
    { this.state.isSend &&
		<div class="outgoing_msg">
    <div className="outgoing-msg-img">
      <img src={require('../../../src/images/wagaan_head.png')} alt={''} style={{float:"right"}}/>
         </div>
		  <div class="sent_msg">
			<p>{this.state.tache}</p>
			<span class="time_date"> {this.state.heure}:{this.state.min} AM </span> </div>
		</div>
 }<br/>
{ this.state.succes &&
<div class="incoming_msg">
		  <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"/> </div>
		  <div class="received_msg">
			<div class="received_withd_msg">
          <p>{this.state.messager2}</p>
			  <span class="time_date"> {this.state.heure}:{this.state.min} AM </span></div>
		  </div>
		</div>
	}	
	{/*  </div> */}
{/*	</div> */}
  </div>
<div className="type_msg">
  <div className="msg-bottom">
      
          <div className="input-icone">
        {/*    <input type="text" className="form-controle inputer" name="tache"  placeholder="Entrer votre message ...."  onChange={this.onChange} onKeyPress={this._handleKeyPress}
            _handleKeyPress={this._handleKeyPress} 
            
messages={this.state.current_message} /> */}
<Form color='teal' size='large' id="register-form" ref="form" onSubmit={this.handleSubmit}   >
            <TextArea    onChange={this.onChange} 
messages={this.state.current_message} 

      style={{ minHeight: 100 }} size='huge'
      className='textarea-message inputer'
    />
    
            <div className="input-icone-append ">                        
           {/* <span className="test"><i class="fa fa-paper-plane fa-lg fa-fw" onClick={this.handleClick} handleClick={this.handleClick}></i></span> 
          <a href class="float" data-target="#infos" data-toggle="modal">
          <i class="fa fa-paper-plane fa-lg fa-fw" onClick={this.handleClick} handleClick={this.handleClick}></i>
</a> 
*/ } 
<button class="msg_send_btn" type="button"><i class="fa fa-paper-plane tdr" onClick={this.handleClick} handleClick={this.handleClick} aria-hidden="true"></i></button>
</div>
            </Form>
          </div>
          
	   {/* add tache success toast */}
     {this.state.isChatAddSuccessAlert === true &&
            <div className="alert alert-success text-center upsuccess-alert">
                reporting ajoutée avec succès.
            </div>
        }
    </div>
    </div>
</div>
<div className="modal fade bs-example-modal-lg" id="updateTextualcContentItemModal" tabIndex="-1" role="dialog" aria-labelledby="showTextualContentItemModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 className="modal-title" id="myModalLabel"> Modifier message </h4>
                    </div>
                    
                    <div className="modal-body"><br/><br/>
                          
                        
                              <div className="form-group ">
                              <div className="input-icone">
            <input type="text" className="form-controle inputer" name="tache" value={this.state.update_message}   placeholder="Entrer votre message ...."  onChange={this.onChangeUpdate} onKeyPress={this._handleKeyPressUpdate}
            _handleKeyPressUpdate={this._handleKeyPressUpdate} 
            
            messages={this.state.update_message} />
            <div className="input-icone-append ">                        
          <button class="msg_send_btn" type="button"><i class="fa fa-paper-plane tdr" onClick={this.handleClickUpdate} handleClickUpdate={this.handleClickUpdate} aria-hidden="true"></i></button>
            </div>
          </div>    
                                  
           </div>
          
          </div>  
       </div>
       
     </div>
      </div>
      {/* modal send mail to all user sendMailalluserModal */}

      <div className="modal fade" id="updateTextualContentItemModal" tabIndex="-1" role="dialog" aria-labelledby="postAccessModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="postAccessModalLabel"> Modifier message </h4>
              </div>
              <br />
              <br />
              <Form color='teal' size='large' id="register-form" ref="form" onSubmit={this.handleSubmit}   >
                <TextArea
                  value={this.state.update_message}    onChange={this.onChangeUpdate} onKeyPress={this._handleKeyPressUpdate}
            _handleKeyPressUpdate={this._handleKeyPressUpdate}
            messages={this.state.update_message} 
            
                  style={{ minHeight: 100 }} size='huge'
                  className='textarea-message'
                />
                <div className="modal-footer">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                  {!this.state.changeTosendMailUsersProgress &&
                    <button type="button" className="btn btn-primary btn_for_assignation button addBtn" onClick={this.handleClickUpdate} handleClickUpdate={this.handleClickUpdate} >
                      <i className="fas fa-paper-plane"></i>&nbsp;
                      Envoyer
                    </button>
                  }
                  {this.state.changeTosendMailUsersProgress &&
                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                      Envoie mail en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                  <div className="row pm_form_rowy">
                    {this.state.errorSendMailToAllUserRequste &&
                      <div className="col-md-12 ri-error-alert-container">
                        <div className="alert alert-danger alerty-content">
                          Un problème est survenu lors de l'envoie. Veuillez réessayer plus tard.
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </Form>


            </div>
          </div>
        </div>
        {/* Import CSV Modal  */}
        
        <div className="modal fade" id="importCSVModal" tabindex="-1" role="dialog" aria-labelledby="importCSVLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 className="modal-title" id="importCSVLabel">Import de fichier vers le Reporting</h4>
              </div>
              <div className="modal-body">
                <div className="row import-input-row">
                  <div className="import-input col-md-8 col-md-offset-2">
                   
                  {/*   <CSVReader
                      onFileLoaded={this.fileSelectedHandler}
                      inputRef={this.fileInput}
                      style={{ display: 'none' }}
                    name="selectedFile"
                      onError={this.handleOnError}
                      onChange={this.onChanger}
                    /> 
                  
                       <input type={"file"} Ref={this.fileInput} style={{ display:"none"}}
                        onFileLoaded={this.onUploadFile} 
                       onChange={this.fileSelectedHandler}/>*/
                  }
                          <input id="myInput" type="file" ref={(ref) => this.myInput = ref} style={{ display: 'none' }}
                          onChange={this.handleInputChange}  name="tache"
                          />
                       
                    <button 
                     onClick={(e) => this.myInput.click() }
                     onChange={this.fileUploadHandler}  >Chargez le fichier </button>

                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-md-12 actions-col">
                  <button type="button" className="btn btn-default cancel_btn cancel button" data-dismiss="modal">
                    <i className="fas fa-times-circle"></i> &nbsp;
                    Quitter
                  </button>
                  {this.state.importInProgress &&
                    <button type="button" className="btn btn-warning button addBtn btn_for_wait">
                      Import en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
       
        {/* Successfull import toast */}
        {this.state.successImportAlert === true &&
          <div className="alert alert-success text-center upsuccess-alert">
            <i className="fas fa-check-double"></i> &nbsp;&nbsp;
            Les données ont été importées avec succès.
        </div>
        }
</div>



  );
      }
}
export default Chat;